/*
Author: jacek.bonecki@elmark.com.pl
*/

import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "stories/components/Tooltip/Tooltip";
import starOn from "images/StarOn.svg";
import starOff from "images/StarOff.svg";

const RatingStars = ({ title, value, handleChange, wrapperClass, starSize, disabled }) => {
  const { t } = useTranslation();
  return (
    <div className={wrapperClass}>
      <div>{title}</div>
      <div style={disabled ? { opacity: 0.5, filter: "alpha(opacity=50)" } : null}>
        {[1, 2, 3, 4, 5].map((item) => (
          <Tooltip key={`serviceStar${item}`} content={t(`reportAttention.star${item}`)} zIndex={1200} direction="bottom">
            <img
              className={!disabled ? "pointer" : ""}
              style={starSize && { width: starSize }}
              src={value >= item ? starOn : starOff}
              alt="star"
              onClick={() => {
                if (!disabled) handleChange(item);
              }}
            ></img>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default RatingStars;
