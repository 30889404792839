import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "stories/components/Modal/Modal";
import Button from "stories/components/Button/Button";
import ico from "images/Error.svg";

const CommonApiErrorModal = ({ title, content, close }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      onClose={close}
      footerActions={
        <div className="modal-buttons-container">
          <Button variant="primary" onClick={close}>
            {t("global.ok")}
          </Button>
        </div>
      }
    >
      <table>
        <tbody>
          <tr>
            <td>
              <img src={ico} width="96" alt="" className="pe-5" />
            </td>
            <td>{content}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default CommonApiErrorModal;
