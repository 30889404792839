import React from "react";
import "./styles.css";

const PageSpinner = ({ subPage = false }) => (
  <div className={`page-spinner-wrapper ${subPage ? "page-spinner-wrapper--subpage" : ""}`}>
    <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem", borderWidth: "0.5rem", color: "var(--primary-color)" }} />
  </div>
);

export default PageSpinner;
