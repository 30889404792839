import React from "react";
import { useTranslation } from "react-i18next";
import { DELIVERY_TYPE_ELMARK_COURIER, RETURN_TYPE_ELMARK_COURIER, RETURN_TYPE_PERSONAL, RETURN_TYPE_TO_BE_AGREED } from "utils/config";
import Radio from "stories/components/Radio/Radio";
import DhlLogoIcon from "images/dhl.svg";
import AddressForm from "components/AddressForm/AddressForm";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";

const ReturnForm = ({
  formErrors,
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedUser,
  setStreetWarningModalActive,
  setFieldTouched,
  productFullListIncludesUR,
}) => {
  const { t } = useTranslation();

  const clearReturnAddress = () => {
    const returnKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("returnAddress"))
      .map((value) => value.at(0));

    returnKeys.forEach((returnKey) => {
      values[`${returnKey}`] = "";
    });
  };

  const setReturnAddresFromInvoice = () => {
    const returnKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("returnAddress"))
      .map((value) => value.at(0));

    returnKeys.forEach((returnKey) => {
      setFieldTouched(returnKey, true);
      const userKey = returnKey.replace("returnAddress", "");
      const [userKeyFirstLetter, ...rest] = userKey;
      if (userKeyFirstLetter[0].toLowerCase() + rest.join("")) values[`${returnKey}`] = selectedUser[userKeyFirstLetter[0].toLowerCase() + rest.join("")];
    });

    values.returnAddressStreet = "";
    values.returnAddressBuilding = values.returnAddressBuilding || "";
    values.returnAddressPlace = values.returnAddressPlace || "";
    values.returnAddressCompanyName = values.returnAddressCompanyName || "";
  };

  const setReturnAddresFromDelivery = () => {
    const returnKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("returnAddress"))
      .map((value) => value.at(0));

    returnKeys.forEach((returnKey) => {
      const deliveryKey = returnKey.replace("returnAddress", "deliveryAddress");
      values[`${returnKey}`] = values[deliveryKey];
    });
  };

  return !productFullListIncludesUR ? (
    <div className="add-report-delivery__address-form">
      <div className="add-report-delivery__radio-container">
        <Radio
          checked={values.returnType === RETURN_TYPE_PERSONAL}
          onChange={() => setFieldValue("returnType", RETURN_TYPE_PERSONAL)}
          label={t("constants.return.type.personal")}
          name="return-type"
        />
        <div className="add-report-delivery__radio-wrapper">
          <Radio
            checked={values.returnType === RETURN_TYPE_ELMARK_COURIER}
            onChange={() => setFieldValue("returnType", RETURN_TYPE_ELMARK_COURIER)}
            label={t("constants.return.type.dhlCourier")}
            name="return-type"
          />
          <img className="add-report-delivery__icon-wrapper no-select" src={DhlLogoIcon} alt="dhl_logo" />
        </div>
      </div>
      {values.returnType === RETURN_TYPE_ELMARK_COURIER && (
        <Stack flexDirection="column" gap="1rem">
          <AddressForm
            formErrors={formErrors}
            type="return"
            values={values}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
          />
          <Stack gap="var(--modal-buttons-gap)" flexWrap="wrap">
            <Button
              outline
              variant="error"
              size="small"
              onClick={() => {
                clearReturnAddress();
                setFieldValue("returnFromInvoice", false);
                setFieldValue("returnFromDelivery", false);
              }}
            >
              {t("global.clear")}
            </Button>
            {values.deliveryType === DELIVERY_TYPE_ELMARK_COURIER && (
              <Button
                size="small"
                onClick={() => {
                  setReturnAddresFromDelivery();
                  setFieldValue("returnFromDelivery", true);
                  setFieldValue("returnFromInvoice", false);
                }}
              >
                {t("addReport.fillReturnData")}
              </Button>
            )}
            <Button
              size="small"
              onClick={() => {
                setReturnAddresFromInvoice();
                setFieldValue("returnFromInvoice", true);
                setFieldValue("returnFromDelivery", false);
                setStreetWarningModalActive(true);
              }}
            >
              {t("addReport.fillInvoiceData")}
            </Button>
          </Stack>
        </Stack>
      )}
    </div>
  ) : (
    <div className="add-report-delivery__radio-container">
      <Radio
        checked={values.returnType === RETURN_TYPE_TO_BE_AGREED}
        onChange={() => setFieldValue("returnType", RETURN_TYPE_TO_BE_AGREED)}
        label={t("constants.return.type.toBeAgreed")}
        name="return-type"
      />
      <p className="paragraph--small text--subdued add-report-delivery__radio-description">{t("addReport.toBeAgreedInfo")}</p>
    </div>
  );
};

export default ReturnForm;
