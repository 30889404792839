import React from "react";
import { useMediaQuery } from "react-responsive";
import DisplayDesktop from "./DisplayDesktop";
import DisplayMobile from "./DisplayMobile";
import "./styles.css";

export const Body = ({
  reportList,
  loading,
  setEndDateFrom,
  setEndDateUpTo,
  setSerialNumber,
  setPartNumber,
  setRepairId,
  setProducerId,
  setTechnicianId,
  setIsIncopmlete,
  setEndDateFromValue,
  setEndDateUpToValue,
  setRepairIdValue,
  setSerialNumberValue,
  setPartNumberValue,
  setTechnicianIdValue,
  setProducerIdValue,
  setIsIncopmleteValue,
}) => {
  const mobile = useMediaQuery({ maxWidth: 850 });

  return (
    <>
      {mobile ? (
        <DisplayMobile
          reportList={reportList}
          loading={loading}
          setEndDateFrom={setEndDateFrom}
          setEndDateUpTo={setEndDateUpTo}
          setRepairId={setRepairId}
          setSerialNumber={setSerialNumber}
          setPartNumber={setPartNumber}
          setTechnicianId={setTechnicianId}
          setProducerId={setProducerId}
          setIsIncopmlete={setIsIncopmlete}
          setEndDateFromValue={setEndDateFromValue}
          setEndDateUpToValue={setEndDateUpToValue}
          setRepairIdValue={setRepairIdValue}
          setSerialNumberValue={setSerialNumberValue}
          setPartNumberValue={setPartNumberValue}
          setTechnicianIdValue={setTechnicianIdValue}
          setProducerIdValue={setProducerIdValue}
          setIsIncopmleteValue={setIsIncopmleteValue}
        />
      ) : (
        <DisplayDesktop
          reportList={reportList}
          loading={loading}
          setEndDateFrom={setEndDateFrom}
          setEndDateUpTo={setEndDateUpTo}
          setRepairId={setRepairId}
          setSerialNumber={setSerialNumber}
          setPartNumber={setPartNumber}
          setTechnicianId={setTechnicianId}
          setProducerId={setProducerId}
          setIsIncopmlete={setIsIncopmlete}
          setEndDateFromValue={setEndDateFromValue}
          setEndDateUpToValue={setEndDateUpToValue}
          setRepairIdValue={setRepairIdValue}
          setSerialNumberValue={setSerialNumberValue}
          setPartNumberValue={setPartNumberValue}
          setTechnicianIdValue={setTechnicianIdValue}
          setProducerIdValue={setProducerIdValue}
          setIsIncopmleteValue={setIsIncopmleteValue}
        />
      )}
    </>
  );
};
