import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "stories/components/Modal/Modal";
import { HelpSerialNumber } from "routes/Help/HelpSerialNumber";

const SerialNumberModal = ({ onClose, active }) => {
  const { t } = useTranslation();

  if (!active) return;

  return (
    <Modal activePrimaryButton={false} title={t("loginPage.serialNumberQuestion")} onClose={onClose}>
      <HelpSerialNumber inModal={true} />
    </Modal>
  );
};

export default SerialNumberModal;
