import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "stories/components/Modal/Modal";
import LoginForm from "../LoginForm";

const LoginModal = ({ active, onClose }) => {
  const { t } = useTranslation();
  if (!active) return;

  return (
    <Modal title={t("loginPage.login")} onClose={onClose} activePrimaryButton={false} activeCloseButton={false}>
      <LoginForm />
    </Modal>
  );
};

export default LoginModal;
