import React, { useState } from "react";
import lodash from "lodash";
import { useSelector } from "react-redux";
import RatingForm from "./RatingForm";
import RatingResult from "./RatingResult";
import { useTranslation } from "react-i18next";
import { canShowRatingForm } from "./utils";

const CustomerRating = ({
  conversationListRefresh,
  setConversationListRefresh,
  customerRatingResult,
  ratingInvitationResult,
  refreshRepairDetails,
  setRefreshRepairDetails,
  setRejectRatingModalActive,
}) => {
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const { t } = useTranslation();
  const [customerRatingEditMode, setCustomerRatingEditMode] = useState(
    canShowRatingForm(repairDetails, user, customerRatingResult, ratingInvitationResult) && lodash.isEmpty(customerRatingResult),
  );

  const customerRatingTitles = {
    true: t("myRepairs.rateService"),
    false: t("global.customerRating"),
  };

  return (
    <div className="rating-wrapper">
      <h6 className="heading--6 heading--large-spacing">{customerRatingTitles[user.id === repairDetails.userId]}</h6>
      {customerRatingEditMode ? (
        <RatingForm
          customerRatingForm
          conversationListRefresh={conversationListRefresh}
          setConversationListRefresh={setConversationListRefresh}
          ratingResult={customerRatingResult}
          ratingInvitationResult={ratingInvitationResult}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setRejectRatingModalActive={setRejectRatingModalActive}
          setEditMode={setCustomerRatingEditMode}
        />
      ) : (
        <RatingResult
          customerRating
          ratingInvitationResult={ratingInvitationResult}
          conversationListRefresh={conversationListRefresh}
          setConversationListRefresh={setConversationListRefresh}
          ratingResult={customerRatingResult}
          setEditMode={setCustomerRatingEditMode}
        />
      )}
    </div>
  );
};

export default CustomerRating;
