import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const DescriptionList = ({ list }) => (
  <dl className="description_list">
    {list.map(({ term, description }) => (
      <React.Fragment key={term}>
        <dt className="description_list__term">{term}</dt>
        <dd className="description_list__description">{description}</dd>
      </React.Fragment>
    ))}
  </dl>
);

DescriptionList.propTypes = {
  list: PropTypes.array,
};

export default DescriptionList;
