import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AddReportHeading } from "../AddReportHeading";
import { AddReportFooter } from "../AddReportFooter";
import AddContactDataForm from "./AddContactDataForm";
import InvoiceDetails from "./InvoiceDetails";

const DisplayDesktop = ({ values, setRegistrationContact, touched, errors, handleBlur, formErrors, setFieldValue, sendContactDataLoading, formValid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="add-report">
      <AddReportHeading />
      <div className="add-report__sides-wrapper">
        <div className="add-report__side-panel add-report-contact__invoice-details-wrapper">
          <InvoiceDetails />
        </div>
        <div className="add-report__side-border" />
        <div className="add-report__side-panel">
          <h6 className="heading--6 heading--margin">{t("addReport.enterData")}</h6>
          <AddContactDataForm touched={touched} errors={errors} handleBlur={handleBlur} formErrors={formErrors} values={values} setFieldValue={setFieldValue} />
        </div>
      </div>
      <AddReportFooter
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 2 })}
        backActionLabel={t("global.back")}
        continueAction={() => setRegistrationContact(values)}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={!formValid && values.newContactData}
        continueActionLoading={sendContactDataLoading}
      />
    </div>
  );
};

export default DisplayDesktop;
