import React, { forwardRef } from "react";
import { getClassNamesForTextField } from "./utils";

const SendMessageField = forwardRef(({ id, disabled = false, loading = false, buttonContent, name, placeholder, value, onChange = () => {}, onClick }, ref) => (
  <div className={`input-send ${getClassNamesForTextField(disabled)}`}>
    <input
      id={id}
      ref={ref}
      name={name}
      className="input-send__field"
      placeholder={placeholder}
      value={value}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !disabled) onClick();
      }}
      onChange={(event) => {
        if (!disabled) onChange(event.target.value);
      }}
    />
    <button disabled={disabled || loading} className={`input-send__button ${disabled ? "input-send__button--disabled" : ""}`} onClick={() => onClick()}>
      {loading ? (
        <div className="spinner-border" role="status" style={{ width: "1.25rem", height: "1.25rem", borderWidth: "0.25rem", color: "var(--primary-color)" }} />
      ) : (
        buttonContent
      )}
    </button>
  </div>
));

export default SendMessageField;
