import React, { forwardRef } from "react";
import { getClassNamesForTextField } from "./utils";

const SinglelineField = forwardRef(({ disabled = false, onChange, className, specialActions, ...props }, ref) => (
  <div className="textfield--special-actions">
    <input
      disabled={disabled}
      className={`input--text ${getClassNamesForTextField(disabled)}`}
      onChange={(event) => onChange(event.target.value, event.target.name)}
      {...props}
      ref={ref}
    />
    {specialActions}
  </div>
));

export default SinglelineField;
