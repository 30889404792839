import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckMore = () => {
  const { t } = useTranslation();

  return (
    <div className="serial-number__check-more">
      <span>
        {`${t("serialNumber.checkAllPossibilitiesUsingOurApp")}: `}
        <Link className="custom-link" to="/">
          serwis.elmark.com.pl
        </Link>
      </span>
    </div>
  );
};

export default CheckMore;
