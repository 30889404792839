import React from "react";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import AppliedFiltersList from "./AppliedFiltersList";
import RepairsList from "./RepairsList";
import TopActions from "./TopActions";

const DisplayClientMobile = ({ repairListLoading, activeFiltersDrawer, setActiveFiltersDrawer, pageNumber, setPageNumber }) => {
  const {
    repair: { repairsList, pageSize },
  } = useSelector((store) => store);
  return (
    <div className="mobile-repair-admin">
      <TopActions
        pageSize={pageSize}
        receivedRepairs={repairsList}
        repairListLoading={repairListLoading}
        activeFiltersDrawer={activeFiltersDrawer}
        setActiveFiltersDrawer={setActiveFiltersDrawer}
        setPageNumber={setPageNumber}
      />
      <AppliedFiltersList />
      <div className="mobile-repair-admin__content-wrapper">
        <RepairsList repairListLoading={repairListLoading} />
      </div>
      <Pagination
        loading={repairListLoading}
        pageSize={pageSize}
        pageNumber={pageNumber}
        listLength={repairsList.length}
        previousAction={() => {
          setPageNumber(pageNumber - 1);
          window.scrollTo(0, 0);
        }}
        continueAction={() => {
          setPageNumber(pageNumber + 1);
          window.scrollTo(0, 0);
        }}
      />
    </div>
  );
};

export default DisplayClientMobile;
