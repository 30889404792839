import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import SelectField from "stories/components/SelectField/SelectField";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import { filterErrorsFromCommonApiErrors, getErrorForInputField } from "utils/global-functions";
import { formatAppliedFiltersForApi, isManageTabsSaveButtonDisabled, sortTabsInAscendingOrder, formatManageTabsBody } from "./utils";

const ManageTabsModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    repair: { tabs, appliedFilters },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});
  const [manageActionLoading, setManageActionLoading] = useState(false);

  const manageTabsFormSchema = Yup.object({
    action: Yup.string().required(t("errors.99")),
    filterName: Yup.string().min(3, t("global.tooShort")).max(50, t("global.tooLong")).required(t("errors.99")),
    filterId: Yup.string().required(t("errors.99")),
  });

  const handleManageTabsSuccess = (response, values) => {
    const { action, filterId, ...rest } = values;

    if (values.action === "add") {
      dispatch({ type: "REPAIR_SET_TABS", tabs: [...tabs, { ...rest, filterId: response.filterId }].sort(sortTabsInAscendingOrder) });
      dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: response.filterId });
    }
    if (values.action === "update") {
      dispatch({
        type: "REPAIR_SET_TABS",
        tabs: [...tabs.filter((tab) => tab.filterId !== values.filterId), { ...rest, filterId: values.filterId }].sort(sortTabsInAscendingOrder),
      });
      dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: values.filterId });
    }

    setActive(false);
  };

  const handleManageTabsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
  };

  const handleManageTabs = (values) => {
    setManageActionLoading(true);
    agent.Repairs.manageTabs({ id: user.id, token: user.token, ...formatManageTabsBody(values), ...formatAppliedFiltersForApi(appliedFilters) })
      .then((response) => handleManageTabsSuccess(response, { ...values, ...appliedFilters }), handleManageTabsError)
      .finally(() => setManageActionLoading(false));
  };

  if (!active) return;

  return (
    <Formik initialValues={{ action: "", filterName: "", filterId: "" }} validationSchema={manageTabsFormSchema}>
      {({ values, setFieldValue, handleBlur, errors, touched }) => (
        <Modal
          title={t("global.manageTabs")}
          footerActions={
            <Stack gap="var(--modal-buttons-gap)">
              <Button size={mobile ? "small" : "default"} disabled={manageActionLoading} onClick={() => setActive(false)}>
                {t("global.cancel")}
              </Button>
              <Button
                size={mobile ? "small" : "default"}
                disabled={isManageTabsSaveButtonDisabled(values)}
                loading={manageActionLoading}
                variant="primary"
                onClick={() => handleManageTabs(values)}
              >
                {t("global.save")}
              </Button>
            </Stack>
          }
          onClose={() => setActive(false)}
        >
          <Form>
            <Stack flexDirection="column" gap="1.5rem">
              <SelectField
                id="action"
                name="action"
                label={t("global.chooseOperationType")}
                placeholder={t("global.chooseFromList")}
                options={[
                  { value: "add", label: t("global.saveAsNew") },
                  { value: "update", label: t("global.editExistingTab") },
                ]}
                isSearchable={false}
                value={values.action}
                onBlur={handleBlur}
                error={getErrorForInputField(errors.action, touched.action) || formErrors.action}
                onChange={(value) => {
                  setFieldValue("action", value);
                  setFieldValue("filterId", "");
                  setFieldValue("filterName", "");
                }}
              />
              {values.action === "update" && (
                <SelectField
                  id="filterId"
                  name="filterId"
                  label={t("global.chooseTab")}
                  placeholder={t("global.chooseFromList")}
                  onBlur={handleBlur}
                  error={getErrorForInputField(errors.filterId, touched.filterId) || formErrors.filterId}
                  value={values.filterId}
                  onChange={(value) => {
                    setFieldValue("filterId", value);
                    setFieldValue("filterName", tabs.find((tab) => tab.filterId === value).filterName);
                  }}
                  options={tabs.map((tab) => {
                    return { value: tab.filterId, label: tab.filterName };
                  })}
                />
              )}
              {(values.action === "add" || values.filterId) && (
                <TextField
                  id="filterName"
                  name="filterName"
                  value={values.filterName}
                  onChange={(value) => setFieldValue("filterName", value)}
                  onBlur={handleBlur}
                  error={getErrorForInputField(errors.filterName, touched.filterName) || formErrors.filterName}
                  label={t("global.filterName")}
                  placeholder={t("global.provideFilterName")}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ManageTabsModal;
