import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AddressDetails = ({ summaryDetails, type }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
  } = useSelector((store) => store);
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });

  return (
    <div className="add-report-summary__address">
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.fullName")}</span>
        <span className="paragraph--small">{summaryDetails[`${type}PersonName`]}</span>
      </div>
      {summaryDetails[`${type}CompanyName`] && (
        <div className="add-report-summary__items-wrapper">
          <span className="paragraph--small text--subdued">{t("global.companyName")}</span>
          <span className="paragraph--small">{summaryDetails[`${type}CompanyName`]}</span>
        </div>
      )}
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.email")}</span>
        <span className="paragraph--small">{summaryDetails[`${type}Email`]}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.phone")}</span>
        <span className="paragraph--small">{summaryDetails[`${type}Phone`]}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.streetAndBuildingNumber")}</span>
        <span className="paragraph--small">{`${summaryDetails[`${type}Street`]} ${summaryDetails[`${type}Building`]}${
          summaryDetails[`${type}Place`] && `/${summaryDetails[`${type}Place`]}`
        }`}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.postalCodeAndCity")}</span>
        <span className="paragraph--small">{`${summaryDetails[`${type}PostCode`]} ${summaryDetails[`${type}City`]}`}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.country")}</span>
        <span className="paragraph--small">{translatedCountryNames.of(summaryDetails[`${type}CountryId`])}</span>
      </div>
      {type === "deliveryAddress" && (
        <div className="add-report-summary__items-wrapper">
          <span className="paragraph--small text--subdued">{t("global.packages")}</span>
          {JSON.parse(summaryDetails.deliveryPackages).map(({ type, quantity }) => (
            <span key={type} className="paragraph--small">{`${constants.packageType[type].strVal} (x${quantity})`}</span>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressDetails;
