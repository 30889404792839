import React from "react";
import { useParams } from "react-router-dom";
import RepairEvents from "routes/RepairEvents/RepairEvents";
import "../commonStyles.css";
import "./styles.css";

const Mobile = () => {
  const { repairId } = useParams();

  return <RepairEvents variant="customer" filterRepairId={repairId} />;
};

export default Mobile;
