import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Formik, Form as FormikForm } from "formik";
import "../Actions.css";
import "./styles.css";
import DisplayDesktop from "./DisplayDesktop";
import DisplayMobile from "./DisplayMobile";

const Menu = ({
  conversationsList,
  conversationTypes,
  setConversationTypes,
  repairNumber,
  setRepairNumber,
  serialNumber,
  setSerialNumber,
  customer,
  setCustomer,
  employeeId,
  setEmployeeId,
  repairState,
  setRepairState,
  dateFrom,
  setDateFrom,
  dateUpTo,
  setDateUpTo,
  error,
  setPageNumber,
}) => {
  const mobile = useMediaQuery({ maxWidth: 850 });

  const [mobileFiltersModalActive, setMobileFiltersModalActive] = useState(false);

  // --- prevent from submit Formik on Enter key on keyboard --- //
  function enterKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        setMobileFiltersModalActive(false);
        conversationsList({ convTypes: conversationTypes, pageNr: 1 });
        setPageNumber(1);
      }}
    >
      {({ handleSubmit }) => (
        <FormikForm onSubmit={handleSubmit} onKeyDown={enterKeyDown}>
          {mobile ? (
            <DisplayMobile
              mobileFiltersModalActive={mobileFiltersModalActive}
              setMobileFiltersModalActive={setMobileFiltersModalActive}
              conversationsList={conversationsList}
              conversationTypes={conversationTypes}
              setConversationTypes={setConversationTypes}
              repairNumber={repairNumber}
              setRepairNumber={setRepairNumber}
              serialNumber={serialNumber}
              setSerialNumber={setSerialNumber}
              customer={customer}
              setCustomer={setCustomer}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
              repairState={repairState}
              setRepairState={setRepairState}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateUpTo={dateUpTo}
              setDateUpTo={setDateUpTo}
              error={error}
              setPageNumber={setPageNumber}
            />
          ) : (
            <DisplayDesktop
              conversationTypes={conversationTypes}
              setConversationTypes={setConversationTypes}
              repairNumber={repairNumber}
              setRepairNumber={setRepairNumber}
              serialNumber={serialNumber}
              setSerialNumber={setSerialNumber}
              customer={customer}
              setCustomer={setCustomer}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
              repairState={repairState}
              setRepairState={setRepairState}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateUpTo={dateUpTo}
              setDateUpTo={setDateUpTo}
              error={error}
              setPageNumber={setPageNumber}
            />
          )}
        </FormikForm>
      )}
    </Formik>
  );
};

export default Menu;
