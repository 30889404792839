import React from "react";
import { getLanguage } from "./utils";
import { pageContent } from "./config";
import "./styles.css";

const Error = () => {
  const userLanguage = getLanguage();

  return (
    <div className="error-page__container">
      <img
        width="110rem"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAGACAYAAACk
              x7W/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQA
              ABf6SURBVHhe7d1rkBxlvcfxnp6Znt3Z3ZnNZaOEEEJUNAZIOFGScA5LkiUEBAFLqQNSHq1I
              jrEoJZrKG7RcYuGlSrfK24tY4FnKg5ciHgotrToxN2APEMUjN7NaxALJjZxA9jK723PtmdO9
              +ZMiyeayu09fZp/vp+qhn3+/maY307/pp7ufjhnwhR2L5YxarUVKNWIxI3HvvRda3/veYVmj
              jB2PDxuO0ySlGu72xj/96QtSDz98RNYoYycStlGpNEqphmka8VtueVfq8cePyhplbMvKG6VS
              g5RquNtrtrfPanjiiTdlDTAupiwBAJohAABAUwQAAGiKAAAATREAAKApAgAANEUAAICmCAAA
              0BQBAACaIgAAQFMEAABoigAAAE0RAACgKQIAADRFAACApggAANAUAQAAmiIAAEBTBAAAaIoA
              AABNEQAAoCkCAAA0RQAAgKYIAADQFAEAAJoiAABAUwQAAGiKAAAATREAAKApAgAANEUAAICm
              CAAA0BQBAACaIgAAQFMEAABoigAAAE0RAACgKQIAADRFAACApggA/0xzW8KHdthtfsi6bazP
              m2w74jY/tLhtrM+bbDvqNj80u22sz5tse9NtAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
              AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACYuJktt2ZlMzcjlpAIQmzHjN/E777zd+tGPSrIK
              U5QpSwAYVTt27BbnF7/YWvriFy1ZhSmKAABwmtEQ+NnPHiUEpjYCAMCY3BC41Q2BraUvfIEQ
              mKIIAABnNHom8POfby3dey8hMAURAADOajQEHnlka2n9ekJgiiEAAJzTaAj86ldbS1/+MiEw
              hXAbKLeBAuPxoBGPr087TlVq1DHOAACMxzrDcbbYpsmxYwrgjwhgvNYZ6fTPuDBc/wgAAOM3
              PHzH6IXhz32OEKhjBACACTlxYfjznycE6hQBAGDCRkPg0Ucf5WGx+kQAAJgUNwRuHZ076Etf
              IgTqDLeBchsooEY8/hOjVvv3dLXKLaJ1gjMAAGo4zmeNanWLHY9zXKkT/KEAqHT8OYFYjGNL
              HeCPBEC1dUYy+R+EQPTxBwKgXqn0aaNW22InkxxjIow/DgC/rDMqFa4JRBh/GAD+qdXWGab5
              k9KGDdwiGkHcBurXbaCplHSACCmVvIOyFMGJzZz5m/hnPnO79d3v8qL5CCEAfAoAs719trly
              5bCUQCRUvv/9LxiDg98IJQSmTXusNjBwe7pW4zmBiCAA/HsQLJM2jCHpA5FQ+OhHjWpPz31h
              hYDrQSOZXJ8ulwmBCCAACABoyG5tDS8EYrEHDctany4WCYGQcREY0FB6YOCbRjb7FfdgLGsC
              5F0YLpW22KkUx5+Q8QcANBWJEGhs5BgUInY+oLHQQ6BY3GI3NXEcCkms1NmZdZdR/gP0W5s3
              S1c9rgEAIV8TMM3jF4a5JhC4WH769GptaCiyF4OT3/72xcmNG/dLqRwBABxnZ7P3ud+F8C4M
              JxLcHRQws1apxIxy2Yhs404lIBDmsmXfNDKZ8IaDvGkjGhoYDgoQOxvAqIZt24z04GC4IeBd
              GM5kOC4FhB0N4CShhkC1us4YGdliT5vGsSkA7GQApwl1OMhx1hm53Ba7tZXjk8/YwQBOE/pw
              kBcCQ0OEgM/YuQDOKPQQ8M4EeE7AN+xYAGcV+jWBfJ4Q8Ak7FcA5jV4TaGkJNwTSaY5XirFD
              AZzT6DWBXC7cECgUuDtIMXYmgPM2GgLNzeGFgHdNgBBQhh0JYFzMD30ovBB4+8Iww0FKsBMB
              jEvD7t1GemgovOEgLwS84aCWFo5fk8QOBDAhoV8TsO0t9syZHMMmgZ0HYMLMJUvCHQ7q7+dh
              sUlgxwGYsBPDQV4IhIGHxSaFnQZg0kINgbefE2AW0XFjhwFQIj08HG4IDA9zi+g4sbMAKGMu
              WhRuCHjDQc3NHNfOEzsKgDINTz8d7pmAd03AuzuI4aDzwk4CoNyJEAjrFlHvpTLcInpO3g6q
              RLyF8IZqAJM1OhzU1BTemcDAwBa7rY0QOIsQ4jla3FPFmpHLSaVUJm0YQ9IHtGU3N99nDA9/
              Q8pgxeMPGi0t69MDA1VZg3cgHQH4KvRrAt6bxbgmMCZ2CgDfxT74wXBDwLtFlBA4DUNADAEB
              gQl1OMg0HzSamtanh4YYDhIkIoDAhP6wmHd3EGcCJ7AjAAQqEk8MM3fQKHYCgMCZl18ebgh4
              D4uZpvbHPwIAQOAann023DOBWm1drLX1v0qdnVofAwkAAKEJMwRqfX23uYsLjld6IgAAhCrU
              4SDNEQAAQhX6cJDGCAAAkUAIBI8AABAZhECwCAAAkUIIBIcAABA5hEAwCAAAkUQI+I/J4JgM
              LhI6OjrMSqUSlxI+aWhoqG3bts170VJdKCxfblRfftm3CeQSnZ1zrM2bD0mpHQKAAIiEbDbb
              mc/n75cSPmlubv55X1/fXVLWDdswBtxF9nilju4BoD0vANx/XH60FvkInAf3l+kz7sJ7/SfN
              x+YG7b+5y7rjfp8GTvl+KWmlzs4L5SO0xDUAhO7KK6/MlMvlq6SET7y5zxYsWPCElAABgPAd
              OnRoteM4jP/7LJlM9u7Zs2e/lAABgPDl8/k10oWPLMvaJV1glGlnMrZtGJFt5a6uubKtmKIq
              lcr10oWP0un0DukCo7wzgMaIN+3vVJrKFi9e/H73DICQ91k8Hi/Pnz+f8X+chCEghOq11167
              zl0Q8j6zLOtPzz777KCUwCgCAKGqVCqM/wcgmUwy/IPTEAAIzYoVK5LFYrFdSviotbV1u3SB
              EwgAhObVV19d7p4BKH+6EyezLGuko6Pjj1ICJxAACE1fX583/g+fJRKJJ7q7u4tSAicQAAiN
              4ziM/wfANM3fSxcBqnR3x/PZbME2jMg2AgChWLp06fRCoXCllPDRRRddtFO6CF4qyo0AQCj2
              799/fa1WS0oJn6RSqcPvec97eqUETkIAIBTDw8OM/wcgkUjs/O1vf+vNBAqchgBAKMrlMtM/
              BKCxsZHhH5wRAYDAXX755QsKhcJFUsInpmnWZs+ezf3/OCMCAIH7xz/+0SFd+MiyrN6XXnrp
              sJTAaQgABK5ard4gXfgolUox/TPOigBAoFauXGmVSqVrpYSPEonENukCY4r5+FJ0JZJdXfOS
              Gze+LqVyvBQ+WBdddNGKAwcO7JYyUKZpHnTPPl6Uckrzxv9vu+22Tz322GPey9Trnl2HL4X3
              HgQrb9hQqQ1GdxJWAoAACFQ6nX7Atu2vSBmobDZ73+Dg4LekRB0hAPzBEBACVavVQhv/nzlz
              JrdEAu9AACAwK1asmFUsFhdLGahEItE3f/78P0sJwEUAIDC9vb0d1Wo1LmWgUqnUM9u3b69I
              CcDlBUAp4o3H2KcI27ZXSzdw3BIJnM5M53KptPv9iGpLbty4X7YVdezGG2/0pn8ILQAymQxP
              xAKnYAgIgThw4MDCYrE4R8pAub/+j95zzz17pQQgCAAE4uDBg6FN/pZMJp/ctGkTQ4nAKQgA
              BKJUKoU2/BOPx3kjFjAGAgC+u/nmmxvcALhGysDNnj07lCePgagjAOC7F1544Z8rlUqzlIFq
              bGw8+JGPfORVKQG8AwEA3/X19YU2/bNpmtu7uroY/0dYChFvevPmArINw4/WIh+hvXQ6/YK7
              8A7Cgbdp06Z9yl2izrnfp4FTvl9KWqmz80L5CC1xBgBfLV++/N2FQuFyKQPl/vo3Fi5c+KSU
              AE5BAMBX+/bt86Z/COXfWSKR6N20aRMPEgJnQADAV/l8PrT7/y3LeurWW2+VCsCpCAD45hOf
              +ETMcZzQAqC5uZnbP4GzIADgm717915WKBTeLWWgTNN0Zs2axfz/wFkQAPDNoUOHwpz98+WX
              XnrpmJQAxkAAwDeVSmWNdAOXSCQY/gHOgQCAL6666qpUqVT6FykD19LSQgAA50AAwBcHDx5s
              d88A0lIGKh6PV+bOnUsAAOdAAMAXQ0NDod39k0ql/nfPnj3DUgI4AwIAvghz/N89A9ghXQBn
              QQBAuSVLllxQLBYXShm4xsZGbv8EzkNMltryJoMzcjmplMqkDWNI+lqZNWvWZ44ePdotZaC8
              +X8sy/p8oVCwZdWUkM1mdw8ODh6QUjvuH3PAXWSPV+okOjvnWJs3H5ISumE2UPWampr+012M
              OTsnbfwtFovVFi1a9F63ry33+8RsoD4w3QOg91Mpsq3c1TVXthV1YO3atWalUrlOSiiQTCb/
              fv/99/9dSkAZ7xpAKuJN+2GqetLT03NFsVgMZfqHqcqyrJ0f+9jHpALU4SIwlHrjjTf49a9Y
              U1MTdzXBFwQAlHIcJ7TbP6eiRCJRvfTSS7mrCb4gAKDMqlWrGsvl8jVSQoF4PP6Hnp6efikB
              pQgAKNPb23ttpVLxrttAkVQqxa9/+IYAgDIjIyOhTf8wVWWzWcb/4RsCAMo4jnODdKGAZVkj
              11577TNSos5Uurvj+Wy2ZBtGZBsBACWWLFkyJ5/Pv19KKBCLxXY98sgjZSlRn5JRbgQAlHj9
              9ddX12o1/j0p1NDQwPAPfMUXFkq4v/5De/3jVDV79mwuAMNXBAAm7aabbjLL5XKHlFDA/fV/
              8OMf/3ivlIAvCABM2l//+tfFpVJplpRQ4/cPPPCANxkc4BsCAJP21ltv8fSvYt70z9IFfEMA
              YNLK5TLj/wqZplm95JJLuAAM3xEAmJQ1a9Y0lUql5VJCAcuyXt6zZ88RKQHfEACYlN7e3lWO
              4zRICQXcM4Bt0gV8RQBgUvr7+7n7R7Hp06fvki7gKwIAk1KtVpn+QaFEIlFcsmTJU1ICviIA
              MGGLFi2am8/nL5USCiSTyZ5f//rXeSkBX8W8l6IbuZyU0ZPs6pqX3LjxdSmV8/H/P5M2jCHp
              T0nTp0//bF9f30NSBu7iiy/euHLlyt9IOSXEYrHB7u7uN6WEsA1jwF1kj1fqJDo751ibNx+S
              UilvMrjyhg2V2uCgrIkeLwAKEQ+AS90A2C+lcgTAxKXT6V/atv2vUgbKPVAaV1999bynn37a
              tx8HiA4CwB9mOpdrcA9UkW1+HvwxcbfffnvccZzQ3v+bSqX+/slPfpKDPzAJXAPAhDz33HNX
              FovFGVKGYcc999wjXQATQQBgQo4dOxbq27+am5u5UwaYJAIAE1KpVEILANM0awsWLHhSSgAT
              RABg3G666aZMmNM/WJbV29PTc1hKABNEAGDcXnzxxZWO41hSBi4ej/OkLKAAAYBx6+/vD+3u
              H08qlSIAUC+8h/qi3PTmPQdgG4YfrUU+YsppbGx8xV14LysJvJmmWWlvb5/p9qER9/s0cMr3
              S0krdXZeKB+hJc4AMC5XXHHFvEKh8F4pA5dOp3ufeuqpt6QEMAkEAMbl8OHDa2q1WkzKwDmO
              89/SBTBJBADGZWRkJNTpnzOZDLd/AooQADhva9euTVYqlVVSBi4ejzsLFy7skRLAJBEAOG87
              duz4p3K5HNr0D6lU6s+7du2K7syFQJ0hAHDejh07FurL3xOJxE7pAlCAAMB43CjLUCSTyR3S
              BaAAAYDzsnTp0kyhUPiwlIHzXpW4bNmyPVICUIAAwHk5cODAdY7jJKUMXCqV+tPvfve7ESkB
              KEAA4LzkcrlQp39IJpNM/wAoRgDgvLi//tdINxSNjY2M/wOKEQA4p8suu2x+Pp+fL2XgEonE
              yCWXXPIHKQEoQgDgnLzpH6QbCsuy9jzzzDNFKQEoQgDgnNxf/6GO/6fTaV7/CPjAtDOZom0Y
              kW3lrq65sq0Iwc0335wIc/oHj2maTAAH+MA7A/De7BTlFtrMkzCM559//sPlcrlVysAlk8nc
              Lbfc8ryUABRiCAhnlcvlQh3/dwPgfx566KGylAAUIgBwVtVq9QbphsKyrCekC0AxAgBn1N7e
              Pr1QKCyRMhRtbW08AAb4hADAGe3bt2+V4zgJKQOXTCaPtba2/llKAIoRADijwcHBsKd/6Hnu
              uee8l8ED8EHMzmRqRi6679hIdnXNS27c+LqUyvn4/59JG8aQ9OvO1772tdh3vvOdV/P5/DxZ
              Fbi2trZfzpgx43Epp7x4PP7G3r17eeZhDLZhDLiL7PFKnURn5xxr8+ZDUipV6e6OlzdsKNYG
              B2VN9BAABMCYPvCBD7z3b3/72z4pEYCWlpYfDA0N3Ssl3qGOA6AS5QBgCAhjOnLkSKi3f+qo
              qamJCe8QKAIAYyoWi6GO/+smkUiU3/e+93HLKwJFAOA0d9xxR9JxnA4pEQA3APb09PTU7ZAh
              6hMBgNM8+eSTS0ulUouUCEBjY+Nu6QKBIQBwmnw+H+rL33XkBgAT3iFwBABO4/76Xy1dBCCV
              Sg3ffffdf5ISCAwBgJNcc801MwuFwpVSIgCmae76+te/zoR3CBwBgJO88sorHdVqNbTpH3Rk
              WdZ26QKBIgBwklwux90/AWtrayMAEAoCACfcfffdsVqtxgNgAWpoaDjY0dHxipRAoAgAnNDT
              03NpoVDgFZwBisVi23/84x8z4R1CQQDghDfffJNf/wHLZDI8/YvQeAGQj3jj11FAbNtm+ocA
              maZZbWtr2yYlEDgzncul04YR2ZbcuHG/bCt8tHbt2lSlUlkhJQKQSqV6//KXv/yflEDgGALC
              qJ07d17tBgDTPwQokUgw+ydCRQBgVH9///XSRUDS6fTvpQuEggDAKPfXP9M/BCgejxfb29uf
              lhIIBQEAY/Hixd70D4ulRABSqdQft27dGt1X8UELBACMw4cPr65Wq3EpEYBYLMbsnwgdAQBj
              ZGSE4Z+AzZgxY5d0MZUlEjXDsrwJnyLZYrKZ2uKl8N6/A+vWUqk0U0r4LB6PG3fdddfDP/3p
              Tx1ZhXOox5fCe0qdnd42R/Y4SwAQAEDk1WsARB1DQACgKQIAADRFAACApggAANAUAQAAmiIA
              AEBTBAAAaIoAAABNEQAAoCkCAAA0RQAAgKYIAADQFAEAAJoy7UymbBtGZFu5q2uubCsAQCHv
              DCAR8ab9lNUA4AeGgABAUwQAAGiKAAAATREAAKApAgAANEUAAIAPKt3d8Xw269iGEdlGAACA
              f7xjbGSb9x8AgIYIAADQFAEAAJoiAABAUwQAAGiKAAAATREAAKApAgAANEUAAICmCAAA0BQB
              AACaIgAAQFNmLJGoGcmkEdlmGLXRLQUAKBUrdXZm3WWUzwT6rc2bpauencnUjFxOKqUyacMY
              kj6ASbANY8BdeMcqpRKdnXPc48shKZXypoMub9hQqQ0OyproiclSWwQAEH0EgD+4BgAAmiIA
              AEBTBAAAaIoAAABNcRHYp4vAZnv7bHPlymEpAUxCZfPmg+4ic7xSx/eLwJs2lWu5XGSPswSA
              X3cBpVLSATBpxaJ01PIzADylzs4WdxHZkRYCwL/bQAFEnN8BEHVcAwAATREAAKApAgAANEUA
              AICmCAAA0BQBAACaIgAAQFMEAABoigAAAE0RAACgKQIAADRFAACApggAANAUAQAAmiIAAEBT
              BAAAaIoAAABNEQAAoCkCAAA0RQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
              AAAAAAAAAAAAAAAAAAAAAAAAAHQWkyUCVFi1Km5Uq1IpEosZ8Y4OJ/nVr8oKdQrXXRc3KhWp
              FHG311y2zLG+9S1ZoU5h9eq4US5LpYi3vZdd5lg//KGsUKdw/fVxo1SSShF3e2Pz5jmp7m5Z
              oU5hzZq4USxKpYi3vTNnOqmtW2UFgkAAhMBubBw2HKdJSjW8ALjzzgtSDz98RNYoYzc12e4B
              tVFKNUzTMG+44V0Njz9+VNYoY7e0FNwDVEpKNbztXb68rWH37rdkjTJ2JlM0CgVLSjXc7Y0t
              WDCj8fnn+2SNMvnW1nLNthNSquEFwOzZ0xtfe61f1iAApiwRJO/Xnh9N9a/0t431WSpavW2v
              6rOKt431WSqaT9tbG+uzVDS/9i/OiAAAAE0RAACgKQIAADRFAACApggAANAUAQAAmiIAAEBT
              BAAAaIoAAABNEQAAoCkCAAA0RQAAgKYIAADQFAEAAJoiAABAUwQAAGiKAAAATREAAKApAgAA
              NEUAAICmCAAA0BQBAACaIgAAQFMEAABoigAAAE0RAACgKQIAADRFAACApggAANAUAQAAmiIA
              AEBTBAAAaIoAAABNEQAAoCkCAAA0RQAAgKYIAADQFAEAAFoyjP8HHRJLV7ayBjUAAAAASUVO
              RK5CYII="
        alt=""
      />
      <div className="error-page__content-wrapper">
        <h2 className="error-page__heading">{pageContent[userLanguage].heading}</h2>
        <p className="error-page__info">{pageContent[userLanguage].info}</p>
        <button
          className="error-page__button"
          onClick={() => {
            window.location.pathname = "/";
          }}
        >
          {pageContent[userLanguage].buttonContent}
        </button>
      </div>
    </div>
  );
};

export default Error;
