import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import Stack from "stories/components/Stack/Stack";
import { formatBytes, isEmployee } from "utils/global-functions";
import { isAbleToModifyAttachment } from "../utils";

const AttachemntsAccordion = ({ attachments, setSelectedAttachmentId, setAttachmentsModalActive, setAttachmentDeleteModalActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  if (!attachments?.length) return;

  return (
    <Accordion
      content={attachments.map((attachment) => {
        return {
          title: (
            <div className={`mobile-attachments-accordion__header ${attachment.visibilityFlag ? "" : "mobile-attachment__item--hidden"}`}>
              <div className="mobile-attachments-accordion__item">
                <span className="paragraph--small text--subdued">{t("global.id")}:</span>
                <span className="paragraph--small">{attachment.id}</span>
              </div>
              <span className="paragraph--small">{attachment.createdAt}</span>
            </div>
          ),
          body: (
            <div className={`mobile-attachments-accordion__body ${attachment.visibilityFlag ? "" : "mobile-attachment__item--hidden"}`}>
              <div className="mobile-attachments-accordion__item">
                <span className="paragraph--small text--subdued">{t("global.file")}:</span>
                <span className="paragraph--small">{attachment.fileName}</span>
              </div>
              <div className="mobile-attachments-accordion__item">
                <span className="paragraph--small text--subdued">{t("global.type")}:</span>
                <Stack flexWrap="wrap" gap="1rem">
                  <span className="paragraph--small">{attachment.fileType}</span>
                  <span className="paragraph--small text--nowrap">{`(${formatBytes(attachment.fileSize)})`}</span>
                </Stack>
              </div>
              {isEmployee(user) && (
                <>
                  <div className="mobile-attachments-accordion__item">
                    <span className="paragraph--small text--subdued">{t("global.visible")}:</span>
                    <span className="paragraph--small">{t(`global.${!!attachment.visibilityFlag}`)}</span>
                  </div>
                  <div className="mobile-attachments-accordion__item">
                    <span className="paragraph--small text--subdued">{t("global.author")}:</span>
                    <span className="paragraph--small">{attachment.authorName || "-"}</span>
                  </div>
                </>
              )}
              {!!attachment.commentText && (
                <div className="mobile-attachments-accordion__item">
                  <span className="paragraph--small text--subdued">{t("global.comment")}:</span>
                  <span className="paragraph--small">{attachment.commentText}</span>
                </div>
              )}
              <div className="mobile-attachments-accordion__buttons-wrapper">
                <a className="unset-all custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                  {t("global.preview")}
                </a>
                {isAbleToModifyAttachment(user, attachment) && (
                  <>
                    <button
                      className="unset-all custom-link--black"
                      onClick={() => {
                        setAttachmentsModalActive(true);
                        setSelectedAttachmentId(attachment.id);
                      }}
                    >
                      {t("global.edit")}
                    </button>
                    <button
                      className="unset-all custom-link"
                      onClick={() => {
                        setAttachmentDeleteModalActive(true);
                        setSelectedAttachmentId(attachment.id);
                      }}
                    >
                      {t("global.delete")}
                    </button>
                  </>
                )}
              </div>
            </div>
          ),
        };
      })}
    />
  );
};

export default AttachemntsAccordion;
