import React from "react";

const AdditionalInformationItem = ({ children, attachment }) => {
  const icons = {
    pdf: (
      <svg className="serial-number-file-icon" width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_24_100)">
          <g clipPath="url(#clip1_24_100)">
            <path
              d="M13.27 1H3C1.9 1 1 1.9 1 3V29C1 30.1 1.9 31 3 31H25C26.1 31 27 30.1 27 29V12.93C27 12.34 26.74 11.78 26.29 11.4L14.56 1.47C14.2 1.16 13.74 1 13.27 1Z"
              stroke="#7E7F7F"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path d="M27 12H16C14.9 12 14 11.1 14 10V1" stroke="#7E7F7F" strokeWidth="2" strokeLinejoin="round" />
            <path d="M5 25V18H7C8.1 18 9 18.9 9 20C9 21.1 8.1 22 7 22H5" stroke="#7E7F7F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M12 25V18H14C15.1 18 16 18.9 16 20V23C16 24.1 15.1 25 14 25H12Z"
              stroke="#7E7F7F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M22 22H19" stroke="#7E7F7F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 25V18H23" stroke="#7E7F7F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_24_100">
            <rect width="28" height="32" fill="white" />
          </clipPath>
          <clipPath id="clip1_24_100">
            <rect width="28" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    web: (
      <svg className="serial-number-file-icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_24_106)">
          <path
            d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
            stroke="#7E7F7F"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M15.5 31C19.6421 31 23 24.2843 23 16C23 7.71573 19.6421 1 15.5 1C11.3579 1 8 7.71573 8 16C8 24.2843 11.3579 31 15.5 31Z"
            stroke="#7E7F7F"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path d="M1 16H31" stroke="#7E7F7F" strokeWidth="2" strokeLinejoin="round" />
          <path d="M29 24H3" stroke="#7E7F7F" strokeWidth="2" strokeLinejoin="round" />
          <path d="M3 9H29" stroke="#7E7F7F" strokeWidth="2" strokeLinejoin="round" />
          <path d="M16 1V31" stroke="#7E7F7F" strokeWidth="2" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_24_106">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  return (
    <li className="serial-number__additional-info-item">
      <a className="custom-link--black serial-number__additional-info-link" href={attachment.url_for_attachments} target="_blank" rel="noreferrer">
        {icons[attachment.extension === "pdf" ? "pdf" : "web"]}
        <span className="serial-number__additional-info-item-contnet">{children}</span>
      </a>
    </li>
  );
};

export default AdditionalInformationItem;
