/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import { ADDITIONAL_PACKAGES_TO_LIMIT, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import PageSpinner from "components/PageSpinner/PageSpinner";
import DisplayMobile from "./DisplayMobile";
import DisplayDesktop from "./DisplayDesktop";
import { getBodyForAccountInfo, getKeyForFormErrors, getRegistrationShipmentBody } from "./utils";
import { DELIVERY_TYPE_TO_BE_AGREED, RETURN_TYPE_TO_BE_AGREED } from "utils/config";
import StreetWarningModal from "./StreetWarningModal";
import PackageModal from "./PackageModal";
import "./styles.css";

export const AddReportDelivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [formErrors, setFormErrors] = useState({});
  const [deliveryProperties, setDeliveryProperties] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [sendShipmentLoading, setSendShipmentLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user);
  const [packageQuantityLimit, setpackageQuantityLimit] = useState(ADDITIONAL_PACKAGES_TO_LIMIT);
  const [packageModalActive, setPackageModalActive] = useState(false);
  const [streetWarningModalActive, setStreetWarningModalActive] = useState(false);
  const phoneNumberRegExp = new RegExp("^[0-9]{9}$|^[0-9]{3}-[0-9]{3}-[0-9]{3}$|^[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}$");
  const [productFullListIncludesUR, setProductFullListIncludesUR] = useState(false);

  const getRegistrationShipmentSuccess = (response) => {
    return setDeliveryProperties(response.data.at(0));
  };

  const getRegistrationShipmentError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    return filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRegistrationShipment = () => {
    setPageLoading(true);

    return agent.Registration.registrationShipmentGet({ id: user.id, token: user.token, registrationId: registration.registrationId }).then(
      getRegistrationShipmentSuccess,
      getRegistrationShipmentError,
    );
  };

  const getProductListSuccess = (response) => {
    setpackageQuantityLimit(response.recordCount + ADDITIONAL_PACKAGES_TO_LIMIT);

    if (response.data.filter((item) => item.producerId === 34).length) setProductFullListIncludesUR(true);

    return response;
  };

  const getProductListError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);

    return filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getProductList = () =>
    agent.Registration.registrationProductList({ id: user.id, token: user.token, registrationId: registration.registrationId }).then(
      getProductListSuccess,
      getProductListError,
    );

  const getUserInfoSuccess = (response) => {
    setSelectedUser(response.data.at(0));

    if (isAdmin(user) || isReception(user)) return;
    return dispatch({ type: "USER_LOGIN", ...response.data.at(0), id: user.id, token: user.token });
  };

  const getUserInfoError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);

    return filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getUserInfo = () => agent.Users.getUser(getBodyForAccountInfo(user, registration)).then(getUserInfoSuccess, getUserInfoError);

  const getAllResources = () => {
    setPageLoading(true);

    return Promise.all([getRegistrationShipment(), getProductList(), getUserInfo()]).finally(() => setPageLoading(false));
  };

  useEffect(() => {
    getAllResources();
  }, []);

  const registrationShipmentSetSuccess = (response) => {
    if (response.result === 301) {
      setFormErrors({});
      return dispatch({ type: "REGISTRATION_SETSTEP", step: 3 });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const registrationShipmentSetError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    setPackageModalActive(error.response.data.messages.some((message) => message.property === "SUM(paramDeliveryPackages.quantity)"));
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(
      Object.fromEntries(
        filteredErrors
          .filter((message) => !!message.property && message.property !== "SUM(paramDeliveryPackages.quantity)")
          .map((message) => [getKeyForFormErrors(message), t(`errors.${message.code}`)]),
      ),
    );

    return filteredErrors
      .filter((message) => !message.property)
      .forEach((message) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${message.code}`),
          },
        }),
      );
  };

  const registrationShipmentSet = (body) => {
    setSendShipmentLoading(true);

    return agent.Registration.registrationShipmentSet({
      id: user.id,
      token: user.token,
      registrationId: registration.registrationId,
      ...getRegistrationShipmentBody(body),
    })
      .then(registrationShipmentSetSuccess, registrationShipmentSetError)
      .finally(() => setSendShipmentLoading(false));
  };

  if (pageLoading) return <PageSpinner subPage />;

  const validationSchema = Yup.object().shape({
    deliveryAddressPersonName: Yup.string().max(50, t("errors.102")).required(t("errors.99")),
    deliveryAddressCompanyName: Yup.string().max(255, t("errors.102")),
    deliveryAddressPhone: Yup.string()
      .required(t("errors.99"))
      .min(9, t("errors.101"))
      .max(12, t("errors.102"))
      .test("phoneNumber", t("errors.111"), (value) => value?.match(phoneNumberRegExp)),
    deliveryAddressEmail: Yup.string().max(255, t("errors.102")).email(t("errors.104")).required(t("errors.99")),
    deliveryAddressStreet: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    deliveryAddressBuilding: Yup.string().min(1, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    deliveryAddressPlace: Yup.string().max(20, t("errors.102")),
    deliveryAddressPostCode: Yup.string().min(3, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    deliveryAddressCity: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    deliveryAddressCountryId: Yup.mixed().required(t("errors.99")),
    returnAddressPersonName: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    returnAddressCompanyName: Yup.string().max(255, t("errors.102")),
    returnAddressPhone: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    returnAddressEmail: Yup.string().max(255, t("errors.102")).email(t("errors.104")).required(t("errors.99")),
    returnAddressStreet: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    returnAddressBuilding: Yup.string().min(1, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    returnAddressPlace: Yup.string().max(20, t("errors.102")),
    returnAddressPostCode: Yup.string().min(3, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    returnAddressCity: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    returnAddressCountryId: Yup.mixed().required(t("errors.99")),
    deliveryPackages: Yup.array(
      Yup.object({ type: Yup.number().required(t("errors.99")), quantity: Yup.number().positive(t("errors.105")).required(t("errors.99")) }),
    ),
  });

  return (
    <Formik
      initialValues={{
        deliveryType: productFullListIncludesUR
          ? DELIVERY_TYPE_TO_BE_AGREED
          : deliveryProperties?.deliveryType !== DELIVERY_TYPE_TO_BE_AGREED
          ? deliveryProperties?.deliveryType
          : null,
        deliveryPackages: deliveryProperties?.deliveryPackages ? JSON.parse(deliveryProperties.deliveryPackages) : [{ type: "", quantity: 1 }],
        deliveryAddressPersonName: deliveryProperties?.deliveryAddressPersonName ?? "",
        deliveryAddressCompanyName: deliveryProperties?.deliveryAddressCompanyName ?? "",
        deliveryAddressPhone: deliveryProperties?.deliveryAddressPhone ?? "",
        deliveryAddressEmail: deliveryProperties?.deliveryAddressEmail ?? "",
        deliveryAddressStreet: deliveryProperties?.deliveryAddressStreet ?? "",
        deliveryAddressBuilding: deliveryProperties?.deliveryAddressBuilding ?? "",
        deliveryAddressPlace: deliveryProperties?.deliveryAddressPlace ?? "",
        deliveryAddressPostCode: deliveryProperties?.deliveryAddressPostCode ?? "",
        deliveryAddressCity: deliveryProperties?.deliveryAddressCity ?? "",
        deliveryAddressCountryId: "PL",
        deliveryFromInvoice: false,
        returnType: productFullListIncludesUR
          ? RETURN_TYPE_TO_BE_AGREED
          : deliveryProperties?.returnType !== RETURN_TYPE_TO_BE_AGREED
          ? deliveryProperties?.returnType
          : null,
        returnAddressPersonName: deliveryProperties?.returnAddressPersonName ?? "",
        returnAddressCompanyName: deliveryProperties?.returnAddressCompanyName ?? "",
        returnAddressPhone: deliveryProperties?.returnAddressPhone ?? "",
        returnAddressEmail: deliveryProperties?.returnAddressEmail ?? "",
        returnAddressStreet: deliveryProperties?.returnAddressStreet ?? "",
        returnAddressBuilding: deliveryProperties?.returnAddressBuilding ?? "",
        returnAddressPlace: deliveryProperties?.returnAddressPlace ?? "",
        returnAddressPostCode: deliveryProperties?.returnAddressPostCode ?? "",
        returnAddressCity: deliveryProperties?.returnAddressCity ?? "",
        returnAddressCountryId: deliveryProperties?.returnAddressCountryId ?? "",
        returnFromInvoice: false,
        returnFromDelivery: false,
      }}
      validationSchema={validationSchema}
    >
      {({ touched, errors, handleBlur, values, setFieldValue, setFieldTouched }) => (
        <Form style={{ height: "100%" }}>
          {mobile ? (
            <DisplayMobile
              registrationShipmentSet={registrationShipmentSet}
              formErrors={formErrors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              sendShipmentLoading={sendShipmentLoading}
              selectedUser={selectedUser}
              setFieldTouched={setFieldTouched}
              packageQuantityLimit={packageQuantityLimit}
              setStreetWarningModalActive={setStreetWarningModalActive}
              productFullListIncludesUR={productFullListIncludesUR}
            />
          ) : (
            <DisplayDesktop
              registrationShipmentSet={registrationShipmentSet}
              formErrors={formErrors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              sendShipmentLoading={sendShipmentLoading}
              selectedUser={selectedUser}
              setFieldTouched={setFieldTouched}
              packageQuantityLimit={packageQuantityLimit}
              setStreetWarningModalActive={setStreetWarningModalActive}
              productFullListIncludesUR={productFullListIncludesUR}
            />
          )}
          <PackageModal active={packageModalActive} setActive={setPackageModalActive} packageLimit={packageQuantityLimit} />
          <StreetWarningModal active={streetWarningModalActive} setActive={setStreetWarningModalActive} />
        </Form>
      )}
    </Formik>
  );
};
