import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { writeCookie, isEmployee } from "utils/global-functions";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import Button from "stories/components/Button/Button";
import Chip from "stories/components/Chip/Chip";
import Drawer from "stories/components/Drawer/Drawer";
import Stack from "stories/components/Stack/Stack";
import { columnsWithSelectableOptions, columnsWithYesNoOptions, defaultPageSizeCookieDaysExpire, repairsPageSizeOptions } from "../config";
import Filters from "../Filters";
import { formatAppliedFiltersForApi } from "../utils";

const TopActions = ({ setActiveManageTabsModal, setDeleteTabModal, repairListLoading, setActiveFiltersDrawer, activeFiltersDrawer, setPageNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    repair: { appliedFilters, tabs, pageSize },
    app: { constants },
  } = useSelector((store) => store);

  const formatTabValue = (key, value) => {
    if (columnsWithYesNoOptions.includes(key)) return t(`global.${!!value}`);

    return columnsWithSelectableOptions.includes(key) ? t(`constants.${key.replace(/([A-Z])/, ".$1").toLowerCase()}.${value}`) : value;
  };

  const handlePageSizeChange = (value) => {
    writeCookie({ key: "repairsPageSize", value: value, day: defaultPageSizeCookieDaysExpire });
    dispatch({ type: "REPAIR_SET_PAGESIZE", pageSize: value });
  };

  return (
    <Stack justifyContent="space-between">
      <Stack alignItems="center" gap="0.5rem" flexWrap="wrap">
        {Object.entries(formatAppliedFiltersForApi(appliedFilters, constants)).map(([key, value]) => (
          <Chip
            key={key}
            onClick={() => {
              if (!repairListLoading) dispatch({ type: "REPAIR_CLEAR_APPLIEDFILTER", filterName: key });
            }}
          >
            {`${t(`myRepairs.${key}`)}: ${formatTabValue(key, value)}`}
          </Chip>
        ))}
      </Stack>
      <div className="admin-repairs-top-actions__buttons-wrapper">
        <div className="admin-repairs-top-actions__page-size-wrapper">
          <PageSizeSelector selectedPageSize={pageSize} onChange={handlePageSizeChange} pageSizeOptions={repairsPageSizeOptions} />
        </div>
        {isEmployee(user) && (
          <Button onClick={() => setDeleteTabModal(true)} disabled={tabs.length === 0 || repairListLoading} variant="error" outline>
            {t("global.deleteTab")}
          </Button>
        )}
        <Drawer
          title={t("global.filters")}
          active={activeFiltersDrawer}
          setActive={setActiveFiltersDrawer}
          activator={
            <Button id="repair_filters_activator" disabled={repairListLoading} variant="primary" onClick={() => setActiveFiltersDrawer(!activeFiltersDrawer)}>
              {t("global.filter")}
            </Button>
          }
        >
          <Filters
            setActiveFiltersDrawer={setActiveFiltersDrawer}
            repairListLoading={repairListLoading}
            setActiveManageTabsModal={setActiveManageTabsModal}
            setPageNumber={setPageNumber}
          />
        </Drawer>
      </div>
    </Stack>
  );
};

export default TopActions;
