import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import TextField from "stories/components/TextField/TextField";
import Stack from "stories/components/Stack/Stack";
import { convertNullToEmptyString } from "../utils";

const RepairDescription = () => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [description, setDescription] = useState(repairDetails.repairDescription || "");
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRepairDescriptionSuccess = (response, repairDescription) => {
    if (response.result === 301) {
      return dispatch({ type: "REPAIR_SET_REPAIR_DETAILS", repairDetails: { ...repairDetails, repairDescription } });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairDescriptionError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    if (filteredErrors.some((filteredError) => filteredError.code === 302)) navigate("/");
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const setRepairDescription = (text) => {
    setDescriptionLoading(true);
    agent.Repairs.setRepairComment({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      commentType: "repairDescription",
      commentText: text,
    })
      .then((response) => setRepairDescriptionSuccess(response, text), setRepairDescriptionError)
      .then(() => setDescriptionLoading(false));
  };

  return (
    <div className="repair-info-description">
      <Stack flexDirection="column" gap="0.5rem">
        <TextField
          label={t("global.descriptionOrJustification")}
          value={description}
          onChange={setDescription}
          variant="multi"
          placeholder={t("global.writeDescriptionOrJustification")}
          numberOfLines={6}
          disabled={!isAdmin(user) && !isReception(user) && user.id !== repairDetails.technicianId}
        />
        <Stack gap="var(--modal-buttons-gap)">
          <Button
            variant="primary"
            loading={descriptionLoading}
            disabled={convertNullToEmptyString(repairDetails.repairDescription) === description}
            onClick={() => setRepairDescription(description)}
          >
            {t("global.save")}
          </Button>
          <Button
            disabled={descriptionLoading || convertNullToEmptyString(repairDetails.repairDescription) === description}
            onClick={() => setDescription(repairDetails.repairDescription)}
          >
            {t("global.cancel")}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default RepairDescription;
