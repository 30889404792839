/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import i18n from "i18n";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { getLanguageFromCookie, setDocumentTitle } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";

const Login = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  useEffect(() => {
    i18n.changeLanguage(getLanguageFromCookie());
    document.documentElement.setAttribute("lang", getLanguageFromCookie());

    setDocumentTitle(t);
  }, []);

  if (mobile) return <Mobile />;

  return <Desktop />;
};

export default Login;
