export const getMessageBoxClasses = (options) => {
  const classes = [];

  if (options.response) classes.push("message-box--response-message");
  if (options.ownMessage) classes.push("message-box--own-message");
  if (options.fullWidth) classes.push("message-box--full-width");
  if (options.internalComment) classes.push("message-box--internal-comment");

  return classes.join(" ");
};
