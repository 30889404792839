import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import AddAttachmentsForm from "./AttachmentsForm";
import { getAttachmentRequestBody } from "./utils";

const AttachmentsModal = ({
  active,
  setActive,
  selectedAttachmentId,
  attachmentList,
  refreshAttachments,
  setRefreshAttachments,
  setSelectedAttachmentId,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const { repairId } = useParams();
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});

  const attachmentToEdit = selectedAttachmentId ? attachmentList.find(({ id }) => id === selectedAttachmentId) : null;

  const handleAttachmentsSuccess = (response) => {
    if (response.result === 301) {
      setActive(false);
      setRefreshAttachments(!refreshAttachments);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const handleAttachmentsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const handleAttachments = ({ attachments, ...values }) =>
    agent.Repairs.handleRepairAttachments({
      id: user.id,
      token: user.token,
      ...values,
      ...getAttachmentRequestBody(attachmentToEdit, repairId, attachments),
    }).then(handleAttachmentsSuccess, handleAttachmentsError);

  const validationSchema = Yup.object().shape({
    commentText: Yup.string().max(2048, t("errors.102")),
  });

  if (!active) return;

  return (
    <Formik
      initialValues={{ commentText: attachmentToEdit?.commentText ?? "", visibilityFlag: attachmentToEdit?.visibilityFlag ?? 1, attachments: [] }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleAttachments(values)}
    >
      {({ values, setFieldValue, isSubmitting, handleBlur, touched, errors, isValid }) => (
        <Form>
          <Modal
            title={selectedAttachmentId ? t("global.editAttachment") : t("global.addAttachments")}
            footerActions={
              <Stack gap="var(--modal-buttons-gap)">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={isSubmitting}
                  onClick={() => {
                    setSelectedAttachmentId(null);
                    setActive(false);
                  }}
                  variant="default"
                >
                  {t("global.cancel")}
                </Button>
                <Button size={mobile ? "small" : "default"} disabled={!isValid} type="submit" variant="primary" loading={isSubmitting}>
                  {t("global.save")}
                </Button>
              </Stack>
            }
            onClose={() => {
              setSelectedAttachmentId(null);
              setActive(false);
            }}
          >
            <AddAttachmentsForm
              values={values}
              setFieldValue={setFieldValue}
              attachmentToEdit={attachmentToEdit}
              formErrors={formErrors}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AttachmentsModal;
