import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { UnoErmaSnCheck } from "components/Utils/UnoErmaSnCheck";
import Button from "stories/components/Button/Button";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { showCheckProductButton } from "./utils";

const CheckProduct = ({ values, serialNotFound, validProductCheck, setValidProductCheck }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [checkResultModalActive, setCheckResultModalActive] = useState(false);
  const dispatch = useDispatch();
  const [checkErrors, setCheckErrors] = useState([]);

  return (
    <>
      {showCheckProductButton(values, serialNotFound, validProductCheck) && (
        <Button size={mobile ? "small" : "default"} onClick={() => setCheckResultModalActive(true)}>
          {t("global.check")}
        </Button>
      )}
      {checkResultModalActive && (
        <UnoErmaSnCheck
          sn={values.searchSerialNumber}
          onClose={() => {
            setCheckResultModalActive(false);
            setValidProductCheck(checkErrors?.length === 0);
          }}
          setSnErrorsCallback={(data) => {
            if (data?.length)
              data.forEach((value) =>
                dispatch({
                  type: "APP_PUSHNOTIFICATION",
                  notification: {
                    type: "danger",
                    description: value,
                  },
                }),
              );
            setCheckErrors(data);
          }}
        />
      )}
    </>
  );
};

export default CheckProduct;
