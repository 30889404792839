import React from "react";
import CardActionButtons from "./CardActionButtons";
import CardBackButton from "./CardBackButton";
import CardTitle from "./CardTitle";
import Navigation from "./Navigation";
import "./styles.css";

const Card = ({ children }) => <div className="custom-mobile-card">{children}</div>;

Card.Navigation = Navigation;
Card.BackButton = CardBackButton;
Card.ActionButtons = CardActionButtons;
Card.Title = CardTitle;

export default Card;
