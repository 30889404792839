import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import QRScanner from "components/QRScanner/QRSscanner";
import lodash from "lodash";
import logo from "images/logo.svg";
import search from "images/search.png";
import dropdown from "images/Dropdown.svg";
import close from "images/Close.svg";
import { Account } from "./AccountNav";
import { PanelMobile } from "./LeftPanel";
import { LangSwitchMobile } from "./LangSwitch";
import { pathWithoutTopBar } from "./config";
import "./styles/Navbar.css";

export const Navbar = ({
  logoutCallback,
  handleSearchWarranty,
  searchWarrantyInput,
  setSearchWarrantyInput,
  warrantySearchLoading,
  textFieldSearchWarranty,
}) => {
  const { user } = useSelector((store) => store);
  const { t } = useTranslation();

  if (lodash.isEmpty(user)) return;

  if (pathWithoutTopBar.some((path) => window.location.pathname.startsWith(path))) return;

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container-fluid">
          <div className="navbar__content">
            <NavLink to="/" className="navbar-brand no-select">
              <img src={logo} alt="Elmark_logo" />
            </NavLink>
            <div className="navbar__searchbar">
              <div className="searchbar-div">
                <input
                  maxLength={50}
                  className="searchbar"
                  ref={textFieldSearchWarranty}
                  disabled={warrantySearchLoading}
                  value={searchWarrantyInput}
                  onChange={(event) => setSearchWarrantyInput(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.code === "Enter") handleSearchWarranty(searchWarrantyInput);
                  }}
                  type="search"
                  placeholder={t("searchbar.placeholder")}
                  aria-label="Search"
                />
                <button disabled={warrantySearchLoading} onClick={() => handleSearchWarranty(searchWarrantyInput)} className="search-btn">
                  {warrantySearchLoading ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ width: "2rem", height: "2rem", borderWidth: "0.25rem", color: "var(--primary-color)" }}
                    />
                  ) : (
                    <img src={search} alt="search" className="search-img" />
                  )}
                </button>
              </div>
              <QRScanner
                setResultValue={(value) => {
                  setSearchWarrantyInput(value);
                  handleSearchWarranty(value);
                }}
              />
            </div>
            <Account logoutCallback={logoutCallback} />
          </div>
        </div>
      </nav>
      <div className="navbar__buffor" />
    </>
  );
};

export const NavbarMobile = ({
  logoutCallback,
  handleSearchWarranty,
  searchWarrantyInput,
  setSearchWarrantyInput,
  warrantySearchLoading,
  textFieldSearchWarranty,
}) => {
  const {
    user,
    registration: { registrationStep },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const location = useLocation();

  if (lodash.isEmpty(user)) return;

  if (pathWithoutTopBar.some((path) => window.location.pathname.startsWith(path))) return;

  return (
    <div className="container-fluid">
      <nav className={isOpen ? "navbar-mobile-open navbar fixed-top navbar-expand-lg" : "navbar-mobile navbar fixed-top navbar-expand-lg"}>
        <button className="logo-btn-mobile navbar-brand no-select" onClick={() => navigate("/")}>
          <img src={logo} alt="Elmark_logo" className="logo-mobile" />
        </button>
        <div className="navbar-mobile-icons-wrapper">
          <LangSwitchMobile />
          <button className="dropdown-mobile no-select" onClick={() => setOpen(!isOpen)}>
            <img src={isOpen ? close : dropdown} alt={`${isOpen ? "close" : "drop"}`} className="dropdown-mobile-img" />
            <div className="mobile-burger-dots-wrapper">
              {!isOpen && user.notificationCount ? <div className="mobile-burger-account-actions-count-circle"></div> : null}
              {!isOpen && registrationStep && location.pathname !== "/addreport" ? (
                <div className="mobile-burger-account-actions-count-circle-grey"></div>
              ) : null}
            </div>
          </button>
        </div>
      </nav>
      <div className={isOpen ? "mobile-navigation" : "mobile-navigation--hidden"}>
        <div className="navbar__searchbar">
          <div className="searchbar-div searchbar-div-mobile">
            <input
              maxLength={50}
              value={searchWarrantyInput}
              onChange={(event) => setSearchWarrantyInput(event.target.value)}
              onKeyDown={(event) => {
                if (event.code === "Enter") handleSearchWarranty(searchWarrantyInput);
              }}
              ref={textFieldSearchWarranty}
              disabled={warrantySearchLoading}
              className="searchbar searchbar-mobile"
              type="search"
              placeholder={t("searchbar.placeholder")}
              aria-label="Search"
            />
            <button disabled={warrantySearchLoading} onClick={() => handleSearchWarranty(searchWarrantyInput)} className="search-btn search-btn-mobile">
              {warrantySearchLoading ? (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "2.5rem", height: "2.5rem", borderWidth: "0.25rem", color: "var(--primary-color)" }}
                />
              ) : (
                <img src={search} alt="search" className="search-img" />
              )}
            </button>
          </div>
          <QRScanner
            setResultValue={(value) => {
              setSearchWarrantyInput(value);
              handleSearchWarranty(value);
            }}
          />
        </div>
        <PanelMobile navMobileActive={isOpen} closeNavMobile={() => setOpen(!isOpen)} logoutCallback={logoutCallback} />
      </div>
    </div>
  );
};
