export const headings = [
  { content: "number" },
  { content: "reportDate" },
  { content: "serialNumberProductSymbol" },
  { content: "producer" },
  { content: "customer" },
  { content: "repairKind" },
  { content: "repairState" },
  { content: "technician" },
  { content: "" },
];

export const myClientHeadings = [
  { content: "number" },
  { content: "reportDate" },
  { content: "serialNumberProductSymbol" },
  { content: "producer" },
  { content: "repairKind" },
  { content: "repairState" },
];

export const otherClientHeadings = [
  { content: "number" },
  { content: "reportDate" },
  { content: "serialNumberProductSymbol" },
  { content: "producer" },
  { content: "person" },
  { content: "repairKind" },
  { content: "repairState" },
];

export const bulkActionModalHeadings = ["number", "serialNumberProductSymbol"];

export const ratingsOptions = [
  { label: "positive", value: 1 },
  { label: "negative", value: 0 },
];

export const yesNoOptions = [
  { label: "yes", value: 1 },
  { label: "no", value: 0 },
];

export const repairsPageSizeOptions = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 75, value: 75 },
  { label: 100, value: 100 },
];

export const columnsWithSelectableOptions = ["repairKind", "repairSource", "repairState", "repairType", "customerRating", "elmarkRating"];

export const columnsWithYesNoOptions = ["isWaiting", "isClosed", "isInQueue", "isAsleep"];

export const defaultPageSizeCookieDaysExpire = 1825;

export const bulkActionOptions = ["repairTypeSet", "repairKindSet", "repairStateSet", "technicianIdSet", "suggestedRepairDateSet", "repairDescriptionSet"];

export const multilineFieldBulkActions = ["repairDescriptionSet"];

export const datePickerBulkActions = ["suggestedRepairDateSet"];

export const selectFieldBulkActions = ["repairTypeSet", "repairKindSet", "repairStateSet", "technicianIdSet"];

export const technicianBulkActionOption = "technicianIdSet";

export const specificRepairWarnings = ["wrnElmatic"];

export const repairDetailsLinkRegExp = new RegExp(`${process.env.REACT_APP_URL}[0-9]*/info`);

export const mainTab = {
  id: "all",
  title: (
    <div className="tab-icon__wrapper">
      <svg height="2.5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g>
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z" />
        </g>
      </svg>
    </div>
  ),
  content: "all",
};
