import React from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "components/ContactForm/ContactForm";
import Radio from "stories/components/Radio/Radio";

const ChangeUserContactForm = ({ values, setFieldValue, formErrors, errors, touched, handleBlur }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h6 className="heading--6 info-modal__heading">{t("global.contact")}</h6>
      <div className="change-user-contact-form__radio-container">
        <Radio checked={!values.newContactData} onChange={() => setFieldValue("newContactData", false)} label={t("addReport.useClientContactData")} />
        <Radio checked={values.newContactData} onChange={() => setFieldValue("newContactData", true)} label={t("addReport.provideOtherContactData")} />
      </div>
      {values.newContactData && (
        <div className="repair-info-form">
          <ContactForm values={values} setFieldValue={setFieldValue} formErrors={formErrors} errors={errors} touched={touched} handleBlur={handleBlur} />
        </div>
      )}
    </div>
  );
};

export default ChangeUserContactForm;
