import React from "react";

const ContactOption = ({ children, onClick, topContent }) => {
  return (
    <div className={`contact-option ${onClick ? "contact-option--click" : ""}`} onClick={onClick}>
      <div className="contact-option__item">{topContent}</div>
      <div className="contact-option__item">{children}</div>
    </div>
  );
};

export default ContactOption;
