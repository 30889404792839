import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";

const CancelAddReportConfirmationModal = ({ active, onClose, onAction, loadingAction }) => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const { t } = useTranslation();

  if (!active) return;

  return (
    <Modal
      title={t("addReport.deleteReport")}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button size={mobile ? "small" : "default"} style={{ width: "4.2rem" }} disabled={loadingAction} onClick={() => onClose()}>
            {t("global.no")}
          </Button>
          <Button variant="primary" size={mobile ? "small" : "default"} style={{ width: "4.2rem" }} loading={loadingAction} onClick={() => onAction()}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
      onClose={() => onClose()}
    >
      <h6 className="heading--6">{t("addReport.deleteReportConfirmationHeading")}</h6>
      <p className="paragraph--small text--subdued">{t("addReport.deleteReportConfirmationBody")}</p>
    </Modal>
  );
};

export default CancelAddReportConfirmationModal;
