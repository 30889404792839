import React from "react";
import PropTypes from "prop-types";
import CloseDrawer from "images/CloseDrawer.svg";
import "./styles.css";

const Drawer = ({ activator, title, children, active, setActive }) => {
  window.onmousedown = function (event) {
    if (!activator?.props?.id) return;
    const path = event.path || (event.composedPath && event.composedPath());

    if (
      path.some((item) => {
        if (item === document || item === window) return false;
        return item.matches(`#${activator.props.id}`) || item.matches(`.drawer`);
      })
    )
      return;

    const drawers = document.getElementsByClassName("drawer__open");

    for (let i = 0; i < drawers.length; i++) if (drawers[i].classList.contains("drawer__open")) setActive(false);
  };

  return (
    <>
      <div className={`drawer ${active ? "drawer__open" : ""}`}>
        <div className="drawer-heading">
          <span className="drawer-heading__title">{title}</span>
          <button className="close-button" onClick={() => setActive(false)}>
            <img src={CloseDrawer} className="close-button__img" alt="x" />
          </button>
        </div>
        <div className="drawer-content">{children}</div>
      </div>
      {activator}
    </>
  );
};

Drawer.propTypes = {
  activator: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any,
  active: PropTypes.bool,
  setActive: PropTypes.func,
};

export default Drawer;
