/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import Modal from "stories/components/Modal/Modal";
import Button from "stories/components/Button/Button";
import TextField from "stories/components/TextField/TextField";
import Stack from "stories/components/Stack/Stack";
import RatingStars from "./RatingStars";
import agent from "api/agent";
import { MIN_DESKTOP_WIDTH } from "utils/config";

const ReportAttention = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });

  const [comment, setComment] = useState();
  const [commentErr, setCommentErr] = useState(true);
  const [commentApiErr, setCommentApiErr] = useState("");
  const [serviceStars, setServiceStars] = useState(null);
  const [visualStars, setVisualStars] = useState(null);
  const [fetching, setFetching] = useState(false);

  const handleCommentChange = (value) => {
    setComment(value);
    setCommentErr(value.length >= 20 ? false : true);
  };

  const emailSendReportAttention = () => {
    setFetching(true);
    const formdata = { comment };
    if (serviceStars) formdata.serviceStars = serviceStars;
    if (visualStars) formdata.visualStars = visualStars;
    agent.Email.emailSendReportAttention({ id: user.id, token: user.token, url: window.location.href, ...formdata }).then(
      (response) => {
        setFetching(false);
        if (response.result === 301) {
          onClose();
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "info", description: t("reportAttention.sent") } });
        }
      },
      (error) => {
        setFetching(false);
        let commentApiErrCode = "";
        if (typeof error.response.data.messages !== "undefined")
          error.response.data.messages.forEach((item) => {
            if (item.property && item.property === "comment") commentApiErrCode = String(item.code);
          });
        setCommentApiErr(commentApiErrCode);
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const footerActions = (
    <div className="modal-buttons-container">
      <Button variant="default" onClick={onClose} disabled={fetching}>
        {t("global.cancel")}
      </Button>
      <Button variant="primary" disabled={commentErr || fetching} onClick={emailSendReportAttention}>
        {t("global.send")}
      </Button>
    </div>
  );
  return (
    <Modal size="big" title={t("reportAttention.title")} footerActions={footerActions} onClose={onClose}>
      <div className="mb-3">{t("reportAttention.invitation")}</div>
      <div className="mb-4">
        <TextField
          value={comment}
          label={t("reportAttention.label")}
          placeholder={t("reportAttention.placeholder")}
          variant="multi"
          numberOfLines={4}
          onChange={handleCommentChange}
          disabled={fetching}
          error={commentApiErr && t(`errors.${commentApiErr}`)}
          maxLength={1024}
          autoComplete="off"
        />
      </div>
      <Stack flexDirection={desktop ? "row" : "column"} gap={desktop ? "3rem" : "1rem"}>
        <RatingStars
          title={t("reportAttention.serviceStars")}
          value={serviceStars}
          handleChange={setServiceStars}
          wrapperClass="text-center mb-3"
          starSize="36px"
          disabled={fetching}
        />
        <RatingStars
          title={t("reportAttention.visualStars")}
          value={visualStars}
          handleChange={setVisualStars}
          wrapperClass="text-center mb-3"
          starSize="36px"
          disabled={fetching}
        />
      </Stack>
    </Modal>
  );
};

export default ReportAttention;
