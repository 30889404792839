import React from "react";
import Arrows from "./Arrows.svg";

const ColumnNameAction = ({ onClick, content }) => (
  <button onClick={() => onClick()} className="custom-table__column-name--action">
    <span>{content}</span>
    <img src={Arrows} alt="" />
  </button>
);

export default ColumnNameAction;
