/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";

const Tabs = ({ cardTabs, handleTabSelection }) => {
  const {
    repair: { selectedTabId },
  } = useSelector((store) => store);

  return (
    <ul className="mobile-repair-admin__tabs-container">
      {cardTabs.map((tab) => (
        <li
          onClick={() => handleTabSelection(tab.id)}
          className={`mobile-repair-admin__tabs-item ${tab.id === selectedTabId ? "mobile-repair-admin__tabs-item--active " : ""}`}
          key={tab.id}
        >
          {tab.title}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
