import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LangSwitchGlobe from "stories/components/LangSwitchGlobe/LangSwitchGlobe";
import HelpModal from "components/HelpModal/HelpModal";
import Logo from "images/logo.svg";
import Info from "images/info.svg";

const TopPanel = () => {
  const { t } = useTranslation();
  const [activeModal, setActiveModal] = useState(false);

  return (
    <div className="login-top-panel">
      <img src={Logo} alt="Logo" />
      <div className="login-top-panel__help">
        <button onClick={() => setActiveModal(true)} className="login-top-panel__link">
          <img src={Info} alt="info_icon" />
          <span className="login-top-panel__link">{t("global.help")}</span>
        </button>
        <LangSwitchGlobe variant="icons" />
      </div>
      <HelpModal active={activeModal} onClose={() => setActiveModal(false)} />
    </div>
  );
};

export default TopPanel;
