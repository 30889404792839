/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import agent from "api/agent";
import Page from "stories/components/Page/Page";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { CHANGE_STATUS_EVENT_TYPE, MAX_MOBILE_WIDTH } from "utils/config";
import DesktopCard from "components/Card/desktop/Card";
import MobileCard from "components/Card/mobile/Card";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import PageSpinner from "components/PageSpinner/PageSpinner";
import E404 from "routes/E404/E404";
import { detailsPageRegexp, pagesWithFlex, pagesWithGrid } from "./config";
import Info from "./Info/Info";
import Conversation from "./Conversation/Conversation";
import Valuation from "./Valuation/Valuation";
import Delivery from "./Delivery/Delivery";
import Rating from "./Rating/Rating";
import RepairsLog from "./RepairsLog/RepairsLog";
import Report from "./Report/Report";
import Photos from "./Photos/Photos";
import Logs from "./Logs/Logs";
import Configuration from "./Configuration/Configuration";
import ActionButtons from "./Info/ActionButtons";
import Attachments from "./Attachments/Attachments";
import RepairSteps from "./RepairSteps";
import { getLinks, isCardWithOverflowHidden } from "./utils";
import "./styles.css";

const RepairDetails = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const { pathname } = useLocation();
  const pathRegex = new RegExp(detailsPageRegexp);
  const { repairId, currentTab } = useParams();
  const {
    repair: { repairDetails },
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const linkCounters = {
    attachments: repairDetails?.attachments,
    photos: repairDetails?.photos,
    repairslog: repairDetails?.repairs?.length,
    valuation: repairDetails?.valuations,
    conversation: repairDetails?.conversations,
    rating: repairDetails?.ratings,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [repairDetailsLoading, setRepairDetailsLoading] = useState(true);
  const [refreshRepairDetails, setRefreshRepairDetails] = useState(false);
  const [convertedLinks, setConvertedLinks] = useState(
    getLinks(user, repairDetails, repairId).map((link) => {
      return {
        url: `/${repairId}${link}`,
        name: t(`myRepairs.${link.replace("/", "")}`),
        counter: linkCounters[link.replaceAll("/", "").replaceAll("-", "")],
      };
    }),
  );
  const [repairSteps, setRepairSteps] = useState([]);
  const [repairStepsLoading, setRepairStepsLoading] = useState(true);

  const getRepairDetailsSuccess = (response) => {
    if (!response.data.length) throw new Error("302");
    dispatch({ type: "REPAIR_SET_REPAIR_DETAILS", repairDetails: response.data.at(0) });
  };

  const getRepairDetailsError = (error) => {
    navigate("/");
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const catchGetRepairDetailsError = (error) => {
    navigate("/");
    dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${error.message}`),
      },
    });
  };

  const getRepairDetails = () => {
    setRepairDetailsLoading(true);
    agent.Repairs.getRepairDetails({ id: user.id, token: user.token, repairId: repairId })
      .then(getRepairDetailsSuccess, getRepairDetailsError)
      .catch(catchGetRepairDetailsError)
      .finally(() => setRepairDetailsLoading(false));
  };

  const getRepairEventsSuccess = (response) => {
    setRepairSteps(response.data.filter(({ eventType }) => eventType === CHANGE_STATUS_EVENT_TYPE));
  };

  const getRepairEventsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRepairEvents = () => {
    setRepairStepsLoading(true);
    agent.Repairs.repairEvents({ id: user.id, token: user.token, repairId: repairId, pageSize: 999, pageNumber: 1 })
      .then(getRepairEventsSuccess, getRepairEventsError)
      .finally(() => setRepairStepsLoading(false));
  };

  useEffect(() => {
    if (pathRegex.test(pathname)) {
      getRepairDetails();
      getRepairEvents();
    }
  }, [refreshRepairDetails, repairId]);

  useEffect(() => {
    if (pathRegex.test(pathname))
      setConvertedLinks(
        getLinks(user, repairDetails, repairId).map((link) => {
          return {
            url: `/${repairId}${link}`,
            name: t(`myRepairs.${link.replace("/", "")}`),
            counter: linkCounters[link.replaceAll("/", "").replaceAll("-", "")],
          };
        }),
      );
  }, [JSON.stringify(repairDetails), user.lang]);

  if (!pathRegex.test(pathname)) return <E404 />;

  const tabsMap = {
    info: <Info refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} repairSteps={repairSteps} />,
    conversation: <Conversation refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    valuation: <Valuation refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    delivery: <Delivery />,
    rating: <Rating refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    logs: <Logs />,
    repairslog: <RepairsLog />,
    report: <Report />,
    attachments: <Attachments refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    photos: <Photos refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    configuration: <Configuration repairDetails={repairDetails} />,
  };

  return (
    <Page
      heading={
        <div className="repair-details-heading">
          {mobile ? (
            <Button onClick={() => setRefreshRepairDetails(!refreshRepairDetails)} size="small">
              {t("global.refresh")}
            </Button>
          ) : (
            <Stack alignItems="center" gap="1rem">
              <h3 className="heading--3 text--nowrap no-margin">{`${t("myRepairs.repairId")} ${repairId}`}</h3>
              <Button onClick={() => setRefreshRepairDetails(!refreshRepairDetails)} size="small">
                {t("global.refresh")}
              </Button>
            </Stack>
          )}
          {!repairStepsLoading && <RepairSteps repairSteps={repairSteps} />}
        </div>
      }
    >
      {mobile ? (
        <MobileCard>
          <MobileCard.BackButton url="/" title={t("myRepairs.backToRepairList")} />
          <MobileCard.Title>{`${t("myRepairs.repairId")} ${repairId}`}</MobileCard.Title>
          <MobileCard.ActionButtons>
            <ActionButtons repairSteps={repairSteps} refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />
          </MobileCard.ActionButtons>
          <MobileCard.Navigation links={convertedLinks} />

          {repairDetailsLoading || repairStepsLoading ? <PageSpinner subPage /> : tabsMap[currentTab.replaceAll("-", "")]}
        </MobileCard>
      ) : (
        <DesktopCard>
          <DesktopCard.Sidebar links={convertedLinks} />
          <DesktopCard.Content
            className={isCardWithOverflowHidden() ? "custom-card__conten--hidden" : ""}
            grid={pagesWithGrid.includes(currentTab) && !repairDetailsLoading && !repairStepsLoading}
            flex={pagesWithFlex.includes(currentTab) && !repairDetailsLoading && !repairStepsLoading}
          >
            {repairDetailsLoading || repairStepsLoading ? <PageSpinner subPage /> : tabsMap[currentTab.replaceAll("-", "")]}
          </DesktopCard.Content>
        </DesktopCard>
      )}
    </Page>
  );
};

export default RepairDetails;
