import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import MessageBox from "components/MessageBox/MessageBox";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors, isAdmin, isClient, isReception } from "utils/global-functions";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Button from "stories/components/Button/Button";
import { canCloseMessage, canManageConversationAlert, canReplyMessage, isMessageEditable } from "./utils";
import { alertIcon } from "./config";

const Messages = ({
  conversationList,
  setConversationList,
  setSelectedConversationId,
  setConfirmDeleteMessageModalActive,
  setEditMessageActive,
  setResponseMessageActive,
  setConfirmCloseMessageModalActive,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();

  const toggleConversationAlertSuccess = (response, alertState, conversationId) => {
    if (response.result === 301) {
      return setConversationList((conversations) => {
        return conversations.map((conversation) => {
          if (conversation.id === conversationId) return { ...conversation, alert: alertState };
          return conversation;
        });
      });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const toggleConversationAlertError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const toggleConversationAlert = (conversationId, alertState) => {
    agent.Conversations.conversationsAlert({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      conversationId: conversationId,
      alert: alertState,
    }).then((response) => toggleConversationAlertSuccess(response, alertState, conversationId), toggleConversationAlertError);
  };

  const getReply = ({ type, id, isClosed, authorId, recipientId }) => {
    return (
      <div className="conversation-message__reply-wrapper">
        {canReplyMessage(isClosed, repairDetails, user, type, recipientId) && (
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              setSelectedConversationId(id);
              setEditMessageActive(false);
              setResponseMessageActive(true);
            }}
          >
            {t("global.reply")}
          </Button>
        )}
        {canCloseMessage(user, type, authorId, recipientId, repairDetails, isClosed) && (
          <Button
            onClick={() => {
              setConfirmCloseMessageModalActive(true);
              setSelectedConversationId(id);
            }}
            outline
            variant="error"
            size="small"
          >
            {t("global.close")}
          </Button>
        )}
      </div>
    );
  };

  const getActions = (conversation) => (
    <Stack gap="1rem" alignItems="center">
      {isMessageEditable(user, conversationList, conversation, repairDetails) && (
        <>
          <button
            onClick={() => {
              setSelectedConversationId(conversation.id);
              setResponseMessageActive(false);
              setEditMessageActive(true);
            }}
            className="unset-all custom-link--black"
          >
            {t("global.edit")}
          </button>
          {(isAdmin(user) || isReception(user)) && (
            <button
              onClick={() => {
                setResponseMessageActive(false);
                setEditMessageActive(false);
                setSelectedConversationId(conversation.id);
                setConfirmDeleteMessageModalActive(true);
              }}
              className="unset-all custom-link"
            >
              {t("global.delete")}
            </button>
          )}
        </>
      )}
      {canManageConversationAlert(user, repairDetails) && (
        <Tooltip direction="bottom" content={t(`myRepairs.${conversation.alert ? "unmarkAsImportant" : "markAsImportant"}`)}>
          <button
            onClick={() => toggleConversationAlert(conversation.id, conversation.alert ? 0 : 1)}
            className={`unset-all conversation-message__button-alert ${conversation.alert ? "conversation-message__button-alert--active" : ""}`}
          >
            {alertIcon}
          </button>
        </Tooltip>
      )}
    </Stack>
  );

  return (
    <div className={`client-conversation${isClient(user) ? " client-conversation--bottom client-conversation--higher" : ""}`}>
      {conversationList.map((conversation) => (
        <MessageBox
          key={conversation.id}
          authorId={conversation.authorId}
          recipientId={conversation.recipientId}
          author={conversation.author || ""}
          recipient={conversation.recipient}
          date={conversation.createdAt}
          content={conversation.text}
          response={!!conversation.parentId}
          attachments={conversation.attachments}
          ownMessage={conversation.authorId === user.id}
          reply={getReply(conversation)}
          actions={getActions(conversation)}
        />
      ))}
    </div>
  );
};

export default Messages;
