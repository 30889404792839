import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { renderMonthElement } from "./utils";

const RangeDatePicker = ({ id, variant, placeholder, date, firstDayOfWeek, onChange, withPortal, ...rest }) => {
  const [focus, setFocus] = useState(null);

  return (
    <DateRangePicker
      small
      noBorder
      displayFormat="DD.MM.YYYY"
      firstDayOfWeek={firstDayOfWeek}
      onDatesChange={({ startDate, endDate }) => onChange({ startDate, endDate })}
      focusedInput={focus}
      onFocusChange={(focusedInput) => setFocus(focusedInput)}
      hideKeyboardShortcutsPanel
      withPortal={withPortal}
      renderMonthElement={renderMonthElement}
      {...rest}
    />
  );
};

export default RangeDatePicker;
