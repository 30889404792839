import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spinner } from "stories/components/Spinner/Spinner";
import Pagination from "components/Pagination/Pagination";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import NoResults from "components/NoResults/NoResults";
import "../Actions.css";
import "./styles.css";
import BodyElement from "./BodyElement";
import Button from "stories/components/Button/Button";

const Body = ({ loading, actions, pageNumber, setPageNumber, pageSize, setPageSize }) => {
  const mobile = useMediaQuery({ maxWidth: 850 });
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading)
    return (
      <div className="actions-body-spinner">
        <Spinner />
      </div>
    );

  return (
    <div className="actions-body-wrapper">
      <div>
        <div className={actions?.length === 0 ? "actions-body-no-results" : "body-heading"}>
          <div className={mobile ? "mobile-body-heading-corner" : "body-heading-corner"}>
            <PageSizeSelector
              selectedPageSize={pageSize}
              pageSizeOptions={[
                { value: 5, label: 5 },
                { value: 10, label: 10 },
                { value: 30, label: 30 },
                { value: 100, label: 100 },
              ]}
              onChange={(value) => {
                setPageSize(value);
                setPageNumber(1);
              }}
            />
          </div>
        </div>

        <div>
          {actions?.map(
            ({
              id,
              parentId,
              type,
              repairId,
              createdAt,
              closedAt,
              text,
              isClosed,
              closer,
              author,
              authorId,
              recipient,
              recipientId,
              amount,
              currency,
              attachments,
              effect,
            }) => {
              return (
                <BodyElement
                  key={id}
                  parentId={parentId}
                  type={type}
                  repairId={repairId}
                  createdAt={createdAt}
                  closedAt={closedAt}
                  text={text}
                  isClosed={isClosed}
                  closer={closer}
                  author={author}
                  authorId={authorId}
                  recipient={recipient}
                  recipientId={recipientId}
                  amount={amount}
                  currency={currency}
                  attachments={attachments}
                  effect={effect}
                />
              );
            },
          )}
        </div>
      </div>

      {actions?.length === 0 ? (
        <NoResults size={mobile ? "mobile" : "small"} infoText={t("actions.noResultsText")}>
          <Button onClick={() => navigate(`/`)}>{t("addReport.mainPage")}</Button>
        </NoResults>
      ) : null}

      <div className={actions?.length === 0 ? "actions-body-no-results" : "body-footer"}>
        <Pagination
          pageSize={pageSize}
          pageNumber={pageNumber}
          listLength={actions?.length}
          previousAction={() => {
            setPageNumber(pageNumber - 1);
          }}
          continueAction={() => {
            setPageNumber(pageNumber + 1);
          }}
        />
      </div>
    </div>
  );
};

export default Body;
