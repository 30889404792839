import React from "react";
import MinimalistNumberField from "./MinimalistNumberField";
import MultilineField from "./MultilineField";
import NumberField from "./NumberField";
import SearchField from "./SearchField";
import SendMessageField from "./SendMessageField";
import SendMessageMultilineField from "./SendMessageMultilineField";
import SinglelineField from "./SinglelineField";

export const getErrorFieldClassName = (error) => (error ? "input--text__error" : "");

export const getTextField = (props) => {
  switch (props.variant) {
    case "number":
      return <NumberField {...props} />;
    case "minimalistNumber":
      return <MinimalistNumberField {...props} />;
    case "multi":
      return <MultilineField {...props} />;
    case "multiSend":
      return <SendMessageMultilineField {...props} />;
    case "search":
      return <SearchField {...props} />;
    case "send":
      return <SendMessageField {...props} />;
    default:
      return <SinglelineField {...props} />;
  }
};

export const getClassNamesForTextField = (disabled, disableResize, fullHeight) => {
  const classNames = [];

  disabled && classNames.push("input__disabled");
  disableResize && classNames.push("disabled-resize");
  fullHeight && classNames.push("input__full-height");

  return classNames.join(" ");
};

export const getClassNamesForNumberButtons = (disabled) => {
  const classNames = [];

  disabled && classNames.push("input-number__button-disabled");

  return classNames.join(" ");
};

export const getClassNamesForSearchButton = (disabled) => {
  const classNames = [];

  disabled && classNames.push("input_searchbar__button-disabled");

  return classNames.join(" ");
};
