import { REPAIR_TYPE_UNDER_WARRANTY } from "utils/config";

export const externalFieldsToReset = [
  { name: "extSerialNumber", defaultValue: "" },
  { name: "extProducerId", defaultValue: null },
  { name: "extDescription", defaultValue: "" },
  { name: "extPartNumber", defaultValue: "" },
  { name: "extDocument", defaultValue: "" },
  { name: "extSellDate", defaultValue: null },
  { name: "repairType", defaultValue: REPAIR_TYPE_UNDER_WARRANTY },
  { name: "extWarrantyLength", defaultValue: "" },
];

export const requiredExternalFields = ["extDescription", "extPartNumber", "extProducerId", "extSellDate", "extSerialNumber", "extWarrantyLength"];
