/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isActiveLink } from "../utils";

const CardSidebarLink = ({ url, children, counter }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(isActiveLink(url));
  }, [window.location.pathname]);

  return (
    <Link to={url} className={`card-sidebar__item ${active && "card-sidebar__item--active"}`}>
      <div className="custom-card__link-wrapper">
        <span className="paragraph--small">{children}</span>
        {!!counter && <div className="custom-card__link-counter">{counter}</div>}
      </div>
    </Link>
  );
};

export default CardSidebarLink;
