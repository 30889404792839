import {
  CONVERSATION,
  CONVERSATION_CUSTOMER_QUESTION,
  CONVERSATION_CUSTOMER_REPLY,
  CONVERSATION_ELMARK_QUESTION,
  CONVERSATION_ELMARK_REPLY,
  DELIVERY_TYPE_ELMARK_COURIER,
  RETURN_TYPE_ELMARK_COURIER,
  RETURN_TYPE_PERSONAL,
} from "utils/config";

export const formatBodyForSetRepairReturnAddress = (values) => {
  if (values.returnType === RETURN_TYPE_PERSONAL) return { returnType: values.returnType };

  return values;
};

export const formatAddressKey = (name) => {
  const [firstLetter, ...rest] = name.replace("address", "");
  const formatedName = [firstLetter.toLowerCase(), ...rest].join("");

  if (formatedName === "personName") return "fullName";
  if (formatedName === "postCode") return "postalCodeAndCity";
  return formatedName;
};

export const getConversationType = (responseMessageActive, converstionGroupType) => {
  if (converstionGroupType === CONVERSATION) return responseMessageActive ? CONVERSATION_CUSTOMER_REPLY : CONVERSATION_CUSTOMER_QUESTION;
  return responseMessageActive ? CONVERSATION_ELMARK_REPLY : CONVERSATION_ELMARK_QUESTION;
};

export const formatRepairDetails = (repairDetails, body) =>
  body.repairDetails === RETURN_TYPE_ELMARK_COURIER
    ? { ...repairDetails, ...body }
    : { ...Object.fromEntries(Object.entries(repairDetails).filter(([key]) => !key.includes("return"))), ...body };

export const getCombinedDeliveryAddresses = (restDeliveryInfo, translatedCountryNames) => {
  return {
    addressPostCode:
      restDeliveryInfo.deliveryAddressPostCode ?? restDeliveryInfo.deliveryAddressCity
        ? `${restDeliveryInfo.deliveryAddressPostCode ?? ""} ${restDeliveryInfo.deliveryAddressCity ?? ""}`
        : null,
    addressStreet: restDeliveryInfo.deliveryAddressStreet
      ? `${restDeliveryInfo.deliveryAddressStreet ?? ""} ${restDeliveryInfo.deliveryAddressBuilding ?? ""}${
          restDeliveryInfo.deliveryAddressPlace ? `/${restDeliveryInfo.deliveryAddressPlace}` : ""
        }`
      : null,
    addressCountry: restDeliveryInfo.deliveryAddressCountryId ? translatedCountryNames.of(restDeliveryInfo.deliveryAddressCountryId) : null,
  };
};

export const getCombinedReturnAddresses = (restReturnInfo, translatedCountryNames) => {
  return {
    addressPostCode:
      restReturnInfo.returnAddressPostCode ?? restReturnInfo.returnAddressCity
        ? `${restReturnInfo.returnAddressPostCode ?? ""} ${restReturnInfo.returnAddressCity ?? ""}`
        : null,
    addressStreet: restReturnInfo.returnAddressStreet
      ? `${restReturnInfo.returnAddressStreet ?? ""} ${restReturnInfo.returnAddressBuilding ?? ""}${
          restReturnInfo.returnAddressPlace ? `/${restReturnInfo.returnAddressPlace}` : ""
        }`
      : null,
    addressCountry: restReturnInfo.returnAddressCountryId ? translatedCountryNames.of(restReturnInfo.returnAddressCountryId) : null,
  };
};

export const getContentForReturnAddress = (returnType, addressOrder, restReturnInfo, translatedCountryNames, constants, t) => {
  return returnType === RETURN_TYPE_ELMARK_COURIER
    ? addressOrder
        .map((name) => {
          if (!getCombinedReturnAddresses(restReturnInfo, translatedCountryNames)[name] && !restReturnInfo[name.replace("address", "returnAddress")])
            return null;
          const description =
            getCombinedReturnAddresses(restReturnInfo, translatedCountryNames)[name] || restReturnInfo[name.replace("address", "returnAddress")];
          if (!description) return null;

          return {
            term: t(`global.${formatAddressKey(name)}`),
            description: description,
          };
        })
        .filter((value) => !!value)
    : [{ term: t("myRepairs.returnType"), description: t(`constants.return.type.${constants?.returnType?.[returnType]?.strVal}`) }];
};

export const getContentForDeliveryAddress = (deliveryType, addressOrder, restDeliveryInfo, translatedCountryNames, constants, t) => {
  return deliveryType === DELIVERY_TYPE_ELMARK_COURIER
    ? addressOrder
        .map((name) => {
          if (!getCombinedDeliveryAddresses(restDeliveryInfo, translatedCountryNames)[name] && !restDeliveryInfo[name.replace("address", "deliveryAddress")])
            return null;
          const description =
            getCombinedDeliveryAddresses(restDeliveryInfo, translatedCountryNames)[name] || restDeliveryInfo[name.replace("address", "deliveryAddress")];
          if (!description) return null;

          return {
            term: t(`global.${formatAddressKey(name)}`),
            description: description,
          };
        })
        .filter((value) => !!value)
    : [{ term: "", description: t("global.notApplicable") }];
};

export const getPackageInfo = (deliveryPackages, constants, t) => {
  return deliveryPackages
    ? JSON.parse(deliveryPackages)
        .map((deliveryPackage) => {
          return Object.entries(deliveryPackage).map(([key, value]) => {
            if (key === "type") return { term: t("myRepairs.packageSize"), description: constants?.packageType?.[value].strVal };

            return { term: t("myRepairs.packageQuantity"), description: value };
          });
        })
        .flat()
    : [];
};
