/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import { t } from "i18next";
import agent from "api/agent";
import dd from "images/DeepDive.png";

const DeepDivePanel = ({ onCloseCallback }) => {
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();

  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);

  const getLogs = () => {
    setFetching(true);
    agent.DeepDive.login()
      .then(
        (response) => {
          agent.DeepDive.getLogs({ id: user.id, token: user.token, repairId: repairDetails.repairId }, response.token, repairDetails.serialNumber).then(
            (response) => {
              dispatch({
                type: "APP_PUSHNOTIFICATION",
                notification: {
                  type: "info",
                  description: t("deepDive.getLogsSuccess"),
                },
              });
              onCloseCallback();
            },
            (error) => {
              const errDetail = error?.response?.data?.detail;
              dispatch({
                type: "APP_PUSHNOTIFICATION",
                notification: {
                  type: "danger",
                  description: `Deep Dive getLogs error!\n${error.message}.\n${
                    typeof errDetail !== "undefined" ? errDetail : "Specific error details not provided"
                  }.`,
                },
              });
            },
          );
        },
        (error) => {
          const errDetail = error?.response?.data?.detail;
          dispatch({
            type: "APP_PUSHNOTIFICATION",
            notification: {
              type: "danger",
              description: `Deep Dive login error!\n${error.message}.\n${
                typeof errDetail !== "undefined" ? errDetail : "Specific error details not provided"
              }.`,
            },
          });
        },
      )
      .finally(() => {
        setFetching(false);
      });
  };

  return (
    <>
      <Modal title={t("deepDive.title")} size="elastic" onClose={onCloseCallback}>
        <Stack flexDirection="row">
          <img src={dd} alt="DeepDive" style={{ marginLeft: "-0rem", marginRight: "0rem" }}></img>
          <Stack flexDirection="column" justifyContent="center" gap="var(--modal-buttons-gap)">
            <Button
              size="small"
              variant="primary"
              disabled={fetching}
              style={{ width: "20rem" }}
              onClick={() => {
                window.open("https://deepdive.elmark.com.pl/", "_blank");
                onCloseCallback();
              }}
            >
              {t("deepDive.goTo")}
            </Button>
            <Button size="small" variant="default" disabled={fetching} style={{ width: "20rem" }} onClick={getLogs}>
              {t("deepDive.getLogs")}
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default DeepDivePanel;
