/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Page from "stories/components/Page/Page";
import { ManagementQos } from "./ManagementQos";
import { ManagementEmail } from "./ManagementEmail";
import { ManagementDict } from "./ManagementDict";
import { ManagementRegs } from "./ManagementRegs";
import { ManagementConstants } from "./ManagementConstants";
import MobileViewUnavailable from "components/Utils/MobileViewUnavailable";
import { useMediaQuery } from "react-responsive";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import "./Management.css";

const MgmtTab = ({ title, type, current, callback }) => {
  return (
    <li className="nav-item">
      <span
        className={["nav-link", current === type && "active"].join(" ")}
        onClick={() => {
          callback(type);
        }}
      >
        {title}
      </span>
    </li>
  );
};

export const Management = () => {
  const user = useSelector((store) => store.user);
  const [mgmtType, setmgmtType] = useState("qos");
  const { t } = useTranslation();
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });

  if (!desktop)
    return (
      <Page>
        <div className="nav-tabs-content">
          <MobileViewUnavailable />
        </div>
      </Page>
    );

  return (
    <Page heading={<div className="heading--3">{t("panel.management")}</div>}>
      {user.role !== "admin" ? (
        <h1>Access denied!</h1>
      ) : (
        <div className="tabs-card">
          <ul className="nav nav-tabs">
            <MgmtTab title={t("management.qos.title")} type="qos" current={mgmtType} callback={setmgmtType} />
            <MgmtTab title={t("management.email.title")} type="email" current={mgmtType} callback={setmgmtType} />
            <MgmtTab title={t("management.dict.title")} type="dict" current={mgmtType} callback={setmgmtType} />
            <MgmtTab title={t("management.regs.title")} type="regs" current={mgmtType} callback={setmgmtType} />
            <MgmtTab title="Constants" type="constants" current={mgmtType} callback={setmgmtType} />
          </ul>
          <div className="nav-tabs-content flex-1">
            {mgmtType === "qos" && <ManagementQos />}
            {mgmtType === "email" && <ManagementEmail />}
            {mgmtType === "dict" && <ManagementDict />}
            {mgmtType === "regs" && <ManagementRegs />}
            {mgmtType === "constants" && <ManagementConstants />}
          </div>
        </div>
      )}
    </Page>
  );
};
