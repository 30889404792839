import React from "react";
import { Link } from "react-router-dom";
import Chevron from "images/Chevron.svg";
import { getBackUrl, getForwardUrl } from "../utils";
import NavigationDropdown from "./NavigationDropdown";
import "./styles.css";

const Navigation = ({ links }) => (
  <div className="mobile-card-navigation">
    <div className="mobile-navigation__border" />
    <div className="mobile-navigation__buttons-wrapper">
      <Link to={getBackUrl(links)} className="mobile-navigation__back-button">
        <img src={Chevron} alt="v" />
      </Link>
      <NavigationDropdown links={links} />
      <Link to={getForwardUrl(links)} className="mobile-navigation__forward-button">
        <img src={Chevron} alt="v" />
      </Link>
    </div>
    <div className="mobile-navigation__border" />
  </div>
);

export default Navigation;
