import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import lodash from "lodash";
import Accordion from "stories/components/Accordion/Accordion";
import { isAdmin, isReception } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import { invoiceDataOrder } from "../config";
import EditClientContactModal from "../EditClientContactModal";
import ChangeUserModal from "../ChangeUserModal";
import { showReplacedProductWarning, transformContactKeyToUserKey } from "../utils";
import ChangeSerialNumberModal from "../ChangeSerialNumberModal";
import RepairDetailsForm from "./RepairDetailsForm";
import Stack from "stories/components/Stack/Stack";
import Link from "stories/components/Link/Link";

const InfoDetails = ({
  productDetailsContent,
  refreshRepairDetails,
  setRefreshRepairDetails,
  repairDetailsTermsMap,
  repairDetailsDescriptionMap,
  productUrl,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const [editClientContactModalActive, setEditClientContactModalActive] = useState(false);
  const [changeUserModalActive, setChangeUserModalActive] = useState(false);
  const [changeSerialNumberModalActive, setChangeSerialNumberModalActive] = useState(false);
  const navigate = useNavigate();

  const editButtons = [
    { active: changeSerialNumberModalActive, onChange: setChangeSerialNumberModalActive, content: t("global.change") },
    { active: changeUserModalActive, onChange: setChangeUserModalActive, content: t("global.change") },
    { active: editClientContactModalActive, onChange: setEditClientContactModalActive, content: t("global.edit") },
  ];

  const productDetails = {
    title: repairDetails.partNumber || t("global.noData"),
    content: (
      <div>
        {productDetailsContent.map(({ term, description }, index) => (
          <React.Fragment key={index}>
            <span className="paragraph--small text--subdued info-details__term">{term}</span>
            {description}
          </React.Fragment>
        ))}
        <Stack flexDirection="row" gap="0.6rem">
          {!lodash.isEmpty(editButtons.at(0)) && (isAdmin(user) || isReception(user)) && (
            <Button onClick={() => editButtons.at(0).onChange(!editButtons.at(0).active)} size="small">
              {editButtons.at(0).content}
            </Button>
          )}
          <Link href={productUrl} target="_blank" rel="noopener noreferrer" className="mobile-repair-info__product-link custom-link--black">
            <div className="button__content">{t("myRepairs.checkProduct")}</div>
          </Link>
        </Stack>
      </div>
    ),
  };

  const restDetails = [
    {
      title: t("myRepairs.repairDetails"),
      content: <RepairDetailsForm refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />,
    },
    {
      title: t("addReport.dataToInvoice"),
      content: invoiceDataOrder
        .map((invoiceDetail) => {
          if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "companyName")
            return { term: t("global.fullName"), description: repairDetails.user.personName || "-" };
          if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "taxNumber") return {};

          return {
            term: repairDetailsTermsMap[invoiceDetail] || t(`global.${invoiceDetail}`),
            description: repairDetailsDescriptionMap[invoiceDetail] || repairDetails.invoice[invoiceDetail] || "-",
          };
        })
        .map(({ term, description }, index) =>
          !term && !description ? null : (
            <React.Fragment key={index}>
              <span className="paragraph--small text--subdued info-details__term">{term}</span>
              <p className="paragraph--small info-details__description">{description}</p>
            </React.Fragment>
          ),
        )
        .filter((value) => !!value),
    },
    {
      title: t("global.user"),
      content: (
        <div>
          <span className="paragraph--small text--subdued info-details__term">{t("global.fullName")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.personName || "-"}</p>
          <span className="paragraph--small text--subdued info-details__term">{t("global.phoneNumber")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.phone || "-"}</p>
          <span className="paragraph--small text--subdued info-details__term">{t("global.emailAddress")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.email || "-"}</p>
          {!lodash.isEmpty(editButtons.at(1)) && (isAdmin(user) || isReception(user)) && (
            <Button onClick={() => editButtons.at(1).onChange(!editButtons.at(1).active)} size="small">
              {editButtons.at(1).content}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: t("global.contactDetails"),
      content: (
        <div>
          {Object.entries(repairDetails)
            .filter(([key]) => key.includes("contact"))
            .map(([key, value]) => {
              return (
                <React.Fragment key={key}>
                  <span className="paragraph--small text--subdued info-details__term">{t(`myRepairs.${key}`)}</span>
                  <p className="paragraph--small info-details__description">{value || repairDetails.user[transformContactKeyToUserKey(key)] || "-"}</p>
                </React.Fragment>
              );
            })}
          {!lodash.isEmpty(editButtons.at(2)) && (isAdmin(user) || isReception(user)) && (
            <Button onClick={() => editButtons.at(2).onChange(!editButtons.at(2).active)} size="small">
              {editButtons.at(2).content}
            </Button>
          )}
        </div>
      ),
    },
  ];

  const replaceWarning = {
    title: t("global.warnings"),
    content: (
      <Button onClick={() => navigate(`/${repairDetails.repairId}/repairs-log`)} variant="error">
        <div className="text--left">{t("myRepairs.productReplacement")}</div>
        <div className="text--left">{t("myRepairs.productReplacementInfo")}</div>
      </Button>
    ),
  };

  const warnings = showReplacedProductWarning(repairDetails.repairs, repairDetails.repairId) ? [replaceWarning] : [];

  return (
    <div className="info-details">
      <Accordion
        content={[productDetails, ...warnings, ...restDetails].map(({ title, content }) => {
          return {
            title: (
              <h6 key={title} className="info-details__heading">
                {title}
              </h6>
            ),
            body: content,
          };
        })}
      />
      <ChangeSerialNumberModal
        active={changeSerialNumberModalActive}
        setActive={setChangeSerialNumberModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <ChangeUserModal
        active={changeUserModalActive}
        setActive={setChangeUserModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <EditClientContactModal active={editClientContactModalActive} setActive={setEditClientContactModalActive} />
    </div>
  );
};

export default InfoDetails;
