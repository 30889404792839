import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import EmployeeSelector from "components/EmployeeSelector/EmployeeSelector";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import TechnicianWarningModal from "../TechnicianWarningModal";

const TechnicianSelector = ({ technicianId, repairId, setRepairWarnings }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    app: { constants, commonApiErrors },
  } = useSelector((store) => store);
  const [selectedTechnicianId, setSelectedTechnicianId] = useState(technicianId);
  const [selectedTechnicianIdToChange, setSelectedTechnicianIdToChange] = useState();
  const [selectTechnicianLoading, setSelectTechnicianLoading] = useState(false);
  const [technicianWarningModalActive, setTechnicianWarningModalActive] = useState(false);

  const setTechnicianSuccess = (response, value) => {
    if (response.result === 301) {
      setRepairWarnings((state) => state.filter((value) => value !== "wrnAsleep"));
      setSelectedTechnicianId(value);
      return setTechnicianWarningModalActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setTechnicianError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const setTechnician = (value) => {
    setSelectTechnicianLoading(true);
    agent.Repairs.repairBulkAction({ id: user.id, token: user.token, repairIds: JSON.stringify([repairId]), action: "technicianIdSet", value: value })
      .then((response) => setTechnicianSuccess(response, value), setTechnicianError)
      .finally(() => setSelectTechnicianLoading(false));
  };

  if (isAdmin(user) || isReception(user))
    return (
      <div className="admin-reports-table__select-wrapper">
        <EmployeeSelector
          menuPortalTarget={document.body}
          disabled={selectTechnicianLoading}
          value={selectedTechnicianId}
          onChange={(value) => {
            setSelectedTechnicianIdToChange(value);
            setTechnicianWarningModalActive(true);
          }}
        />
        <TechnicianWarningModal
          active={technicianWarningModalActive}
          setActive={setTechnicianWarningModalActive}
          loading={selectTechnicianLoading}
          onAction={() => setTechnician(selectedTechnicianIdToChange)}
        />
      </div>
    );

  return <span>{constants?.employeesArr?.find(({ intVal }) => intVal === technicianId)?.strVal}</span>;
};

export default TechnicianSelector;
