import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";

const NewUpdateAvailableModal = ({ active, setActive, reload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (!active) return;

  return (
    <Modal
      title={t("global.newUpdateTitle")}
      onClose={() => setActive(false)}
      footerActions={
        <Button
          loading={loading}
          variant="primary"
          onClick={() => {
            setLoading(true);
            reload();
          }}
        >
          {t("global.reload")}
        </Button>
      }
    >
      <Stack alignItems="center" gap="2rem">
        <svg width="15rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="#aaa" d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9h5l-7 7-7-7h5V3h4v6z" />
          </g>
        </svg>
        <p className="paragraph--small">{t("global.newUpdateInfo")}</p>
      </Stack>
    </Modal>
  );
};

export default NewUpdateAvailableModal;
