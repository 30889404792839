import React from "react";
import PropTypes from "prop-types";
import CheckMark from "images/CheckMark.svg";
import { getCheckboxClassName } from "./utils";
import "./styles.css";

const Checkbox = ({ label, disabled = false, onChange, name, withoutPadding, className, ...rest }) => (
  <label className={`checkbox-wrapper ${className} ${getCheckboxClassName(disabled, withoutPadding)}`}>
    {label}
    <input type="checkbox" name={name} disabled={disabled} onChange={(event) => onChange(event.target.checked, name)} {...rest} />
    <span className="checkmark-wrapper">
      <img className="checkmark__icon" src={CheckMark} alt="&#10004;" />
    </span>
  </label>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;
