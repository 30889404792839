import lodash from "lodash";
import moment from "moment";
import { CONVERSATION_RATING_CUSTOMER, CONVERSATION_RATING_ELMARK } from "utils/config";
import { isAdmin } from "utils/global-functions";

export const getBodyForRatingRequest = (ratingResult, ratingInvitationResult, user, customerRatingForm) => {
  if (isAdmin(user) && !customerRatingForm)
    return lodash.isEmpty(ratingResult)
      ? { action: "add", conversationType: CONVERSATION_RATING_ELMARK }
      : { action: "update", conversationId: ratingResult.id, conversationType: CONVERSATION_RATING_ELMARK };
  return lodash.isEmpty(ratingResult)
    ? { action: "add", parentId: ratingInvitationResult.id, conversationType: CONVERSATION_RATING_CUSTOMER }
    : { action: "update", conversationId: ratingResult.id, parentId: ratingInvitationResult.id, conversationType: CONVERSATION_RATING_CUSTOMER };
};

export const isSaveRatingDisabled = (ratingResult) => {
  if (lodash.isEmpty(ratingResult)) return false;
  if (ratingResult.type === CONVERSATION_RATING_ELMARK) return false;

  const createdAtMomentLimit = moment(ratingResult?.createdAt).add({ m: 30 });

  return moment().isAfter(createdAtMomentLimit);
};

export const canShowRatingForm = (repairDetails, user, customerRatingResult, ratingInvitationResult) => {
  if (lodash.isEmpty(ratingInvitationResult)) return false;

  return (
    user.id === repairDetails.userId &&
    !isSaveRatingDisabled(customerRatingResult) &&
    ((!lodash.isEmpty(customerRatingResult) && ratingInvitationResult.isClosed) || (lodash.isEmpty(customerRatingResult) && !ratingInvitationResult.isClosed))
  );
};
