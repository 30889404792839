import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import { formatBodyWithDateAndExternalProduct } from "./utils";
import SearchSerialNumber from "./SearchSerialNumber";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const ChangeSerialNumberModal = ({ active, setActive, refreshRepairDetails, setRefreshRepairDetails }) => {
  const { t } = useTranslation();
  const {
    user,
    repair,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [foundProduct, setFoundProduct] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [addExternal, setAddExternal] = useState(false);
  const [repairSerialNumberLoading, setRepairSerialNumberLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const setRepairSerialNumberSuccess = (response) => {
    if (response.result === 301) {
      setRefreshRepairDetails(!refreshRepairDetails);
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairSerialNumberError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    if (filteredErrors.some((filteredError) => filteredError.code === 302)) navigate("/");
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const setRepairSerialNumber = (values) => {
    setRepairSerialNumberLoading(true);
    const { searchSerialNumber, ...rest } = values;
    agent.Repairs.setRepairSerialNumber({
      id: user.id,
      token: user.token,
      repairId: repair.repairDetails.repairId,
      ...formatBodyWithDateAndExternalProduct(rest, addExternal),
    })
      .then(setRepairSerialNumberSuccess, setRepairSerialNumberError)
      .finally(() => setRepairSerialNumberLoading(false));
  };

  const validationSchema = Yup.object().shape({
    extSerialNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    extPartNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    extDescription: Yup.string().min(3, t("errors.101")).max(200, t("errors.102")).required(t("errors.99")),
    extDocument: Yup.string().max(50, t("errors.102")),
    extWarrantyLength: Yup.number().min(0, t("errors.101")),
    extProducerId: Yup.mixed().required(t("errors.99")),
  });

  if (!active) return;

  return (
    <Formik
      initialValues={{
        searchSerialNumber: "",
        serialNumber: "",
        extSerialNumber: "",
        extPartNumber: "",
        extDescription: "",
        extProducerId: "",
        extDocument: "",
        extSellDate: null,
        extWarrantyLength: "",
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleBlur, touched, errors, isValid }) => (
        <Form>
          <Modal
            title={t("global.changeSerialNumber")}
            onClose={() => {
              setAddExternal(false);
              setActive(false);
            }}
            footerActions={
              <Stack gap="var(--modal-buttons-gap)">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={repairSerialNumberLoading}
                  onClick={() => {
                    setAddExternal(false);
                    setActive(false);
                  }}
                >
                  {t("global.cancel")}
                </Button>
                <Button
                  size={mobile ? "small" : "default"}
                  loading={repairSerialNumberLoading}
                  disabled={!values.serialNumber || (lodash.isEmpty(foundProduct) && !isValid)}
                  variant="primary"
                  onClick={() => setRepairSerialNumber(values)}
                >
                  {t("global.save")}
                </Button>
              </Stack>
            }
          >
            <SearchSerialNumber
              foundProduct={foundProduct}
              setFoundProduct={setFoundProduct}
              values={values}
              setFieldValue={setFieldValue}
              formErrors={formErrors}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              addExternal={addExternal}
              setAddExternal={setAddExternal}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeSerialNumberModal;
