/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import TabsCard from "components/TabsCard/TabsCard";
import BulkActionModal from "../BulkActionModal";
import TopActions from "./TopActions";
import BottomActions from "./BottomActions";
import RepairsTable from "./RepairsTable";
import "./styles.css";

const DisplayAdminDesktop = ({
  setActiveManageTabsModal,
  setDeleteTabModal,
  selectedItems,
  setSelectedItems,
  repairListLoading,
  activeFiltersDrawer,
  setActiveFiltersDrawer,
  refreshList,
  setRefreshList,
  cardTabs,
  pageNumber,
  setPageNumber,
  handleTabSelection,
}) => {
  const {
    repair: { selectedTabId, repairsList, pageSize },
  } = useSelector((store) => store);
  const [activeBulkActionModal, setActiveBulkActionModal] = useState(false);

  return (
    <TabsCard selected={selectedTabId} setSelected={handleTabSelection} tabs={cardTabs}>
      <TopActions
        setActiveManageTabsModal={setActiveManageTabsModal}
        setDeleteTabModal={setDeleteTabModal}
        repairListLoading={repairListLoading}
        activeFiltersDrawer={activeFiltersDrawer}
        setActiveFiltersDrawer={setActiveFiltersDrawer}
        setPageNumber={setPageNumber}
      />
      <div className="repairs-table">
        <div className="repairs-table__group repairs-table__group--flex">
          <RepairsTable selectedItems={selectedItems} setSelectedItems={setSelectedItems} repairListLoading={repairListLoading} />
        </div>
      </div>
      <div className="repairs-table__group">
        <BottomActions
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          activeBulkActionModal={activeBulkActionModal}
          setActiveBulkActionModal={setActiveBulkActionModal}
          repairListLoading={repairListLoading}
        />
        <Pagination
          loading={repairListLoading}
          pageSize={pageSize}
          pageNumber={pageNumber}
          listLength={repairsList.length}
          previousAction={() => {
            setPageNumber(pageNumber - 1);
            window.scrollTo(0, 0);
          }}
          continueAction={() => {
            setPageNumber(pageNumber + 1);
            window.scrollTo(0, 0);
          }}
        />
      </div>
      <BulkActionModal
        active={activeBulkActionModal}
        setActive={setActiveBulkActionModal}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
    </TabsCard>
  );
};

export default DisplayAdminDesktop;
