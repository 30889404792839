import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import Stack from "stories/components/Stack/Stack";
import { FACTORY_RESET_AGREEMENT_STATUSES } from "utils/config";
import { AddReportHeading } from "../AddReportHeading";
import { AddReportFooter } from "../AddReportFooter";
import { getAccordionContentForProductList } from "../utils";
import AddProductForm from "./AddProductForm";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import SearchForWarranty from "./SearchForWarranty";
import { isUnderWarranty } from "./utils";
import CheckProductDemo from "./CheckProductDemo";

const DisplayDesktop = ({
  setAddExternal,
  foundProduct,
  setFoundProduct,
  setRecentlyRepaired,
  setSerialNotFound,
  warrantyErrors,
  setWarrantyErrors,
  formErrors,
  setFormErrors,
  addExternal,
  setProductFullList,
  recentlyRepaired,
  serialNotFound,
  setDeleteConfirmationModalActive,
  setProductToDelete,
  productFullList,
  deleteConfirmationModalActive,
  deleteActionLoading,
  deleteProduct,
  productToDelete,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  resetForm,
  underWarrantyMap,
  accordionHeadings,
  validProductCheck,
  setValidProductCheck,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    app: { constants },
  } = useSelector((store) => store);

  return (
    <div className="add-report">
      <AddReportHeading />
      <div className="add-report__sides-wrapper">
        <div className="add-report__side-panel">
          <Stack flexDirection="column" gap="2rem">
            <SearchForWarranty
              setAddExternal={setAddExternal}
              foundProduct={foundProduct}
              setFieldValue={setFieldValue}
              setFoundProduct={setFoundProduct}
              setRecentlyRepaired={setRecentlyRepaired}
              setSerialNotFound={setSerialNotFound}
              warrantyErrors={warrantyErrors}
              setWarrantyErrors={setWarrantyErrors}
              values={values}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              setValidProductCheck={setValidProductCheck}
            />
            <CheckProductDemo
              values={values}
              serialNotFound={serialNotFound}
              validProductCheck={validProductCheck}
              setValidProductCheck={setValidProductCheck}
            />
            <AddProductForm
              serialNotFound={serialNotFound}
              addExternal={addExternal}
              setAddExternal={setAddExternal}
              productFullList={productFullList}
              setProductFullList={setProductFullList}
              foundProduct={foundProduct}
              recentlyRepaired={recentlyRepaired}
              setSerialNotFound={setSerialNotFound}
              setDeleteConfirmationModalActive={setDeleteConfirmationModalActive}
              setProductToDelete={setProductToDelete}
              setFoundProduct={setFoundProduct}
              setWarrantyErrors={setWarrantyErrors}
              setRecentlyRepaired={setRecentlyRepaired}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              validProductCheck={validProductCheck}
            />
          </Stack>
        </div>
        <div className="add-report__side-border" />
        <div className="add-report__side-panel">
          <Accordion
            content={productFullList.map(({ warrantyEnd, factoryResetAgreement, ...rest }, index) => {
              return getAccordionContentForProductList(
                {
                  warrantyEnd: `${underWarrantyMap[isUnderWarranty(warrantyEnd)]} ${warrantyEnd}`,
                  factoryResetAgreement: t(`global.${FACTORY_RESET_AGREEMENT_STATUSES[factoryResetAgreement === null ? "noData" : factoryResetAgreement]}`),
                  ...rest,
                },
                accordionHeadings,
                constants,
                index,
                {
                  action: {
                    name: t("global.delete"),
                    onClick: (product) => {
                      setDeleteConfirmationModalActive(true);
                      setProductToDelete(product);
                    },
                  },
                },
              );
            })}
          />
        </div>
      </div>
      <AddReportFooter
        continueAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 2 })}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={!productFullList.length}
        productFullList={productFullList}
        currentStep={1}
      />
      <DeleteConfirmationModal
        active={deleteConfirmationModalActive}
        setActive={setDeleteConfirmationModalActive}
        loading={deleteActionLoading}
        onAction={() => deleteProduct(productToDelete)}
      />
    </div>
  );
};

export default DisplayDesktop;
