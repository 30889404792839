export const ERROR_CODE_DATA_NOT_FOUND = 302;

export const REPAIR_TYPE_NONE_VALUE = 0;
export const REPAIR_TYPE_UNDER_WARRANTY = 1;
export const REPAIR_TYPE_OUT_OF_WARRANTY = 2;
export const REPAIR_TYPE_REPAIR_WARRANTY = 3;

export const DELIVERY_TYPE_PERSONAL = 1;
export const DELIVERY_TYPE_OWN_COURIER = 2;
export const DELIVERY_TYPE_ELMARK_COURIER = 3;
export const DELIVERY_TYPE_TO_BE_AGREED = 4;

export const RETURN_TYPE_PERSONAL = 1;
export const RETURN_TYPE_ELMARK_COURIER = 2;
export const RETURN_TYPE_TO_BE_AGREED = 3;

export const REPAIR_SOURCE_ALL = 0;
export const REPAIR_SOURCE_CLIENT = 1;
export const REPAIR_SOURCE_ELMARK = 2;

export const SUPPORTED_LANGUAGES = ["en", "pl"];
export const DEFAULT_LANGUAGE = "pl";

export const ADDITIONAL_PACKAGES_TO_LIMIT = 2;
export const MAX_PRODUCTS_QUANTITY_TO_SHOW_INITIALLY = 6;

export const MAX_MOBILE_WIDTH = 850;
export const MIN_DESKTOP_WIDTH = 851;

export const DEFAULT_PAGE_SIZE = 25;

export const PRIVACY_POLICY_LINKS = {
  pl: "https://www.elmark.com.pl/polityka-prywatnosci",
  en: "https://elmark-automation.com/privacy-policy/",
};

export const WARRANTY_CONDITIONS_LINKS = {
  pl: "https://www.elmark.com.pl/warunki-gwarancji",
  en: "https://elmark-automation.com/warranty-conditions",
};

export const ABOUT_US_LINKS = {
  pl: "https://www.elmark.com.pl/o-nas/",
  en: "https://elmark-automation.com/about-us",
};

export const CONTACT_LINKS = {
  pl: "https://www.elmark.com.pl/kontakt/",
  en: "https://elmark-automation.com/contact",
};

export const MANUAL_LINKS = {
  pl: "/docs/Instrukcja obsługi.pdf",
  en: "/docs/User's manual.pdf",
};

export const ELMARK_SEARCH_LINK = {
  pl: "https://www.elmark.com.pl/search/",
  en: "https://elmark-automation.com/search/",
};

export const ELMARK_CHANGE_ACCOUNT_DETAILS_LINKS = {
  pl: "https://www.elmark.com.pl/customer/account/edit",
  en: "https://elmark-automation.com/customer/account/edit",
};

export const DISABLED_REPAIR_STATES = [12, 13];
export const DISABLED_BULK_REPAIR_STATES = [12, 13, 21, 22, 23];
export const REPAIR_STATES_START_OF_CLOSED_STATES = 21;

export const INITIAL_STATE = 0;

export const CONVERSATION = 1;
export const CONVERSATION_CUSTOMER_QUESTION = 1;
export const CONVERSATION_CUSTOMER_REPLY = 2;
export const CONVERSATION_TYPES = [CONVERSATION_CUSTOMER_QUESTION, CONVERSATION_CUSTOMER_REPLY];

export const CONVERSATION_INTERNAL = 3;
export const CONVERSATION_ELMARK_QUESTION = 5;
export const CONVERSATION_ELMARK_REPLY = 6;

export const CONVERSATION_VALUATION = 2;
export const CONVERSATION_CUSTOMER_VALUATION = 3;
export const CONVERSATION_CUSTOMER_APPROVAL = 4;

export const CONVERSATION_RATING = 4;
export const CONVERSATION_INVITATION = 7;
export const CONVERSATION_RATING_CUSTOMER = 8;
export const CONVERSATION_RATING_ELMARK = 9;

export const ATTACHMENTS_REPORT_TYPE = 1;
export const ATTACHMENTS_CONVERSATION_TYPE = 2;
export const ATTACHMENTS_GENERAL_TYPE = 3;
export const ATTACHMENTS_PHOTOS_TYPE = 4;

export const CURRENCY_OPTIONS = [
  { label: "PLN", value: "PLN" },
  { label: "EUR", value: "EUR" },
  { label: "USD", value: "USD" },
  { label: "RON", value: "RON" },
];

export const ACTIVE_EMPLOYEE_STATE = "green";

export const yesNoOptions = [
  { label: "yes", value: 1 },
  { label: "no", value: 0 },
];

export const IS_IN_QUEUE_OPTIONS = [
  { value: 1, label: "inQueue" },
  { value: 0, label: "outOfQueue" },
  { value: -1, label: "offTheQueue" },
];

export const CONVERSATION_EXTRAS = ["RTT"];

export const CHANGE_STATUS_EVENT_TYPE = 4;

export const DHL_ERROR_CODE = 750;

export const WARRANTY_DETAILS_EXCLUDED_FOR_USER = ["isReplacement", "isExternal", "isInService", "isRecentlyRepaired", "serialSource"];

export const WARRANTY_KEYS_WITH_BOOLEAN_VALUES = ["warrantyUnder", "isReplacement", "isExternal", "isInService", "isRecentlyRepaired"];

export const UNAUTHORIZED_SESSION_ERROR_CODES = [203, 204];

export const FACTORY_RESET_AGREEMENT_STATUSES = { 0: "no", 1: "yes", noData: "noData" };

export const COUNTRIES_WITH_FREE_DELIVERY = ["PL"];

export const LINKS_TO_STORES = {
  pl: {
    elmark: "https://www.elmark.com.pl/",
    elmatic: "https://elmatic.net/",
    rugged: "https://rugged.com.pl/pl/",
  },
  en: {
    elmark: "https://elmark-automation.com/",
    elmatic: "https://elmatic.net/en/",
    rugged: "https://rugged.com.pl/en/",
  },
};
