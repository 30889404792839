import React from "react";
import Modal from "stories/components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { HelpContent } from "routes/Help/HelpContent";

const HelpModal = ({ onClose, active }) => {
  const { t } = useTranslation();

  if (!active) return;

  return (
    <Modal activePrimaryButton={false} title={t("global.help")} onClose={onClose} size="elastic">
      <HelpContent inModal={true} />
    </Modal>
  );
};

export default HelpModal;
