import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "stories/components/Modal/Modal";
import Button from "stories/components/Button/Button";
import ico from "images/MaintenanceBreak.png";

const MaintenanceBreakModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Modal
      title={t("commonApiErrors.maintenanceBreak.title")}
      onClose={() => {
        dispatch({ type: "APP_CLOSE_COMMONAPIERROR", kind: "maintenanceBreak" });
      }}
      footerActions={
        <div className="modal-buttons-container">
          <Button
            variant="primary"
            onClick={() => {
              dispatch({ type: "APP_CLOSE_COMMONAPIERROR", kind: "maintenanceBreak" });
            }}
          >
            {t("global.ok")}
          </Button>
        </div>
      }
    >
      <table>
        <tbody>
          <tr>
            <td>
              <img src={ico} alt="" className="pe-5" />
            </td>
            <td>{t("commonApiErrors.maintenanceBreak.content")}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default MaintenanceBreakModal;
