import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Brick = ({ variant = "white", content, uppercase = false }) => (
  <span className={`brick brick--${variant}${uppercase ? " brick--uppercase" : ""}`}>{content}</span>
);

Brick.propTypes = {
  content: PropTypes.any,
  backgroundColor: PropTypes.oneOf(["white", "silver", "gray"]),
  uppercase: PropTypes.bool,
};

export default Brick;
