import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import { FACTORY_RESET_AGREEMENT_STATUSES } from "utils/config";
import { getAccordionContentForProductList } from "../utils";
import { isUnderWarranty } from "./utils";

const ProductList = ({ summaryDetails }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
  } = useSelector((store) => store);

  const accordionHeadings = {
    description: t("global.description"),
    producer: t("global.producer"),
    sellDate: t("addReport.sellDate"),
    status: t("global.warrantyStatus"),
    faultDescription: t("addReport.faultDescription"),
    extraEquipment: t("addReport.extraEquipment"),
    attachments: t("addReport.attachments"),
    factoryResetAgreement: t("myRepairs.factoryResetAgreement"),
  };

  const underWarrantyMap = {
    true: t("addReport.warrantyCoveredTo"),
    false: t("addReport.warrantyExpiredAt"),
  };

  return (
    <Accordion
      content={summaryDetails?.products?.map(({ warrantyEnd, factoryResetAgreement, ...rest }, index) => {
        return getAccordionContentForProductList(
          {
            warrantyEnd: `${underWarrantyMap[isUnderWarranty(warrantyEnd)]} ${warrantyEnd}`,
            factoryResetAgreement: t(`global.${FACTORY_RESET_AGREEMENT_STATUSES[factoryResetAgreement === null ? "noData" : factoryResetAgreement]}`),
            ...rest,
          },
          accordionHeadings,
          constants,
          index,
          { desktop: true },
        );
      })}
    />
  );
};

export default ProductList;
