import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import { isAdmin, isReception } from "utils/global-functions";
import EmptyPage from "../EmptyPage";
import Gallery from "../Gallery";
import "./styles.css";

const Content = ({ setPhotosModalActive, photos, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosPreviewModalActive }) => {
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const { t } = useTranslation();

  return (
    <>
      <div className="mobile-repair-photos__card-heading">
        <h4 className="heading--4  heading--large-spacing">{t("myRepairs.photos")}</h4>
        {(isReception(user) || isAdmin(user) || repairDetails.technicianId === user.id) && (
          <Button variant="primary" size="small" onClick={() => setPhotosModalActive(true)}>
            {t("myRepairs.addPhoto")}
          </Button>
        )}
      </div>
      {!!photos.length ? (
        <Gallery
          setPhotosModalActive={setPhotosModalActive}
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          photos={photos}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      ) : (
        <EmptyPage />
      )}
    </>
  );
};

export default Content;
