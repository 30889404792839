import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Badge from "stories/components/Badge/Badge";
import Stack from "stories/components/Stack/Stack";
import Table from "stories/components/Table/Table";
import Info from "images/InfoRect.svg";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { isEmployee } from "utils/global-functions";
import { getClassesForSpecifiedRepair, isMyRepairPage } from "../utils";
import TechnicianSelector from "./TechnicianSelector";

const RepairsTableRow = ({ repair }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
  } = useSelector((store) => store);
  const [repairWarnings, setRepairWarnings] = useState(repair.repairWarnings);

  return (
    <Table.Row key={repair.repairId}>
      <Table.Cell>
        <Link to={`/${repair.repairId}/info`} className="custom-link display--block line-height--4">
          {repair.repairId}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <span className="text--nowrap">{repair.registrationDate}</span>
      </Table.Cell>
      <Table.Cell>
        <Stack flexDirection="column">
          <span className={`text--nowrap ${getClassesForSpecifiedRepair(repairWarnings)}`}>{repair.serialNumber || "-"}</span>
          <span className={`text--nowrap ${getClassesForSpecifiedRepair(repairWarnings)}`}>{repair.partNumber || "-"}</span>
        </Stack>
      </Table.Cell>
      <Table.Cell>{constants?.producers?.[repair?.producerId]?.strVal || "-"}</Table.Cell>
      {(isEmployee(user) || !isMyRepairPage()) && <Table.Cell>{repair.customer || "-"}</Table.Cell>}
      <Table.Cell>
        <Badge
          content={t(`constants.repair.kind.${constants?.repairKind?.[repair.repairKind]?.strVal}`)}
          backgroundColor={constants?.repairKind?.[repair.repairKind]?.bgColor}
        />
      </Table.Cell>
      <Table.Cell>
        <Badge
          content={t(`constants.repair.state.${constants?.repairState?.[repair.repairState]?.strVal}`)}
          backgroundColor={constants?.repairState?.[repair.repairState]?.bgColor}
        />
      </Table.Cell>
      {isEmployee(user) && (
        <Table.Cell>
          <TechnicianSelector technicianId={repair.technicianId} repairId={repair.repairId} setRepairWarnings={setRepairWarnings} />
        </Table.Cell>
      )}
      {isEmployee(user) && (
        <Table.Cell>
          {repairWarnings.length > 0 && (
            <div className="admins-repairs-table__info-wrapper">
              <Tooltip
                content={`<ul class="admins-repairs-info__list">\n${repairWarnings
                  .map((warning) => `<li key={${warning}}>${t(`constants.repair.warning.${warning}`)}</li>`)
                  .join("\n")}\n</ul>`}
                direction="left"
              >
                <img src={Info} className="admins-repairs-table__info-icon" alt="i" />
              </Tooltip>
            </div>
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default RepairsTableRow;
