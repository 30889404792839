import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import Tick from "images/Tick.svg";

const ProsCard = () => {
  const { t } = useTranslation();

  return (
    <div className="mobile_login_card">
      <Stack flexDirection="column" gap="1.5rem">
        <h3>{t("loginPage.rmaPros")}</h3>
        <Stack flexDirection="column" gap="1rem">
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros1")}</span>
          </div>
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros2")}</span>
          </div>
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros3")}</span>
          </div>
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros4")}</span>
          </div>
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros5")}</span>
          </div>
          <div className="mobile_login_card__pros_item">
            <img className="mobile_login_card__tick" src={Tick} alt="-" />
            <span>{t("loginPage.pros6")}</span>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default ProsCard;
