import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Desktop = ({ configuration, serialNumber }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
  } = useSelector((store) => store);

  return (
    <div>
      <table className="management-config">
        <thead>
          <tr>
            <th colSpan={4} className="pb-2">
              <h5 className="text--black heading--5">{t("global.computer")}:</h5>
            </th>
          </tr>
          <tr>
            <th>{t("myRepairs.partNumber")}</th>
            <th>{t("myRepairs.description")}</th>
            <th>{t("myRepairs.producer")}</th>
            <th>{t("myRepairs.serialNumber")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={serialNumber === configuration.serialNumber ? "text--red text--bold" : ""}>
            <td>{`${configuration.partNumber}`}</td>
            <td>{`${configuration.description}`}</td>
            <td>{`${constants?.producers?.[configuration.producerId]?.strVal}`}</td>
            <td>{`${configuration.serialNumber}`}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4} className="pt-5 pb-2">
              <h5 className="text--black heading--5">{t("global.components")}:</h5>
            </th>
          </tr>
          <tr>
            <th>{t("myRepairs.partNumber")}</th>
            <th>{t("myRepairs.description")}</th>
            <th>{t("myRepairs.producer")}</th>
            <th>{t("myRepairs.serialNumber")}</th>
          </tr>
        </thead>
        <tbody>
          {configuration?.items?.map((item) => (
            <tr key={item.serialNumber} className={serialNumber === item.serialNumber ? "text--red text--bold" : ""}>
              <td>{`${item.partNumber}`}</td>
              <td>{`${item.description}`}</td>
              <td>{`${constants?.producers?.[item.producerId]?.strVal}`}</td>
              <td>{`${item.serialNumber}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Desktop;
