import React from "react";
import "./styles.css";
import TopPanel from "./TopPanel";

const CookiePolicy = () => (
  <div className="cookie-policy">
    <TopPanel />
    <div className="page-content cookie-policy__wrapper">
      <h1 className="heading--3 cookie-policy__heading">Cookie Policy at Elmark Automatyka S.A.</h1>
      <ol className="cookie-policy__list">
        <li>
          The website of ELMARK Automatyka S.A. (hereinafter: the Website) uses cookies to provide high level of services and to adapt the Website to user’s
          needs.
        </li>
        <li>
          Cookie files ("cookies") are IT data, in particular text files, which are stored in the end device of the Website user and are used for enabling
          functionality of the Website. Cookies usually contain the name of the website they come from, the storage time on the end device and an unique number.
        </li>
        <li>
          Using the Website without changing the cookie settings means they will be saved on the end device. Changes to cookies saving options are possible at
          any time from the browser settings.
        </li>
        <li>Cookies are used for the following purposes:</li>
        <ol className="cookie-policy__list">
          <li>
            Adapting the content of the Website to the user's preferences and optimizing the Website; in particular, cookies allow to recognize the Website
            user's device and properly display the Website, tailored the Website view to user’s individual needs.
          </li>
          <li>Creating statistics that help to understand how users use Website, which enables improvement of its structure and content.</li>
          <li>
            Maintaining the Website user's session (after logging in), so that the user does not have to re-enter the login and password on each subpage of the
            Website;
          </li>
        </ol>
        <li>
          The Website uses two basic types of cookies: session cookies and persistent cookies. Session cookies are temporary files that are stored on the user's
          end device until logging out, leaving the Website or turning off the software (web browser). "Persistent" cookies are stored on the user's end device
          for the time specified in the cookie file parameters or until they are deleted by the user.
        </li>
        <li>In addition, the Website uses the following types of cookies:</li>
        <ol className="cookie-policy__list">
          <li>
            "Necessary" cookies, enabling the use of services available on the Website, e.g. authentication cookies used for services that require
            authentication on the Website;
          </li>
          <li>Cookies used to ensure security, e.g. used to detect incorrect authentication;</li>
          <li>"Performance" cookies, enabling the collection of information on how the Website is used;</li>
          <li>
            "Functional" cookies, enabling the user interface to be saved and personalized, e.g. language or region the user comes from, font size, Website
            appearance, etc .;
          </li>
          <li>"Advertising" cookies, enabling users to provide advertising content tailored to their interests.</li>
        </ol>
        <li>As part of the ELMARK Automatyka S.A. Website the following systems that generate cookies are used:</li>
        <ol className="cookie-policy__list">
          <li>Magento (Magento Privacy | Magento)</li>
          <li>Google Analytics (Privacy Policy - Privacy & Terms - Google)</li>
          <li>Facebook (Cookie Policy (facebook.com))</li>
          <li>Linkedin (LinkedIn Privacy Policy)</li>
          <li>Pardot (Salesforce's Privacy Information - Salesforce.com)</li>
        </ol>
        <li>
          In many cases, the software used for web browser allows cookies to be stored on the user's end device by default. Website users can change cookie
          settings at any time. These settings can be changed in particular to block the automatic handling of cookies in the web browser settings or to inform
          about cookies being saved on user’s device. Detailed information on the possibilities and methods of how cookies are used is available in the web
          browser settings.
        </li>
        <li>Restricting the use of cookies may affect some of the Website functionalities.</li>
        <li>Cookies placed on the Website user's end device may also be used by advertisers and partners cooperating with the Website operator.</li>
        <li>
          The Website has been designed with the compatibility to following browsers: Microsoft Internet Explorer 10+, Mozilla Firefox 16.0.2+, Google Chrome
          22.0.1229+.
        </li>
      </ol>
    </div>
  </div>
);

export default CookiePolicy;
