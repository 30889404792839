import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Stack from "stories/components/Stack/Stack";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import MinimalTable from "stories/components/MinimalTable/MinimalTable";
import Modal from "stories/components/Modal/Modal";
import { bulkActionModalHeadings, datePickerBulkActions } from "./config";
import BulkActionForm from "./BulkActionForm";

const BulkActionModal = ({ active, setActive, selectedItems, setSelectedItems, refreshList, setRefreshList }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const {
    user,
    app: { commonApiErrors },
    repair: { repairsList },
  } = useSelector((store) => store);
  const [formErrors, setFormErrors] = useState({});

  if (!active) return;

  const handleBulkActionSubmitSuccess = (response) => {
    if (response.result === 301) {
      setActive(false);
      setSelectedItems([]);
      return setRefreshList(!refreshList);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const handleBulkActionSubmitError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const handleBulkActionSubmit = (values) => {
    const formatedValue = datePickerBulkActions.includes(values.action) ? values.value.format("YYYY-MM-DD") : values.value;
    return agent.Repairs.repairBulkAction({
      id: user.id,
      token: user.token,
      repairIds: JSON.stringify(selectedItems),
      action: values.action,
      value: formatedValue,
    }).then(handleBulkActionSubmitSuccess, handleBulkActionSubmitError);
  };

  return (
    <Formik initialValues={{ action: "", value: "" }} onSubmit={(values) => handleBulkActionSubmit(values)}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            id="bulkActionModalId"
            title={t("global.bulkAction")}
            size={mobile ? "default" : "elastic"}
            onClose={() => {
              setFormErrors({});
              setActive(false);
            }}
            footerActions={
              <div className="group-action-modal__buttons-wrapper">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={isSubmitting}
                  onClick={() => {
                    setFormErrors({});
                    setActive(false);
                  }}
                >
                  {t("global.cancel")}
                </Button>
                <Button size={mobile ? "small" : "default"} loading={isSubmitting} type="submit" disabled={!values.value || !values.action} variant="primary">
                  {t("global.proceed")}
                </Button>
              </div>
            }
          >
            <div className="group-action-modal__content">
              <div className="group-action-modal__content-item group-action-modal__content-item--list">
                <MinimalTable
                  headings={bulkActionModalHeadings.map((heading) => (
                    <span className="text--preline">{t(`myRepairs.${heading}`)}</span>
                  ))}
                  rows={repairsList
                    .filter((repair) => selectedItems.some((selectedItem) => selectedItem === repair.repairId))
                    .map((repair) => [
                      repair.repairId,
                      <Stack flexDirection="column">
                        <span>{repair.serialNumber}</span>
                        <span>{repair.partNumber}</span>
                      </Stack>,
                    ])}
                />
              </div>
              <div className="group-action-modal__content-item group-action-modal__content-item--major">
                <BulkActionForm values={values} setFieldValue={setFieldValue} formErrors={formErrors} />
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default BulkActionModal;
