import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "stories/components/Drawer/Drawer";
import Button from "stories/components/Button/Button";
import { isEmployee, writeCookie } from "utils/global-functions";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import Filters from "../Filters";
import { defaultPageSizeCookieDaysExpire, repairsPageSizeOptions } from "../config";

const TopActions = ({
  setDeleteTabModal,
  setActiveManageTabsModal,
  repairListLoading,
  activeFiltersDrawer,
  setActiveFiltersDrawer,
  pageSize,
  setPageNumber,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { tabs },
  } = useSelector((store) => store);
  const dispatch = useDispatch();

  const handlePageSizeChange = (value) => {
    writeCookie({ key: "repairsPageSize", value: value, day: defaultPageSizeCookieDaysExpire });
    dispatch({ type: "REPAIR_SET_PAGESIZE", pageSize: value });
  };

  return (
    <div className="mobile-top-actions">
      <div className="mobile-top-action mobile-top-action--top">
        <div className="mobile-top-actions__group">
          <PageSizeSelector selectedPageSize={pageSize} onChange={handlePageSizeChange} pageSizeOptions={repairsPageSizeOptions} />
        </div>
        <div className="mobile-top-actions__group">
          <Drawer
            active={activeFiltersDrawer}
            setActive={setActiveFiltersDrawer}
            title={t("global.filters")}
            activator={
              <div className="mobile-top-actions__button-wrapper">
                <Button disabled={repairListLoading} size="small" variant="primary" onClick={() => setActiveFiltersDrawer(!activeFiltersDrawer)}>
                  {t("global.filters")}
                </Button>
                {isEmployee(user) && (
                  <Button size="small" onClick={() => setDeleteTabModal(true)} disabled={tabs.length === 0 || repairListLoading} variant="error" outline>
                    {t("global.deleteTab")}
                  </Button>
                )}
              </div>
            }
          >
            <Filters
              setActiveManageTabsModal={setActiveManageTabsModal}
              setActiveFiltersDrawer={setActiveFiltersDrawer}
              repairListLoading={repairListLoading}
              setPageNumber={setPageNumber}
            />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default TopActions;
