import React from "react";
import PropTypes from "prop-types";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TableEmptyState from "./TableEmptyState";
import TableSpinner from "./TableSpinner";
import "./styles.css";

const Table = ({ headings, children, selectable = false, selected, setSelected, loading = false, emptyState, extended = false }) => (
  <>
    <table className="custom-table">
      <TableHead selectable={selectable} headings={headings} />
      {!loading && <TableBody extended={extended} selectable={selectable} selected={selected} setSelected={setSelected} rows={children} />}
    </table>
    {!children?.length && !loading && (emptyState || <TableEmptyState />)}
    {loading && <TableSpinner />}
  </>
);

Table.Cell = TableCell;
Table.Row = TableRow;

Table.propTypes = {
  headings: PropTypes.array,
  children: PropTypes.any,
  selectable: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default Table;
