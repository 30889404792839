import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Badge from "stories/components/Badge/Badge";
import Table from "stories/components/Table/Table";

const RepairsLogTableRow = ({ repair }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
  } = useSelector((store) => store);

  return (
    <Table.Row extended>
      <Table.Cell>
        <Link className="custom-link" to={`/${repair.repairId}/info`}>
          {repair.repairId}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <span className="paragraph--small text--nowrap">{repair.registrationDate}</span>
      </Table.Cell>
      <Table.Cell>
        <span className="paragraph--small text--nowrap">{repair.endDate || "-"}</span>
      </Table.Cell>
      <Table.Cell>
        <Badge
          content={t(`constants.repair.type.${constants?.repairType[repair.repairType]?.strVal}`)}
          backgroundColor={constants?.repairType[repair.repairType]?.bgColor}
        />
      </Table.Cell>
      <Table.Cell>
        <Badge
          content={t(`constants.repair.kind.${constants?.repairKind[repair.repairKind]?.strVal}`)}
          backgroundColor={constants?.repairKind[repair.repairKind]?.bgColor}
        />
      </Table.Cell>
      <Table.Cell>
        <Badge
          content={t(`constants.repair.state.${constants?.repairState[repair.repairState]?.strVal}`)}
          backgroundColor={constants?.repairState[repair.repairState]?.bgColor}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default RepairsLogTableRow;
