import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";

const DeleteRepairWarningModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteRepairLoading, setDeleteRepairLoading] = useState(false);

  const deleteRepairSuccess = (response) => {
    if (response.result === 301) {
      return navigate("/");
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deleteRepairError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const deleteRepair = () => {
    setDeleteRepairLoading(true);
    agent.Repairs.deleteRepair({ id: user.id, token: user.token, repairId: repairDetails.repairId })
      .then(deleteRepairSuccess, deleteRepairError)
      .finally(() => setDeleteRepairLoading(false));
  };

  if (!active) return;

  return (
    <Modal
      title={t("myRepairs.deleteRepair")}
      onClose={() => setActive(false)}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button size={mobile ? "small" : "default"} onClick={() => setActive(false)} disabled={deleteRepairLoading}>
            {t("global.no")}
          </Button>
          <Button variant="primary" size={mobile ? "small" : "default"} onClick={() => deleteRepair()} loading={deleteRepairLoading}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <h6 className="heading--6">{t("myRepairs.deleteRepairConfirmationHeading")}</h6>
      <p className="paragraph--small text--subdued">{t("myRepairs.deleteRepairConfirmationBody")}</p>
    </Modal>
  );
};

export default DeleteRepairWarningModal;
