/*
Author: jacek.bonecki@elmark.com.pl
rObject: czy ma zwracać Badgesy czy sam tekst
rClasses: czy ma otaczać w div'a z tymi klasami
*/

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Badge from "stories/components/Badge/Badge";
import Brick from "stories/components/Brick/Brick";
import { getRandomComponentId } from "stories/components/Modal/utils";
import "./RepairEvents.css";

const RepairEventsDecode = ({ eventType, value, rObject = true, rClasses = false, brickVariant = "white" }) => {
  const { t } = useTranslation();
  const app = useSelector((store) => store.app);
  const user = useSelector((store) => store.user);

  let v = null;
  let r = null;

  switch (eventType) {
    /****************/
    /* repairSource */
    /****************/
    case 1:
      v = typeof app.constants.repairSource?.[value] === "object" ? app.constants.repairSource[value] : null;
      r = v ? (
        rObject ? (
          <Badge content={t(`constants.repair.source.${v.strVal}`)} backgroundColor={v.bgColor} />
        ) : (
          t(`constants.repair.source.${v.strVal}`)
        )
      ) : rObject ? (
        <Badge content={`${value} (${t("global.notDefinedConstant")})`} backgroundColor="transparent" />
      ) : (
        `${value} (${t("global.notDefinedConstant")})`
      );
      break;
    /**************/
    /* repairType */
    /**************/
    case 2:
      v = typeof app.constants.repairType?.[value] === "object" ? app.constants.repairType[value] : null;
      r = v ? (
        rObject ? (
          <Badge content={t(`constants.repair.type.${v.strVal}`)} backgroundColor={v.bgColor} />
        ) : (
          t(`constants.repair.type.${v.strVal}`)
        )
      ) : rObject ? (
        <Badge content={`${value} (${t("global.notDefinedConstant")})`} backgroundColor="transparent" />
      ) : (
        `${value} (${t("global.notDefinedConstant")})`
      );
      break;
    /**************/
    /* repairKind */
    /**************/
    case 3:
      v = typeof app.constants.repairKind?.[value] === "object" ? app.constants.repairKind[value] : null;
      r = v ? (
        rObject ? (
          <Badge content={t(`constants.repair.kind.${v.strVal}`)} backgroundColor={v.bgColor} />
        ) : (
          t(`constants.repair.kind.${v.strVal}`)
        )
      ) : rObject ? (
        <Badge content={`${value} (${t("global.notDefinedConstant")})`} backgroundColor="transparent" />
      ) : (
        `${value} (${t("global.notDefinedConstant")})`
      );
      break;
    /***************/
    /* repairState */
    /***************/
    case 4:
      v = typeof app.constants.repairState?.[value] === "object" ? app.constants.repairState[value] : null;
      r = v ? (
        rObject ? (
          <Badge content={t(`constants.repair.state.${v.strVal}`)} backgroundColor={v.bgColor} />
        ) : (
          t(`constants.repair.state.${v.strVal}`)
        )
      ) : rObject ? (
        <Badge content={`${value} (${t("global.notDefinedConstant")})`} backgroundColor="transparent" />
      ) : (
        `${value} (${t("global.notDefinedConstant")})`
      );
      break;
    /***************/
    /* technicianId */
    /***************/
    case 6:
      v = typeof app.constants.employees?.[value] === "object" ? app.constants.employees[value] : null;
      r = v ? (
        rObject ? (
          <Badge content={v.strVal} />
        ) : (
          v.strVal
        )
      ) : rObject ? (
        <Badge content={value ? `${value} (${t("global.notDefinedConstant")})` : <span>&nbsp;</span>} backgroundColor="transparent" />
      ) : value ? (
        `${value} (${t("global.notDefinedConstant")})`
      ) : (
        <span>&nbsp;</span>
      );
      break;
    /*************/
    /* isInQueue */
    /*************/
    case 17:
      switch (value) {
        case "-1":
          value = "offTheQueue";
          break;
        case "0":
          value = "outOfQueue";
          break;
        case "1":
          value = "inQueue";
          break;
        default:
      }
      r = rObject ? <Badge content={t(`global.${value}`)} /> : value;
      break;
    case 9 /*replacementSerialNumberChange*/:
    case 12 /*userIdChange*/:
    case 13 /*serialNumberChange*/:
    case 15 /*contactDataChange*/:
    case 16 /*returnChange*/:
    case 51 /*repairDuplicated*/:
    case 60 /*attachmentAdded*/:
    case 61 /*attachmentUpdated*/:
    case 62 /*attachmentDeleted*/:
      let content = value !== null ? value : <span>&nbsp;</span>;
      try {
        const json = JSON.parse(value);
        content = (
          <table className="repair-events-table">
            <tbody>
              {Object.entries(json)
                .filter((item) => !["idFull", "isExternal", "isReplacement"].includes(item[0].substring(3)) || [1, 2, 3].includes(user.roleId))
                .sort((item1, item2) => {
                  if (parseInt(item1[0].substring(0, 2)) < parseInt(item2[0].substring(0, 2))) return -1;
                  else if (parseInt(item1[0].substring(0, 2)) > parseInt(item2[0].substring(0, 2))) return 1;
                  else return 0;
                })
                .map((item) => (
                  <tr key={getRandomComponentId()}>
                    <td>{t(`global.${item[0].substring(3)}`)}</td>
                    <td>
                      {item[0].substring(3) === "pickupMethod"
                        ? t(`constants.return.type.${app.constants.returnType?.[item[1]].strVal}`)
                        : item[1] !== null
                        ? item[1]
                        : eventType === 15
                        ? t("global.currentContactData")
                        : "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        );
      } catch (err) {}
      r = rObject ? <Brick content={content} variant={brickVariant} /> : content;
      break;
    /**********/
    /* Others */
    /**********/
    default:
      r = rObject ? (
        <Brick content={value !== null && value.length ? value : <span>&nbsp;</span>} variant={brickVariant} />
      ) : value !== null && value.length ? (
        value
      ) : (
        <span>&nbsp;</span>
      );
  }

  return rClasses ? <div className={rClasses}>{r}</div> : r;
};

export default RepairEventsDecode;
