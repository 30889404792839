import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "../commonStyles.css";
import "../Button/styles.css";
import { getButtonClassName } from "./utils";
import "./styles.css";

const ButtonLink = ({
  type = "button",
  disabled = false,
  loading = false,
  outline = false,
  fullWidth = false,
  fullHeight = false,
  variant = "default",
  size = "default",
  className,
  children,
  url,
  ...rest
}) => (
  <Link
    disabled={disabled || loading}
    type={type}
    to={url}
    className={`button-link button ${getButtonClassName({ variant, size, fullWidth, disabled, loading, outline, fullHeight })} ${className ? className : ""}`}
    {...rest}
  >
    <div className="button__content">
      {loading && <div className="spinner-border" role="status" style={{ width: "1.5rem", height: "1.5rem", borderWidth: "0.25rem" }} />}
      <div>{children}</div>
    </div>
  </Link>
);

ButtonLink.propTypes = {
  variant: PropTypes.oneOf(["default", "primary", "error"]),
  size: PropTypes.oneOf(["big", "default", "small"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  outline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  url: PropTypes.string,
};

export default ButtonLink;
