import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import Badge from "stories/components/Badge/Badge";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import { isAdmin } from "utils/global-functions";
import { canApproveValuation, canCloseValuationResult } from "../utils";

const ValuationOffer = ({
  conversation: {
    id,
    currency,
    createdAt,
    text,
    isClosed,
    amount,
    recipientId,
    effect,
    attachments,
    resultText,
    resultAttachments,
    resultId,
    resultIsClosed,
    resultRecipientId,
  },
  setConfirmModalActive,
  setSelectedConversationId,
  setAddValuationModalActive,
  setConfirmActionName,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((user) => user);

  return (
    <div className="mobile-price-offer">
      <Stack gap="1rem" justifyContent="space-between">
        <h6 className="heading--6">{t("myRepairs.repairValuation")}</h6>
        {!isClosed && isAdmin(user) && (
          <Stack gap="1rem" alignItems="baseline">
            <button
              className="unset-all custom-link--black"
              onClick={() => {
                setSelectedConversationId(id);
                setAddValuationModalActive(true);
              }}
              size="small"
            >
              {t("global.edit")}
            </button>
            <button
              className="unset-all custom-link"
              onClick={() => {
                setSelectedConversationId(id);
                setConfirmModalActive(true);
                setConfirmActionName("delete");
              }}
              size="small"
            >
              {t("global.delete")}
            </button>
          </Stack>
        )}
      </Stack>
      <p className="paragraph--small no-margin">{`${t("global.diagnosis")}: ${text}`}</p>
      <span className="paragraph--small text--bold">{`${t("global.cost")}: ${amount} ${currency} ${t("global.net").toLowerCase()}`}</span>
      {!!attachments?.length && (
        <ul>
          {attachments.map((attachment) => (
            <li key={attachment.id}>
              <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                {attachment.fileName}
              </a>
            </li>
          ))}
        </ul>
      )}
      {(!!effect || effect === 0) && (
        <div className="valuation-result">
          <Badge backgroundColor={effect ? "green" : "red"} content={effect ? t("myRepairs.accepted") : t("myRepairs.rejected")} />
          {resultText?.trim() && (
            <Stack flexDirection="column">
              <span className="paragraph--small text--subdued">{t("myRepairs.valuationComment")}</span>
              <span>{resultText}</span>
            </Stack>
          )}
          {!!resultAttachments?.length && (
            <Stack flexDirection="column">
              <span className="paragraph--small text--subdued">{t("global.uploadedAttachments")}</span>
              <ul>
                {resultAttachments.map((attachment) => (
                  <li key={attachment.id}>
                    <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                      {attachment.fileName}
                    </a>
                  </li>
                ))}
              </ul>
            </Stack>
          )}
          {canCloseValuationResult(resultIsClosed, user, resultRecipientId) && (
            <Button
              outline
              variant="error"
              size="small"
              onClick={() => {
                setSelectedConversationId(resultId);
                setConfirmModalActive(true);
                setConfirmActionName("close");
              }}
            >
              {t("global.close")}
            </Button>
          )}
        </div>
      )}
      <div className="mobile-price-offer__footer">
        <span className="paragraph--small text--subdued">{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
      </div>
      {canApproveValuation(isClosed, user, recipientId) && (
        <div className="mobile-price-offer__buttons-wrapper">
          <Button
            size="small"
            outline
            variant="error"
            onClick={() => {
              setSelectedConversationId(id);
              setConfirmModalActive(true);
              setConfirmActionName("reject");
            }}
          >
            {t("global.reject")}
          </Button>
          <Button
            size="small"
            variant="primary"
            onClick={() => {
              setConfirmModalActive(true);
              setSelectedConversationId(id);
              setConfirmActionName("approve");
            }}
          >
            {t("global.accept")}
          </Button>
        </div>
      )}
      <div className="mobile-price-offer__border" />
    </div>
  );
};

export default ValuationOffer;
