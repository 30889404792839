import { combineReducers } from "redux";
import appReducer from "./appReducer";
import repairReducer from "./repairReducer";
import registrationReducer from "./registrationReducer";
import userReducer from "./userReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  repair: repairReducer,
  registration: registrationReducer,
});
