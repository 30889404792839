import React from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "stories/components/Dropzone/Dropzone";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField } from "utils/global-functions";

const ConfirmValuationForm = ({ values, setFieldValue, errors, touched, formErrors, handleBlur }) => {
  const { t } = useTranslation();

  return (
    <div className="add-pricing-form">
      <TextField
        id="conversationText"
        name="conversationText"
        variant="multi"
        value={values.conversationText}
        onChange={(value) => setFieldValue("conversationText", value)}
        placeholder={t("global.writeAComment")}
        label={t("global.comment")}
        numberOfLines={6}
        disableResize
        onBlur={handleBlur}
        error={getErrorForInputField(errors.conversationText, touched.conversationText) || formErrors.conversationText}
      />
      <Dropzone label={t("addReport.attachments")} files={values.attachments} addFilesValue={(value) => setFieldValue("attachments", value)} />
    </div>
  );
};

export default ConfirmValuationForm;
