import list from "images/List.svg";
import action from "images/Action.svg";
import stack from "images/Stack.svg";
import wrench from "images/Wrench.svg";
import book from "images/Book.svg";
import info from "images/info.svg";
import barcode from "images/Barcode.svg";

export const routes = {
  customer: ["/", "/other-repairs", "/actions", "/help"],
  admin: ["/", "/changelog", "/actions", "/producerreport", "/externalserial", "/management", "/help"],
  reception: ["/", "/changelog", "/actions", "/producerreport", "/externalserial"],
  technician: ["/", "/actions", "/producerreport"],
};

export const routesIcons = new Map([
  ["/", list],
  ["/other-repairs", list],
  ["/reports", list],
  ["/actions", action],
  ["/changelog", stack],
  ["/history", stack],
  ["/producerreport", wrench],
  ["/management", book],
  ["/help", info],
  ["/externalserial", barcode],
]);

export const pathWithoutTopBar = ["/cookie-policy", "/serial-number/"];

export const pathWithoutNavigationBar = ["/cookie-policy", "/serial-number/"];
