import React from "react";
import lodash from "lodash";
import { useSelector } from "react-redux";
import Login from "./Login/Login";

const Protected = ({ children }) => {
  const {
    user,
    app: { constants },
  } = useSelector((store) => store);

  if (lodash.isEmpty(user) || !constants) return <Login />;

  return children;
};

export default Protected;
