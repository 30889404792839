/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import SelectField from "stories/components/SelectField/SelectField";
import { getPartNamesList, getPartNamesOptions, savePartNameToCookies } from "./utils";
import "./styles.css";

const ReportModal = ({
  modalVariant, //options: "add"/"edit"
  setCanDelete,
  repair,
  report,
  dictionaries,
  producerReportManagePart,
  getReportDetails,
  active,
  onClose,
  onAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);

  const [disabled, setDisabled] = useState(true);

  const [addFirstForm, setAddFirstForm] = useState(true);
  const [editFirstForm, setEditFirstForm] = useState(false);
  const [partsForm, setPartsForm] = useState(true);
  const [editPartsForm, setEditPartsForm] = useState(true);
  const [editPartId, setEditPartId] = useState();
  const [partsList, setPartsList] = useState(true);
  const [addNewPart, setAddNewPart] = useState(true);

  const [problem, setProblem] = useState("");
  const [findings, setFindings] = useState("");
  const [malfunction, setMalfunction] = useState("");
  const [testProcedure, setTestProcedure] = useState("");

  const [partName, setPartName] = useState("");
  const [pcbSerialNumber, setPcbSerialNumber] = useState("");
  const [repairStatus, setRepairStatus] = useState("");
  const [location, setLocation] = useState("");
  const [qty, setQty] = useState(1);

  const dictionariesFindings = dictionaries?.filter(function (el) {
    return el.dict === "findings";
  });
  const dictionariesMalfunction = dictionaries?.filter(function (el) {
    return el.dict === "malfunction";
  });
  const dictionariesRepairStatus = dictionaries?.filter(function (el) {
    return el.dict === "repairStatus";
  });

  useEffect(() => {
    if (active && modalVariant === "edit") {
      setDisabled(false);
      setAddFirstForm(false);
      setEditFirstForm(false);
      setPartsList(false);
      setProblem(report.problem);
      setFindings(report.findings);
      setMalfunction(report.malfunction);
      setTestProcedure(report.testProcedure);
      setAddNewPart(false);
    }
  }, [active]);

  if (!active) return;

  return (
    <Modal
      title={`${modalVariant === "edit" ? t("producerReport.editModalTitle") : t("producerReport.addModalTitle")} ${repair.repairId}`}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          {modalVariant === "add" && (
            <Button
              style={{ width: "6rem" }}
              onClick={() => {
                onClose();
                setProblem("");
                setFindings("");
                setMalfunction("");
                setTestProcedure("");
                setPartName("");
                setPcbSerialNumber("");
                setRepairStatus("");
                setLocation("");
                setQty(1);
                setEditPartId();
                modalVariant === "edit" && setPartsForm(true);
              }}
            >
              {t("global.cancel")}
            </Button>
          )}
          <Button
            variant="primary"
            disabled={disabled}
            style={{ width: "6rem" }}
            onClick={() => {
              onAction();
            }}
          >
            OK
          </Button>
        </Stack>
      }
      onClose={() => onClose()}
    >
      <div className="report-modal-content">
        {addFirstForm || editFirstForm ? (
          <div className="report-modal-content-form">
            <TextField
              label="Problem"
              value={problem ?? ""}
              placeholder={t("global.enterData")}
              onChange={(value) => {
                setProblem(value);
              }}
              error={problem?.length > 255 ? t(`errors.102`) : null}
            />
            <SelectField
              label="Findings"
              placeholder={t("global.chooseFromList")}
              isClearable={true}
              value={findings ?? ""}
              onChange={(value) => setFindings(value)}
              options={dictionariesFindings?.map(({ item }) => {
                return { value: item, label: item };
              })}
              specialActions={
                <Tooltip content={t("myRepairs.setSameInProblem")} zIndex={10000}>
                  <div className="pointer" onClick={() => setProblem(findings || "")}>
                    <svg height="2.5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z" />
                      </g>
                    </svg>
                  </div>
                </Tooltip>
              }
            />

            <SelectField
              label="Cause of malfunction"
              placeholder={t("global.chooseFromList")}
              isClearable={true}
              value={malfunction ?? ""}
              onChange={(value) => {
                setMalfunction(value);
              }}
              options={dictionariesMalfunction?.map(({ item }) => {
                return { value: item, label: item };
              })}
            />
            <TextField
              label="Test procedure"
              value={testProcedure ?? ""}
              placeholder={t("global.enterData")}
              onChange={(value) => {
                setTestProcedure(value);
              }}
              error={testProcedure?.length > 255 ? t(`errors.102`) : null}
            />
            <div className="report-modal-content-form-button">
              {addFirstForm ? (
                <Button
                  variant="primary"
                  size="small"
                  disabled={findings && problem.length <= 255 && testProcedure.length <= 255 ? false : true}
                  onClick={() => {
                    agent.ProducerReport.producerReportManage({
                      id: user.id,
                      token: user.token,
                      action: "add",
                      repairId: repair.repairId,
                      problem: problem ?? "",
                      findings: findings ?? "",
                      malfunction: malfunction ?? "",
                      testProcedure: testProcedure ?? "",
                    }).then(
                      () => {
                        setAddFirstForm(false);
                        setPartsForm(false);
                        setDisabled(false);
                        setCanDelete(true);
                      },
                      (error) => {
                        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
                        dispatch({
                          type: "APP_PUSHNOTIFICATION",
                          notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) },
                        });
                      },
                    );
                  }}
                >
                  {t("global.add")}
                </Button>
              ) : null}
              {editFirstForm ? (
                <Button
                  variant="primary"
                  size="small"
                  disabled={findings && problem?.length <= 255 && testProcedure?.length <= 255 ? false : true}
                  onClick={() => {
                    agent.ProducerReport.producerReportManage({
                      id: user.id,
                      token: user.token,
                      action: "update",
                      repairId: repair.repairId,
                      problem: problem ?? "",
                      findings: findings ?? "",
                      malfunction: malfunction ?? "",
                      testProcedure: testProcedure ?? "",
                    }).then(
                      () => {
                        setEditFirstForm(false);
                        if (modalVariant === "add" && partsList) setPartsForm(false);
                        setDisabled(false);
                      },
                      (error) => {
                        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
                        dispatch({
                          type: "APP_PUSHNOTIFICATION",
                          notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) },
                        });
                      },
                    );
                  }}
                >
                  {t("global.save")}
                </Button>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <div className="report-modal-content-form">
              <div>
                <div className="text--subdued">Problem</div>
                <div>{problem === null || problem === "" ? "-" : problem}</div>
              </div>
              <div>
                <div className="text--subdued">Findings</div>
                <div>{findings === null || findings === "" ? "-" : findings}</div>
              </div>
              <div>
                <div className="text--subdued">Malfunction</div>
                <div>{malfunction === null || malfunction === "" ? "-" : malfunction}</div>
              </div>
              <div>
                <div className="text--subdued">Test procedure</div>
                <div>{testProcedure === null || testProcedure === "" ? "-" : testProcedure}</div>
              </div>
            </div>
            <div className="report-modal-content-form-button">
              <Button
                variant="default"
                size="small"
                onClick={() => {
                  setEditFirstForm(true);
                  setPartsForm(true);
                  setDisabled(true);
                  problem ? setProblem(problem) : setProblem("");
                  testProcedure ? setTestProcedure(testProcedure) : setTestProcedure("");
                }}
              >
                {t("global.edit")}
              </Button>
            </div>
          </div>
        )}
        {/* ---------- PARTS ---------- */}
        <p className="paragraph text--subdued">Parts</p>
        {partsList ? null : (
          <>
            <div className="modal-parts-list-container">
              {report?.parts?.length === 0 ? (
                <div>No parts</div>
              ) : (
                <>
                  {report?.parts?.map(({ partId, pcbSerialNumber, partName, repairStatus, location, qty }, index) => {
                    return (
                      <div key={index}>
                        <div className="modal-parts-list-row">
                          <div className="modal-parts-list-row-cell-half">
                            <div className="text--subdued">PCB Serial number</div>
                            <div>{pcbSerialNumber}</div>
                          </div>
                          <div className="modal-parts-list-row-cell-half">
                            <div className="text--subdued">Part name</div>
                            <div>{partName}</div>
                          </div>
                        </div>
                        <div className="modal-parts-list-row">
                          <div className="modal-parts-list-row-cell-half">
                            <div className="text--subdued">Repair status</div>
                            <div>{repairStatus ?? "-"}</div>
                          </div>

                          <div className="modal-parts-list-row-cell-quater">
                            <div className="text--subdued">Location</div>
                            <div>{location}</div>
                          </div>
                          <div className="modal-parts-list-row-cell-quater">
                            <div className="text--subdued">Qty</div>
                            <div>{qty}</div>
                          </div>
                        </div>

                        <div className="report-modal-content-form-button">
                          <Button
                            variant="error"
                            size="small"
                            onClick={() => {
                              producerReportManagePart({
                                update: true,
                                action: "delete",
                                partId: partId,
                              });
                            }}
                          >
                            {t("global.delete")}
                          </Button>
                          <Button
                            variant="default"
                            size="small"
                            onClick={() => {
                              setEditPartsForm(false);
                              setPartsForm(false);
                              setPartsList(true);
                              setEditPartId(partId);
                              setPartName(report.parts[index].partName ?? "");
                              setPcbSerialNumber(report.parts[index].pcbSerialNumber ?? "");
                              setRepairStatus(report.parts[index].repairStatus ?? "");
                              setLocation(report.parts[index].location ?? "");
                              setQty(report.parts[index].qty ?? 1);
                              modalVariant === "edit" && setDisabled(true);
                            }}
                          >
                            {t("global.edit")}
                          </Button>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            {addNewPart ? null : (
              <div className="report-modal-content-form-button">
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    setPartsForm(false);
                    setAddNewPart(true);
                    setEditPartsForm(true);
                    if (report?.parts?.length) setPcbSerialNumber(report.parts.at(-1).pcbSerialNumber);
                    modalVariant === "edit" && setDisabled(true);
                  }}
                >
                  {t("producerReport.addNewPart")}
                </Button>
              </div>
            )}
          </>
        )}
        {partsForm ? null : (
          <div>
            <div>
              <div className="report-modal-content-row">
                <TextField
                  label="PCB serial number"
                  placeholder={t("global.enterData")}
                  value={pcbSerialNumber}
                  onChange={(value) => {
                    setPcbSerialNumber(value);
                  }}
                />
                <SelectField
                  async
                  cacheOptions
                  editable
                  id="partName"
                  name="partName"
                  label="Part name"
                  placeholder={t("global.enterData")}
                  value={partName}
                  options={getPartNamesOptions(partName)}
                  promiseOptions={getPartNamesList}
                  onChange={(value) => setPartName(value)}
                />
              </div>
              <div className="report-modal-content-row">
                <SelectField
                  label="Repair status"
                  placeholder={t("global.chooseFromList")}
                  isClearable={true}
                  value={repairStatus}
                  onChange={(value) => {
                    setRepairStatus(value);
                  }}
                  options={dictionariesRepairStatus?.map(({ item }) => {
                    return { value: item, label: item };
                  })}
                />
                <div className="report-modal-content-row">
                  <TextField
                    label="Location"
                    placeholder={t("global.enterData")}
                    value={location}
                    onChange={(value) => {
                      setLocation(value);
                    }}
                  />
                  <TextField
                    label="Qty"
                    variant="minimalistNumber"
                    min={1}
                    placeholder={t("global.enterData")}
                    value={qty}
                    onChange={(value) => {
                      setQty(value);
                    }}
                    error={(qty && qty < 1) || isNaN(qty) ? t(`errors.104`) : null}
                  />
                </div>
              </div>
            </div>
            <div className="report-modal-content-form-button">
              <Button
                variant="default"
                size="small"
                onClick={() => {
                  setPartsForm(true);
                  setPartsList(false);
                  setEditPartsForm(true);
                  setAddNewPart(false);
                  setPartName("");
                  setPcbSerialNumber("");
                  setRepairStatus("");
                  setLocation("");
                  setQty(1);
                  setDisabled(false);
                }}
              >
                {t("global.cancel")}
              </Button>
              <Button
                variant="primary"
                size="small"
                disabled={partName && pcbSerialNumber && location && qty && !isNaN(qty) ? false : true}
                onClick={() => {
                  if (!editPartsForm)
                    agent.ProducerReport.producerReportManagePart({
                      id: user.id,
                      token: user.token,
                      action: "update",
                      partId: editPartId,
                      partName: partName ?? "",
                      pcbSerialNumber: pcbSerialNumber ?? "",
                      repairStatus: repairStatus ?? "",
                      location: location ?? "",
                      qty: qty ?? "",
                    }).then(
                      () => {
                        savePartNameToCookies(partName);
                        getReportDetails();
                        setPartsForm(true);
                        setEditPartsForm(true);
                        setPartsList(false);
                        setAddNewPart(false);
                        setPartName("");
                        setPcbSerialNumber("");
                        setRepairStatus("");
                        setLocation("");
                        setQty(1);
                        setDisabled(false);
                      },
                      (error) => {
                        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
                        dispatch({
                          type: "APP_PUSHNOTIFICATION",
                          notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) },
                        });
                      },
                    );
                  else
                    agent.ProducerReport.producerReportManagePart({
                      id: user.id,
                      token: user.token,
                      action: "add",
                      repairId: repair.repairId,
                      partName: partName ?? "",
                      pcbSerialNumber: pcbSerialNumber ?? "",
                      repairStatus: repairStatus ?? "",
                      location: location ?? "",
                      qty: qty ?? "",
                    }).then(
                      () => {
                        savePartNameToCookies(partName);
                        getReportDetails();
                        setPartsForm(true);
                        setEditPartsForm(true);
                        setPartsList(false);
                        setAddNewPart(false);
                        setPartName("");
                        setPcbSerialNumber("");
                        setRepairStatus("");
                        setLocation("");
                        setQty(1);
                        setDisabled(false);
                      },
                      (error) => {
                        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
                        dispatch({
                          type: "APP_PUSHNOTIFICATION",
                          notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) },
                        });
                      },
                    );
                }}
              >
                {t("global.save")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReportModal;
