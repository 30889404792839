import agent from "api/agent";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { CONVERSATION_RATING_CUSTOMER, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";

const CloseClientRatingModal = ({ active, setActive, selectedConversationId, conversationListRefresh, setConversationListRefresh }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [closeClientRatingLoading, setCloseClientRatingLoading] = useState(false);

  const closeClientRatingSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setActive(false);
      return setConversationListRefresh(!conversationListRefresh);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const closeClientRatingError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const closeClientRating = () => {
    setCloseClientRatingLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      conversationType: CONVERSATION_RATING_CUSTOMER,
      action: "close",
      repairId: repairDetails.repairId,
      conversationId: selectedConversationId,
    })
      .then(closeClientRatingSuccess, closeClientRatingError)
      .finally(() => setCloseClientRatingLoading(false));
  };

  if (!active) return;

  return (
    <Modal
      title={t("myRepairs.closeClientRatingTitle")}
      onClose={() => setActive(false)}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button size={mobile ? "small" : "default"} disabled={closeClientRatingLoading} onClick={() => setActive(false)}>
            {t("global.no")}
          </Button>
          <Button size={mobile ? "small" : "default"} loading={closeClientRatingLoading} variant="primary" onClick={() => closeClientRating()}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <p className="paragraph--small">{t("myRepairs.closeClientRatingInfo")}</p>
    </Modal>
  );
};

export default CloseClientRatingModal;
