import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { CONVERSATION_CUSTOMER_APPROVAL, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import ConfirmValuationForm from "./ConfirmValuationForm";

const ConfirmActionModal = ({
  active,
  setActive,
  actionName,
  selectedConversationId,
  setSelectedConversationId,
  conversationList,
  setRefreshValuationList,
  refreshValuationList,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
    repair: { repairDetails },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [confirmActionLoading, setConfirmActionLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const confirmActionModalContents = {
    reject: { title: t("myRepairs.rejectValuation"), content: t("myRepairs.rejectValuationInfo") },
    approve: { title: t("myRepairs.approveValuation"), content: t("myRepairs.approveValuationInfo") },
    delete: { title: t("myRepairs.deleteValuation"), content: t("myRepairs.deleteValuationInfo") },
    close: { title: t("myRepairs.closeValuation"), content: t("myRepairs.closeValuationInfo") },
  };

  const confirmResult = {
    approve: 1,
    reject: 0,
  };

  const confirmActionSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setRefreshValuationList(!refreshValuationList);
      setActive(false);
      setSelectedConversationId(null);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const confirmActionError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const confirmValuation = (values) => {
    setConfirmActionLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      action: "add",
      conversationType: CONVERSATION_CUSTOMER_APPROVAL,
      parentId: selectedConversationId,
      conversationResult: confirmResult[actionName],
      ...values,
      conversationText: values.conversationText.trim(),
    })
      .then(confirmActionSuccess, confirmActionError)
      .finally(() => setConfirmActionLoading(false));
  };

  const deleteValuation = () => {
    setConfirmActionLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      action: "delete",
      conversationId: selectedConversationId,
      conversationType: conversationList.find(({ id }) => id === selectedConversationId).type,
    })
      .then(confirmActionSuccess, confirmActionError)
      .finally(() => setConfirmActionLoading(false));
  };

  const closeValuation = () => {
    setConfirmActionLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      action: "close",
      conversationId: selectedConversationId,
      conversationType: CONVERSATION_CUSTOMER_APPROVAL,
    })
      .then(confirmActionSuccess, confirmActionError)
      .finally(() => setConfirmActionLoading(false));
  };

  if (!active) return;

  return (
    <Formik
      initialValues={{ conversationText: "", attachments: [] }}
      onSubmit={(values) => {
        if (actionName === "delete") deleteValuation();
        if (actionName === "close") closeValuation();
        else confirmValuation(values);
      }}
    >
      {({ values, setFieldValue, touched, handleBlur, errors }) => (
        <Form>
          <Modal
            title={confirmActionModalContents[actionName].title}
            onClose={() => setActive(false)}
            footerActions={
              <Stack gap="var(--modal-buttons-gap)">
                <Button size={mobile ? "small" : "default"} disabled={confirmActionLoading} onClick={() => setActive(false)}>
                  {t("global.no")}
                </Button>
                <Button size={mobile ? "small" : "default"} loading={confirmActionLoading} type="submit" variant="primary">
                  {t("global.yes")}
                </Button>
              </Stack>
            }
          >
            <Stack flexDirection="column" gap="2rem">
              <p className="paragraph--small">{confirmActionModalContents[actionName].content}</p>
              {!["delete", "close"].includes(actionName) && (
                <Stack flexDirection="column" gap=".5rem">
                  <h6 className="heading--6">{t("global.additionalInformation")}</h6>
                  <ConfirmValuationForm
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleBlur={handleBlur}
                    errors={errors}
                    formErrors={formErrors}
                  />
                </Stack>
              )}
            </Stack>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmActionModal;
