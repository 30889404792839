import React from "react";

const Tab = ({ fitted, selected, id, onSelect, title }) => {
  return (
    <li className={`nav-item ${fitted ? "nav-link--fitted" : ""}`}>
      <span className={`nav-link ${selected === id ? "active" : ""}`} onClick={() => onSelect(id)}>
        {title}
      </span>
    </li>
  );
};

export default Tab;
