import React from "react";
import { useTranslation } from "react-i18next";
import Chevron from "images/Chevron.svg";
import Button from "stories/components/Button/Button";
import "./styles.css";

const Pagination = ({ loading = false, pageSize, pageNumber, listLength, previousAction, continueAction }) => {
  const { t } = useTranslation();

  return (
    <div className="pagination">
      <Button size="small" disabled={pageNumber - 1 <= 0 || loading} onClick={previousAction}>
        <img src={Chevron} alt="<" className="pagination__arrow pagination__arrow--left" />
      </Button>
      <span className="paragraph--small">{t("myRepairs.page", { pageNumber: pageNumber })}</span>
      <Button size="small" disabled={pageSize > listLength || loading} onClick={continueAction}>
        <img src={Chevron} alt=">" className="pagination__arrow pagination__arrow--right" />
      </Button>
    </div>
  );
};

export default Pagination;
