import React from "react";
import { useTranslation } from "react-i18next";
import Radio from "stories/components/Radio/Radio";
import { userResultHeadings } from "./config";

const ChangeUserResults = ({ results, values, setFieldValue }) => {
  const { t } = useTranslation();

  if (!results?.length) return;

  return (
    <div className="new-client-results">
      <table className="new-client-results__table">
        <thead className="new-client-results__table-head">
          <tr>
            {userResultHeadings[values.employeeUser].map((heading) => (
              <th key={heading} className="new-client-results__table-cell">
                {heading ? t(`global.${heading}`) : heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="new-client-results__table-body">
          {results.map(({ userId, taxNumber, companyName, personName, email }) => (
            <tr className="new-client-results__table-row" onClick={() => setFieldValue("selectedUserId", userId)} key={userId}>
              <td className="new-client-results__table-cell">
                <Radio checked={userId === values.selectedUserId} onChange={() => setFieldValue("selectedUserId", userId)} />
              </td>
              {!values.employeeUser && (
                <>
                  <td className="new-client-results__table-cell">{companyName || "-"}</td>
                  <td className="new-client-results__table-cell">{taxNumber || "-"}</td>
                </>
              )}
              <td className="new-client-results__table-cell">{personName}</td>
              <td className="new-client-results__table-cell">{email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChangeUserResults;
