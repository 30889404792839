import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Formik, Form } from "formik";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH, REPAIR_SOURCE_CLIENT, REPAIR_SOURCE_ELMARK } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Radio from "stories/components/Radio/Radio";
import TextField from "stories/components/TextField/TextField.js";
import Button from "stories/components/Button/Button.js";
import { getNewRegistrationId } from "../utils";
import "../styles/AddReport.css";
import EmployeeTable from "./EmployeeTable";
import ClientTable from "./ClientTable";
import "./styles.css";

export const Admin = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const textFieldSerach = useRef(null);
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);

  const handleTextFieldSearchFocus = () => textFieldSerach.current.focus();

  const getEmployeesSuccess = (response) => {
    if (!response.data.length) throw Error("302");
    setEmployees(response.data);
    setSearchError(null);
  };

  const getEmployeesError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    if (filteredErrors.length > 0) setSearchError(t(`errors.${filteredErrors.at(0).code}`));
    setEmployees([]);
  };

  const getEmployees = ({ textFieldSearchPhrase }) => {
    setLoadingTable(true);
    return agent.Resources.employeeSearch({ id: user.id, token: user.token, filter: textFieldSearchPhrase })
      .then(getEmployeesSuccess, getEmployeesError)
      .catch((error) => {
        setEmployees([]);
        setSearchError(t(`errors.${error.message}`));
      })
      .finally(() => setLoadingTable(false));
  };

  const getClientsSuccess = (response) => {
    if (!response.data.length) throw Error("302");
    setClients(response.data);
    setSearchError(null);
  };

  const getClientsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    if (filteredErrors.length > 0) setSearchError(t(`errors.${filteredErrors.at(0).code}`));
    setClients([]);
  };

  const getClients = ({ textFieldSearchPhrase }) => {
    setLoadingTable(true);
    return agent.Resources.customerSearch({ id: user.id, token: user.token, filter: textFieldSearchPhrase })
      .then(getClientsSuccess, getClientsError)
      .catch((error) => {
        setClients([]);
        setSearchError(t(`errors.${error.message}`));
      })
      .finally(() => setLoadingTable(false));
  };

  return (
    <div className="add-report">
      <Formik
        initialValues={{
          registrationId: getNewRegistrationId(),
          repairSource: REPAIR_SOURCE_ELMARK,
          userId: "",
          elmarkUser: true,
          clientUser: false,
          textFieldSearchPhrase: "",
        }}
        onSubmit={(values) => {
          dispatch({ type: "REGISTRATION_SET", data: { ...values, step: 1, authorId: user.id } });
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} style={{ flex: "1", display: "flex" }}>
            <div className="admin-add-report__user-search">
              <div>
                <h2 className="choose-report-admin">{t("addReport.selectSource")}</h2>
                <div className="admin-first-panel-radio">
                  <Radio
                    label={t("global.elmark")}
                    checked={values.elmarkUser}
                    onClick={() => {
                      setFieldValue("elmarkUser", true);
                      setFieldValue("clientUser", false);
                      setFieldValue("userId", "");
                      setFieldValue("textFieldSearchPhrase", "");
                      setEmployees([]);
                      setSearchError(null);
                      handleTextFieldSearchFocus();
                    }}
                    onChange={() => setFieldValue("repairSource", REPAIR_SOURCE_ELMARK)}
                  />
                  <Radio
                    label={t("global.client")}
                    checked={values.clientUser}
                    onClick={() => {
                      setFieldValue("clientUser", true);
                      setFieldValue("elmarkUser", false);
                      setFieldValue("userId", "");
                      setFieldValue("textFieldSearchPhrase", "");
                      setClients([]);
                      setSearchError(null);
                      handleTextFieldSearchFocus();
                    }}
                    onChange={() => setFieldValue("repairSource", REPAIR_SOURCE_CLIENT)}
                  />
                </div>
                <div className="admin-first-panel-search">
                  <TextField
                    autoFocus
                    ref={textFieldSerach}
                    variant="search"
                    loading={loadingTable}
                    placeholder={values.clientUser ? t("addReport.searchClient") : t("addReport.searchEmployee")}
                    value={values.textFieldSearchPhrase}
                    error={searchError}
                    onChange={(value) => setFieldValue("textFieldSearchPhrase", value)}
                    onClick={() => (values.clientUser ? getClients(values) : getEmployees(values))}
                  />
                </div>
                {values.clientUser ? (
                  <ClientTable values={values} clients={clients} setFieldValue={setFieldValue} />
                ) : (
                  <EmployeeTable values={values} employees={employees} setFieldValue={setFieldValue} />
                )}
              </div>
              <div className="add-report-first-panel__admin-buttons">
                <Button
                  size={mobile ? "small" : "default"}
                  variant="default"
                  onClick={() => navigate("/")}
                  className="add-report-first-panel__button-action button"
                >
                  {t("addReport.mainPage")}
                </Button>
                <Button
                  size={mobile ? "small" : "default"}
                  type="submit"
                  variant="primary"
                  disabled={!values.userId}
                  className={`add-report-first-panel__button-action button button__primary ${!values.userId ? "button__disabled--primary" : ""}`}
                >
                  {t("global.start")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
