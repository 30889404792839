import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isAdmin, isEmployee, isReception } from "utils/global-functions";
import Table from "stories/components/Table/Table";
import { headings, myClientHeadings, otherClientHeadings } from "../config";
import EmptyState from "./EmptyState";
import RepairsTableRow from "./RepairsTableRow";
import { isMyRepairPage } from "../utils";

const RepairsTable = ({ selectedItems, setSelectedItems, repairListLoading }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairsList },
  } = useSelector((store) => store);

  const getHeadings = () => {
    if (isEmployee(user))
      return headings.map(({ content, ...rest }) => {
        return { content: content ? t(`myRepairs.${content}`) : content, ...rest };
      });

    if (isMyRepairPage())
      return myClientHeadings.map(({ content, ...rest }) => {
        return { content: content ? t(`myRepairs.${content}`) : content, ...rest };
      });

    return otherClientHeadings.map(({ content, ...rest }) => {
      return { content: content ? t(`myRepairs.${content}`) : content, ...rest };
    });
  };

  return (
    <Table
      selectable={isAdmin(user) || isReception(user)}
      loading={repairListLoading}
      selected={selectedItems}
      setSelected={setSelectedItems}
      headings={getHeadings()}
      emptyState={<EmptyState />}
    >
      {repairsList.map((repair) => (
        <RepairsTableRow key={repair.repairId} repair={repair} id={repair.repairId} />
      ))}
    </Table>
  );
};

export default RepairsTable;
