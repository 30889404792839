/*
Author: jacek.bonecki@elmark.com.pl
*/

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Badge from "stories/components/Badge/Badge";

export const ManagementConstants = () => {
  const app = useSelector((store) => store.app);
  const { t } = useTranslation();

  return (
    <>
      {typeof app.constants !== "undefined"
        ? [
            "userRole",
            "repairSource",
            "repairType",
            "repairKind",
            "repairState",
            "repairEventType",
            "repairWarning",
            "deliveryType",
            "returnType",
            "packageType",
            "producers",
            "conversationType",
            "emailsRecipient",
            "emailsDelivery",
            "dictionaries",
            "technicians",
            "employees",
          ]
            .filter((what) => typeof app.constants[what] !== "undefined")
            .map((what, whatindex) => (
              <div key={whatindex} className="mb-2 p-2" style={{ border: "1px solid silver" }}>
                <h4 style={{ backgroundColor: "gray", color: "white", padding: "2px" }}>{what}</h4>
                {Object.keys(app.constants[what]).map((item, index) => (
                  <div key={index} className="p-1 mb-1">
                    <div style={{ display: "inline-block", width: "250px" }}>
                      [{item} = {app.constants[what][item].strVal}]
                    </div>
                    <Badge
                      content={
                        !["packageType", "producers", "emailsRecipient", "dictionaries", "technicians", "employees"].includes(what)
                          ? t(`constants.${what.replace(/([A-Z])/, ".$1").toLowerCase()}.${app.constants[what][item].strVal}`)
                          : app.constants[what][item].strVal
                      }
                      backgroundColor={app.constants[what][item].bgColor}
                    />
                  </div>
                ))}
              </div>
            ))
        : null}
    </>
  );
};
