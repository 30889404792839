import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { repairDetailsForClient } from "../config";
import ActionButtons from "../ActionButtons";
import ClientRepairDescrpiton from "../ClientRepairDescription";
import DeleteRepair from "../DeleteRepair";
import { getReplacementDetails } from "../utils";
import InfoColumn from "./InfoColumn";

const ClientContent = ({ productDetails, restDetails, refreshRepairDetails, setRefreshRepairDetails, repairSteps, productUrl }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
    repair: { repairDetails },
  } = useSelector((store) => store);

  const repairDetailsInfoDescription = {
    repairType: t(`constants.repair.type.${constants.repairType[repairDetails.repairType]?.strVal}`),
    repairKind: t(`constants.repair.kind.${constants.repairKind[repairDetails.repairKind]?.strVal}`),
    repairState: t(`constants.repair.state.${constants.repairState[repairDetails.repairState]?.strVal}`),
    isInQueue: repairDetails.isInQueue !== null ? t(`global.${!!repairDetails.isInQueue}`) : "-",
  };

  const repairDetailsInfo = {
    title: t("myRepairs.repairDetails"),
    content: [
      ...repairDetailsForClient.map((repairDetail) => {
        return { term: t(`global.${repairDetail}`), description: repairDetailsInfoDescription[repairDetail] || repairDetails[repairDetail] || "-" };
      }),
    ],
  };

  const repairReplacementProduct = repairDetails.replacementSerialNumber
    ? {
        title: t("global.replacementProduct"),
        content: [
          {
            term: t("global.serialNumber"),
            description: repairDetails.replacementSerialNumber,
            options: { tooltipDescriptionContent: getReplacementDetails(repairDetails, constants, t) },
          },
        ],
      }
    : null;

  return (
    <>
      <InfoColumn details={[productDetails]} titleUrl={productUrl} />
      <InfoColumn details={[repairDetailsInfo, repairReplacementProduct]} />
      <InfoColumn details={restDetails} showButtons={<ActionButtons repairSteps={repairSteps} />} />
      <ClientRepairDescrpiton />
      <DeleteRepair refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />
    </>
  );
};

export default ClientContent;
