import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import { CONVERSATION_CUSTOMER_VALUATION, MAX_MOBILE_WIDTH } from "utils/config";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import AddValuationForm from "./AddValuationForm";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { getBodyForRepairValuation } from "./utils";
import { useMediaQuery } from "react-responsive";

const AddValuationModal = ({
  active,
  setActive,
  conversationList,
  selectedConversationId,
  setSelectedConversationId,
  refreshValuationList,
  setRefreshValuationList,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});

  const selectedConversation = conversationList.find(({ id }) => id === selectedConversationId);

  const sendRepairValuationSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setActive(false);
      setFormErrors({});
      setRefreshValuationList(!refreshValuationList);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const sendRepairValuationError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const sendRepairValuation = ({ keepAttachmentsIds, ...rest }) =>
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      conversationType: CONVERSATION_CUSTOMER_VALUATION,
      repairId: repairDetails.repairId,
      keepAttachmentsIds: JSON.stringify(keepAttachmentsIds),
      ...getBodyForRepairValuation(selectedConversationId),
      ...rest,
    }).then(sendRepairValuationSuccess, sendRepairValuationError);

  const validationSchema = Yup.object().shape({
    conversationText: Yup.string().min(3, t("errors.101")).max(2048, t("errors.102")).required(t("errors.99")),
    conversationAmount: Yup.number().positive(t("errors.105")).required(t("errors.99")),
    conversationCurrency: Yup.string().required(t("errors.99")),
  });

  if (!active) return;

  const keepAttachmentsIds = selectedConversation?.attachments.map(({ id }) => id);

  return (
    <Formik
      initialValues={{
        conversationText: selectedConversation?.text ?? "",
        conversationAmount: selectedConversation?.amount ?? "",
        conversationCurrency: selectedConversation?.currency ?? "",
        attachments: selectedConversation?.attachments || [],
        keepAttachmentsIds: keepAttachmentsIds || [],
      }}
      onSubmit={(values) => sendRepairValuation(values)}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, setFieldValue, isSubmitting, errors, touched, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            title={selectedConversationId ? t("myRepairs.editValuation") : t("myRepairs.addValuation")}
            onClose={() => {
              setActive(false);
              setSelectedConversationId(null);
            }}
            footerActions={
              <div className="add-pricing-form__action-buttons">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={isSubmitting}
                  onClick={() => {
                    setActive(false);
                    setSelectedConversationId(null);
                  }}
                >
                  {t("global.cancel")}
                </Button>
                <Button size={mobile ? "small" : "default"} type="submit" variant="primary" loading={isSubmitting}>
                  {t("global.save")}
                </Button>
              </div>
            }
          >
            <AddValuationForm
              setActiveModal={setActive}
              values={values}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              formErrors={formErrors}
              selectedConversation={selectedConversation}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AddValuationModal;
