import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const ConfirmDeletePhotoModal = ({
  active,
  setActive,
  selectedPhotoId,
  setSelectedPhotoId,
  setRefreshPhotos,
  refreshPhotos,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const deletePhotoSuccess = (response) => {
    if (response.result === 301) {
      setActive(false);
      setRefreshPhotos(!refreshPhotos);
      setSelectedPhotoId(null);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deletePhotoError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const deletePhoto = () => {
    setDeleteActionLoading(true);
    agent.Repairs.handleRepairAttachments({ id: user.id, token: user.token, action: "delete", attachmentId: selectedPhotoId })
      .then(deletePhotoSuccess, deletePhotoError)
      .finally(() => setDeleteActionLoading(false));
  };

  if (!active) return;

  return (
    <Modal
      title={t("myRepairs.deletePhoto")}
      onClose={() => {
        setSelectedPhotoId(null);
        setActive(false);
      }}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button
            size={mobile ? "small" : "default"}
            disabled={deleteActionLoading}
            onClick={() => {
              setSelectedPhotoId(null);
              setActive(false);
            }}
          >
            {t("global.no")}
          </Button>
          <Button size={mobile ? "small" : "default"} loading={deleteActionLoading} variant="primary" onClick={() => deletePhoto()}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <p className="paragraph--small">{t("myRepairs.deletePhotoInfo")}</p>
    </Modal>
  );
};

export default ConfirmDeletePhotoModal;
