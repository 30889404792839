/*
Author: jacek.bonecki@elmark.com.pl
*/

import React from "react";
import { useTranslation } from "react-i18next";

const MobileViewUnavailableIcon = () => (
  <svg viewBox="0 0 20 20" height="4.8rem" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#bb1720"
      d="M17 6V5h-2V2H3v14h5v4h3.25H11a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6zm-5.75 14H3a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5.75zM11 8v8h6V8h-6zm3 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
    />
  </svg>
);

const MobileViewUnavailable = () => {
  const { t } = useTranslation();
  return (
    <div className="p-4">
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div>
          <MobileViewUnavailableIcon />
        </div>
        <div className="heading--6 text--red-dark text--preline">{t("mobileViewUnavailable.title")}</div>
      </div>
      <div className="pt-4 text--green">{t("mobileViewUnavailable.message")}</div>
    </div>
  );
};
export default MobileViewUnavailable;
