import React from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectField from "stories/components/SelectField/SelectField";
import { ACTIVE_EMPLOYEE_STATE } from "utils/config";

const EmployeeSelector = ({ disabled, value, onChange, size = "small", className = "", error, id, onBlur, placeholder, label, ...rest }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
  } = useSelector((store) => store);

  const employeeOptions = lodash.isEmpty(constants)
    ? []
    : Array.from(new Set([...constants?.techniciansArr.map(({ intVal }) => intVal), ...constants?.employeesArr.map(({ intVal }) => intVal)])).map((id) => {
        const employee = constants?.employees[id] || constants?.technicians[id];
        return { value: id, label: employee.strVal, disabled: employee.bgColor !== ACTIVE_EMPLOYEE_STATE };
      });

  return (
    <SelectField
      id={id}
      onBlur={onBlur}
      className={className}
      size={size}
      disabled={disabled}
      label={label}
      placeholder={placeholder || t("global.none")}
      value={value}
      onChange={onChange}
      options={employeeOptions}
      error={error}
      {...rest}
    />
  );
};

export default EmployeeSelector;
