import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Accordion from "stories/components/Accordion/Accordion";
import Badge from "stories/components/Badge/Badge";
import Button from "stories/components/Button/Button";

const MobileRepairsLogList = ({ repairsLogResult }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    app: { constants },
  } = useSelector((store) => store);

  return (
    <Accordion
      shadow
      content={repairsLogResult.map((repair) => {
        return {
          title: (
            <>
              <div className="mobile-repair-admin__accordion-heading">
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.number")}:</span>
                  <span className="paragraph--small">{repair.repairId}</span>
                </div>
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.reportDate")}:</span>
                  <span className="paragraph--small">{repair.registrationDate}</span>
                </div>
              </div>
            </>
          ),
          body: (
            <div className="mobile-repair-admin__accordion-list">
              <div className="mobile-repair-admin__accordion-item">
                <span className="paragraph--small text--subdued">{t("myRepairs.repairType")}:</span>
                <Badge
                  content={t(`constants.repair.type.${constants?.repairType?.[repair.repairType]?.strVal}`)}
                  backgroundColor={constants?.repairType?.[repair.repairType]?.bgColor}
                />
              </div>
              <div className="mobile-repair-admin__accordion-item">
                <span className="paragraph--small text--subdued">{t("myRepairs.repairKind")}:</span>
                <span className="paragraph--small">
                  <Badge
                    content={t(`constants.repair.kind.${constants?.repairKind?.[repair.repairKind]?.strVal}`)}
                    backgroundColor={constants?.repairKind?.[repair.repairKind]?.bgColor}
                  />
                </span>
              </div>
              <div className="mobile-repair-admin__accordion-item">
                <span className="paragraph--small text--subdued">{t("myRepairs.repairState")}:</span>
                <Badge
                  content={t(`constants.repair.state.${constants?.repairState?.[repair.repairState]?.strVal}`)}
                  backgroundColor={constants?.repairState?.[repair.repairState]?.bgColor}
                />
              </div>
              <Button onClick={() => navigate(`/${repair.repairId}/info`)} size="small">
                {t("global.openRepair")}
              </Button>
            </div>
          ),
        };
      })}
    />
  );
};

export default MobileRepairsLogList;
