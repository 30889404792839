import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isAdmin, isReception } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import DeleteRepair from "../DeleteRepair";
import { showReplacedProductWarning } from "../utils";
import InfoColumn from "./InfoColumn";
import FormInfoColumn from "./FormInfoColumn";
import RepairDescription from "./RepairDescription";
import InternalComment from "./InternalComment";

const Content = ({
  changeUserModalActive,
  setChangeUserModalActive,
  editClientContactModalActive,
  setEditClientContactModalActive,
  changeSerialNumberModalActive,
  setChangeSerialNumberModalActive,
  refreshRepairDetails,
  setRefreshRepairDetails,
  productDetails,
  restDetails,
  repairSteps,
  productUrl,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const navigate = useNavigate();

  const editButtons = [
    null,
    { active: changeUserModalActive, onChange: setChangeUserModalActive, content: t("global.change") },
    { active: editClientContactModalActive, onChange: setEditClientContactModalActive, content: t("global.edit") },
  ];

  const replaceWarning = {
    title: t("global.warnings"),
    content: [
      {
        term: "",
        description: "",
        action: (
          <Button onClick={() => navigate(`/${repairDetails.repairId}/repairs-log`)} variant="error">
            <div className="text--left">{t("myRepairs.productReplacement")}</div>
            <div className="text--left">{t("myRepairs.productReplacementInfo")}</div>
          </Button>
        ),
      },
    ],
  };

  const firstColumnInfo = showReplacedProductWarning(repairDetails.repairs, repairDetails.repairId) ? [productDetails, replaceWarning] : [productDetails];

  const changeSerialNumberButton =
    isAdmin(user) || isReception(user)
      ? [{ active: changeSerialNumberModalActive, onChange: setChangeSerialNumberModalActive, content: t("global.change") }]
      : [];

  return (
    <>
      <InfoColumn details={firstColumnInfo} editButtons={changeSerialNumberButton} titleUrl={productUrl} />
      <FormInfoColumn refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />
      <InfoColumn details={restDetails} editButtons={isAdmin(user) || isReception(user) ? editButtons : []} />
      <RepairDescription />
      <InternalComment />
      <DeleteRepair repairSteps={repairSteps} refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />
    </>
  );
};

export default Content;
