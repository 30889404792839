import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectField from "stories/components/SelectField/SelectField";
import Button from "stories/components/Button/Button";
import Drawer from "stories/components/Drawer/Drawer";
import { isEmployee } from "utils/global-functions";
import { Filters } from "./Filters";
import "./styles.css";

const DisplayMobile = ({
  mobileFiltersModalActive,
  setMobileFiltersModalActive,
  employees,
  conversationsList,
  conversationTypes,
  setConversationTypes,
  repairNumber,
  setRepairNumber,
  serialNumber,
  setSerialNumber,
  customer,
  setCustomer,
  employeeId,
  setEmployeeId,
  repairState,
  setRepairState,
  dateFrom,
  setDateFrom,
  dateUpTo,
  setDateUpTo,
  error,
  setPageNumber,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <div>
      {isEmployee(user) ? (
        <SelectField
          label={t("actions.chooseActions")}
          placeholder={t("actions.all")}
          value={conversationTypes}
          options={[
            { label: t("actions.all"), value: null },
            { label: t("actions.pricings"), value: 2 },
            { label: t("actions.conversations"), value: 1 },
            { label: t("actions.conversationsInternal"), value: 3 },
            { label: t("actions.ratings"), value: 4 },
          ]}
          onChange={(value) => {
            setConversationTypes(value);
            conversationsList({ convTypes: value, pageNr: 1 });
            setPageNumber(1);
          }}
        />
      ) : (
        <SelectField
          label={t("actions.chooseActions")}
          placeholder={t("actions.all")}
          value={conversationTypes}
          options={[
            { label: t("actions.all"), value: undefined },
            { label: t("actions.pricings"), value: 2 },
            { label: t("actions.conversations"), value: 1 },
            { label: t("actions.ratings"), value: 4 },
          ]}
          onChange={(value) => {
            setConversationTypes(value);
            conversationsList({ convTypes: value, pageNr: 1 });
            setPageNumber(1);
          }}
        />
      )}
      <div className="mobile-actions-menu-filter">
        <Drawer
          title={t("global.filters")}
          active={mobileFiltersModalActive}
          setActive={setMobileFiltersModalActive}
          activator={
            <Button variant="primary" size="small" onClick={() => setMobileFiltersModalActive(true)}>
              {t("global.filter")}
            </Button>
          }
        >
          <div className="mobile-actions-filters-drawer-wrapper">
            <Filters
              repairNumber={repairNumber}
              setRepairNumber={setRepairNumber}
              serialNumber={serialNumber}
              setSerialNumber={setSerialNumber}
              customer={customer}
              setCustomer={setCustomer}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
              repairState={repairState}
              setRepairState={setRepairState}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateUpTo={dateUpTo}
              setDateUpTo={setDateUpTo}
              error={error}
            />
          </div>
          <div className="mobile-actions-filters-buttons">
            <Button
              variant="default"
              type="submit"
              onClick={() => {
                setRepairNumber("");
                setSerialNumber("");
                setCustomer("");
                setEmployeeId(null);
                setRepairState(null);
                setDateFrom(null);
                setDateUpTo(null);
              }}
            >
              {t("actions.clearFilters")}
            </Button>
            <Button variant="primary" type="submit">
              {t("actions.showResults")}
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default DisplayMobile;
