import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "stories/components/Spinner/Spinner";
import Accordion from "stories/components/Accordion/Accordion";
import Button from "stories/components/Button/Button";
import NoResults from "components/NoResults/NoResults";
import "./styles.css";

const DisplayDesktop = ({
  reportList,
  loading,
  setEndDateFrom,
  setEndDateUpTo,
  setSerialNumber,
  setPartNumber,
  setRepairId,
  setProducerId,
  setTechnicianId,
  setIsIncopmlete,
  setEndDateFromValue,
  setEndDateUpToValue,
  setRepairIdValue,
  setSerialNumberValue,
  setPartNumberValue,
  setTechnicianIdValue,
  setProducerIdValue,
  setIsIncopmleteValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    app: { constants },
  } = useSelector((store) => store);

  if (loading)
    return (
      <div className="producer-report-body-spinner">
        <Spinner />
      </div>
    );

  return (
    <div className="producer-report-body">
      {reportList?.length !== 0 && (
        <table className="producer-report-body-heading">
          <tbody>
            <tr>
              <th className="body-heading-receive-lp">No.</th>
              <th className="body-heading-receive-date">Receive date</th>
              <th className="body-heading-finish-date">Finish date</th>
              <th className="body-heading-product-name">Product name</th>
              <th className="body-heading-product-sn">Product serial number</th>
              <th className="body-heading-report-info">Report info</th>
            </tr>
          </tbody>
        </table>
      )}

      {reportList?.map(
        (
          { repairId, technicianId, registrationDate, endDate, serialNumber, partNumber, problem, testProcedure, findings, malfunction, parts, isIncomplete },
          index,
        ) => {
          return (
            <div key={index} className="producer-report-body-accordion">
              <Accordion
                content={[
                  {
                    title: (
                      <div className="producer-report-accordion-title">
                        <div className="body-title-report-lp">{index + 1}</div>
                        <div className="body-title-report-receive-date">{registrationDate ?? "-"}</div>
                        <div className="body-title-report-finish-date">{endDate ?? "-"}</div>
                        <div className="body-title-report-pn">{partNumber ?? "-"}</div>
                        <div className="body-title-report-sn">{serialNumber ?? "-"}</div>
                        <div className={isIncomplete ? "empty-field" : "not-empty-field"}>
                          {isIncomplete ? t("producerReport.reportStateNoReport") : t("producerReport.reportStateOk")}
                        </div>
                      </div>
                    ),
                    body: (
                      <div className="producer-report-accordion-body">
                        <div className="producer-report-accordion-body-line">
                          <div className="producer-report-accordion-body-line-field-first">
                            <div className="producer-report-accordion-body-grey-label">Problem</div>
                            <div>{problem ?? "-"}</div>
                          </div>
                          <div className="producer-report-accordion-body-line-field-second">
                            <div className="producer-report-accordion-body-grey-label">Findings</div>
                            <div>{findings ?? "-"}</div>
                          </div>
                        </div>
                        <div className="producer-report-accordion-body-line body-line-2">
                          <div className="producer-report-accordion-body-line-field-first">
                            <div className="producer-report-accordion-body-grey-label">Test procedure</div>
                            <div>{testProcedure ?? "-"}</div>
                          </div>
                          <div className="producer-report-accordion-body-line-field-second">
                            <div className="producer-report-accordion-body-grey-label">Cause of malfunction</div>
                            <div>{malfunction ?? "-"}</div>
                          </div>
                        </div>

                        <div style={{ marginTop: "1.8rem" }}>
                          <h6 style={{ fontWeight: "500" }} className="heading--6 text--subdued">
                            Parts
                          </h6>
                          {parts.length !== 0 ? (
                            <table className="producer-report-accordion-body-parts-table">
                              <thead>
                                <tr>
                                  <th>PCB serial number</th>
                                  <th>Part name</th>
                                  <th>Repair status</th>
                                  <th>Location</th>
                                  <th>Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                                {parts?.map(({ partId, pcbSerialNumber, partName, repairStatus, location, qty }) => {
                                  return (
                                    <tr key={partId}>
                                      <td>{pcbSerialNumber}</td>
                                      <td>{partName}</td>
                                      <td>{repairStatus ?? "-"}</td>
                                      <td>{location}</td>
                                      <td>{qty}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div>No parts</div>
                          )}
                        </div>

                        <div className="producer-report-accordion-body-info">
                          <div className="producer-report-accordion-body-contents">
                            <div>
                              <span className="technician-grey">{t("global.serviceTechnician")}:</span>
                              <span>{constants?.employees?.[technicianId]?.strVal ?? t("producerReport.unassigned")}</span>
                            </div>
                            <div>
                              <span className="technician-grey">{t("global.repairNumber")}:</span>
                              <Link className="custom-link" to={`/${repairId}/info`}>
                                {repairId}
                              </Link>
                            </div>
                          </div>
                          <Button variant="primary" onClick={() => navigate(`/${repairId}/report`)}>
                            {t("producerReport.goToReport")}
                          </Button>
                        </div>
                      </div>
                    ),
                  },
                ]}
                shadow={true}
              />
            </div>
          );
        },
      )}

      {reportList?.length === 0 ? (
        <NoResults size="small" infoText={t("actions.noResultsText")}>
          <div className="producer-report-no-results-buttons">
            <Button
              onClick={() => {
                setEndDateFrom();
                setEndDateUpTo();
                setSerialNumber("");
                setPartNumber("");
                setRepairId("");
                setProducerId("");
                setTechnicianId("");
                setIsIncopmlete("");
                setEndDateFromValue();
                setEndDateUpToValue();
                setSerialNumberValue("");
                setPartNumberValue("");
                setRepairIdValue("");
                setProducerIdValue("");
                setTechnicianIdValue("");
                setIsIncopmleteValue("");
              }}
            >
              {t("global.clearFilters")}
            </Button>
            <Button onClick={() => navigate(`/`)}>{t("addReport.mainPage")}</Button>
          </div>
        </NoResults>
      ) : null}
    </div>
  );
};

export default DisplayDesktop;
