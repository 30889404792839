import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Table from "stories/components/Table/Table";
import { sortDescendingOrder } from "utils/global-functions";
import { repairsTableHeadings } from "../config";
import RepairsTableRow from "./RepairsTableRow";

const RepairsTable = () => {
  const {
    repair: {
      repairDetails: { repairs },
    },
    app: { leftPanelHidden },
  } = useSelector((store) => store);
  const { t } = useTranslation();

  return (
    <div className={`other-repairs-table__wrapper ${leftPanelHidden ? "other-repairs-table__wrapper--small" : ""}`}>
      <Table
        extended
        headings={repairsTableHeadings.map((heading) => {
          return { content: t(`global.${heading}`) };
        })}
      >
        {repairs
          .sort((a, b) => sortDescendingOrder(a.repairId, b.repairId))
          .map((repair) => (
            <RepairsTableRow key={repair.repairId} repair={repair} />
          ))}
      </Table>
    </div>
  );
};

export default RepairsTable;
