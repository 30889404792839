export const headingsMap = {
  true: [{ content: "id" }, { content: "file" }, { content: "type" }, { content: "postDate" }, { content: "" }],
  false: [
    { content: "id" },
    { content: "file" },
    { content: "type" },
    { content: "postDate" },
    { content: "author" },
    { content: "visible" },
    { content: "actions" },
    { content: "" },
  ],
};
