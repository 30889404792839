export const links = {
  user: ["/info", "/delivery", "/conversation", "/valuation", "/rating", "/logs", "/repairs-log", "/attachments", "/photos"],
  employee: ["/info", "/configuration", "/delivery", "/conversation", "/valuation", "/rating", "/logs", "/repairs-log", "/report", "/attachments", "/photos"],
  admin: ["/info", "/configuration", "/delivery", "/conversation", "/valuation", "/rating", "/logs", "/repairs-log", "/report", "/attachments", "/photos"],
  technician: ["/info", "/configuration", "/delivery", "/conversation", "/valuation", "/rating", "/logs", "/repairs-log", "/report", "/attachments", "/photos"],
  reception: ["/info", "/configuration", "/delivery", "/conversation", "/valuation", "/logs", "/repairs-log", "/report", "/attachments", "/photos"],
};

export const detailsPageRegexp = `/(${Array.from(new Set(Object.values(links).flat()))
  .map((link) => link.replace("/", ""))
  .join("|")})(/internal|)$`;

export const pagesWithGrid = ["info", "delivery"];

export const pagesWithFlex = ["conversation"];
