import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import LoginForm from "../LoginForm";

const LoginCard = () => {
  const { t } = useTranslation();

  return (
    <div className="login_card">
      <div className="login_card__wrapper">
        <div className="login_heading_center">
          <h2 className="login_heading">{t("loginPage.motto")}</h2>
        </div>
        <div>
          <Stack flexDirection="column" gap="2.4rem">
            <h2 className="login_heading">{t("loginPage.login")}</h2>
            <LoginForm />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
