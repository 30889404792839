import moment from "moment";
import { sortAscendingOrder, isEmployee } from "utils/global-functions";
import { specificRepairWarnings } from "./config";

export const handleAllItemsSelection = (selectedItems, items) =>
  items.every((item) => selectedItems.some((selected) => selected === item.repairId)) ? [] : items.map((item) => item.repairId);

export const handleItemSelection = (selectedItems, itemId) => {
  if (selectedItems.some((selected) => selected === itemId)) return selectedItems.filter((selected) => selected !== itemId);

  return [...selectedItems, itemId];
};

export const formatTabsFilterForSerializableValues = (tabsFilters) => {
  const copyTabFilters = Object.assign(tabsFilters);

  return copyTabFilters.map((copyTabFilter) => formatAppliedFiltersForSerializableValues(copyTabFilter));
};

export const formatAppliedFiltersForSerializableValues = (appliedFilters) => {
  const filters = Object.assign(appliedFilters);

  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      if (key.toLowerCase().includes("date")) return [key, value ? moment(value).format("x") : value];

      return [key, value];
    }),
  );
};

export const formatAppliedFiltersForApi = (appliedFilters, constants) => {
  const filters = Object.assign(appliedFilters);

  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      if (key.toLowerCase().includes("date")) return [key, value ? moment(parseInt(value)).format("YYYY-MM-DD") : value];

      return [
        key,
        constants?.[key.replace("producerId", "producers").replace("technicianId", "technicians")]
          ? constants?.[key.replace("producerId", "producers").replace("technicianId", "employees")]?.[value.toString()]?.strVal
          : value,
      ];
    }),
  );
};

export const isManageTabsSaveButtonDisabled = (values) => {
  if (values.action === "add") {
    const { filterId, ...rest } = values;
    return Object.values(rest).some((value) => !value);
  }

  if (values.action === "update") return Object.values(values).some((value) => !value);

  return true;
};

export const sortTabsInAscendingOrder = (a, b) => {
  const nameA = a.filterName.toUpperCase();
  const nameB = b.filterName.toUpperCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const isAppliedFiltersEqualCurrentTabFilters = (appliedFilters, currentTabFilters) =>
  JSON.stringify(Object.entries(appliedFilters).sort(([a], [b]) => sortAscendingOrder(a, b))) ===
  JSON.stringify(
    Object.entries(currentTabFilters)
      .filter(([, value]) => !!value || value === 0)
      .sort(([a], [b]) => sortAscendingOrder(a, b)),
  );

export const setStarToCurrentTabName = (tabs, selectedTabId) =>
  tabs.map((tab) => {
    if (tab.id === selectedTabId) {
      const { title, ...rest } = tab;

      if (title.includes("*")) return tab;

      return { title: `${title} *`, ...rest };
    }
    return tab;
  });

export const getListType = (user) => {
  if (isEmployee(user)) return "full";
  if (window.location.pathname === "/") return "my";
  return "other";
};

export const isMyRepairPage = () => window.location.pathname === "/";

export const isOtherRepairsPage = () => window.location.pathname === "/other-repairs";

export const getClassesForSpecifiedRepair = (repairWarnings) =>
  specificRepairWarnings.every((warning) => repairWarnings?.includes(warning)) ? "text--red text--bold-900" : "";

export const formatManageTabsBody = (values) => {
  const { filterId, ...rest } = values;

  return rest.action === "add" ? rest : { filterId, ...rest };
};
