import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Dropzone from "stories/components/Dropzone/Dropzone";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import { photosExtensions } from "./config";

const PhotosForm = ({ values, setFieldValue, selectedPhotoId, formErrors }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection="column" gap="1rem">
      <TextField
        label={t("global.comment")}
        numberOfLines={4}
        value={values.commentText}
        error={formErrors.commentText}
        variant="multi"
        onChange={(value) => setFieldValue("commentText", value)}
      />
      <Checkbox
        label={t("global.visible")}
        checked={!!values.visibilityFlag}
        error={formErrors.visibilityFlag}
        onChange={() => setFieldValue("visibilityFlag", !!values.visibilityFlag ? 0 : 1)}
      />
      {!selectedPhotoId && (
        <Dropzone
          extensions={photosExtensions}
          error={formErrors.attachments}
          label={t("addReport.attachments")}
          files={values.attachments}
          addFilesValue={(value) => setFieldValue("attachments", value)}
        />
      )}
    </Stack>
  );
};

export default PhotosForm;
