import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "stories/components/Button/Button";
import { WARRANTY_CONDITIONS_LINKS } from "utils/config";
import { formatWarrantyDescription } from "utils/global-functions";
import Badge from "stories/components/Badge/Badge";
import ThumbnailPreviewModal from "./ThumbnailPreviewModal";
import { formatWarrantyResult, getLanguage } from "./utils";
import RepairHistory from "./RepairHistory";
import AdditionalInformation from "./AdditionalInformation";
import { warrantyKeyPrefix } from "./config";

const WarrantyResult = ({ warrantyResult, productStoreInfo, repairsLogResult, elmaticConfiguration, setBundleContentModelActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const [thumbnailPreviewModalActive, setThumbnailPreviewModalActive] = useState(false);

  const warrantyUnderConfig = { 0: { content: t("global.no"), color: "red" }, 1: { content: t("global.yes"), color: "green" } };
  const underWarrantyKey = "warrantyUnder";
  const warrantyLengthKey = "warrantyLength";

  const formatWarrantyValue = (key, value) => {
    if (key === warrantyLengthKey)
      return (
        <div className="warranty-length-wrapper">
          <span>{formatWarrantyDescription(key, value, t)}</span>
          <a className="custom-link" target="_blank" href={WARRANTY_CONDITIONS_LINKS[getLanguage(user)]} rel="noreferrer">
            {t("help.warrantyConditions")}
          </a>
        </div>
      );
    if (key === underWarrantyKey) return <Badge content={warrantyUnderConfig[value].content} backgroundColor={warrantyUnderConfig[value].color} />;
    if (elmaticConfiguration.recordCount && key === "serialNumber")
      return (
        <div className="warranty-length-wrapper">
          <span>{formatWarrantyDescription(key, value, t)}</span>
          <button className="unset-all custom-link" onClick={() => setBundleContentModelActive(true)}>
            {t("myRepairs.configuration")}
          </button>
        </div>
      );

    return value;
  };

  return (
    <div>
      <div className="serial-number__heading-wrapper">
        <h4 className="heading--4 serial-number__heading serial-number__info-heading">{t("serialNumber.productCard")}:</h4>
        <h5 className="heading--4 serial-number__sub-heading text--subdued">{warrantyResult.serialNumber}</h5>
      </div>
      <div className="serial-number__info-wrapper">
        {productStoreInfo?.thumbnail ? (
          <div onClick={() => setThumbnailPreviewModalActive(true)} className="serial-number__thumbnail-wrapper">
            <img className="serial-number__thumbnail" src={productStoreInfo.thumbnail} alt="Product thumbnail" />
          </div>
        ) : (
          <div className="serial-number__thumbnail-wrapper serial-number__placeholder-icon-wrapper">
            <svg fill="var(--grey-light)" xmlns="http://www.w3.org/2000/svg" width="8rem" viewBox="0 0 512 512">
              <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
            </svg>
          </div>
        )}
        <div className="serial-number__info">
          <h5 className="heading--5 serial-number__info-heading">{t("serialNumber.productInformation")}</h5>
          <div className="serial-number__result">
            {formatWarrantyResult(warrantyResult)
              .filter(([key]) => !key.startsWith(warrantyKeyPrefix))
              .map(([key, value]) => (
                <div className="serial-number__result-item" key={key}>
                  <span className="serial-number__result-item-key">{t(`myRepairs.${key}`)}</span>
                  <span className="serial-number__result-item-description">{formatWarrantyValue(key, value)}</span>
                </div>
              ))}
          </div>
          {productStoreInfo?.url && (
            <a style={{ all: "unset" }} href={productStoreInfo?.url} target="_blank" rel="noreferrer">
              <Button>{t("serialNumber.findOutMoreAboutTheProduct")}</Button>
            </a>
          )}
        </div>
        <div className="serial-number__info serial-number__info--margin">
          <h5 className="heading--5 serial-number__info-heading">{t("global.warrantyStatus")}</h5>
          <div className="serial-number__result">
            {formatWarrantyResult(warrantyResult)
              .filter(([key]) => key.startsWith(warrantyKeyPrefix))
              .map(([key, value]) => (
                <div className="serial-number__result-item" key={key}>
                  <span className="serial-number__result-item-key">{t(`myRepairs.${key}`)}</span>
                  <span className="serial-number__result-item-info">{formatWarrantyValue(key, value)}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <RepairHistory repairsLogResult={repairsLogResult} />
      <AdditionalInformation productStoreInfo={productStoreInfo} />
      <ThumbnailPreviewModal
        active={thumbnailPreviewModalActive}
        onClose={() => setThumbnailPreviewModalActive(false)}
        thumbnailUrl={productStoreInfo.thumbnail}
      />
    </div>
  );
};

export default WarrantyResult;
