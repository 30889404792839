import React from "react";
import { useTranslation } from "react-i18next";
import Button from "stories/components/Button/Button";
import Link from "stories/components/Link/Link";
import Tooltip from "stories/components/Tooltip/Tooltip";

const InfoColumn = ({ details, editButtons = [], titleUrl }) => {
  const { t } = useTranslation();

  const isFirstElement = (index) => index === 0;

  return (
    <div className="repair-info__column">
      <div>
        {details.map((detail, index) => {
          if (!detail) return null;

          return (
            <div key={detail.title} className="repair-info__tuple">
              {titleUrl && isFirstElement(index) ? (
                <Link href={titleUrl} target="_blank" rel="noopener noreferrer" className="custom-link serial-number__link">
                  <Tooltip allowHtml content={t("myRepairs.checkProductInfo")} direction="top" zIndex={999999}>
                    <div className="repair-info__product-link-content">
                      <h6 key={detail.title} className="heading--6 repair-info__title-wrapper">
                        {detail.title}
                      </h6>
                      <svg className="serial-number__link-icon mobile-serial-number__link-icon" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V11H19L18.9999 6.413L11.2071 14.2071L9.79289 12.7929L17.5849 5H13V3H21Z" />
                      </svg>
                    </div>
                  </Tooltip>
                </Link>
              ) : (
                <h6 key={detail.title} className="heading--6 repair-info__title-wrapper">
                  {detail.title}
                </h6>
              )}
              <div className="repair-info__tuple-item-wrapper">
                {detail.content.map(({ term, description, action, options }, index) =>
                  term || description || action ? (
                    <div className="repair-info__tuple-item" key={index}>
                      <span className="paragraph--small text--subdued">{term}</span>
                      {options?.tooltipDescriptionContent ? (
                        <Tooltip direction="bottom" content={options.tooltipDescriptionContent}>
                          <span className="paragraph--small text--pre-line text--underline-dotted">{description || "-"}</span>
                        </Tooltip>
                      ) : (
                        <span className="paragraph--small text--pre-line">{!action && !description ? "-" : description}</span>
                      )}
                      {action}
                    </div>
                  ) : null,
                )}
              </div>
              <div className="repair-info__button-wrapper">
                {editButtons[index] && (
                  <Button onClick={() => editButtons[index].onChange(!editButtons[index].active)} size="small">
                    {editButtons[index].content}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InfoColumn;
