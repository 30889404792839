import React from "react";
import { useTranslation } from "react-i18next";

const WaitingForValuation = () => {
  const { t } = useTranslation();

  return (
    <div className="pricing__empty-state">
      <h6 className="heading--6">{t("myRepairs.waitingForValuationHeading")}</h6>
    </div>
  );
};

export default WaitingForValuation;
