import moment from "moment";
import lodash from "lodash";
import { RETURN_TYPE_ELMARK_COURIER } from "utils/config";
import { isAdmin, isReception, sortDescendingOrder } from "utils/global-functions";
import { productDetailsWithNullableValues, repairSetDetailsOptionals, repairSetReplacementOptionals } from "./config";

export const transformContactKeyToUserKey = (key) => {
  const [firstLetter, ...rest] = key.replace("contact", "").split("");
  const transformedKey = [firstLetter.toLowerCase(), ...rest].join("");

  if (transformedKey === "person") return `${transformedKey}Name`;

  return transformedKey;
};

const repairContactRequestKeysToSkip = ["newContactData"];

export const formatBodyForRepairContactRequest = (values) => {
  return {
    ...Object.fromEntries(
      Object.entries(values)
        .filter(([key]) => !repairContactRequestKeysToSkip.includes(key))
        .map(([key, value]) => [key, values.newContactData ? value : null]),
    ),
  };
};

export const isEveryValueInObjectNullable = (object) => Object.values(object).every((obj) => !obj);

export const extractContactDataFromObject = (object) => Object.fromEntries(Object.entries(object).filter(([key]) => key.includes("contact")));

const changeUserRequestKeysToSkip = ["newContactData", "returnType"];

export const formatBodyForChangeUserRequest = (values) => {
  return {
    ...Object.fromEntries(
      Object.entries(values)
        .filter(([key]) => !changeUserRequestKeysToSkip.includes(key))
        .map(([key, value]) => {
          if (key.includes("contact")) return [key, values.newContactData ? value : null];

          return [key, values.returnType === RETURN_TYPE_ELMARK_COURIER ? value : null];
        }),
    ),
    returnType: values.returnType,
  };
};

export const getAccInfoBody = (user, repair) => {
  const body = { id: user.id, token: user.token };
  if (isAdmin(user) || isReception(user)) body.userId = repair.userId;

  return body;
};

export const formatBodyWithDateAndExternalProduct = (body, addExternal) => {
  if (!addExternal) return Object.fromEntries(Object.entries(body).filter(([key]) => !key.includes("ext")));

  return Object.fromEntries(
    Object.entries(body).map(([key, value]) => {
      if (key.toLowerCase().includes("date")) return [key, value ? value.format("YYYY-MM-DD") : value];

      return [key, value];
    }),
  );
};

export const formatBodyForSetDetailsRequest = (body, repairDetails, user) =>
  Object.fromEntries(
    Object.entries(body)
      .filter(([key, value]) => {
        if (repairSetDetailsOptionals[isAdmin(user) || isReception(user)].includes(key))
          return key.toLowerCase().includes("date") ? repairDetails[key] !== moment(value).format("YYYY-MM-DD") : repairDetails[key] !== value;

        return false;
      })
      .map(([key, value]) => {
        if (key.toLowerCase().includes("date")) return [key, value ? moment(value).format("YYYY-MM-DD") : value];
        return [key, value];
      }),
  );

export const formatBodyForSetReplacementRequest = (body) =>
  Object.fromEntries(
    Object.entries(body)
      .filter(([key]) => repairSetReplacementOptionals.includes(key))
      .map(([key, value]) => {
        if (key.toLowerCase().includes("date")) return [key, value ? moment(value).format("YYYY-MM-DD") : value];

        return [key, value];
      }),
  );

export const isChangeUserActionValid = (values, errors) => {
  if (!values.selectedUserId) return false;

  let isValid = true;
  if (values.returnType === RETURN_TYPE_ELMARK_COURIER) isValid = Object.entries(errors).filter(([key]) => key.toLowerCase().includes("return")).length === 0;
  if (values.newContactData && isValid) isValid = Object.entries(errors).filter(([key]) => key.toLowerCase().includes("contact")).length === 0;

  return isValid;
};

export const convertNullToEmptyString = (value) => (value === null ? "" : value);

export const isNewContact = (repairDetails) => !!repairDetails.contactPerson;

export const showReplacedProductWarning = (repairs, repairId) => {
  const sortedRepairs = repairs.sort((a, b) => sortDescendingOrder(a.repairId, b.repairId));
  const lastReplacedTypeRepair = sortedRepairs.find(({ repairKind }) => repairKind === 3);
  if (!lastReplacedTypeRepair) return false;

  return parseInt(repairId) > lastReplacedTypeRepair.repairId;
};

export const isRepairDetailsModificationForbidden = (user) => !isAdmin(user) && !isReception(user);

export const isRepairDetailsOnlyAdminAllowed = (user) => !isAdmin(user);

export const showCheckProductButton = (values, serialNotFound, validProductCheck) => serialNotFound && !validProductCheck && values.searchSerialNumber;

export const getReplacementDetails = (repairDetails, constants, t) =>
  `<ul class="repair-info-tooltip__list">
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("global.partNumber")}</span>
        <span>${repairDetails.replacement.partNumber || "-"}</span>
      </li>
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("global.description")}</span>
        <span>${repairDetails.replacement.description || "-"}</span>
      </li>
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("global.producer")}</span>
        <span>${constants?.producers?.[repairDetails.replacement.producerId]?.strVal || "-"}</span>
      </li>
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("global.invoice")}</span>
        <span>${repairDetails.replacement.document || "-"}</span>
      </li>
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("addReport.sellDate")}</span>
        <span>${repairDetails.replacement.sellDate || "-"}</span>
      </li>
      <li class="repair-info-tooltip__item">
        <span class="text--green">${t("addReport.warrantyLength")}</span>
        <span>${repairDetails.replacement.warrantyLength ? `${repairDetails.replacement.warrantyLength} m.` : "-"}</span>
      </li>
    </ul>`;

export const isProductDetailEmpty = (repairDetails, additionalProductDetail) =>
  (!repairDetails[additionalProductDetail] || lodash.isEmpty(repairDetails[additionalProductDetail].toString())) &&
  !productDetailsWithNullableValues.includes(additionalProductDetail);
