export const pageContent = {
  pl: {
    heading: "Coś poszło nie tak...",
    info: "Bardzo przepraszamy. Nasz zespół otrzymał powiadomienie o błędzie, jeśli odświeżenie aplikacji nie rozwiązało problemu, skontaktuj się z nami.",
    buttonContent: "Odśwież",
  },
  en: {
    heading: "Something went wrong...",
    info: "We're sorry about it. Our development team got the notification about the issue, but feel free to contact us if reloading the application doesn't fix it.",
    buttonContent: "Reload",
  },
};
