import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH, WARRANTY_CONDITIONS_LINKS, ABOUT_US_LINKS, CONTACT_LINKS, MANUAL_LINKS } from "utils/config";
import envelope from "images/Envelope.svg";
import phone from "images/Phone.svg";
import contact from "images/contact2.svg";
import about from "images/about.svg";
import manual from "images/manual.svg";
import ReportAttention from "components/Utils/ReportAttention";
import { HelpSerialNumber } from "./HelpSerialNumber";
import "./styles.css";

export const HelpContent = ({ inModal }) => {
  const { t } = useTranslation();
  const user = useSelector((store) => store.user);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const [showReportAttention, setShowReportAttention] = useState(false);

  return (
    <div style={inModal && { maxWidth: "100rem" }} className={mobile || inModal ? "mobile-help-page-wrapper" : "desktop-help-page-wrapper"}>
      <div>
        <div className="help-buttons-wrapper">
          <div className="help-manual-wrapper">
            <img src={manual} alt="" className="help-icon-svg"></img>
            <a style={{ fontWeight: 600 }} target="_blank" rel="noopener noreferrer" href={MANUAL_LINKS[i18n.language]} className="help-buttons-link paragraph">
              {t("help.manual")}
            </a>
          </div>
          <div className="help-buttons-wrapper-right">
            <a target="_blank" rel="noopener noreferrer" href={WARRANTY_CONDITIONS_LINKS[i18n.language]} className="help-buttons-link paragraph">
              {t("help.warrantyConditions")}
            </a>
            {user.id ? (
              <div
                className="help-buttons-link paragraph"
                onClick={() => {
                  setShowReportAttention(true);
                }}
              >
                {t("reportAttention.title")}
              </div>
            ) : (
              <div className="paragraph text--silver">{t("reportAttention.title")}</div>
            )}
          </div>
        </div>
        <hr />
        <div className="help-texts-wrapper">
          <div>
            <h2 className="heading--6">{t("help.aboutSystem")}</h2>
            <div>{t("help.aboutSystemContent")}</div>
          </div>
          <div>
            <h2 className="heading--6">{t("help.delivery")}</h2>
            <div>{t("help.deliveryContent")}</div>
          </div>
          <HelpSerialNumber />
        </div>
      </div>
      <div>
        <hr />
        <div className="help-icons-wrapper">
          <a href="mailto:serwis@elmark.com.pl" className="help-icons-link">
            <img src={envelope} alt="" className="help-icon-svg"></img>
            {t("help.serviceEmail")}
          </a>
          <a href="tel: +48 22 773 79 37" className="help-icons-link">
            <img src={phone} alt="" className="help-icon-svg"></img>
            {t("help.servicePhone")}
          </a>
          <a target="_blank" rel="noopener noreferrer" href={CONTACT_LINKS[i18n.language]} className="help-icons-link">
            <img src={contact} alt="" className="help-icon-svg"></img>
            {t("global.contact")}
          </a>
          <a target="_blank" rel="noopener noreferrer" href={ABOUT_US_LINKS[i18n.language]} className="help-icons-link">
            <img src={about} alt="" className="help-icon-svg"></img>
            {t("help.aboutUs")}
          </a>
        </div>
      </div>
      {showReportAttention && (
        <ReportAttention
          onClose={() => {
            setShowReportAttention(false);
          }}
        />
      )}
    </div>
  );
};
