import React, { useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import QRScanner from "components/QRScanner/QRSscanner";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";

const CheckWarrantyModal = ({ active, setActive, checkWarrantyModalType, warrantyResult }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const navigate = useNavigate();
  const [serialNumber, setSerialNumber] = useState("");

  const modalContent = {
    noResult: { title: t("global.noResultsFound"), content: t("warranty.noResultWarrantyModalInfo") },
    another: { title: t("loginPage.checkWarranty"), content: t("warranty.anotherWarrantyModalInfo") },
  };

  if (!active) return;

  return (
    <Modal
      title={modalContent[checkWarrantyModalType].title}
      onClose={() => {
        if (lodash.isEmpty(warrantyResult)) navigate("/");
        setActive(false);
      }}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button
            size={mobile ? "small" : "default"}
            onClick={() => {
              navigate("/");
              setActive(false);
            }}
          >
            {t("global.home")}
          </Button>
          <Button
            size={mobile ? "small" : "default"}
            disabled={!serialNumber}
            variant="primary"
            onClick={() => {
              navigate(`/serial-number/${encodeURIComponent(serialNumber)}`, { replace: true });
              setActive(false);
              setSerialNumber("");
            }}
          >
            {t("global.check")}
          </Button>
        </Stack>
      }
    >
      <p className="paragraph--small">{modalContent[checkWarrantyModalType].content}</p>
      <TextField
        id="serialNumber"
        name="serialNumber"
        className="repairs__filters-field--full"
        value={serialNumber}
        onKeyPress={(event) => {
          if (event.code === "Enter") {
            navigate(`/serial-number/${encodeURIComponent(serialNumber)}`, { replace: true });
            setActive(false);
            setSerialNumber("");
          }
        }}
        onChange={(value) => setSerialNumber(value)}
        label={t("global.serialNumber")}
        placeholder={t("global.provideSerialNumber")}
        specialActions={<QRScanner setResultValue={(value) => setSerialNumber(value)} />}
      />
    </Modal>
  );
};

export default CheckWarrantyModal;
