import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Stack = ({ children, ...args }) => (
  <div className="flex" style={args}>
    {children}
  </div>
);

Stack.propTypes = {
  flexDirection: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
  flexWrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
  justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
  alignItems: PropTypes.oneOf(["stretch", "flex-start", "flex-end", "center", "baseline"]),
  alignContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly", "stretch"]),
  gap: PropTypes.string,
};

export default Stack;
