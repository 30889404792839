import React from "react";
import { useTranslation } from "react-i18next";
import Search from "images/Search.svg";
import { getImgClassName, getHeadingClassName, getParagraphClassName } from "./utils";
import "./styles.css";

// size options: big, small, mobile

const NoResults = ({ infoText, children, size = "big" }) => {
  const { t } = useTranslation();

  return (
    <div className="no-results-wrapper">
      <img src={Search} alt="search" className={` ${getImgClassName({ size })}`} />
      <div className="no-results-texts">
        <h6 className={` ${getHeadingClassName({ size })}`}>{t("global.noResultsFound")}</h6>
        <p className={` ${getParagraphClassName({ size })}`}>{infoText}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default NoResults;
