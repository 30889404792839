import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToastItem from "./ToastItem";
import "./Toast.css";

const Toast = ({ toastList, position = "bottom-right", autoDelete }) => {
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  return (
    <div className={`notification-container notification-container--${position}`}>
      {list.map((toast, index) => (
        <ToastItem key={index} toast={toast} position={position} autoDelete={autoDelete} />
      ))}
    </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  autoDeleteTime: PropTypes.number,
};

export default Toast;
