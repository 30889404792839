import React, { useState } from "react";
import { SingleDatePicker as ABSingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { renderMonthElement } from "./utils";

const SingleDatePicker = ({
  id,
  numberOfMonths,
  firstDayOfWeek,
  placeholder,
  openDirection,
  disabled,
  date,
  onChange,
  isOutsideRange,
  withPortal,
  startDateId,
  endDateId,
  startDate,
  startDatePlaceholderText,
  endDate,
  endDatePlaceholderText,
  variant,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <ABSingleDatePicker
      small
      noBorder
      openDirection={openDirection}
      displayFormat="YYYY-MM-DD"
      isOutsideRange={isOutsideRange}
      numberOfMonths={numberOfMonths}
      firstDayOfWeek={firstDayOfWeek}
      placeholder={placeholder}
      disabled={disabled}
      date={date}
      onDateChange={(date) => onChange(date, id)}
      focused={focus}
      onFocusChange={({ focused }) => setFocus(focused)}
      hideKeyboardShortcutsPanel
      withPortal={withPortal}
      renderMonthElement={renderMonthElement}
      {...props}
    />
  );
};

export default SingleDatePicker;
