import React from "react";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import Accordion from "stories/components/Accordion/Accordion";
import { sortAscendingOrder } from "utils/global-functions";
import AdditionalInformationItem from "./AdditionalInformationItem";
import { formatFileSize, getGroupedAttachmentsByType } from "./utils";
import Stack from "stories/components/Stack/Stack";

const AdditionalInformation = ({ productStoreInfo }) => {
  const { t } = useTranslation();

  if (lodash.isEmpty(productStoreInfo?.attachments)) return;

  const attachments = getGroupedAttachmentsByType(JSON.parse(productStoreInfo?.attachments));

  return (
    <div className="serial-number__additional-info">
      <h5 className="heading--5 serial-number__info-heading">{t("global.additionalInformation")}</h5>
      <Accordion
        content={Object.entries(attachments)
          .sort(([keyA], [keyB]) => sortAscendingOrder(keyA, keyB))
          .map(([group, attachments]) => {
            return {
              title: (
                <Stack gap="1rem" alignItems="center">
                  <span>{group === "null" ? t("global.filesDownload") : group}</span>
                  <span className="serial-number__files-counter">{attachments.length}</span>
                </Stack>
              ),
              body: (
                <ul className="serial-number__additional-info-list">
                  {attachments.map((attachment) => (
                    <AdditionalInformationItem key={attachment.position} attachment={attachment}>
                      <span>{attachment.label}</span>
                      <span>{!!parseInt(attachment.size) ? formatFileSize(parseInt(attachment.size)) : ""}</span>
                    </AdditionalInformationItem>
                  ))}
                </ul>
              ),
            };
          })}
      />
    </div>
  );
};

export default AdditionalInformation;
