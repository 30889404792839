import React from "react";
import { useTranslation } from "react-i18next";

const Results = ({ results, setCustomer, setActive }) => {
  const { t } = useTranslation();

  const headings = [t("addReport.companyName"), t("global.nip"), t("global.fullName"), t("global.email")];

  return (
    <div className="customer-search-modal__results">
      {results.length > 0 && (
        <table className="customer-search-modal__table">
          <thead className="customer-search-modal__table-head">
            <tr>
              {headings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody className="customer-search-modal__table-body">
            {results.map(({ companyName, taxNumber, personName, email, isActive }) => {
              return (
                <tr className={!!isActive ? "" : "customer-search-modal__row-disabled"} key={email} id={email}>
                  <td
                    className={!!companyName ? "pointer" : "customer-search-modal__cell-disabled"}
                    onClick={() => {
                      if (!companyName) return;

                      setCustomer(companyName);
                      return setActive(false);
                    }}
                  >
                    {!!companyName ? companyName : <hr style={{ color: "var(--font-primary)", width: "0.6rem" }} />}
                  </td>
                  <td
                    className={!!companyName ? "pointer" : " customer-search-modal__cell-disabled"}
                    onClick={() => {
                      if (!taxNumber) return;

                      setCustomer(taxNumber);
                      return setActive(false);
                    }}
                  >
                    {!!taxNumber ? taxNumber : <hr style={{ color: "var(--font-primary)", width: "0.6rem" }} />}
                  </td>
                  <td
                    className="pointer"
                    onClick={() => {
                      setCustomer(personName);
                      setActive(false);
                    }}
                  >
                    {personName}
                  </td>
                  <td
                    className="pointer"
                    onClick={() => {
                      setCustomer(email);
                      setActive(false);
                    }}
                  >
                    {email}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Results;
