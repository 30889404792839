import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    fontFamily: "HK-Grotesk",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#FFF",
    paddingTop: 30,
    paddingBottom: 15,
    paddingRight: 25,
    paddingLeft: 50,
    fontSize: 8,
  },
  pageView: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  heading: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: -20,
    fontSize: 16,
  },
  repairId: {
    marginLeft: 0,
  },
  section: {
    flexDirection: "row",
    gap: 25,
    justifyContent: "flex-start",
  },
  logo: {
    width: 105,
    height: 35,
    marginLeft: -5,
    marginTop: 1.5,
  },
  date: {
    color: "grey",
    fontSize: 10,
    marginRight: 10,
    marginTop: -5,
  },
  qr: {
    width: 120,
    height: 120,
    marginRight: "-10",
    marginTop: "-33",
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 6,
  },
  clientDataWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    marginBottom: 15,
  },
  clientData: {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "49%",
    maxWidth: "49%",
  },
  dataTitle: {
    color: "grey",
    marginBottom: 5,
  },
  dataContent: {
    flexDirection: "row",
    fontSize: 8,
  },
  dataContentGrey: {
    color: "grey",
    marginRight: 8,
  },
  textWrapper: {
    flexDirection: "row",
  },
  greyTextFront: {
    color: "grey",
    width: 65,
    maxWidth: 65,
  },
  textBack: {
    width: 180,
  },
  productTable: {
    fontSize: 8,
  },
  tableHeaderCell: {
    color: "grey",
    fontSize: 10,
    justifyContent: "center",
    alignItems: "center",
    height: 20,
  },
  tableBodyCell: {
    fontSize: 8,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: 30,
    paddingVertical: 2,
    paddingHorizontal: 3,
  },
  additional: {
    fontSize: 8,
    marginTop: 5,
    marginBottom: 15,
  },
  additionalTitle: {
    fontSize: 10,
    color: "grey",
    marginTop: 5,
  },

  deliveryWrapper: {
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "space-between",
  },
  deliveryData: {
    flexDirection: "column",
    justifyContent: "flex-start",
    fontSize: 8,
    width: "49%",
  },
  deliveryTitle: {
    fontSize: 10,
    color: "grey",
    marginBottom: 5,
  },
  deliveryAddress: {
    flexDirection: "column",
    fontSize: 8,
  },
  deliveryAddressInfoWrapper: {
    flexDirection: "column",
    marginRight: 5,
    color: "grey",
  },
  summarySvgWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
    marginBottom: 20,
    marginLeft: -10,
  },
  singleSvgWrapper: {
    alignItems: "center",
    justifyContent: "space-between",
    height: 85,
    width: 50,
    marginHorizontal: 10,
    marginBottom: 10,
  },
  stamp: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  stampField: {
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 80,
    width: 200,
    border: 1,
    borderColor: "grey",
  },
  stampText: {
    marginLeft: 3,
    marginTop: 2,
    color: "grey",
    fontSize: 8,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 250,
  },
});
