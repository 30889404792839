/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "stories/components/Modal/Modal";
import agent from "api/agent";
import TextField from "stories/components/TextField/TextField";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Results from "./Results";
import "./styles.css";

const CustomerSelectorModal = ({ active, setActive, setCustomer, zIndex }) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchFieldRef = useRef(null);

  const accountSearchSuccess = (response) => {
    if (response.data.length === 0) throw new Error("302");

    setResults(response.data);
  };

  const accountSearchError = useCallback(
    (error) => {
      setResults([]);
      dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
      filteredErrors.forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
    },
    [commonApiErrors.codeList, dispatch, t],
  );

  const accountSearchCatch = (error) => {
    setResults([]);
    dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${error.message}`),
      },
    });
  };

  const accountSearch = useCallback(
    (inputValue) =>
      agent.Resources.accountSearch({ id: user.id, token: user.token, filter: inputValue })
        .then(accountSearchSuccess, accountSearchError)
        .catch(accountSearchCatch)
        .finally(() => setLoading(false)),
    [accountSearchError, user.id, user.token],
  );

  const accountSearchAfterKeyPressed = useCallback(
    lodash.debounce((inputValue) => {
      if (!loading) setLoading(true);

      accountSearch(inputValue);
    }, 1000),
    [loading, accountSearch],
  );

  useEffect(() => {
    setTimeout(() => searchFieldRef.current?.select(), [500]);
  }, [active]);

  useEffect(() => {
    searchFieldRef.current?.focus();
  }, [loading]);

  if (!active) return;

  return (
    <Modal
      title={t("global.customerSelectorModalTitle")}
      subTitle={t("global.customerSelectorModalSubTitle")}
      size="huge"
      onClose={() => setActive(false)}
      zIndex={zIndex}
    >
      <TextField
        ref={searchFieldRef}
        preventDefaultActive
        loading={loading}
        onClick={() => accountSearch(searchPhrase)}
        variant="search"
        value={searchPhrase}
        onChange={(value) => {
          setSearchPhrase(value);
          accountSearchAfterKeyPressed(value);
        }}
      />
      <Results results={results} setCustomer={setCustomer} setActive={setActive} />
    </Modal>
  );
};

export default CustomerSelectorModal;
