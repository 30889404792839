import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import "./styles/AddReport.css";

export const AddReportHeading = () => {
  const { t } = useTranslation();
  const {
    registration: { registrationStep },
  } = useSelector((store) => store);

  const panels = {
    1: "products",
    2: "delivery",
    3: "data",
    4: "summary",
  };

  return (
    <MediaQuery minWidth={MIN_DESKTOP_WIDTH}>
      <div className="add-report__heading">
        {panels[registrationStep] === "summary" ? (
          <span className="add-report__heading-item text--left add-report__heading-item--current">{t("addReport.summary")}</span>
        ) : (
          <>
            <div className={`add-report__heading-item text--left ${panels[registrationStep] === "products" ? "add-report__heading-item--current" : ""}`}>
              <span>{t("addReport.addProducts")}</span>
            </div>
            <div className="add-report__border" />
            <div className={`add-report__heading-item text--center ${panels[registrationStep] === "delivery" ? "add-report__heading-item--current" : ""}`}>
              <span>{t("addReport.chooseDelivery")}</span>
            </div>
            <div className="add-report__border" />
            <div className={`add-report__heading-item text--right ${panels[registrationStep] === "data" ? "add-report__heading-item--current" : ""}`}>
              <span>{t("addReport.enterData")}</span>
            </div>
          </>
        )}
      </div>
      <div className="add-report__border add-report__border--full" />
    </MediaQuery>
  );
};
