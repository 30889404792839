import React from "react";
import { useTranslation } from "react-i18next";
import Radio from "stories/components/Radio/Radio";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import {
  COUNTRIES_WITH_FREE_DELIVERY,
  DELIVERY_TYPE_ELMARK_COURIER,
  DELIVERY_TYPE_OWN_COURIER,
  DELIVERY_TYPE_PERSONAL,
  DELIVERY_TYPE_TO_BE_AGREED,
} from "utils/config";
import DhlLogoIcon from "images/dhl.svg";
import DeliveryPackagesForm from "./DeliveryPackagesForm";
import AddressForm from "../../../components/AddressForm/AddressForm";

const DeliveryForm = ({
  formErrors,
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedUser,
  packageQuantityLimit,
  setStreetWarningModalActive,
  setFieldTouched,
  productFullListIncludesUR,
}) => {
  const { t } = useTranslation();

  const clearDeliveryAddress = () => {
    const deliveryKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("deliveryAddress"))
      .map((value) => value.at(0));

    deliveryKeys.forEach((deliveryKey) => {
      values[`${deliveryKey}`] = "";
    });
    values["deliveryAddressCountryId"] = "PL";
  };

  const setDeliveryAddresFromInvoice = () => {
    const deliveryKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("deliveryAddress"))
      .map((value) => value.at(0));

    deliveryKeys.forEach((deliveryKey) => {
      setFieldTouched(deliveryKey, true);
      const userKey = deliveryKey.replace("deliveryAddress", "");
      const [userKeyFirstLetter, ...rest] = userKey;
      if (userKeyFirstLetter[0].toLowerCase() + rest.join("")) values[`${deliveryKey}`] = selectedUser[userKeyFirstLetter[0].toLowerCase() + rest.join("")];
    });

    values.deliveryAddressStreet = "";
    values.deliveryAddressBuilding = values.deliveryAddressBuilding || "";
    values.deliveryAddressPlace = values.deliveryAddressPlace || "";
    values.deliveryAddressCompanyName = values.deliveryAddressCompanyName || "";
  };

  return !productFullListIncludesUR ? (
    <div>
      <div className="add-report-delivery__radio-container">
        <Radio
          checked={values.deliveryType === DELIVERY_TYPE_PERSONAL}
          onChange={() => setFieldValue("deliveryType", DELIVERY_TYPE_PERSONAL)}
          label={t("constants.delivery.type.personal")}
          name="delivery_type"
        />
        <Radio
          checked={values.deliveryType === DELIVERY_TYPE_OWN_COURIER}
          onChange={() => setFieldValue("deliveryType", DELIVERY_TYPE_OWN_COURIER)}
          label={t("constants.delivery.type.ownCourier")}
          name="delivery_type"
        />
        {COUNTRIES_WITH_FREE_DELIVERY.includes(selectedUser.countryId) && (
          <div>
            <div className="add-report-delivery__radio-wrapper">
              <Radio
                checked={values.deliveryType === DELIVERY_TYPE_ELMARK_COURIER}
                onChange={() => setFieldValue("deliveryType", DELIVERY_TYPE_ELMARK_COURIER)}
                label={t("constants.delivery.type.dhlCourier")}
                name="delivery_type"
              />
              <img className="add-report-delivery__icon-wrapper no-select" src={DhlLogoIcon} alt="dhl_logo" />
            </div>
            <p className="paragraph--small text--subdued add-report-delivery__radio-description">{t("addReport.dhlInfo")}</p>
          </div>
        )}
      </div>
      {values.deliveryType === DELIVERY_TYPE_ELMARK_COURIER && (
        <Stack flexDirection="column" gap="3rem">
          <DeliveryPackagesForm
            errors={errors}
            handleBlur={handleBlur}
            formErrors={formErrors}
            values={values}
            setFieldValue={setFieldValue}
            packageQuantityLimit={packageQuantityLimit}
          />
          <div>
            <Stack flexDirection="column" gap="1rem">
              <AddressForm
                type="delivery"
                formErrors={formErrors}
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                options={{ showPhoneNumberFormatsTooltip: true }}
              />
              <Stack flexWrap="wrap" gap="var(--modal-buttons-gap)">
                <Button
                  outline
                  variant="error"
                  size="small"
                  onClick={() => {
                    clearDeliveryAddress();
                    setFieldValue("deliveryFromInvoice", false);
                  }}
                >
                  {t("global.clear")}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setDeliveryAddresFromInvoice();
                    setFieldValue("deliveryFromInvoice", true);
                    setStreetWarningModalActive(true);
                  }}
                >
                  {t("addReport.fillInvoiceData")}
                </Button>
              </Stack>
            </Stack>
          </div>
        </Stack>
      )}
    </div>
  ) : (
    <div className="add-report-delivery__radio-container">
      <Radio
        checked={values.deliveryType === DELIVERY_TYPE_TO_BE_AGREED}
        onChange={() => setFieldValue("deliveryType", DELIVERY_TYPE_TO_BE_AGREED)}
        label={t("constants.delivery.type.toBeAgreed")}
        name="delivery_type"
      />
      <p className="paragraph--small text--subdued add-report-delivery__radio-description">{t("addReport.toBeAgreedInfo")}</p>
    </div>
  );
};

export default DeliveryForm;
