import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmployee } from "utils/global-functions";
import Stack from "stories/components/Stack/Stack";
import { getMessageBoxClasses } from "./utils";
import "./styles.css";

const MessageBox = ({
  content,
  authorId,
  recipientId,
  author,
  date,
  ownMessage,
  response,
  fullWidth,
  internalComment,
  reply,
  actions,
  attachments,
  recipient,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  const authorMap = {
    true: t("global.elmark"),
    false: "",
  };

  const getAuthor = () => (authorId === user.id ? user.personName : authorMap[authorId === 0 && !isEmployee(user)]);

  const getRecipient = () => (recipientId === user.id ? user.personName : authorMap[recipientId === 0 && !isEmployee(user)]);

  return (
    <div className={`message-box ${getMessageBoxClasses({ ownMessage, response, fullWidth, internalComment })}`}>
      <Stack justifyContent="space-between" alignItems="flex-start">
        <Stack flexDirection="column">
          <div>
            <span className="paragraph--small text--subdued">{`${t("global.from")}: `}</span>
            <span className={user.id === authorId ? "its-me" : "paragraph--small text--subdued"}>{author || getAuthor()}</span>
          </div>
          <div>
            <span className="paragraph--small text--subdued">{`${t("global.to")}: `}</span>
            <span className={user.id === recipientId ? "its-me" : "paragraph--small text--subdued"}>{recipient || getRecipient()}</span>
          </div>
        </Stack>
        {actions}
      </Stack>
      <span className="message-box__content">{content}</span>
      {!!attachments?.length && (
        <ul>
          {attachments.map((attachment) => (
            <li key={attachment.id}>
              <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                {attachment.fileName}
              </a>
            </li>
          ))}
        </ul>
      )}
      <div className={`message-box__footer ${reply ? "" : "message-box__footer--end"}`}>
        {reply}
        <span className="message-box__date">{moment(date).format("YYYY-MM-DD HH:mm:ss")}</span>
      </div>
    </div>
  );
};

export default MessageBox;
