import React from "react";
import { useTranslation } from "react-i18next";

const ParentMessage = ({ responseMessageActive, conversationList, selectedConversationId, setSelectedConversationId, setResponseMessageActive }) => {
  const { t } = useTranslation();

  if (!responseMessageActive) return;

  return (
    <div className="parent-message">
      <div className="parent-message__content-wrapper">
        <span className="paragraph--small text--subdued text--bold">{t("myRepairs.responseFor")}: </span>
        <div className="paragraph--small parent-message__message-wrapper">{conversationList.find(({ id }) => id === selectedConversationId).text}</div>
      </div>
      <div>
        <button
          onClick={() => {
            setSelectedConversationId(null);
            setResponseMessageActive(false);
          }}
          className="unset-all custom-link"
        >
          {t("global.cancel")}
        </button>
      </div>
    </div>
  );
};

export default ParentMessage;
