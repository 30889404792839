import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH, MIN_DESKTOP_WIDTH, REPAIR_SOURCE_CLIENT } from "utils/config";
import Button from "stories/components/Button/Button.js";
import { getNewRegistrationId } from "../utils";
import "../styles/AddReport.css";
import "./styles.css";

export const Client = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);

  return (
    <div className="add-report">
      <div className="first-panel">
        <h3 className="heading--3 no-margin">{t("addReport.title")}</h3>
        <h4 className="heading--5 no-margin text--no-bold">{t("addReport.info")}</h4>

        <MediaQuery minWidth={MIN_DESKTOP_WIDTH}>
          <div className="add-report__steps">
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step1")}</span>
              <span>{t("addReport.addProducts")}</span>
            </div>
            <hr className="add-report__line" />
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step2")}</span>
              <span>{t("addReport.chooseDelivery")}</span>
            </div>
            <hr className="add-report__line" />
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step3")}</span>
              <span>{t("addReport.enterData")}</span>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={MAX_MOBILE_WIDTH}>
          <div className="add-report__steps">
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step1")}</span>
              <span>{t("addReport.addProducts")}</span>
            </div>
            <hr className="mobile-add-report-line" />
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step2")}</span>
              <span>{t("addReport.chooseDelivery")}</span>
            </div>
            <hr className="mobile-add-report-line" />
            <div className="add-report__step">
              <span className="text--bold">{t("addReport.step3")}</span>
              <span>{t("addReport.enterData")}</span>
            </div>
          </div>
        </MediaQuery>
        <div className="add-report__buttons-wrapper">
          <Button className="client-add-report__button" variant="default" size={mobile ? "small" : "default"} onClick={() => navigate("/")}>
            {t("addReport.mainPage")}
          </Button>
          <Button
            className="client-add-report__button"
            variant="primary"
            size={mobile ? "small" : "default"}
            onClick={() => {
              dispatch({
                type: "REGISTRATION_SET",
                data: { registrationId: getNewRegistrationId(), repairSource: REPAIR_SOURCE_CLIENT, userId: user.id, step: 1, authorId: user.id },
              });
            }}
          >
            {t("global.start")}
          </Button>
        </div>
        <h5 style={{ marginTop: "1.8rem" }} className="text--no-bold text--red">
          {t("addReport.registrationDataDeleteInfo")}
        </h5>
      </div>
    </div>
  );
};
