import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Button from "stories/components/Button/Button";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { getPackageQuantityLimit, getPackageTypeOptions, isNoMorePackageOption, isPackageQuantityLimit } from "./utils";

const DeliveryPackagesForm = ({ formErrors, values, setFieldValue, errors, handleBlur, packageQuantityLimit }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const {
    app: { constants },
  } = useSelector((store) => store);
  const [packageTypeOptions, setPackageTypeOptions] = useState(getPackageTypeOptions(constants.packageType));
  const [allPackageQuantity, setAllPackageQuantity] = useState([1]);

  const handleUpdatepackageQuantityLimit = (value, index) => {
    setAllPackageQuantity((arr) => {
      arr[index] = value;

      return [...arr];
    });
  };

  useEffect(() => {
    setPackageTypeOptions(getPackageTypeOptions(constants.packageType, values.deliveryPackages));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)]);

  return (
    <div className="add-report-delivery__parcels-form">
      {values.deliveryPackages?.map((_, index) => (
        <div className="add-report-delivery__form-wrapper" key={index}>
          <SelectField
            id={`deliveryPackageSize${index}`}
            name={`deliveryPackageSize${index}`}
            placeholder={t("global.selectFromList")}
            label={`${t("addReport.packageSize")}${index + 1}`}
            isSearchable={false}
            value={values.deliveryPackages[index].type}
            onBlur={handleBlur}
            error={errors?.deliveryPackages?.at(index)?.type}
            options={packageTypeOptions}
            onChange={(value) => setFieldValue(`deliveryPackages.${index}.type`, value)}
          />
          <TextField
            id={`deliveryPackageQuantity${index}`}
            name={`deliveryPackageQuantity${index}`}
            variant="number"
            onBlur={handleBlur}
            value={values.deliveryPackages[index].quantity}
            error={errors?.deliveryPackages?.at(index)?.quantity}
            min={0}
            max={getPackageQuantityLimit(allPackageQuantity, packageQuantityLimit, index)}
            onChange={(value) => {
              setFieldValue(`deliveryPackages.${index}.quantity`, value);
              handleUpdatepackageQuantityLimit(value, index);
            }}
          />
        </div>
      ))}
      {formErrors?.deliveryPackages && <span className="paragraph--small text--red">{formErrors.deliveryPackages}</span>}
      <div className="add-report-delivery__parcel-buttons">
        {values.deliveryPackages.length > 1 && (
          <Button
            variant="error"
            outline
            size={mobile ? "small" : "default"}
            onClick={() => {
              if (values.deliveryPackages.length === 1) return;
              handleUpdatepackageQuantityLimit(0, values.deliveryPackages.length - 1);
              values.deliveryPackages.pop();
              setFieldValue("deliveryPackages", values.deliveryPackages);
            }}
          >
            {t("addReport.deletePackage")}
          </Button>
        )}
        {(!isNoMorePackageOption(constants, values) || !isPackageQuantityLimit(packageQuantityLimit, allPackageQuantity)) && (
          <Button
            size={mobile ? "small" : "default"}
            onClick={() => {
              if (isNoMorePackageOption(constants, values) || isPackageQuantityLimit(packageQuantityLimit, allPackageQuantity)) return;

              handleUpdatepackageQuantityLimit(1, values.deliveryPackages.length);
              setFieldValue("deliveryPackages", [...values.deliveryPackages, { type: "", quantity: 1 }]);
            }}
          >
            {t("addReport.addPackage")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DeliveryPackagesForm;
