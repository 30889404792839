/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { defaultExtenstions } from "./config";
import { getExtensionsList } from "./utils";
import "./Dropzone.css";

function Dropzone({ label, addFilesValue, files, extensions = defaultExtenstions }) {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [filesList, setFilesList] = useState([]);

  getExtensionsList(extensions);

  useEffect(() => {
    const filesArray = Array.from(files);
    setFilesList(filesArray.length ? filesArray.map((file) => <li key={file.path}>{file.path}</li>) : []);
  }, [files]);

  const onDrop = (acceptedFiles) => {
    const dataTransfer = new DataTransfer();

    const currentFilesArray = Array.from(files);
    const allFiles = [...currentFilesArray, ...acceptedFiles];
    const uniqueFiles = Array.from(new Set(allFiles.map((file) => file.path))).map((path) => allFiles.find((file) => file.path === path));

    uniqueFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });

    addFilesValue(dataTransfer.files);
  };

  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: extensions,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <section>
      <label className="dropzone-label">{label}</label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="dropzone-top-text">
          {mobile ? (
            <button type="button" className="dropzone-browse-btn" onClick={open}>
              {t("dropzone.browse")}
            </button>
          ) : isDragActive ? (
            <span className="dropzone-blue-text">{t("dropzone.drop")}</span>
          ) : (
            <span>
              <span className="dropzone-blue-text">{t("dropzone.drag")}</span>
              <button type="button" className="dropzone-browse-btn" onClick={open}>
                {t("dropzone.browse").toLowerCase()}
              </button>
            </span>
          )}
        </div>
        <div className="dropzone-files-formats">
          <span className="dropzone-blue-text">{t("dropzone.format")}</span>
          <span>{getExtensionsList(extensions)}</span>
        </div>
        <div className="dropzone-files-list">
          <ul>{filesList}</ul>
        </div>
      </div>
    </section>
  );
}

Dropzone.propTypes = {
  label: PropTypes.string,
  filesValue: PropTypes.any,
  setFilesValue: PropTypes.func,
};

export default Dropzone;
