import { partNamesCookieKeyName } from "./config";

const getParsedPartNamesCookie = () => {
  const partNamesCookie = localStorage.getItem(partNamesCookieKeyName);
  return partNamesCookie ? JSON.parse(partNamesCookie) : [];
};

export const savePartNameToCookies = (partName) => {
  if (!partName) return;
  const parsedPartNamesCookie = getParsedPartNamesCookie();
  if (parsedPartNamesCookie.includes(partName)) return;
  parsedPartNamesCookie.push(partName);
  return localStorage.setItem(partNamesCookieKeyName, JSON.stringify(parsedPartNamesCookie));
};

export const filterPartNamesOptions = (input) => {
  const parsedPartNamesCookie = getParsedPartNamesCookie();
  return parsedPartNamesCookie
    .filter((partNames) => partNames.toLowerCase().includes(input.toLowerCase()))
    .map((partNames) => {
      return { label: partNames, value: partNames };
    });
};

export const getPartNamesList = (input, callback) => {
  callback(filterPartNamesOptions(input));
};

export const getPartNamesOptions = (partName) => {
  savePartNameToCookies(partName);
  const parsedPartNamesCookie = getParsedPartNamesCookie();
  return parsedPartNamesCookie.map((partName) => {
    return { label: partName, value: partName };
  });
};
