import React from "react";
import { Link } from "react-router-dom";
import Chevron from "images/Chevron.svg";
import "./styles.css";

const CardBackButton = ({ title, url }) => (
  <Link to={url} className="mobile-back-button">
    <img src={Chevron} alt="v" className="mobile-back-button__icon" />
    <span className="mobile-back-button__text">{title}</span>
  </Link>
);

export default CardBackButton;
