import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Page = ({ heading, children }) => (
  <div className="page">
    {heading && <div className="page__heading heading--3">{heading}</div>}
    <div className="page__content">{children}</div>
  </div>
);

export default Page;

Page.propTypes = {
  heading: PropTypes.any,
  content: PropTypes.any,
};
