import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";
import Page from "stories/components/Page/Page";
import { Admin } from "./Admin/Admin";
import { Client } from "./Client/Client";
import { AddReportProducts } from "./AddReportProducts/AddReportProducts";
import { AddReportDelivery } from "./AddReportDelivery/AddReportDelivery";
import { AddReportData } from "./AddReportData/AddReportData";
import { AddReportSummary } from "./AddReportSummary/AddReportSummary";
import { AddReportSuccess } from "./AddReportSuccess/AddReportSuccess";
import { AddReportAuthorErrorModal } from "./AddReportAuthorErrorModal";
import { isCurrentRegistrationBelongsToDifferentUser } from "./utils";

const AddReport = () => {
  const { t } = useTranslation();
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const { user, registration } = useSelector((store) => store);

  const registrationStepRoutes = {
    0: isAdmin(user) || isReception(user) ? <Admin /> : <Client />,
    1: <AddReportProducts />,
    2: <AddReportDelivery />,
    3: <AddReportData />,
    4: <AddReportSummary />,
    5: <AddReportSuccess />,
  };

  return (
    <Page heading={desktop && t("panel.add")}>
      {isCurrentRegistrationBelongsToDifferentUser(registration, user) ? <AddReportAuthorErrorModal /> : registrationStepRoutes[registration.registrationStep]}
    </Page>
  );
};

export default AddReport;
