import React from "react";
import CheckMark from "images/CheckMark.svg";
import "./styles.css";

const TableCheckbox = ({ disabled = false, name, onChange = () => {}, ...rest }) => (
  <label className={`custom-table__checkbox-wrapper ${disabled ? "custom-table__checkbox-wrapper__disabled" : ""}`}>
    <input type="checkbox" name={name} disabled={disabled} onChange={onChange} {...rest} />
    <span className="custom-table__checkmark-wrapper">
      <img src={CheckMark} alt="&#10004;" />
    </span>
  </label>
);

export default TableCheckbox;
