import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Tooltip from "stories/components/Tooltip/Tooltip";
import vector from "images/Vector.svg";
import chat from "images/Chat.svg";
import down from "images/IconDown.svg";
import { LangSwitch } from "./LangSwitch";
import "./styles/AccountNav.css";

export const Account = ({ logoutCallback }) => {
  const { user } = useSelector((store) => store);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="account">
      <div className="account__buttons-wrapper">
        <Tooltip allowHtml content={`${t("panel.add")}`} direction="bottom" zIndex={999999}>
          <button className="account__button" onClick={() => navigate(`/addreport`)}>
            <img src={vector} className="account__button-icon no-select" alt="+" />
          </button>
        </Tooltip>
        <Tooltip allowHtml content={`${t("panel.actions")}`} direction="bottom" zIndex={999999}>
          <button className="account__button" onClick={() => navigate(`/actions`)}>
            <img src={chat} className="account__button-icon no-select" alt="actions" />
            {user.notificationCount ? <div className="account-actions-count-circle">{user.notificationCount}</div> : null}
          </button>
        </Tooltip>
        <LangSwitch />
      </div>

      <div className="account__pipe" />

      <div className="dropdown account__dropdown">
        <button className="dropdown-toggle account__dropdown-button" type="button" id="accountDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
          {user.personName}
          <img src={down} className={`account__dropdown-icon no-select `} alt="v" />
        </button>
        <ul className="dropdown-menu account__dropdown-options" aria-labelledby="accountDropdownMenu" id="ul">
          <li
            className={"account__dropdown-option"}
            onClick={() => {
              navigate("/account");
            }}
          >
            {t("account.data")}
          </li>

          <li className={"account__dropdown-option account__dropdown-option--logout"} onClick={logoutCallback}>
            {t("global.logout")}
          </li>
        </ul>
      </div>
    </div>
  );
};

export const AccountMobile = ({ navMobileActive, closeNavMobile }) => {
  const [isOpenAccount, setOpenAccount] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickAcc = () => {
    closeNavMobile();
    navigate("/account");
  };

  useEffect(() => {
    setOpenAccount(false);
  }, [navMobileActive]);

  return (
    <div className="mobile-account__dropdown">
      <button
        className={`mobile-account__dropdown-button`}
        onClick={() => {
          setOpenAccount(!isOpenAccount);
        }}
      >
        <img src={down} className={`${isOpenAccount ? "mobile-account__icon--rotated" : "mobile-account__icon"}`} alt="show" />
        <span>{t("account.myAccount")}</span>
      </button>

      <div className={isOpenAccount ? "mobile-account__options" : "mobile-account__options--hidden"}>
        <button className="mobile-account__option" onClick={clickAcc}>
          <span>{t("account.data")}</span>
        </button>
      </div>
    </div>
  );
};
