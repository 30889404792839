import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import Content from "./Content";

const Mobile = ({ photosLoading, setPhotosModalActive, photos, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosPreviewModalActive }) => {
  return photosLoading ? (
    <PageSpinner subPage />
  ) : (
    <Content
      setPhotosModalActive={setPhotosModalActive}
      photos={photos}
      setDeletePhotoModalActive={setDeletePhotoModalActive}
      setSelectedPhotoId={setSelectedPhotoId}
      setPhotosPreviewModalActive={setPhotosPreviewModalActive}
    />
  );
};

export default Mobile;
