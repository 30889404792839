import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField } from "utils/global-functions";

const ContactForm = ({ values, setFieldValue, handleBlur, formErrors, errors, touched }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        id="contactPerson"
        name="contactPerson"
        onBlur={handleBlur}
        value={values.contactPerson}
        onChange={(value) => setFieldValue("contactPerson", value)}
        placeholder={t("global.provideFullName")}
        label={t("global.fullName")}
        error={formErrors.contactPerson || getErrorForInputField(errors.contactPerson, touched.contactPerson)}
      />
      <TextField
        id="contactPhone"
        name="contactPhone"
        onBlur={handleBlur}
        value={values.contactPhone}
        onChange={(value) => setFieldValue("contactPhone", value)}
        placeholder={t("global.provideTelephone")}
        label={t("global.telephone")}
        error={formErrors.contactPhone || getErrorForInputField(errors.contactPhone, touched.contactPhone)}
      />
      <TextField
        id="contactEmail"
        name="contactEmail"
        onBlur={handleBlur}
        value={values.contactEmail}
        onChange={(value) => setFieldValue("contactEmail", value)}
        placeholder={t("global.provideEmail")}
        label={t("global.email")}
        error={formErrors.contactEmail || getErrorForInputField(errors.contactEmail, touched.contactEmail)}
      />
    </>
  );
};

export default ContactForm;
