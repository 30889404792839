import React from "react";
import TableCheckbox from "./TableCheckbox";

const TableBody = ({ rows, selectable, selected, setSelected, extended }) => (
  <tbody>
    {!!rows?.length &&
      rows.map((row, index) => {
        if (extended) return row;
        return (
          <tr key={row.props?.id || index}>
            {selectable && (
              <td
                onMouseDown={() => {
                  if (selected.some((x) => x === row.props.id)) return setSelected(selected.filter((x) => x !== row.props.id));
                  return setSelected([...selected, row.props.id]);
                }}
                className="custom-table__cell--checkbox pointer"
              >
                <TableCheckbox checked={selected.some((x) => x === row.props.id)} />
              </td>
            )}
            {row}
          </tr>
        );
      })}
  </tbody>
);

export default TableBody;
