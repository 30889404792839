import React, { useEffect, useState } from "react";

const ToastItem = ({ toast, autoDelete, position }) => {
  const [active, setActive] = useState(true);
  const autoDeleteTime = toast.autoDeleteTime || 5500;

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete) setActive(false);
    }, autoDeleteTime);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!active) return;

  return (
    <div className={`toast toast--${position} toast-${toast.type} align-items-center text-bg-primary border-0`} role="alert">
      <div className="d-flex">
        <div className="toast-body">{toast.description}</div>
        <button type="button" className="btn-close btn-close-white me-2 m-auto" onClick={() => setActive(false)} />
      </div>
    </div>
  );
};

export default ToastItem;
