const variantsMap = new Map([
  ["primary", "button__primary"],
  ["error", "button__error"],
]);

const sizesMap = new Map([
  ["small", "button__small"],
  ["big", "button__big"],
]);

export const getButtonClassName = ({ variant, size, fullWidth, disabled, loading, outline, fullHeight }) => {
  const classNames = [];

  classNames.push(variantsMap.get(variant) || "");
  classNames.push(sizesMap.get(size) || "");
  outline && classNames.push(`${variantsMap.get(variant) || "button"}--outline`);
  fullHeight && classNames.push("button__full-height");
  fullWidth && classNames.push("button__full-width");
  (disabled || loading) && classNames.push(handleDisableClassName(outline, variant));

  return classNames.length ? classNames.join(" ") : "";
};

const handleDisableClassName = (outline, variant) => (outline ? `button__disabled--${variant}-outline` : `button__disabled--${variant}`);
