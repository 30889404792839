import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Button from "stories/components/Button/Button";
import { MAX_MOBILE_WIDTH, REPAIR_TYPE_NONE_VALUE } from "utils/config";
import { isAdmin } from "utils/global-functions";
import AddExternalProductForm from "./AddExternalProductForm";

const AddExternalProduct = ({
  setSerialNotFound,
  setFormErrors,
  formErrors,
  values,
  setFieldValue,
  addExternal,
  setAddExternal,
  resetForm,
  isSubmitting,
  setWarrantyErrors,
  errors,
  touched,
  handleBlur,
  validProductCheck,
  serialNotFound,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  return (
    <>
      {serialNotFound && (validProductCheck || isAdmin(user)) && !addExternal && (
        <Button
          variant={validProductCheck ? "default" : "error"}
          size={mobile ? "small" : "default"}
          onClick={() => {
            setAddExternal(true);
            setSerialNotFound(false);
            setFormErrors([]);
            setFieldValue("extSerialNumber", values.searchWarrantyInput);
            setFieldValue("serialNumber", values.searchWarrantyInput);
            setFieldValue("repairType", REPAIR_TYPE_NONE_VALUE);
          }}
        >
          {validProductCheck ? t("addReport.addOutOfRecord") : t("addReport.forceAddProduct")}
        </Button>
      )}
      <AddExternalProductForm
        setFieldValue={setFieldValue}
        values={values}
        setAddExternal={setAddExternal}
        setSerialNotFound={setSerialNotFound}
        addExternal={addExternal}
        resetForm={resetForm}
        isSubmitting={isSubmitting}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        setWarrantyErrors={setWarrantyErrors}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
      />
    </>
  );
};

export default AddExternalProduct;
