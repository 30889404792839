import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import { extractContactDataFromObject, formatBodyForChangeUserRequest, isChangeUserActionValid, isEveryValueInObjectNullable } from "./utils";
import ChangeUserForm from "./ChangeUserForm";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const ChangeUserModal = ({ active, setActive, setRefreshRepairDetails, refreshRepairDetails }) => {
  const { t } = useTranslation();
  const {
    user,
    repair,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const [changeUserLoading, setChangeUserLoading] = useState(false);
  const navigate = useNavigate();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const changeUserSuccess = (response) => {
    if (response.result === 301) {
      setFormErrors({});
      setRefreshRepairDetails(!refreshRepairDetails);
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const changeUserError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    if (filteredErrors.some((filteredError) => filteredError.code === 302)) navigate("/");
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const changeUser = (values) => {
    setChangeUserLoading(true);
    const { selectedUserId, textFieldSearchPhrase, employeeUser, ...rest } = values;
    const body = { repairId: repair.repairDetails.repairId, userId: selectedUserId, ...formatBodyForChangeUserRequest(rest) };

    agent.Repairs.changeUser({
      id: user.id,
      token: user.token,
      ...body,
    })
      .then(changeUserSuccess, changeUserError)
      .finally(() => setChangeUserLoading(false));
  };

  const validationSchema = Yup.object().shape({
    returnAddressPersonName: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    returnAddressCompanyName: Yup.string().max(255, t("errors.102")),
    returnAddressPhone: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    returnAddressEmail: Yup.string().max(255, t("errors.102")).email(t("errors.104")).required(t("errors.99")),
    returnAddressStreet: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    returnAddressBuilding: Yup.string().min(1, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    returnAddressPlace: Yup.string().max(20, t("errors.102")),
    returnAddressPostCode: Yup.string().min(3, t("errors.101")).max(20, t("errors.102")).required(t("errors.99")),
    returnAddressCity: Yup.string().min(3, t("errors.101")).max(255, t("errors.102")).required(t("errors.99")),
    returnAddressCountryId: Yup.mixed().required(t("errors.99")),
    contactPerson: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    contactPhone: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    contactEmail: Yup.string().max(255, t("errors.102")).email(t("errors.104")).required(t("errors.99")),
  });

  if (!active) return;

  return (
    <Formik
      initialValues={{
        deliveryFromInvoice: false,
        employeeUser: false,
        textFieldSearchPhrase: "",
        selectedUserId: "",
        newContactData: !isEveryValueInObjectNullable(extractContactDataFromObject(repair.repairDetails)),
        returnType: repair.repairDetails.returnType,
        returnAddressPersonName: repair.repairDetails.returnAddressPersonName || "",
        returnAddressCompanyName: repair.repairDetails.returnAddressCompanyName || "",
        returnAddressPhone: repair.repairDetails.returnAddressPhone || "",
        returnAddressEmail: repair.repairDetails.returnAddressEmail || "",
        returnAddressStreet: repair.repairDetails.returnAddressStreet || "",
        returnAddressBuilding: repair.repairDetails.returnAddressBuilding || "",
        returnAddressPlace: repair.repairDetails.returnAddressPlace || "",
        returnAddressPostCode: repair.repairDetails.returnAddressPostCode || "",
        returnAddressCity: repair.repairDetails.returnAddressCity || "",
        returnAddressCountryId: repair.repairDetails.returnAddressCountryId || "",
        contactPerson: repair.repairDetails.contactPerson || "",
        contactPhone: repair.repairDetails.contactPhone || "",
        contactEmail: repair.repairDetails.contactEmail || "",
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, touched, handleBlur }) => (
        <Form>
          <Modal
            size="big"
            title={t("myRepairs.assaignToAnotherClient")}
            onClose={(value) => {
              setActive(value);
              setFormErrors({});
            }}
            footerActions={
              <div className="new-client-modal__action-buttons">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={changeUserLoading}
                  onClick={() => {
                    setActive(false);
                    setFormErrors({});
                  }}
                >
                  {t("global.cancel")}
                </Button>
                <Button
                  size={mobile ? "small" : "default"}
                  loading={changeUserLoading}
                  disabled={!isChangeUserActionValid(values, errors)}
                  variant="primary"
                  onClick={() => changeUser(values)}
                >
                  {t("global.save")}
                </Button>
              </div>
            }
          >
            <div className="new-client-modal">
              <ChangeUserForm formErrors={formErrors} values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} handleBlur={handleBlur} />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeUserModal;
