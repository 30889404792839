/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";

export const EmailActions = ({ repairId }) => {
  const [show, setShow] = useState(false);
  const [fetching, setFetching] = useState(false);
  const buttons = ["urlog", "urpkg"];
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const sendMail = (emailType) => {
    setFetching(true);
    agent.Email.emailSendActionEmail({ id: user.id, token: user.token, repairId, emailType })
      .then(
        (response) => {
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "info", description: "Wiadomość została wysłana" } });
        },
        (error) => {
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "Przy wysyłaniu wiadomości napotkano błąd!" } });
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        },
      )
      .finally(() => {
        setFetching(false);
      });
  };

  return (
    <>
      {show && (
        <Modal title={t("emailActions.titleExt")} size="elastic" onClose={() => setShow(false)}>
          <table>
            <tbody>
              {buttons.map((item, index) => (
                <tr key={`EmailActionsButton${index}`}>
                  <td>{t(`emailActions.${item}`)}</td>
                  <td className="p-1 ps-3">
                    <Button variant="primary" size="small" disabled={fetching} onClick={() => sendMail(item)}>
                      {t("global.send")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
      <Button
        style={{ width: "11rem" }}
        variant="primary"
        onClick={() => {
          setShow(true);
        }}
      >
        <Stack alignItems="center" gap="0.6rem">
          <svg height="20px" id="Layer_1" version="1.1" viewBox="0 62 562 400" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M67,148.7c11,5.8,163.8,89.1,169.5,92.1c5.7,3,11.5,4.4,20.5,4.4c9,0,14.8-1.4,20.5-4.4c5.7-3,158.5-86.3,169.5-92.1   c4.1-2.1,11-5.9,12.5-10.2c2.6-7.6-0.2-10.5-11.3-10.5H257H65.8c-11.1,0-13.9,3-11.3,10.5C56,142.9,62.9,146.6,67,148.7z" />
              <path d="M455.7,153.2c-8.2,4.2-81.8,56.6-130.5,88.1l82.2,92.5c2,2,2.9,4.4,1.8,5.6c-1.2,1.1-3.8,0.5-5.9-1.4l-98.6-83.2   c-14.9,9.6-25.4,16.2-27.2,17.2c-7.7,3.9-13.1,4.4-20.5,4.4c-7.4,0-12.8-0.5-20.5-4.4c-1.9-1-12.3-7.6-27.2-17.2l-98.6,83.2   c-2,2-4.7,2.6-5.9,1.4c-1.2-1.1-0.3-3.6,1.7-5.6l82.1-92.5c-48.7-31.5-123.1-83.9-131.3-88.1c-8.8-4.5-9.3,0.8-9.3,4.9   c0,4.1,0,205,0,205c0,9.3,13.7,20.9,23.5,20.9H257h185.5c9.8,0,21.5-11.7,21.5-20.9c0,0,0-201,0-205   C464,153.9,464.6,148.7,455.7,153.2z" />
            </g>
          </svg>
          <span>{t("emailActions.title")}</span>
        </Stack>
      </Button>
    </>
  );
};
