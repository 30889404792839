import {
  CONVERSATION,
  CONVERSATION_CUSTOMER_QUESTION,
  CONVERSATION_CUSTOMER_REPLY,
  CONVERSATION_ELMARK_QUESTION,
  CONVERSATION_ELMARK_REPLY,
  CONVERSATION_INTERNAL,
  REPAIR_STATES_START_OF_CLOSED_STATES,
} from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";

const isSendMessageFieldDisabledForInternalConversation = (selectedRecipient) => !selectedRecipient;

const isSendMessageFieldDisabledForClientConversation = (user, responseMessageActive, repairDetails, editMessageActive) => {
  if (repairDetails.repairState >= REPAIR_STATES_START_OF_CLOSED_STATES) return true;
  if (isAdmin(user) || isReception(user) || user.id === repairDetails?.technicianId) return false;
  if (repairDetails.userId !== user.id) return true;

  return !responseMessageActive && !editMessageActive;
};

export const isSendMessageFieldDisabled = (user, responseMessageActive, repairDetails, converstionGroupType, selectedRecipient, editMessageActive) =>
  converstionGroupType === CONVERSATION_INTERNAL
    ? isSendMessageFieldDisabledForInternalConversation(selectedRecipient)
    : isSendMessageFieldDisabledForClientConversation(user, responseMessageActive, repairDetails, editMessageActive);

const getPlaceholderForInternalMessageField = () => "writeMessage";

const getPlaceholderForCustomerMessageField = (user, repairDetails, responseMessageActive, conversationList, editMessageActive) => {
  if (repairDetails.userId !== user.id) return "closed";
  if (!conversationList.some((conversation) => conversation.type === CONVERSATION_CUSTOMER_QUESTION && conversation.isClosed === 0)) return "waitForResponse";

  return responseMessageActive || editMessageActive ? "writeMessage" : "selectMessageToReply";
};

export const getPlaceholderForMessageField = (user, conversationList, responseMessageActive, repairDetails, converstionGroupType, editMessageActive) => {
  if (repairDetails.repairState >= REPAIR_STATES_START_OF_CLOSED_STATES) return "closed";
  if (isAdmin(user) || isReception(user) || user.id === repairDetails?.technicianId) return "writeMessage";

  return converstionGroupType === CONVERSATION_INTERNAL
    ? getPlaceholderForInternalMessageField()
    : getPlaceholderForCustomerMessageField(user, repairDetails, responseMessageActive, conversationList, editMessageActive);
};

export const getRestBodyForSenMessageRequest = (
  responseMessageActive,
  editMessageActive,
  selectedConversationId,
  conversationList,
  selectedConversationExtras,
) => {
  if (editMessageActive) {
    const selectedConversation = conversationList.find(({ id }) => id === selectedConversationId);
    if ([CONVERSATION_CUSTOMER_REPLY, CONVERSATION_ELMARK_REPLY].includes(selectedConversation.type))
      return { conversationId: selectedConversationId, parentId: selectedConversation.parentId };
    return selectedConversation.type === CONVERSATION_ELMARK_QUESTION
      ? { conversationId: selectedConversationId }
      : {
          conversationId: selectedConversationId,
          conversationExtras: JSON.stringify(selectedConversationExtras),
        };
  }
  if (responseMessageActive) return { parentId: selectedConversationId };
  return { conversationExtras: JSON.stringify(selectedConversationExtras) };
};

export const isMessageEditable = (user, conversationList, conversation, repairDetails) => {
  if (repairDetails.repairState >= REPAIR_STATES_START_OF_CLOSED_STATES || conversation.isClosed) return false;
  if (conversationList.some(({ parentId }) => parentId === conversation.id)) return false;

  return isAdmin(user) || isReception(user) || conversation.authorId === user.id;
};

export const getAuthorMessage = (user, authorId) => {
  if (user.id === authorId) return "you";

  return "elmark";
};

export const getConversationType = (responseMessageActive, converstionGroupType, selectedConversationId, conversationList) => {
  if (!!selectedConversationId && !responseMessageActive) return conversationList.find(({ id }) => id === selectedConversationId).type;
  if (converstionGroupType === CONVERSATION) return responseMessageActive ? CONVERSATION_CUSTOMER_REPLY : CONVERSATION_CUSTOMER_QUESTION;
  return responseMessageActive ? CONVERSATION_ELMARK_REPLY : CONVERSATION_ELMARK_QUESTION;
};

export const showExtrasSelector = (
  user,
  converstionGroupType,
  responseMessageActive,
  selectedConversationId,
  editMessageActive,
  repairDetails,
  conversationList,
) => {
  if (converstionGroupType === CONVERSATION_INTERNAL) return false;
  if (responseMessageActive) return false;
  if (conversationList.find(({ id }) => id === selectedConversationId)?.type !== CONVERSATION_CUSTOMER_QUESTION && editMessageActive) return false;

  return isAdmin(user) || isReception(user) || user.id === repairDetails?.technicianId;
};

export const canCloseMessage = (user, type, authorId, recipientId, repairDetails, isClosed) => {
  if (isClosed) return false;
  if (isAdmin(user) || isReception(user)) return true;
  if (type === CONVERSATION_CUSTOMER_QUESTION) return false;
  if (type === CONVERSATION_ELMARK_QUESTION) return user.id === authorId;
  if (type === CONVERSATION_ELMARK_REPLY) return recipientId === user.id;

  return repairDetails?.technicianId === user.id;
};

export const canReplyMessage = (isClosed, repairDetails, user, type, recipientId) => {
  if (isClosed || repairDetails.repairState >= REPAIR_STATES_START_OF_CLOSED_STATES) return false;

  return [CONVERSATION_ELMARK_QUESTION, CONVERSATION_CUSTOMER_QUESTION].includes(type) && (user.id === recipientId || isAdmin(user) || isReception(user));
};

export const getConversationRecipient = (selectedRecipient, responseMessageActive, selectedConversationId, conversationList, repairDetails) => {
  const conversation = conversationList.find(({ id }) => id === selectedConversationId);
  if (responseMessageActive) return conversation?.authorId ? { conversationRecipient: conversation.authorId } : {};
  return { conversationRecipient: selectedRecipient || repairDetails.userId };
};

export const getConversationActionType = (editMessageActive) => (editMessageActive ? "update" : "add");

export const canManageConversationAlert = (user, repairDetails) => isAdmin(user) || isReception(user) || user.id === repairDetails?.technicianId;
