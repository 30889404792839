import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { counterVariation, isEmployee } from "utils/global-functions";
import Badge from "stories/components/Badge/Badge";
import { additionalProductDetails, invoiceDataOrder, productDetailsInOrder } from "../config";
import ChangeSerialNumberModal from "../ChangeSerialNumberModal";
import EditClientContactModal from "../EditClientContactModal";
import { isProductDetailEmpty, transformContactKeyToUserKey } from "../utils";
import ChangeUserModal from "../ChangeUserModal";
import ClientContent from "./ClientContent";
import Content from "./Content";
import "./styles.css";

const Desktop = ({ refreshRepairDetails, setRefreshRepairDetails, repairDetailsTermsMap, repairDetailsDescriptionMap, repairSteps, productUrl }) => {
  const [editClientContactModalActive, setEditClientContactModalActive] = useState(false);
  const [changeUserModalActive, setChangeUserModalActive] = useState(false);
  const [changeSerialNumberModalActive, setChangeSerialNumberModalActive] = useState(false);
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
    repair: { repairDetails },
  } = useSelector((store) => store);

  const productDetailsContentDecodedValues = {
    producerId: constants.producers?.[repairDetails.producerId]?.strVal || "-",
    warrantyLength: repairDetails.warrantyLength
      ? `${repairDetails.warrantyLength} ${counterVariation(
          repairDetails.warrantyLength,
          t("global.month").toLowerCase(),
          t("global.months234").toLowerCase(),
          t("global.months05p").toLowerCase(),
        )}`
      : "-",
    warrantyUnder: repairDetails.warrantyUnder !== null ? t(`global.${!!repairDetails.warrantyUnder}`) : "-",
    repairSource: t(`global.${constants.repairSource?.[repairDetails.repairSource]?.strVal}`),
    factoryResetAgreement:
      repairDetails.factoryResetAgreement === null ? t("global.noData") : repairDetails.factoryResetAgreement ? t("global.yes") : t("global.no"),
  };

  const productDetails = {
    title: repairDetails.partNumber || <Badge content={t("global.noData")} backgroundColor={"red"} />,
    content: [
      ...productDetailsInOrder.map((productDetail) => {
        return {
          term: t(`myRepairs.${productDetail}`),
          description: productDetailsContentDecodedValues[productDetail] || (
            <p className="paragraph--small client-data-description">{repairDetails[productDetail] || "-"}</p>
          ),
        };
      }),
      ...additionalProductDetails.map((additionalProductDetail) => {
        if (isProductDetailEmpty(repairDetails, additionalProductDetail)) return {};

        return {
          term: t(`myRepairs.${additionalProductDetail}`),
          description: productDetailsContentDecodedValues[additionalProductDetail] || (
            <p className="paragraph--small client-data-description">{repairDetails[additionalProductDetail] || "-"}</p>
          ),
        };
      }),
    ],
  };

  const restDetails = [
    {
      title: t("addReport.dataToInvoice"),
      content: invoiceDataOrder
        .map((invoiceDetail) => {
          if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "companyName")
            return { term: t("global.fullName"), description: repairDetails.user.personName };

          if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "taxNumber") return null;

          return {
            term: repairDetailsTermsMap[invoiceDetail] || t(`global.${invoiceDetail}`),
            description: repairDetailsDescriptionMap[invoiceDetail] || repairDetails.invoice[invoiceDetail],
          };
        })
        .filter((value) => !!value),
    },
    {
      title: t("global.user"),
      content: [
        { term: t("global.fullName"), description: repairDetails.user.personName },
        { term: t("global.phoneNumber"), description: repairDetails.user.phone },
        { term: t("global.emailAddress"), description: repairDetails.user.email },
      ],
    },
    {
      title: t("global.contactDetails"),
      content: Object.entries(repairDetails)
        .filter(([key]) => key.includes("contact"))
        .map(([key, value]) => {
          return { term: t(`myRepairs.${key}`), description: value || repairDetails.user[transformContactKeyToUserKey(key)] };
        }),
    },
  ];

  return (
    <>
      {isEmployee(user) ? (
        <Content
          changeUserModalActive={changeUserModalActive}
          setChangeUserModalActive={setChangeUserModalActive}
          editClientContactModalActive={editClientContactModalActive}
          setEditClientContactModalActive={setEditClientContactModalActive}
          changeSerialNumberModalActive={changeSerialNumberModalActive}
          setChangeSerialNumberModalActive={setChangeSerialNumberModalActive}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          productDetails={productDetails}
          restDetails={restDetails}
          repairSteps={repairSteps}
          productUrl={productUrl}
        />
      ) : (
        <ClientContent
          productDetails={productDetails}
          restDetails={restDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          refreshRepairDetails={refreshRepairDetails}
          repairSteps={repairSteps}
          productUrl={productUrl}
        />
      )}
      <ChangeUserModal
        active={changeUserModalActive}
        setActive={setChangeUserModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <EditClientContactModal active={editClientContactModalActive} setActive={setEditClientContactModalActive} />
      <ChangeSerialNumberModal
        active={changeSerialNumberModalActive}
        setActive={setChangeSerialNumberModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
    </>
  );
};

export default Desktop;
