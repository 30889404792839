/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import { CONVERSATION_INVITATION, CONVERSATION_RATING, CONVERSATION_RATING_CUSTOMER, CONVERSATION_RATING_ELMARK, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors, isEmployee } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import RejectRatingModal from "./RejectRatingModal";
import "./styles.css";

const Rating = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [ratingResult, setRatingResult] = useState({});
  const [customerRatingResult, setCustomerRatingResult] = useState({});
  const [ratingInvitationResult, setRatingInvitationResult] = useState({});
  const [conversationListLoading, setConversationListLoading] = useState(true);
  const [conversationListRefresh, setConversationListRefresh] = useState(false);
  const [rejectRatingModalActive, setRejectRatingModalActive] = useState(false);
  const { repairId } = useParams();
  const dispatch = useDispatch();

  const showConversationsSuccess = (response) => {
    setRatingInvitationResult(response.data.find(({ type }) => type === CONVERSATION_INVITATION));
    if (isEmployee(user)) {
      setCustomerRatingResult(response.data.find(({ type }) => type === CONVERSATION_RATING_CUSTOMER));
      setRatingResult(response.data.find(({ type }) => type === CONVERSATION_RATING_ELMARK));
    } else {
      setCustomerRatingResult(response.data.find(({ type }) => type === CONVERSATION_RATING_CUSTOMER));
    }
  };

  const showConversationsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const showConversations = () => {
    setConversationListLoading(true);
    agent.Conversations.conversationsList({
      id: user.id,
      token: user.token,
      conversationTypes: CONVERSATION_RATING,
      repairId: repairId,
      pageNumber: 1,
      pageSize: 999,
    })
      .then(showConversationsSuccess, showConversationsError)
      .finally(() => setConversationListLoading(false));
  };

  useEffect(() => {
    showConversations();
  }, [conversationListRefresh]);

  return (
    <>
      {mobile ? (
        <Mobile
          ratingResult={ratingResult}
          customerRatingResult={customerRatingResult}
          ratingInvitationResult={ratingInvitationResult}
          conversationListLoading={conversationListLoading}
          conversationListRefresh={conversationListRefresh}
          setConversationListRefresh={setConversationListRefresh}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setRejectRatingModalActive={setRejectRatingModalActive}
        />
      ) : (
        <Desktop
          ratingResult={ratingResult}
          customerRatingResult={customerRatingResult}
          ratingInvitationResult={ratingInvitationResult}
          conversationListLoading={conversationListLoading}
          conversationListRefresh={conversationListRefresh}
          setConversationListRefresh={setConversationListRefresh}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setRejectRatingModalActive={setRejectRatingModalActive}
        />
      )}
      <RejectRatingModal
        active={rejectRatingModalActive}
        setActive={setRejectRatingModalActive}
        ratingInvitationResult={ratingInvitationResult}
        setRefreshRepairDetails={setRefreshRepairDetails}
        refreshRepairDetails={refreshRepairDetails}
      />
    </>
  );
};

export default Rating;
