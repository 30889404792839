import RangeDatePicker from "./RangeDatePicker";
import SingleDatePicker from "./SingleDatePicker";
import moment from "moment";

export const getDatePicker = (props) => {
  switch (props.variant) {
    case "range":
      return <RangeDatePicker {...props} />;
    default:
      return <SingleDatePicker {...props} />;
  }
};

export const renderMonthElement = ({ month, onYearSelect, onMonthSelect }) => (
  <div className="render-month-element">
    <div className="render-month-element__panel">
      <button type="button" className="render-month-element__button" onClick={() => onYearSelect(month, month.year() - 1)}>
        ←
      </button>
      <span>{month.year()}</span>
      <button type="button" className="render-month-element__button" onClick={() => onYearSelect(month, month.year() + 1)}>
        →
      </button>
    </div>
    <div className="render-month-element__panel">
      <button
        type="button"
        className="render-month-element__button"
        onClick={() => {
          const newMonthVal = moment(month).subtract(1, "month").month();
          if (newMonthVal === 11) onYearSelect(month, month.year() - 1);

          onMonthSelect(month, newMonthVal);
        }}
      >
        ←
      </button>
      <span>{month.format("MMMM")}</span>
      <button
        type="button"
        className="render-month-element__button"
        onClick={() => {
          const newMonthVal = moment(month).add(1, "month").month();
          if (newMonthVal === 0) onYearSelect(month, month.year() + 1);

          onMonthSelect(month, newMonthVal);
        }}
      >
        →
      </button>
    </div>
  </div>
);
