import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddReportFooter } from "routes/AddReport/AddReportFooter";
import Accordion from "stories/components/Accordion/Accordion";
import ReturnForm from "./ReturnForm";
import DeliveryForm from "./DeliveryForm";
import { isContinueActionDisabled } from "./utils";

const DisplayMobile = ({
  registrationShipmentSet,
  formErrors,
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedUser,
  sendShipmentLoading,
  packageQuantityLimit,
  setStreetWarningModalActive,
  setFieldTouched,
  productFullListIncludesUR,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="add-report-delivery">
      <Accordion
        content={[
          {
            title: <h3 className="heading--3">{t("addReport.chooseDelivery")}</h3>,
            body: (
              <DeliveryForm
                formErrors={formErrors}
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                selectedUser={selectedUser}
                packageQuantityLimit={packageQuantityLimit}
                setStreetWarningModalActive={setStreetWarningModalActive}
                setFieldTouched={setFieldTouched}
                productFullListIncludesUR={productFullListIncludesUR}
              />
            ),
            active: true,
          },
          {
            title: <h3 className="heading--3">{t("addReport.choosePickup")}</h3>,
            body: (
              <ReturnForm
                formErrors={formErrors}
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                selectedUser={selectedUser}
                setStreetWarningModalActive={setStreetWarningModalActive}
                setFieldTouched={setFieldTouched}
                productFullListIncludesUR={productFullListIncludesUR}
              />
            ),
            active: true,
          },
        ]}
      />
      <AddReportFooter
        backActionLabel={t("global.back")}
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 1 })}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={isContinueActionDisabled(values, errors)}
        continueAction={() => registrationShipmentSet(values)}
        continueActionLoading={sendShipmentLoading}
      />
    </div>
  );
};

export default DisplayMobile;
