/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Tooltip as BSTooltip } from "bootstrap";
import { isThisZindexTooltipClassNameExists } from "./utils";
import "./styles.css";

const Tooltip = ({ children, content, direction = "right", zIndex }) => {
  const childRef = useRef(undefined);
  const className = `tooltip-${zIndex}-zIndex`;

  useEffect(() => {
    const t = new BSTooltip(childRef.current, {
      title: `<div class="tooltip-content-wrapper">${content}</div>`,
      placement: direction,
      trigger: "hover",
      html: true,
      customClass: zIndex ? className : "",
    });

    return () => t.hide();
  }, [content, direction]);

  if (zIndex && !isThisZindexTooltipClassNameExists(className)) document.styleSheets[0].insertRule(`.${className} { z-index: ${zIndex} !important; }`, 0);

  return React.cloneElement(children, { ref: childRef });
};

export default Tooltip;
