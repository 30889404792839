import CustomerSelectorModal from "components/CustomerSelectorModal/CustomerSelectorModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { isEmployee } from "utils/global-functions";
import "./styles.css";

export const Filters = ({
  repairNumber,
  setRepairNumber,
  serialNumber,
  setSerialNumber,
  customer,
  setCustomer,
  employeeId,
  setEmployeeId,
  repairState,
  setRepairState,
  dateFrom,
  setDateFrom,
  dateUpTo,
  setDateUpTo,
  error,
}) => {
  const { t } = useTranslation();
  const {
    app: { constants },
    user,
  } = useSelector((store) => store);
  const [customerSelectorModalActive, setCustomerSelectorModalActive] = useState(false);

  return (
    <>
      <div className="actions-menu-input">
        <TextField
          label={t("global.repairNumber")}
          placeholder={t("actions.enterRepairNumber")}
          value={repairNumber}
          onChange={(value) => {
            setRepairNumber(value);
          }}
        />
      </div>
      <div className="actions-menu-input">
        <TextField
          label={t("global.serialNumber")}
          placeholder={t("actions.enterSerialNumber")}
          value={serialNumber}
          onChange={(value) => {
            setSerialNumber(value);
          }}
          error={error.length !== 0 ? t(`errors.${error}`) : null}
        />
      </div>
      {isEmployee(user) && (
        <div>
          <div className="actions-menu-input">
            <TextField
              label={t("global.customer")}
              placeholder={t("actions.enterCustomer")}
              value={customer}
              onChange={(value) => {
                setCustomer(value);
              }}
              specialActions={
                <Tooltip content={t("global.selectFromList")} zIndex={1040} direction="right">
                  <div>
                    <svg className="pointer qr-scanner__icon" onClick={() => setCustomerSelectorModalActive(true)} viewBox="0 0 48 48" width="20" fill="none">
                      <g>
                        <path d="M16.601,18.578c0.106,0,0.21-0.012,0.316-0.016c-0.085-0.597-0.145-1.201-0.145-1.822 c0-5.098,3-9.484,7.324-11.523C22.551,2.691,19.777,1,16.601,1c-4.854,0-8.789,3.935-8.789,8.789 C7.812,14.643,11.746,18.578,16.601,18.578z" />
                        <circle cx="29.476" cy="16.74" r="9.763" />
                        <path d="M37.993,26.365c-2.27,2.011-5.247,3.241-8.518,3.241c-3.256,0-6.22-1.219-8.487-3.212 c-5.913,3.227-9.953,9.7-9.953,17.165c0,1.176,0.105,2.323,0.297,3.44h36.37C47.895,45.883,48,44.735,48,43.56 C48,36.072,43.937,29.582,37.993,26.365z" />
                        <path d="M19.162,24.205c-0.628-0.886-1.153-1.849-1.542-2.881c-0.336,0.029-0.676,0.048-1.02,0.048 c-2.932,0-5.6-1.097-7.641-2.892C3.637,21.385,0,27.213,0,33.934c0,1.058,0.095,2.092,0.267,3.098h9.062 C10.636,31.484,14.27,26.838,19.162,24.205z" />
                      </g>
                    </svg>
                  </div>
                </Tooltip>
              }
            />
          </div>
          <div className="actions-menu-input">
            <SelectField
              label={t("global.employee")}
              placeholder={t("global.chooseFromList")}
              value={employeeId}
              isClearable={true}
              onChange={(value) => {
                setEmployeeId(value);
              }}
              options={constants?.employeesArr?.map(({ intVal, strVal }) => {
                return { value: intVal, label: strVal };
              })}
            />
          </div>
        </div>
      )}
      <div className="actions-menu-input">
        <SelectField
          label={t("global.repairState")}
          placeholder={t("global.chooseFromList")}
          value={repairState}
          isClearable={true}
          onChange={(value) => {
            setRepairState(value);
          }}
          options={constants?.repairStateArr?.map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.state.${strVal}`) };
          })}
        />
      </div>
      <div className="actions-menu-input">
        <DatePicker
          label={t("global.dateRange")}
          variant="range"
          startDate={dateFrom}
          startDateId={"startDate"}
          endDate={dateUpTo}
          endDateId={"endDate"}
          language={user.lang}
          onChange={({ startDate, endDate }) => {
            setDateFrom(startDate);
            setDateUpTo(endDate);
          }}
        />
      </div>
      <CustomerSelectorModal active={customerSelectorModalActive} setActive={setCustomerSelectorModalActive} setCustomer={setCustomer} />
    </>
  );
};
