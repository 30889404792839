import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MaintenanceBreakModal from "./MaintenanceBreakModal";
import CommonApiErrorModal from "./CommonApiErrorModal";

const CommonApiErrors = () => {
  const dispatch = useDispatch();
  const { app } = useSelector((store) => store);
  const { t } = useTranslation();

  return (
    <>
      {app.commonApiErrors.maintenanceBreak ? <MaintenanceBreakModal /> : null}
      {Object.keys(app.commonApiErrors)
        .filter((item) => app.commonApiErrors[item])
        .filter((item) => !["codeList", "maintenanceBreak"].includes(item))
        .map((item, index) => (
          <CommonApiErrorModal
            key={item}
            title={t(`commonApiErrors.${item}.title`)}
            content={t(`commonApiErrors.${item}.content`)}
            close={() => {
              dispatch({ type: "APP_CLOSE_COMMONAPIERROR", kind: item });
              if (["blockedAccount", "incorrectToken"].includes(item)) dispatch({ type: "USER_LOGOUT" });
            }}
          />
        ))}
    </>
  );
};

export default CommonApiErrors;
