import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { isEmployee } from "utils/global-functions";
import Accordion from "stories/components/Accordion/Accordion";
import ButtonLink from "stories/components/ButtonLink/ButtonLink";
import "./styles.css";

const BodyElement = ({ type, repairId, createdAt, text, isClosed, author, authorId, recipient, recipientId, amount, currency, effect }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: 850 });

  const { user } = useSelector((store) => store);

  const createdDateDay = createdAt.substring(0, createdAt.indexOf(" "));
  const createdDateHour = createdAt.substring(createdAt.indexOf(" "), createdAt.indexOf("."));

  let typeName;
  if (type === 1 || type === 2) {
    typeName = t("actions.conversation");
  }
  if (type === 3 || type === 4) {
    typeName = t("actions.pricing");
  }
  if (type === 5 || type === 6) {
    typeName = t("actions.conversationInternal");
  }
  if (type === 7 || type === 8 || type === 9) {
    typeName = t("actions.rating");
  }

  let statusName;
  if (isClosed === 1) {
    statusName = t("actions.closed");
  }
  if (isClosed === 0) {
    statusName = t("actions.open");
  }

  // ----- Text in BodyElement content ----- //
  let contentText = text;
  let beforeText = "";
  let contentTextLine2 = "";
  let beforeTextLine2 = "";
  let contentTextLine3 = "";
  let beforeTextLine3 = "";
  if (type === 1) {
    if (user.id === recipientId) {
      isClosed ? (contentText = text) : (contentText = `${t("global.elmark")} ${t("actions.sentMessage")}.`);
      isClosed ? (contentTextLine2 = "") : (contentTextLine2 = t("actions.toSeeIt"));
      !isEmployee(user) && isClosed ? (beforeText = `Elmark: `) : (beforeText = "");
    } else if (user.id === authorId) {
      contentText = text;
      beforeText = "";
    } else {
      contentText = text;
      beforeText = "";
    }
  }
  if (type === 2) {
    if (user.id === authorId) {
      contentText = text;
      beforeText = isEmployee(user) ? "" : `${t("global.you")}: `;
    } else if (user.id === recipientId) {
      isClosed ? (contentText = text) : (contentText = `${t("actions.toSeeMessage")}`);
      beforeText = "";
    } else {
      contentText = text;
      beforeText = "";
    }
  }
  if (type === 3) {
    contentText = text;
    beforeText = `${t("global.diagnosis")}: `;
    contentTextLine2 = `${amount ? amount : "-"} ${currency ? currency : ""}.`;
    beforeTextLine2 = `${t("global.cost")}: `;
    if (user.id === recipientId) {
      contentTextLine3 = t("actions.clientAskForPricing");
    } else {
      contentTextLine3 = t("actions.askForPricing");
    }
  }
  if (type === 4) {
    contentTextLine3 = "";
    if (user.id === authorId) {
      effect ? (contentText = t("actions.youAcceptPricing")) : (contentText = t("actions.youRejectPricing"));
    } else {
      effect ? (contentText = t("actions.clientAcceptPricing")) : (contentText = t("actions.clientRejectPricing"));
    }
    if (effect === 1) {
      contentTextLine2 = "";
    }
    if (effect === 0) {
      contentTextLine2 = text?.trim() ? text.trim() : "-";
      beforeTextLine2 = `${t("global.reason")}: `;
    }
  }
  if (type === 5 || type === 6) {
    if (isClosed) {
      contentText = text ?? "-";
    } else {
      if (user.id === recipientId) {
        contentText = t("actions.toSeeMessage");
      } else if (user.id === authorId) {
        beforeText = "";
        contentText = text ?? "-";
      } else {
        contentText = text ?? "-";
      }
    }
  }
  if (type === 7) {
    contentText = user.id === recipientId ? t("actions.clientAskForRating") : t("actions.askForRating");
  }
  if (type === 8) {
    contentText = user.id === authorId ? t("actions.youRate") : `${t("global.client")} ${t("actions.rateRepairRest")}`;
    beforeTextLine2 = `${t("actions.rating")}: `;
    beforeTextLine3 = `${t("global.comment")}: `;
    contentTextLine3 = text === "" || text === undefined || text === null ? "-" : text;
    if (effect === 0) {
      contentTextLine2 = t("global.negative");
    } else {
      contentTextLine2 = t("global.positive");
    }
  }
  if (type === 9) {
    contentText = `${t("global.admin")} ${t("actions.rateRepairRest")}`;
    beforeTextLine2 = `${t("actions.rating")}: `;
    beforeTextLine3 = `${t("global.comment")}: `;
    contentTextLine3 = text === "" || text === undefined || text === null ? "-" : text;
    if (effect === 0) {
      contentTextLine2 = t("global.negative");
    } else {
      contentTextLine2 = t("global.positive");
    }
  }

  // ---------- notification red circle display conditions ---------- //
  const [notification, setNotification] = useState(false);
  const showNotification = () => {
    if (user.id === recipientId && !isClosed) {
      setNotification(true);
    } else {
      setNotification(false);
    }
    if (isClosed) {
      setNotification(false);
    }
  };

  useEffect(() => {
    showNotification();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------- MOBILE ---------- //
  if (mobile)
    return (
      <div className="mobile-actions-body">
        <Accordion
          content={[
            {
              title: (
                <div className="mobile-body-element-heading">
                  <div className="mobile-body-element-heading-title">
                    <div>
                      <span className="mobile-body-element-grey">{t("actions.repairNo")}</span>
                      {` ${repairId}`}
                    </div>
                    {notification ? <div className="mobile-heading-new-circle"></div> : null}
                  </div>
                  <div className="mobile-body-element-heading-right mobile-body-element-grey">
                    <div>{createdDateDay}</div>
                    <div style={{ marginTop: "-0.8rem" }}>{createdDateHour}</div>
                  </div>
                </div>
              ),
              body: (
                <div className="mobile-body-element-body">
                  <table className="mobile-body-element-table">
                    <tbody>
                      <tr>
                        <td className="mobile-body-element-table-grey-column">{`${t("global.type")}: `}</td>
                        <td>{typeName}</td>
                      </tr>
                      {isEmployee(user) && (
                        <>
                          <tr>
                            <td className="mobile-body-element-table-grey-column">{`${t("global.from")}: `}</td>
                            <td>
                              <span className={user.id === authorId ? "its-me" : ""}>{type === 7 ? "Elmark" : author}</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="mobile-body-element-table-grey-column">{`${t("global.to")}: `}</td>
                            <td>
                              <span className={user.id === recipientId ? "its-me" : ""}>{recipient}</span>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>

                  {type === 3 && (
                    <div style={{ marginTop: "2.4rem" }}>
                      <span>{contentTextLine3}</span>
                    </div>
                  )}

                  <div className="mobile-body-element">
                    {type === 1 || type === 2 || type === 3 ? <span style={{ color: "var(--grey)" }}>{beforeText}</span> : null}

                    <div>{contentText}</div>

                    {type === 3 || type === 4 ? (
                      <div>
                        <span style={{ color: "var(--grey)" }}>{beforeTextLine2}</span>
                        <span>{contentTextLine2}</span>
                      </div>
                    ) : null}

                    {type === 8 || type === 9 ? (
                      <>
                        <div>
                          <span style={{ color: "var(--grey)" }}>{beforeTextLine2}</span>
                          <span style={{ fontWeight: "600" }} className={effect ? "green" : "red"}>
                            {contentTextLine2}
                          </span>
                        </div>
                        <div>
                          <span style={{ color: "var(--grey)" }}>{beforeTextLine3}</span>
                          {contentTextLine3 === "-" ? <span>{contentTextLine3}</span> : <div>{contentTextLine3}</div>}
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div>
                    <span className="mobile-body-element-grey">{t("global.status")}:</span>
                    <span>{` ${statusName}`}</span>
                  </div>

                  <div className="mobile-body-element-footer">
                    {type === 3 || type === 4 ? (
                      <ButtonLink variant="primary" size="small" url={`/${repairId}/valuation`}>
                        {t("actions.goToPricing")}
                      </ButtonLink>
                    ) : null}
                    {type === 1 || type === 2 ? (
                      <ButtonLink variant="primary" size="small" url={`/${repairId}/conversation`}>
                        {t("actions.goToConversation")}
                      </ButtonLink>
                    ) : null}
                    {type === 5 || type === 6 ? (
                      <ButtonLink variant="primary" size="small" url={`/${repairId}/conversation/internal`}>
                        {t("actions.goToConversationInternal")}
                      </ButtonLink>
                    ) : null}
                    {type === 7 ? (
                      <ButtonLink variant="primary" size="small" url={`/${repairId}/rating`}>
                        {t("actions.goToRatingForm")}
                      </ButtonLink>
                    ) : null}
                    {type === 8 || type === 9 ? (
                      <ButtonLink variant="primary" size="small" url={`/${repairId}/rating`}>
                        {t("actions.goToRepair")}
                      </ButtonLink>
                    ) : null}
                  </div>
                </div>
              ),
              active: true,
            },
          ]}
        />
      </div>
    );

  // ---------- DESKTOP ---------- //
  return (
    <div className="actions-body">
      <div className="body-element-heading">
        <div className="body-element-heading-title">
          {`${typeName} ${type === 1 || type === 2 || type === 5 || type === 6 ? t("actions.titleContent2") : t("actions.titleContent")} ${repairId}`}
          {notification ? <div className="heading-new-circle"></div> : null}
        </div>
        <div className="body-element-heading-right">
          <div>
            {t("global.status")}: <span>{statusName}</span>
          </div>
          <div className="body-element-heading-date-wrapper">
            <div className="body-element-heading-date">{createdDateDay}</div>
            <div className="body-element-heading-date" style={{ marginTop: "-0.3rem" }}>
              {createdDateHour}
            </div>
          </div>
        </div>
      </div>

      <div className="body-element">
        {isEmployee(user) && (
          <table style={{ marginBottom: "1.2rem" }}>
            <tbody>
              <tr>
                <td style={{ color: "var(--grey)", textAlign: "right" }}>{`${t("global.from")}: `}</td>
                <td className={user.id === authorId ? "its-me" : ""}>{type === 7 ? "Elmark" : author}</td>
              </tr>
              <tr>
                <td style={{ color: "var(--grey)", textAlign: "right" }}>{` ${t("global.to")}: `}</td>
                <td className={user.id === recipientId && type !== 9 ? "its-me" : ""}>{recipient}</td>
              </tr>
            </tbody>
          </table>
        )}

        {type === 3 && (
          <div>
            <span>{contentTextLine3}</span>
          </div>
        )}

        {type === 1 || type === 2 || type === 3 || type === 5 || type === 6 ? <span style={{ color: "var(--grey)" }}>{beforeText}</span> : null}

        <span>{contentText}</span>

        {type === 1 && <div>{contentTextLine2}</div>}

        {type === 3 ? (
          <div>
            <span style={{ color: "var(--grey)" }}>{beforeTextLine2}</span>
            <span>{contentTextLine2}</span>
          </div>
        ) : null}

        {type === 4 && text ? (
          <div>
            <span style={{ color: "var(--grey)" }}>{beforeTextLine2}</span>
            <span>{contentTextLine2}</span>
          </div>
        ) : null}

        {type === 8 || type === 9 ? (
          <>
            <div>
              <span style={{ color: "var(--grey)" }}>{beforeTextLine2}</span>
              <span style={{ fontWeight: "600" }} className={effect ? "green" : "red"}>
                {contentTextLine2}
              </span>
            </div>
            <div>
              <span style={{ color: "var(--grey)" }}>{beforeTextLine3}</span>
              <span>{contentTextLine3}</span>
            </div>
          </>
        ) : null}
      </div>

      <div className="body-element-footer">
        {type === 3 || type === 4 ? (
          <ButtonLink variant="primary" url={`/${repairId}/valuation`}>
            {t("actions.goToPricing")}
          </ButtonLink>
        ) : null}
        {type === 1 || type === 2 ? (
          <ButtonLink variant="primary" url={`/${repairId}/conversation`}>
            {t("actions.goToConversation")}
          </ButtonLink>
        ) : null}
        {type === 5 || type === 6 ? (
          <ButtonLink variant="primary" url={`/${repairId}/conversation/internal`}>
            {t("actions.goToConversationInternal")}
          </ButtonLink>
        ) : null}
        {type === 7 ? (
          <ButtonLink variant="primary" url={`/${repairId}/rating`}>
            {t("actions.goToRatingForm")}
          </ButtonLink>
        ) : null}
        {type === 8 || type === 9 ? (
          <ButtonLink variant="primary" url={`/${repairId}/rating`}>
            {t("actions.goToRepair")}
          </ButtonLink>
        ) : null}
      </div>
    </div>
  );
};

export default BodyElement;
