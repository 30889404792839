import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const MinimalTable = ({ headings, rows }) => (
  <table className="minimal-table">
    <thead className="minimal-table__head">
      <tr>
        {headings.map((heading, index) => (
          <th key={index} className="minimal-table__head-item">
            {heading}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          {row.map((tuple, index) => (
            <td key={index} className="minimal-table__body-item">
              {tuple}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

MinimalTable.propTypes = {
  headings: PropTypes.array,
  rows: PropTypes.array,
};

export default MinimalTable;
