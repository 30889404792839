/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH, REPAIR_TYPE_UNDER_WARRANTY } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import PageSpinner from "components/PageSpinner/PageSpinner";
import "../styles/AddReport.css";
import DisplayDesktop from "./DisplayDesktop";
import Mobile from "./DisplayMobile";
import "./styles.css";

export const AddReportProducts = () => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    registration,
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [deleteConfirmationModalActive, setDeleteConfirmationModalActive] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [addExternal, setAddExternal] = useState(false);
  const [foundProduct, setFoundProduct] = useState({});
  const [recentlyRepaired, setRecentlyRepaired] = useState(false);
  const [serialNotFound, setSerialNotFound] = useState(false);
  const [productListLoading, setProductListLoading] = useState(true);
  const [warrantyErrors, setWarrantyErrors] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [productFullList, setProductFullList] = useState([]);
  const [validProductCheck, setValidProductCheck] = useState(false);

  const underWarrantyMap = {
    true: t("addReport.warrantyCoveredTo"),
    false: t("addReport.warrantyExpiredAt"),
  };

  const accordionHeadings = {
    description: t("global.description"),
    producer: t("global.producer"),
    sellDate: t("addReport.sellDate"),
    status: t("global.warrantyStatus"),
    faultDescription: t("addReport.faultDescription"),
    extraEquipment: t("addReport.extraEquipment"),
    attachments: t("addReport.attachments"),
    factoryResetAgreement: t("myRepairs.factoryResetAgreement"),
  };

  const deleteProductSuccess = (response, data) => {
    if (response.result === 301) {
      setProductFullList((state) => state.filter((item) => item.repairId !== data.repairId));
      setDeleteConfirmationModalActive(false);
      return setDeleteActionLoading(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deleteProductError = (error) => {
    setDeleteConfirmationModalActive(false);
    setDeleteActionLoading(false);
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const deleteProduct = (data) => {
    setDeleteActionLoading(true);

    return agent.Registration.registrationProductDelete({
      id: user.id,
      token: user.token,
      registrationId: registration.registrationId,
      repairId: data.repairId,
    }).then((response) => deleteProductSuccess(response, data), deleteProductError);
  };

  const getRegistrationProductListSuccess = (response) => {
    setProductFullList(response.data);
  };

  const getRegistrationProductListError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRegistrationProductList = () =>
    agent.Registration.registrationProductList({ id: user.id, token: user.token, registrationId: registration.registrationId })
      .then(getRegistrationProductListSuccess, getRegistrationProductListError)
      .finally(() => setProductListLoading(false));

  useEffect(() => {
    getRegistrationProductList();
  }, []);

  const validationSchema = Yup.object().shape({
    faultDescription: Yup.string().min(5, t("errors.101")).max(2048, t("errors.102")).required(t("errors.99")),
    equipmentDescription: Yup.string().max(2048, t("errors.102")),
    extSerialNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    extPartNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    extDescription: Yup.string().min(3, t("errors.101")).max(200, t("errors.102")).required(t("errors.99")),
    extDocument: Yup.string().max(50, t("errors.102")),
    extWarrantyLength: Yup.number().min(0, t("errors.101")),
    extProducerId: Yup.mixed().required(t("errors.99")),
  });

  if (productListLoading) return <PageSpinner subPage />;

  return (
    <Formik
      initialValues={{
        registrationId: registration?.registrationId,
        repairSource: registration?.registrationRepairSource,
        userId: registration?.registrationUserId,
        serialNumber: "",
        extSerialNumber: "",
        extPartNumber: "",
        extDescription: "",
        extProducerId: null,
        extDocument: "",
        extSellDate: null,
        extWarrantyLength: "",
        repairType: REPAIR_TYPE_UNDER_WARRANTY,
        faultDescription: "",
        equipmentDescription: "",
        attachments: [],
        searchWarrantyInput: "",
        factoryResetAgreement: "",
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleBlur, setFieldValue, resetForm }) => (
        <Form className="add-report-products__form">
          {mobile ? (
            <Mobile
              setAddExternal={setAddExternal}
              foundProduct={foundProduct}
              setFoundProduct={setFoundProduct}
              setRecentlyRepaired={setRecentlyRepaired}
              setSerialNotFound={setSerialNotFound}
              warrantyErrors={warrantyErrors}
              setWarrantyErrors={setWarrantyErrors}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              addExternal={addExternal}
              setProductFullList={setProductFullList}
              recentlyRepaired={recentlyRepaired}
              serialNotFound={serialNotFound}
              setDeleteConfirmationModalActive={setDeleteConfirmationModalActive}
              setProductToDelete={setProductToDelete}
              productFullList={productFullList}
              productListLoading={productListLoading}
              deleteConfirmationModalActive={deleteConfirmationModalActive}
              deleteActionLoading={deleteActionLoading}
              deleteProduct={deleteProduct}
              productToDelete={productToDelete}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              underWarrantyMap={underWarrantyMap}
              accordionHeadings={accordionHeadings}
              validProductCheck={validProductCheck}
              setValidProductCheck={setValidProductCheck}
            />
          ) : (
            <DisplayDesktop
              setAddExternal={setAddExternal}
              foundProduct={foundProduct}
              setFoundProduct={setFoundProduct}
              setRecentlyRepaired={setRecentlyRepaired}
              setSerialNotFound={setSerialNotFound}
              warrantyErrors={warrantyErrors}
              setWarrantyErrors={setWarrantyErrors}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              addExternal={addExternal}
              setProductFullList={setProductFullList}
              recentlyRepaired={recentlyRepaired}
              serialNotFound={serialNotFound}
              setDeleteConfirmationModalActive={setDeleteConfirmationModalActive}
              setProductToDelete={setProductToDelete}
              productFullList={productFullList}
              productListLoading={productListLoading}
              deleteConfirmationModalActive={deleteConfirmationModalActive}
              deleteActionLoading={deleteActionLoading}
              deleteProduct={deleteProduct}
              productToDelete={productToDelete}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              underWarrantyMap={underWarrantyMap}
              accordionHeadings={accordionHeadings}
              validProductCheck={validProductCheck}
              setValidProductCheck={setValidProductCheck}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
