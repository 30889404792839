import React from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "stories/components/Dropzone/Dropzone.js";
import TextField from "stories/components/TextField/TextField";
import SelectField from "stories/components/SelectField/SelectField";
import { CURRENCY_OPTIONS } from "utils/config";
import { getErrorForInputField } from "utils/global-functions";

const AddValuationForm = ({ values, setFieldValue, errors, touched, handleBlur, formErrors, selectedConversation }) => {
  const { t } = useTranslation();

  return (
    <div className="add-pricing-form">
      <TextField
        id="conversationText"
        name="conversationText"
        variant="multi"
        value={values.conversationText}
        onChange={(value) => setFieldValue("conversationText", value)}
        placeholder={t("global.provideDiagnosis")}
        label={t("global.diagnosis")}
        numberOfLines={6}
        disableResize
        onBlur={handleBlur}
        error={getErrorForInputField(errors.conversationText, touched.conversationText) || formErrors.conversationText}
      />
      <div className="add-pricing-form__cost-field">
        <TextField
          id="conversationAmount"
          name="conversationAmount"
          value={values.conversationAmount}
          variant="minimalistNumber"
          onChange={(value) => setFieldValue("conversationAmount", value)}
          placeholder={t("global.provideRepairCost")}
          label={t("global.repairCost")}
          onBlur={handleBlur}
          error={getErrorForInputField(errors.conversationAmount, touched.conversationAmount) || formErrors.conversationAmount}
        />
        <SelectField
          id="conversationCurrency"
          value={values.conversationCurrency}
          onChange={(value) => setFieldValue("conversationCurrency", value)}
          options={CURRENCY_OPTIONS}
          isSearchable={false}
          placeholder={t("global.chooseFromList")}
          label={t("global.currency")}
          onBlur={handleBlur}
          error={getErrorForInputField(errors.conversationCurrency, touched.conversationCurrency) || formErrors.conversationCurrency}
        />
      </div>
      {!!values.keepAttachmentsIds?.length && (
        <>
          <span className="paragraph--small">{t("global.uploadedAttachments")}</span>
          <div className="pricing__keep-attachments">
            {values.keepAttachmentsIds.map((attachmentId) => {
              const attachment = selectedConversation.attachments.find(({ id }) => id === attachmentId);
              return (
                <div className="pricing__keep-attachment" key={attachmentId}>
                  <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                    {attachment.fileName}
                  </a>
                  <button
                    className="unset-all custom-link"
                    onClick={() =>
                      setFieldValue(
                        "keepAttachmentsIds",
                        values.keepAttachmentsIds.filter((id) => id !== attachmentId),
                      )
                    }
                  >
                    {t("global.delete")}
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
      <Dropzone label={t("addReport.attachments")} files={values.attachments} addFilesValue={(value) => setFieldValue("attachments", value)} />
    </div>
  );
};

export default AddValuationForm;
