import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./pdfDocument";
import "../commonStyles.css";

const PdfModal = ({ variant, repair, repairSteps, replacementInfo, todayDate, constants, lang, active, onClose }) => {
  if (!active) return;

  return (
    <div className="pdf-modal">
      <button onClick={() => onClose()} className="pdf-modal__close">
        &times;
      </button>

      <figure className="pdf-modal__figure">
        <PDFViewer width={"70%"} height={"100%"}>
          <PdfDocument
            variant={variant}
            repair={repair}
            repairSteps={repairSteps}
            replacementInfo={replacementInfo}
            todayDate={todayDate}
            constants={constants}
            lang={lang}
          />
        </PDFViewer>
      </figure>
    </div>
  );
};

export default PdfModal;
