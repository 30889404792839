/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import QRScanner from "components/QRScanner/QRSscanner";
import TextField from "stories/components/TextField/TextField";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors, isEmployee } from "utils/global-functions";
import history from "utils/history";
import FoundProduct from "components/FoundProduct/FoundProduct";
import { getRepairTypeToFoundProduct } from "./utils";

const SearchForWarranty = ({
  values,
  setAddExternal,
  foundProduct,
  setFieldValue,
  setFoundProduct,
  setRecentlyRepaired,
  setSerialNotFound,
  warrantyErrors,
  setWarrantyErrors,
  formErrors,
  setFormErrors,
  setValidProductCheck,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const checkWarrantySuccess = ({ data }) => {
    if (data.length === 0) throw new Error("1002");
    if (data.at(0).isInService === 1) throw new Error("1001");

    setWarrantyErrors([]);
    setValidProductCheck(true);
    setFormErrors([]);
    setFoundProduct(data.at(0));
    setFieldValue("repairType", getRepairTypeToFoundProduct(data));
    setRecentlyRepaired(!!data.at(0).isRecentlyRepaired);
    setSerialNotFound(false);
  };

  const checkWarrantyError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setRecentlyRepaired(false);
    setFoundProduct({});
    if (values) setValidProductCheck(false);
    if (filteredErrors.length > 0) setWarrantyErrors(filteredErrors);
  };

  const checkWarranty = (serialNumber) => {
    setLoading(true);

    return agent.Resources.checkWarranty({ serialNumber })
      .then(checkWarrantySuccess, checkWarrantyError)
      .catch((error) => {
        setFoundProduct({});
        setRecentlyRepaired(false);
        if (values) setValidProductCheck(false);
        setWarrantyErrors([{ code: error.message }]);
        if (error.message === "1002" && isEmployee(user)) setSerialNotFound(true);
      })
      .finally(() => {
        setAddExternal(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    const serialNumber = searchParams.get("serialNumber");
    if (serialNumber) {
      setFieldValue("searchWarrantyInput", serialNumber);
      checkWarranty(serialNumber);
      history.replace({ pathname: window.location.pathname, search: "" });
    }
  }, []);

  return (
    <Stack flexDirection="column" gap="1.5rem">
      <TextField
        value={values.searchWarrantyInput}
        maxLength={50}
        onChange={(value) => {
          setFieldValue("searchWarrantyInput", value);
          setFoundProduct({});
          setRecentlyRepaired(false);
          setValidProductCheck(false);
          setSerialNotFound(false);
        }}
        error={(warrantyErrors.at(0) && t(`errors.${warrantyErrors.at(0).code}`)) || formErrors?.serialNumber}
        onClick={() => checkWarranty(values.searchWarrantyInput)}
        placeholder={t("addReport.searchPlaceholder")}
        variant="search"
        loading={loading}
        specialActions={
          <QRScanner
            setResultValue={(value) => {
              setFieldValue("searchWarrantyInput", value);
              setFoundProduct({});
              setRecentlyRepaired(false);
              setValidProductCheck(false);
              setSerialNotFound(false);
              setTimeout(() => checkWarranty(value), 200);
            }}
          />
        }
      />
      <FoundProduct foundProduct={foundProduct} />
    </Stack>
  );
};

export default SearchForWarranty;
