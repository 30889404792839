import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import EmptyPage from "../EmptyPage";
import AttachmentsDetails from "./AttachmentsDetails";
import "./styles.css";

const Mobile = ({ attachmentsLoading, attachmentList, setAttachmentsModalActive, setAttachmentDeleteModalActive, setSelectedAttachmentId }) => {
  return attachmentsLoading ? (
    <PageSpinner subPage />
  ) : (
    <>
      <AttachmentsDetails
        attachmentList={attachmentList}
        setAttachmentsModalActive={setAttachmentsModalActive}
        setSelectedAttachmentId={setSelectedAttachmentId}
        setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
      />
      {!attachmentList.length && <EmptyPage />}
    </>
  );
};

export default Mobile;
