/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import { DHL_ERROR_CODE, ERROR_CODE_DATA_NOT_FOUND, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import PageSpinner from "components/PageSpinner/PageSpinner";
import DisplayDesktop from "./DisplayDesktop";
import DisplayMobile from "./DisplayMobile";

export const AddReportSummary = () => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [summaryDetails, setSummaryDetails] = useState({});
  const [summaryDetailsLoading, setSummaryDetailsLoading] = useState(true);
  const [confirmRegistrationLoading, setConfirmRegistrationLoading] = useState(false);

  const confirmRegistrationSuccess = (response) => {
    if (response.result === 301) {
      return dispatch({ type: "REGISTRATION_SETSTEP", step: 5 });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const confirmRegistrationError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    const dhlError = filteredErrors.find(({ code }) => code === DHL_ERROR_CODE);
    if (dhlError)
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: `${t(`errors.${dhlError.code}`)}\n${dhlError.cause}`,
          autoDeleteTime: 10000,
        },
      });
    filteredErrors
      .filter(({ code }) => code !== DHL_ERROR_CODE)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const confirmRegistration = () => {
    setConfirmRegistrationLoading(true);

    return agent.Registration.registrationConfirm({ id: user.id, token: user.token, registrationId: registration.registrationId })
      .then(confirmRegistrationSuccess, confirmRegistrationError)
      .finally(() => setConfirmRegistrationLoading(false));
  };

  const getRegistrationDetailsSuccess = (response) => {
    setSummaryDetails(response.data.at(0));
    setSummaryDetailsLoading(false);
  };

  const getRegistrationDetailsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
    dispatch({ type: "REGISTRATION_SETSTEP", step: 3 });
    if (filteredErrors.some((filteredError) => filteredError.code === ERROR_CODE_DATA_NOT_FOUND)) return dispatch({ type: "REGISTRATION_SETSTEP", step: 1 });
  };

  const getRegistrationDetails = () =>
    agent.Registration.registrationDetails({ id: user.id, token: user.token, registrationId: registration.registrationId }).then(
      getRegistrationDetailsSuccess,
      getRegistrationDetailsError,
    );

  useEffect(() => {
    getRegistrationDetails();
  }, []);

  if (summaryDetailsLoading) return <PageSpinner subPage />;

  return mobile ? (
    <DisplayMobile confirmRegistration={confirmRegistration} confirmRegistrationLoading={confirmRegistrationLoading} summaryDetails={summaryDetails} />
  ) : (
    <DisplayDesktop confirmRegistration={confirmRegistration} confirmRegistrationLoading={confirmRegistrationLoading} summaryDetails={summaryDetails} />
  );
};
