import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageSpinner from "components/PageSpinner/PageSpinner";
import { isAdmin, isReception, isEmployee } from "utils/global-functions";
import Accordion from "stories/components/Accordion/Accordion";
import Button from "stories/components/Button/Button";
import Badge from "stories/components/Badge/Badge";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Stack from "stories/components/Stack/Stack";
import PhotosModal from "routes/Home/Details/Photos/PhotosModal";
import { handleItemSelection, isOtherRepairsPage } from "../utils";
import Info from "images/InfoRect.svg";
import EmptyState from "./EmptyState";

const RepairsList = ({ selectedItems, setSelectedItems, repairListLoading }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
    repair: { repairsList },
  } = useSelector((store) => store);
  const navigate = useNavigate();

  const [photosModalActive, setPhotosModalActive] = useState(false);
  const [photosModalRepairId, setPhotosModalRepairId] = useState(null);

  if (repairListLoading) return <PageSpinner subPage />;

  if (repairsList.length === 0) return <EmptyState />;

  return (
    <>
      <Accordion
        shadow
        content={repairsList.map((repair) => {
          return {
            title: (
              <>
                {(isAdmin(user) || isReception(user)) && (
                  <div className="mobile-repair-admin__accordion-checkbox-wrapper">
                    <Checkbox
                      withoutPadding
                      checked={selectedItems.some((selectedItem) => selectedItem === repair.repairId)}
                      onChange={() => setSelectedItems(handleItemSelection(selectedItems, repair.repairId))}
                    />
                  </div>
                )}
                <div className="mobile-repair-admin__accordion-heading">
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{t("myRepairs.number")}:</span>
                    <span className="paragraph--small">{repair.repairId}</span>
                  </div>
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{t("myRepairs.reportDate")}:</span>
                    <span className="paragraph--small">{repair.registrationDate}</span>
                  </div>
                </div>
              </>
            ),
            body: (
              <div className="mobile-repair-admin__accordion-list">
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.serialNumber")}:</span>
                  <span className="paragraph--small">{repair.serialNumber || "-"}</span>
                </div>
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.symbol")}:</span>
                  <span className="paragraph--small">{repair.partNumber || "-"}</span>
                </div>
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.producer")}:</span>
                  <span className="paragraph--small">{constants?.producers?.[repair?.producerId]?.strVal || "-"}</span>
                </div>
                {(isEmployee(user) || isOtherRepairsPage()) && (
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{isOtherRepairsPage() ? t("myRepairs.person") : t("myRepairs.customer")}:</span>
                    <span className="paragraph--small">{repair.customer || "-"}</span>
                  </div>
                )}
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.repairState")}:</span>
                  <Badge
                    content={t(`constants.repair.state.${constants?.repairState?.[repair.repairState]?.strVal}`)}
                    backgroundColor={constants?.repairState?.[repair.repairState]?.bgColor}
                  />
                </div>
                <div className="mobile-repair-admin__accordion-item">
                  <span className="paragraph--small text--subdued">{t("myRepairs.repairKind")}:</span>
                  <span className="paragraph--small">
                    <Badge
                      content={t(`constants.repair.kind.${constants?.repairKind?.[repair.repairKind]?.strVal}`)}
                      backgroundColor={constants?.repairKind?.[repair.repairKind]?.bgColor}
                    />
                  </span>
                </div>
                {repair.repairWarnings?.length > 0 && isEmployee(user) && (
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{t("myRepairs.comments")}:</span>
                    <Stack>
                      <div className="admins-repairs-table__info-wrapper">
                        <Tooltip
                          content={`<ul class="admins-repairs-info__list">\n${repair.repairWarnings
                            .map((warning) => `<li key={${warning}}>${t(`constants.repair.warning.${warning}`)}</li>`)
                            .join("\n")}\n</ul>`}
                          direction="left"
                        >
                          <img src={Info} className="admins-repairs-table__info-icon" alt="i" />
                        </Tooltip>
                      </div>
                    </Stack>
                  </div>
                )}
                {isEmployee(user) && (
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{t("myRepairs.technician")}:</span>
                    <span className="paragraph--small">
                      {constants?.employees?.[repair.technicianId]?.strVal || constants?.technicians?.[repair.technicianId]?.strVal || t("global.none")}
                    </span>
                  </div>
                )}
                {isEmployee(user) && repair?.rentInfo && (
                  <div className="mobile-repair-admin__accordion-item">
                    <span className="paragraph--small text--subdued">{t("myRepairs.rentTo")}:</span>
                    <span className="paragraph--small">{repair?.rentInfo ?? "-"}</span>
                  </div>
                )}

                <Stack justifyContent="space-between">
                  <Button onClick={() => navigate(`/${repair.repairId}/info`)} size="small">
                    {t("global.openRepair")}
                  </Button>

                  {(isReception(user) || isAdmin(user) || repair.technicianId === user.id) && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => {
                        setPhotosModalRepairId(repair.repairId);
                        setPhotosModalActive(true);
                      }}
                    >
                      {t("myRepairs.addPhoto")}
                    </Button>
                  )}
                </Stack>
              </div>
            ),
          };
        })}
      />
      <PhotosModal photosModalRepairId={photosModalRepairId} photos={[]} active={photosModalActive} setActive={setPhotosModalActive} />
    </>
  );
};

export default RepairsList;
