import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import Content from "./Content";
import "./styles.css";

const Desktop = ({ photosLoading, setPhotosModalActive, photos, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosPreviewModalActive }) => {
  return (
    <>
      {photosLoading ? (
        <PageSpinner subPage />
      ) : (
        <Content
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          photos={photos}
          setPhotosModalActive={setPhotosModalActive}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      )}
    </>
  );
};

export default Desktop;
