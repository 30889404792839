/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../stories/components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Button from "stories/components/Button/Button";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import agent from "api/agent";
import MobileViewUnavailable from "components/Utils/MobileViewUnavailable";
import VarDump from "components/Utils/VarDump";
import { MIN_DESKTOP_WIDTH } from "utils/config";

const QueueConfigItemView = ({ data, index, editClick, restoreClick, TableRowDragStart, TableRowDragOver, TableRowDragEnd }) => {
  const app = useSelector((store) => store.app);
  const { t } = useTranslation();
  return (
    <tr className={[data.isAdded && "added", data.isDeleted && "deleted", data.isModified && "modified"].join(" ")}>
      <td
        className="management-config-arrow"
        draggable="true"
        onDragStart={TableRowDragStart}
        onDragOver={TableRowDragOver}
        onDragEnd={TableRowDragEnd}
        index={index}
      >
        &#8597;
        {0 ? (
          <>
            <br />
            {data.priority}
          </>
        ) : null}
      </td>
      <td>
        {typeof app.constants.repairType?.[data.repairType] === "object"
          ? t(`constants.repair.type.${app.constants.repairType[data.repairType].strVal}`)
          : t("global.notDefinedConstant")}
      </td>
      <td>
        {typeof app.constants.repairSource?.[data.repairSource] === "object"
          ? t(`constants.repair.source.${app.constants.repairSource[data.repairSource].strVal}`)
          : t("global.notDefinedConstant")}
      </td>
      <td>{data.quantity}</td>
      <td className="management-config-actions">
        <div className="m-1">
          {!data.isDeleted ? (
            <Button
              variant="default"
              size="small"
              style={{ width: "60px" }}
              onClick={() => {
                editClick(index);
              }}
            >
              {t("global.edit")}
            </Button>
          ) : (
            <Button
              variant="default"
              size="small"
              style={{ width: "60px" }}
              onClick={() => {
                restoreClick(index);
              }}
            >
              {t("global.restore")}
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

const QueueConfigItemForm = ({ data, index, removeClick, saveClick, cancelClick }) => {
  const noError = "\u00a0";
  const [formData, setFormData] = useState(data);
  const [repairTypeError, setRepairTypeError] = useState("");
  const [repairSourceError, setRepairSourceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const app = useSelector((store) => store.app);
  const { t } = useTranslation();
  const repairTypeOptions = [0, 1, 2, 3].map((item) => {
    return {
      value: item,
      label:
        typeof app.constants.repairType?.[item] === "object"
          ? t(`constants.repair.type.${app.constants.repairType[item].strVal}`)
          : t("global.notDefinedConstant"),
    };
  });
  const repairSourceOptions = [0, 1, 2].map((item) => {
    return {
      value: item,
      label:
        typeof app.constants.repairSource?.[item] === "object"
          ? t(`constants.repair.source.${app.constants.repairSource[item].strVal}`)
          : t("global.notDefinedConstant"),
    };
  });
  const quantityMin = 1;
  const quantityMax = 25;
  const handleRepairTypeChange = (value) => {
    const obj = { ...formData };
    obj.repairType = value;
    setFormData(obj);
  };
  const handleRepairSourceChange = (value) => {
    const obj = { ...formData };
    obj.repairSource = value;
    setFormData(obj);
  };
  const handleQuantityChange = (value) => {
    const obj = { ...formData };
    obj.quantity = value;
    setFormData(obj);
  };
  const handleValidateAndSave = () => {
    let errors = false;
    if (!Object.keys(repairTypeOptions).includes(String(formData.repairType))) {
      errors = true;
      setRepairTypeError("global.selectFromList");
    } else setRepairTypeError("");
    if (!Object.keys(repairSourceOptions).includes(String(formData.repairSource))) {
      errors = true;
      setRepairSourceError("global.selectFromList");
    } else setRepairSourceError("");
    if (formData.quantity < quantityMin || formData.quantity > quantityMax) {
      errors = true;
      setQuantityError("management.qos.config.quantity.valid");
    } else setQuantityError("");
    if (!errors) saveClick(index, formData);
  };
  return (
    <tr className="active">
      <td className="management-config-arrow"></td>
      <td>
        <SelectField
          placeholder={t("global.selectFromList")}
          multi={false}
          value={formData.repairType}
          options={repairTypeOptions}
          onChange={handleRepairTypeChange}
          label={t("management.qos.config.type")}
          error={repairTypeError.length ? t(repairTypeError) + "!" : noError}
        />
      </td>
      <td>
        <SelectField
          placeholder={t("global.selectFromList")}
          multi={false}
          value={formData.repairSource}
          options={repairSourceOptions}
          onChange={handleRepairSourceChange}
          label={t("management.qos.config.source")}
          error={repairSourceError.length ? t(repairSourceError) + "!" : noError}
        />
      </td>
      <td>
        <TextField
          variant="minimalistNumber"
          placeholder={t("global.enterValue")}
          min={quantityMin}
          max={quantityMax}
          value={formData.quantity}
          onChange={handleQuantityChange}
          label={t("management.qos.config.quantity")}
          error={quantityError.length ? t(quantityError, { quantityMin, quantityMax }) + "!" : noError}
        />
      </td>
      <td className="management-config-actions">
        <div className="m-1">
          <Button
            variant="error"
            size="small"
            style={{ width: "60px" }}
            onClick={() => {
              removeClick(index);
            }}
          >
            {t("global.remove")}
          </Button>
        </div>{" "}
        <div className="m-1">
          <Button variant="primary" size="small" style={{ width: "60px" }} onClick={handleValidateAndSave}>
            {t("global.save")}
          </Button>
        </div>
        <div className="m-1">
          <Button
            variant="default"
            size="small"
            style={{ width: "60px" }}
            onClick={() => {
              cancelClick(index);
            }}
          >
            {t("global.cancel")}
          </Button>
        </div>
      </td>
    </tr>
  );
};

export const ManagementQos = () => {
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [queueConfigFetching, setQueueConfigFetching] = useState(true);
  const [queueAccessFetching, setQueueAccessFetching] = useState(true);
  const [queueConfigArray, setQueueConfigArray] = useState([]);
  const [queueAccessArray, setQueueAccessArray] = useState([]);

  useEffect(() => {
    queueConfigGet();
    queueAccessGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let draggedRowObject = null;
  let draggedRowIndex = null;
  let draggedRowMovement = null;
  const TableRowDragStart = (e) => {
    draggedRowObject = e.target.parentNode;
    draggedRowIndex = parseInt(e.target.getAttribute("index"));
    draggedRowMovement = 0;
    draggedRowObject.style.backgroundColor = "#DEE2E6";
  };
  const TableRowDragOver = (e) => {
    e.preventDefault();
    let children = Array.from(e.target.parentNode.parentNode.children);
    if (children.indexOf(e.target.parentNode) === children.indexOf(draggedRowObject)) return;
    if (children.indexOf(e.target.parentNode) > children.indexOf(draggedRowObject)) {
      e.target.parentNode.after(draggedRowObject);
      draggedRowMovement++;
    } else {
      e.target.parentNode.before(draggedRowObject);
      draggedRowMovement--;
    }
  };
  const TableRowDragEnd = (e) => {
    draggedRowObject.style.backgroundColor = "";
    if (draggedRowMovement !== 0) {
      const arr = [...queueConfigArray];
      arr.splice(draggedRowIndex + draggedRowMovement, 0, arr.splice(draggedRowIndex, 1)[0]);
      arr.forEach((item, index) => {
        arr[index].priority = index;
        if (!arr[index].isAdded)
          arr[index].isModified =
            arr[index].priority !== arr[index].originalData.priority ||
            arr[index].type !== arr[index].originalData.type ||
            arr[index].source !== arr[index].originalData.source ||
            arr[index].quantity !== arr[index].originalData.quantity;
      });
      setQueueConfigArray(arr);
    }
  };

  const queueConfigGet = () => {
    setQueueConfigFetching(true);
    agent.Management.queueConfigGet({ id: user.id, token: user.token }).then(
      (response) => {
        const arr = response.data;
        arr.forEach((item, index) => {
          item.priority = index;
          arr[index].originalData = { ...item };
          arr[index].isActive = false;
          arr[index].isSaved = false;
          arr[index].isAdded = false;
          arr[index].isDeleted = false;
          arr[index].isModified = false;
        });
        setQueueConfigArray(arr);
        setQueueConfigFetching(false);
      },
      (error) => {
        setQueueConfigFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueConfigGet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const handleQueueConfigItemAdd = () => {
    const arr = [...queueConfigArray];
    const newId = queueConfigArray.length ? queueConfigArray.reduce((max, item) => ({ id: item.id > max.id ? item.id : max.id })).id + 1 : 1;
    const newPriority = queueConfigArray.length
      ? queueConfigArray.reduce((max, item) => ({ priority: item.priority > max.priority ? item.priority : max.priority })).priority + 1
      : 0;
    arr.push({
      id: newId,
      priority: newPriority,
      repairType: -1,
      repairSource: -1,
      quantity: 0,
      originalData: { id: newId, priority: newPriority, repairType: -1, repairSource: -1, quantity: 0 },
      isActive: true,
      isSaved: false,
      isAdded: true,
      isDeleted: false,
      isModified: false,
    });
    setQueueConfigArray(arr);
  };
  const handleQueueConfigItemEdit = (index) => {
    const arr = [...queueConfigArray];
    arr[index].isActive = true;
    setQueueConfigArray(arr);
  };
  const handleQueueConfigItemSave = (index, formData) => {
    const arr = [...queueConfigArray];
    arr[index] = { ...formData };
    arr[index].isActive = false;
    arr[index].isSaved = true;
    arr[index].isModified =
      !arr[index].isAdded &&
      (arr[index].priority !== arr[index].originalData.priority ||
        arr[index].repairType !== arr[index].originalData.repairType ||
        arr[index].repairSource !== arr[index].originalData.repairSource ||
        arr[index].quantity !== arr[index].originalData.quantity)
        ? true
        : false;
    setQueueConfigArray(arr);
  };
  const handleQueueConfigItemCancel = (index) => {
    const arr = [...queueConfigArray];
    if (arr[index].isAdded && !arr[index].isSaved) arr.splice(index, 1);
    else arr[index].isActive = false;
    setQueueConfigArray(arr);
  };
  const handleQueueConfigItemRemove = (index) => {
    const arr = [...queueConfigArray];
    if (!arr[index].isAdded) {
      arr[index].isActive = false;
      arr[index].isDeleted = true;
    } else arr.splice(index, 1);
    setQueueConfigArray(arr);
  };
  const handleQueueConfigItemRestore = (index) => {
    const arr = [...queueConfigArray];
    arr[index].isDeleted = false;
    setQueueConfigArray(arr);
  };

  const queueConfigSet = () => {
    setQueueConfigFetching(true);
    const arr = queueConfigArray
      .filter((item) => item.isAdded || item.isDeleted || item.isModified)
      .map((item) => {
        return {
          id: item.id,
          priority: item.priority,
          repairType: item.repairType,
          repairSource: item.repairSource,
          quantity: item.quantity,
          action: item.isAdded ? "i" : item.isDeleted ? "d" : item.isModified ? "u" : null,
        };
      });
    agent.Management.queueConfigSet({ id: user.id, token: user.token, data: arr }).then(
      (response) => {
        if (response.result === 301) queueConfigGet();
        else {
          setQueueConfigFetching(false);
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueConfigSet.failed" } });
        }
      },
      (error) => {
        setQueueConfigFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueConfigSet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const queueAccessGet = () => {
    setQueueAccessFetching(true);
    agent.Management.queueAccessGet({ id: user.id, token: user.token }).then(
      (response) => {
        const arr = response.data;
        arr.forEach((item, index) => {
          arr[index].hasAccessOriginal = arr[index].hasAccess;
        });
        setQueueAccessArray(arr);
        setQueueAccessFetching(false);
      },
      (error) => {
        setQueueAccessFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueAccessGet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const handleAccessClick = (value, name) => {
    const arr = [...queueAccessArray];
    arr[name].hasAccess = value ? 1 : 0;
    setQueueAccessArray(arr);
  };

  const queueAccessSet = () => {
    setQueueAccessFetching(true);
    const arr = queueAccessArray
      .filter((item) => item.hasAccessOriginal !== item.hasAccess)
      .map((item) => {
        return { uid: item.userId, action: item.hasAccess ? "grant" : "revoke" };
      });
    agent.Management.queueAccessSet({ id: user.id, token: user.token, data: arr }).then(
      (response) => {
        if (response.result === 301) queueAccessGet();
        else {
          setQueueAccessFetching(false);
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueAccessSet.failed" } });
        }
      },
      (error) => {
        setQueueAccessFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "queueAccessSet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  if (!desktop) return <MobileViewUnavailable />;
  return user.role !== "admin" ? (
    <h1>Access denied!</h1>
  ) : (
    <>
      <div className="mb-5">
        <h5 className="heading--5">{t("management.qos.config.title")}:</h5>
        {queueConfigFetching ? (
          <div className="mt-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mt-2 p-3">
              <div className="float-end mb-5">
                <Button variant="primary" onClick={handleQueueConfigItemAdd}>
                  {t("management.qos.config.add")}
                </Button>
              </div>
              <table className="management-config">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("management.qos.config.type")}</th>
                    <th>{t("management.qos.config.source")}</th>
                    <th>{t("management.qos.config.quantity")}</th>
                    <th className="management-config-actions">{t("management.qos.config.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {queueConfigArray.map((item, index) =>
                    !item.isActive ? (
                      <QueueConfigItemView
                        key={item.id}
                        data={item}
                        index={index}
                        editClick={handleQueueConfigItemEdit}
                        restoreClick={handleQueueConfigItemRestore}
                        TableRowDragStart={TableRowDragStart}
                        TableRowDragOver={TableRowDragOver}
                        TableRowDragEnd={TableRowDragEnd}
                      />
                    ) : (
                      <QueueConfigItemForm
                        key={item.id}
                        data={item}
                        index={index}
                        removeClick={handleQueueConfigItemRemove}
                        saveClick={handleQueueConfigItemSave}
                        cancelClick={handleQueueConfigItemCancel}
                      />
                    ),
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-3 p-3">
              <Button
                variant="primary"
                onClick={queueConfigSet}
                disabled={
                  !queueConfigArray.filter((item) => item.isActive).length &&
                  (queueConfigArray.filter((item) => item.isAdded).length ||
                    queueConfigArray.filter((item) => item.isDeleted).length ||
                    queueConfigArray.filter((item) => item.isModified).length)
                    ? false
                    : true
                }
              >
                {t("global.savechanges")}
              </Button>
              {0 ? (
                <div className="p-3">
                  <div>isActive: {queueConfigArray.filter((item) => item.isActive).length}</div>
                  <div>isSaved: {queueConfigArray.filter((item) => item.isSaved).length}</div>
                  <div>isAdded: {queueConfigArray.filter((item) => item.isAdded).length}</div>
                  <div>isDeleted: {queueConfigArray.filter((item) => item.isDeleted).length}</div>
                  <div>isModified: {queueConfigArray.filter((item) => item.isModified).length}</div>
                </div>
              ) : null}
              {0 ? <VarDump name="queueConfigArray" data={queueConfigArray} /> : null}
              {0 ? <VarDump name="queueAccessArray" data={queueAccessArray} /> : null}
            </div>
          </>
        )}
      </div>
      <div className="mb-5">
        <h5 className="heading--5">{t("management.qos.access")}:</h5>
        {queueAccessFetching ? (
          <div className="mt-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mt-2 p-3">
              {queueAccessArray.map((item, index) => (
                <div className="mb-2" key={index} style={item.hasAccess !== item.hasAccessOriginal ? { color: "blue" } : null}>
                  <Checkbox name={index} label={item.personName} checked={item.hasAccess} onChange={handleAccessClick} />
                </div>
              ))}
            </div>
            <div className="p-3">
              <Button
                variant="primary"
                onClick={queueAccessSet}
                disabled={queueAccessArray.filter((item) => item.hasAccessOriginal !== item.hasAccess).length ? false : true}
              >
                {t("global.savechanges")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
