import React from "react";
import { useTranslation } from "react-i18next";
import LangSwitchGlobe from "stories/components/LangSwitchGlobe/LangSwitchGlobe";
import Logo from "images/logo.svg";
import Info from "images/info.svg";
import { Link } from "react-router-dom";

const TopPanel = () => {
  const { t } = useTranslation();

  return (
    <div className="cookie-policy-top-panel">
      <Link to="/">
        <img src={Logo} alt="Logo" />
      </Link>
      <div className="cookie-policy-top-panel__help">
        <a href="/" className="cookie-policy-top-panel__link">
          <img src={Info} alt="info_icon" />
          <span className="cookie-policy-top-panel__link">{t("global.help")}</span>
        </a>
        <LangSwitchGlobe variant="icons" />
      </div>
    </div>
  );
};

export default TopPanel;
