import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import SelectField from "stories/components/SelectField/SelectField";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const DeleteTabModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    repair: { tabs },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});
  const [deleteTabActionLoading, setDeleteTabActionLoading] = useState(false);

  const deleteTabSuccess = (response, filterId) => {
    if (response.result === 301) {
      dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: "all" });
      dispatch({ type: "REPAIR_CLEAR_ALL_APPLIEDFILTERS" });
      dispatch({ type: "REPAIR_SET_TABS", tabs: [...tabs.filter((tab) => tab.filterId !== filterId)] });
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deleteTabError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
  };

  const deleteTab = (values) => {
    setDeleteTabActionLoading(true);

    return agent.Repairs.manageTabs({ id: user.id, token: user.token, action: "delete", filterId: values.filterId })
      .then((response) => deleteTabSuccess(response, values.filterId), deleteTabError)
      .finally(() => setDeleteTabActionLoading(false));
  };

  const deleteTabFormSchema = Yup.object({
    action: Yup.string().required(t("errors.99")),
    filterName: Yup.string().min(3, t("global.tooShort")).max(50, t("global.tooLong")).required(t("errors.99")),
    filterId: Yup.string().required(t("errors.99")),
  });

  if (!active) return;

  return (
    <Formik initialValues={{ filterId: "" }} validationSchema={deleteTabFormSchema}>
      {({ values, setFieldValue, handleBlur, errors, touched }) => (
        <Modal
          title={t("global.deleteTab")}
          footerActions={
            <Stack gap="var(--modal-buttons-gap)">
              <Button size={mobile ? "small" : "default"} disabled={deleteTabActionLoading} onClick={() => setActive(false)}>
                {t("global.cancel")}
              </Button>
              <Button
                size={mobile ? "small" : "default"}
                disabled={!values.filterId}
                loading={deleteTabActionLoading}
                variant="error"
                onClick={() => deleteTab(values)}
              >
                {t("global.delete")}
              </Button>
            </Stack>
          }
          onClose={() => setActive(false)}
        >
          <Form>
            <SelectField
              id="filterId"
              name="filterId"
              label={t("global.chooseTab")}
              placeholder={t("global.chooseFromList")}
              onBlur={handleBlur}
              error={touched.filterId && errors.filterId && !values.filterId ? errors.filterId : formErrors.filterId}
              value={values.filterId}
              isSearchable={false}
              onChange={(value) => {
                setFieldValue("filterId", value);
                setFieldValue("filterName", tabs.find((tab) => tab.filterId === value).filterName);
              }}
              options={tabs.map((tab) => {
                return { value: tab.filterId, label: tab.filterName };
              })}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default DeleteTabModal;
