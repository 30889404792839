import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isAdmin, isReception } from "utils/global-functions";
import { addressOrder } from "../config";
import { getContentForDeliveryAddress, getContentForReturnAddress, getPackageInfo } from "../utils";
import DeliveryData from "./DeliveryData";

const Content = ({ editReturnAddressModalActive, setEditReturnAddressModalActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { constants },
  } = useSelector((store) => store);
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });

  const { deliveryPackages, deliveryType, ...restDeliveryInfo } = Object.fromEntries(Object.entries(repairDetails).filter(([key]) => key.includes("delivery")));
  const { returnType, ...restReturnInfo } = Object.fromEntries(Object.entries(repairDetails).filter(([key]) => key.includes("return")));

  return (
    <>
      <DeliveryData
        details={[
          {
            title: t("myRepairs.deliveryInfo"),
            copyButton: false,
            content: [
              { term: t("myRepairs.deliveryType"), description: t(`constants.delivery.type.${constants?.deliveryType?.[deliveryType]?.strVal}`) },
              ...getPackageInfo(deliveryPackages, constants, t),
            ],
          },
        ]}
      />
      <DeliveryData
        details={[
          {
            title: t("myRepairs.deliveryAddress"),
            content: getContentForDeliveryAddress(deliveryType, addressOrder, restDeliveryInfo, translatedCountryNames, constants, t),
          },
        ]}
      />
      <DeliveryData
        details={[
          {
            title: t("myRepairs.returnAddress"),
            content: getContentForReturnAddress(returnType, addressOrder, restReturnInfo, translatedCountryNames, constants, t),
          },
        ]}
        editButton={(isAdmin(user) || isReception(user)) && { active: editReturnAddressModalActive, onChange: setEditReturnAddressModalActive }}
      />
    </>
  );
};

export default Content;
