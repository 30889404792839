import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Badge = ({ backgroundColor = "green", content, uppercase = true }) => (
  <span className={`custom-badge custom-badge--${backgroundColor}${uppercase ? " custom-badge--uppercase" : ""}`}>{content}</span>
);

Badge.propTypes = {
  content: PropTypes.any,
  backgroundColor: PropTypes.oneOf(["green", "red", "yellow", "null", "transparent"]),
  uppercase: PropTypes.bool,
};

export default Badge;
