import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import "moment/locale/pl";
import PropTypes from "prop-types";
import { MAX_MOBILE_WIDTH } from "utils/config";
import "../commonStyles.css";
import { getDatePicker } from "./utils";
import "./styles.css";

const DatePicker = ({
  id,
  variant = "default",
  label,
  placeholder,
  error,
  date,
  startDate,
  startDateId,
  startDatePlaceholderText,
  endDate,
  endDateId,
  endDatePlaceholderText,
  onChange,
  helpText,
  disabled = false,
  numberOfMonths = 1,
  firstDayOfWeek = 1,
  language = "en-gb",
  isOutsideRange = () => false,
  openDirection = "up",
  className,
}) => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  moment.locale(language);

  return (
    <div className={`input ${className || ""}`}>
      {label && <label className="input__label">{label}</label>}
      {getDatePicker({
        id,
        startDateId,
        endDateId,
        startDate,
        startDatePlaceholderText,
        endDate,
        endDatePlaceholderText,
        variant,
        placeholder,
        date,
        onChange,
        disabled,
        numberOfMonths,
        firstDayOfWeek,
        isOutsideRange,
        openDirection,
        withPortal: mobile,
      })}
      {error && <span className="input__error-message">{error}</span>}
      {helpText && <span className="input__help-text">{helpText}</span>}
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(["single", "range"]),
  openDirection: PropTypes.oneOf(["up", "down"]),
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  helpText: PropTypes.string,
  language: PropTypes.string,
  disabled: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  firstDayOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  date: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
};

export default DatePicker;
