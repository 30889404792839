import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import Protected from "./Protected";
import Home from "./Home/Main/Home";
import { Account } from "./Account/Account";
import { Actions } from "./Actions/Actions";
import { ProducerReport } from "./ProducerReport/ProducerReport";
import AddReport from "./AddReport/AddReport";
import { Management } from "./Management/Management";
import RepairEvents from "./RepairEvents/RepairEvents";
import ExternalSerialNumber from "./ExternalSerialNumber/ExternalSerialNumber";
import Help from "./Help/Help";
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import E404 from "./E404/E404";
import RepairDetails from "./Home/Details/Details";
import ScrollToTop from "./ScrollToTop";
import SerialNumber from "./SerialNumber/SerialNumber";

const Routes = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route
        exact
        path="/"
        element={
          <Protected>
            <Home />
          </Protected>
        }
      />
      <Route path="/serial-number/:serialNumber" element={<SerialNumber />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route
        path="/other-repairs"
        element={
          <Protected>
            <Home />
          </Protected>
        }
      />
      <Route
        path="/:repairId/:currentTab"
        element={
          <Protected>
            <RepairDetails />
          </Protected>
        }
      >
        <Route
          index
          element={
            <Protected>
              <RepairDetails />
            </Protected>
          }
        />
        <Route
          path=":typeTab"
          element={
            <Protected>
              <RepairDetails />
            </Protected>
          }
        />
      </Route>
      <Route
        exact
        path="/account"
        element={
          <Protected>
            <Account />
          </Protected>
        }
      />
      <Route
        exact
        path="/addreport"
        element={
          <Protected>
            <AddReport />
          </Protected>
        }
      />
      <Route
        exact
        path="/externalserial"
        element={
          <Protected>
            <ExternalSerialNumber />
          </Protected>
        }
      />
      <Route
        exact
        path="/management"
        element={
          <Protected>
            <Management />
          </Protected>
        }
      />
      <Route
        exact
        path="/actions"
        element={
          <Protected>
            <Actions />
          </Protected>
        }
      />
      <Route
        exact
        path="/producerreport"
        element={
          <Protected>
            <ProducerReport />
          </Protected>
        }
      />
      <Route
        exact
        path="/changelog"
        element={
          <Protected>
            <RepairEvents />
          </Protected>
        }
      />
      <Route
        exact
        path="/help"
        element={
          <Protected>
            <Help />
          </Protected>
        }
      />
      <Route
        path="*"
        element={
          <Protected>
            <E404 />
          </Protected>
        }
      />
    </Switch>
  </>
);

export default Routes;
