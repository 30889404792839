import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { sortDescendingOrder } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import Accordion from "stories/components/Accordion/Accordion";
import { repairsTableHeadings } from "../config";
import RepairsListBodyItem from "./RepairsListBodyItem";

const RepairsList = () => {
  const {
    repair: {
      repairDetails: { repairs },
    },
  } = useSelector((store) => store);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { repairId } = useParams();

  const [firstHeading, secondHeading, ...restHeadings] = repairsTableHeadings;

  return (
    <Accordion
      content={repairs
        .sort((a, b) => sortDescendingOrder(a.repairId, b.repairId))
        .map((repair) => {
          return {
            selectedAccordion: repair.repairId === parseInt(repairId),
            title: (
              <div className="other-repairs-list__heading">
                <div className="other-repairs-list__heading-item">
                  <span className="paragraph--small text--subdued">{t(`global.${firstHeading}`)}:</span>
                  <span className="paragraph--small">{repair[firstHeading]}</span>
                </div>
                <div className="other-repairs-list__heading-item">
                  <span className="paragraph--small text--subdued">{t(`global.${secondHeading}`)}:</span>
                  <span className="paragraph--small">{repair[secondHeading]}</span>
                </div>
              </div>
            ),
            body: (
              <div className="other-repairs-list__body">
                {restHeadings.map((heading) => (
                  <RepairsListBodyItem key={heading} heading={heading} repair={repair} />
                ))}
                {repair.repairId !== parseInt(repairId) && (
                  <div className="other-repairs-list__button">
                    <Button onClick={() => navigate(`/${repair.repairId}/info`)} size="small">
                      {t("global.openRepair")}
                    </Button>
                  </div>
                )}
              </div>
            ),
          };
        })}
    />
  );
};

export default RepairsList;
