/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Radio from "stories/components/Radio/Radio";
import Button from "stories/components/Button/Button";
import DhlLogoIcon from "images/dhl.svg";
import AddressForm from "components/AddressForm/AddressForm";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { RETURN_TYPE_ELMARK_COURIER, RETURN_TYPE_PERSONAL } from "utils/config";
import agent from "api/agent";

const ChangeUserReturnAddressForm = ({ values, setFieldValue, formErrors, errors, touched, handleBlur }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [selectedUser, setSelectedUser] = useState(user);
  const dispatch = useDispatch();

  const getUserInfoSuccess = (response) => {
    setSelectedUser(response.data.at(0));
  };

  const getUserInfoError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getUserInfo = () => {
    agent.Users.getUser({ id: user.id, token: user.token, userId: values.selectedUserId }).then(getUserInfoSuccess, getUserInfoError);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const setReturnAddresFromInvoice = () => {
    const returnKeys = Object.entries(values)
      .filter((value) => value.at(0).includes("returnAddress"))
      .map((value) => value.at(0));

    returnKeys.forEach((returnKey) => {
      const userKey = returnKey.replace("returnAddress", "");
      const [userKeyFirstLetter, ...rest] = userKey;
      if (userKeyFirstLetter[0].toLowerCase() + rest.join("")) values[`${returnKey}`] = selectedUser[userKeyFirstLetter[0].toLowerCase() + rest.join("")];
    });

    values.returnAddressStreet = "";
    values.returnAddressBuilding = values.returnAddressBuilding || "";
    values.returnAddressPlace = values.returnAddressPlace || "";
    values.returnAddressCompanyName = values.returnAddressCompanyName || "";
  };

  return (
    <div>
      <h6 className="heading--6 info-modal__heading">{t("global.returnAddress")}</h6>
      <div className="change-user-return-address__radio-container">
        <Radio
          checked={values.returnType === RETURN_TYPE_PERSONAL}
          onChange={() => setFieldValue("returnType", RETURN_TYPE_PERSONAL)}
          label={t("constants.return.type.personal")}
          name="returnType"
        />
        <div className="change-user-return-address__radio-wrapper">
          <Radio
            checked={values.returnType === RETURN_TYPE_ELMARK_COURIER}
            onChange={() => setFieldValue("returnType", RETURN_TYPE_ELMARK_COURIER)}
            label={t("constants.return.type.dhlCourier")}
            name="returnType"
          />
          <img className="change-user-return-address__icon-wrapper no-select" src={DhlLogoIcon} alt="dhl_logo" />
        </div>
      </div>
      {values.returnType === RETURN_TYPE_ELMARK_COURIER && (
        <div className="repair-info-form">
          <AddressForm
            type="return"
            formErrors={formErrors}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
          <Button
            size="small"
            onClick={() => {
              setReturnAddresFromInvoice();
              setFieldValue("deliveryFromInvoice", true);
            }}
          >
            {t("addReport.fillInvoiceData")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChangeUserReturnAddressForm;
