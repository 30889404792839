import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isAdmin, isReception } from "utils/global-functions";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import Gallery from "../Gallery";
import EmptyPage from "../EmptyPage";

const Content = ({ setPhotosModalActive, photos, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosPreviewModalActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);

  return (
    <div className="photos-box">
      <Stack justifyContent="space-between" alignItems="center" gap="1rem">
        <h6 className="heading--6 heading--large-spacing">{t("myRepairs.photos")}</h6>
        {(isReception(user) || isAdmin(user) || repairDetails.technicianId === user.id) && (
          <Button variant="primary" onClick={() => setPhotosModalActive(true)}>
            {t("myRepairs.addPhoto")}
          </Button>
        )}
      </Stack>
      {!!photos.length ? (
        <Gallery
          setPhotosModalActive={setPhotosModalActive}
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          photos={photos}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};

export default Content;
