import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Film from "images/Film.svg";
import { isVideo } from "./utils";
import { isAdmin, isClient, isEmployee } from "utils/global-functions";
import { useSelector } from "react-redux";

const GalleryCard = ({ photo, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosModalActive, setPhotosPreviewModalActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <div className={`details-gallery-card ${photo.visibilityFlag ? "" : "details-gallery-card--hidden"}`}>
      <div className="details-gallery-card__img-wrapper">
        <button
          onClick={() => {
            setSelectedPhotoId(photo.id);
            setPhotosPreviewModalActive(true);
          }}
          className="unset-all"
        >
          {photo.commentText ? (
            <Tooltip content={`<p class="text--preline no-margin text--left">${photo.commentText}</p>`} direction="bottom">
              <img
                className={`no-select ${isVideo(photo) ? "details-gallery__img" : ""}`}
                src={isVideo(photo) ? Film : `data:image/png;base64,${photo.content}`}
                alt={photo.fileName}
              />
            </Tooltip>
          ) : (
            <img
              className={`no-select ${isVideo(photo) ? "details-gallery__img" : ""}`}
              src={isVideo(photo) ? Film : `data:image/png;base64,${photo.content}`}
              alt={photo.fileName}
            />
          )}
        </button>
      </div>
      <div className="details-gallery-card__info-wrapper">
        <div className="details-gallery-card__info-container">
          <div className="details-gallery-card__info">
            <span className="paragraph--small text--subdued text--nowrap">{t("global.name")}:</span>
            <span className="paragraph--small text--nowrap text--elipsis">{photo.fileName}</span>
          </div>
          <div className="details-gallery-card__info">
            <span className="paragraph--small text--subdued text--nowrap">{t("global.type")}:</span>
            <span className="paragraph--small text--nowrap">{photo.fileType}</span>
          </div>
          {(!isClient(user) || isEmployee(user)) && (
            <>
              <div className="details-gallery-card__info">
                <span className="paragraph--small text--subdued text--nowrap">{t("global.author")}:</span>
                <span className="paragraph--small text--right">{photo.authorName || "-"}</span>
              </div>
              <div className="details-gallery-card__info">
                <span className="paragraph--small text--subdued text--nowrap">{t("global.visible")}:</span>
                <span className="paragraph--smalltext--nowrap">{t(`global.${!!photo.visibilityFlag}`)}</span>
              </div>
            </>
          )}
          <div className="details-gallery-card__info">
            <span className="paragraph--small text--subdued text--nowrap">{t("global.date")}:</span>
            <Stack flexWrap="wrap" gap="0.25rem" justifyContent="flex-end">
              <span className="paragraph--small text--nowrap">{photo.createdAt.split(" ").at(0)}</span>
              <span className="paragraph--small text--nowrap">{photo.createdAt.split(" ").at(1)}</span>
            </Stack>
          </div>
        </div>
        <div className="details-gallery-card__info">
          {(isAdmin(user) || photo.authorId === user.id) && (
            <div className="details-gallery__buttons-wrapper">
              <button
                onClick={() => {
                  setSelectedPhotoId(photo.id);
                  setPhotosModalActive(true);
                }}
                className="unset-all custom-link--black"
              >
                {t("global.edit")}
              </button>
              <button
                onClick={() => {
                  setSelectedPhotoId(photo.id);
                  setDeletePhotoModalActive(true);
                }}
                className="unset-all custom-link"
              >
                {t("global.delete")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryCard;
