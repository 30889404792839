/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import PageSpinner from "components/PageSpinner/PageSpinner";
import { filterErrorsFromCommonApiErrors, isEmployee } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";

const Configuration = ({ repairDetails }) => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState({});
  const { repairId } = useParams();

  const getConfigurationSuccess = (response) => {
    setConfiguration(response.data[0]);
  };

  const getConfigurationError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getConfiguration = () => {
    setLoading(true);
    agent.Repairs.elmaticConfiguration({
      id: user.id,
      token: user.token,
      serialNumber: repairDetails.serialNumber,
    })
      .then(getConfigurationSuccess, getConfigurationError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isEmployee(user)) navigate(`/${repairId}/info`);
    else getConfiguration();
  }, []);

  if (loading) return <PageSpinner subPage />;

  return mobile ? (
    <Mobile configuration={configuration} serialNumber={repairDetails.serialNumber} />
  ) : (
    <Desktop configuration={configuration} serialNumber={repairDetails.serialNumber} />
  );
};

export default Configuration;
