import React from "react";
import LoginCard from "./LoginCard";
import ProsCard from "./ProsCard";
import TopPanel from "./TopPanel";
import "./styles.css";

const Mobile = () => (
  <div className="mobile_login_container">
    <TopPanel />
    <LoginCard />
    <ProsCard />
  </div>
);

export default Mobile;
