import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Modal from "stories/components/Modal/Modal";
import Button from "stories/components/Button/Button";

export const AddReportAuthorErrorModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  if (!show) return;

  return (
    <Modal
      title={t("global.error")}
      footerActions={
        <div className="modal-buttons-container">
          <Button size={mobile ? "small" : "default"} variant="default" onClick={() => setShow(false)}>
            {t("global.no")}
          </Button>
          <Button
            size={mobile ? "small" : "default"}
            variant="error"
            onClick={() => {
              dispatch({ type: "REGISTRATION_CANCEL" });
              setShow(false);
            }}
          >
            {t("global.yes")}
          </Button>
        </div>
      }
      onClose={() => setShow(false)}
    >
      <h6 className="heading--6">{t("addReport.error.author1")}</h6>
      <p className="paragraph--small">{t("addReport.error.author2")}</p>
      <p className="paragraph--small text--red">{t("addReport.error.author3")}</p>
    </Modal>
  );
};
