/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import agent from "api/agent";
import { CONVERSATION, CONVERSATION_INTERNAL, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors, isEmployee } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import ConfirmDeleteMessageModal from "./ConfirmDeleteMessageModal";
import ConfirmCloseMessageModal from "./ConfirmCloseMessageModal";
import "./commonStyles.css";

const Conversation = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const { t } = useTranslation();
  const { repairId, typeTab } = useParams();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [conversationList, setConversationList] = useState([]);
  const [conversationListLoading, setConversationListLoading] = useState(true);
  const [converstionGroupType, setConversationGroupType] = useState(typeTab === "internal" && isEmployee(user) ? CONVERSATION_INTERNAL : CONVERSATION);
  const [confirmDeleteMessageModalActive, setConfirmDeleteMessageModalActive] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [editMessageActive, setEditMessageActive] = useState(false);
  const [responseMessageActive, setResponseMessageActive] = useState(false);
  const [refreshConversation, setRefreshConversation] = useState(false);
  const [confirmCloseMessageModalActive, setConfirmCloseMessageModalActive] = useState(false);
  const dispatch = useDispatch();

  const getConversationSuccess = (response) => {
    if (response.data.length === 0) return setConversationList([]);

    setConversationList(response.data.reverse());
  };

  const getConversationError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getConversation = () => {
    agent.Conversations.conversationsList({
      id: user.id,
      token: user.token,
      conversationTypes: converstionGroupType,
      repairId: repairId,
      sortOrder: "Q&A",
      pageNumber: 1,
      pageSize: 999,
    })
      .then(getConversationSuccess, getConversationError)
      .finally(() => setConversationListLoading(false));
  };

  useEffect(() => {
    getConversation();
    const conversationInterval = setInterval(getConversation, 60000);

    return () => clearInterval(conversationInterval);
  }, [converstionGroupType, refreshConversation]);

  return (
    <>
      {mobile ? (
        <Mobile
          conversationList={conversationList}
          converstionGroupType={converstionGroupType}
          setConversationGroupType={setConversationGroupType}
          selectedConversationId={selectedConversationId}
          setSelectedConversationId={setSelectedConversationId}
          setConfirmDeleteMessageModalActive={setConfirmDeleteMessageModalActive}
          editMessageActive={editMessageActive}
          setEditMessageActive={setEditMessageActive}
          responseMessageActive={responseMessageActive}
          setResponseMessageActive={setResponseMessageActive}
          conversationListLoading={conversationListLoading}
          refreshConversation={refreshConversation}
          setRefreshConversation={setRefreshConversation}
          setConfirmCloseMessageModalActive={setConfirmCloseMessageModalActive}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setConversationList={setConversationList}
        />
      ) : (
        <Desktop
          conversationList={conversationList}
          converstionGroupType={converstionGroupType}
          setConversationGroupType={setConversationGroupType}
          selectedConversationId={selectedConversationId}
          setSelectedConversationId={setSelectedConversationId}
          setConfirmDeleteMessageModalActive={setConfirmDeleteMessageModalActive}
          editMessageActive={editMessageActive}
          setEditMessageActive={setEditMessageActive}
          responseMessageActive={responseMessageActive}
          setResponseMessageActive={setResponseMessageActive}
          conversationListLoading={conversationListLoading}
          refreshConversation={refreshConversation}
          setRefreshConversation={setRefreshConversation}
          setConfirmCloseMessageModalActive={setConfirmCloseMessageModalActive}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setConversationList={setConversationList}
        />
      )}
      <ConfirmDeleteMessageModal
        selectedConversationId={selectedConversationId}
        setSelectedConversationId={setSelectedConversationId}
        active={confirmDeleteMessageModalActive}
        setActive={setConfirmDeleteMessageModalActive}
        conversationList={conversationList}
        refreshConversation={refreshConversation}
        setRefreshConversation={setRefreshConversation}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <ConfirmCloseMessageModal
        active={confirmCloseMessageModalActive}
        setActive={setConfirmCloseMessageModalActive}
        selectedConversationId={selectedConversationId}
        refreshConversation={refreshConversation}
        setSelectedConversationId={setSelectedConversationId}
        setRefreshConversation={setRefreshConversation}
        conversationList={conversationList}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
    </>
  );
};

export default Conversation;
