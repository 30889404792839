import React from "react";
import GalleryCard from "./GalleryCard";

const Gallery = ({ photos, setDeletePhotoModalActive, setSelectedPhotoId, setPhotosModalActive, setPhotosPreviewModalActive }) => {
  return (
    <div className="details-gallery">
      {photos.map((photo) => (
        <GalleryCard
          key={photo.id}
          photo={photo}
          setPhotosModalActive={setPhotosModalActive}
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      ))}
    </div>
  );
};

export default Gallery;
