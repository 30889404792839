/*
Author: jacek.bonecki@elmark.com.pl
*/

import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Page from "stories/components/Page/Page";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import ReportAttention from "components/Utils/ReportAttention";
import e404 from "images/E404.png";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import { useState } from "react";

const E404 = () => {
  const { t } = useTranslation();
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const navigate = useNavigate();
  const [showReportAttention, setShowReportAttention] = useState(false);

  return (
    <Page>
      <div className="page-content">
        <div className={desktop ? "mt-5" : "mt-2"}>
          <Stack flexDirection={desktop ? "row" : "column"} alignItems="center" justifyContent="center" gap="2rem">
            <img src={e404} alt="E404" className="no-select" />
            <div className="text-center">
              <div className="heading--4 pb-2">{t("e404.title")}</div>
              <div>{t("e404.option1")}</div>
              <div className="p-4">
                <Stack justifyContent="center" alignItems="center" gap="var(--modal-buttons-gap)">
                  <Button
                    variant="default"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {t("e404.button1")}
                  </Button>
                  <Button
                    variant="default"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    {t("e404.button2")}
                  </Button>
                </Stack>
              </div>
              <div>{t("e404.option2")}</div>
              <div className="p-4">
                <Button
                  size="small"
                  onClick={() => {
                    setShowReportAttention(true);
                  }}
                >
                  {t("e404.button3")}
                </Button>
              </div>
            </div>
          </Stack>
        </div>
      </div>
      {showReportAttention && (
        <ReportAttention
          onClose={() => {
            setShowReportAttention(false);
          }}
        />
      )}
    </Page>
  );
};

export default E404;
