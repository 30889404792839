import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "stories/components/Button/Button";
import { isClient } from "utils/global-functions";
import ProductList from "./ProductList";

const DisplayMobile = ({ productList }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="add-report-success">
      <h3 className="heading--3 text--center">{t("addReport.thankYouForReport")}</h3>
      <p className={`paragraph--small add-report-success__message ${isClient(user) ? "add-report-success__description" : ""}`}>
        {isClient(user) ? t("addReport.successClientMessage") : t("addReport.successServiceMessage")}
      </p>
      <ProductList productList={productList} />
      <div className="add-report-success__buttons-wrapper">
        <Button className="add-report-success__button" onClick={() => navigate("/")} size="small">
          {t("addReport.mainPage")}
        </Button>
        <Button className="add-report-success__button" onClick={() => dispatch({ type: "REGISTRATION_CANCEL" })} size="small" variant="primary">
          {t("addReport.addNewReport")}
        </Button>
      </div>
    </div>
  );
};

export default DisplayMobile;
