import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const ConfirmDeleteAttachmetModal = ({
  active,
  setActive,
  selectedAttachmentId,
  setSelectedAttachmentId,
  refreshAttachments,
  setRefreshAttachments,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [loadingDeleteAttachment, setLoadingDeleteAttachment] = useState(false);

  const deleteAttachmentSuccess = (response) => {
    if (response.result === 301) {
      setSelectedAttachmentId(null);
      setActive(false);
      setRefreshAttachments(!refreshAttachments);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deleteAttachmentError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const deleteAttachment = () => {
    setLoadingDeleteAttachment(true);
    agent.Repairs.handleRepairAttachments({ id: user.id, token: user.token, action: "delete", attachmentId: selectedAttachmentId })
      .then(deleteAttachmentSuccess, deleteAttachmentError)
      .finally(() => setLoadingDeleteAttachment(false));
  };

  if (!active) return;

  return (
    <Modal
      onClose={() => setActive(false)}
      title={t("global.deleteAttachment")}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button size={mobile ? "small" : "default"} disabled={loadingDeleteAttachment} onClick={() => setActive(false)}>
            {t("global.no")}
          </Button>
          <Button size={mobile ? "small" : "default"} loading={loadingDeleteAttachment} variant="primary" onClick={() => deleteAttachment()}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <p className="paragraph--small">{t("myRepairs.deleteAttachmentInfo")}</p>
    </Modal>
  );
};

export default ConfirmDeleteAttachmetModal;
