export const setPanelStyle = (panel, options = { active: false }) => {
  if (options.active) {
    panel.style.maxHeight = null;
    panel.style.marginTop = null;
    panel.style.marginBottom = null;
    return;
  }

  panel.style.maxHeight = "fit-content";
  panel.style.marginTop = "1.2rem";
  panel.style.marginBottom = "1.2rem";
  return;
};

export const handleAccordionToggle = (acc) => {
  acc.classList.toggle("active");
  acc.classList.toggle("accordion--border");
  acc.children["accordion_chevron"].classList.toggle("accordion-chevron__active");
  const panel = acc.nextElementSibling;
  setPanelStyle(panel, { active: !!panel.style.maxHeight });
};

export const getActiveAccordionStyle = (active) => {
  if (!active) return {};

  return { maxHeight: "fit-content", marginTop: "1.2rem", marginBottom: "1.2rem" };
};
