import React from "react";
import { useTranslation } from "react-i18next";
import { RETURN_TYPE_ELMARK_COURIER } from "utils/config";
import AddressDetails from "./AddressDetails";

const ReturnInfo = ({ summaryDetails }) => {
  const { t } = useTranslation();

  const returnTypes = { 1: t("constants.return.type.personal"), 2: t("constants.return.type.dhlCourier"), 3: t("constants.return.type.toBeAgreed") };

  return (
    <div>
      <span className="paragraph--small text--subdued">{returnTypes[summaryDetails.returnType]}</span>
      {summaryDetails.returnType === RETURN_TYPE_ELMARK_COURIER && <AddressDetails summaryDetails={summaryDetails} type="returnAddress" />}
    </div>
  );
};

export default ReturnInfo;
