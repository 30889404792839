import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Dropzone from "stories/components/Dropzone/Dropzone.js";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField } from "utils/global-functions";

const AttachmentsForm = ({ values, setFieldValue, attachmentToEdit, formErrors, handleBlur, touched, errors }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection="column" gap="1rem">
      <TextField
        id="commentText"
        name="commentText"
        label={t("global.comment")}
        numberOfLines={5}
        value={values.commentText}
        variant="multi"
        error={formErrors.commentText || getErrorForInputField(errors.commentText, touched.commentText)}
        onBlur={handleBlur}
        onChange={(value) => setFieldValue("commentText", value)}
      />
      <Checkbox
        id="visibilityFlag"
        name="visibilityFlag"
        label={t("global.visible")}
        checked={!!values.visibilityFlag}
        error={formErrors.visibilityFlag || getErrorForInputField(errors.visibilityFlag, touched.visibilityFlag)}
        onChange={() => setFieldValue("visibilityFlag", !!values.visibilityFlag ? 0 : 1)}
      />
      {!attachmentToEdit && (
        <Dropzone
          error={formErrors.attachments}
          label={t("addReport.attachments")}
          files={values.attachments}
          addFilesValue={(value) => setFieldValue("attachments", value)}
        />
      )}
    </Stack>
  );
};

export default AttachmentsForm;
