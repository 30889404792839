import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "utils/config";

export const readLangCookie = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("lang"))
    ?.split("=")[1];

export const getLanguage = () =>
  readLangCookie() || (SUPPORTED_LANGUAGES.includes(navigator.language?.split("-")[0]) ? navigator.language?.split("-")[0] : DEFAULT_LANGUAGE);
