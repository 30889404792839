import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import { isAdmin, isClient, isReception } from "utils/global-functions";
import AttachmentsTable from "./AttachmentsTable";
import { isEmployee } from "utils/global-functions";
import DeepDivePanel from "components/Utils/DeepDivePanel";

const AttachmentsDetails = ({ attachmentList, setAttachmentsModalActive, setAttachmentDeleteModalActive, setSelectedAttachmentId }) => {
  const { t } = useTranslation();
  const {
    app,
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const [deepDivePanelActive, setDeepDivePanelActive] = useState(false);

  return (
    <>
      <Stack justifyContent="space-between">
        <h6 className="heading--6  heading--large-spacing">{t("global.attachments")}</h6>
        <Stack gap="var(--modal-buttons-gap)">
          {app.constants?.producers?.[repairDetails.producerId]?.strVal === "Universal Robots" && isEmployee(user) && repairDetails.repairState <= 20 && (
            <>
              <Button onClick={() => setDeepDivePanelActive(true)}>{t("deepDive.button")}</Button>
              {deepDivePanelActive && <DeepDivePanel onCloseCallback={() => setDeepDivePanelActive(false)}></DeepDivePanel>}
            </>
          )}
          {!isClient(user) && (isReception(user) || isAdmin(user) || repairDetails.technicianId === user.id) && (
            <Button variant="primary" onClick={() => setAttachmentsModalActive(true)}>
              {t("global.addAttachments")}
            </Button>
          )}
        </Stack>
      </Stack>
      {!!attachmentList?.length && (
        <AttachmentsTable
          attachmentList={attachmentList}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
          setAttachmentsModalActive={setAttachmentsModalActive}
        />
      )}
    </>
  );
};

export default AttachmentsDetails;
