const appReducer = (
  state = {
    notificationList: [],
    commonApiErrors: {
      codeList: [1, 203, 204, 205],
      maintenanceBreak: false,
      blockedAccount: false,
      incorrectToken: false,
      accessDenied: false,
      serverError: false,
      offline: false,
    },
  },
  action,
) => {
  let commonApiErrors = null;
  switch (action.type) {
    case "APP_SETCONSTANTS":
      return { ...state, constants: action.constants };
    case "APP_CLEARCONSTANTS":
      return { ...state, constants: {} };
    case "APP_SETLEFTPANELHIDDEN":
      return { ...state, leftPanelHidden: action.leftPanelHidden };
    case "APP_PUSHNOTIFICATION":
      const arr = [...state.notificationList];
      arr.push(action.notification);
      return { ...state, notificationList: arr };
    case "APP_CHECK_COMMONAPIERROR":
      commonApiErrors = { ...state.commonApiErrors };
      if (action.status === 0) commonApiErrors.offline = true;
      if (action.status >= 500) commonApiErrors.serverError = true;
      if (typeof action.error?.messages === "object" && action.error?.messages.length) {
        action.error.messages
          .filter((item) => item.type === "error")
          .filter((item) => state.commonApiErrors.codeList.includes(item.code))
          .forEach((item) => {
            switch (item.code) {
              case 1:
                commonApiErrors.maintenanceBreak = true;
                break;
              case 203:
                commonApiErrors.blockedAccount = true;
                break;
              case 204:
                commonApiErrors.incorrectToken = true;
                break;
              case 205:
                commonApiErrors.accessDenied = true;
                break;
              default:
            }
          });
      }
      return { ...state, commonApiErrors };
    case "APP_CLOSE_COMMONAPIERROR":
      commonApiErrors = { ...state.commonApiErrors };
      commonApiErrors[action.kind] = false;
      return { ...state, commonApiErrors };
    default:
      return state;
  }
};

export default appReducer;
