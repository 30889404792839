/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";

const useQRCodeScan = ({ qrcodeMountNodeID = "", closeAfterScan = true, getQrBoxDimension = () => 250 }) => {
  const [decodedQRData, setDecodedQrData] = useState({
    isScanning: false,
    isScanSuccess: false,
    isScanFailure: false,
    data: null,
    error: "",
  });
  const html5QrCodeScannerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (html5QrCodeScannerRef.current && decodedQRData.isScanning) {
        html5QrCodeScannerRef.current
          ?.stop()
          ?.then(() => {})
          ?.catch(() => {});
      }
    };
  }, []);

  const stopQrCode = () => {
    if (!!html5QrCodeScannerRef.current?.hasBorderShaders) {
      html5QrCodeScannerRef.current
        ?.stop()
        ?.then(() => {
          setDecodedQrData((state) => {
            return {
              ...state,
              isScanning: false,
              data: "",
            };
          });
        })
        ?.catch(() => {});
      html5QrCodeScannerRef?.current?.element?.classList.remove("qr-scanner__container");
    }
  };

  const startQrCode = () => {
    try {
      setDecodedQrData({
        ...decodedQRData,
        isScanning: true,
        data: null,
      });

      const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);
      html5QrCodeScannerRef.current = html5qrCodeScanner;

      html5qrCodeScanner
        .start(
          { facingMode: "environment" },
          { fps: 10, qrbox: getQrBoxDimension(), aspectRatio: 1.777778 },
          (qrCodeMessage) => {
            setDecodedQrData({
              ...decodedQRData,
              isScanSuccess: true,
              isScanning: false,
              data: qrCodeMessage,
              error: "",
            });

            if (!closeAfterScan) return;

            html5QrCodeScannerRef?.current?.element?.classList.remove("qr-scanner__container");
            return html5qrCodeScanner
              .stop()
              .then(() => {})
              .catch(() => {});
          },
          (errorMessage) => {},
        )
        .catch((err) => {
          setDecodedQrData({
            ...decodedQRData,
            isScanSuccess: false,
            isScanning: false,
            isScanFailure: true,
            data: null,
            error: err || "QR Code parsing failed",
          });
        });
      html5QrCodeScannerRef?.current?.element?.classList.add("qr-scanner__container");
    } catch (e) {
      setDecodedQrData({
        ...decodedQRData,
        isScanSuccess: false,
        isScanning: false,
        isScanFailure: true,
        data: null,
        error: e || "QR Code parsing failed",
      });
    }
  };

  return {
    startQrCode,
    decodedQRData,
    stopQrCode,
  };
};

export default useQRCodeScan;
