import React from "react";
import { getClassNamesForTextField } from "./utils";

const MultilineField = ({ disabled = false, name, numberOfLines, onChange, value, disableResize = true, fullHeight = false, ...rest }) => (
  <textarea
    {...rest}
    disabled={disabled}
    name={name}
    value={value}
    rows={numberOfLines}
    className={`input--text ${getClassNamesForTextField(disabled, disableResize, fullHeight)}`}
    onChange={(event) => onChange(event.target.value, event.target.name)}
  />
);

export default MultilineField;
