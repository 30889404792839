import React from "react";
import LoginCard from "./LoginCard";
import ProsCard from "./ProsCard";
import SearchForWarranty from "./SearchForWarranty";
import TopPanel from "./TopPanel";
import "./styles.css";

const Desktop = () => (
  <div className="login_box">
    <div className="login_container">
      <TopPanel />
      <LoginCard />
      <SearchForWarranty />
      <ProsCard />
    </div>
  </div>
);

export default Desktop;
