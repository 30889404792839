/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import agent from "api/agent";
import { ATTACHMENTS_CONVERSATION_TYPE, ATTACHMENTS_GENERAL_TYPE, ATTACHMENTS_REPORT_TYPE, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import AttachmentsModal from "./AttachmentsModal";
import ConfirmDeleteAttachmetModal from "./ConfirmDeleteAttachmentModal";

const Attachments = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const { repairId } = useParams();
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentsModalActive, setAttachmentsModalActive] = useState(false);
  const [attachementDeleteModalActive, setAttachmentDeleteModalActive] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const [refreshAttachments, setRefreshAttachments] = useState(false);

  const getAttachmentsSuccess = (response) => {
    setAttachmentList(response.data);
  };

  const getAttachmentsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getAttachments = () => {
    setAttachmentsLoading(true);
    agent.Repairs.handleRepairAttachments({
      id: user.id,
      token: user.token,
      repairId: repairId,
      action: "list",
      attachmentTypes: JSON.stringify([ATTACHMENTS_REPORT_TYPE, ATTACHMENTS_CONVERSATION_TYPE, ATTACHMENTS_GENERAL_TYPE]),
    })
      .then(getAttachmentsSuccess, getAttachmentsError)
      .finally(() => setAttachmentsLoading(false));
  };

  useEffect(() => {
    getAttachments();
  }, [refreshAttachments]);

  return (
    <>
      {mobile ? (
        <Mobile
          attachmentsLoading={attachmentsLoading}
          attachmentList={attachmentList}
          setAttachmentsModalActive={setAttachmentsModalActive}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
        />
      ) : (
        <Desktop
          attachmentsLoading={attachmentsLoading}
          attachmentList={attachmentList}
          setAttachmentsModalActive={setAttachmentsModalActive}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
        />
      )}
      <AttachmentsModal
        active={attachmentsModalActive}
        setActive={setAttachmentsModalActive}
        selectedAttachmentId={selectedAttachmentId}
        setSelectedAttachmentId={setSelectedAttachmentId}
        attachmentList={attachmentList}
        refreshAttachments={refreshAttachments}
        setRefreshAttachments={setRefreshAttachments}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <ConfirmDeleteAttachmetModal
        active={attachementDeleteModalActive}
        setActive={setAttachmentDeleteModalActive}
        selectedAttachmentId={selectedAttachmentId}
        setSelectedAttachmentId={setSelectedAttachmentId}
        refreshAttachments={refreshAttachments}
        setRefreshAttachments={setRefreshAttachments}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
    </>
  );
};

export default Attachments;
