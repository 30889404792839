import React from "react";
import PropTypes from "prop-types";
import "../commonStyles.css";

const ErrorLabel = ({ children }) => children && <span className="input__error-message">{children}</span>;

ErrorLabel.propTypes = {
  children: PropTypes.string,
};

export default ErrorLabel;
