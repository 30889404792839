import React from "react";
import PropTypes from "prop-types";
import "./Radio.css";
import { getRadioClassName } from "./utils";

const Radio = ({ id, disabled = false, name, label, value, onChange, ...rest }) => (
  <label className={`radio ${getRadioClassName(disabled)}`}>
    <input disabled={disabled} id={id} name={name} value={value} onChange={(event) => onChange(event)} type="radio" {...rest} />
    {label}
  </label>
);

Radio.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default Radio;
