import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import { isAdmin, isReception } from "utils/global-functions";

const AddValuation = ({ setAddValuationModalActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);

  if (!isReception(user) && !isAdmin(user) && repairDetails.technicianId !== user.id) return;

  return (
    <div className="mobile-add-pricing">
      <Button size="small" onClick={() => setAddValuationModalActive(true)} variant="primary">
        {t("myRepairs.addValuation")}
      </Button>
    </div>
  );
};

export default AddValuation;
