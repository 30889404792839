import React, { useState, useRef } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select, { components } from "react-select";

const Input = (props) => <components.Input {...props} isHidden={false} />;

const EditableSelect = ({ options, async, value, onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(value?.label ?? "");
  const selectRef = useRef();

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") setInputValue(inputValue);
  };

  const handleOnChange = (option) => {
    onChange(option);
    setInputValue(option ? option.label : "");
  };

  const onFocus = () => value && selectRef.current.select?.inputRef?.select();

  return async ? (
    <AsyncCreatableSelect
      ref={selectRef}
      options={options}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={handleOnChange}
      onFocus={onFocus}
      controlShouldRenderValue={false}
      components={{
        Input,
      }}
      {...rest}
    />
  ) : (
    <Select
      ref={selectRef}
      options={options}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={handleOnChange}
      onFocus={onFocus}
      controlShouldRenderValue={false}
      components={{
        Input,
      }}
      {...rest}
    />
  );
};

export default EditableSelect;
