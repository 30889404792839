import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import Stack from "stories/components/Stack/Stack";
import { AddReportFooter } from "routes/AddReport/AddReportFooter";
import { FACTORY_RESET_AGREEMENT_STATUSES } from "utils/config";
import { getAccordionContentForProductList } from "../utils";
import AddProductForm from "./AddProductForm";
import SearchForWarranty from "./SearchForWarranty";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { isUnderWarranty } from "./utils";
import CheckProductDemo from "./CheckProductDemo";

const DisplayMobile = ({
  setAddExternal,
  foundProduct,
  setFoundProduct,
  setRecentlyRepaired,
  setSerialNotFound,
  warrantyErrors,
  setWarrantyErrors,
  formErrors,
  setFormErrors,
  addExternal,
  setProductFullList,
  recentlyRepaired,
  serialNotFound,
  setDeleteConfirmationModalActive,
  setProductToDelete,
  productFullList,
  deleteConfirmationModalActive,
  deleteActionLoading,
  deleteProduct,
  productToDelete,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  resetForm,
  underWarrantyMap,
  accordionHeadings,
  validProductCheck,
  setValidProductCheck,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    app: { constants },
  } = useSelector((store) => store);

  const content = [
    {
      title: <h3 className="heading--3">{t("addReport.addProducts")}</h3>,
      body: (
        <Stack flexDirection="column" gap="0.8rem">
          <SearchForWarranty
            setAddExternal={setAddExternal}
            foundProduct={foundProduct}
            setFieldValue={setFieldValue}
            setFoundProduct={setFoundProduct}
            setRecentlyRepaired={setRecentlyRepaired}
            setSerialNotFound={setSerialNotFound}
            warrantyErrors={warrantyErrors}
            setWarrantyErrors={setWarrantyErrors}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            values={values}
            setValidProductCheck={setValidProductCheck}
          />
          <CheckProductDemo values={values} serialNotFound={serialNotFound} validProductCheck={validProductCheck} setValidProductCheck={setValidProductCheck} />
          <AddProductForm
            serialNotFound={serialNotFound}
            addExternal={addExternal}
            setAddExternal={setAddExternal}
            setProductFullList={setProductFullList}
            foundProduct={foundProduct}
            recentlyRepaired={recentlyRepaired}
            setSerialNotFound={setSerialNotFound}
            setDeleteConfirmationModalActive={setDeleteConfirmationModalActive}
            setProductToDelete={setProductToDelete}
            setFoundProduct={setFoundProduct}
            setWarrantyErrors={setWarrantyErrors}
            setRecentlyRepaired={setRecentlyRepaired}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            validProductCheck={validProductCheck}
          />
        </Stack>
      ),
      active: true,
    },
    {
      title: <h3 className="heading--3">{t("addReport.productsInReport")}</h3>,
      body: (
        <Accordion
          content={productFullList.map(({ warrantyEnd, factoryResetAgreement, ...rest }, index) => {
            return getAccordionContentForProductList(
              {
                warrantyEnd: `${underWarrantyMap[isUnderWarranty(warrantyEnd)]} ${warrantyEnd}`,
                factoryResetAgreement: t(`global.${FACTORY_RESET_AGREEMENT_STATUSES[factoryResetAgreement === null ? "noData" : factoryResetAgreement]}`),
                ...rest,
              },
              accordionHeadings,
              constants,
              index,
              {
                action: {
                  name: t("global.delete"),
                  onClick: (product) => {
                    setDeleteConfirmationModalActive(true);
                    setProductToDelete(product);
                  },
                },
              },
            );
          })}
        />
      ),
      active: true,
    },
  ];

  return (
    <div className="add-report-products">
      <Accordion content={content} />
      <AddReportFooter
        continueAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 2 })}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={!productFullList.length}
        productFullList={productFullList}
        currentStep={1}
      />
      <DeleteConfirmationModal
        active={deleteConfirmationModalActive}
        setActive={setDeleteConfirmationModalActive}
        loading={deleteActionLoading}
        onAction={() => deleteProduct(productToDelete)}
      />
    </div>
  );
};

export default DisplayMobile;
