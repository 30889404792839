/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MobileViewUnavailable from "components/Utils/MobileViewUnavailable";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../stories/components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import Button from "stories/components/Button/Button";
import Drawer from "stories/components/Drawer/Drawer";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Chip from "stories/components/Chip/Chip";
import Modal from "stories/components/Modal/Modal";
import agent from "api/agent";
import { t } from "i18next";
import bin from "../../images/bin.svg";
import { MIN_DESKTOP_WIDTH } from "utils/config";

const DictItem = ({ item, index, delClick, editClick }) => {
  const app = useSelector((store) => store.app);
  return (
    <tr>
      <td className="management-config-dictionary text-center">
        <div style={{ marginTop: "-9px" }}>
          <Checkbox checked={item.status} disabled={true} />
        </div>
      </td>
      <td className="management-config-dictionary">
        {typeof app.constants.dictionaries?.[item.dictionaryId] === "object"
          ? app.constants.dictionaries[item.dictionaryId].strVal
          : t("global.notDefinedConstant")}
      </td>
      <td className="management-config-dictionary">
        {typeof app.constants.producers?.[item.producerId] === "object" ? app.constants.producers[item.producerId].strVal : t("global.notDefinedConstant")}
      </td>
      <td>{item.name}</td>
      <td className="management-config-actions">
        <img
          src={bin}
          className="management-config-trash"
          alt="delete"
          onClick={() => {
            delClick(index);
          }}
        ></img>
      </td>
      <td className="management-config-actions">
        <Button
          variant="default"
          size="small"
          onClick={() => {
            editClick(index);
          }}
          style={{ width: "60px" }}
        >
          {t("global.edit")}
        </Button>
      </td>
    </tr>
  );
};

const DictItemForm = ({ item, index, itemFormCancel, itemUIDPerformed }) => {
  const app = useSelector((store) => store.app);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [formData, setFormData] = useState(index >= 0 ? item : { dictionaryId: 0, producerId: 0, name: "", status: 1 });
  const [formErrors, setFormErrors] = useState({ dictionaryId: "", producerId: "", name: "" });
  const dictOptions =
    typeof app.constants.dictionariesArr === "object"
      ? app.constants.dictionariesArr.map((item) => {
          return { value: item.intVal, label: item.strVal };
        })
      : [];
  const producerOptions =
    typeof app.constants.producersArr === "object"
      ? app.constants.producersArr.map((item) => {
          return { value: item.intVal, label: item.strVal };
        })
      : [];

  const handleChange = (value, name) => {
    const obj = { ...formData };
    obj[name] = name !== "status" ? value : value ? 1 : 0;
    setFormData(obj);
  };

  const itemFormValidate = () => {
    const obj = { dictionaryId: "", producerId: "", name: "" };
    let errors = false;
    if (!formData.dictionaryId) {
      obj.dictionaryId = "global.selectFromList";
      errors = true;
    }
    if (!formData.producerId) {
      obj.producerId = "global.selectFromList";
      errors = true;
    }
    if (!formData.name.length) {
      obj.name = "global.enterValue";
      errors = true;
    }
    setFormErrors(obj);
    if (!errors) itemFormSave();
  };

  const itemFormSave = () => {
    setFetching(true);
    const obj = { ...formData };
    obj.action = index >= 0 ? "update" : "insert";
    agent.Management.dictionaryManageItem({ id: user.id, token: user.token, ...obj }).then(
      (response) => {
        setFetching(false);
        if (response.result === 301) itemUIDPerformed();
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "dictionaryManageItem.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  return (
    <Modal
      title={index >= 0 ? t("global.editing") : t("global.newEntry")}
      bsBackdrop="static"
      onClose={itemFormCancel}
      footerActions={
        <div className="modal-buttons-container">
          <Button variant="default" onClick={itemFormCancel}>
            {t("global.cancel")}
          </Button>
          <Button variant="primary" onClick={itemFormValidate}>
            {t("global.save")}
          </Button>
        </div>
      }
    >
      <>
        <div className="mb-3">
          <Checkbox name="status" label={t("global.active")} checked={formData.status} onChange={handleChange} disabled={fetching} />
        </div>
        <div className="mb-2">
          <SelectField
            name="dictionaryId"
            label={t("global.dictionary")}
            placeholder={t("global.selectFromList")}
            options={dictOptions}
            value={formData.dictionaryId}
            multi={false}
            onChange={handleChange}
            disabled={fetching}
            error={formErrors.dictionaryId.length ? t(formErrors.dictionaryId) : null}
          />
        </div>
        <div className="mb-2">
          <SelectField
            name="producerId"
            label={t("global.producer")}
            placeholder={t("global.selectFromList")}
            options={producerOptions}
            value={formData.producerId}
            multi={false}
            onChange={handleChange}
            disabled={fetching}
            error={formErrors.producerId.length ? t(formErrors.producerId) : null}
          />
        </div>
        <div className="mb-5">
          <TextField
            name="name"
            variant="multi"
            numberOfLines={3}
            label={t("global.name")}
            placeholder=""
            maxLength={255}
            value={formData.name}
            onChange={handleChange}
            disabled={fetching}
            error={formErrors.name.length ? t(formErrors.name) : null}
          />
        </div>
      </>
    </Modal>
  );
};

export const ManagementDict = () => {
  const app = useSelector((store) => store.app);
  const user = useSelector((store) => store.user);
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({ dictionaryId: 0, producerId: 0, name: "" });
  const [drawerFilter, setDrawerFilter] = useState({ dictionaryId: 0, producerId: 0, name: "" });
  const [dictData, setDictData] = useState([]);
  const [editedIndex, setEditedIndex] = useState(null);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [deleteFetching, setDeleteFetching] = useState(false);

  useEffect(() => {
    dictionaryListItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const dictOptions =
    typeof app.constants.dictionariesArr === "object"
      ? app.constants.dictionariesArr.map((item) => {
          return { value: item.intVal, label: item.strVal };
        })
      : [];
  const producerOptions =
    typeof app.constants.producersArr === "object"
      ? app.constants.producersArr.map((item) => {
          return { value: item.intVal, label: item.strVal };
        })
      : [];

  const handleFilterChange = (value, name) => {
    const obj = { ...filter };
    obj[name] = value;
    setFilter(obj);
  };

  const handleDrawerFilterChange = (value, name) => {
    const obj = { ...drawerFilter };
    obj[name] = value;
    setDrawerFilter(obj);
  };

  const formReset = () => {
    setDrawerFilter({ dictionaryId: 0, producerId: 0, name: "" });
  };

  const dictionaryListItems = () => {
    setShowFilter(false);
    setFetching(true);
    agent.Management.dictionaryListItems({
      id: user.id,
      token: user.token,
      dictionaryId: filter.dictionaryId,
      producerId: filter.producerId,
      name: filter.name,
    }).then(
      (response) => {
        setFetching(false);
        setDictData(response.data);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "dictionaryListItems.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const itemDelCancel = () => {
    setDeletedIndex(null);
  };

  const itemDelConfirmed = () => {
    setDeleteFetching(true);
    agent.Management.dictionaryManageItem({ id: user.id, token: user.token, action: "delete", itemId: dictData[deletedIndex].itemId }).then(
      (response) => {
        setDeleteFetching(false);
        if (response.result === 301) itemUIDPerformed();
      },
      (error) => {
        setDeleteFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "dictionaryManageItem.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const itemFormCancel = () => {
    setEditedIndex(null);
  };
  const itemUIDPerformed = () => {
    setEditedIndex(null);
    setDeletedIndex(null);
    dictionaryListItems();
  };

  if (!desktop) return <MobileViewUnavailable />;
  return user.role !== "admin" ? (
    <h1>Access denied!</h1>
  ) : (
    <>
      <div className="p-3">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td className="filter-chips-wrapper">
                {filter.dictionaryId ? (
                  <Chip
                    onClick={() => {
                      handleFilterChange(0, "dictionaryId");
                    }}
                  >
                    {t("global.dictionary")}:{" "}
                    {typeof app.constants.dictionaries?.[filter.dictionaryId] === "object"
                      ? app.constants.dictionaries[filter.dictionaryId].strVal
                      : t("global.notDefinedConstant")}
                  </Chip>
                ) : null}
                {filter.producerId ? (
                  <Chip
                    onClick={() => {
                      handleFilterChange(0, "producerId");
                    }}
                  >
                    {t("global.producer")}:{" "}
                    {typeof app.constants.producers?.[filter.producerId] === "object"
                      ? app.constants.producers[filter.producerId].strVal
                      : t("global.notDefinedConstant")}
                  </Chip>
                ) : null}
                {filter.name.length ? (
                  <Chip
                    onClick={() => {
                      handleFilterChange("", "name");
                    }}
                  >
                    {t("global.name")}: {filter.name}
                  </Chip>
                ) : null}
              </td>
              <td className="text-end">
                <Button
                  variant="default"
                  onClick={() => {
                    setEditedIndex(-1);
                  }}
                >
                  {t("global.add")}
                </Button>{" "}
                <Button
                  variant="primary"
                  onClick={() => {
                    setDrawerFilter({ ...filter });
                    setShowFilter(true);
                  }}
                >
                  {t("global.filter")}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {editedIndex !== null ? (
          <DictItemForm
            item={editedIndex >= 0 ? dictData[editedIndex] : null}
            index={editedIndex}
            itemFormCancel={itemFormCancel}
            itemUIDPerformed={itemUIDPerformed}
          />
        ) : null}
        {deletedIndex !== null ? (
          <Modal
            title={t("global.delEntryConfirm")}
            onClose={itemDelCancel}
            footerActions={
              <div className="modal-buttons-container">
                <Button variant="default" onClick={itemDelCancel} disabled={deleteFetching}>
                  {t("global.no")}
                </Button>
                <Button variant="primary" onClick={itemDelConfirmed} disabled={deleteFetching}>
                  {t("global.yes")}
                </Button>
              </div>
            }
          ></Modal>
        ) : null}
        <Drawer active={showFilter} title={t("global.filters")} setActive={setShowFilter}>
          <div>
            <div className="mb-2">
              <SelectField
                name="dictionaryId"
                label={t("global.dictionary")}
                placeholder={t("global.selectFromList")}
                options={[{ value: 0, label: t("global.all.v1") }, ...dictOptions]}
                value={drawerFilter.dictionaryId}
                multi={false}
                onChange={handleDrawerFilterChange}
                disabled={fetching}
              />
            </div>
            <div className="mb-2">
              <SelectField
                name="producerId"
                label={t("global.producer")}
                placeholder={t("global.selectFromList")}
                options={[{ value: 0, label: t("global.all.v2") }, ...producerOptions]}
                value={drawerFilter.producerId}
                multi={false}
                onChange={handleDrawerFilterChange}
                disabled={fetching}
              />
            </div>
            <div className="mb-5">
              <TextField
                name="name"
                label={t("global.name")}
                placeholder=""
                maxLength={30}
                value={drawerFilter.name}
                onChange={handleDrawerFilterChange}
                disabled={fetching}
              />
            </div>
            <div className="text-end">
              <Button variant="default" onClick={formReset}>
                {t("global.clearFilters")}
              </Button>{" "}
              <Button
                variant="primary"
                disabled={fetching}
                onClick={() => {
                  setFilter(drawerFilter);
                }}
              >
                {t("global.showResults")}
              </Button>
            </div>
          </div>
        </Drawer>
        {fetching ? (
          <div className="mt-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mt-2">
              {dictData.length ? (
                <table className="management-config">
                  <thead>
                    <tr>
                      <th>{t("global.active")}</th>
                      <th>{t("global.dictionary")}</th>
                      <th>{t("global.producer")}</th>
                      <th>{t("global.name")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dictData.map((item, index) => (
                      <DictItem
                        key={item.itemId}
                        item={item}
                        index={index}
                        delClick={(index) => {
                          setDeletedIndex(index);
                        }}
                        editClick={(index) => {
                          setEditedIndex(index);
                        }}
                      />
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};
