import React from "react";
import { useTranslation } from "react-i18next";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";

const PackageModal = ({ active, setActive, packageLimit }) => {
  const { t } = useTranslation();

  if (!active) return;

  return (
    <Modal title={t("global.error")} onClose={() => setActive(false)} footerActions={<Button onClick={() => setActive(false)}>{t("global.close")}</Button>}>
      <p className="paragraph--small">{t("addReport.packageOverLimitModalInfo")}</p>
      <p className="paragraph--small text--bold">{t("addReport.packageOverLimitModalInfoMaximum", { limit: packageLimit })}</p>
    </Modal>
  );
};

export default PackageModal;
