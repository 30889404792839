import md5 from "md5";

export const getRandomComponentId = () => String(md5(Math.floor(Math.random() * 10000)).slice(0, 17));

const modalClassSizes = {
  huge: "modal-xl",
  big: "modal-lg",
  small: "modal-sm",
  default: "",
  elastic: "modal-elastic",
};

export const getModalSize = (size) => modalClassSizes[size];

export const handleModalzIndexChange = (zIndex, className) => {
  if (Array.from(Array.from(document.styleSheets).find((x) => !!x.href)?.cssRules)?.some((cssRule) => cssRule.selectorText === className))
    Array.from(Array.from(document.styleSheets).find((x) => !!x.href).cssRules).find((cssRule) => cssRule.selectorText === className).style.zIndex = zIndex;
  else document.styleSheets[0].insertRule(`.modal-backdrop { z-index: ${zIndex} !important; }`, 0);
};
