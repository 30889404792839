/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MobileViewUnavailable from "components/Utils/MobileViewUnavailable";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../stories/components/Spinner/Spinner";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import moment from "moment";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import Badge from "stories/components/Badge/Badge";

export const ManagementRegs = () => {
  const app = useSelector((store) => store.app);
  const user = useSelector((store) => store.user);
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [records, setRecords] = useState([]);
  const [regs, setRegs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    unapprovedReportsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unapprovedReportsList = () => {
    setFetching(true);
    agent.Management.unapprovedReportsList({ id: user.id, token: user.token }).then(
      (response) => {
        setFetching(false);
        setRecords(response.data);
        const arr = [];
        response.data.forEach((item) => {
          if (arr.findIndex((elem) => elem === item.registrationId) === -1) arr.push(item.registrationId);
        });
        setRegs(arr);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "unapprovedReportsList.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const unapprovedReportRemove = () => {
    setFetching(true);
    agent.Repairs.deleteRepair({ id: user.id, token: user.token, repairId: id }).then(
      (response) => {
        if (response.result === 301) {
          setShowModal(false);
          unapprovedReportsList();
        } else {
          setFetching(false);
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "deleteRepair.failed" } });
        }
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "deleteRepair.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  if (!desktop) return <MobileViewUnavailable />;
  return user.role !== "admin" ? (
    <h1>Access denied!</h1>
  ) : fetching ? (
    <div className="mt-4">
      <Spinner />
    </div>
  ) : (
    <>
      {showModal ? (
        <Modal
          title={`${t("management.regs.modalTitle")} (${id})`}
          footerActions={
            <div className="modal-buttons-container">
              <Button
                variant="default"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t("global.no")}
              </Button>
              <Button variant="primary" onClick={unapprovedReportRemove}>
                {t("global.yes")}
              </Button>
            </div>
          }
          onClose={() => {
            setShowModal(false);
          }}
        >
          <h6 className="heading--6">{t("management.regs.modalQuestion")}</h6>
          <p className="paragraph--small text--subdued">{t("management.regs.modalExplanation")}</p>
        </Modal>
      ) : null}
      <div className="mb-4">
        <Button size="small" onClick={unapprovedReportsList}>
          {t("global.refresh")}
        </Button>
      </div>
      {regs.length ? (
        <>
          {regs.map((registrationId) => (
            <div className="management-frame mb-4" key={registrationId}>
              <div>
                <b>RegistrationId</b>: {registrationId}
              </div>
              {records
                .filter((item) => item.registrationId === registrationId)
                .map((item) => (
                  <div className="management-frame mt-2" key={item.repairId}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="text--bold pe-3 text--right" style={{ width: "10rem" }}>
                            {t("global.createdAt")}
                          </td>
                          <td>{item.createdAt}</td>
                          <td rowSpan={4} style={{ textAlign: "right", verticalAlign: "top" }}>
                            <div>
                              <Badge
                                uppercase={false}
                                backgroundColor={moment().diff(item.createdAt, "minutes") > 120 ? "red" : "green"}
                                content={moment(item.createdAt).locale(user.lang).fromNow()}
                              />
                            </div>
                            <div className="mt-2">
                              <Button
                                size="small"
                                variant="default"
                                onClick={() => {
                                  setShowModal(true);
                                  setId(item.repairId);
                                }}
                              >
                                {t("global.delete")}
                              </Button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text--bold pe-3 text--right">{t("global.product")}</td>
                          <td>
                            S/N={item.serialNumber}, P/N={item.partNumber}, DSCR={item.description} ({app.constants?.producers?.[item.producerId].strVal})
                          </td>
                        </tr>
                        <tr>
                          <td className="text--bold pe-3 text--right">{t("global.customer")}</td>
                          <td>
                            ({item.customerId}) {item.customer}
                          </td>
                        </tr>
                        <tr>
                          <td className="text--bold pe-3 text--right">{t("global.author")}</td>
                          <td>
                            ({item.authorId}) {item.author}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};
