import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField } from "utils/global-functions";
import { convertNullToEmptyString, isRepairDetailsModificationForbidden } from "./utils";
import AddExternalForm from "./AddExternalForm";

const AddReplacementProductForm = ({ values, setFieldValue, formErrors, handleBlur, errors, touched }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);

  return (
    <div className="repair-info-form">
      <TextField
        id="serialNumber"
        name="serialNumber"
        label={t("global.serialNumber")}
        placeholder={t("addReport.serialNumberPlaceholder")}
        value={values.serialNumber}
        onChange={(value) => setFieldValue("serialNumber", value)}
        error={getErrorForInputField(errors.serialNumber, touched.serialNumber) || formErrors?.serialNumber}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      {convertNullToEmptyString(repairDetails.replacementSerialNumber) !== values.serialNumber && (
        <AddExternalForm
          values={values}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          withoutSerialNumber
          noExtInNames
        />
      )}
    </div>
  );
};

export default AddReplacementProductForm;
