import React from "react";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import EditableSelect from "./EditableSelect";

const sizeClasses = {
  small: "select-field__small",
};

const dropdownPosition = {
  true: "absolute",
  false: "static",
};

const menuPosition = {
  true: null,
  false: "fixed",
};

export const getSelectFieldClassNames = (size) => {
  const classNames = [];

  classNames.push(sizeClasses[size] || "");

  return classNames.join(" ");
};

export const getSelectField = ({
  async,
  id,
  editable,
  size,
  disabled,
  name,
  label,
  multi,
  menuPortalTarget,
  onChange,
  promiseOptions,
  t,
  selected,
  selectContainer,
  ...rest
}) => {
  if (async)
    return editable ? (
      <EditableSelect
        async
        className={getSelectFieldClassNames(size)}
        cacheOptions
        backspaceRemovesValue
        formatCreateLabel={(label) => `${t("global.create")}: "${label}"`}
        loadingMessage={() => `${t("global.loading")}...`}
        loadOptions={promiseOptions}
        id={`${id}__container`}
        inputId={id}
        isDisabled={disabled}
        menuPosition={menuPosition[!!menuPortalTarget]}
        isMulti={multi}
        value={selected}
        onChange={(option) => onChange(option ? option.value : null, name)}
        noOptionsMessage={() => t("global.noOptions")}
        menuPortalTarget={menuPortalTarget || selectContainer.current}
        styles={{ menuPortal: (base) => ({ ...base, position: dropdownPosition[!!menuPortalTarget], zIndex: 10056 }) }}
        isOptionDisabled={(option) => option.disabled}
        {...rest}
      />
    ) : (
      <AsyncCreatableSelect
        className={getSelectFieldClassNames(size)}
        cacheOptions
        backspaceRemovesValue
        formatCreateLabel={(label) => `${t("global.create")}: "${label}"`}
        loadingMessage={() => `${t("global.loading")}...`}
        loadOptions={promiseOptions}
        id={`${id}__container`}
        inputId={id}
        isDisabled={disabled}
        menuPosition={menuPosition[!!menuPortalTarget]}
        isMulti={multi}
        value={selected}
        onChange={(option) => onChange(option ? option.value : null, name)}
        noOptionsMessage={() => t("global.noOptions")}
        menuPortalTarget={menuPortalTarget || selectContainer.current}
        styles={{ menuPortal: (base) => ({ ...base, position: dropdownPosition[!!menuPortalTarget], zIndex: 10056 }) }}
        isOptionDisabled={(option) => option.disabled}
        {...rest}
      />
    );

  return editable ? (
    <EditableSelect
      className={getSelectFieldClassNames(size)}
      id={`${id}__container`}
      inputId={id}
      isDisabled={disabled}
      menuPosition={menuPosition[!!menuPortalTarget]}
      isMulti={multi}
      value={selected}
      onChange={(option) => {
        if (multi) return onChange(option.map(({ value }) => value));
        else onChange(option ? option.value : null, name);
      }}
      noOptionsMessage={() => t("global.noOptions")}
      menuPortalTarget={menuPortalTarget || selectContainer.current}
      styles={{ menuPortal: (base) => ({ ...base, position: dropdownPosition[!!menuPortalTarget], zIndex: 10056 }) }}
      isOptionDisabled={(option) => option.disabled}
      {...rest}
    />
  ) : (
    <Select
      className={getSelectFieldClassNames(size)}
      id={`${id}__container`}
      inputId={id}
      isDisabled={disabled}
      menuPosition={menuPosition[!!menuPortalTarget]}
      isMulti={multi}
      value={selected}
      onChange={(option) => {
        if (multi) return onChange(option.map(({ value }) => value));
        else onChange(option ? option.value : null, name);
      }}
      noOptionsMessage={() => t("global.noOptions")}
      menuPortalTarget={menuPortalTarget || selectContainer.current}
      styles={{ menuPortal: (base) => ({ ...base, position: dropdownPosition[!!menuPortalTarget], zIndex: 10056 }) }}
      isOptionDisabled={(option) => option.disabled}
      {...rest}
    />
  );
};
