import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Chevron from "images/Chevron.svg";
import { getActiveAccordionStyle, handleAccordionToggle } from "./utils";
import "./styles.css";

const Accordion = ({ content, shadow = false }) => {
  useEffect(() => {
    const accordions = document.getElementsByClassName("accordion");

    for (let i = 0; i < accordions.length; i++) {
      if (!accordions[i].dataset.listenerSet) accordions[i].addEventListener("click", () => handleAccordionToggle(accordions[i]));
      accordions[i].dataset.listenerSet = true;
    }
  }, [content]);

  return (
    <div className="accordions-box">
      {content.map(({ title, body, active, selectedAccordion }, index) => (
        <div key={index} className={`accordion-wrapper ${shadow ? "accordion-wrapper--shadow" : ""} ${selectedAccordion ? "accordion--selected" : ""}`}>
          <button className={`accordion no-select ${selectedAccordion ? "accordion--selected" : ""}`} type="button">
            {title}
            <img id="accordion_chevron" className={`accordion-chevron ${active ? "accordion-chevron__active" : ""}`} src={Chevron} alt="v" />
          </button>
          <div className={`accordion-panel ${selectedAccordion ? "accordion--selected" : ""}`} style={getActiveAccordionStyle(active)}>
            <div className="accordion-content">{body}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  content: PropTypes.array,
  shadow: PropTypes.bool,
};

export default Accordion;
