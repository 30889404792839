import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "stories/components/Table/Table";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { ATTACHMENTS_GENERAL_TYPE } from "utils/config";
import Info from "images/InfoRect.svg";
import Stack from "stories/components/Stack/Stack";
import { formatBytes, isEmployee } from "utils/global-functions";
import { isAbleToModifyAttachment } from "../utils";

const AttachmentsTableRows = ({ attachmentList, setAttachmentsModalActive, setSelectedAttachmentId, setAttachmentDeleteModalActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return attachmentList.map((attachment) => (
    <Table.Row className={`${attachment.visibilityFlag ? "" : "attachment__table-row--hidden"}`} key={attachment.id} id={attachment.id} extended>
      <Table.Cell>{attachment.id}</Table.Cell>
      <Table.Cell>
        <a className="custom-link" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
          {attachment.fileName}
        </a>
      </Table.Cell>
      <Table.Cell>
        <div className="attachment__table-date">
          <span className="paragraph--small">{attachment.fileType}</span>
          <span className="paragraph--small">{formatBytes(attachment.fileSize)}</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="attachment__table-date">
          <span className="paragraph--small">{attachment.createdAt.split(" ").at(0)}</span>
          <span className="paragraph--small">{attachment.createdAt.split(" ").at(1)}</span>
        </div>
      </Table.Cell>
      {isEmployee(user) && (
        <>
          <Table.Cell>{attachment.authorName || "-"}</Table.Cell>
          <Table.Cell>{t(`global.${!!attachment.visibilityFlag}`)}</Table.Cell>
          <Table.Cell>
            {isAbleToModifyAttachment(user, attachment) && attachment.attachmentType === ATTACHMENTS_GENERAL_TYPE && (
              <Stack gap="var(--modal-buttons-gap)">
                <button
                  className="unset-all custom-link--black"
                  onClick={() => {
                    setAttachmentsModalActive(true);
                    setSelectedAttachmentId(attachment.id);
                  }}
                >
                  {t("global.edit")}
                </button>
                <button
                  className="unset-all custom-link"
                  onClick={() => {
                    setAttachmentDeleteModalActive(true);
                    setSelectedAttachmentId(attachment.id);
                  }}
                >
                  {t("global.delete")}
                </button>
              </Stack>
            )}
          </Table.Cell>
        </>
      )}
      <Table.Cell>
        {!!attachment.commentText && (
          <div className="attachment__table-comment">
            <Tooltip content={attachment.commentText} direction="left">
              <img src={Info} className="admins-repairs-table__info-icon" alt="i" />
            </Tooltip>
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  ));
};

export default AttachmentsTableRows;
