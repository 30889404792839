import React from "react";
import Search from "images/Search.svg";
import { useTranslation } from "react-i18next";

const TableEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="custom-table-empty-state">
      <img src={Search} alt="search_icon" className="custom-table-empty-state__icon-wrapper" />
      <div>
        <h6 className="heading--6 no-margin">{t("global.noResultsFound")}</h6>
        <p className="paragraph--small no-margin">{t("global.noResultsFoundInfo")}</p>
      </div>
    </div>
  );
};

export default TableEmptyState;
