import { deleteCookie, readCookie, writeCookie, readDottedComponent } from "utils/global-functions";

const registrationReducer = (
  state = {
    registrationStep: parseInt(readDottedComponent(readCookie("registration"), 0)) || 0,
    registrationId: readDottedComponent(readCookie("registration"), 1) || null,
    registrationAuthorId: parseInt(readDottedComponent(readCookie("registration"), 2)) || null,
    registrationUserId: parseInt(readDottedComponent(readCookie("registration"), 3)) || null,
    registrationRepairSource: parseInt(readDottedComponent(readCookie("registration"), 4)) || null,
  },
  action,
) => {
  switch (action.type) {
    case "REGISTRATION_SET":
      writeCookie({
        key: "registration",
        value: `${action.data.step}.${action.data.registrationId}.${action.data.authorId}.${action.data.userId}.${action.data.repairSource}`,
      });
      return {
        ...state,
        registrationStep: action.data.step,
        registrationId: action.data.registrationId,
        registrationAuthorId: action.data.authorId,
        registrationUserId: action.data.userId,
        registrationRepairSource: action.data.repairSource,
      };

    case "REGISTRATION_SETSTEP":
      writeCookie({
        key: "registration",
        value: `${action.step}.${state.registrationId}.${state.registrationAuthorId}.${state.registrationUserId}.${state.registrationRepairSource}`,
      });
      return { ...state, registrationStep: action.step };

    case "REGISTRATION_CANCEL":
      deleteCookie("registration");
      return { ...state, registrationStep: 0, registrationId: null, registrationAuthorId: null, registrationUserId: null, registrationRepairSource: null };

    default:
      return state;
  }
};

export default registrationReducer;
