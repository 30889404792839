import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Plus from "images/Plus.svg";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";

const AssignRepairButton = () => {
  const { t } = useTranslation();
  const {
    user,
    app: { leftPanelHidden, commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const assignRepairSuccess = (response) => {
    if (response.result === 301) {
      return navigate(`/${response.repairId}/info`);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const assignRepairCustomErrors = {
    302: "302assaignRepair",
  };

  const assignRepairError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${assignRepairCustomErrors[filterdError.code] || filterdError.code}`),
        },
      }),
    );
  };

  const assignRepair = () => {
    agent.Management.queueGetRepair({ id: user.id, token: user.token }).then(assignRepairSuccess, assignRepairError);
  };

  return leftPanelHidden && !mobile ? (
    <Tooltip allowHtml content={t("panel.add")} direction="right" zIndex={999999}>
      <button type="button" onClick={() => assignRepair()} className="panel__call-to-action--technician panel__call-to-action--small">
        <img className="panel__call-to-action--icon no-select panel__call-to-action--icon-small" src={Plus} alt="+" />
        <span className="span-hidden">{t("panel.assignRepair")}</span>
      </button>
    </Tooltip>
  ) : (
    <button type="button" onClick={() => assignRepair()} className="panel__call-to-action--technician">
      <img className="panel__call-to-action--icon no-select" src={Plus} alt="+" />
      <span className="span">{t("panel.assignRepair")}</span>
    </button>
  );
};

export default AssignRepairButton;
