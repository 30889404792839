import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { EmailActions } from "components/Utils/EmailActions";
import Button from "stories/components/Button/Button";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";
import bin from "images/bin.svg";
import Stack from "stories/components/Stack/Stack";
import ActionButtons from "./ActionButtons";
import DeleteRepairWarningModal from "./DeleteRepairWarningModal";

const DeleteRepair = ({ refreshRepairDetails, setRefreshRepairDetails, repairSteps }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const [deleteRepairWarningModalActive, setDeleteRepairWarningModalActive] = useState(false);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  return (
    <div className="delete-repair">
      <Stack gap={"var(--modal-buttons-gap)"}>
        {isAdmin(user) && (
          <Button
            style={{ width: "11rem" }}
            fullHeight={mobile}
            size={mobile ? "small" : "default"}
            variant="error"
            outline
            onClick={() => setDeleteRepairWarningModalActive(true)}
          >
            <Stack alignItems="center" gap="0.6rem">
              <img src={bin} alt="" height="20" />
              <span>{t("myRepairs.deleteRepair")}</span>
            </Stack>
          </Button>
        )}
        {(isAdmin(user) || isReception(user)) && <EmailActions repairId={repairDetails.repairId} />}
      </Stack>
      {!mobile && <ActionButtons repairSteps={repairSteps} refreshRepairDetails={refreshRepairDetails} setRefreshRepairDetails={setRefreshRepairDetails} />}
      <DeleteRepairWarningModal active={deleteRepairWarningModalActive} setActive={setDeleteRepairWarningModalActive} />
    </div>
  );
};

export default DeleteRepair;
