import { DELIVERY_TYPE_ELMARK_COURIER, RETURN_TYPE_ELMARK_COURIER } from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";

export const getRegistrationShipmentBody = (body) => {
  const modifiedBody = {};

  if (body.deliveryType === DELIVERY_TYPE_ELMARK_COURIER) {
    const deliveryKeys = Object.entries(body)
      .filter((value) => value.at(0).includes("deliveryAddress"))
      .map((value) => value.at(0));
    deliveryKeys.forEach((deliveryKey) => {
      modifiedBody[deliveryKey] = body[deliveryKey];
    });
    modifiedBody.deliveryPackages = body.deliveryPackages ? JSON.stringify(body.deliveryPackages) : body.deliveryPackages;
  }

  if (body.returnType === RETURN_TYPE_ELMARK_COURIER) {
    const returnKeys = Object.entries(body)
      .filter((value) => value.at(0).includes("returnAddress"))
      .map((value) => value.at(0));
    returnKeys.forEach((returnKey) => {
      modifiedBody[returnKey] = body[returnKey];
    });
  }

  modifiedBody.deliveryType = body.deliveryType;
  modifiedBody.returnType = body.returnType;

  return modifiedBody;
};

export const getPackageTypeOptions = (packageType, deliveryPackages) =>
  Object.entries(packageType)
    .map(([key, { strVal }]) => {
      return { label: strVal, value: parseInt(key) };
    })
    .filter(({ value }) => (deliveryPackages ? !deliveryPackages.map(({ type }) => type).includes(value) : !deliveryPackages));

export const getKeyForFormErrors = (message) => {
  if (message.property.includes("->")) return message.property.split("[").at(0);

  return message.property;
};

export const isPackageQuantityLimit = (packageQuantityLimit, allPackageQuantity) =>
  packageQuantityLimit - allPackageQuantity.reduce((accumulator, value) => accumulator + value) === 0;

export const getPackageQuantityLimit = (allPackageQuantity, packageQuantityLimit, index) => {
  if (!allPackageQuantity.filter((_, packageQuantityLimitIndex) => packageQuantityLimitIndex !== index).length) return packageQuantityLimit;
  return (
    packageQuantityLimit -
    allPackageQuantity.filter((_, packageQuantityLimitIndex) => packageQuantityLimitIndex !== index).reduce((accumulator, value) => accumulator + value)
  );
};

export const isNoMorePackageOption = (constants, values) => getPackageTypeOptions(constants.packageType).length === values.deliveryPackages.length;

export const isContinueActionDisabled = (values, errors) => {
  if (!values.returnType || !values.deliveryType) return true;

  return (
    (values.deliveryType === DELIVERY_TYPE_ELMARK_COURIER && isFormValid(errors, "delivery")) ||
    (values.returnType === RETURN_TYPE_ELMARK_COURIER && isFormValid(errors, "return"))
  );
};

const isFormValid = (errors, formName) => Object.entries(errors).some(([key]) => key.includes(formName));

export const getBodyForAccountInfo = (user, registration) =>
  isAdmin(user) || isReception(user) ? { id: user.id, token: user.token, userId: registration.registrationUserId } : { id: user.id, token: user.token };
