import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AddReportHeading } from "../AddReportHeading";
import { AddReportFooter } from "../AddReportFooter";
import DeliveryForm from "./DeliveryForm";
import ReturnForm from "./ReturnForm";
import { isContinueActionDisabled } from "./utils";

const DisplayDesktop = ({
  registrationShipmentSet,
  formErrors,
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedUser,
  sendShipmentLoading,
  packageQuantityLimit,
  setStreetWarningModalActive,
  setFieldTouched,
  productFullListIncludesUR,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="add-report">
      <AddReportHeading />
      <div className="add-report__sides-wrapper">
        <div className="add-report__side-panel">
          <div className="add-report-delivery__left-form-wrapper">
            <h6 className="heading--6 heading--margin">{t("addReport.deliveryHow")}</h6>
            <DeliveryForm
              formErrors={formErrors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              selectedUser={selectedUser}
              packageQuantityLimit={packageQuantityLimit}
              setStreetWarningModalActive={setStreetWarningModalActive}
              setFieldTouched={setFieldTouched}
              productFullListIncludesUR={productFullListIncludesUR}
            />
          </div>
        </div>
        <div className="add-report__side-border" />
        <div className="add-report__side-panel">
          <div className="add-report-delivery__right-form-wrapper">
            <h6 className="heading--6 heading--margin">{t("addReport.returnHow")}</h6>
            <ReturnForm
              formErrors={formErrors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              selectedUser={selectedUser}
              setStreetWarningModalActive={setStreetWarningModalActive}
              setFieldTouched={setFieldTouched}
              productFullListIncludesUR={productFullListIncludesUR}
            />
          </div>
        </div>
      </div>
      <AddReportFooter
        backActionLabel={t("global.back")}
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 1 })}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={isContinueActionDisabled(values, errors)}
        continueAction={() => registrationShipmentSet(values)}
        continueActionLoading={sendShipmentLoading}
      />
    </div>
  );
};

export default DisplayDesktop;
