import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddReportFooter } from "routes/AddReport/AddReportFooter";
import Accordion from "stories/components/Accordion/Accordion";
import AddContactDataForm from "./AddContactDataForm";
import InvoiceDetails from "./InvoiceDetails";

const DisplayMobile = ({ touched, errors, handleBlur, formErrors, values, setFieldValue, setRegistrationContact, sendContactDataLoading, formValid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="add-report-data">
      <Accordion
        content={[
          { title: <h3 className="heading--3">{t("addReport.dataToInvoice")}</h3>, body: <InvoiceDetails />, active: true },
          {
            title: <h3 className="heading--3">{t("addReport.enterData")}</h3>,
            body: (
              <AddContactDataForm
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                formErrors={formErrors}
                values={values}
                setFieldValue={setFieldValue}
              />
            ),
            active: true,
          },
        ]}
      />
      <AddReportFooter
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 2 })}
        backActionLabel={t("global.back")}
        continueAction={() => setRegistrationContact(values)}
        continueActionLabel={t("global.continue")}
        continueActionDisabled={!formValid && values.newContactData}
        continueActionLoading={sendContactDataLoading}
      />
    </div>
  );
};

export default DisplayMobile;
