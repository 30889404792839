import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "stories/components/Modal/Modal";
import { formatWarrantyDescription, formatWarrantyDetails } from "utils/global-functions";
import DescriptionList from "stories/components/DescriptionList/DescriptionList";
import Button from "stories/components/Button/Button";

const WarrantyModal = ({ onClose, active, data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!active) return;

  return (
    <Modal
      title={t("loginPage.foundProduct")}
      onClose={onClose}
      footerActions={
        <Button variant="primary" onClick={() => navigate(`/serial-number/${encodeURIComponent(data.serialNumber)}`)}>
          {t("serialNumber.productCard")}
        </Button>
      }
    >
      <DescriptionList
        list={formatWarrantyDetails(data).map(([key, value]) => {
          return { term: t(`loginPage.${key}`), description: formatWarrantyDescription(key, value, t) };
        })}
      />
    </Modal>
  );
};

export default WarrantyModal;
