import React from "react";
import CardContent from "./CardContent";
import CardSidebar from "./CardSidebar";
import "./styles.css";

const Card = ({ children }) => <div className="custom-grid-card">{children}</div>;

Card.Content = CardContent;
Card.Sidebar = CardSidebar;

export default Card;
