import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import Radio from "stories/components/Radio/Radio";
import ContactForm from "components/ContactForm/ContactForm";

const EditClientContactForm = ({ values, setFieldValue, formErrors, handleBlur, errors, touched }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection="column" gap="0.4rem">
      <div className="client-data-modal__textfields-wrapper">
        <Radio checked={!values.newContactData} onChange={() => setFieldValue("newContactData", false)} label={t("addReport.useClientContactData")} />
        <Radio checked={values.newContactData} onChange={() => setFieldValue("newContactData", true)} label={t("addReport.provideOtherContactData")} />
        {values.newContactData && (
          <div className="repair-info-form">
            <ContactForm values={values} setFieldValue={setFieldValue} formErrors={formErrors} handleBlur={handleBlur} errors={errors} touched={touched} />
          </div>
        )}
      </div>
    </Stack>
  );
};

export default EditClientContactForm;
