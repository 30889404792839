import React, { Suspense, useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import i18n from "i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import agent from "api/agent";
import lodash from "lodash";
import { MAX_MOBILE_WIDTH, UNAUTHORIZED_SESSION_ERROR_CODES } from "utils/config";
import PageSpinner from "components/PageSpinner/PageSpinner";
import Toast from "stories/components/Toast/Toast";
import Routes from "routes/Routes";
import "../../i18n";
import useServiceWorker from "../../useServiceWorker";
import { Navbar, NavbarMobile } from "./Navbar.js";
import { LeftPanel } from "./LeftPanel.js";
import CookiesSticker from "./CookiesSticker";
import { Footer } from "./Footer.js";
import { isUserLogged, extractIdentity, userId, userToken, getAndValidateLangFromSearchParam } from "./utils";
import "./App.css";
import CommonApiErrors from "components/Utils/CommonApiErrors";
import VarDump from "components/Utils/VarDump";
import WarrantySearchResultModal from "./WarrantySearchResultModal";
import { filterErrorsFromCommonApiErrors, setDocumentTitle, writeCookie } from "utils/global-functions";
import { useTranslation } from "react-i18next";
import NewUpdateAvailableModal from "./NewUpdateAvailableModal";

function App() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [loadingApp, setLoadingApp] = useState(true);
  const textFieldSearchWarranty = useRef(null);
  const [warrantySearchActive, setWarrantySearchActive] = useState(false);
  const [warrantySearchLoading, setWarrantySearchLoading] = useState(false);
  const [searchWarrantyInput, setSearchWarrantyInput] = useState("");
  const [searchWarrantyResult, setSearchWarrantyResult] = useState({});
  const [newUpdateAvailableModalActive, setNewUpdateAvailableModalActive] = useState(false);
  const { app, user, registration } = useSelector((store) => store);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const [debugTop, setDebugTop] = useState(false);
  const [debugShow, setDebugShow] = useState(false);

  useEffect(() => {
    if (!warrantySearchLoading) {
      handleTextFieldSearchFocus();
    }
  }, [warrantySearchLoading, warrantySearchActive]);

  const handleTextFieldSearchFocus = () => {
    textFieldSearchWarranty.current?.focus();
    textFieldSearchWarranty.current?.select();
  };

  const getUser = (body) => {
    return agent.Users.getUser({ ...body }).then(
      (response) => handleGetUserSuccess(response.data),
      (error) => handleGetUserError(error),
    );
  };

  const handleGetUserSuccess = (data) => {
    dispatch({ type: "USER_LOGIN", ...data[0], id: userId, token: userToken });
    i18n.changeLanguage(data.at(0).lang);
    document.documentElement.setAttribute("lang", data.at(0).lang);
    setDocumentTitle(t);
    agent.Conversations.conversationsCount({
      id: userId,
      token: userToken,
    }).then(
      ({ data }) => {
        dispatch({ type: "USER_NOTIFICATION", notificationCount: data[0].cnt });
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
    agent.App.getConstants({ token: userToken, id: userId })
      .then(
        (response) => handleGetConstantsSuccess(response.data),
        (error) => handleGetConstantsError(error),
      )
      .finally(() => setLoadingApp(false));
  };

  const handleGetUserError = (error) => {
    setLoadingApp(false);
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
  };

  const handleGetConstantsSuccess = (data) => {
    const constants = {};
    data.forEach((item) => {
      if (typeof constants[item.name] === "undefined") constants[item.name] = {};
      constants[item.name][item.intVal] = { strVal: item.strVal, bgColor: item.bgColor };
      if (typeof constants[item.name + "Arr"] === "undefined") constants[item.name + "Arr"] = [];
      constants[item.name + "Arr"].push({ intVal: item.intVal, strVal: item.strVal, bgColor: item.bgColor });
    });
    dispatch({ type: "APP_SETCONSTANTS", constants });
  };

  const handleGetConstantsError = (error) => {
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
  };

  const handleLogoutClick = () => {
    const handleLogoutSuccess = () => {
      dispatch({ type: "USER_LOGOUT" });
      dispatch({
        type: "REPAIR_SET_APPLIEDFILTERS",
        appliedFilters: { isClosed: 0 },
      });
      dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: "all" });
      dispatch({ type: "REPAIR_SET_TABS", tabs: [] });
      navigate("/");
    };

    const handleLogoutError = (error) => {
      dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    };

    const { token, id } = extractIdentity();

    agent.Users.logout({ token: token, id: id }).then(
      () => handleLogoutSuccess(),
      (error) => handleLogoutError(error),
    );
  };

  const handleSearchWarrantySuccess = (response) => {
    if (!response.data.at(0)) throw new Error();
    setSearchWarrantyResult(response.data.at(0));
    setWarrantySearchActive(true);
  };

  const handleSearchWarrantyFailed = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, app.commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const handleSearchWarranty = (value) => {
    setWarrantySearchLoading(true);
    agent.Resources.checkWarranty({ serialNumber: value })
      .then(handleSearchWarrantySuccess, handleSearchWarrantyFailed)
      .catch(() =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t("errors.302"),
          },
        }),
      )
      .finally(() => setWarrantySearchLoading(false));
  };

  useEffect(() => {
    if (isUserLogged()) {
      getUser({ token: userToken, id: userId });
      if (searchParams.get("lang")) setSearchParams("");
    } else {
      setLoadingApp(false);
      if (searchParams.get("lang")) {
        writeCookie({ value: getAndValidateLangFromSearchParam(searchParams.get("lang")), key: "lang" });
        setSearchParams("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- count notifications in actions hub --- //
  const conversationsCount = () => {
    return agent.Conversations.conversationsCount({
      id: user.id,
      token: user.token,
    }).then(
      ({ data }) => {
        dispatch({ type: "USER_NOTIFICATION", notificationCount: data[0].cnt });
      },
      (error) => {
        if (error.response?.data?.messages?.some(({ code }) => UNAUTHORIZED_SESSION_ERROR_CODES.includes(code)))
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!lodash.isEmpty(user)) {
        conversationsCount();
      }
    }, 60000);
    if (lodash.isEmpty(user)) clearInterval(interval);
    return () => clearInterval(interval);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (showReload && waitingWorker) setNewUpdateAvailableModalActive(true);
  }, [waitingWorker, showReload, reloadPage]);

  if (loadingApp) return <PageSpinner />;

  return (
    <div className="all">
      <Navbar
        warrantySearchLoading={warrantySearchLoading}
        logoutCallback={handleLogoutClick}
        handleSearchWarranty={handleSearchWarranty}
        searchWarrantyInput={searchWarrantyInput}
        setSearchWarrantyInput={setSearchWarrantyInput}
        textFieldSearchWarranty={textFieldSearchWarranty}
      />
      <div className="container-fluid">
        <Toast toastList={app.notificationList} position="bottom-right" autoDelete />
        {mobile ? (
          <div>
            <NavbarMobile
              warrantySearchLoading={warrantySearchLoading}
              logoutCallback={handleLogoutClick}
              handleSearchWarranty={handleSearchWarranty}
              searchWarrantyInput={searchWarrantyInput}
              setSearchWarrantyInput={setSearchWarrantyInput}
            />
          </div>
        ) : (
          <div className="main-div">
            <LeftPanel logoutCallback={handleLogoutClick} />
          </div>
        )}
        <div className="application__content">
          <Routes />
          <Footer />
          <CommonApiErrors />

          {0 ? (
            <div
              style={{
                position: "fixed",
                left: "5px",
                top: debugTop ? "5px" : "",
                bottom: debugTop ? "" : "5px",
                zIndex: "999999",
                backgroundColor: "#FFFF80",
                padding: "5px",
                border: "1px solid silver",
                borderRadius: "5px",
              }}
              onDoubleClick={() => {
                setDebugTop(!debugTop);
              }}
            >
              <div
                style={{ cursor: "pointer", fontSize: "20px", lineHeight: "20px", marginBottom: "5px" }}
                onClick={() => {
                  setDebugShow(!debugShow);
                }}
              >
                {debugShow ? debugTop ? <span>&uarr;</span> : <span>&darr;</span> : debugTop ? <span>&darr;</span> : <span>&uarr;</span>}
              </div>
              <button
                onClick={() => {
                  dispatch({ type: "APP_CLEARCONSTANTS" });
                }}
              >
                CC
              </button>
              {debugShow ? (
                <>
                  <VarDump name="user" data={user} />
                  <VarDump name="registration" data={registration} />
                  <VarDump name="app.commonApiErrors" data={app.commonApiErrors} />
                  <VarDump name="cookies" data={document.cookie.split("; ")} />
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <CookiesSticker />
      <NewUpdateAvailableModal active={newUpdateAvailableModalActive} setActive={setNewUpdateAvailableModalActive} reload={reloadPage} />
      <WarrantySearchResultModal active={warrantySearchActive} setActive={setWarrantySearchActive} searchWarrantyResult={searchWarrantyResult} />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...">
      <App />
    </Suspense>
  );
}
