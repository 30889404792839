export const additionalProductDetails = ["factoryResetAgreement", "faultDescription", "equipmentDescription", "registrationDate", "registrationAuthorName"];

export const productDetailsInOrder = ["serialNumber", "description", "producerId", "sellDate", "warrantyLength", "warrantyEnd", "warrantyUnder"];

export const invoiceDataOrder = ["companyName", "street", "postCode", "taxNumber", "countryId"];

export const repairSetDetailsOptionals = {
  true: [
    "repairType",
    "repairKind",
    "repairState",
    "technicianId",
    "isInQueue",
    "suggestedRepairDate",
    "invoiceNumber",
    "shippingNoticeNumber",
    "producerRepairNumber",
    "rentInfo",
    "delegationCount",
  ],
  false: ["repairType", "repairKind"],
};

export const repairSetReplacementOptionals = ["serialNumber", "partNumber", "description", "producerId", "document", "sellDate", "warrantyLength"];

export const externalFormPropertyNames = {
  false: ["extSerialNumber", "extPartNumber", "extDescription", "extProducerId", "extDocument", "extSellDate", "extWarrantyLength"],
  true: ["serialNumber", "partNumber", "description", "producerId", "document", "sellDate", "warrantyLength"],
};

export const repairDetailsForClient = [
  "repairType",
  "repairKind",
  "repairState",
  "isInQueue",
  "suggestedRepairDate",
  "endDate",
  "shippingNoticeNumber",
  "invoiceNumber",
];

export const userResultHeadings = {
  false: ["", "companyName", "taxNumber", "fullName", "email"],
  true: ["", "fullName", "email"],
};

export const productDetailsWithNullableValues = ["factoryResetAgreement"];
