import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import DescriptionList from "stories/components/DescriptionList/DescriptionList";
import Modal from "stories/components/Modal/Modal";
import { formatWarrantyDescription, formatWarrantyDetails } from "utils/global-functions";

const WarrantySearchResultModal = ({ active, setActive, searchWarrantyResult }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((store) => store);

  if (!active) return;

  return (
    <Modal
      title={t("loginPage.foundProduct")}
      onClose={() => setActive(false)}
      footerActions={
        <Button
          variant="primary"
          onClick={() => {
            navigate(`/serial-number/${encodeURIComponent(searchWarrantyResult.serialNumber)}`);
            setActive(false);
          }}
        >
          {t("serialNumber.productCard")}
        </Button>
      }
    >
      <DescriptionList
        list={formatWarrantyDetails(searchWarrantyResult, user).map(([key, value]) => {
          return { term: t(`loginPage.${key}`), description: formatWarrantyDescription(key, value, t) };
        })}
      />
    </Modal>
  );
};

export default WarrantySearchResultModal;
