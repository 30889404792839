import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { filterErrorsFromCommonApiErrors, getErrorForInputField } from "utils/global-functions";
import { externalFormPropertyNames } from "./config";

const AddExternalForm = ({ values, setFieldValue, handleBlur, touched, formErrors, errors, noExtInNames = false, withoutSerialNumber = false }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants, commonApiErrors },
  } = useSelector((store) => store);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();

  const getProductsList = lodash.debounce(async (input, callback) => {
    try {
      const response = await agent.Resources.getProductsList({ id: user.id, token: user.token, filter: input });
      setProductList(response.data);
      callback(
        response.data.map(({ partNumber }) => {
          return { label: partNumber, value: partNumber };
        }),
      );
    } catch (error) {
      dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
      filteredErrors.forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
    }
  }, 1000);

  return (
    <div className="repair-info-form">
      {!withoutSerialNumber && (
        <TextField
          id={externalFormPropertyNames[noExtInNames].at(0)}
          name={externalFormPropertyNames[noExtInNames].at(0)}
          label={t("global.serialNumber")}
          placeholder={t("addReport.serialNumberPlaceholder")}
          disabled
          value={values[externalFormPropertyNames[noExtInNames].at(0)]}
          onChange={(value) => {
            setFieldValue(externalFormPropertyNames[noExtInNames].at(0), value);
            setFieldValue("serialNumber", value);
          }}
          error={
            getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(0)], touched[externalFormPropertyNames[noExtInNames].at(0)]) ||
            formErrors?.[externalFormPropertyNames[noExtInNames].at(0)]
          }
          onBlur={handleBlur}
        />
      )}
      <SelectField
        async
        cacheOptions
        id={externalFormPropertyNames[noExtInNames].at(1)}
        name={externalFormPropertyNames[noExtInNames].at(1)}
        label={t("addReport.productSymbol")}
        placeholder={t("addReport.productSymbolPlaceholder")}
        value={values[externalFormPropertyNames[noExtInNames].at(1)]}
        promiseOptions={getProductsList}
        onChange={(value) => {
          setFieldValue(externalFormPropertyNames[noExtInNames].at(1), value);
          const foundProduct = productList.find(({ partNumber }) => partNumber === value);
          if (foundProduct) {
            setFieldValue(externalFormPropertyNames[noExtInNames].at(3), foundProduct.producerId || "");
            setFieldValue(externalFormPropertyNames[noExtInNames].at(2), foundProduct.description || "");
          }
        }}
        error={getErrorForInputField(errors.partNumber, touched.partNumber) || formErrors?.partNumber}
        onBlur={handleBlur}
      />
      <TextField
        id={externalFormPropertyNames[noExtInNames].at(2)}
        name={externalFormPropertyNames[noExtInNames].at(2)}
        label={t("addReport.productDescription")}
        placeholder={t("addReport.productDescriptionPlaceholder")}
        maxLength={200}
        value={values[externalFormPropertyNames[noExtInNames].at(2)]}
        onChange={(value) => setFieldValue(externalFormPropertyNames[noExtInNames].at(2), value)}
        error={
          getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(2)], touched[externalFormPropertyNames[noExtInNames].at(2)]) ||
          formErrors?.[externalFormPropertyNames[noExtInNames].at(2)]
        }
        onBlur={handleBlur}
      />
      <SelectField
        id={externalFormPropertyNames[noExtInNames].at(3)}
        label={t("global.producer")}
        placeholder={t("addReport.producerPlaceholder")}
        value={values[externalFormPropertyNames[noExtInNames].at(3)]}
        onChange={(value) => setFieldValue(externalFormPropertyNames[noExtInNames].at(3), value)}
        options={constants.producersArr?.map(({ intVal, strVal }) => {
          return { value: intVal, label: strVal };
        })}
        error={
          getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(3)], touched[externalFormPropertyNames[noExtInNames].at(3)]) ||
          formErrors?.[externalFormPropertyNames[noExtInNames].at(3)]
        }
        onBlur={handleBlur}
      />
      <TextField
        id={externalFormPropertyNames[noExtInNames].at(4)}
        name={externalFormPropertyNames[noExtInNames].at(4)}
        label={t("global.invoice")}
        placeholder={t("addReport.invoicePlaceholder")}
        value={values[externalFormPropertyNames[noExtInNames].at(4)]}
        onChange={(value) => setFieldValue(externalFormPropertyNames[noExtInNames].at(4), value)}
        error={
          getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(4)], touched[externalFormPropertyNames[noExtInNames].at(4)]) ||
          formErrors?.[externalFormPropertyNames[noExtInNames].at(4)]
        }
        onBlur={handleBlur}
      />
      <DatePicker
        label={t("addReport.sellDate")}
        placeholder={t("addReport.sellDatePlaceholder")}
        openDirection="down"
        language={user.lang}
        date={values[externalFormPropertyNames[noExtInNames].at(5)]}
        onChange={(value) => setFieldValue(externalFormPropertyNames[noExtInNames].at(5), value)}
        error={
          getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(5)], touched[externalFormPropertyNames[noExtInNames].at(5)]) ||
          formErrors?.[externalFormPropertyNames[noExtInNames].at(5)]
        }
        onBlur={handleBlur}
      />
      <TextField
        id={externalFormPropertyNames[noExtInNames].at(6)}
        name={externalFormPropertyNames[noExtInNames].at(6)}
        variant="minimalistNumber"
        min={1}
        label={t("addReport.warrantyLength")}
        placeholder={t("addReport.warrantyLengthPlaceholder")}
        value={values[externalFormPropertyNames[noExtInNames].at(6)]}
        onChange={(value) => setFieldValue(externalFormPropertyNames[noExtInNames].at(6), value)}
        error={
          getErrorForInputField(errors[externalFormPropertyNames[noExtInNames].at(6)], touched[externalFormPropertyNames[noExtInNames].at(6)]) ||
          formErrors?.[externalFormPropertyNames[noExtInNames].at(6)]
        }
        onBlur={handleBlur}
      />
    </div>
  );
};

export default AddExternalForm;
