import moment from "moment";
import { DEFAULT_LANGUAGE, LINKS_TO_STORES, SUPPORTED_LANGUAGES, WARRANTY_KEYS_WITH_BOOLEAN_VALUES } from "./config";
import { WARRANTY_DETAILS_EXCLUDED_FOR_USER } from "utils/config";

const getExpireDate = (days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  return `expires=${date.toUTCString()}`;
};

export const writeCookie = ({ key, value, days = 30, sameSite = "Strict" }) =>
  (document.cookie = `${key}=${value};${getExpireDate(days)};SameSite=${sameSite};path=/`);

export const readCookie = (key) =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith(key))
    ?.split("=")[1];

export const readDottedComponent = (value, index) => {
  if (typeof value === "undefined" || value === null) return null;
  const values = value.split(".");
  if (values.length > index) return values[index];
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=;Max-Age=0;SameSite=Strict;path=/";
};

export const removeEmptyValuesFromObject = (object) => Object.fromEntries(Object.entries(object).filter(([, value]) => value === 0 || !!value));

export const isClient = (user) => user.role === "customer";
export const isPriv = (user) => user.accType === "priv";
export const isAdmin = (user) => user.role === "admin";
export const isReception = (user) => user.role === "reception";
export const isTechnician = (user) => user.role === "technician";
export const isCorpUser = (user) => user.accType === "corp";

export const isEmployee = (user) => isAdmin(user) || isReception(user) || isTechnician(user) || user.companyId === 1;

export const getErrorForInputField = (error, touched) => {
  if (error && touched) return error;
  return null;
};

export const preventDefaultOnEnterKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const getLanguageFromCookie = () => {
  const lang = readCookie("lang");

  if (SUPPORTED_LANGUAGES.includes(lang)) return lang;

  return DEFAULT_LANGUAGE;
};

export const filterErrorsFromCommonApiErrors = (error, codeList) =>
  error?.messages ? error.messages.filter((message) => !codeList.includes(message.code)) : [];

export const sortAscendingOrder = (a, b) => {
  const formatedA = typeof a === "string" ? a.toUpperCase() : a;
  const formatedB = typeof b === "string" ? b.toUpperCase() : b;

  if (formatedA < formatedB) return -1;
  if (formatedA > formatedB) return 1;
  return 0;
};

export const sortDescendingOrder = (a, b) => {
  const formatedA = typeof a === "string" ? a.toUpperCase() : a;
  const formatedB = typeof b === "string" ? b.toUpperCase() : b;

  if (formatedA > formatedB) return -1;
  if (formatedA < formatedB) return 1;
  return 0;
};

export const sortDatesDescendingOrder = (a, b) => {
  if (moment(a).isBefore(moment(b))) return 1;
  if (moment(a).isAfter(moment(b))) return -1;
  return 0;
};

export const formatBytes = (bytes) => {
  const formatedBytes = bytes / 1024;
  return formatedBytes < 1024 ? `${formatedBytes.toFixed(2)} kB` : `${(formatedBytes / 1024).toFixed(2)} MB`;
};

export const convertDataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) u8arr[n] = bstr.charCodeAt(n);

  return new File([u8arr], `${filename}.${mime.split("/").at(1)}`, { type: mime });
};

export const isElementOverflown = (element) => {
  return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth;
};

export const calculateSpaceBetweenTwoElements = (element1, element2) => {
  const element1Position = element1?.offsetLeft + element1?.getBoundingClientRect()?.width;
  const element2Position = element2?.offsetLeft;

  return element2Position - element1Position;
};

export const formatWarrantyDescription = (key, value, t) => {
  if (WARRANTY_KEYS_WITH_BOOLEAN_VALUES.includes(key) && value.toString() === "0") return t("global.false");
  if (WARRANTY_KEYS_WITH_BOOLEAN_VALUES.includes(key) && value.toString() === "1") return t("global.true");
  if (key === "warrantyLength")
    return `${value} ${counterVariation(value, t("global.month").toLowerCase(), t("global.months234").toLowerCase(), t("global.months05p").toLowerCase())}`;

  return value;
};

export const counterVariation = (value, string1, string234, string05p) => {
  if (value === 0) return string05p;
  if (value === 1) return string1;
  if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 12 || value % 100 > 14)) return string234;
  return string05p;
};

export const setDocumentTitle = (t) => {
  document.title = `${process.env.REACT_APP_ENVIRONMENT !== "production" ? `(${process.env.REACT_APP_ENVIRONMENT}) ` : ""}${t("global.appTitle")}`;
};

export const setLangLinks = (lang, link) => LINKS_TO_STORES[lang][link];

export const isDevelopmentEnvironment = () => process.env.REACT_APP_ENVIRONMENT !== "production";

export const formatWarrantyDetails = (warrantyDetails, user = {}) => {
  const formatedDetails = isEmployee(user)
    ? Object.entries(warrantyDetails)
    : Object.entries(warrantyDetails).filter(([key]) => !WARRANTY_DETAILS_EXCLUDED_FOR_USER.includes(key));

  if (!formatedDetails.warrantyUnder) return formatedDetails.filter(([key]) => key !== "warrantyEnd");

  return formatedDetails;
};
