import React from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { NavLink } from "react-router-dom";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { routesIcons } from "./config";

const LeftPanelItem = ({ isHidden, routeName, url, closeNavMobile, animationVisible }) => {
  const { user } = useSelector((store) => store);

  if (isHidden)
    return (
      <Tooltip allowHtml content={routeName} direction="right" zIndex={999999}>
        <NavLink
          to={url}
          onClick={() => {
            if (closeNavMobile) closeNavMobile();
          }}
          className={`panel__item--small ${animationVisible ? "animation-big-small" : ""}`}
        >
          <img src={routesIcons.get(url)} className="no-select panel__item--icon" alt="button" />
          <span className={isHidden ? "span-hidden" : "span"}>{routeName}</span>
        </NavLink>
      </Tooltip>
    );

  return (
    <NavLink
      to={url}
      onClick={() => {
        if (closeNavMobile) closeNavMobile();
      }}
      className={`panel__item ${animationVisible ? "animation-small-big" : ""}`}
    >
      <img src={routesIcons.get(url)} className="no-select panel__item--icon" alt="button" />
      <span className={isHidden ? "span-hidden" : "span"}>{routeName}</span>
      <MediaQuery maxWidth={850}>
        {url === "/actions" && user.notificationCount ? <div className="mobile-account-actions-count-circle">{user.notificationCount}</div> : null}
      </MediaQuery>
    </NavLink>
  );
};

export default LeftPanelItem;
