import React, { forwardRef } from "react";
import Search from "images/Search.svg";
import { getClassNamesForSearchButton, getClassNamesForTextField } from "./utils";

const SearchField = forwardRef(
  (
    { id, disabled = false, name, placeholder, value, onChange = () => {}, onClick, loading, specialActions = null, preventDefaultActive = false, ...rest },
    ref,
  ) => (
    <div className="input--special-actions">
      <div className={`input_searchbar__wrapper input_searchbar ${getClassNamesForTextField(disabled)}`}>
        <input
          id={id}
          disabled={disabled || loading}
          name={name}
          className="input_searchbar__field"
          placeholder={placeholder}
          value={value}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onClick();
              if (preventDefaultActive) {
                ref.current?.select();
                event.preventDefault();
              }
            }
          }}
          onChange={(event) => onChange(event.target.value)}
          {...rest}
          ref={ref}
          type="search"
        />
        <button type="button" className={`input_searchbar__button no-select ${getClassNamesForSearchButton(disabled)}`} onClick={() => onClick()}>
          {loading ? (
            <div
              className="spinner-border no-select"
              role="status"
              style={{ width: "2rem", height: "2rem", borderWidth: "0.25rem", color: "var(--primary-color)" }}
            />
          ) : (
            <img src={Search} alt="Search" className="input_searchbar__image no-select" />
          )}
        </button>
      </div>
      {specialActions}
    </div>
  ),
);

export default SearchField;
