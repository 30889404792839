import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import agent from "api/agent";
import info from "images/info.svg";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import CancelAddReportConfirmationModal from "./CancelAddReportConfirmationModal";
import "./styles/AddReport.css";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { showTooltipInfoForAddProductsStep } from "./utils";

export const AddReportFooter = ({
  backAction,
  backActionLabel,
  continueAction,
  continueActionDisabled,
  continueActionLabel,
  continueActionLoading,
  productFullList,
  currentStep,
}) => {
  const { t } = useTranslation();
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelModalActive, setCancelModalActive] = useState(false);
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);

  const cancelRegistrationSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "REGISTRATION_CANCEL" });
      return navigate("/");
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const cancelRegistrationError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
    setCancelModalActive(false);
  };

  const cancelRegistration = () => {
    setCancelLoading(true);
    return agent.Registration.registrationCancel({ id: user.id, token: user.token, registrationId: registration.registrationId })
      .then(cancelRegistrationSuccess, cancelRegistrationError)
      .finally(() => setCancelLoading(false));
  };

  return (
    <>
      {desktop && <div className="add-report__border add-report__border--full" />}
      <div className="add-report__footer">
        <Button
          disabled={continueActionLoading}
          loading={cancelLoading}
          onClick={() => setCancelModalActive(true)}
          variant="error"
          size={desktop ? "default" : "small"}
        >
          {t("addReport.deleteReport")}
        </Button>
        <Stack gap="1rem">
          {backActionLabel && (
            <Button disabled={cancelLoading || continueActionLoading} onClick={() => backAction()} size={desktop ? "default" : "small"}>
              {backActionLabel}
            </Button>
          )}
          {continueActionLabel && (
            <>
              {showTooltipInfoForAddProductsStep(productFullList, currentStep) && (
                <Tooltip content={t("addReport.infoForAddProducts")}>
                  <img src={info} alt="i" />
                </Tooltip>
              )}
              <Button
                loading={continueActionLoading}
                onClick={() => continueAction()}
                disabled={continueActionDisabled || cancelLoading}
                variant="primary"
                size={desktop ? "default" : "small"}
              >
                {continueActionLabel}
              </Button>
            </>
          )}
        </Stack>
        <CancelAddReportConfirmationModal
          active={cancelModalActive}
          onClose={() => setCancelModalActive(false)}
          loadingAction={cancelLoading}
          onAction={() => cancelRegistration()}
        />
      </div>
    </>
  );
};
