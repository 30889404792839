export const setupErrorHandler = () => {
  if (process.env.NODE_ENV !== "production") return;

  window.addEventListener("error", (error) => {
    if (error.filename && !error.filename.includes(process.env.REACT_APP_URL)) return;

    setTimeout(() => {
      window.location = "/error";
    }, 1000);
  });
};

export const isErrorPage = () => window.location.pathname === "/error";
