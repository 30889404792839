import React from "react";
import { useTranslation } from "react-i18next";
import { sentStateName } from "./config";

const ContactSentInfo = ({ contactOption }) => {
  const { t } = useTranslation();

  if (contactOption !== sentStateName) return;

  return (
    <div className="contact-sent-info">
      <div className="contact-sent-info__head">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_330_534)">
            <circle cx="30" cy="30" r="28.5" stroke="#EA1D28" strokeWidth="3" />
            <path d="M21 30.4828L27.129 37L41 23" stroke="#EA1D28" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_330_534">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span className="contact-sent-info__heading">{t("serialNumber.yourApplicationHasBeenSent")}</span>
      </div>
      <p>{t("serialNumber.completeRequestInfo")}</p>
    </div>
  );
};

export default ContactSentInfo;
