/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import EmployeeSelector from "components/EmployeeSelector/EmployeeSelector";
import Button from "stories/components/Button/Button";
import Dropzone from "stories/components/Dropzone/Dropzone.js";
import TextField from "stories/components/TextField/TextField";
import SelectField from "stories/components/SelectField/SelectField";
import { CONVERSATION_EXTRAS, CONVERSATION_INTERNAL, MAX_MOBILE_WIDTH, REPAIR_STATES_START_OF_CLOSED_STATES } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import {
  getConversationActionType,
  getConversationRecipient,
  getConversationType,
  getPlaceholderForMessageField,
  getRestBodyForSenMessageRequest,
  isSendMessageFieldDisabled,
  showExtrasSelector,
} from "./utils";
import ParentMessage from "./ParentMessage";
import EditMessageMode from "./EditMessageMode";

const SendMessageField = ({
  conversationList,
  editMessageActive,
  setEditMessageActive,
  selectedConversationId,
  setSelectedConversationId,
  responseMessageActive,
  setResponseMessageActive,
  converstionGroupType,
  refreshConversation,
  setRefreshConversation,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
    repair: { repairDetails },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const sendMessageInput = useRef(null);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const selectedConversation = conversationList.find(({ id }) => id === selectedConversationId);
  const [messageInputText, setMessageInputText] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState("");
  const [sendFileActive, setSendFileActive] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [keepAttachmentsIds, setKeepAttachmentsIds] = useState(selectedConversation?.attachments.map(({ id }) => id) || []);
  const [saveMessageLoading, setSaveMagessageLoading] = useState(false);
  const [selectedConversationExtras, setSelectedConversationExtras] = useState([]);

  useEffect(() => {
    if (editMessageActive || responseMessageActive) {
      setMessageInputText(responseMessageActive ? "" : selectedConversation?.text || "");
      setSelectedRecipient(editMessageActive ? selectedConversation?.recipientId : selectedConversation?.authorId || "");
      setKeepAttachmentsIds(selectedConversation?.attachments.map(({ id }) => id) || []);
      sendMessageInput.current.focus();
    } else {
      setKeepAttachmentsIds([]);
      setMessageInputText("");
      setSelectedRecipient("");
    }
    setSendFileActive(false);
    setAttachments([]);
  }, [editMessageActive, responseMessageActive, selectedConversationId]);

  useEffect(() => {
    if (!!selectedConversationId && !responseMessageActive) {
      const selectedConversationExtras = conversationList?.find(({ id }) => id === selectedConversationId)?.extras;
      if (selectedConversationExtras) setSelectedConversationExtras(selectedConversationExtras || []);
    } else setSelectedConversationExtras([]);
  }, [selectedConversationId]);

  const saveMessageSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setMessageInputText("");
      setSelectedConversationExtras([]);
      setSelectedConversationId(null);
      setResponseMessageActive(false);
      setEditMessageActive(false);
      setRefreshConversation(!refreshConversation);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const saveMessageError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const saveMessage = (value) => {
    setSaveMagessageLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      action: getConversationActionType(editMessageActive),
      repairId: repairDetails.repairId,
      conversationType: getConversationType(responseMessageActive, converstionGroupType, selectedConversationId, conversationList),
      conversationText: value,
      attachments: attachments,
      keepAttachmentsIds: JSON.stringify(editMessageActive ? keepAttachmentsIds : []),
      ...getConversationRecipient(selectedRecipient, responseMessageActive, selectedConversationId, conversationList, repairDetails),
      ...getRestBodyForSenMessageRequest(responseMessageActive, editMessageActive, selectedConversationId, conversationList, selectedConversationExtras),
    })
      .then(saveMessageSuccess, saveMessageError)
      .finally(() => setSaveMagessageLoading(false));
  };

  return (
    <div className="conversation__searchfield-wrapper">
      <ParentMessage
        conversationList={conversationList}
        selectedConversationId={selectedConversationId}
        responseMessageActive={responseMessageActive}
        setSelectedConversationId={setSelectedConversationId}
        setResponseMessageActive={setResponseMessageActive}
      />
      <EditMessageMode
        conversationList={conversationList}
        selectedConversationId={selectedConversationId}
        setSelectedConversationId={setSelectedConversationId}
        editMessageActive={editMessageActive}
        setEditMessageActive={setEditMessageActive}
      />
      <div className="conversation__bottom">
        <div className="conversation__input-wrapper">
          {converstionGroupType === CONVERSATION_INTERNAL && (
            <EmployeeSelector
              disabled={responseMessageActive || repairDetails.repairState >= REPAIR_STATES_START_OF_CLOSED_STATES}
              value={selectedRecipient}
              onChange={setSelectedRecipient}
              size="default"
              placeholder={t("global.chooseRecipient")}
            />
          )}
          <TextField
            className="conversation__input"
            ref={sendMessageInput}
            loading={saveMessageLoading}
            value={messageInputText}
            onChange={setMessageInputText}
            disabled={isSendMessageFieldDisabled(user, responseMessageActive, repairDetails, converstionGroupType, selectedRecipient, editMessageActive)}
            onClick={() => saveMessage(messageInputText)}
            variant="multiSend"
            numberOfLines={6}
            disableResize={false}
            placeholder={t(
              `global.${getPlaceholderForMessageField(user, conversationList, responseMessageActive, repairDetails, converstionGroupType, editMessageActive)}`,
            )}
            buttonContent={t("global.send")}
          />
          <Button
            outline
            disabled={isSendMessageFieldDisabled(user, responseMessageActive, repairDetails, converstionGroupType, selectedRecipient, editMessageActive)}
            size={mobile ? "small" : "default"}
            variant={sendFileActive ? "error" : "primary"}
            className="conversation-message__attachments-button"
            onClick={() => {
              setSendFileActive(!sendFileActive);
              !sendFileActive && setAttachments([]);
            }}
          >
            {sendFileActive ? t("global.cancel") : t("global.attachFiles")}
          </Button>
          {showExtrasSelector(
            user,
            converstionGroupType,
            responseMessageActive,
            selectedConversationId,
            editMessageActive,
            repairDetails,
            conversationList,
          ) && (
            <SelectField
              multi
              menuPortalTarget={document.body}
              placeholder={t("myRepairs.extrasOptionsSelectorPlaceholder")}
              isSearchable={false}
              closeMenuOnSelect={false}
              disabled={isSendMessageFieldDisabled(user, responseMessageActive, repairDetails, converstionGroupType, selectedRecipient, editMessageActive)}
              value={selectedConversationExtras}
              onChange={(value) => setSelectedConversationExtras(value)}
              options={CONVERSATION_EXTRAS.map((value) => {
                return { value: value, label: t(`myRepairs.${value}`) };
              })}
            />
          )}
        </div>
        {!!keepAttachmentsIds?.length && editMessageActive && (
          <>
            <span className="paragraph--small">{t("global.uploadedAttachments")}</span>
            <div className="pricing__keep-attachments">
              {keepAttachmentsIds.map((attachmentId) => {
                if (!selectedConversation?.attachments.length) return null;
                const attachment = selectedConversation?.attachments?.find(({ id }) => id === attachmentId);
                return (
                  <div className="pricing__keep-attachment" key={attachmentId}>
                    <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                      {attachment.fileName}
                    </a>
                    <button className="unset-all custom-link" onClick={() => setKeepAttachmentsIds(keepAttachmentsIds.filter((id) => id !== attachmentId))}>
                      {t("global.delete")}
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {sendFileActive && <Dropzone files={attachments} addFilesValue={(value) => setAttachments(value)} />}
      </div>
    </div>
  );
};

export default SendMessageField;
