import React from "react";
import { useTranslation } from "react-i18next";
import image1 from "images/sn1.png";
import image2 from "images/sn2.png";
import image3 from "images/sn3.png";
import image4 from "images/sn4.png";
import image5 from "images/sn5.png";
import image6 from "images/sn6.png";
import "./styles.css";

export const HelpSerialNumber = ({ inModal }) => {
  const { t } = useTranslation();

  return (
    <div>
      {!inModal && <h2 className="heading--6">{t("loginPage.serialNumberQuestion")}</h2>}
      <div>{t("help.serialNumberContent")}</div>

      <div className="help-serial-number-images">
        <div
          id="carouselExampleControls"
          className="help-carousel carousel slide"
          data-bs-ride="carousel"
          data-bs-interval={8000}
          data-bs-pause="hover"
          data-bs-touch={true}
        >
          <div className="carousel-inner mt-3" style={{ borderRadius: "1rem" }}>
            <div className="carousel-item active">
              <img src={image3} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={image1} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={image5} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={image2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={image4} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={image6} className="d-block w-100" alt="..." />
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};
