import React from "react";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";

const Delivery = () => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  return mobile ? <Mobile /> : <Desktop />;
};

export default Delivery;
