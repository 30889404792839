import { deleteCookie, writeCookie } from "utils/global-functions";
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      writeCookie({ key: "checksum", value: window.btoa(`${action.id}:${action.token}`.split("").reverse().join("")) });
      return {
        ...state,
        id: action.id,
        token: action.token,
        email: action.email,
        personName: action.personName,
        phone: action.phone,
        taxNumber: action.taxNumber,
        companyName: action.companyName,
        companyId: action.companyId,
        street: action.street,
        postCode: action.postCode,
        city: action.city,
        countryId: action.countryId,
        role: action.role,
        roleId: action.roleId,
        lang: action.lang,
        accType: action.accType,
      };
    case "USER_LOGOUT":
      deleteCookie("checksum");
      return {};
    case "USER_SETLANG":
      if (Object.keys(state).length) return { ...state, lang: action.lang };
      else return state;
    case "USER_NOTIFICATION":
      return { ...state, notificationCount: action.notificationCount };
    default:
      return state;
  }
};

export default userReducer;
