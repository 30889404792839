import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MAX_PRODUCTS_QUANTITY_TO_SHOW_INITIALLY } from "utils/config";
import Accordion from "stories/components/Accordion/Accordion";
import { AddReportHeading } from "../AddReportHeading";
import { AddReportFooter } from "../AddReportFooter";
import ContactInfo from "./ContactInfo";
import DeliveryInfo from "./DeliveryInfo";
import ProductList from "./ProductList";
import ReturnInfo from "./ReturnInfo";

const DisplayDesktop = ({ confirmRegistrationLoading, confirmRegistration, summaryDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="add-report">
      <AddReportHeading />
      <div className="add-report__sides-wrapper">
        <div className="add-report__side-panel">
          <Accordion
            content={[
              {
                title: <span className="paragraph--small text--bold">{t("addReport.products")}</span>,
                body: <ProductList summaryDetails={summaryDetails} />,
                active: summaryDetails?.products?.length <= MAX_PRODUCTS_QUANTITY_TO_SHOW_INITIALLY,
              },
            ]}
          />
        </div>
        <div className="add-report__side-border" />
        <div className="add-report__side-panel">
          <Accordion
            content={[
              {
                title: <span className="paragraph--small text--bold">{t("addReport.deliveryMethod")}</span>,
                body: <DeliveryInfo summaryDetails={summaryDetails} />,
              },
              {
                title: <span className="paragraph--small text--bold">{t("addReport.pickupMethod")}</span>,
                body: <ReturnInfo summaryDetails={summaryDetails} />,
              },
              {
                title: <span className="paragraph--small text--bold">{t("addReport.contactData")}</span>,
                body: <ContactInfo summaryDetails={summaryDetails} />,
              },
            ]}
          />
        </div>
      </div>
      <AddReportFooter
        backActionLabel={t("global.back")}
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 3 })}
        continueActionLabel={t("addReport.sendReport")}
        continueActionLoading={confirmRegistrationLoading}
        continueAction={() => confirmRegistration()}
      />
    </div>
  );
};

export default DisplayDesktop;
