/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TextField from "stories/components/TextField/TextField";
import Page from "stories/components/Page/Page";
import Button from "stories/components/Button/Button";
import agent from "api/agent";
import SelectField from "stories/components/SelectField/SelectField";
import DatePicker from "stories/components/DatePicker/DatePicker";
import Stack from "stories/components/Stack/Stack";

const ExternalSerialNumber = () => {
  const user = useSelector((store) => store.user);
  const app = useSelector((store) => store.app);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t } = useTranslation();

  const [esn, setEsn] = useState("");
  const [esnData, setEsnData] = useState([]);
  const [editedIndex, setEditedIndex] = useState(null);
  const [esnError, setEsnError] = useState(true);
  const [esnErrors, setEsnErrors] = useState({
    serialNumber: "",
    partNumber: "",
    description: "",
    producerId: "",
    document: "",
    sellDate: "",
    warrantyLength: "",
  });
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (!fetching) inputRef.current.select();
  }, [fetching]);

  const handleEsnChange = (value, name) => {
    setEsn(value);
    setEsnData([]);
    setEsnError(value.length >= 3 ? false : true);
  };

  const handleEsnFormChange = (value, name) => {
    const obj = [...esnData];
    obj[editedIndex][name] = name !== "sellDate" ? value : value !== null ? value.format("YYYY-MM-DD") : "";
    setEsnData(obj);
  };

  const handleSetEditedIndex = (index) => {
    const errors = { ...esnErrors };
    Object.keys(errors).forEach((key) => {
      errors[key] = "";
    });
    setEsnErrors(errors);
    setEditedIndex(index);
  };

  const externalSerialNumberGet = () => {
    const errors = { ...esnErrors };
    Object.keys(errors).forEach((key) => {
      errors[key] = "";
    });
    setEsnErrors(errors);
    setFetching(true);
    setEditedIndex(null);
    agent.Resources.externalSerialNumber({ id: user.id, token: user.token, externalSerialNumber: esn, action: "get" }).then(
      (response) => {
        setFetching(false);
        setEsnData(response.data);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "externalSerialNumberGet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const externalSerialNumberSet = () => {
    const postData = {
      action: "set",
      externalSerialNumber: esn,
      serialNumberType: esnData[editedIndex].serialNumberType,
      repairId: esnData[editedIndex].repairId,
      partNumber: esnData[editedIndex].partNumber,
      description: esnData[editedIndex].description,
      producerId: esnData[editedIndex].producerId,
      document: esnData[editedIndex].document,
      sellDate: esnData[editedIndex].sellDate,
      warrantyLength: esnData[editedIndex].warrantyLength,
    };
    setFetching(true);
    agent.Resources.externalSerialNumber({ id: user.id, token: user.token, ...postData }).then(
      (response) => {
        setFetching(false);
        if (response.result === 301) {
          externalSerialNumberGet();
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "info", description: "externalSerialNumberSet.success" } });
        }
      },
      (error) => {
        const errors = { ...esnErrors };
        Object.keys(errors).forEach((key) => {
          errors[key] = "";
        });
        if (typeof error.response.data.messages !== "undefined")
          error.response.data.messages.forEach((item) => {
            if (item.property) errors[item.property] = item.code;
          });
        setEsnErrors(errors);
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "externalSerialNumberSet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  return (
    <Page heading={<div className="heading--3">{t("panel.externalSerial")}</div>}>
      <div className="page-content">
        {!["reception", "admin"].includes(user.role) ? (
          <h1>Access denied!</h1>
        ) : (
          <Stack justifyContent="flex-start" alignItems="flex-start" gap="3rem">
            <div style={{ flex: 2 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!esnError) externalSerialNumberGet();
                }}
              >
                <TextField
                  ref={inputRef}
                  name="esn"
                  label={t("myRepairs.serialNumber")}
                  placeholder={t("loginPage.provideSerialNumber")}
                  value={esn}
                  onChange={handleEsnChange}
                  autoComplete="off"
                  maxLength="50"
                  disabled={fetching}
                />
                <Button className="mt-3 mb-5" variant="primary" disabled={fetching || esnError} onClick={externalSerialNumberGet}>
                  {t("global.search")}
                </Button>
              </form>
              {esnData.length ? (
                <div>
                  {esnData.map((item, index) => (
                    <div
                      key={index}
                      className="mb-5"
                      style={{
                        padding: "1.5rem 1.5rem",
                        border: "0.1rem solid var(--grey-light4)",
                        borderRadius: "4px",
                        color: editedIndex === index ? "" : "silver",
                      }}
                    >
                      <table className="mb-3" style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td className="text--nowrap" style={{ width: "1px" }}>
                              {t("global.serialNumberType")}
                            </td>
                            <td className="ps-3 text--bold">{t(`global.${item.serialNumberType}`)}</td>
                            <td rowSpan={3} className="text--right" style={{ verticalAlign: "top" }}>
                              {editedIndex === index ? (
                                <Button className="mt-3" variant="error" onClick={externalSerialNumberSet} disabled={fetching}>
                                  {t("global.save")}
                                </Button>
                              ) : (
                                <Button
                                  className="mt-3"
                                  variant="default"
                                  size="small"
                                  onClick={() => {
                                    handleSetEditedIndex(index);
                                  }}
                                  disabled={fetching}
                                >
                                  {t("global.edit")}
                                </Button>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text--nowrap" style={{ width: "1px" }}>
                              {t("global.repairState")}
                            </td>
                            <td className="ps-3 text--bold">{t(`constants.repair.state.${app.constants.repairState[1].strVal}`)}</td>
                          </tr>
                          <tr>
                            <td className="text--nowrap" style={{ width: "1px" }}>
                              {t("global.repairNumber")}
                            </td>
                            <td className="ps-3 text--bold">{item.repairId}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mb-2">
                        <TextField
                          name="partNumber"
                          label={t("myRepairs.partNumber")}
                          value={item.partNumber}
                          onChange={handleEsnFormChange}
                          autoComplete="off"
                          maxLength="50"
                          disabled={fetching || editedIndex !== index}
                          error={esnErrors.partNumber && t(`errors.${esnErrors.partNumber}`)}
                        />
                      </div>
                      <div className="mb-2">
                        <TextField
                          name="description"
                          label={t("addReport.productDescription")}
                          value={item.description}
                          onChange={handleEsnFormChange}
                          autoComplete="off"
                          maxLength="200"
                          disabled={fetching || editedIndex !== index}
                          error={esnErrors.description && t(`errors.${esnErrors.description}`)}
                        />
                      </div>
                      <div className="mb-2">
                        <Stack gap="1rem">
                          <SelectField
                            name="producerId"
                            options={
                              typeof app.constants.producersArr === "object"
                                ? app.constants.producersArr.map((item) => {
                                    return { value: item.intVal, label: item.strVal };
                                  })
                                : []
                            }
                            label={t("global.producer")}
                            value={item.producerId}
                            onChange={handleEsnFormChange}
                            disabled={fetching || editedIndex !== index}
                            error={esnErrors.producerId && t(`errors.${esnErrors.producerId}`)}
                          />
                          <TextField
                            name="document"
                            label={t("global.invoice")}
                            value={item.document}
                            onChange={handleEsnFormChange}
                            autoComplete="off"
                            maxLength="50"
                            disabled={fetching || editedIndex !== index}
                            error={esnErrors.document && t(`errors.${esnErrors.document}`)}
                          />
                        </Stack>
                      </div>
                      <div className="mb-2">
                        <Stack gap="1rem">
                          <DatePicker
                            id="sellDate"
                            label={t("addReport.sellDate")}
                            variant="single"
                            date={item.sellDate && moment(item.sellDate)}
                            openDirection="down"
                            language={user.lang}
                            onChange={handleEsnFormChange}
                            disabled={fetching || editedIndex !== index}
                            error={esnErrors.sellDate && t(`errors.${esnErrors.sellDate}`)}
                          />
                          <TextField
                            variant="minimalistNumber"
                            min={0}
                            max={60}
                            name="warrantyLength"
                            label={t("addReport.warrantyLength")}
                            value={item.warrantyLength}
                            onChange={handleEsnFormChange}
                            autoComplete="off"
                            disabled={fetching || editedIndex !== index}
                            error={esnErrors.warrantyLength && t(`errors.${esnErrors.warrantyLength}`)}
                          />
                        </Stack>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div style={{ flex: 1 }}>
              <p>{t("panel.externalSerialExplanation1")}</p>
              <p>{t("panel.externalSerialExplanation2")}</p>
              <p className="text--red text--bold">{t("panel.externalSerialExplanation3")}</p>
              <p>{t("panel.externalSerialExplanation4")}</p>
            </div>
          </Stack>
        )}
      </div>
    </Page>
  );
};

export default ExternalSerialNumber;
