import React from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import Checkbox from "stories/components/Checkbox/Checkbox";
import { handleAllItemsSelection } from "../utils";
import { isAdmin, isReception } from "utils/global-functions";

const BottomActions = ({ selectedItems, setSelectedItems, activeBulkActionModal, setActiveBulkActionModal, repairListLoading }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairsList },
  } = useSelector((store) => store);

  if (!isAdmin(user) && !isReception(user)) return;

  return (
    <div className="mobile-bottom-action">
      <Checkbox
        disabled={!repairsList.length || repairListLoading}
        checked={repairsList.every((receivedRepair) => selectedItems.some((selectedItem) => selectedItem === receivedRepair.repairId))}
        onChange={() => setSelectedItems(handleAllItemsSelection(selectedItems, repairsList))}
        label={t("global.selectAll")}
      />
      <Button size="small" disabled={lodash.isEmpty(selectedItems)} onClick={() => setActiveBulkActionModal(!activeBulkActionModal)}>
        {`${t("global.bulkAction")} (${selectedItems.length}/${repairsList.length})`}
      </Button>
    </div>
  );
};

export default BottomActions;
