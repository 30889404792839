import md5 from "md5";
import moment from "moment";
import lodash from "lodash";
import Button from "stories/components/Button/Button";

export const getNewRegistrationId = () => moment().format("YYYYMMDDhhmmss") + ":" + md5(Math.floor(Math.random() * 10000)).slice(0, 17);

export const isCurrentRegistrationBelongsToDifferentUser = (registration, user) =>
  registration.registrationStep && registration.registrationId !== null && registration.registrationAuthorId !== user.id;

export const getAccordionContentForProductList = (record, headings, constants, index, options = { desktop: false, action: false }) => {
  return {
    title: options.desktop ? (
      <span className="paragraph--small text--subdued">{`${index + 1}. ${record.partNumber} ${record.serialNumber}`}</span>
    ) : (
      <h6 className="heading--6">{`${index + 1}. ${record.partNumber} ${record.serialNumber}`}</h6>
    ),
    body: (
      <div className="added-products">
        <div className="added-products__items-wrapper">
          <div className="added-products__item">
            <span className="paragraph--small text--subdued">{headings.description}</span>
            <span className="paragraph--small">{record.description}</span>
          </div>
          <div className="added-products__item">
            <span className="paragraph--small text--subdued">{headings.producer}</span>
            <span className="paragraph--small">{constants?.producers?.[record.producerId]?.strVal}</span>
          </div>
          <div className="added-products__item">
            <span className="paragraph--small text--subdued">{headings.sellDate}</span>
            <span className="paragraph--small">{record.sellDate}</span>
          </div>
          <div className="added-products__item">
            <span className="paragraph--small text--subdued">{headings.status}</span>
            <span className="paragraph--small">{record.warrantyEnd}</span>
          </div>
          <div className="added-products__item">
            <span className="paragraph--small text--subdued">{headings.factoryResetAgreement}</span>
            <span className="paragraph--small">{record.factoryResetAgreement}</span>
          </div>
          <div className="added-products__items-group">
            <div className="added-products__item">
              <span className="paragraph--small text--subdued">{headings.faultDescription}</span>
              <span className="paragraph--small">{record.faultDescription}</span>
            </div>
            {record.equipmentDescription && (
              <div className="added-products__item">
                <span className="paragraph--small text--subdued">{headings.extraEquipment}</span>
                <span className="paragraph--small">{record.equipmentDescription}</span>
              </div>
            )}
            {!!record.attachments?.length && (
              <div className="added-products__item">
                <span className="paragraph--small text--subdued">{headings.attachments}</span>
                <ul>
                  {record.attachments.map(({ id, fileName }) => (
                    <li key={id}>
                      <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${id}`} rel="noreferrer" target="_blank">
                        {fileName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {options.action && (
          <Button className="add-report__delete-product-button" size="small" variant="error" outline onClick={() => options.action.onClick(record)}>
            {options.action.name}
          </Button>
        )}
      </div>
    ),
  };
};

export const showTooltipInfoForAddProductsStep = (productFullList, currentStep) => lodash.isEmpty(productFullList) && currentStep === 1;
