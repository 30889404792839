import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import PhotosForm from "./PhotosForm";
import { getBodyForPhotosRequest } from "./utils";

const PhotosModal = ({
  photosModalRepairId = null,
  photos,
  active,
  setActive,
  refreshPhotos,
  setRefreshPhotos,
  selectedPhotoId,
  setSelectedPhotoId,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const { repairId } = useParams();
  const finalRepairId = repairId ?? photosModalRepairId;
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});

  const handlePhotoActionSuccess = (response) => {
    if (response.result === 301) {
      setActive(false);
      if (photosModalRepairId) {
        return dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "success",
            description: t(`global.photoSuccess`) + " " + photosModalRepairId,
          },
        });
      }
      setSelectedPhotoId(null);
      setRefreshPhotos(!refreshPhotos);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const handlePhotoActionError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const handlePhotoAction = (values) =>
    agent.Repairs.handleRepairAttachments({
      id: user.id,
      token: user.token,
      ...getBodyForPhotosRequest(selectedPhotoId, finalRepairId),
      ...values,
    }).then(handlePhotoActionSuccess, handlePhotoActionError);

  if (!active) return;

  const selectedPhoto = photos.find(({ id }) => id === selectedPhotoId);

  return (
    <Formik
      initialValues={{ commentText: selectedPhoto?.commentText ?? "", visibilityFlag: selectedPhoto?.visibilityFlag ?? 1, attachments: [] }}
      onSubmit={(values) => handlePhotoAction(values)}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Modal
            title={t(`myRepairs.${selectedPhotoId ? "editPhoto" : "addPhoto"}`)}
            onClose={() => {
              setActive(false);
              !photosModalRepairId && setSelectedPhotoId(null);
            }}
            footerActions={
              <Stack gap="var(--modal-buttons-gap)">
                <Button
                  size={mobile ? "small" : "default"}
                  disabled={isSubmitting}
                  onClick={() => {
                    setActive(false);
                    !photosModalRepairId && setSelectedPhotoId(null);
                  }}
                >
                  {t("global.cancel")}
                </Button>
                <Button size={mobile ? "small" : "default"} loading={isSubmitting} variant="primary" type="submit">
                  {t("global.save")}
                </Button>
              </Stack>
            }
          >
            <PhotosForm formErrors={formErrors} selectedPhotoId={selectedPhotoId} values={values} setFieldValue={setFieldValue} />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PhotosModal;
