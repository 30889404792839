import { isAdmin, isReception } from "utils/global-functions";

export const transformContactValues = (body, newContactData) => {
  if (newContactData) return body;

  return { ...Object.fromEntries(Object.keys(body).map((key) => [key, null])), contactPerson: null };
};

export const isNewContactData = (contactData) => Object.values(contactData).every((contactValue) => !!contactValue);

export const getAccInfoBody = (user, registration) => {
  const body = { id: user.id, token: user.token };
  if (isAdmin(user) || isReception(user)) body.userId = registration.registrationUserId;

  return body;
};
