import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Link from "stories/components/Link/Link";
import { invoiceDataOrder, repairDetailsForClient } from "../config";
import { getReplacementDetails, transformContactKeyToUserKey } from "../utils";
import ClientRepairDescrpiton from "../ClientRepairDescription";

const ClientInfoDetails = ({ productDetailsContent, repairDetailsTermsMap, repairDetailsDescriptionMap, productUrl }) => {
  const { t } = useTranslation();
  const {
    repair: { repairDetails },
    app: { constants },
  } = useSelector((store) => store);

  const productDetails = {
    title: repairDetails.partNumber,
    content: (
      <div>
        {productDetailsContent.map(({ term, description }, index) => (
          <React.Fragment key={index}>
            <span className="paragraph--small text--subdued info-details__term">{term}</span>
            {description}
          </React.Fragment>
        ))}
        <Link href={productUrl} target="_blank" rel="noopener noreferrer" className="mobile-repair-info__product-link custom-link--black">
          <div className="button__content">{t("myRepairs.checkProduct")}</div>
        </Link>
      </div>
    ),
  };

  const repairDetailsInfoDescription = {
    repairType: t(`constants.repair.type.${constants.repairType[repairDetails.repairType]?.strVal}`),
    repairKind: t(`constants.repair.kind.${constants.repairKind[repairDetails.repairKind]?.strVal}`),
    repairState: t(`constants.repair.state.${constants.repairState[repairDetails.repairState]?.strVal}`),
    isInQueue: repairDetails.isInQueue !== null ? t(`global.${!!repairDetails.isInQueue}`) : "-",
  };

  const restDetails = [
    {
      title: t("myRepairs.repairDetails"),
      content: [
        ...repairDetailsForClient?.map((repairDetail) => {
          return { term: t(`global.${repairDetail}`), description: repairDetailsInfoDescription[repairDetail] || repairDetails[repairDetail] || "-" };
        }),
        { term: t("global.descriptionOrJustification"), description: repairDetails.repairDescription || "-" },
      ].map(({ term, description }) => (
        <React.Fragment key={term}>
          <span className="paragraph--small text--subdued info-details__term">{term}</span>
          <p className="paragraph--small info-details__description">{description}</p>
        </React.Fragment>
      )),
    },
    {
      title: t("global.replacementProduct"),
      content: [
        <>
          <span className="paragraph--small text--subdued info-details__term">{t("global.serialNumber")}</span>
          <Tooltip direction="bottom" content={getReplacementDetails(repairDetails, constants, t)}>
            <p className="paragraph--small info-details__description">{repairDetails.replacementSerialNumber || "-"}</p>
          </Tooltip>
        </>,
      ],
    },
    {
      title: t("addReport.dataToInvoice"),
      content: (
        <>
          {invoiceDataOrder
            .map((invoiceDetail) => {
              if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "companyName")
                return { term: t("global.fullName"), description: repairDetails.user.personName };
              if (!repairDetails.invoice[invoiceDetail] && invoiceDetail === "taxNumber") return {};

              return {
                term: repairDetailsTermsMap[invoiceDetail] || t(`global.${invoiceDetail}`),
                description: repairDetailsDescriptionMap[invoiceDetail] || repairDetails.invoice[invoiceDetail] || "-",
              };
            })
            .map(({ term, description }, index) =>
              !term && !description ? null : (
                <React.Fragment key={index}>
                  <span className="paragraph--small text--subdued info-details__term">{term}</span>
                  <p className="paragraph--small info-details__description">{description}</p>
                </React.Fragment>
              ),
            )
            .filter((value) => !!value)}
        </>
      ),
    },
    {
      title: t("global.user"),
      content: (
        <div>
          <span className="paragraph--small text--subdued info-details__term">{t("global.fullName")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.personName}</p>
          <span className="paragraph--small text--subdued info-details__term">{t("global.phoneNumber")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.phone}</p>
          <span className="paragraph--small text--subdued info-details__term">{t("global.emailAddress")}</span>
          <p className="paragraph--small info-details__description">{repairDetails.user.email}</p>
        </div>
      ),
    },
    {
      title: t("global.contactDetails"),
      content: (
        <div>
          {Object.entries(repairDetails)
            .filter(([key]) => key.includes("contact"))
            .map(([key, value], index) => {
              return (
                <React.Fragment key={index}>
                  <span className="paragraph--small text--subdued info-details__term">{t(`myRepairs.${key}`)}</span>
                  <p className="paragraph--small info-details__description">{value || repairDetails.user[transformContactKeyToUserKey(key)]}</p>
                </React.Fragment>
              );
            })}
        </div>
      ),
    },
  ];

  return (
    <div className="info-details">
      <Accordion
        content={[productDetails, ...restDetails].map(({ title, content }) => {
          return {
            title: (
              <h6 key={title} className="info-details__heading">
                {title}
              </h6>
            ),
            body: content,
          };
        })}
      />
      <ClientRepairDescrpiton />
    </div>
  );
};

export default ClientInfoDetails;
