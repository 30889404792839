import React from "react";
import { Link } from "react-router-dom";

const ProductList = ({ productList }) => (
  <div className="add-report-success__product-list">
    {productList.map((product) => (
      <div className="add-report-success__product" key={product.repairId}>
        <Link className="add-report-success__link" to={`/${product.repairId}/info`}>
          {product.repairId}
        </Link>
        <span className="add-report-success__product-serial">{`${product.serialNumber}/${product.partNumber}`}</span>
      </div>
    ))}
  </div>
);
export default ProductList;
