import { calculateSpaceBetweenTwoElements, isAdmin, isEmployee, isReception, isTechnician } from "utils/global-functions";
import lodash from "lodash";
import { links } from "./config";

export const getLinksGroupName = (user) => {
  if (isAdmin(user)) return "admin";
  if (isReception(user)) return "reception";
  if (isTechnician(user)) return "technician";
  if (isEmployee(user)) return "employee";
  return "user";
};

export const getLinks = (user, repairDetails, repairId) => {
  return links[getLinksGroupName(user)].filter((link) => {
    if (lodash.isEmpty(repairDetails) || repairDetails.repairId !== parseInt(repairId)) return true;
    if (link === "/repairs-log") return repairDetails.repairs?.length > 1;
    if (link === "/configuration") return !!repairDetails.isElmaticConfiguration;
    return true;
  });
};

export const getFinalStepColor = (index, valueAfter, constants) => {
  if (index) return {};

  return {
    borderColor: `var(--${constants?.repairState?.[parseInt(valueAfter)]?.bgColor})`,
    backgroundColor: `var(--${constants?.repairState?.[parseInt(valueAfter)]?.bgColor})`,
  };
};

export const canDeleteStep = (repairStepsContainer) =>
  calculateSpaceBetweenTwoElements(repairStepsContainer?.current?.parentElement?.childNodes[0], repairStepsContainer?.current?.parentElement?.childNodes[1]) <
  10;

export const canAddStep = (repairStepsContainer, repairSteps, firstStepsLength) =>
  calculateSpaceBetweenTwoElements(repairStepsContainer?.current?.parentElement?.childNodes[0], repairStepsContainer?.current?.parentElement?.childNodes[1]) >
    100 && firstStepsLength <= repairSteps.length;

export const isCardWithOverflowHidden = () => window.location.pathname.includes("attachments");
