import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ELMARK_CHANGE_ACCOUNT_DETAILS_LINKS } from "utils/config";
import agent from "api/agent";
import Page from "stories/components/Page/Page";
import "./Account.css";

export const Account = () => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    agent.Users.getUser({ id: user.id, token: user.token }).then(
      ({ data }) => {
        dispatch({ type: "USER_LOGIN", ...data[0], id: user.id, token: user.token });
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let fname = user.personName.substring(0, user.personName.indexOf(" "));
  let lname = user.personName.substring(user.personName.indexOf(" ") + 1);

  let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  let country;
  if (user.countryId) {
    country = regionNames.of(`${user.countryId}`);
    if (country === "Poland") country = t("global.poland");
  }

  let accountType = user.accType;
  accountType === "corp" ? (accountType = t("account.company")) : (accountType = t("account.private"));

  return (
    <Page heading={t("account.data")}>
      <div className="account-data">
        <div className="acc-data-top">
          <div className="acc-data-left">
            <h1 className="acc-data-title heading--4">{t("account.yourData")}</h1>
            <div className="acc-data-table">
              <table>
                <tbody>
                  <tr>
                    <th className="acc-table-header-col paragraph">{t("account.accType")}</th>
                    <td className="acc-table-col paragraph">{accountType}</td>
                  </tr>
                  <tr>
                    <th className="acc-table-header-col paragraph">{t("global.fname")}</th>
                    <td className="acc-table-col paragraph">{fname}</td>
                  </tr>
                  <tr>
                    <th className="acc-table-header-col paragraph">{t("global.lname")}</th>
                    <td className="acc-table-col paragraph">{lname}</td>
                  </tr>
                  <tr>
                    <th className="acc-table-header-col paragraph">{t("global.email")}</th>
                    <td className="acc-table-col paragraph">{user.email}</td>
                  </tr>
                  <tr>
                    <th className="acc-table-header-col paragraph">{t("global.phone")}</th>
                    <td className="acc-table-col paragraph">{user.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="acc-address">
            <span className="acc-address-title heading--4">{t("account.address")}</span>
            <div className="acc-address-data paragraph">
              <div>{user.companyName}</div>
              <div>{user.taxNumber}</div>
              <div>{user.street}</div>
              <div>
                {user.city}
                {", "}
                {user.postCode}
              </div>
              <div>{country}</div>
            </div>
          </div>
        </div>
        <div className="data-change paragraph">
          <div>
            <MediaQuery minWidth={850}>{`${t("account.change1")} ${t("account.change2")} ${t("account.change3")} `}</MediaQuery>
            <MediaQuery maxWidth={850}>
              <div>{t("account.change1")}</div>
              <div>{t("account.change2")}</div>
              <div>{t("account.change3")}</div>
            </MediaQuery>
            <a className="link-acc-data" target="_blank" rel="noopener noreferrer" href={ELMARK_CHANGE_ACCOUNT_DETAILS_LINKS[i18n.language]}>
              {t("account.visit")}
            </a>
            .
          </div>
          <MediaQuery maxWidth={850}>
            <br />
          </MediaQuery>
          <div>
            <MediaQuery minWidth={850}>
              {`${t("account.changeAddress1")} ${t("account.changeAddress2")} ${t("account.changeAddress3")} ${t("account.changeAddress4")} `}
              <a className="link-acc-data" href="mailto:elmark@elmark.com.pl">
                {t("global.email-sm")}
              </a>
              .
            </MediaQuery>
            <MediaQuery maxWidth={850}>
              <div>{t("account.changeAddress1")}</div>
              <div>{t("account.changeAddress2")}</div>
              <div>{t("account.changeAddress3")}</div>
              <div>
                {t("account.changeAddress4")}{" "}
                <a className="link-acc-data" href="mailto:elmark@elmark.com.pl">
                  {t("global.email-sm")}
                </a>
                .
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </Page>
  );
};
