import React, { useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import { isAdmin } from "utils/global-functions";
import Badge from "stories/components/Badge/Badge";
import CloseClientRatingModal from "./CloseClientRatingModal";
import WaitingForInvitation from "./WaitingForInvitation";
import RatingNotAvailable from "./RatingNotAvailable";
import { isSaveRatingDisabled } from "./utils";

const RatingResult = ({ ratingResult, conversationListRefresh, setConversationListRefresh, customerRating = false, setEditMode, ratingInvitationResult }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const [closeRatingResultModalActive, setCloseRatingResultModalActive] = useState(false);

  if (lodash.isEmpty(ratingResult))
    return customerRating && !ratingInvitationResult?.isClosed && user.id === repairDetails.userId ? (
      <WaitingForInvitation />
    ) : (
      <RatingNotAvailable ratingInvitationResult={ratingInvitationResult} customerRatingResult={ratingResult} />
    );

  return (
    <div className="client-rating">
      <span className="paragraph--small text--subdued">{t("myRepairs.ratingResult")}</span>
      <Badge backgroundColor={ratingResult?.effect ? "green" : "red"} content={ratingResult?.effect ? t("global.positive") : t("global.negative")} />
      {!!ratingResult?.text && (
        <Stack flexDirection="column">
          <span className="paragraph--small text--subdued">{t("myRepairs.ratingComment")}</span>
          <p className="paragraph--small text--preline no-margin">{ratingResult?.text}</p>
        </Stack>
      )}
      {!!ratingResult?.attachments.length && (
        <Stack flexDirection="column">
          <span className="paragraph--small text--subdued">{t("global.uploadedAttachments")}</span>
          <ul>
            {ratingResult?.attachments?.map((attachment) => {
              return (
                <li key={attachment.id}>
                  <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                    {attachment.fileName}
                  </a>
                </li>
              );
            })}
          </ul>
        </Stack>
      )}
      <Stack flexDirection="column">
        <span className="paragraph--small text--subdued">{t("global.createdAt")}</span>
        <span className="paragraph--small">{moment(ratingResult.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
      </Stack>
      {!!ratingResult.updatedAt && (
        <Stack flexDirection="column">
          <span className="paragraph--small text--subdued">{t("global.updatedAt")}</span>
          <span className="paragraph--small">{moment(ratingResult.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</span>
        </Stack>
      )}
      <Stack gap="1rem">
        {((!customerRating && isAdmin(user)) || (user.id === repairDetails.userId && customerRating && !isSaveRatingDisabled(ratingResult))) && (
          <Button size="small" onClick={() => setEditMode(true)}>
            {t("global.edit")}
          </Button>
        )}
        {customerRating && (
          <>
            {!ratingResult.isClosed && (isAdmin(user) || user.id === repairDetails.technicianId) && (
              <Button size="small" variant="error" outline onClick={() => setCloseRatingResultModalActive(true)}>
                {t("global.close")}
              </Button>
            )}
            <CloseClientRatingModal
              active={closeRatingResultModalActive}
              setActive={setCloseRatingResultModalActive}
              selectedConversationId={ratingResult.id}
              conversationListRefresh={conversationListRefresh}
              setConversationListRefresh={setConversationListRefresh}
            />
          </>
        )}
      </Stack>
    </div>
  );
};

export default RatingResult;
