import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import FoundProduct from "components/FoundProduct/FoundProduct";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import QRScanner from "components/QRScanner/QRSscanner";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import AddExternalForm from "./AddExternalForm";
import CheckProduct from "./CheckProduct";

const SearchSerialNumber = ({ values, setFieldValue, formErrors, handleBlur, touched, errors, addExternal, setAddExternal, foundProduct, setFoundProduct }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [searchError, setSearchError] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [serialNotFound, setSerialNotFound] = useState(false);
  const [validProductCheck, setValidProductCheck] = useState(false);
  const dispatch = useDispatch();

  const checkWarrantySuccess = ({ data }) => {
    if (data.length === 0) throw new Error("1002");
    if (data.at(0).isInService === 1) throw new Error("1001");

    setSearchError(null);
    setFieldValue("serialNumber", data.at(0).serialNumber);
    setFoundProduct(data.at(0));
    setSerialNotFound(false);
  };

  const checkWarrantyError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFoundProduct({});
    if (filteredErrors.length > 0) setSearchError(t(`errors.${filteredErrors.at(0).code}`));
  };

  const checkWarrantyCatch = (error) => {
    setFoundProduct({});
    setSearchError(t(`errors.${error.message}`));
    if (error.message === "1002" && (isAdmin(user) || isReception(user))) setSerialNotFound(true);
  };

  const checkWarrantyFinally = () => {
    setAddExternal(false);
    setSearchLoading(false);
  };

  const checkSerialNumber = (serialNumber) => {
    setSearchLoading(true);

    return agent.Resources.checkWarranty({ serialNumber })
      .then(checkWarrantySuccess, checkWarrantyError)
      .catch(checkWarrantyCatch)
      .finally(checkWarrantyFinally);
  };

  return (
    <Stack flexDirection="column" gap="2rem">
      <TextField
        value={values.searchSerialNumber}
        label={t("global.serialNumber")}
        onChange={(value) => {
          setFieldValue("searchSerialNumber", value);
          setFieldValue("extSerialNumber", value);
          setFieldValue("serialNumber", value);
          setFoundProduct({});
        }}
        error={searchError || formErrors?.serialNumber}
        onClick={() => checkSerialNumber(values.searchSerialNumber)}
        placeholder={t("addReport.searchPlaceholder")}
        variant="search"
        loading={searchLoading}
        specialActions={
          <QRScanner
            setResultValue={(value) => {
              setFieldValue("searchSerialNumber", value);
              setFieldValue("extSerialNumber", value);
              setFieldValue("serialNumber", value);
              setFoundProduct({});
              checkSerialNumber(value);
            }}
          />
        }
      />
      <FoundProduct foundProduct={foundProduct} />
      <CheckProduct values={values} serialNotFound={serialNotFound} validProductCheck={validProductCheck} setValidProductCheck={setValidProductCheck} />
      {validProductCheck && <Button onClick={() => setAddExternal(true)}>{t("addReport.addOutOfRecord")}</Button>}
      {addExternal && <AddExternalForm values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} touched={touched} errors={errors} />}
    </Stack>
  );
};

export default SearchSerialNumber;
