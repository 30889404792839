import React from "react";
import { t } from "i18next";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";

const DeleteConfirmationModal = ({ active, setActive, onAction }) => {
  if (!active) return;

  return (
    <Modal
      id="delete_report_modal"
      title={t("producerReport.deleteReport")}
      onClose={() => setActive(false)}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button style={{ width: "4.2rem" }} onClick={() => setActive(false)}>
            {t("global.no")}
          </Button>
          <Button variant="primary" style={{ width: "4.2rem" }} onClick={() => onAction()}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <h6 className="heading--6">{t("producerReport.deleteReportConfirmationHeading")}</h6>
      <p className="paragraph--small text--subdued">{t("producerReport.deleteReportConfirmationBody")}</p>
    </Modal>
  );
};

export default DeleteConfirmationModal;
