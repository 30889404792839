/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import PageSpinner from "components/PageSpinner/PageSpinner";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Mobile from "./DisplayMobile";
import DisplayDesktop from "./DisplayDesktop";
import "./styles.css";

export const AddReportSuccess = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);

  const getRegistrationResultSuccess = (response) => {
    setProductList(response.data);
  };

  const getRegistrationResultError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
    dispatch({ type: "REGISTRATION_SETSTEP", step: 3 });
  };

  const getRegistrationResult = () => {
    agent.Registration.registrationResult({ id: user.id, token: user.token, registrationId: registration.registrationId })
      .then(getRegistrationResultSuccess, getRegistrationResultError)
      .finally(() => setProductListLoading(false));
  };

  useEffect(() => {
    getRegistrationResult();

    return () => dispatch({ type: "REGISTRATION_CANCEL" });
  }, []);

  if (productListLoading) return <PageSpinner subPage />;

  return mobile ? <Mobile productList={productList} /> : <DisplayDesktop productList={productList} />;
};
