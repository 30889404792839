import React, { useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import BulkActionModal from "../BulkActionModal";
import Tabs from "./Tabs";
import TopActions from "./TopActions";
import RepairsList from "./RepairsList";
import BottomActions from "./BottomActions";
import AppliedFiltersList from "./AppliedFiltersList";
import "./styles.css";

const DisplayAdminMobile = ({
  setDeleteTabModal,
  setActiveManageTabsModal,
  selectedItems,
  setSelectedItems,
  repairListLoading,
  activeFiltersDrawer,
  setActiveFiltersDrawer,
  refreshList,
  setRefreshList,
  cardTabs,
  pageNumber,
  setPageNumber,
  handleTabSelection,
}) => {
  const {
    repair: { repairsList, pageSize },
  } = useSelector((store) => store);
  const [activeBulkActionModal, setActiveBulkActionModal] = useState(false);

  return (
    <div className="mobile-repair-admin">
      <TopActions
        setDeleteTabModal={setDeleteTabModal}
        setActiveManageTabsModal={setActiveManageTabsModal}
        pageSize={pageSize}
        receivedRepairs={repairsList}
        repairListLoading={repairListLoading}
        activeFiltersDrawer={activeFiltersDrawer}
        setActiveFiltersDrawer={setActiveFiltersDrawer}
        setPageNumber={setPageNumber}
      />
      <AppliedFiltersList />
      <Tabs cardTabs={cardTabs} handleTabSelection={handleTabSelection} />
      <div className="mobile-repair-admin__content-wrapper">
        <RepairsList selectedItems={selectedItems} setSelectedItems={setSelectedItems} repairListLoading={repairListLoading} />
      </div>
      <BottomActions
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        activeBulkActionModal={activeBulkActionModal}
        setActiveBulkActionModal={setActiveBulkActionModal}
        repairListLoading={repairListLoading}
      />
      <Pagination
        loading={repairListLoading}
        pageSize={pageSize}
        pageNumber={pageNumber}
        listLength={repairsList.length}
        previousAction={() => {
          setPageNumber(pageNumber - 1);
          window.scrollTo(0, 0);
        }}
        continueAction={() => {
          setPageNumber(pageNumber + 1);
          window.scrollTo(0, 0);
        }}
      />
      <BulkActionModal
        active={activeBulkActionModal}
        setActive={setActiveBulkActionModal}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
    </div>
  );
};

export default DisplayAdminMobile;
