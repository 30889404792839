import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddReportFooter } from "routes/AddReport/AddReportFooter";
import Accordion from "stories/components/Accordion/Accordion";
import DeliveryInfo from "./DeliveryInfo";
import ReturnInfo from "./ReturnInfo";
import ContactInfo from "./ContactInfo";
import ProductList from "./ProductList";
import "./styles.css";

const DisplayMobile = ({ confirmRegistration, confirmRegistrationLoading, summaryDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="add-report-summary">
      <h3 className="heading--3 add-report-summary__heading">{t("addReport.summary")}</h3>
      <Accordion
        content={[
          { title: <h3 className="heading--3">{t("addReport.products")}</h3>, body: <ProductList summaryDetails={summaryDetails} />, active: true },
          { title: <h3 className="heading--3">{t("addReport.deliveryMethod")}</h3>, body: <DeliveryInfo summaryDetails={summaryDetails} />, active: true },
          { title: <h3 className="heading--3">{t("addReport.pickupMethod")}</h3>, body: <ReturnInfo summaryDetails={summaryDetails} />, active: true },
          { title: <h3 className="heading--3">{t("addReport.contactData")}</h3>, body: <ContactInfo summaryDetails={summaryDetails} />, active: true },
        ]}
      />
      <AddReportFooter
        backActionLabel={t("global.back")}
        backAction={() => dispatch({ type: "REGISTRATION_SETSTEP", step: 3 })}
        continueActionLabel={t("addReport.sendReport")}
        continueActionLoading={confirmRegistrationLoading}
        continueAction={() => confirmRegistration()}
      />
    </div>
  );
};

export default DisplayMobile;
