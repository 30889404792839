import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const ConfirmDeleteMessageModal = ({
  active,
  setActive,
  selectedConversationId,
  setSelectedConversationId,
  conversationList,
  refreshConversation,
  setRefreshConversation,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [deleteMessageLoading, setDeleteMessageLoading] = useState(false);

  const deleteMessageSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setRefreshConversation(!refreshConversation);
      setRefreshRepairDetails(!refreshRepairDetails);
      setSelectedConversationId(null);
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const deleteMessageError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const deleteMessage = () => {
    setDeleteMessageLoading(true);
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      action: "delete",
      repairId: repairDetails.repairId,
      conversationType: conversationList.find(({ id }) => id === selectedConversationId).type,
      conversationId: selectedConversationId,
    })
      .then(deleteMessageSuccess, deleteMessageError)
      .finally(() => setDeleteMessageLoading(false));
  };

  if (!active) return;

  return (
    <Modal
      title={t("myRepairs.deleteMessage")}
      onClose={() => {
        setActive(false);
        setSelectedConversationId(null);
      }}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button
            size={mobile ? "small" : "default"}
            onClick={() => {
              setActive(false);
              setSelectedConversationId(null);
            }}
          >
            {t("global.no")}
          </Button>
          <Button size={mobile ? "small" : "default"} loading={deleteMessageLoading} variant="primary" onClick={deleteMessage}>
            {t("global.yes")}
          </Button>
        </Stack>
      }
    >
      <p className="paragraph--small">{t("myRepairs.deleteMessageInfo")}</p>
    </Modal>
  );
};

export default ConfirmDeleteMessageModal;
