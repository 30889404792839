import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors, getErrorForInputField } from "utils/global-functions";
import { calculateRepairType, resetExternalFields } from "./utils";

const AddExternalProductForm = ({
  setFieldValue,
  values,
  setAddExternal,
  setSerialNotFound,
  addExternal,
  resetForm,
  isSubmitting,
  formErrors,
  setFormErrors,
  setWarrantyErrors,
  errors,
  touched,
  handleBlur,
}) => {
  const {
    app: { constants, commonApiErrors },
    user,
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const { t } = useTranslation();
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!addExternal) resetExternalFields(setFieldValue);
  }, [addExternal, setFieldValue]);

  const getProductsList = lodash.debounce(async (input, callback) => {
    try {
      const response = await agent.Resources.getProductsList({ id: user.id, token: user.token, filter: input });
      setProductList(response.data);
      callback(
        response.data.map(({ partNumber }) => {
          return { label: partNumber, value: partNumber };
        }),
      );
    } catch (error) {
      dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
      filteredErrors.forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
    }
  }, 1000);

  if (!addExternal) return;

  return (
    <div className="add-external-form">
      <TextField
        id="extSerialNumber"
        name="extSerialNumber"
        maxLength={50}
        disabled
        label={t("global.serialNumber")}
        placeholder={t("addReport.serialNumberPlaceholder")}
        value={values.extSerialNumber}
        onChange={(value) => {
          setFieldValue("extSerialNumber", value);
          setFieldValue("serialNumber", value);
        }}
        error={getErrorForInputField(errors.extSerialNumber, touched.extSerialNumber) || formErrors?.extSerialNumber}
        onBlur={handleBlur}
      />
      <SelectField
        async
        cacheOptions
        id="extPartNumber"
        name="extPartNumber"
        label={t("addReport.productSymbol")}
        placeholder={t("addReport.productSymbolPlaceholder")}
        value={values.extPartNumber}
        promiseOptions={getProductsList}
        onChange={(value) => {
          setFieldValue("extPartNumber", value);
          const foundProduct = productList.find(({ partNumber }) => partNumber === value);
          if (foundProduct) {
            setFieldValue("extProducerId", foundProduct.producerId || "");
            setFieldValue("extDescription", foundProduct.description || "");
          }
        }}
        error={getErrorForInputField(errors.extPartNumber, touched.extPartNumber) || formErrors?.extPartNumber}
        onBlur={handleBlur}
      />

      <TextField
        id="extDescription"
        name="extDescription"
        maxLength={200}
        label={t("addReport.productDescription")}
        placeholder={t("addReport.productDescriptionPlaceholder")}
        value={values.extDescription}
        onChange={(value) => setFieldValue("extDescription", value)}
        error={getErrorForInputField(errors.extDescription, touched.extDescription) || formErrors?.extDescription}
        onBlur={handleBlur}
      />
      <SelectField
        id="extProducerId"
        label={t("global.producer")}
        placeholder={t("addReport.producerPlaceholder")}
        value={values.extProducerId}
        onChange={(value) => setFieldValue("extProducerId", value)}
        options={constants.producersArr.map(({ intVal, strVal }) => {
          return { value: intVal, label: strVal };
        })}
        onBlur={handleBlur}
        error={getErrorForInputField(errors.extProducerId, touched.extProducerId) || formErrors?.extProducerId}
      />
      <TextField
        id="extDocument"
        name="extDocument"
        maxLength={50}
        label={t("global.invoice")}
        placeholder={t("addReport.invoicePlaceholder")}
        value={values.extDocument}
        onChange={(value) => setFieldValue("extDocument", value)}
        error={getErrorForInputField(errors.extDocument, touched.extDocument) || formErrors?.extDocument}
        onBlur={handleBlur}
      />
      <DatePicker
        label={t("addReport.sellDate")}
        placeholder={t("addReport.sellDatePlaceholder")}
        openDirection="down"
        date={values.extSellDate}
        language={user.lang}
        onChange={(date) => {
          setFieldValue("extSellDate", date);
          setFieldValue("repairType", calculateRepairType(date, values.extWarrantyLength));
        }}
        error={formErrors?.extSellDate}
      />
      <TextField
        id="extWarrantyLength"
        name="extWarrantyLength"
        variant="minimalistNumber"
        min={0}
        label={t("addReport.warrantyLength")}
        placeholder={t("addReport.warrantyLengthPlaceholder")}
        value={values.extWarrantyLength}
        onChange={(value) => {
          setFieldValue("extWarrantyLength", value);
          setFieldValue("repairType", calculateRepairType(values.extSellDate, value));
        }}
        error={getErrorForInputField(errors.extWarrantyLength, touched.extWarrantyLength) || formErrors?.extWarrantyLength}
        onBlur={handleBlur}
      />
      <Button
        className="add-product-external-btn button"
        disabled={isSubmitting}
        outline
        variant="error"
        size={mobile ? "small" : "default"}
        onClick={() => {
          setAddExternal(false);
          setSerialNotFound(false);
          setFormErrors([]);
          resetForm();
          setWarrantyErrors([]);
        }}
      >
        {t("global.cancel")}
      </Button>
    </div>
  );
};

export default AddExternalProductForm;
