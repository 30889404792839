/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MAX_MOBILE_WIDTH } from "utils/config";
import agent from "api/agent";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import "./commonStyles.css";

const Info = ({ refreshRepairDetails, setRefreshRepairDetails, repairSteps }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });
  const [productUrl, setProductUrl] = useState("");

  const repairDetailsTermsMap = {
    postCode: t(`global.postalCodeAndCity`),
    countryId: t(`global.country`),
  };

  const repairDetailsDescriptionMap = {
    countryId: repairDetails?.invoice?.countryId ? translatedCountryNames.of(repairDetails?.invoice?.countryId) : null,
    postCode: `${repairDetails.invoice.postCode || "-"} ${repairDetails.invoice.city || ""}`,
  };

  const getProductStoreInfoSuccess = (response) => {
    setProductUrl(response.url);
  };

  const getProductStoreInfo = () => agent.ExternalResources.getProductStoreInfo({ data: [{ name: repairDetails.partNumber, lang: user.lang, skus: "" }] });

  useEffect(() => {
    getProductStoreInfo().then(getProductStoreInfoSuccess);
  }, []);

  return mobile ? (
    <Mobile
      refreshRepairDetails={refreshRepairDetails}
      setRefreshRepairDetails={setRefreshRepairDetails}
      repairDetailsTermsMap={repairDetailsTermsMap}
      repairDetailsDescriptionMap={repairDetailsDescriptionMap}
      productUrl={productUrl}
    />
  ) : (
    <Desktop
      refreshRepairDetails={refreshRepairDetails}
      setRefreshRepairDetails={setRefreshRepairDetails}
      repairDetailsTermsMap={repairDetailsTermsMap}
      repairDetailsDescriptionMap={repairDetailsDescriptionMap}
      repairSteps={repairSteps}
      productUrl={productUrl}
    />
  );
};

export default Info;
