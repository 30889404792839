import React from "react";
import PropTypes from "prop-types";
import Radio from "../Radio/Radio.js";
import "../commonStyles.css";

const RadioGroup = ({ name, label, error, helpText, choices, selected, onChange }) => (
  <div className="input">
    {label && <label className="input__label">{label}</label>}
    <div>
      {choices.map(({ label, value }) => {
        return (
          <Radio
            checked={selected.toString() === value.toString()}
            key={value}
            name={name}
            value={value}
            label={label}
            onChange={(event) => onChange(event.target.defaultValue)}
          />
        );
      })}
    </div>
    {error && <span className="input__error-message">{error}</span>}
    {helpText && <span className="input__help-text">{helpText}</span>}
  </div>
);

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  helpText: PropTypes.string,
  choices: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioGroup;
