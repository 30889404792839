import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import Radio from "stories/components/Radio/Radio";
import { MAX_MOBILE_WIDTH, REPAIR_SOURCE_CLIENT } from "utils/config";
import { filterErrorsFromCommonApiErrors, getErrorForInputField, isAdmin, isReception } from "utils/global-functions";
import Dropzone from "stories/components/Dropzone/Dropzone.js";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import Button from "stories/components/Button/Button";
import AddExternalProduct from "./AddExternalProduct";
import RecentlyRepaired from "./RecentlyRepaired";
import { formatAddProductBody, isAddProductButtonDisabled } from "./utils";

const AddProductForm = ({
  foundProduct,
  recentlyRepaired,
  setSerialNotFound,
  setProductFullList,
  addExternal,
  setAddExternal,
  setFoundProduct,
  setWarrantyErrors,
  setRecentlyRepaired,
  formErrors,
  setFormErrors,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  resetForm,
  validProductCheck,
  serialNotFound,
}) => {
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addProductLoading, setAddProductLoading] = useState(false);

  const clearRestForm = () => {
    setFormErrors({});
    setSerialNotFound(false);
    setAddExternal(false);
    setWarrantyErrors([]);
    setFoundProduct({});
    setRecentlyRepaired(false);
  };

  const getRegistrationProductListSuccess = (response) => {
    setProductFullList(response.data);
    setSerialNotFound(false);
    setAddExternal(false);
    setWarrantyErrors([]);
    setFoundProduct({});
    setFormErrors({});
  };

  const getRegistrationProductListError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRegistrationProductList = (product) => {
    agent.Registration.registrationProductList({ id: user.id, token: user.token, registrationId: product.registrationId }).then(
      getRegistrationProductListSuccess,
      getRegistrationProductListError,
    );
  };

  const addProductSuccess = (product) => {
    getRegistrationProductList(product);
  };

  const addProductError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const addProduct = ({ serialNumber, userId, registrationId, repairSource, extSellDate, equipmentDescription, factoryResetAgreement, ...rest }, resetForm) => {
    setAddProductLoading(true);
    const product = {
      ...rest,
      extSellDate: extSellDate?.format("YYYY-MM-DD"),
      repairSource: repairSource || REPAIR_SOURCE_CLIENT,
      registrationId: registrationId || registration.registrationId,
      userId: userId || registration.registrationUserId,
      serialNumber: serialNumber || foundProduct.serialNumber,
    };
    if (!isReception(user) && !isAdmin(user)) product.factoryResetAgreement = factoryResetAgreement;

    return agent.Registration.registrationProductAdd({
      id: user.id,
      token: user.token,
      equipmentDescription: equipmentDescription,
      ...formatAddProductBody(product),
    })
      .then((response) => {
        if (response.result !== 301) throw new Error("unknownError");
        addProductSuccess(formatAddProductBody(product));
        resetForm();
        clearRestForm();
      }, addProductError)
      .catch((error) => {
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${error.message}`),
          },
        });
      })
      .finally(() => setAddProductLoading(false));
  };

  return (
    <Stack gap="2rem" flexDirection="column">
      <AddExternalProduct
        serialNotFound={serialNotFound}
        addExternal={addExternal}
        setAddExternal={setAddExternal}
        setSerialNotFound={setSerialNotFound}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
        values={values}
        setFieldValue={setFieldValue}
        resetForm={resetForm}
        isSubmitting={addProductLoading}
        setWarrantyErrors={setWarrantyErrors}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        validProductCheck={validProductCheck}
      />
      <RecentlyRepaired recentlyRepaired={recentlyRepaired} setFieldValue={setFieldValue} values={values} formErrors={formErrors} />
      <Stack flexDirection="column" gap="1.5rem">
        <span>
          <span className={`paragraph--small ${isAdmin(user) || isReception(user) ? "text--subdued" : ""}`}>{t("global.factoryResetAgreementQuestion")} </span>
          <span className={`paragraph--small text--bold ${isAdmin(user) || isReception(user) ? "text--subdued" : "text--red"}`}>
            {t("global.factoryResetAgreementWarning")}
          </span>
        </span>
        <div>
          <Radio
            disabled={isAdmin(user) || isReception(user)}
            checked={values.factoryResetAgreement === 1}
            onChange={() => setFieldValue("factoryResetAgreement", 1)}
            label={t("global.yes")}
            name="factory_reset_agreement"
          />
          <Radio
            disabled={isAdmin(user) || isReception(user)}
            checked={values.factoryResetAgreement === 0}
            onChange={() => setFieldValue("factoryResetAgreement", 0)}
            label={t("global.no")}
            name="factory_reset_agreement"
          />
        </div>
      </Stack>
      <TextField
        id="faultDescription"
        name="faultDescription"
        variant="multi"
        value={values.faultDescription}
        onBlur={handleBlur}
        numberOfLines={6}
        maxLength={2048}
        onChange={(value) => setFieldValue("faultDescription", value)}
        label={t("addReport.faultDescription")}
        placeholder={t("addReport.faultDescriptionPlaceholder")}
        error={getErrorForInputField(errors.faultDescription, touched.faultDescription) || formErrors?.faultDescription}
        disableResize
      />
      <TextField
        id="equipmentDescription"
        name="equipmentDescription"
        variant="multi"
        value={values.equipmentDescription}
        onBlur={handleBlur}
        numberOfLines={6}
        maxLength={2048}
        onChange={(value) => setFieldValue("equipmentDescription", value)}
        label={t("addReport.extraEquipment")}
        placeholder={t("addReport.extraEquipmentPlaceholder")}
        error={formErrors?.equipmentDescription}
        disableResize
      />
      <Dropzone label={t("addReport.attachments")} files={values.attachments} addFilesValue={(value) => setFieldValue("attachments", value)} />
      <Stack justifyContent="space-between">
        <Button
          size={mobile ? "small" : "default"}
          onClick={() => {
            resetForm();
            clearRestForm();
          }}
          disabled={addProductLoading}
        >
          {t("global.clear")}
        </Button>
        <Button
          disabled={isAddProductButtonDisabled(values, foundProduct, addExternal, errors, user)}
          loading={addProductLoading}
          onClick={() => addProduct(values, resetForm)}
          size={mobile ? "small" : "default"}
          variant="primary"
        >
          {t("addReport.addProduct")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AddProductForm;
