import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import { isEmployee } from "utils/global-functions";
import { CONVERSATION, CONVERSATION_INTERNAL } from "utils/config";
import { useNavigate, useParams } from "react-router-dom";

const TopBar = ({ converstionGroupType, setConversationGroupType, setSelectedConversationId, setResponseMessageActive, setEditMessageActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const navigate = useNavigate();
  const { repairId } = useParams();

  if (!isEmployee(user)) return <div />;

  return (
    <div className="conversation-topbar">
      <div className="conversation-topbar__buttons-wrapper">
        <Button
          variant={converstionGroupType === CONVERSATION_INTERNAL ? "primary" : "default"}
          onClick={() => {
            setSelectedConversationId(null);
            setResponseMessageActive(false);
            setEditMessageActive(false);
            setConversationGroupType(CONVERSATION_INTERNAL);
            navigate(`/${repairId}/conversation/internal`);
          }}
        >
          {t("myRepairs.generalElmarkChat")}
        </Button>
        <Button
          variant={converstionGroupType === CONVERSATION ? "primary" : "default"}
          onClick={() => {
            setSelectedConversationId(null);
            setResponseMessageActive(false);
            setEditMessageActive(false);
            setConversationGroupType(CONVERSATION);
            navigate(`/${repairId}/conversation`);
          }}
        >
          {t("myRepairs.generalChatWithClient")}
        </Button>
      </div>
    </div>
  );
};

export default TopBar;
