/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import PageSpinner from "components/PageSpinner/PageSpinner";
import Stack from "stories/components/Stack/Stack";
import agent from "api/agent";
import { getLanguage, getListType, getSkus } from "./utils";
import TopPanel from "./TopPanel";
import CheckWarrantyModal from "./CheckWarrantyModal";
import MobileTopPanel from "./MobileTopPanel";
import WarrantyResult from "./WarrantyResult";
import CardControls from "./CardControls";
import CheckMore from "./CheckMore";
import OurShops from "./OurShops";
import BundleContentModal from "./BundleContentModal";
import "./styles.css";

const SerialNumber = () => {
  const { t, i18n } = useTranslation();
  const { serialNumber } = useParams();
  const { user, app } = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [productStoreInfo, setProductStoreInfo] = useState({});
  const [checkWarrantyModalActive, setCheckWarrantyModalActive] = useState(false);
  const [checkWarrantyModalType, setWarrantyModalType] = useState("noResult");
  const [warrantyResult, setWarrantyResult] = useState({});
  const [repairsLogResult, setRepairsLogResult] = useState([]);
  const [repairsLogResultSerialNumber, setRepairsLogResultSerialNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [elmaticConfiguration, setElmaticConfiguration] = useState({});
  const [bundleContentModelActive, setBundleContentModelActive] = useState(false);

  const getProductStoreInfoError = (error) => error;

  const getProductStoreInfoSuccess = (response) => setProductStoreInfo(response);

  const getProductStoreInfo = (warrantyResult, configuration) => {
    const body = {
      data: [
        {
          name: warrantyResult.partNumber,
          lang: i18n?.language || getLanguage(user),
          skus: getSkus(configuration?.items || ""),
        },
      ],
    };

    return agent.ExternalResources.getProductStoreInfo(body).then(getProductStoreInfoSuccess, getProductStoreInfoError);
  };

  const checkWarrantyError = (error) => {
    navigate("/");
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, app.commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const checkWarrantySuccess = (response) => {
    if (!response.data.length) {
      setWarrantyModalType("noResult");
      return setCheckWarrantyModalActive(true);
    }

    if (response.data.at(0)?.serialNumber) setRepairsLogResultSerialNumber(response.data.at(0).serialNumber);
    setWarrantyResult(response.data.at(0));

    return getConfiguration(response.data.at(0));
  };

  const checkWarranty = () =>
    agent.Resources.checkWarranty({ serialNumber })
      .then(checkWarrantySuccess, checkWarrantyError)
      .finally(() => setLoading(false));

  const getRepairLogsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, app.commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRepairLogsSuccess = (response) => {
    setRepairsLogResult(response.data);
  };

  const getRepairLogs = () => {
    agent.Repairs.showRepairs({
      id: user.id,
      token: user.token,
      pageSize: 999,
      serialNumber: serialNumber,
      pageNumber: 1,
      listType: getListType(user),
    }).then(getRepairLogsSuccess, getRepairLogsError);
  };

  const getConfigurationError = (error, warrantyResult) => {
    getProductStoreInfo(warrantyResult, []);
    return error;
  };

  const getConfigurationSuccess = (response, warrantyResult) => {
    setElmaticConfiguration(response);
    getProductStoreInfo(warrantyResult, response.data.at(0));
  };

  const getConfiguration = (warrantyResult) => {
    return agent.Repairs.elmaticConfiguration({
      id: user.id,
      token: user.token,
      serialNumber: warrantyResult.serialNumber,
    }).then(
      (response) => getConfigurationSuccess(response, warrantyResult),
      (error) => getConfigurationError(error, warrantyResult),
    );
  };

  useEffect(() => {
    setLoading(true);
    checkWarranty();
    if (!lodash.isEmpty(user)) getRepairLogs();
  }, [serialNumber]);

  useEffect(() => {
    if (!lodash.isEmpty(warrantyResult)) getProductStoreInfo(warrantyResult, elmaticConfiguration);
  }, [i18n.language]);

  if (loading) return <PageSpinner />;

  return (
    <>
      {mobile && <MobileTopPanel />}
      <div className="serial-number-container">
        {!mobile && <TopPanel />}
        {lodash.isEmpty(warrantyResult) ? (
          <div />
        ) : (
          <>
            <CheckMore />
            <div className="serial-number__card">
              <div className="serial-number__wrapper">
                <CardControls
                  repairsLogResultSerialNumber={repairsLogResultSerialNumber}
                  setCheckWarrantyModalActive={setCheckWarrantyModalActive}
                  setWarrantyModalType={setWarrantyModalType}
                />
                <Stack flexDirection="column" gap="6rem">
                  <WarrantyResult
                    setBundleContentModelActive={setBundleContentModelActive}
                    elmaticConfiguration={elmaticConfiguration}
                    warrantyResult={warrantyResult}
                    productStoreInfo={productStoreInfo}
                    repairsLogResult={repairsLogResult}
                  />
                </Stack>
              </div>
            </div>
            <OurShops />
          </>
        )}
        <CheckWarrantyModal
          active={checkWarrantyModalActive}
          setActive={setCheckWarrantyModalActive}
          checkWarrantyModalType={checkWarrantyModalType}
          warrantyResult={warrantyResult}
        />
        <BundleContentModal active={bundleContentModelActive} onClose={() => setBundleContentModelActive(false)} elmaticConfiguration={elmaticConfiguration} />
      </div>
    </>
  );
};

export default SerialNumber;
