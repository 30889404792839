import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import agent from "api/agent";
import ErrorLabel from "stories/components/ErrorLabel/ErrorLabel";
import TextField from "stories/components/TextField/TextField";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import Link from "stories/components/Link/Link";
import { filterErrorsFromCommonApiErrors, setDocumentTitle } from "utils/global-functions";
import "./desktop/styles.css";

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [errors, setErrors] = useState([]);
  const {
    app: { commonApiErrors },
  } = useSelector((store) => store);

  const login = (body) => {
    return agent.Users.login({ ...body }).then(
      (response) => handleLoginSuccess(response.data),
      (error) => handleLoginError(error),
    );
  };

  const handleLoginSuccess = (data) => {
    dispatch({ type: "USER_LOGIN", ...data[0] });
    i18n.changeLanguage(data.at(0).lang);
    document.documentElement.setAttribute("lang", data.at(0).lang);
    dispatch({
      type: "REPAIR_SET_APPLIEDFILTERS",
      appliedFilters: { isClosed: 0 },
    });
    dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: "all" });
    dispatch({ type: "REPAIR_SET_TABS", tabs: [] });
    setDocumentTitle(t);
    agent.App.getConstants({ id: data[0].id, token: data[0].token }).then(
      (response) => handleGetConstantsSuccess(response.data),
      (error) => handleGetConstantsError(error),
    );
    agent.Conversations.conversationsCount({
      id: data[0].id,
      token: data[0].token,
    }).then(
      ({ data }) => {
        dispatch({ type: "USER_NOTIFICATION", notificationCount: data[0].cnt });
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const handleLoginError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setErrors(filteredErrors.map((error) => error.code));
  };

  const handleGetConstantsSuccess = (data) => {
    const constants = {};
    data.forEach((item) => {
      if (typeof constants[item.name] === "undefined") constants[item.name] = {};
      constants[item.name][item.intVal] = { strVal: item.strVal, bgColor: item.bgColor };
      if (typeof constants[item.name + "Arr"] === "undefined") constants[item.name + "Arr"] = [];
      constants[item.name + "Arr"].push({ intVal: item.intVal, strVal: item.strVal, bgColor: item.bgColor });
    });
    dispatch({ type: "APP_SETCONSTANTS", constants });
  };

  const handleGetConstantsError = (error) => {
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
  };

  return (
    <Formik initialValues={{ email: "", pass: "" }} onSubmit={(values) => login(values)}>
      {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Stack flexDirection="column" gap="2rem">
            <div>
              <div className="login_input">
                <label htmlFor="email">{t("global.email-sm")}</label>
                <TextField
                  name="email"
                  type="email"
                  placeholder={t("loginPage.emailPlaceholder")}
                  value={values.email}
                  onChange={(value) => setFieldValue("email", value)}
                />
              </div>
              <div className="login_input">
                <label htmlFor="password">{t("loginPage.password")}</label>
                <TextField
                  name="password"
                  type="password"
                  placeholder={t("loginPage.passwordPlaceholder")}
                  value={values.pass}
                  onChange={(value) => setFieldValue("pass", value)}
                />
              </div>
              <div className="login_errors_wrapper">
                {errors.map((code) => (
                  <ErrorLabel key={code}>{t(`errors.${code}`)}</ErrorLabel>
                ))}
              </div>
            </div>
            <div>
              <div className="login_form_footer">
                <Stack flexDirection="column" gap="0.4rem">
                  <span>
                    {t("loginPage.signUpQuestion")}{" "}
                    <Link className="login-link" href="https://www.elmark.com.pl/customer/account/create">
                      {t("loginPage.signUp")}
                    </Link>
                  </span>
                  <span>
                    {t("loginPage.forgotPasswordQuestion")}{" "}
                    <Link className="login-link" href="https://www.elmark.com.pl/customer/account/forgotpassword">
                      {t("loginPage.forgotPassword")}
                    </Link>
                  </span>
                </Stack>
                <div className="login_button_wrapper">
                  <Button size={mobile ? "small" : "default"} type="submit" loading={isSubmitting} variant="primary">
                    {t("loginPage.login")}
                  </Button>
                </div>
              </div>
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
