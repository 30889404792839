/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getChevron, getRandomComponentId } from "./utils";
import "./styles.css";

const ImageModal = ({ id = getRandomComponentId(), onClose, content, filePath, video, photoButtons }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const imageTag = useRef(null);

  useEffect(() => {
    if (video) imageTag.current?.addEventListener("loadeddata", () => setLoading(false));
    else imageTag.current?.addEventListener("load", () => setLoading(false));

    const modal = document.getElementById(id);
    modal.style.display = "block";
    return () => {
      modal.style.display = "none";
    };
  }, [id]);

  return (
    <div id={id} className="image-modal">
      <button onClick={() => onClose()} className="image-modal__close">
        &times;
      </button>
      <div className={`image-modal__content-wrapper${photoButtons ? " image-modal__content-wrapper--multi" : ""}`}>
        {!!photoButtons && getChevron("back", photoButtons.nextPhotoButton, loading, setLoading)}
        {loading ? (
          <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem", borderWidth: "0.5rem", color: "var(--grey-low-opacity)" }} />
        ) : null}
        <figure className={`image-modal__figure ${loading ? " image-modal__figure--hidden" : ""}`}>
          {video ? (
            <video ref={imageTag} className="image-modal__content" src={filePath} controls />
          ) : (
            <>
              <div className="image-modal__info--link">
                <a href={filePath} className="custom-link--white" target="_blank" rel="noreferrer">
                  {t("global.openInNewWindow")}
                </a>
              </div>
              <img ref={imageTag} src={filePath} className="image-modal__content" alt={filePath} />
            </>
          )}
          <figcaption className="image-modal__info">{content}</figcaption>
        </figure>
        {!!photoButtons && getChevron("forward", photoButtons.previousPhotoButton, loading, setLoading)}
      </div>
    </div>
  );
};

export default ImageModal;
