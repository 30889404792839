import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TextField from "stories/components/TextField/TextField";
import SelectField from "stories/components/SelectField/SelectField";
import DatePicker from "stories/components/DatePicker/DatePicker";
import Button from "stories/components/Button/Button";

export const Filters = ({
  setFiltersActive,
  setEndDateFrom,
  setEndDateUpTo,
  setRepairId,
  setSerialNumber,
  setPartNumber,
  setTechnicianId,
  setProducerId,
  setIsIncopmlete,
  endDateFromValue,
  setEndDateFromValue,
  endDateUpToValue,
  setEndDateUpToValue,
  repairIdValue,
  setRepairIdValue,
  serialNumberValue,
  setSerialNumberValue,
  partNumberValue,
  setPartNumberValue,
  technicianIdValue,
  setTechnicianIdValue,
  producerIdValue,
  setProducerIdValue,
  isIncompleteValue,
  setIsIncopmleteValue,
}) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
  } = useSelector((store) => store);

  // employees options with technicians on the top and rest of employees on the bottom
  const techniciansOptions = constants?.techniciansArr?.map(({ intVal, strVal }) => {
    return { value: intVal, label: strVal };
  });
  const employeesOptions = constants?.employeesArr?.map(({ intVal, strVal }) => {
    return { value: intVal, label: strVal };
  });

  const allOptions = techniciansOptions?.concat(employeesOptions);

  const options = allOptions?.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      allOptions?.findIndex((allOptions) => {
        return JSON.stringify(allOptions) === _value;
      })
    );
  });

  return (
    <div>
      <div>
        <div className="producer-report-filters-input">
          <DatePicker
            label={t("producerReport.endDates")}
            variant="range"
            openDirection="down"
            startDate={endDateFromValue}
            startDateId={"startDate"}
            endDate={endDateUpToValue}
            endDateId={"endDate"}
            language={user.lang}
            onChange={({ startDate, endDate }) => {
              setEndDateFromValue(startDate);
              setEndDateUpToValue(endDate);
            }}
          />
        </div>
        <div className="producer-report-filters-input">
          <TextField
            label={t("global.serialNumber")}
            placeholder={t("actions.enterSerialNumber")}
            value={serialNumberValue}
            onChange={(value) => {
              setSerialNumberValue(value);
            }}
            error={serialNumberValue && serialNumberValue.length < 3 ? t(`errors.101`) : null}
          />
        </div>
        <div className="producer-report-filters-input">
          <TextField
            label={t("producerReport.partNumber")}
            placeholder={t("producerReport.enterPartNumber")}
            value={partNumberValue}
            onChange={(value) => {
              setPartNumberValue(value);
            }}
            error={partNumberValue && partNumberValue.length < 3 ? t(`errors.101`) : null}
          />
        </div>
        <div className="producer-report-filters-input">
          <TextField
            label={t("global.repairNumber")}
            placeholder={t("actions.enterRepairNumber")}
            value={repairIdValue}
            onChange={(value) => {
              setRepairIdValue(value);
            }}
          />
        </div>
        <div className="producer-report-filters-input">
          <SelectField
            label={t("global.producer")}
            placeholder={t("global.chooseFromList")}
            value={producerIdValue}
            isClearable={true}
            onChange={(value) => {
              setProducerIdValue(value);
            }}
            options={constants?.producersArr?.map(({ intVal, strVal }) => {
              return { value: intVal, label: strVal };
            })}
          />
        </div>
        <div className="producer-report-filters-input">
          <SelectField
            label={t("myRepairs.technician")}
            placeholder={t("global.chooseFromList")}
            value={technicianIdValue}
            isClearable={true}
            onChange={(value) => {
              setTechnicianIdValue(value);
            }}
            options={options}
          />
        </div>

        <div className="producer-report-filters-input">
          <SelectField
            label="Report info"
            placeholder={t("global.chooseFromList")}
            value={isIncompleteValue}
            isClearable={true}
            onChange={(value) => {
              setIsIncopmleteValue(value);
            }}
            options={[
              { value: 0, label: t("producerReport.reportStateOk") },
              { value: 1, label: t("producerReport.reportStateNoReport") },
            ]}
          />
        </div>
      </div>
      <div className="producer-report-filters-buttons">
        <Button
          type="button"
          onClick={() => {
            setEndDateFromValue();
            setEndDateUpToValue();
            setSerialNumberValue("");
            setPartNumberValue("");
            setRepairIdValue("");
            setProducerIdValue("");
            setTechnicianIdValue("");
            setIsIncopmleteValue("");
          }}
        >
          {t("actions.clearFilters")}
        </Button>
        <Button
          variant="primary"
          type="button"
          disabled={
            (serialNumberValue && serialNumberValue.length < 3) || (partNumberValue && partNumberValue.length < 3) || isNaN(repairIdValue) ? true : false
          }
          onClick={() => {
            setFiltersActive(false);
            setEndDateFrom(endDateFromValue);
            setEndDateUpTo(endDateUpToValue);
            setSerialNumber(serialNumberValue);
            setPartNumber(partNumberValue);
            setRepairId(repairIdValue);
            setProducerId(producerIdValue);
            setTechnicianId(technicianIdValue);
            setIsIncopmlete(isIncompleteValue);
          }}
        >
          {t("actions.showResults")}
        </Button>
      </div>
    </div>
  );
};
