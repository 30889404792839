import React from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "stories/components/RadioGroup/RadioGroup";
import { REPAIR_TYPE_REPAIR_WARRANTY, REPAIR_TYPE_UNDER_WARRANTY } from "utils/config";

const RecentlyRepaired = ({ recentlyRepaired, setFieldValue, values, formErrors }) => {
  const { t } = useTranslation();

  const choices = [
    { label: t("global.newRepair"), value: REPAIR_TYPE_UNDER_WARRANTY },
    { label: t("global.repairReclamation"), value: REPAIR_TYPE_REPAIR_WARRANTY },
  ];

  if (!recentlyRepaired) return;

  return (
    <div className="add-product__recently-repaired">
      <RadioGroup
        label={t("global.chooseRepairType")}
        name="repairType"
        choices={choices}
        selected={values.repairType}
        onChange={(value) => setFieldValue("repairType", value)}
        error={formErrors?.repairType}
      />
    </div>
  );
};

export default RecentlyRepaired;
