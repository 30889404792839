import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import { ATTACHMENTS_CONVERSATION_TYPE, ATTACHMENTS_GENERAL_TYPE, ATTACHMENTS_REPORT_TYPE } from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";
import AttachemntsAccordion from "./AttachemntsAccordion";

const AttachmentsDetails = ({ attachmentList, setAttachmentsModalActive, setSelectedAttachmentId, setAttachmentDeleteModalActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);

  const conversationAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_CONVERSATION_TYPE);
  const generalAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_GENERAL_TYPE);
  const reportAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_REPORT_TYPE);

  return (
    <div className="mobile-attachments">
      <Stack gap="1rem" justifyContent="space-between" alignItems="center">
        <h4 className="heading--4  heading--large-spacing">{t("global.attachments")}</h4>
        {(isReception(user) || isAdmin(user) || repairDetails.technicianId === user.id) && (
          <Button variant="primary" size="small" onClick={() => setAttachmentsModalActive(true)}>
            {t("global.addAttachments")}
          </Button>
        )}
      </Stack>
      {!!reportAttachments?.length && <h6 className="heading--6 mobile-attachments__heading-wrapper">{t("myRepairs.reportAttachments")}</h6>}
      <AttachemntsAccordion attachments={reportAttachments} />
      {!!conversationAttachments?.length && <h6 className="heading--6 mobile-attachments__heading-wrapper">{t("myRepairs.conversationAttachments")}</h6>}
      <AttachemntsAccordion attachments={conversationAttachments} />
      {!!generalAttachments?.length && <h6 className="heading--6 mobile-attachments__heading-wrapper">{t("myRepairs.generalAttachments")}</h6>}
      <AttachemntsAccordion
        attachments={generalAttachments}
        setSelectedAttachmentId={setSelectedAttachmentId}
        setAttachmentsModalActive={setAttachmentsModalActive}
        setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
      />
    </div>
  );
};

export default AttachmentsDetails;
