import React from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Table from "stories/components/Table/Table";
import { sortDescendingOrder } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";
import MobileRepairsLogList from "./MobileRepairsLogList";
import RepairsLogTableRow from "./RepairsLogTableRow";
import { repairsTableHeadings } from "./config";

const RepairsLogResult = ({ repairsLogResult }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  if (lodash.isEmpty(repairsLogResult)) return;

  return mobile ? (
    <MobileRepairsLogList repairsLogResult={repairsLogResult} />
  ) : (
    <div className="serial-number__table-wrapper">
      <Table
        extended
        headings={repairsTableHeadings.map((heading) => {
          return { content: t(`global.${heading}`) };
        })}
      >
        {repairsLogResult
          .sort((a, b) => sortDescendingOrder(a.repairId, b.repairId))
          .map((repair) => (
            <RepairsLogTableRow key={repair.repairId} repair={repair} />
          ))}
      </Table>
    </div>
  );
};

export default RepairsLogResult;
