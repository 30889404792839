import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ElmarkLogo from "images/elmark_logo.png";
import ElmaticLogo from "images/elmatic_logo.png";
import RuggedLogo from "images/rugged_logo.png";
import { LINKS_TO_STORES } from "utils/config";
import { getLanguage } from "./utils";

const OurShops = () => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <div className="serial-number__our-shops">
      <h5 className="heading--4 text--no-bold">{t("serialNumber.checkOurOtherShops")}</h5>
      <span className="paragraph--small">{t("serialNumber.exploreMoreOfOurProductsAndUseOurKnowledgeCenter")}</span>
      <ul className="serial-number__shop-list">
        <li>
          <a href={LINKS_TO_STORES[getLanguage(user)].elmatic} target="_blank" rel="noreferrer">
            <img src={ElmaticLogo} alt="Elmatic_logo" />
          </a>
        </li>
        <li>
          <a href={LINKS_TO_STORES[getLanguage(user)].elmark} target="_blank" rel="noreferrer">
            <img src={ElmarkLogo} alt="Elmark_logo" />
          </a>
        </li>
        <li>
          <a href={LINKS_TO_STORES[getLanguage(user)].rugged} target="_blank" rel="noreferrer">
            <img src={RuggedLogo} alt="Rugged_logo" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default OurShops;
