import moment from "moment";
import lodash from "lodash";
import { REPAIR_TYPE_NONE_VALUE, REPAIR_TYPE_OUT_OF_WARRANTY, REPAIR_TYPE_UNDER_WARRANTY } from "utils/config";
import { isAdmin, isReception, removeEmptyValuesFromObject } from "utils/global-functions";
import { externalFieldsToReset, requiredExternalFields } from "./config";

export const getRepairTypeToFoundProduct = (data) => {
  if (!!data.at(0).isRecentlyRepaired) return REPAIR_TYPE_NONE_VALUE;
  return data.at(0).warrantyUnder === 0 ? REPAIR_TYPE_OUT_OF_WARRANTY : REPAIR_TYPE_UNDER_WARRANTY;
};

export const calculateRepairType = (sellDate, warrantyLength) => {
  if (!sellDate) return REPAIR_TYPE_NONE_VALUE;

  const newSellDate = sellDate.clone();

  return newSellDate.add(warrantyLength, "M").isSameOrBefore(moment()) ? REPAIR_TYPE_OUT_OF_WARRANTY : REPAIR_TYPE_UNDER_WARRANTY;
};

export const isUnderWarranty = (date) => moment().isSameOrBefore(date);

export const isAddProductButtonDisabled = (values, foundProduct, addExternal, errors, user) => {
  if (values.factoryResetAgreement !== 1 && values.factoryResetAgreement !== 0 && !isAdmin(user) && !isReception(user)) return true;
  if (errors?.faultDescription) return true;
  if (addExternal && !isFormValid(errors)) return true;
  if (addExternal && !isExternalFieldsAreFilled(values)) return true;
  return (!values.serialNumber && lodash.isEmpty(foundProduct)) || values.repairType === REPAIR_TYPE_NONE_VALUE;
};

const isExternalFieldsAreFilled = (values) =>
  Object.entries(values)
    .filter(([key]) => requiredExternalFields.includes(key))
    .every(([, value]) => value === 0 || !!value);

const isFormValid = (errors) => !Object.entries(errors).some(([key]) => key.includes("ext"));

export const showCheckProductButton = (values, serialNotFound, validProductCheck) => serialNotFound && !validProductCheck && values.searchWarrantyInput;

export const formatAddProductBody = (product) => {
  if (product.extSerialNumber) return product;

  return removeEmptyValuesFromObject(product);
};

export const resetExternalFields = (setFieldValue) =>
  externalFieldsToReset.forEach((fieldToReset) => setFieldValue(fieldToReset.name, fieldToReset.defaultValue));
