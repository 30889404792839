import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import Badge from "stories/components/Badge/Badge";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import { isAdmin } from "utils/global-functions";
import { canApproveValuation, canCloseValuationResult } from "../utils";

const ValuationOffer = ({
  conversation: {
    id,
    currency,
    createdAt,
    text,
    isClosed,
    amount,
    effect,
    recipientId,
    attachments,
    resultText,
    resultAttachments,
    resultId,
    resultIsClosed,
    resultRecipientId,
  },
  setConfirmModalActive,
  setSelectedConversationId,
  setAddValuationModalActive,
  setConfirmActionName,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <div className="price-offer">
      <div className="price-offer__heading">
        <h6 className="price-offer__title">{t("myRepairs.repairValuation")}</h6>
        <div className="price-offer__heading-right">
          {!isClosed && isAdmin(user) && (
            <Stack gap="1rem" alignItems="baseline">
              <button
                className="unset-all custom-link--black"
                onClick={() => {
                  setSelectedConversationId(id);
                  setAddValuationModalActive(true);
                }}
                size="small"
              >
                {t("global.edit")}
              </button>
              <button
                className="unset-all custom-link"
                onClick={() => {
                  setSelectedConversationId(id);
                  setConfirmModalActive(true);
                  setConfirmActionName("delete");
                }}
                size="small"
              >
                {t("global.delete")}
              </button>
            </Stack>
          )}
          <span className="paragraph--small text--subdued">{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
        </div>
      </div>
      <div className="price-offer__content">
        <p className="price-offer__body paragraph--small no-margin">{`${t("global.diagnosis")}: ${text}`}</p>
        <span className="paragraph--small text--bold">{`${t("global.cost")}: ${amount} ${currency} ${t("global.net").toLowerCase()}`}</span>
        {!!attachments?.length && (
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.id}>
                <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                  {attachment.fileName}
                </a>
              </li>
            ))}
          </ul>
        )}
        {(!!effect || effect === 0) && (
          <div className="valuation-result">
            <Badge backgroundColor={effect ? "green" : "red"} content={effect ? t("myRepairs.accepted") : t("myRepairs.rejected")} />
            {resultText?.trim() && (
              <Stack flexDirection="column">
                <span className="paragraph--small text--subdued">{t("myRepairs.valuationComment")}</span>
                <span>{resultText}</span>
              </Stack>
            )}
            {!!resultAttachments?.length && (
              <Stack flexDirection="column">
                <span className="paragraph--small text--subdued">{t("global.uploadedAttachments")}</span>
                <ul>
                  {resultAttachments.map((attachment) => (
                    <li key={attachment.id}>
                      <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                        {attachment.fileName}
                      </a>
                    </li>
                  ))}
                </ul>
              </Stack>
            )}
            {canCloseValuationResult(resultIsClosed, user, resultRecipientId) && (
              <Button
                outline
                variant="error"
                size="small"
                onClick={() => {
                  setSelectedConversationId(resultId);
                  setConfirmModalActive(true);
                  setConfirmActionName("close");
                }}
              >
                {t("global.close")}
              </Button>
            )}
          </div>
        )}
        {}
      </div>
      {canApproveValuation(isClosed, user, recipientId) && (
        <div className="price-offer__footer">
          <Button
            size="small"
            outline
            variant="error"
            onClick={() => {
              setSelectedConversationId(id);
              setConfirmModalActive(true);
              setConfirmActionName("reject");
            }}
          >
            {t("global.reject")}
          </Button>
          <Button
            size="small"
            variant="primary"
            onClick={() => {
              setConfirmModalActive(true);
              setSelectedConversationId(id);
              setConfirmActionName("approve");
            }}
          >
            {t("global.accept")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ValuationOffer;
