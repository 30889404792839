import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import { t } from "i18next";
import { useMediaQuery } from "react-responsive";
import Page from "stories/components/Page/Page";
import Menu from "./Menu/Menu";
import Body from "./Body/Body";
import "./Actions.css";

export const Actions = () => {
  const mobile = useMediaQuery({ maxWidth: 850 });
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);

  const [conversationTypes, setConversationTypes] = useState(null);
  const [repairNumber, setRepairNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [customer, setCustomer] = useState("");
  const [employeeId, setEmployeeId] = useState(user.id);
  const [repairState, setRepairState] = useState();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateUpTo, setDateUpTo] = useState(null);

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [actions, setActions] = useState([]);
  const [error, setError] = useState([]);

  const conversationsList = ({ convTypes, pageNr, repId }) => {
    setLoading(true);

    return agent.Conversations.conversationsList({
      id: user.id,
      token: user.token,
      pageSize: pageSize,
      pageNumber: pageNr ?? pageNumber,
      conversationTypes: convTypes ?? conversationTypes,
      repairId: repId ?? repairNumber === "" ? null : repairNumber,
      serialNumber: serialNumber === "" ? null : serialNumber,
      customer: customer === "" ? null : customer,
      employeeId: employeeId,
      repairState: repairState,
      dateFrom: dateFrom?.format("YYYY-MM-DD"),
      dateUpTo: dateUpTo?.format("YYYY-MM-DD"),
    })
      .then(
        ({ data }) => {
          setActions(data);
          setError([]);
        },
        (error) => {
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
          setError(error.response.data.messages[0].code);
          setActions([]);
        },
      )
      .finally(() => setLoading(false));
  };

  const conversationsCount = () => {
    agent.Conversations.conversationsCount({ id: user.id, token: user.token }).then(
      ({ data }) => {
        dispatch({ type: "USER_NOTIFICATION", notificationCount: data[0].cnt });
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  useEffect(() => {
    conversationsList({ convTypes: conversationTypes });
    conversationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber, conversationTypes]);

  return (
    <Page heading={t("actions.actionsCenter")}>
      <div className={mobile ? "" : "actions"}>
        <Menu
          conversationsList={conversationsList}
          conversationTypes={conversationTypes}
          setConversationTypes={setConversationTypes}
          repairNumber={repairNumber}
          setRepairNumber={setRepairNumber}
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          customer={customer}
          setCustomer={setCustomer}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
          repairState={repairState}
          setRepairState={setRepairState}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateUpTo={dateUpTo}
          setDateUpTo={setDateUpTo}
          error={error}
          setPageNumber={setPageNumber}
        />
        <div className="body-div">
          <Body loading={loading} actions={actions} pageNumber={pageNumber} setPageNumber={setPageNumber} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>
    </Page>
  );
};
