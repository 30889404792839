import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import DisplayDesktop from "./DisplayDesktop";
import DisplayMobile from "./DisplayMobile";
import "./styles.css";

export const Heading = ({
  producerReportList,
  pageSize,
  setPageSize,
  setPageNumber,
  endDateFrom,
  setEndDateFrom,
  endDateUpTo,
  setEndDateUpTo,
  repairId,
  setRepairId,
  serialNumber,
  setSerialNumber,
  partNumber,
  setPartNumber,
  technicianId,
  setTechnicianId,
  producerId,
  setProducerId,
  isIncomplete,
  setIsIncopmlete,
  endDateFromValue,
  setEndDateFromValue,
  endDateUpToValue,
  setEndDateUpToValue,
  repairIdValue,
  setRepairIdValue,
  serialNumberValue,
  setSerialNumberValue,
  partNumberValue,
  setPartNumberValue,
  technicianIdValue,
  setTechnicianIdValue,
  producerIdValue,
  setProducerIdValue,
  isIncompleteValue,
  setIsIncopmleteValue,
  reportListExportCallback,
  reportListLength,
}) => {
  const mobile = useMediaQuery({ maxWidth: 850 });

  const [filtersActive, setFiltersActive] = useState(false);

  return (
    <>
      {mobile ? (
        <DisplayMobile
          producerReportList={producerReportList}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          setEndDateFrom={setEndDateFrom}
          setEndDateUpTo={setEndDateUpTo}
          setRepairId={setRepairId}
          setSerialNumber={setSerialNumber}
          setPartNumber={setPartNumber}
          setTechnicianId={setTechnicianId}
          setProducerId={setProducerId}
          setIsIncopmlete={setIsIncopmlete}
          endDateFromValue={endDateFromValue}
          setEndDateFromValue={setEndDateFromValue}
          endDateUpToValue={endDateUpToValue}
          setEndDateUpToValue={setEndDateUpToValue}
          repairIdValue={repairIdValue}
          setRepairIdValue={setRepairIdValue}
          serialNumberValue={serialNumberValue}
          setSerialNumberValue={setSerialNumberValue}
          partNumberValue={partNumberValue}
          setPartNumberValue={setPartNumberValue}
          technicianIdValue={technicianIdValue}
          setTechnicianIdValue={setTechnicianIdValue}
          producerIdValue={producerIdValue}
          setProducerIdValue={setProducerIdValue}
          isIncompleteValue={isIncompleteValue}
          setIsIncopmleteValue={setIsIncopmleteValue}
          reportListExportCallback={reportListExportCallback}
          reportListLength={reportListLength}
        />
      ) : (
        <DisplayDesktop
          producerReportList={producerReportList}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          endDateFrom={endDateFrom}
          setEndDateFrom={setEndDateFrom}
          endDateUpTo={endDateUpTo}
          setEndDateUpTo={setEndDateUpTo}
          repairId={repairId}
          setRepairId={setRepairId}
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          partNumber={partNumber}
          setPartNumber={setPartNumber}
          technicianId={technicianId}
          setTechnicianId={setTechnicianId}
          producerId={producerId}
          setProducerId={setProducerId}
          isIncomplete={isIncomplete}
          setIsIncopmlete={setIsIncopmlete}
          endDateFromValue={endDateFromValue}
          setEndDateFromValue={setEndDateFromValue}
          endDateUpToValue={endDateUpToValue}
          setEndDateUpToValue={setEndDateUpToValue}
          repairIdValue={repairIdValue}
          setRepairIdValue={setRepairIdValue}
          serialNumberValue={serialNumberValue}
          setSerialNumberValue={setSerialNumberValue}
          partNumberValue={partNumberValue}
          setPartNumberValue={setPartNumberValue}
          technicianIdValue={technicianIdValue}
          setTechnicianIdValue={setTechnicianIdValue}
          producerIdValue={producerIdValue}
          setProducerIdValue={setProducerIdValue}
          isIncompleteValue={isIncompleteValue}
          setIsIncopmleteValue={setIsIncopmleteValue}
          reportListExportCallback={reportListExportCallback}
          reportListLength={reportListLength}
        />
      )}
    </>
  );
};
