import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import AddValuation from "./AddValuation";
import ValuationOffer from "./ValuationOffer";
import "./styles.css";

const Mobile = ({
  setAddValuationModalActive,
  conversationList,
  setConfirmModalActive,
  selectedConversationId,
  setSelectedConversationId,
  conversationListLoading,
  setConfirmActionName,
}) => {
  return conversationListLoading ? (
    <PageSpinner subPage />
  ) : (
    <>
      <AddValuation setAddValuationModalActive={setAddValuationModalActive} />
      <div className="mobile-pricing">
        {conversationList.map((conversation) => (
          <ValuationOffer
            setAddValuationModalActive={setAddValuationModalActive}
            selectedConversationId={selectedConversationId}
            setSelectedConversationId={setSelectedConversationId}
            setConfirmModalActive={setConfirmModalActive}
            key={conversation.id}
            conversation={conversation}
            setConfirmActionName={setConfirmActionName}
          />
        ))}
      </div>
    </>
  );
};

export default Mobile;
