import React from "react";
import { useTranslation } from "react-i18next";
import Page from "stories/components/Page/Page";
import { HelpContent } from "./HelpContent";
import "./styles.css";

const Help = () => {
  const { t } = useTranslation();

  return (
    <Page heading={t("global.help")}>
      <HelpContent />
    </Page>
  );
};

export default Help;
