/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import Page from "stories/components/Page/Page";
import { Spinner } from "stories/components/Spinner/Spinner";
import Button from "stories/components/Button/Button";
import SelectField from "stories/components/SelectField/SelectField";
import Chip from "stories/components/Chip/Chip";
import Drawer from "stories/components/Drawer/Drawer";
import TextField from "stories/components/TextField/TextField";
import DatePicker from "stories/components/DatePicker/DatePicker";
import Stack from "stories/components/Stack/Stack";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import Pagination from "components/Pagination/Pagination";
import ico from "images/IconDown.svg";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import { isEmployee } from "utils/global-functions";
import RepairEventsDecode from "./RepairEventsDecode";

const RepairEvents = ({ variant = "admin", filterRepairId = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const app = useSelector((store) => store.app);
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const [fetching, setFetching] = useState(true);
  const [pageSize, setPageSize] = useState(variant === "admin" ? 50 : 999);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({ repairId: filterRepairId, serialNumber: "", dateFrom: null, dateUpTo: null, bool: false });
  const [drawerFilter, setDrawerFilter] = useState({ repairId: filterRepairId, serialNumber: "", dateFrom: null, dateUpTo: null, bool: false });
  const [drawerFilterErr, setDrawerFilterErr] = useState(false);
  const [events, setEvents] = useState([]);
  const [detailsVersion, setDetailsVersion] = useState(1);

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pageSize, pageNumber]);

  const handleFilterChange = (value, name) => {
    const obj = { ...filter };
    obj[name] = value;
    setFilter(obj);
  };

  const handleDrawerFilterChange = (value, name) => {
    const obj = { ...drawerFilter };
    obj[name] = value;
    setDrawerFilter(obj);
    if (name === "serialNumber") setDrawerFilterErr(value.length === 0 || value.length >= 3 ? false : true);
  };

  const formReset = () => {
    setDrawerFilter({ repairId: "", serialNumber: "", dateFrom: null, dateUpTo: null });
  };

  const getEvents = () => {
    setShowFilter(false);
    setFetching(true);
    agent.Repairs.repairEvents({
      id: user.id,
      token: user.token,
      pageSize,
      pageNumber,
      repairId: filter.repairId ? filter.repairId : null,
      serialNumber: filter.serialNumber ? filter.serialNumber : null,
      dateFrom: filter.dateFrom !== null ? filter.dateFrom.format("YYYY-MM-DD") : null,
      dateUpTo: filter.dateUpTo !== null ? filter.dateUpTo.format("YYYY-MM-DD") : null,
    }).then(
      (response) => {
        response.data.forEach((item) => {
          item.showDetails = false;
        });
        setEvents(response.data);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "getEvents.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const toggleDetails = (index) => {
    const arr = [...events];
    arr[index].showDetails = !arr[index].showDetails;
    setEvents(arr);
  };

  const toggleAll = () => {
    const arr = [...events];
    arr.forEach((item, index) => {
      item.showDetails = !item.showDetails;
    });
    setEvents(arr);
  };

  const content = (
    <>
      {variant === "admin" ? (
        <>
          <table>
            <tbody>
              <tr>
                <td className="filter-chips-wrapper">
                  {filter.repairId ? (
                    <Chip
                      onClick={() => {
                        handleFilterChange("", "repairId");
                      }}
                    >
                      {t("global.repairId")}: {filter.repairId}
                    </Chip>
                  ) : null}
                  {filter.serialNumber ? (
                    <Chip
                      onClick={() => {
                        handleFilterChange("", "serialNumber");
                      }}
                    >
                      {t("global.serialNumber")}: {filter.serialNumber}
                    </Chip>
                  ) : null}
                  {filter.dateFrom !== null ? (
                    <Chip
                      onClick={() => {
                        handleFilterChange(null, "dateFrom");
                      }}
                    >
                      {t("global.dateFrom")}: {filter.dateFrom.format("YYYY-MM-DD")}
                    </Chip>
                  ) : null}
                  {filter.dateUpTo !== null ? (
                    <Chip
                      onClick={() => {
                        handleFilterChange(null, "dateUpTo");
                      }}
                    >
                      {t("global.dateUpTo")}: {filter.dateUpTo.format("YYYY-MM-DD")}
                    </Chip>
                  ) : null}
                </td>
                <td className="text-end">
                  <Stack justifyContent="flex-end" alignItems="center" gap="var(--modal-buttons-gap)" flexDirection={desktop ? "row" : "column"}>
                    <div style={{ textAlign: "left" }}>
                      <PageSizeSelector
                        selectedPageSize={pageSize}
                        pageSizeOptions={[
                          { value: 10, label: 10 },
                          { value: 25, label: 25 },
                          { value: 50, label: 50 },
                          { value: 100, label: 100 },
                        ]}
                        onChange={(value) => {
                          setPageSize(value);
                          setPageNumber(1);
                        }}
                        disabled={fetching}
                      />
                    </div>
                    <div style={{ textAlign: "left", display: "flex", alignItems: "center", gap: "0.4rem" }}>
                      <span className="text--nowrap">{t("global.version")}</span>
                      <div style={{ minWidth: "6.5rem" }}>
                        <SelectField
                          size="small"
                          options={[
                            { value: 1, label: "V1" },
                            { value: 2, label: "V2" },
                          ]}
                          value={detailsVersion}
                          onChange={(value) => {
                            setDetailsVersion(value);
                          }}
                          disabled={fetching}
                        ></SelectField>
                      </div>
                    </div>
                    <div style={{ width: "2.5rem" }}></div>
                    <Button
                      variant="default"
                      onClick={() => {
                        handleFilterChange(!filter.bool, "bool");
                      }}
                      disabled={fetching}
                    >
                      {t("global.refresh")}
                    </Button>{" "}
                    <Button
                      variant="primary"
                      onClick={() => {
                        setDrawerFilter({ ...filter });
                        setShowFilter(true);
                      }}
                      disabled={fetching}
                    >
                      {t("global.filter")}
                    </Button>
                  </Stack>
                </td>
              </tr>
            </tbody>
          </table>
          <Drawer active={showFilter} title={t("global.filters")} setActive={setShowFilter}>
            <div>
              <div className="mb-2">
                <TextField
                  variant="minimalistNumber"
                  name="repairId"
                  label={t("global.repairId")}
                  placeholder=""
                  value={drawerFilter.repairId}
                  onChange={handleDrawerFilterChange}
                  disabled={fetching}
                />
              </div>
              <div className="mb-2">
                <TextField
                  name="serialNumber"
                  label={t("global.serialNumber")}
                  placeholder=""
                  maxLength={50}
                  value={drawerFilter.serialNumber}
                  onChange={handleDrawerFilterChange}
                  disabled={fetching}
                />
              </div>
              <div className="mb-2">
                <DatePicker
                  id="dateFrom"
                  label={t("global.dateFrom")}
                  placeholder=""
                  date={drawerFilter.dateFrom}
                  onChange={handleDrawerFilterChange}
                  variant="single"
                  openDirection="down"
                  language={user.lang}
                  disabled={fetching}
                />
              </div>
              <div className="mb-5">
                <DatePicker
                  id="dateUpTo"
                  label={t("global.dateUpTo")}
                  placeholder=""
                  date={drawerFilter.dateUpTo}
                  onChange={handleDrawerFilterChange}
                  variant="single"
                  openDirection="down"
                  language={user.lang}
                  disabled={fetching}
                />
              </div>
              <div className="text-end">
                <Button variant="default" onClick={formReset}>
                  {t("global.clearFilters")}
                </Button>{" "}
                <Button
                  variant="primary"
                  disabled={fetching || drawerFilterErr}
                  onClick={() => {
                    setPageNumber(1);
                    setFilter(drawerFilter);
                  }}
                >
                  {t("global.showResults")}
                </Button>
              </div>
            </div>
          </Drawer>
        </>
      ) : null}
      {fetching ? (
        <div className="mt-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div style={!desktop ? { overflowX: "scroll", width: "100%" } : null}>
            <table className="management-config mt-5">
              <thead>
                <tr>
                  {filterRepairId === "" ? <th>{t("global.repairId")}</th> : null}
                  {isEmployee(user) ? <th>{t("global.author")}</th> : null}
                  <th>{t("global.event")}</th>
                  <th>{t("global.datetime")}</th>
                  <th className="pointer no-select" onClick={toggleAll}>
                    <div style={{ lineHeight: "12px" }}>
                      <img src={ico} alt="" style={{ transform: "rotate(180deg)" }} />
                    </div>
                    <div style={{ lineHeight: "12px" }}>
                      <img src={ico} alt="" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {events.map((item, index) => (
                  <React.Fragment key={`${item.id}-event`}>
                    <tr
                      key={`${item.id}-event-data`}
                      className={item.hasValues ? "pointer" : ""}
                      onClick={() => {
                        toggleDetails(index);
                      }}
                    >
                      {filterRepairId === "" ? <td>{item.repairId}</td> : null}
                      {isEmployee(user) ? <td>{item.who ? item.who : "-"}</td> : null}
                      <td>
                        {typeof app.constants.repairEventType?.[item.eventType] === "object"
                          ? t(`constants.repair.eventtype.${app.constants.repairEventType[item.eventType].strVal}`)
                          : `${item.eventType} (${t("global.notDefinedConstant")})`}
                      </td>
                      <td>
                        <span className="text--nowrap">{item.createdAt.substring(0, 10)}</span>{" "}
                        <span className="text--nowrap">{item.createdAt.substring(11, item.createdAt.indexOf("."))}</span>
                      </td>
                      {item.hasValues ? (
                        <td>
                          <img
                            src={ico}
                            alt=""
                            className="pointer no-select"
                            style={{ transform: item.showDetails ? "rotate(180deg)" : "rotate(0deg)", transition: "0.5s" }}
                          />
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                    {item.hasValues && item.showDetails ? (
                      <>
                        {detailsVersion === 1 && desktop ? (
                          <tr key={`${item.id}-event-details`} id={`${item.id}-details`}>
                            <td colSpan={5} className="pt-3 pb-3">
                              <table>
                                <tbody>
                                  <tr style={{ boxShadow: "none" }}>
                                    {![60].includes(item.eventType) && (
                                      <td>
                                        <RepairEventsDecode eventType={item.eventType} value={item.valueBefore} rClasses="mt-2 mb-2" brickVariant="silver" />
                                      </td>
                                    )}
                                    {![60, 62].includes(item.eventType) && <td style={{ fontSize: "2rem", color: "var(--green)", padding: "0" }}>&#10140;</td>}
                                    {![62].includes(item.eventType) && (
                                      <td>
                                        <RepairEventsDecode eventType={item.eventType} value={item.valueAfter} rClasses="mt-2 mb-2" brickVariant="gray" />
                                      </td>
                                    )}
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ) : null}
                        {detailsVersion === 2 || !desktop ? (
                          <tr key={`${item.id}-event-details`} id={`${item.id}-details`}>
                            <td colSpan={5} className="p-4">
                              {![60].includes(item.eventType) && (
                                <>
                                  <div className="text--bold">{t("global.valueBefore")}:</div>
                                  <RepairEventsDecode eventType={item.eventType} value={item.valueBefore} rClasses="mt-2 mb-2" brickVariant="silver" />
                                </>
                              )}
                              {![62].includes(item.eventType) && (
                                <>
                                  <div className="text--bold">{t("global.valueAfter")}:</div>
                                  <RepairEventsDecode eventType={item.eventType} value={item.valueAfter} rClasses="mt-2 mb-2" brickVariant="gray" />
                                </>
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ) : null}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          {variant === "admin" ? (
            <div className="mt-5">
              <Pagination
                pageSize={pageSize}
                pageNumber={pageNumber}
                listLength={events.length}
                previousAction={() => {
                  setPageNumber(pageNumber - 1);
                }}
                continueAction={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );

  return variant === "admin" ? (
    <Page heading={<div className="heading--3">{t("panel.changelog")}</div>}>
      <div className="page-content">{!["technician", "reception", "admin"].includes(user.role) ? <h1>Access denied!</h1> : content}</div>
    </Page>
  ) : (
    content
  );
};

export default RepairEvents;
