import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "utils/config";
import { isClient, isCorpUser, isDevelopmentEnvironment, readCookie } from "utils/global-functions";
import { routes } from "./config";

export const extractIdentity = () => {
  const identity = readCookie("checksum");
  if (!identity) return {};
  const [id, token] = window.atob(identity).split("").reverse().join("").split(":");
  return { token, id: parseInt(id) };
};

export const userId = extractIdentity().id;
export const userToken = extractIdentity().token;
export const isUserLogged = () => userToken && userId;

export const getRoutesForNavPanel = (user) => {
  return routes[user.role].filter((route) => {
    if ((isClient(user) && !isCorpUser(user)) || user.companyId === 1) return route !== "/other-repairs";

    return !!route;
  });
};

export const getAppVersionForFooter = () => `(v.${process.env.REACT_APP_VERSION}${isDevelopmentEnvironment() ? "-beta" : ""})`;

export const getAndValidateLangFromSearchParam = (lang) => (SUPPORTED_LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE);
