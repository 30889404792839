import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Chip from "stories/components/Chip/Chip";
import Stack from "stories/components/Stack/Stack";
import { columnsWithSelectableOptions, columnsWithYesNoOptions } from "../config";
import { formatAppliedFiltersForApi } from "../utils";

const AppliedFiltersList = ({ repairListLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    repair: { appliedFilters },
    app: { constants },
  } = useSelector((store) => store);

  const formatTabValue = (key, value) => {
    if (columnsWithYesNoOptions.includes(key)) return t(`global.${!!value}`);

    return columnsWithSelectableOptions.includes(key) ? t(`constants.${key.replace(/([A-Z])/, ".$1").toLowerCase()}.${value}`) : value;
  };
  return (
    <Stack alignItems="center" gap="0.5rem" flexWrap="wrap">
      {Object.entries(formatAppliedFiltersForApi(appliedFilters, constants)).map(([key, value]) => (
        <Chip
          key={key}
          onClick={() => {
            if (!repairListLoading) dispatch({ type: "REPAIR_CLEAR_APPLIEDFILTER", filterName: key });
          }}
        >
          {`${t(`myRepairs.${key}`)}: ${formatTabValue(key, value)}`}
        </Chip>
      ))}
    </Stack>
  );
};

export default AppliedFiltersList;
