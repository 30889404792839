import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/Tooltip";
import "../commonStyles.css";
import "./styles.css";
import { getTextField } from "./utils";

const TextField = forwardRef(({ tooltipContent, tooltipZindex = 999, ...props }, ref) => (
  <div className={`input ${props.className ? props.className : ""}`}>
    {props.label &&
      (tooltipContent ? (
        <Tooltip zIndex={tooltipZindex} direction="bottom" content={tooltipContent}>
          <label className="input__label input__label--tooltip" htmlFor={props.name}>
            {props.label}
          </label>
        </Tooltip>
      ) : (
        <label className="input__label" htmlFor={props.name}>
          {props.label}
        </label>
      ))}
    {getTextField({ ...props, ref })}
    {props.error && <span className="input__error-message">{props.error}</span>}
    {props.helpText && <span className="input__help-text">{props.helpText}</span>}
  </div>
));

TextField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  buttonContent: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  error: PropTypes.string,
  variant: PropTypes.oneOf(["default", "multi", "number", "minimalistNumber", "search", "send", "multiSend"]),
  numberOfLines: PropTypes.number,
  disableResize: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default TextField;
