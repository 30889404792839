import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField } from "utils/global-functions";
import { countriesList } from "utils/countries";
import "./styles.css";

const AddressForm = ({ formErrors, values, setFieldValue, type, touched, errors, handleBlur, options = { showPhoneNumberFormatsTooltip: false } }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });
  const polishOption = { value: "PL", label: translatedCountryNames.of("PL") };
  const countryOptions = [
    ...countriesList
      .filter((country) => country.value.toUpperCase() !== "PL")
      .map(({ value }) => {
        return { value: value, label: translatedCountryNames.of(value) };
      }),
  ];
  countryOptions.unshift(polishOption);

  return (
    <div className="address-form">
      <TextField
        id={`${type}AddressPersonName`}
        name={`${type}AddressPersonName`}
        maxLength={50}
        value={values[`${type}AddressPersonName`]}
        onChange={(value) => setFieldValue(`${type}AddressPersonName`, value)}
        error={getErrorForInputField(errors?.[`${type}AddressPersonName`], touched?.[`${type}AddressPersonName`]) || formErrors?.[`${type}AddressPersonName`]}
        placeholder={t("global.provideFullName")}
        label={t("global.fullName")}
        onBlur={handleBlur}
      />
      <div className="address-form__group">
        <TextField
          id={`${type}AddressCompanyName`}
          name={`${type}AddressCompanyName`}
          maxLength={255}
          value={values[`${type}AddressCompanyName`]}
          onChange={(value) => setFieldValue(`${type}AddressCompanyName`, value)}
          error={
            getErrorForInputField(errors?.[`${type}AddressCompanyName`], touched?.[`${type}AddressCompanyName`]) || formErrors?.[`${type}AddressCompanyName`]
          }
          placeholder={t("global.provideCompanyName")}
          label={t("global.companyName")}
          onBlur={handleBlur}
        />
        <TextField
          id={`${type}AddressPhone`}
          name={`${type}AddressPhone`}
          tooltipContent={
            options.showPhoneNumberFormatsTooltip
              ? `${t("addReport.acceptedFormats")}: <ul class='tooltip-list'><li>123-456-789</li><li>12-345-67-89</li><li>123456789</li></ul>`
              : false
          }
          maxLength={50}
          value={values[`${type}AddressPhone`]}
          onChange={(value) => setFieldValue(`${type}AddressPhone`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressPhone`], touched?.[`${type}AddressPhone`]) || formErrors?.[`${type}AddressPhone`]}
          placeholder={t("global.provideTelephone")}
          label={t("global.phone")}
          onBlur={handleBlur}
        />
      </div>
      <div className="address-form__group">
        <TextField
          id={`${type}AddressEmail`}
          name={`${type}AddressEmail`}
          maxLength={255}
          value={values[`${type}AddressEmail`]}
          onChange={(value) => setFieldValue(`${type}AddressEmail`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressEmail`], touched?.[`${type}AddressEmail`]) || formErrors?.[`${type}AddressEmail`]}
          placeholder={t("global.provideEmail")}
          label={t("global.emailAddress")}
          onBlur={handleBlur}
        />
        <TextField
          id={`${type}AddressStreet`}
          name={`${type}AddressStreet`}
          maxLength={255}
          value={values[`${type}AddressStreet`]}
          onChange={(value) => setFieldValue(`${type}AddressStreet`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressStreet`], touched?.[`${type}AddressStreet`]) || formErrors?.[`${type}AddressStreet`]}
          placeholder={t("global.provideStreet")}
          label={t("global.street")}
          onBlur={handleBlur}
        />
      </div>
      <div className="address-form__group">
        <TextField
          id={`${type}AddressBuilding`}
          name={`${type}AddressBuilding`}
          maxLength={20}
          value={values[`${type}AddressBuilding`]}
          onChange={(value) => setFieldValue(`${type}AddressBuilding`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressBuilding`], touched?.[`${type}AddressBuilding`]) || formErrors?.[`${type}AddressBuilding`]}
          placeholder={t("global.provideBuildingNumber")}
          label={t("global.buildingNumber")}
          onBlur={handleBlur}
        />
        <TextField
          id={`${type}AddressPlace`}
          name={`${type}AddressPlace`}
          maxLength={20}
          value={values[`${type}AddressPlace`]}
          onChange={(value) => setFieldValue(`${type}AddressPlace`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressPlace`], touched?.[`${type}AddressPlace`]) || formErrors?.[`${type}AddressPlace`]}
          placeholder={t("global.provideHouseNumber")}
          label={t("global.houseNumber")}
          onBlur={handleBlur}
        />
      </div>
      <div className="address-form__group">
        <TextField
          id={`${type}AddressPostCode`}
          name={`${type}AddressPostCode`}
          maxLength={20}
          value={values[`${type}AddressPostCode`]}
          onChange={(value) => setFieldValue(`${type}AddressPostCode`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressPostCode`], touched?.[`${type}AddressPostCode`]) || formErrors?.[`${type}AddressPostCode`]}
          placeholder={t("global.providePostalCode")}
          label={t("global.postalCode")}
          onBlur={handleBlur}
        />
        <TextField
          id={`${type}AddressCity`}
          name={`${type}AddressCity`}
          maxLength={255}
          value={values[`${type}AddressCity`]}
          onChange={(value) => setFieldValue(`${type}AddressCity`, value)}
          error={getErrorForInputField(errors?.[`${type}AddressCity`], touched?.[`${type}AddressCity`]) || formErrors?.[`${type}AddressCity`]}
          placeholder={t("global.provideCity")}
          label={t("global.city")}
          onBlur={handleBlur}
        />
      </div>
      <SelectField
        id={`${type}AddressCountryId`}
        value={values[`${type}AddressCountryId`]}
        onChange={(value) => setFieldValue(`${type}AddressCountryId`, value)}
        error={getErrorForInputField(errors?.[`${type}AddressCountryId`], touched?.[`${type}AddressCountryId`]) || formErrors?.[`${type}AddressCountryId`]}
        placeholder={t("global.selectFromList")}
        onBlur={handleBlur}
        label={t("global.country")}
        options={type === "delivery" ? [polishOption] : countryOptions}
      />
    </div>
  );
};

export default AddressForm;
