import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import lodash from "lodash";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { ACTIVE_EMPLOYEE_STATE, DISABLED_BULK_REPAIR_STATES, INITIAL_STATE } from "utils/config";
import { bulkActionOptions, datePickerBulkActions, multilineFieldBulkActions, selectFieldBulkActions, technicianBulkActionOption } from "./config";

const BulkActionForm = ({ values, setFieldValue, formErrors }) => {
  const { t } = useTranslation();
  const {
    app: { constants },
    user,
  } = useSelector((store) => store);

  const selectFieldBulkActionOptions = {
    repairTypeSet: lodash.isEmpty(constants)
      ? []
      : constants.repairTypeArr
          ?.filter(({ intVal }) => intVal !== INITIAL_STATE)
          ?.map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.type.${strVal}`) };
          }),
    repairKindSet: lodash.isEmpty(constants)
      ? []
      : constants.repairKindArr
          ?.filter(({ intVal }) => intVal !== INITIAL_STATE)
          ?.map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.kind.${strVal}`) };
          }),
    repairStateSet: lodash.isEmpty(constants)
      ? []
      : constants.repairStateArr
          ?.filter(({ intVal }) => !DISABLED_BULK_REPAIR_STATES.includes(intVal) && intVal !== INITIAL_STATE)
          ?.map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.state.${strVal}`) };
          }),
    technicianIdSet: lodash.isEmpty(constants)
      ? []
      : Array.from(new Set([...constants.techniciansArr?.map(({ intVal }) => intVal), ...constants.employeesArr?.map(({ intVal }) => intVal)])).map((id) => {
          const employee = constants.employees?.[id];
          return { value: id, label: employee?.strVal, disabled: employee?.bgColor !== ACTIVE_EMPLOYEE_STATE };
        }),
  };

  return (
    <div className="group-action-modal__form">
      <SelectField
        value={values.action}
        onChange={(value) => {
          setFieldValue("action", value);
          if (datePickerBulkActions.includes(value)) setFieldValue("value", null);
          else setFieldValue("value", "");
        }}
        options={bulkActionOptions.map((bulkActionOption) => {
          return { label: t(`myRepairs.${bulkActionOption}`), value: bulkActionOption };
        })}
        placeholder={t("global.chooseFromList")}
        label={t("global.selectActionType")}
        isSearchable={false}
        error={formErrors.action}
      />
      {selectFieldBulkActions.includes(values.action) && (
        <SelectField
          label={values.action === technicianBulkActionOption ? t("global.chooseEmployee") : t("global.indicateValue")}
          placeholder={t("global.chooseFromList")}
          value={values.value}
          options={selectFieldBulkActionOptions[values.action]}
          onChange={(value) => setFieldValue("value", value)}
          error={formErrors.value}
        />
      )}
      {multilineFieldBulkActions.includes(values.action) && (
        <TextField
          variant="multi"
          numberOfLines={6}
          label={t("global.writeValue")}
          placeholder={t("global.provideValue")}
          value={values.value}
          onChange={(value) => setFieldValue("value", value)}
          error={formErrors.value}
        />
      )}
      {datePickerBulkActions.includes(values.action) && (
        <DatePicker
          label={t("global.indicateValue")}
          placeholder={t("global.chooseDate")}
          openDirection="up"
          date={values.value}
          language={user.lang}
          onChange={(value) => setFieldValue("value", value)}
          error={formErrors.value}
        />
      )}
    </div>
  );
};

export default BulkActionForm;
