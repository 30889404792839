import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Badge from "stories/components/Badge/Badge";

const RepairsListBodyItem = ({ heading, repair }) => {
  const {
    app: { constants },
  } = useSelector((store) => store);
  const { t } = useTranslation();

  const repairsStates = {
    repairKind: (
      <Badge
        content={t(`constants.repair.kind.${constants?.repairKind[repair.repairKind]?.strVal}`)}
        backgroundColor={constants?.repairKind[repair.repairKind]?.bgColor}
      />
    ),
    repairState: (
      <Badge
        content={t(`constants.repair.state.${constants?.repairState[repair.repairState]?.strVal}`)}
        backgroundColor={constants?.repairState[repair.repairState]?.bgColor}
      />
    ),
    repairType: (
      <Badge
        content={t(`constants.repair.type.${constants?.repairType[repair.repairType]?.strVal}`)}
        backgroundColor={constants?.repairType[repair.repairType]?.bgColor}
      />
    ),
  };

  return (
    <div className="other-repairs-list__body-item">
      <span className="paragraph--small text--subdued">{t(`global.${heading}`)}:</span>
      <span className="paragraph--small">{repairsStates[heading] || repair[heading] || "-"}</span>
    </div>
  );
};

export default RepairsListBodyItem;
