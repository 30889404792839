/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import lodash from "lodash";
import { filterErrorsFromCommonApiErrors, formatWarrantyDescription, formatWarrantyDetails } from "utils/global-functions";
import QRScanner from "components/QRScanner/QRSscanner";
import Button from "stories/components/Button/Button";
import Link from "stories/components/Link/Link";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import DescriptionList from "stories/components/DescriptionList/DescriptionList";
import SerialNumberModal from "../desktop/SerialNumberModal";

const SearchForWarrantyModal = ({ active, warrantyDetails, setWarrantyDetails, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { app } = useSelector((store) => store);
  const searchWarrantyTextField = useRef(null);
  const [warrantyInput, setWarrantyInput] = useState("");
  const [errors, setErrors] = useState([]);
  const [warrantyLoading, setWarrantyLoading] = useState(false);
  const [activeSNModal, setActiveSNModal] = useState(false);

  useEffect(() => {
    if (!warrantyLoading) handleSearchWarrantyTextFieldFocus();

    if (active)
      setTimeout(() => {
        handleSearchWarrantyTextFieldFocus();
      }, 500);
  }, [warrantyLoading, active]);

  const handleSearchWarrantyTextFieldFocus = () => {
    searchWarrantyTextField.current?.focus();
    searchWarrantyTextField.current?.select();
  };

  const checkWarrantySuccess = (response) => {
    if (!response.data.at(0)) throw new Error();
    setWarrantyDetails(response.data.at(0));
    setErrors([]);
  };

  const checkWarrantyError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, app.commonApiErrors.codeList);
    setErrors(filteredErrors.map((error) => error.code));
  };

  const checkWarranty = (value) => {
    setWarrantyLoading(true);
    agent.Resources.checkWarranty({ serialNumber: value || warrantyInput })
      .then(checkWarrantySuccess, checkWarrantyError)
      .catch(() => setErrors([302]))
      .finally(() => setWarrantyLoading(false));
  };

  if (!active) return;

  return (
    <Modal
      title={t("loginPage.checkWarranty")}
      onClose={onClose}
      footerActions={
        !lodash.isEmpty(warrantyDetails) && (
          <Button variant="primary" onClick={() => navigate(`/serial-number/${encodeURIComponent(warrantyDetails.serialNumber)}`)}>
            {t("serialNumber.productCard")}
          </Button>
        )
      }
    >
      <Stack flexDirection="column" gap="0.5rem">
        <TextField
          maxLength={50}
          loading={warrantyLoading}
          ref={searchWarrantyTextField}
          error={errors.at(0) && t(`errors.${errors.at(0)}`)}
          placeholder={t("loginPage.provideSerialNumber")}
          variant="search"
          value={warrantyInput}
          onChange={(value) => {
            setWarrantyInput(value);
            setWarrantyDetails({});
          }}
          onClick={() => checkWarranty()}
          specialActions={
            <QRScanner
              setResultValue={(value) => {
                setWarrantyDetails({});
                setWarrantyInput(value);
                checkWarranty(value);
              }}
            />
          }
        />
        <Link className="login-link" onClick={() => setActiveSNModal(true)}>
          {t("loginPage.serialNumberQuestion")}
        </Link>
      </Stack>
      {!lodash.isEmpty(warrantyDetails) && (
        <>
          <h4 className="mobile_warranty_heading_result">{t("loginPage.foundProduct")}</h4>
          <DescriptionList
            list={formatWarrantyDetails(warrantyDetails).map(([key, value]) => {
              return { term: t(`loginPage.${key}`), description: formatWarrantyDescription(key, value, t) };
            })}
          />
        </>
      )}
      <SerialNumberModal active={activeSNModal} onClose={() => setActiveSNModal(false)} />
    </Modal>
  );
};

export default SearchForWarrantyModal;
