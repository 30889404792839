import React from "react";
import { useTranslation } from "react-i18next";
import { DELIVERY_TYPE_ELMARK_COURIER } from "utils/config";
import AddressDetails from "./AddressDetails";

const DeliveryInfo = ({ summaryDetails }) => {
  const { t } = useTranslation();

  const deliveryTypes = {
    1: t("constants.delivery.type.personal"),
    2: t("constants.delivery.type.ownCourier"),
    3: t("constants.delivery.type.dhlCourier"),
    4: t("constants.delivery.type.toBeAgreed"),
  };

  return (
    <div>
      <span className="paragraph--small text--subdued">{deliveryTypes[summaryDetails.deliveryType]}</span>
      {summaryDetails.deliveryType === DELIVERY_TYPE_ELMARK_COURIER && <AddressDetails summaryDetails={summaryDetails} type="deliveryAddress" />}
    </div>
  );
};

export default DeliveryInfo;
