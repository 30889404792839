export const getTableData = ({ cellData }) => {
  let string1, string2, string3, string4, string5, string6, string7, string8, string9, string10, string11, string12, string13, string14;
  let stringFinal;
  string1 = cellData.substring(0, 15);
  string2 = cellData.substring(15, 30);
  string3 = cellData.substring(30, 45);
  string4 = cellData.substring(45, 60);
  string5 = cellData.substring(60, 75);
  string6 = cellData.substring(75, 90);
  string7 = cellData.substring(90, 105);
  string8 = cellData.substring(105, 120);
  string9 = cellData.substring(120, 135);
  string10 = cellData.substring(135, 150);
  string11 = cellData.substring(150, 165);
  string12 = cellData.substring(165, 180);
  string13 = cellData.substring(180, 190);
  string14 = cellData.substring(190, 200);
  if (cellData.length <= 30) {
    stringFinal = `${string1}\n${string2}`;
  }
  if (cellData.length > 30 && cellData.length <= 45) {
    stringFinal = `${string1}\n${string2}\n${string3}`;
  }
  if (cellData.length > 45 && cellData.length <= 60) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}`;
  }
  if (cellData.length > 60 && cellData.length <= 75) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}`;
  }
  if (cellData.length > 75 && cellData.length <= 90) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}`;
  }
  if (cellData.length > 90 && cellData.length <= 105) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}`;
  }
  if (cellData.length > 105 && cellData.length <= 120) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}`;
  }
  if (cellData.length > 120 && cellData.length <= 135) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}`;
  }
  if (cellData.length > 135 && cellData.length <= 150) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}\n${string10}`;
  }
  if (cellData.length > 150 && cellData.length <= 165) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}\n${string10}\n${string11}`;
  }
  if (cellData.length > 165 && cellData.length <= 180) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}\n${string10}\n${string11}\n${string12}`;
  }
  if (cellData.length > 180 && cellData.length <= 190) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}\n${string10}\n${string11}\n${string12}\n${string13}`;
  }
  if (cellData.length > 190 && cellData.length <= 200) {
    stringFinal = `${string1}\n${string2}\n${string3}\n${string4}\n${string5}\n${string6}\n${string7}\n${string8}\n${string9}\n${string10}\n${string11}\n${string12}\n${string13}\n${string14}`;
  }
  return stringFinal;
};

export const getTitle = ({ variant, t }) => {
  let title;
  if (variant === "receive") title = `${t("pdf.receiveConfirmNumber")} `;
  if (variant === "state") title = `${t("pdf.currentRepairStateNumber")} `;
  if (variant === "final") title = `${t("pdf.finalReportNumber")} `;
  return title;
};

export const getCountry = ({ t, countryId }) => {
  let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  let country;
  if (countryId) country = regionNames.of(`${countryId}`);
  if (country === "Poland") country = t("global.poland");
  return country;
};

export const sortAscendingOrder = (a, b) => {
  const formatedA = typeof a === "string" ? a.toUpperCase() : a;
  const formatedB = typeof b === "string" ? b.toUpperCase() : b;

  if (formatedA > formatedB) return 1;
  if (formatedA < formatedB) return -1;
  return 0;
};

export const getSvgWidth = (lang) => {
  if (lang === "en") {
    return 70;
  } else {
    return 50;
  }
};
export const getSvgMargin = (lang) => {
  if (lang === "en") {
    return 0;
  } else {
    return 10;
  }
};

export const getRepairSvgPath = (valueAfter) => {
  if (valueAfter === "1") return "M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9h5l-7 7-7-7h5V3h4v6z";
  if (valueAfter === "2") return "M4 3h16l2 4v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.004L4 3zm9 11v-4h-2v4H8l4 4 4-4h-3zm6.764-7l-1-2H5.237l-1 2h15.527z";
  if (valueAfter === "3")
    return "M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z";
  if (valueAfter === "11") return "M10 10.111V1l11 6v14H3V7z";
  if (valueAfter === "12") return "M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455z";
  if (valueAfter === "13")
    return "M0 112.5V422.3c0 18 10.1 35 27 41.3c87 32.5 174 10.3 261-11.9c79.8-20.3 159.6-40.7 239.3-18.9c23 6.3 48.7-9.5 48.7-33.4V89.7c0-18-10.1-35-27-41.3C462 15.9 375 38.1 288 60.3C208.2 80.6 128.4 100.9 48.7 79.1C25.6 72.8 0 88.6 0 112.5zM128 416H64V352c35.3 0 64 28.7 64 64zM64 224V160h64c0 35.3-28.7 64-64 64zM448 352c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM384 256c0 61.9-43 112-96 112s-96-50.1-96-112s43-112 96-112s96 50.1 96 112zM252 208c0 9.7 6.9 17.7 16 19.6V276h-4c-11 0-20 9-20 20s9 20 20 20h24 24c11 0 20-9 20-20s-9-20-20-20h-4V208c0-11-9-20-20-20H272c-11 0-20 9-20 20z";
  if (valueAfter === "21") return "M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z";
  if (valueAfter === "22") return "M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z";
  if (valueAfter === "23")
    return "M5.33 3.271a3.5 3.5 0 0 1 4.472 4.474L20.647 18.59l-2.122 2.121L7.68 9.867a3.5 3.5 0 0 1-4.472-4.474L5.444 7.63a1.5 1.5 0 1 0 2.121-2.121L5.329 3.27zm10.367 1.884l3.182-1.768 1.414 1.414-1.768 3.182-1.768.354-2.12 2.121-1.415-1.414 2.121-2.121.354-1.768zm-7.071 7.778l2.121 2.122-4.95 4.95A1.5 1.5 0 0 1 3.58 17.99l.097-.107 4.95-4.95z";
};

export const transformRepairSvg = (valueAfter) => {
  if (valueAfter === "3") return "scale(0.04, 0.04) translate(45, 60)";
  if (valueAfter === "12") return "scale(1, 1) ";
  if (valueAfter === "13") return "scale(0.04, 0.04) translate(15, 60)";
  else return "scale(1, 1)";
};

export const strokeWidthRepairSvg = (valueAfter) => {
  if (valueAfter === "3") return 25;
  if (valueAfter === "13") return 20;
  else return 1;
};

export const getLastSvgColor = (valueAfter) => {
  if (valueAfter === "1" || valueAfter === "2" || valueAfter === "3" || valueAfter === "21" || valueAfter === "22") return "#00a680";
  if (valueAfter === "0" || valueAfter === "11" || valueAfter === "12" || valueAfter === "13") return "#f4b53f";
  if (valueAfter === "23") return "#c9141e";
  else return 1;
};
