/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import agent from "api/agent";
import { CONVERSATION_VALUATION, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import AddValuationModal from "./AddValuationModal";
import ConfirmActionModal from "./ConfirmActionModal";

const Valuation = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [addValuationModalActive, setAddValuationModalActive] = useState(false);
  const [confirmModalActive, setConfirmModalActive] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [conversationListLoading, setConversationListLoading] = useState(true);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [refreshValuationList, setRefreshValuationList] = useState(false);
  const [confirmActionName, setConfirmActionName] = useState(null);
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { repairId } = useParams();

  const showConversationsSuccess = (response) => {
    const conversationsWithEffect = response.data
      .filter(({ effect }) => effect || effect === 0)
      .map(({ effect, parentId, text, attachments, id, isClosed, recipientId }) => {
        return {
          effect,
          id: parentId,
          resultText: text,
          resultAttachments: attachments,
          resultId: id,
          resultIsClosed: isClosed,
          resultRecipientId: recipientId,
        };
      });
    setConversationList(
      response.data
        .filter(({ amount, text, currency }) => amount && currency && text)
        .map((conversation) => {
          return lodash.merge(
            conversation,
            conversationsWithEffect.find((conversationWithEffect) => conversationWithEffect.id === conversation.id),
          );
        }),
    );
  };

  const showConversationsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const showConversations = () => {
    setConversationListLoading(true);
    agent.Conversations.conversationsList({
      id: user.id,
      token: user.token,
      conversationTypes: CONVERSATION_VALUATION,
      repairId: repairId,
      pageNumber: 1,
      pageSize: 999,
    })
      .then(showConversationsSuccess, showConversationsError)
      .finally(() => setConversationListLoading(false));
  };

  useEffect(() => {
    showConversations();
  }, [refreshValuationList]);

  return (
    <>
      {mobile ? (
        <Mobile
          setAddValuationModalActive={setAddValuationModalActive}
          conversationList={conversationList}
          setConfirmModalActive={setConfirmModalActive}
          selectedConversationId={selectedConversationId}
          setSelectedConversationId={setSelectedConversationId}
          conversationListLoading={conversationListLoading}
          setConfirmActionName={setConfirmActionName}
        />
      ) : (
        <Desktop
          setAddValuationModalActive={setAddValuationModalActive}
          conversationList={conversationList}
          setConfirmModalActive={setConfirmModalActive}
          selectedConversationId={selectedConversationId}
          setSelectedConversationId={setSelectedConversationId}
          conversationListLoading={conversationListLoading}
          setConfirmActionName={setConfirmActionName}
        />
      )}
      <AddValuationModal
        active={addValuationModalActive}
        setActive={setAddValuationModalActive}
        conversationList={conversationList}
        selectedConversationId={selectedConversationId}
        setSelectedConversationId={setSelectedConversationId}
        refreshValuationList={refreshValuationList}
        setRefreshValuationList={setRefreshValuationList}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <ConfirmActionModal
        active={confirmModalActive}
        setActive={setConfirmModalActive}
        actionName={confirmActionName}
        setActionName={setConfirmActionName}
        selectedConversationId={selectedConversationId}
        setSelectedConversationId={setSelectedConversationId}
        conversationList={conversationList}
        setRefreshValuationList={setRefreshValuationList}
        refreshValuationList={refreshValuationList}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
    </>
  );
};

export default Valuation;
