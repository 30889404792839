import React from "react";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { MAX_MOBILE_WIDTH, MIN_DESKTOP_WIDTH } from "utils/config";
import Radio from "stories/components/Radio/Radio";
import Stack from "stories/components/Stack/Stack";

const EmployeeTable = ({ employees, setFieldValue, values }) => {
  const { t } = useTranslation();

  if (!employees.length) return;

  const headings = ["", t("global.fullName"), t("global.email")];

  return (
    <div className="admin-first-panel-results">
      <MediaQuery minWidth={MIN_DESKTOP_WIDTH}>
        <table className="admin-table admin-table-employee">
          <thead className="admin-thead-employee admin-thead">
            <tr>
              {headings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody className="admin-tbody-employee admin-tbody">
            {employees.map(({ userId, personName, email }) => {
              return (
                <tr key={userId} onClick={() => setFieldValue("userId", userId)}>
                  <td>
                    <Radio name="employeeRadioGroup" checked={userId === values.userId} onChange={() => setFieldValue("userId", userId)} />
                  </td>
                  <td>{personName}</td>
                  <td>{email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </MediaQuery>
      <MediaQuery maxWidth={MAX_MOBILE_WIDTH}>
        <div className="mobile-report-first-panel__client-result-list">
          {employees.map(({ userId, personName, email }) => {
            return (
              <div key={userId} className="mobile-report-first-panel__client-result" onClick={() => setFieldValue("userId", userId)}>
                <Stack>
                  <Radio name="clientRadioGroup" checked={userId === values.userId} onChange={() => setFieldValue("userId", userId)} />
                  <span className="text--red text--underline">{personName}</span>
                </Stack>
                <div className="mobile-report-first-panel__client-result-info">
                  <span>{`${t("global.email")} : ${email}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </MediaQuery>
    </div>
  );
};

export default EmployeeTable;
