import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import DhlLogoIcon from "images/dhl.svg";
import agent from "api/agent";
import AddressForm from "components/AddressForm/AddressForm";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import Radio from "stories/components/Radio/Radio";
import { MAX_MOBILE_WIDTH, RETURN_TYPE_ELMARK_COURIER, RETURN_TYPE_PERSONAL } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { formatBodyForSetRepairReturnAddress, formatRepairDetails } from "./utils";
import { useMediaQuery } from "react-responsive";

const EditReturnAddressModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const {
    app: { commonApiErrors },
    user,
    repair: { repairDetails },
  } = useSelector((store) => store);
  const [formErrors, setFormErrors] = useState({});
  const [repairReturnAddressLoading, setRepairReturnAddressLoading] = useState(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const setRepairReturnAddressSuccess = (response, body) => {
    if (response.result === 301) {
      setFormErrors({});
      dispatch({ type: "REPAIR_SET_REPAIR_DETAILS", repairDetails: formatRepairDetails(repairDetails, body) });
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairReturnAddressError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const setRepairReturnAddress = (values) => {
    const body = formatBodyForSetRepairReturnAddress(values);
    setRepairReturnAddressLoading(true);
    agent.Repairs.setRepairReturnAddress({ id: user.id, token: user.token, repairId: repairDetails.repairId, ...body })
      .then((response) => setRepairReturnAddressSuccess(response, body), setRepairReturnAddressError)
      .finally(() => setRepairReturnAddressLoading(false));
  };

  if (!active) return;

  return (
    <Formik
      initialValues={{
        returnAddressBuilding: repairDetails.returnAddressBuilding ?? "",
        returnAddressCity: repairDetails.returnAddressCity ?? "",
        returnAddressCompanyName: repairDetails.returnAddressCompanyName ?? "",
        returnAddressCountryId: repairDetails.returnAddressCountryId ?? "",
        returnAddressEmail: repairDetails.returnAddressEmail ?? "",
        returnAddressPersonName: repairDetails.returnAddressPersonName,
        returnAddressPhone: repairDetails.returnAddressPhone ?? "",
        returnAddressPlace: repairDetails.returnAddressPlace ?? "",
        returnAddressPostCode: repairDetails.returnAddressPostCode ?? "",
        returnAddressStreet: repairDetails.returnAddressStreet ?? "",
        returnType: repairDetails.returnType,
      }}
    >
      {({ values, setFieldValue, errors, touched, handleBlur }) => (
        <Modal
          size="big"
          footerActions={
            <Stack gap="var(--modal-buttons-gap)">
              <Button size={mobile ? "small" : "default"} disabled={repairReturnAddressLoading} onClick={() => setActive(false)}>
                {t("global.cancel")}
              </Button>
              <Button size={mobile ? "small" : "default"} loading={repairReturnAddressLoading} onClick={() => setRepairReturnAddress(values)} variant="primary">
                {t("global.save")}
              </Button>
            </Stack>
          }
          onClose={() => setActive(false)}
          title={t("myRepairs.editReturnAddress")}
        >
          <div className="delivery-info-return-address__radio-container">
            <Radio
              checked={values.returnType === RETURN_TYPE_PERSONAL}
              onChange={() => setFieldValue("returnType", RETURN_TYPE_PERSONAL)}
              label={t("constants.return.type.personal")}
              name="returnType"
            />
            <div className="delivery-info-return-address__radio-wrapper">
              <Radio
                checked={values.returnType === RETURN_TYPE_ELMARK_COURIER}
                onChange={() => setFieldValue("returnType", RETURN_TYPE_ELMARK_COURIER)}
                label={t("constants.return.type.dhlCourier")}
                name="returnType"
              />
              <img className="delivery-info-return-address__icon-wrapper no-select" src={DhlLogoIcon} alt="dhl_logo" />
            </div>
          </div>
          {values.returnType === RETURN_TYPE_ELMARK_COURIER && (
            <div className="repair-info-form">
              <AddressForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                formErrors={formErrors}
                touched={touched}
                handleBlur={handleBlur}
                type="return"
              />
            </div>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default EditReturnAddressModal;
