/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { sortDescendingOrder } from "utils/global-functions";
import RepairStepsDropdown from "./RepairStepsDropdown";
import RepairStepsIcon from "./RepairStepsIcon";
import { canAddStep, canDeleteStep, getFinalStepColor } from "./utils";

const RepairSteps = ({ repairSteps }) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const repairStepsContainer = useRef(null);
  const {
    app: { constants },
  } = useSelector((store) => store);
  const [firstStepsLength, setFristStepsLength] = useState(12);

  useEffect(() => {
    if (canDeleteStep(repairStepsContainer)) setFristStepsLength((state) => state - 1);
    if (canAddStep(repairStepsContainer, repairSteps, firstStepsLength)) setFristStepsLength((state) => state + 1);
  }, [windowSize.width, firstStepsLength]);

  if (!repairSteps?.length) return;

  const restSteps = [...repairSteps.sort((a, b) => sortDescendingOrder(a.id, b.id))];
  const firstSteps = restSteps.splice(0, firstStepsLength);

  return (
    <div ref={repairStepsContainer} className="repair-steps">
      {firstSteps
        .sort((a, b) => sortDescendingOrder(a.id, b.id))
        .map(({ valueAfter, id, createdAt }, index) => (
          <React.Fragment key={id}>
            {restSteps?.length > 0 && index === firstSteps.length - 1 ? (
              <RepairStepsDropdown steps={[firstSteps[index], ...restSteps]} />
            ) : (
              <Tooltip
                key={id}
                direction="bottom"
                content={`<div class="text--left">${t(
                  `constants.repair.state.${constants?.repairState?.[parseInt(valueAfter)]?.strVal}`,
                )}<div class="text--green">${moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</div></div>`}
              >
                <div style={getFinalStepColor(index, valueAfter, constants)} className={`repair-steps__item`}>
                  <RepairStepsIcon iconColor={index === 0 ? "var(--white)" : "var(--primary-color)"} iconName={valueAfter} />
                </div>
              </Tooltip>
            )}
            {index < firstSteps.length - 1 && repairSteps.length > 1 && <div className="repair-steps__line" />}
          </React.Fragment>
        ))}
    </div>
  );
};

export default RepairSteps;
