import React from "react";
import { useTranslation } from "react-i18next";

const EmptyPage = () => {
  const { t } = useTranslation();

  return (
    <div className="details-gallery__empty-page">
      <h6 className="heading--6">{t("myRepairs.photosEmptyPage")}</h6>
    </div>
  );
};

export default EmptyPage;
