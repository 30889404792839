import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "stories/components/Button/Button";
import { readCookie, writeCookie } from "utils/global-functions";

const CookiesSticker = () => {
  const { t } = useTranslation();
  const [cookiesStickerInactive, setCookiesStickerInactive] = useState(readCookie("allowCookie"));

  if (cookiesStickerInactive) return;

  return (
    <div className="cookies-sticker">
      <span className="paragrapgh--small">
        {t("CookiesPolicy.cookiesStickerInfo1")}
        <Link className="custom-link" to="/cookie-policy">
          {t("CookiesPolicy.cookiesLink")}
        </Link>{" "}
        {t("CookiesPolicy.cookiesStickerInfo2")}
      </span>
      <Button
        onClick={() => {
          writeCookie({ key: "allowCookie", value: "true", days: 365 });
          setCookiesStickerInactive(readCookie("allowCookie"));
        }}
        variant="primary"
      >
        {t("global.okClose")}
      </Button>
    </div>
  );
};

export default CookiesSticker;
