import React, { useState } from "react";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { isAdmin, isEmployee } from "utils/global-functions";
import RatingForm from "./RatingForm";
import RatingResult from "./RatingResult";
import { useTranslation } from "react-i18next";

const AdminRating = ({
  conversationListRefresh,
  setConversationListRefresh,
  ratingResult,
  ratingInvitationResult,
  refreshRepairDetails,
  setRefreshRepairDetails,
  setRejectRatingModalActive,
}) => {
  const { user } = useSelector((store) => store);
  const { t } = useTranslation();
  const [adminRatingEditMode, setAdminRatingEditMode] = useState(isAdmin(user) && lodash.isEmpty(ratingResult));

  if (!isEmployee(user)) return;

  return (
    <div className="rating-wrapper">
      <h6 className="heading--6 heading--large-spacing">{t("myRepairs.adminRating")}</h6>
      {adminRatingEditMode ? (
        <RatingForm
          ratingResult={ratingResult}
          conversationListRefresh={conversationListRefresh}
          setConversationListRefresh={setConversationListRefresh}
          ratingInvitationResult={ratingInvitationResult}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
          setRejectRatingModalActive={setRejectRatingModalActive}
          setEditMode={setAdminRatingEditMode}
        />
      ) : (
        <RatingResult ratingResult={ratingResult} setEditMode={setAdminRatingEditMode} />
      )}
    </div>
  );
};

export default AdminRating;
