import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { MAX_MOBILE_WIDTH } from "utils/config";

const TechnicianWarningModal = ({ active, setActive, onAction, loading }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  if (!active) return;

  return (
    <Modal
      title={t("global.attention")}
      onClose={() => setActive(false)}
      footerActions={
        <Stack gap="var(--modal-buttons-gap)">
          <Button size={mobile ? "small" : "default"} disabled={loading} onClick={() => setActive(false)}>
            {t("global.cancel")}
          </Button>
          <Button size={mobile ? "small" : "default"} loading={loading} variant="primary" onClick={onAction}>
            {t("global.proceed")}
          </Button>
        </Stack>
      }
    >
      {t("myRepairs.technicianChangeConfirmationMessage")}
    </Modal>
  );
};

export default TechnicianWarningModal;
