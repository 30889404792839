import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { setDocumentTitle, writeCookie } from "utils/global-functions";
import en from "../../../images/en.png";
import pl from "../../../images/pl.png";
import "./LangSwitchGlobe.css";

const langs = {
  pl: { nativeName: "Polski", flag: <img src={pl} className="flag-globe" alt=""></img> },
  en: { nativeName: "English", flag: <img src={en} className="flag-globe" alt=""></img> },
};

const LangSwitchGlobe = ({ variant = "icons", weight = "bold" }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="lang-globe-dropdown">
      <button className="dropdown-toggle lang-globe-dropdown-button" type="button" id="accountDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7.25" />
          <path d="M11.25 8C11.25 10.118 10.8192 11.9983 10.1576 13.3214C9.47361 14.6894 8.66745 15.25 8 15.25C7.33255 15.25 6.52639 14.6894 5.84239 13.3214C5.18083 11.9983 4.75 10.118 4.75 8C4.75 5.88205 5.18083 4.00168 5.84239 2.67856C6.52639 1.31057 7.33255 0.75 8 0.75C8.66745 0.75 9.47361 1.31057 10.1576 2.67856C10.8192 4.00168 11.25 5.88205 11.25 8Z" />
          <line x1="1" y1="8.25" x2="15" y2="8.25" />
          <line x1="1" y1="8.25" x2="15" y2="8.25" />
        </svg>
        <span className={`lang-globe-span-${weight}`}>{t("global.langLabel")}</span>
      </button>
      <ul className={`dropdown-menu lang-globe-popup ${variant}`} aria-labelledby="accountDropdownMenu" id="ul">
        {Object.keys(langs).map((lang) => (
          <li
            className="lang-globe-btn"
            key={lang}
            type="submit"
            onClick={() => {
              i18n.changeLanguage(lang);
              document.documentElement.setAttribute("lang", lang);
              setDocumentTitle(t);
              writeCookie({ value: lang, key: "lang" });
            }}
          >
            {langs[lang].flag}
            <span>{langs[lang].nativeName}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

LangSwitchGlobe.propTypes = {
  variant: PropTypes.oneOf(["left", "icons"]),
  weight: PropTypes.oneOf(["blod", "normal"]),
};

export default LangSwitchGlobe;
