import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import Tick from "images/Tick.svg";

const ProsCard = () => {
  const { t } = useTranslation();

  return (
    <div className="login_card login_card--lower_top_padding">
      <h3 className="secondary_login_heading bottom_margin">{t("loginPage.rmaPros")}</h3>
      <div className="pros_list">
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros1")}</span>
        </Stack>
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros2")}</span>
        </Stack>
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros3")}</span>
        </Stack>
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros4")}</span>
        </Stack>
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros5")}</span>
        </Stack>
        <Stack alignContent="center" alignItems="center" gap="0.75rem">
          <img className="tick_icon" src={Tick} alt="-" />
          <span>{t("loginPage.pros6")}</span>
        </Stack>
      </div>
    </div>
  );
};

export default ProsCard;
