import React from "react";
import lodash from "lodash";
import { Page, Text, View, Image, Document, Font, Svg, Line, Path, Circle } from "@react-pdf/renderer";
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from "@david.kucsai/react-pdf-table";
import { useTranslation } from "react-i18next";
import ea from "images/ea.png";
import font from "../../../../../fonts/hk-grotesk/HKGrotesk-Regular.woff";
import {
  getTableData,
  getTitle,
  getCountry,
  sortAscendingOrder,
  getSvgWidth,
  getSvgMargin,
  getRepairSvgPath,
  transformRepairSvg,
  strokeWidthRepairSvg,
  getLastSvgColor,
} from "./utils";
import { styles } from "./pdfStyles";

export const PdfDocument = ({ variant, repair, repairSteps, replacementInfo, todayDate, constants, lang }) => {
  const { t } = useTranslation();

  return (
    <Document title={`Raport-${repair?.repairId ?? "from-repair"}`} author="Elmark" subject="Service">
      <Page style={styles.page}>
        <View style={styles.pageView}>
          <View style={styles.heading}>
            <View style={{ flexDirection: "row", borderBottom: 0.5, borderBottomColor: "000", paddingBottom: 4 }}>
              <Image style={styles.logo} src={ea} />
              <View style={{ flexDirection: "column", paddingLeft: 8, color: "grey", fontSize: 7 }}>
                <Text style={{ color: "000", lineHeight: 1 }}>{constants?.elmarkHeader?.[1]?.strVal}</Text>
                <Text style={{ lineHeight: 1 }}>{constants?.elmarkHeader?.[2]?.strVal}</Text>
                <Text style={{ lineHeight: 1.1 }}>{constants?.elmarkHeader?.[3]?.strVal}</Text>
                <Text style={{ lineHeight: 1 }}>{constants?.elmarkHeader?.[4]?.strVal}</Text>
                <Text style={{ lineHeight: 1 }}>{constants?.elmarkHeader?.[5]?.strVal}</Text>
              </View>
            </View>

            <Text style={styles.date}>{todayDate}</Text>
          </View>
          <View style={styles.title}>
            <View style={styles.titleText}>
              <Text>{getTitle({ variant: variant, t: t })}</Text>
              <Text style={styles.repairId}>{` ${repair?.repairId ?? "-"}`}</Text>
            </View>
            <Image style={styles.qr} src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${process.env.REACT_APP_URL}${repair?.repairId}/info`} />
          </View>
          <View style={{ marginTop: -25 }}>
            <Text style={styles.sectionTitle}>{t("pdf.clientData")}</Text>
          </View>
          <View style={styles.clientDataWrapper}>
            <View style={styles.clientData}>
              <Text style={styles.dataTitle}>{t("addReport.dataToInvoice")}</Text>

              <View style={styles.deliveryAddress}>
                {repair?.invoice?.taxNumber ? (
                  <View style={styles.textWrapper}>
                    <Text style={styles.greyTextFront}>{t("global.taxNumber")}</Text>
                    <Text style={styles.textBack}>{repair?.invoice?.taxNumber ?? "-"}</Text>
                  </View>
                ) : null}
                {repair?.invoice?.companyName ? (
                  <View style={styles.textWrapper}>
                    <Text style={styles.greyTextFront}>{t("global.companyName")}</Text>
                    <Text style={styles.textBack}>{repair?.invoice?.companyName ?? "-"}</Text>
                  </View>
                ) : null}
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.street")}</Text>
                  <Text style={styles.textBack}>{repair?.invoice?.street ?? "-"}</Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.postalCode")}</Text>
                  <Text style={styles.textBack}>{repair?.invoice?.postCode ?? "-"}</Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.city")}</Text>
                  <Text style={styles.textBack}>{repair?.invoice?.city ?? "-"}</Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.country")}</Text>
                  <Text style={styles.textBack}>{repair?.invoice?.countryId ? getCountry({ t: t, countryId: repair?.invoice?.countryId }) : "-"}</Text>
                </View>
              </View>
            </View>

            <View style={styles.clientData}>
              <Text style={styles.dataTitle}>{t("global.contactDetails")}</Text>
              <View style={styles.deliveryAddress}>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.fullName")}</Text>
                  <Text style={styles.textBack}>{repair?.contactPerson ?? repair?.user?.personName ?? "-"}</Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.phone")}</Text>
                  <Text style={styles.textBack}>{repair?.contactPhone ?? repair?.user?.phone ?? "-"}</Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text style={styles.greyTextFront}>{t("global.email")}</Text>
                  <Text style={styles.textBack}>{repair?.contactEmail ?? repair?.user?.email ?? "-"}</Text>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.sectionTitle}>{t("pdf.product")}</Text>
            <View>
              <Table
                data={[
                  {
                    serialNumber: repair?.serialNumber
                      ? repair?.serialNumber?.length <= 18
                        ? repair?.serialNumber
                        : getTableData({ cellData: repair?.serialNumber ?? "-" })
                      : "-",
                    symbol: repair?.partNumber
                      ? repair?.partNumber.length <= 18
                        ? repair?.partNumber
                        : getTableData({ cellData: repair?.partNumber ?? "-" })
                      : "-",
                    description: repair?.description
                      ? repair?.description.length <= 18
                        ? repair?.description
                        : getTableData({ cellData: repair?.description ?? "-" })
                      : "-",
                    producer: repair?.producerId ? constants.producers?.[repair?.producerId]?.strVal : "-",
                    sellDate: repair?.sellDate ?? "-",
                    warranty:
                      repair?.warrantyUnder !== null
                        ? repair?.warrantyUnder === 1
                          ? `${t("pdf.warrantyUntil")} ${repair?.warrantyEnd ?? "-"}`
                          : t("addReport.warrantyExpired")
                        : "-",
                  },
                ]}
              >
                <TableHeader>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("global.serialNumber")}</Text>
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("global.partNumber")}</Text>
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("pdf.productDesc")}</Text>
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("global.producer")}</Text>
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("loginPage.sellDate")}</Text>
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    <Text>{t("global.warrantyStatus")}</Text>
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.serialNumber} />
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.symbol} />
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.description} />
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.producer} />
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.sellDate} />
                  <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.warranty} />
                </TableBody>
              </Table>
            </View>

            <View style={styles.additional}>
              <View>
                <Text style={styles.additionalTitle}>{t("myRepairs.factoryResetAgreement")}</Text>
                <Text>{repair?.factoryResetAgreement === null ? t("global.noData") : repair?.factoryResetAgreement ? t("global.yes") : t("global.no")}</Text>
              </View>
              <View>
                <Text style={styles.additionalTitle}>{t("myRepairs.faultDescription")}</Text>
                <Text>{repair?.faultDescription?.replace(/\r/g, "")}</Text>
              </View>
              <View>
                <Text style={styles.additionalTitle}>{t("addReport.extraEquipment")}</Text>
                <Text>{repair?.equipmentDescription === "" ? "-" : repair?.equipmentDescription?.replace(/\r/g, "")}</Text>
              </View>
            </View>

            {repair?.replacementSerialNumber && variant !== "receive" && (
              <View style={{ marginBottom: 20 }}>
                <Text style={styles.sectionTitle}>{t("pdf.productReplacement")}</Text>

                <Table
                  data={[
                    {
                      serialNumber: repair?.replacementSerialNumber
                        ? repair?.replacementSerialNumber.length <= 16
                          ? repair?.replacementSerialNumber
                          : getTableData({ cellData: repair?.replacementSerialNumber ?? "-" })
                        : "-",
                      symbol: replacementInfo?.partNumber
                        ? replacementInfo.partNumber.length <= 16
                          ? replacementInfo?.partNumber
                          : getTableData({ cellData: replacementInfo.partNumber ?? "-" })
                        : "-",
                      description: replacementInfo?.description
                        ? replacementInfo.description.length <= 16
                          ? replacementInfo?.description
                          : getTableData({ cellData: replacementInfo.description ?? "-" })
                        : "-",
                      producer: replacementInfo?.producer ?? "-",
                      sellDate: replacementInfo?.sellDate ?? "-",
                      warranty:
                        replacementInfo?.warrantyUnder !== null
                          ? replacementInfo?.warrantyUnder === 1
                            ? `${t("pdf.warrantyUntil")} ${replacementInfo?.warrantyEnd}`
                            : t("addReport.warrantyExpired")
                          : "-",
                    },
                  ]}
                >
                  <TableHeader>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("global.serialNumber")}</Text>
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("global.partNumber")}</Text>
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("pdf.productDesc")}</Text>
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("global.producer")}</Text>
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("loginPage.sellDate")}</Text>
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      <Text>{t("global.warrantyStatus")}</Text>
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.serialNumber} />
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.symbol} />
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.description} />
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.producer} />
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.sellDate} />
                    <DataTableCell style={styles.tableBodyCell} getContent={(r) => r.warranty} />
                  </TableBody>
                </Table>
              </View>
            )}
          </View>

          <View>
            <Text style={styles.sectionTitle}>{t("pdf.deliveryPickup")}</Text>
            <View style={styles.deliveryWrapper}>
              {variant !== "final" && (
                <View style={styles.deliveryData}>
                  <Text style={styles.deliveryTitle}>{t("addReport.deliveryMethod")}</Text>
                  <View>
                    {repair?.deliveryType === 1 ? <Text>{t("constants.delivery.type.personal")}</Text> : null}
                    {repair?.deliveryType === 2 ? <Text>{t("constants.delivery.type.ownCourier")}</Text> : null}
                    {repair?.deliveryType === 3 ? (
                      <>
                        <Text>{t("constants.delivery.type.dhlCourier")}</Text>

                        <View style={styles.deliveryAddress}>
                          <View style={{ flexDirection: "row", marginTop: 5 }}>
                            <Text style={styles.greyTextFront}>{t("myRepairs.packageSize")}</Text>
                            <View style={styles.textBack}>
                              {repair?.deliveryPackages ? (
                                JSON.parse(repair?.deliveryPackages).map(({ type, quantity }, index) => {
                                  return <Text key={index}>{`${constants?.packageType?.[type]?.strVal} (${quantity} ${t("global.pcs")})`}</Text>;
                                })
                              ) : (
                                <Text>-</Text>
                              )}
                            </View>
                          </View>
                          {repair?.deliveryAddressCompanyName ? (
                            <View style={styles.textWrapper}>
                              <Text style={styles.greyTextFront}>{t("global.companyName")}</Text>
                              <Text style={styles.textBack}>{repair?.deliveryAddressCompanyName ?? "-"}</Text>
                            </View>
                          ) : null}
                          <View style={styles.textWrapper}>
                            <Text style={styles.greyTextFront}>{t("global.fullName")}</Text>
                            <Text style={styles.textBack}>{repair?.deliveryAddressPersonName ?? "-"}</Text>
                          </View>
                          <View style={styles.textWrapper}>
                            <Text style={styles.greyTextFront}>{t("global.phone")}</Text>
                            <Text style={styles.textBack}>{repair?.deliveryAddressPhone ?? "-"}</Text>
                          </View>
                          <View style={styles.textWrapper}>
                            <Text style={styles.greyTextFront}>{t("global.email")}</Text>
                            <Text style={styles.textBack}>{repair?.deliveryAddressEmail ?? "-"}</Text>
                          </View>
                          <View style={styles.textWrapper}>
                            <Text style={styles.greyTextFront}>{t("global.address")}</Text>
                            <View style={styles.textBack}>
                              {repair?.deliveryAddressPlace ? (
                                <Text>{`${repair?.deliveryAddressStreet ?? "-"} ${repair?.deliveryAddressBuilding ?? ""}/${
                                  repair?.deliveryAddressPlace
                                }`}</Text>
                              ) : (
                                <Text>{`${repair?.deliveryAddressStreet ?? "-"} ${repair?.deliveryAddressBuilding ?? ""}`}</Text>
                              )}
                              <Text>{`${repair?.deliveryAddressPostCode ?? ""} ${repair?.deliveryAddressCity ?? ""}`}</Text>
                              <Text>{repair?.deliveryAddressCountryId ? getCountry({ t: t, countryId: repair?.deliveryAddressCountryId }) : ""}</Text>
                            </View>
                          </View>
                        </View>
                      </>
                    ) : null}
                    {repair?.deliveryType === 4 ? <Text>{t("constants.delivery.type.toBeAgreed")}</Text> : null}
                  </View>
                </View>
              )}
              <View style={styles.deliveryData}>
                <Text style={styles.deliveryTitle}>{t("addReport.pickupMethod")}</Text>
                <View>
                  {repair?.returnType === 1 ? <Text>{t("constants.return.type.personal")}</Text> : null}
                  {repair?.returnType === 2 ? (
                    <>
                      <Text style={{ marginBottom: 5 }}>{t("constants.return.type.dhlCourier")}</Text>

                      <View style={styles.deliveryAddress}>
                        {repair?.returnAddressCompanyName ? (
                          <View style={styles.textWrapper}>
                            <Text style={styles.greyTextFront}>{t("global.companyName")}</Text>
                            <Text style={styles.textBack}>{repair?.returnAddressCompanyName ?? "-"}</Text>
                          </View>
                        ) : null}
                        <View style={styles.textWrapper}>
                          <Text style={styles.greyTextFront}>{t("global.fullName")}</Text>
                          <Text style={styles.textBack}>{repair?.returnAddressPersonName ?? "-"}</Text>
                        </View>
                        <View style={styles.textWrapper}>
                          <Text style={styles.greyTextFront}>{t("global.phone")}</Text>
                          <Text style={styles.textBack}>{repair?.returnAddressPhone ?? "-"}</Text>
                        </View>
                        <View style={styles.textWrapper}>
                          <Text style={styles.greyTextFront}>{t("global.email")}</Text>
                          <Text style={styles.textBack}>{repair?.returnAddressEmail ?? "-"}</Text>
                        </View>
                        <View style={styles.textWrapper}>
                          <Text style={styles.greyTextFront}>{t("global.address")}</Text>
                          <View style={styles.textBack}>
                            {repair?.returnAddressPlace ? (
                              <Text>{`${repair?.returnAddressStreet ?? "-"} ${repair?.returnAddressBuilding ?? ""}/${repair?.returnAddressPlace ?? ""}`}</Text>
                            ) : (
                              <Text>{`${repair?.returnAddressStreet ?? "-"} ${repair?.returnAddressBuilding ?? ""}`}</Text>
                            )}
                            <Text>{`${repair?.returnAddressPostCode ?? ""} ${repair?.returnAddressCity ?? ""}`}</Text>
                            <Text>{repair?.returnAddressCountryId ? getCountry({ t: t, countryId: repair?.returnAddressCountryId }) : ""}</Text>
                          </View>
                        </View>
                      </View>
                    </>
                  ) : null}
                  {repair?.returnType === 3 ? <Text>{t("constants.return.type.toBeAgreed")}</Text> : null}
                </View>
              </View>
            </View>
          </View>
          {variant !== "receive" && (
            <View>
              {variant === "state" && !lodash.isEmpty(repairSteps) && (
                <View wrap={false}>
                  <Text style={[styles.sectionTitle, { marginBottom: 0 }]}>{t("pdf.repairProcess")}</Text>
                  <View style={repairSteps.length > 5 ? [styles.summarySvgWrapper, { marginBottom: 30 }] : styles.summarySvgWrapper}>
                    {repairSteps
                      .sort((a, b) => sortAscendingOrder(a.id, b.id))
                      .slice(0, repairSteps?.length - 1)
                      .map(({ valueAfter, id, createdAt }) => (
                        <View key={id} style={{ flexDirection: "row" }}>
                          <View style={[styles.singleSvgWrapper, { width: getSvgWidth(lang), marginHorizontal: getSvgMargin(lang) }]}>
                            <Svg
                              style={{
                                width: 50,
                                height: 50,
                                padding: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: 8,
                              }}
                            >
                              <Path
                                d={getRepairSvgPath(valueAfter)}
                                fill={valueAfter === "12" ? "000" : "none"}
                                stroke={valueAfter === "12" ? "white" : "000"}
                                strokeWidth={strokeWidthRepairSvg(valueAfter)}
                                transform={transformRepairSvg(valueAfter)}
                              />
                              <Circle cx={12} cy={13} r={18} stroke="000" strokeWidth={1} />
                            </Svg>
                            <Text style={{ textAlign: "center" }}>
                              {`${t(`constants.repair.state.${constants?.repairState?.[parseInt(valueAfter)]?.strVal}`)} `}
                            </Text>
                            <Text style={{ marginTop: 6, color: "grey", fontSize: 7, textAlign: "center" }}>
                              {createdAt.slice(0, createdAt.indexOf(".")).replace(" ", "\n")}
                            </Text>
                          </View>
                          <Svg style={{ width: 30, height: 70 }}>
                            <Line x1={0} x2={30} y1={23} y2={23} stroke="000" strokeWidth={1} />
                            <Line x1={25} x2={30} y1={20} y2={23} stroke="000" strokeWidth={1} />
                            <Line x1={25} x2={30} y1={26} y2={23} stroke="000" strokeWidth={1} />
                          </Svg>
                        </View>
                      ))}

                    <View style={[styles.singleSvgWrapper, { width: getSvgWidth(lang), marginHorizontal: getSvgMargin(lang) }]}>
                      <Svg style={{ width: 50, height: 50, padding: 10, justifyContent: "center", alignItems: "center", marginLeft: 8 }}>
                        <Circle
                          cx={12}
                          cy={13}
                          r={18}
                          stroke={getLastSvgColor(repairSteps[repairSteps.length - 1].valueAfter)}
                          fill={getLastSvgColor(repairSteps[repairSteps.length - 1].valueAfter)}
                          strokeWidth={1}
                          style={{ color: getLastSvgColor(repairSteps[repairSteps.length - 1].valueAfter) }}
                        />
                        <Path
                          d={getRepairSvgPath(repairSteps[repairSteps.length - 1].valueAfter)}
                          fill="white"
                          stroke={getLastSvgColor(repairSteps[repairSteps.length - 1].valueAfter)}
                          strokeWidth={strokeWidthRepairSvg(repairSteps[repairSteps.length - 1].valueAfter)}
                          transform={transformRepairSvg(repairSteps[repairSteps.length - 1].valueAfter)}
                        />
                      </Svg>
                      <Text style={{ textAlign: "center" }}>
                        {`${t(`constants.repair.state.${constants?.repairState?.[parseInt(repairSteps[repairSteps.length - 1].valueAfter)]?.strVal}`)} `}
                      </Text>
                      <Text style={{ marginTop: 6, color: "grey", fontSize: 7, textAlign: "center" }}>
                        {repairSteps[repairSteps.length - 1].createdAt.slice(0, repairSteps[repairSteps.length - 1].createdAt.indexOf(".")).replace(" ", "\n")}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View wrap={false}>
                <Text style={styles.sectionTitle}>{t("pdf.repairSummary")}</Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flexDirection: "column", color: "grey" }}>
                    <Text>{t("myRepairs.repairType")}</Text>
                    {variant === "final" && <Text>{t("global.repairEndDate")}</Text>}
                    {variant === "final" && <Text>{t("global.invoiceNumber")}</Text>}
                    {variant === "final" && <Text>{t("global.shippingNoticeNumber")}</Text>}
                    {repair?.repairDescription && <Text>{t("pdf.repairDescription")}</Text>}
                  </View>
                  <View style={{ flexDirection: "column", marginLeft: 10 }}>
                    <Text>{repair?.repairType ? t(`constants.repair.type.${constants?.repairTypeArr?.[repair?.repairType]?.strVal}`) : "-"}</Text>
                    {variant === "final" && <Text>{repair?.endDate ?? "-"}</Text>}
                    {variant === "final" && <Text>{repair?.invoiceNumber ?? "-"}</Text>}
                    {variant === "final" && <Text>{repair?.shippingNoticeNumber ?? "-"}</Text>}
                    {repair?.repairDescription && <Text style={{ width: 400 }}>{repair?.repairDescription?.replace(/\r/g, "") ?? "-"}</Text>}
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>

        {variant === "receive" && (
          <View wrap={false} style={{ marginTop: 10 }}>
            <View style={styles.stamp}>
              <View style={styles.stampField}>
                <Text style={styles.stampText}>{t("pdf.stamp")}</Text>
              </View>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

Font.register({
  family: "HK-Grotesk",
  src: font,
});
