import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RepairEvents from "routes/RepairEvents/RepairEvents";

const LogsTable = () => {
  const { t } = useTranslation();
  const { repairId } = useParams();

  return (
    <div className="logs-table">
      <h6 className="heading--6 heading--large-spacing">{t("myRepairs.reportLogs")}</h6>
      <RepairEvents variant="customer" filterRepairId={repairId} />
    </div>
  );
};

export default LogsTable;
