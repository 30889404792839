import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal as BSModal } from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getModalSize, getRandomComponentId, handleModalzIndexChange } from "./utils";
import "./styles.css";

const Modal = ({ id = getRandomComponentId(), size = "defult", title, subTitle, children, footerActions, onClose, bsBackdrop = "static", zIndex = 1050 }) => {
  const className = ".modal-backdrop";

  useEffect(() => {
    const myModal = new BSModal(document.getElementById(id), { keyboard: false });
    handleModalzIndexChange(zIndex, className);
    myModal.show();
    return () => {
      myModal.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="modal fade" id={id} data-bs-backdrop={bsBackdrop}>
      <div className={`modal-dialog modal-dialog-centered ${getModalSize(size)}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title__wrapper">
              <h5 className="modal-title">{title}</h5>
              {!!subTitle && <h6 className="modal-subtitle">{subTitle}</h6>}
            </div>
            <button type="button" className="btn-close" onClick={() => onClose(false)} />
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">{footerActions}</div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  footerActions: PropTypes.any,
  onClose: PropTypes.func,
  bsBackdrop: PropTypes.oneOf(["static", "true", "false"]),
};

export default Modal;
