/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors, isAdmin, isPriv, isReception } from "utils/global-functions";
import { getAccInfoBody } from "./utils";

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    registration,
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });
  const [userLoading, setUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user);

  const getUserInfoSuccess = (response) => {
    setSelectedUser(response.data.at(0));
    if (isAdmin(user) || isReception(user)) return;
    return dispatch({ type: "USER_LOGIN", ...response.data.at(0), id: user.id, token: user.token });
  };

  const getUserInfoError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getUserInfo = () => {
    agent.Users.getUser(getAccInfoBody(user, registration))
      .then(getUserInfoSuccess, getUserInfoError)
      .finally(() => setUserLoading(false));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (userLoading) return;

  return (
    <div className="contact-invoice-details">
      <h6 className="heading--6">{t("addReport.dataToInvoice")}</h6>
      <div className="contact-invoice-details__group">
        {isPriv(selectedUser) ? (
          <>
            <span className="paragraph--small text--subdued">{t("global.fullName")}</span>
            <span className="paragraph--small">{selectedUser?.personName}</span>
          </>
        ) : (
          <>
            <span className="paragraph--small text--subdued">{t("global.fullCompanyName")}</span>
            <span className="paragraph--small">{selectedUser?.companyName}</span>
          </>
        )}
      </div>
      <div className="contact-invoice-details__group">
        <span className="paragraph--small text--subdued">{t("global.streetAndBuildingNumber")}</span>
        <span className="paragraph--small">{selectedUser?.street}</span>
      </div>
      <div className="contact-invoice-details__group">
        <span className="paragraph--small text--subdued">{t("global.postalCodeAndCity")}</span>
        <span className="paragraph--small">{`${selectedUser?.postCode} ${selectedUser?.city}`}</span>
      </div>
      <div className="contact-invoice-details__group">
        <span className="paragraph--small text--subdued">{t("global.country")}</span>
        <span className="paragraph--small">{translatedCountryNames.of(selectedUser?.countryId)}</span>
      </div>
      {!isPriv(selectedUser) && (
        <div className="contact-invoice-details__group">
          <span className="paragraph--small text--subdued">{t("global.taxNumber")}</span>
          <span className="paragraph--small">{selectedUser?.taxNumber}</span>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;
