import React from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";

const RatingNotAvailable = ({ ratingInvitationResult, customerRatingResult }) => {
  const { t } = useTranslation();

  return (
    <div className="rating__empty-state">
      <h6 className="heading--6">
        {ratingInvitationResult?.isClosed && lodash.isEmpty(customerRatingResult) ? t("myRepairs.ratingRejected") : t("myRepairs.ratingNotAvailableYet")}
      </h6>
    </div>
  );
};

export default RatingNotAvailable;
