import React from "react";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { MAX_MOBILE_WIDTH, MIN_DESKTOP_WIDTH } from "utils/config";
import Radio from "stories/components/Radio/Radio";
import Stack from "stories/components/Stack/Stack";

const ClientTable = ({ clients, setFieldValue, values }) => {
  const { t } = useTranslation();

  if (!clients.length) return;

  const headings = ["", t("addReport.companyName"), t("global.nip"), t("global.fullName"), t("global.email")];

  return (
    <div className="admin-first-panel-results">
      <MediaQuery minWidth={MIN_DESKTOP_WIDTH}>
        <table className="admin-table admin-table-client">
          <thead className="admin-thead-client admin-thead">
            <tr>
              {headings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody className="admin-tbody-client admin-tbody">
            {clients.map(({ userId, companyName, taxNumber, personName, email }) => {
              return (
                <tr key={userId} id={userId} onClick={() => setFieldValue("userId", userId)}>
                  <td>
                    <Radio
                      id={`client ${userId}`}
                      checked={userId === values.userId}
                      name="clientRadioGroup"
                      onChange={() => setFieldValue("userId", userId)}
                    />
                  </td>
                  <td>{companyName ? companyName : <hr style={{ color: "var(--font-primary)", width: "0.6rem" }} />}</td>
                  <td>{taxNumber ? taxNumber : <hr style={{ color: "var(--font-primary)", width: "0.6rem" }} />}</td>
                  <td>{personName}</td>
                  <td>{email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </MediaQuery>
      <MediaQuery maxWidth={MAX_MOBILE_WIDTH}>
        <div className="mobile-report-first-panel__client-result-list">
          {clients.map(({ userId, companyName, taxNumber, personName, email }) => {
            return (
              <div className="mobile-report-first-panel__client-result" key={userId} onClick={() => setFieldValue("userId", userId)}>
                <Stack>
                  <Radio name="clientRadioGroup" checked={userId === values.userId} onChange={() => setFieldValue("userId", userId)} />
                  <span className="text--red text--underline">{companyName || personName}</span>
                </Stack>
                <div className="mobile-report-first-panel__client-result-info">
                  <span>{companyName && `${t("global.nip")} : ${taxNumber}`}</span>
                  <span>{companyName && `${t("global.fullName")} : ${personName}`}</span>
                  <span>{`${t("global.email")} : ${email}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </MediaQuery>
    </div>
  );
};

export default ClientTable;
