import React from "react";
import { useTranslation } from "react-i18next";

const EditMessageMode = ({ setSelectedConversationId, editMessageActive, setEditMessageActive }) => {
  const { t } = useTranslation();

  if (!editMessageActive) return;

  return (
    <div className="parent-message">
      <span className="paragraph--small text--bold">{t("myRepairs.editMessageInfo")}</span>
      <button
        onClick={() => {
          setSelectedConversationId(null);
          setEditMessageActive(false);
        }}
        className="unset-all custom-link"
      >
        {t("global.cancel")}
      </button>
    </div>
  );
};

export default EditMessageMode;
