import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ClientRepairDescrpiton = () => {
  const { t } = useTranslation();
  const {
    repair: { repairDetails },
  } = useSelector((store) => store);

  return (
    <div className="repair-info-description--client">
      <h6 className="heading--6 heading--large-spacing">{t("global.descriptionOrJustification")}</h6>
      <p className="paragraph--small text--pre-line">{repairDetails.repairDescription || "-"}</p>
    </div>
  );
};

export default ClientRepairDescrpiton;
