import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmployee } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import { Filters } from "./Filters";
import "./styles.css";

const DisplayDesktop = ({
  conversationTypes,
  setConversationTypes,
  repairNumber,
  setRepairNumber,
  serialNumber,
  setSerialNumber,
  customer,
  setCustomer,
  employeeId,
  setEmployeeId,
  repairState,
  setRepairState,
  dateFrom,
  setDateFrom,
  dateUpTo,
  setDateUpTo,
  error,
  setPageNumber,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <div className="actions-menu">
      <div className="actions-menu-buttons">
        <div
          className={conversationTypes === null ? "actions-menu-button actions-menu-active" : "actions-menu-button"}
          onClick={() => {
            setConversationTypes(null);
            setPageNumber(1);
          }}
        >
          {t("actions.all")}
        </div>
        <div
          className={conversationTypes === 2 ? "actions-menu-button actions-menu-active" : "actions-menu-button"}
          onClick={() => {
            setConversationTypes(2);
            setPageNumber(1);
          }}
        >
          {t("actions.pricings")}
        </div>
        <div
          className={conversationTypes === 1 ? "actions-menu-button actions-menu-active" : "actions-menu-button"}
          onClick={() => {
            setConversationTypes(1);
            setPageNumber(1);
          }}
        >
          {t("actions.conversations")}
        </div>
        {isEmployee(user) && (
          <div
            className={conversationTypes === 3 ? "actions-menu-button actions-menu-active" : "actions-menu-button"}
            onClick={() => {
              setConversationTypes(3);
              setPageNumber(1);
            }}
          >
            {t("actions.conversationsInternal")}
          </div>
        )}
        <div
          className={conversationTypes === 4 ? "actions-menu-button actions-menu-active" : "actions-menu-button"}
          onClick={() => {
            setConversationTypes(4);
            setPageNumber(1);
          }}
        >
          {t("actions.ratings")}
        </div>
      </div>

      <div className="actions-menu-inputs">
        <Filters
          repairNumber={repairNumber}
          setRepairNumber={setRepairNumber}
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          customer={customer}
          setCustomer={setCustomer}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
          repairState={repairState}
          setRepairState={setRepairState}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateUpTo={dateUpTo}
          setDateUpTo={setDateUpTo}
          error={error}
        />
        <div className="actions-menu-save-button">
          <Button
            variant="default"
            type="submit"
            style={{ width: "5.5rem" }}
            onClick={() => {
              setRepairNumber("");
              setSerialNumber("");
              setCustomer("");
              setEmployeeId(null);
              setRepairState(null);
              setDateFrom(null);
              setDateUpTo(null);
            }}
          >
            {t("actions.clearFilters")}
          </Button>

          <Button variant="primary" type="submit" style={{ width: "5.5rem" }}>
            {t("actions.showResults")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisplayDesktop;
