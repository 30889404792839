import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Table from "stories/components/Table/Table";
import { ATTACHMENTS_CONVERSATION_TYPE, ATTACHMENTS_GENERAL_TYPE, ATTACHMENTS_REPORT_TYPE } from "utils/config";
import { isClient } from "utils/global-functions";
import { headingsMap } from "../config";
import { getClassForColspan } from "../utils";
import AttachmentsTableRows from "./AttachmentsTableRows";

const AttachmentsTable = ({ attachmentList, setAttachmentDeleteModalActive, setSelectedAttachmentId, setAttachmentsModalActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  if (!attachmentList.length) return;

  const conversationAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_CONVERSATION_TYPE);
  const generalAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_GENERAL_TYPE);
  const reportAttachments = attachmentList.filter(({ attachmentType }) => attachmentType === ATTACHMENTS_REPORT_TYPE);

  const headings = headingsMap[isClient(user)];

  return (
    <div className="table-wrapper">
      <Table
        extended
        headings={headings.map(({ content }) => {
          return { content: content ? t(`global.${content}`) : content };
        })}
      >
        <tr className={`attachment__table-colspan ${getClassForColspan(reportAttachments)}`}>
          <td colSpan={headings.length}>{t("myRepairs.reportAttachments")}</td>
        </tr>
        <AttachmentsTableRows
          attachmentList={reportAttachments}
          setAttachmentsModalActive={setAttachmentsModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
        />
        <tr className={`attachment__table-colspan ${getClassForColspan(conversationAttachments)}`}>
          <td colSpan={headings.length}>{t("myRepairs.conversationAttachments")}</td>
        </tr>
        <AttachmentsTableRows
          attachmentList={conversationAttachments}
          setAttachmentsModalActive={setAttachmentsModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
        />
        <tr className={`attachment__table-colspan ${getClassForColspan(generalAttachments)}`}>
          <td colSpan={headings.length}>{t("myRepairs.generalAttachments")}</td>
        </tr>
        <AttachmentsTableRows
          attachmentList={generalAttachments}
          setAttachmentsModalActive={setAttachmentsModalActive}
          setSelectedAttachmentId={setSelectedAttachmentId}
          setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
        />
      </Table>
    </div>
  );
};

export default AttachmentsTable;
