/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import agent from "api/agent";
import { ATTACHMENTS_PHOTOS_TYPE, MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import PhotosModal from "./PhotosModal";
import ConfirmDeletePhotoModal from "./ConfirmDeletePhotoModal";
import PhotosPreviewModal from "./PhotosPreviewModal";
import "./commonStyles.css";

const Photos = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const { repairId } = useParams();
  const [photosLoading, setPhotosLoading] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [photosModalActive, setPhotosModalActive] = useState(false);
  const [deletePhotoModalActive, setDeletePhotoModalActive] = useState(false);
  const [photosPreviewModalActive, setPhotosPreviewModalActive] = useState(false);
  const [refreshPhotos, setRefreshPhotos] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);

  const getPhotosSuccess = (response) => {
    setPhotos(response.data);
  };

  const getPhotosError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getPhotos = () => {
    setPhotosLoading(true);
    agent.Repairs.handleRepairAttachments({
      id: user.id,
      token: user.token,
      action: "list",
      repairId: repairId,
      attachmentTypes: JSON.stringify([ATTACHMENTS_PHOTOS_TYPE]),
    })
      .then(getPhotosSuccess, getPhotosError)
      .finally(() => setPhotosLoading(false));
  };

  useEffect(() => {
    getPhotos();
  }, [refreshPhotos]);

  return (
    <>
      {mobile ? (
        <Mobile
          photosLoading={photosLoading}
          photos={photos}
          setPhotosModalActive={setPhotosModalActive}
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      ) : (
        <Desktop
          photosLoading={photosLoading}
          photos={photos}
          setPhotosModalActive={setPhotosModalActive}
          setDeletePhotoModalActive={setDeletePhotoModalActive}
          setSelectedPhotoId={setSelectedPhotoId}
          setPhotosPreviewModalActive={setPhotosPreviewModalActive}
        />
      )}
      <PhotosModal
        photos={photos}
        active={photosModalActive}
        setActive={setPhotosModalActive}
        refreshPhotos={refreshPhotos}
        setRefreshPhotos={setRefreshPhotos}
        selectedPhotoId={selectedPhotoId}
        setSelectedPhotoId={setSelectedPhotoId}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <ConfirmDeletePhotoModal
        active={deletePhotoModalActive}
        setActive={setDeletePhotoModalActive}
        selectedPhotoId={selectedPhotoId}
        setSelectedPhotoId={setSelectedPhotoId}
        refreshPhotos={refreshPhotos}
        setRefreshPhotos={setRefreshPhotos}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <PhotosPreviewModal
        active={photosPreviewModalActive}
        setActive={setPhotosPreviewModalActive}
        selectedPhotoId={selectedPhotoId}
        setSelectedPhotoId={setSelectedPhotoId}
        photos={photos}
      />
    </>
  );
};

export default Photos;
