import React from "react";
import { useTranslation } from "react-i18next";
import SelectField from "stories/components/SelectField/SelectField";
import "./styles.css";

const PageSizeSelector = ({ selectedPageSize, onChange, pageSizeOptions, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="page-size-selector">
      <span className="text--nowrap">{t("global.perPage")}</span>
      <div className="page-size-selector__buttons-wrapper">
        <SelectField
          placeholder={t("global.none")}
          size="small"
          isSearchable={false}
          disabled={disabled}
          value={selectedPageSize}
          onChange={onChange}
          options={pageSizeOptions}
        />
      </div>
    </div>
  );
};

export default PageSizeSelector;
