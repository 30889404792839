/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import md5 from "md5";
import useQRCodeScan from "hooks/useQRCodeScanner";
import Tooltip from "stories/components/Tooltip/Tooltip";
import "./styles.css";

const QRScanner = ({ setResultValue }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const id = md5(Math.floor(Math.random() * 10000)).slice(0, 17);
  const { decodedQRData, stopQrCode, startQrCode } = useQRCodeScan({
    qrcodeMountNodeID: id,
  });

  useEffect(() => {
    if (decodedQRData.data) setResultValue(decodedQRData.data);
  }, [decodedQRData.data]);

  return (
    <>
      {mobile ? (
        <button type="button" className="unset-all" onClick={() => startQrCode()}>
          <svg className="qr-scanner__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z" />
            </g>
          </svg>
        </button>
      ) : (
        <Tooltip direction="bottom" zIndex={1100} content={t("global.scanCode")}>
          <button type="button" className="unset-all" onClick={() => startQrCode()}>
            <svg className="qr-scanner__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z" />
              </g>
            </svg>
          </button>
        </Tooltip>
      )}
      <div className={`qr-scanner ${decodedQRData.isScanning ? "qr-scanner--show" : ""}`}>
        <button className="unset-all qr-scanner__close-button" type="button" onClick={() => stopQrCode()}>
          <svg className="qr-scanner__close-icon" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.41425 4.00001L7.70725 1.70701C8.09825 1.31601 8.09825 0.684006 7.70725 0.293006C7.31625 -0.0979941 6.68425 -0.0979941 6.29325 0.293006L4.00025 2.58601L1.70725 0.293006C1.31625 -0.0979941 0.68425 -0.0979941 0.29325 0.293006C-0.09775 0.684006 -0.09775 1.31601 0.29325 1.70701L2.58625 4.00001L0.29325 6.29301C-0.09775 6.68401 -0.09775 7.31601 0.29325 7.70701C0.48825 7.90201 0.74425 8.00001 1.00025 8.00001C1.25625 8.00001 1.51225 7.90201 1.70725 7.70701L4.00025 5.41401L6.29325 7.70701C6.48825 7.90201 6.74425 8.00001 7.00025 8.00001C7.25625 8.00001 7.51225 7.90201 7.70725 7.70701C8.09825 7.31601 8.09825 6.68401 7.70725 6.29301L5.41425 4.00001Z"
            />
          </svg>
        </button>
        <div id={id} />
      </div>
    </>
  );
};

export default QRScanner;
