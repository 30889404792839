import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import Page from "stories/components/Page/Page";
import { Heading } from "./Heading/Heading";
import { Body } from "./Body/Body";
import { Footer } from "./Footer/Footer";
import "./ProducerReport.css";
import moment from "moment";

export const ProducerReport = () => {
  const mobile = useMediaQuery({ maxWidth: 850 });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);

  const [loading, setLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [endDateFrom, setEndDateFrom] = useState(null);
  const [endDateUpTo, setEndDateUpTo] = useState(null);
  const [repairId, setRepairId] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [technicianId, setTechnicianId] = useState("");
  const [producerId, setProducerId] = useState("");
  const [isIncomplete, setIsIncopmlete] = useState("");

  const [endDateFromValue, setEndDateFromValue] = useState(null);
  const [endDateUpToValue, setEndDateUpToValue] = useState(null);
  const [repairIdValue, setRepairIdValue] = useState("");
  const [serialNumberValue, setSerialNumberValue] = useState("");
  const [partNumberValue, setPartNumberValue] = useState("");
  const [technicianIdValue, setTechnicianIdValue] = useState("");
  const [producerIdValue, setProducerIdValue] = useState("");
  const [isIncompleteValue, setIsIncopmleteValue] = useState("");

  const producerReportList = () => {
    setLoading(true);

    return agent.ProducerReport.producerReportList({
      id: user.id,
      token: user.token,
      pageSize: pageSize,
      pageNumber: pageNumber,
      isClosed: 1,
      endDateFrom: endDateFrom?.format("YYYY-MM-DD"),
      endDateUpTo: endDateUpTo?.format("YYYY-MM-DD"),
      repairId: repairId === "" ? null : repairId,
      serialNumber: serialNumber === "" ? null : serialNumber,
      partNumber: partNumber === "" ? null : partNumber,
      technicianId: technicianId === "" ? null : technicianId,
      producerId: producerId === "" ? null : producerId,
      isIncomplete: isIncomplete === "" ? null : isIncomplete,
    })
      .then(
        ({ data }) => {
          setReportList(data);
        },
        (error) => {
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        },
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    producerReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber, endDateFrom, endDateUpTo, serialNumber, partNumber, repairId, technicianId, producerId, isIncomplete]);

  const csvDataItem = (data) => {
    /* Author: jacek.bonecki@elmark.com.pl */
    return `"${data !== null ? String(data).trim().replaceAll('"', '""') : ""}";`;
  };

  const reportListExport = () => {
    /* Author: jacek.bonecki@elmark.com.pl */
    const reportKeys = [
      "registrationDate",
      "endDate",
      "partNumber",
      "serialNumber",
      "producerRepairNumber",
      "problem",
      "findings",
      "testProcedure",
      "malfunction",
    ];
    const partKeys = ["pcbSerialNumber", "location", "partName", "repairStatus", "qty"];
    let csv = `"No.";"Receive Date";"Finish Date";"Product Name";"Product Serial No.";"Producer Repair Number";"Problem";"Findings";"Test procedure";"Cause of malfunction";"PCB Serial No.";"Location";"Part name";"Repair Status";"Q'ty";\n`;
    let line = null;
    reportList.forEach((item, itemindex) => {
      if (item.parts.length) {
        item.parts.forEach((subitem, subitemindex) => {
          line = `"${itemindex + 1}";`;
          reportKeys.forEach((key) => {
            line += csvDataItem(item[key]);
          });
          partKeys.forEach((key) => {
            line += csvDataItem(subitem[key]);
          });
          csv += `${line}\n`;
        });
      } else {
        line = `"${itemindex + 1}";`;
        reportKeys.forEach((key) => {
          line += csvDataItem(item[key]);
        });
        csv += `${line}\n`;
      }
    });
    const blob = new Blob(["\ufeff", csv]);
    const anchor = document.createElement("a");
    anchor.download = `Elmark eRMA Report List Export ${moment().format("YYYY-MM-DD \\g\\o\\d\\z\\. hh-mm-ss")}.csv`;
    anchor.href = URL.createObjectURL(blob);
    anchor.click();
  };

  return (
    <Page heading={t("panel.producerReport")}>
      <div className={mobile ? "mobile-producer-report-main" : "producer-report-main"}>
        <div>
          <Heading
            producerReportList={producerReportList}
            reportList={reportList}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            endDateFrom={endDateFrom}
            setEndDateFrom={setEndDateFrom}
            endDateUpTo={endDateUpTo}
            setEndDateUpTo={setEndDateUpTo}
            repairId={repairId}
            setRepairId={setRepairId}
            serialNumber={serialNumber}
            setSerialNumber={setSerialNumber}
            partNumber={partNumber}
            setPartNumber={setPartNumber}
            technicianId={technicianId}
            setTechnicianId={setTechnicianId}
            producerId={producerId}
            setProducerId={setProducerId}
            isIncomplete={isIncomplete}
            setIsIncopmlete={setIsIncopmlete}
            endDateFromValue={endDateFromValue}
            setEndDateFromValue={setEndDateFromValue}
            endDateUpToValue={endDateUpToValue}
            setEndDateUpToValue={setEndDateUpToValue}
            repairIdValue={repairIdValue}
            setRepairIdValue={setRepairIdValue}
            serialNumberValue={serialNumberValue}
            setSerialNumberValue={setSerialNumberValue}
            partNumberValue={partNumberValue}
            setPartNumberValue={setPartNumberValue}
            technicianIdValue={technicianIdValue}
            setTechnicianIdValue={setTechnicianIdValue}
            producerIdValue={producerIdValue}
            setProducerIdValue={setProducerIdValue}
            isIncompleteValue={isIncompleteValue}
            setIsIncopmleteValue={setIsIncopmleteValue}
            reportListExportCallback={reportListExport}
            reportListLength={reportList.length}
          />
          <Body
            reportList={reportList}
            loading={loading}
            setEndDateFrom={setEndDateFrom}
            setEndDateUpTo={setEndDateUpTo}
            setRepairId={setRepairId}
            setSerialNumber={setSerialNumber}
            setPartNumber={setPartNumber}
            setTechnicianId={setTechnicianId}
            setProducerId={setProducerId}
            setIsIncopmlete={setIsIncopmlete}
            setEndDateFromValue={setEndDateFromValue}
            setEndDateUpToValue={setEndDateUpToValue}
            setRepairIdValue={setRepairIdValue}
            setSerialNumberValue={setSerialNumberValue}
            setPartNumberValue={setPartNumberValue}
            setTechnicianIdValue={setTechnicianIdValue}
            setProducerIdValue={setProducerIdValue}
            setIsIncopmleteValue={setIsIncopmleteValue}
          />
        </div>
        <Footer reportList={reportList} pageNumber={pageNumber} setPageNumber={setPageNumber} pageSize={pageSize} setPageSize={setPageSize} />
      </div>
    </Page>
  );
};
