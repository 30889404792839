import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Stack from "stories/components/Stack/Stack";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import QRScanner from "components/QRScanner/QRSscanner";
import TextField from "stories/components/TextField/TextField";
import Link from "stories/components/Link/Link";
import SerialNumber from "images/SerialNumber.svg";
import agent from "api/agent";
import WarrantyModal from "./WarrantyModal";
import SerialNumberModal from "./SerialNumberModal";

const SearchForWarranty = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { app } = useSelector((store) => store);
  const searchWarrantyTextField = useRef(null);
  const [warrantyInput, setWarrantyInput] = useState("");
  const [warrantyDetails, setWarrantyDetails] = useState({});
  const [warrantyLoading, setWarrantyLoading] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [activeSNModal, setActiveSNModal] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!warrantyLoading) {
      handleSearchWarrantyTextFieldFocus();
    }
  }, [warrantyLoading, activeModal]);

  const handleSearchWarrantyTextFieldFocus = () => {
    searchWarrantyTextField.current?.focus();
    searchWarrantyTextField.current?.select();
  };

  const checkWarrantySuccess = (response) => {
    if (!response.data.at(0)) throw new Error();
    setWarrantyDetails(response.data.at(0));
    setErrors([]);
    setActiveModal(true);
  };

  const checkWarrantyError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, app.commonApiErrors.codeList);
    setErrors(filteredErrors.map((error) => error.code));
  };

  const checkWarranty = (value) => {
    setWarrantyLoading(true);
    agent.Resources.checkWarranty({ serialNumber: value || warrantyInput })
      .then(checkWarrantySuccess, checkWarrantyError)
      .catch(() => setErrors([302]))
      .finally(() => setWarrantyLoading(false));
  };

  return (
    <div className="login_card">
      <div className="login_card__wrapper">
        <div>
          <h3 className="secondary_login_heading margin_bottom">{t("loginPage.checkWarranty")}</h3>
          <Stack flexDirection="column" gap="0.5rem">
            <TextField
              maxLength={50}
              loading={warrantyLoading}
              ref={searchWarrantyTextField}
              placeholder={t("loginPage.provideSerialNumber")}
              variant="search"
              error={errors.at(0) && t(`errors.${errors.at(0)}`)}
              value={warrantyInput}
              onChange={(value) => setWarrantyInput(value)}
              onClick={() => checkWarranty()}
              specialActions={
                <QRScanner
                  setResultValue={(value) => {
                    setWarrantyDetails({});
                    setWarrantyInput(value);
                    checkWarranty(value);
                  }}
                />
              }
            />
            <Link className="login-link" onClick={() => setActiveSNModal(true)}>
              {t("loginPage.serialNumberQuestion")}
            </Link>
          </Stack>
        </div>
        <div className="warranty_image_wrapper">
          <img className="warranty_image" src={SerialNumber} alt="Serial number" />
        </div>
      </div>
      <WarrantyModal data={warrantyDetails} active={activeModal} onClose={() => setActiveModal(false)} />
      <SerialNumberModal active={activeSNModal} onClose={() => setActiveSNModal(false)} />
    </div>
  );
};

export default SearchForWarranty;
