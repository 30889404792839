import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "stories/components/Button/Button";
import { CONVERSATION, CONVERSATION_INTERNAL } from "utils/config";
import { isEmployee } from "utils/global-functions";

const TopBar = ({ converstionGroupType, setConversationGroupType, setSelectedConversationId, setResponseMessageActive, setEditMessageActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const navigate = useNavigate();
  const { repairId } = useParams();

  if (!isEmployee(user)) return;

  return (
    <div className="mobile-conversation__button-wrapper">
      <Button
        size="small"
        variant={converstionGroupType === CONVERSATION_INTERNAL ? "primary" : "default"}
        onClick={() => {
          setSelectedConversationId(null);
          setResponseMessageActive(false);
          setEditMessageActive(false);
          setConversationGroupType(CONVERSATION_INTERNAL);
          navigate(`/${repairId}/conversation/internal`);
        }}
      >
        {t("myRepairs.generalElmarkChat")}
      </Button>
      <Button
        size="small"
        variant={converstionGroupType === CONVERSATION ? "primary" : "default"}
        onClick={() => {
          setSelectedConversationId(null);
          setResponseMessageActive(false);
          setEditMessageActive(false);
          setConversationGroupType(CONVERSATION);
          navigate(`/${repairId}/conversation`);
        }}
      >
        {t("myRepairs.generalChatWithClient")}
      </Button>
    </div>
  );
};

export default TopBar;
