import React from "react";
import { getClassNamesForNumberButtons, getClassNamesForTextField } from "./utils";
import Minus from "images/Minus.svg";
import Plus from "images/Plus2.svg";

const NumberField = ({ disabled = false, value, onChange, id, name, placeholder, max, min, className, onBlur, ...rest }) => (
  <div className="input-number__wrapper">
    <button
      id={id}
      name={name}
      disabled={disabled}
      onBlur={onBlur}
      type="button"
      className={`input-number__button ${getClassNamesForNumberButtons(disabled)}`}
      onClick={() => {
        if (value <= min) return;
        return onChange(value - 1);
      }}
    >
      <img src={Minus} alt="-" className="input-number__button-character" />
    </button>
    <input
      className={`input-number ${getClassNamesForTextField(disabled)} ${className}`}
      disabled={disabled}
      type="number"
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      min={min}
      max={max}
      onBlur={onBlur}
      onChange={({ target: { value } }) => onChange(value ? parseInt(value) : value)}
      {...rest}
    />
    <button
      id={id}
      name={name}
      disabled={disabled}
      onBlur={onBlur}
      type="button"
      className={`input-number__button ${getClassNamesForNumberButtons(disabled)}`}
      onClick={() => {
        if (value >= max) return;
        return onChange(value + 1);
      }}
    >
      <img src={Plus} alt="+" className="input-number__button-character" />
    </button>
  </div>
);

export default NumberField;
