/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isActiveLink } from "../utils";
import "./styles.css";

const NavigationDropdownLink = ({ link }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(isActiveLink(link.url));
  }, [window.location.pathname]);

  return (
    <Link to={link.url} className={`mobile-navigation-dropdown__item ${active ? "mobile-navigation-dropdown__item--active" : ""}`}>
      <div className="mobile-navigation__link-wrapper">
        <span className="paragraph--small">{link.name}</span>
        {!!link.counter && <div className="mobile-navigation__link-counter">{link.counter}</div>}
      </div>
    </Link>
  );
};

export default NavigationDropdownLink;
