import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "stories/components/Tooltip/Tooltip";
import Button from "stories/components/Button/Button";

const DeliveryData = ({ details, editButton }) => {
  const { t } = useTranslation();
  const copyDataToClipboardClick = (e, content) => {
    navigator.clipboard.writeText(content.map((item) => item.description).join("\n") + "\n");
    const tooltipId =
      e.target.getAttribute("obj-type") === "svg-icon" ? e.target.getAttribute("aria-describedby") : e.target.parentNode.getAttribute("aria-describedby");
    if (tooltipId) document.getElementById(tooltipId).firstChild.nextSibling.firstChild.innerText = t("global.copied");
  };

  return (
    <div className="client-data-row">
      {details.map(({ title, copyButton = true, content }) => (
        <div className="repair-info-form" key={title}>
          <h6 key={title} className="heading--6 heading--large-spacing">
            {title}
            {copyButton && (
              <Tooltip allowHtml content={t("global.copyDataToClipboard")} direction="right" zIndex={999999}>
                <svg
                  className="pointer copy__icon"
                  obj-type="svg-icon"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={(e) => {
                    copyDataToClipboardClick(e, content);
                  }}
                >
                  <path d="M0 0h48v48H0z" fill="none" />
                  <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" />
                </svg>
              </Tooltip>
            )}
          </h6>
          {content.map(({ term, description }, index) => (
            <div key={index}>
              <span key={term} className="paragrapgh--small text--subdued">
                {term}
              </span>
              <p key={description} className="paragrapgh--small">
                {description}
              </p>
            </div>
          ))}
        </div>
      ))}
      {editButton && (
        <Button onClick={() => editButton.onChange(!editButton.active)} size="small">
          {t("global.edit")}
        </Button>
      )}
    </div>
  );
};

export default DeliveryData;
