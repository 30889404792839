import React, { useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import Stack from "stories/components/Stack/Stack";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import {
  convertNullToEmptyString,
  formatBodyForSetDetailsRequest,
  formatBodyForSetReplacementRequest,
  getReplacementDetails,
  isRepairDetailsModificationForbidden,
} from "../utils";
import DetailsForm from "../DetailsForm";
import AddReplacementProductForm from "../AddReplacementProductForm";
import DeleteReplacementProductModal from "../DeleteReplacementProductModal";

const RepairDetailsForm = ({ refreshRepairDetails, setRefreshRepairDetails }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors, constants },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [deleteReplacementProductModalActive, setDeleteReplacementProductModalActive] = useState(false);

  const setRepairDetailsSuccess = (response) => {
    if (response.result === 301) {
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairDetailsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const setRepairDetails = (values) => {
    const body = formatBodyForSetDetailsRequest(values, repairDetails, user);
    return agent.Repairs.setRepairDetails({ id: user.id, token: user.token, repairId: repairDetails.repairId, ...body }).then(
      setRepairDetailsSuccess,
      setRepairDetailsError,
    );
  };

  const setRepairReplacementSuccess = (response) => {
    if (response.result === 301) {
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairReplacementError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const setRepairReplacement = (values) => {
    const body = formatBodyForSetReplacementRequest(values);
    return agent.Repairs.setRepairReplacement({ id: user.id, token: user.token, repairId: repairDetails.repairId, ...body }).then(
      setRepairReplacementSuccess,
      setRepairReplacementError,
    );
  };

  const detailsValidationSchema = Yup.object().shape({
    shippingNoticeNumber: Yup.string().max(50, t("errors.102")),
    invoiceNumber: Yup.string().max(50, t("errors.102")),
    producerRepairNumber: Yup.string().max(50, t("errors.102")),
    rentInfo: Yup.string().max(255, t("errors.102")),
    delegationCount: Yup.number().min(0, t("errors.105")).max(9, t("errors.106")),
  });

  const productValidationSchema = Yup.object().shape({
    serialNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    partNumber: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    description: Yup.string().min(3, t("errors.101")).max(200, t("errors.102")).required(t("errors.99")),
    document: Yup.string().max(50, t("errors.102")),
    warrantyLength: Yup.number().min(0, t("errors.101")),
    producerId: Yup.mixed().required(t("errors.99")),
  });

  return (
    <>
      <Formik
        initialValues={{
          shippingNoticeNumber: repairDetails.shippingNoticeNumber ?? "",
          invoiceNumber: repairDetails.invoiceNumber ?? "",
          technicianId: repairDetails.technicianId,
          suggestedRepairDate: repairDetails.suggestedRepairDate ? moment(repairDetails.suggestedRepairDate) : null,
          repairType: repairDetails.repairType ?? "",
          isInQueue: repairDetails.isInQueue ?? "",
          repairKind: repairDetails.repairKind ?? "",
          repairState: repairDetails.repairState ?? "",
          endDate: repairDetails.endDate,
          producerRepairNumber: repairDetails.producerRepairNumber ?? "",
          rentInfo: repairDetails.rentInfo ?? "",
          delegationCount: repairDetails.delegationCount ?? 0,
        }}
        validationSchema={detailsValidationSchema}
        onSubmit={(values) => setRepairDetails(values)}
      >
        {({ values, setFieldValue, errors, handleBlur, touched, isSubmitting, dirty, isValid, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h6 className="heading--6 heading--large-spacing">{t("myRepairs.repairDetails")}</h6>
            <div className="repair-info-form">
              <DetailsForm values={values} setFieldValue={setFieldValue} errors={errors} handleBlur={handleBlur} touched={touched} formErrors={formErrors} />
              <Button variant={!dirty || !isValid ? "default" : "primary"} size="small" type="submit" disabled={!dirty || !isValid} loading={isSubmitting}>
                {t("global.save")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{
          serialNumber: repairDetails.replacementSerialNumber ?? "",
          partNumber: "",
          description: "",
          producerId: "",
          document: "",
          sellDate: null,
          warrantyLength: "",
        }}
        validationSchema={productValidationSchema}
        onSubmit={(values) => setRepairReplacement(values)}
      >
        {({ values, setFieldValue, errors, handleBlur, touched, isSubmitting, dirty, isValid, resetForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h6 className="heading--6 heading--large-spacing">{t("global.replacementProduct")}</h6>
            <div className="repair-info-form">
              <AddReplacementProductForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                formErrors={formErrors}
                handleBlur={handleBlur}
                touched={touched}
              />
              {values.serialNumber === repairDetails.replacementSerialNumber && !!repairDetails.replacementSerialNumber && (
                <Stack justifyContent="space-between">
                  <Button
                    disabled={isRepairDetailsModificationForbidden(user)}
                    outline
                    variant="error"
                    size="small"
                    onClick={() => setDeleteReplacementProductModalActive(true)}
                  >
                    {t("global.delete")}
                  </Button>
                  <Tooltip direction="bottom" content={getReplacementDetails(repairDetails, constants, t)}>
                    <span className="paragraph--small text--underline-dotted">{t("global.details")}</span>
                  </Tooltip>
                </Stack>
              )}
              {convertNullToEmptyString(repairDetails.replacementSerialNumber) !== values.serialNumber && (
                <Stack gap="var(--modal-buttons-gap)">
                  <Button
                    size="small"
                    loading={isSubmitting}
                    disabled={!dirty}
                    onClick={() => {
                      setFieldValue("serialNumber", repairDetails.replacementSerialNumber);
                      resetForm();
                    }}
                  >
                    {t("global.cancel")}
                  </Button>
                  <Button
                    variant={!dirty || !isValid ? "default" : "primary"}
                    size="small"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!dirty || !isValid || isRepairDetailsModificationForbidden(user)}
                  >
                    {t("global.save")}
                  </Button>
                </Stack>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <DeleteReplacementProductModal
        active={deleteReplacementProductModalActive}
        setActive={setDeleteReplacementProductModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
    </>
  );
};

export default RepairDetailsForm;
