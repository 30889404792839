import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { isAdmin, isReception } from "utils/global-functions";
import { PdfDocument } from "./pdf/pdfDocument";
import DuplicateRepairModal from "./DuplicateRepairModal";
import PdfModal from "./pdf/PdfModal";
import { Spinner } from "stories/components/Spinner/Spinner";

const ActionButtons = ({ refreshRepairDetails, setRefreshRepairDetails, repairSteps }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { repairId } = useParams();
  const {
    user,
    app: { constants },
    repair: { repairDetails },
  } = useSelector((user) => user);

  const [duplicateRepairModalActive, setDuplicateRepairModalActive] = useState(false);
  const [modalVariant, setModalVariant] = useState(); //options: "receive"/"state"/"final"
  const [pdfModalActive, setPdfModalActive] = useState(false);
  const [replacementInfo, setReplacementInfo] = useState();
  const [loading, setLoading] = useState(false);

  let todayDate = new Date().toISOString().slice(0, 10);

  const checkWarranty = () => {
    setLoading(true);
    agent.Resources.checkWarranty({ serialNumber: repairDetails.replacementSerialNumber })
      .then(
        ({ data }) => {
          setReplacementInfo(data);
        },
        (error) => {
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        },
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    repairDetails.replacementSerialNumber && checkWarranty();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairDetails.replacementSerialNumber]);

  if (loading)
    return (
      <div className="repair-info__action-buttons">
        <Spinner />
      </div>
    );

  return (
    <div className="repair-info__action-buttons">
      {(isAdmin(user) || isReception(user)) && (
        <Button onClick={() => setDuplicateRepairModalActive(true)} size={mobile ? "small" : "default"}>
          {t("global.duplicate")}
        </Button>
      )}

      {mobile ? (
        <div>
          {repairDetails.endDate ? (
            <div>
              <PDFDownloadLink
                fileName={`${t("pdf.finalReportNumber")} ${repairId}.pdf`}
                style={{ textDecoration: "none" }}
                document={
                  <PdfDocument
                    variant={"final"}
                    repair={repairDetails}
                    repairSteps={repairSteps}
                    replacementInfo={replacementInfo?.[0]}
                    todayDate={todayDate}
                    constants={constants}
                  />
                }
              >
                <Button size="small">{t("pdf.reportFromRepair")}</Button>
              </PDFDownloadLink>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem", flexWrap: "wrap" }}>
              <PDFDownloadLink
                fileName={`${t("pdf.receiveConfirmNumber")} ${repairId}.pdf`}
                style={{ textDecoration: "none" }}
                document={
                  <PdfDocument
                    variant={"receive"}
                    repair={repairDetails}
                    repairSteps={repairSteps}
                    replacementInfo={replacementInfo?.[0]}
                    todayDate={todayDate}
                    constants={constants}
                  />
                }
              >
                <Button size="small">{t("pdf.receiveConfirm")}</Button>
              </PDFDownloadLink>
              <PDFDownloadLink
                fileName={`${t("pdf.currentRepairStateNumber")} ${repairId}.pdf`}
                style={{ textDecoration: "none" }}
                document={
                  <PdfDocument
                    variant={"state"}
                    repair={repairDetails}
                    repairSteps={repairSteps}
                    replacementInfo={replacementInfo?.[0]}
                    todayDate={todayDate}
                    constants={constants}
                  />
                }
              >
                <Button size="small" disabled={!repairSteps?.length}>
                  {t("pdf.currentRepairState")}
                </Button>
              </PDFDownloadLink>
            </div>
          )}
        </div>
      ) : (
        <div>
          {repairDetails.endDate ? (
            <Button
              size={mobile ? "small" : "default"}
              onClick={() => {
                setModalVariant("final");
                setPdfModalActive(true);
              }}
            >
              {t("pdf.reportFromRepair")}
            </Button>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
              <Button
                size={mobile ? "small" : "default"}
                onClick={() => {
                  setModalVariant("receive");
                  setPdfModalActive(true);
                }}
              >
                {t("pdf.receiveConfirm")}
              </Button>
              <Button
                size={mobile ? "small" : "default"}
                disabled={!repairSteps?.length}
                onClick={() => {
                  setModalVariant("state");
                  setPdfModalActive(true);
                }}
              >
                {t("pdf.currentRepairState")}
              </Button>
            </div>
          )}
        </div>
      )}

      <DuplicateRepairModal
        active={duplicateRepairModalActive}
        setActive={setDuplicateRepairModalActive}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
      />
      <PdfModal
        variant={modalVariant}
        repair={repairDetails}
        repairSteps={repairSteps}
        replacementInfo={replacementInfo?.[0]}
        todayDate={todayDate}
        constants={constants}
        lang={user.lang}
        active={pdfModalActive}
        onClose={() => setPdfModalActive(false)}
      />
    </div>
  );
};

export default ActionButtons;
