import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import TextField from "stories/components/TextField/TextField";
import Checkbox from "stories/components/Checkbox/Checkbox";
import TextButton from "components/TextButton/TextButton";
import { PRIVACY_POLICY_LINKS } from "utils/config";
import { filterErrorsFromCommonApiErrors, getErrorForInputField, removeEmptyValuesFromObject } from "utils/global-functions";
import { getLanguage } from "../utils";
import { contactMessageTitle, contactTypeMap, emailOptionName, phoneOptionName, sentStateName } from "./config";

const ContactForm = ({ contactOption, setContactOption }) => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const {
    app: { commonApiErrors },
    user,
  } = useSelector((store) => store);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sendContactDataSuccess = () => {
    setContactOption(sentStateName);
  };

  const sendContactDataError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const sendContactData = (values, token) => {
    const body = {
      ...values,
      grcToken: token,
      title: contactMessageTitle,
      contactType: contactTypeMap[contactOption],
      url: window.location.href,
    };

    agent.Email.emailSendFormData(removeEmptyValuesFromObject(body))
      .then(sendContactDataSuccess, sendContactDataError)
      .finally(() => setLoading(false));
  };

  const setCaptchaToken = (values) => {
    setLoading(true);
    window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "submit" }).then((token) => {
      sendContactData(values, token);
    });
  };

  const addCaptchaScript = () => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`;
    document.body.appendChild(script);
  };

  useEffect(() => addCaptchaScript(), []);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().max(50, t("errors.102")).required(t("errors.99")),
    phone: Yup.string().max(50, t("errors.102")),
    comment: Yup.string().max(1024, t("errors.102")),
    email: Yup.string().email(t("errors.104")),
  });

  if (contactOption !== emailOptionName && contactOption !== phoneOptionName) return;

  return (
    <Formik validationSchema={validationSchema} initialValues={{ fullName: "", email: "", phone: "", comment: "", personalDataAgreement: false }}>
      {({ touched, errors, handleBlur, values, setFieldValue }) => (
        <Form>
          <div className="contact-form__container">
            <TextField
              id="fullName"
              name="fullName"
              label={t("global.fullName")}
              placeholder={t("global.provideFullName")}
              value={values.fullName}
              onBlur={handleBlur}
              error={getErrorForInputField(errors.fullName, touched.fullName) || formErrors?.fullName}
              onChange={(value) => setFieldValue("fullName", value)}
            />
            {contactOption !== phoneOptionName && (
              <TextField
                id="email"
                name="email"
                onBlur={handleBlur}
                label={t("global.email")}
                placeholder={t("global.provideEmail")}
                value={values.email}
                error={getErrorForInputField(errors.email, touched.email) || formErrors?.email}
                onChange={(value) => setFieldValue("email", value)}
              />
            )}
            {contactOption !== emailOptionName && (
              <TextField
                id="phone"
                name="phone"
                onBlur={handleBlur}
                label={t("global.phoneNumber")}
                placeholder={t("global.provideTelephone")}
                value={values.phone}
                error={getErrorForInputField(errors.phone, touched.phone) || formErrors?.phone}
                onChange={(value) => setFieldValue("phone", value)}
              />
            )}
            <TextField
              id="comment"
              name="comment"
              fullHeight
              onBlur={handleBlur}
              label={t("global.message")}
              className="contact-form__message-field"
              placeholder={t("serialNumber.writeUsAMessage")}
              variant="multi"
              value={values.comment}
              error={getErrorForInputField(errors.comment, touched.comment) || formErrors?.comment}
              onChange={(value) => setFieldValue("comment", value)}
            />
            <Checkbox
              checked={values.personalDataAgreement}
              onChange={() => setFieldValue("personalDataAgreement", !values.personalDataAgreement)}
              className="contact-form__checkbox"
              label={
                <p className="contact-form__personal-agreement">
                  {t("serialNumber.personalAgreement1")}{" "}
                  <a className="custom-link" href={PRIVACY_POLICY_LINKS[getLanguage(user)]} target="_blank" rel="noreferrer">
                    {t("global.privacyPolicy")}
                  </a>{" "}
                  {t("serialNumber.personalAgreement2")}
                </p>
              }
            />
            <TextButton className="contact-form__back-button" onClick={() => setContactOption("")}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2927 16.7072L6.29275 10.7072C5.90175 10.3162 5.90175 9.68425 6.29275 9.29325L12.2927 3.29325C12.6838 2.90225 13.3158 2.90225 13.7067 3.29325C14.0977 3.68425 14.0977 4.31625 13.7067 4.70725L8.41375 10.0002L13.7067 15.2933C14.0977 15.6842 14.0977 16.3162 13.7067 16.7072C13.5117 16.9022 13.2557 17.0002 12.9997 17.0002C12.7437 17.0002 12.4877 16.9022 12.2927 16.7072Z"
                  fill="#EA1D28"
                />
              </svg>
              <span>{t("global.back")}</span>
            </TextButton>
            <Button
              className="contact-form__send-button g-recaptcha"
              variant="primary"
              data-sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onClick={() => setCaptchaToken(values)}
              disabled={!values.personalDataAgreement}
              loading={loading}
            >
              {t("global.send")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
