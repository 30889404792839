import { useState, useCallback, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showReload, setShowReload] = useState(false);

  const onSWUpdate = useCallback((registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }, []);

  const reloadPage = useCallback(async () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    if (!(await navigator.serviceWorker.ready.waiting))
      setTimeout(() => {
        window.location.reload();
        setShowReload(false);
      }, 1000);
  }, [waitingWorker]);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
  }, [onSWUpdate]);

  return { showReload, waitingWorker, reloadPage };
};

export default useServiceWorker;
