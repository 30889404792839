import React, { forwardRef } from "react";
import { getClassNamesForTextField } from "./utils";

const SendMessageMultilineField = forwardRef(
  ({ id, disabled, name, placeholder, value, onClick, onChange, loading, buttonContent, numberOfLines = 1, disableResize = true }, ref) => {
    return (
      <div className={`input-send ${getClassNamesForTextField(disabled, disableResize)}`}>
        <textarea
          id={id}
          ref={ref}
          disabled={disabled}
          name={name}
          rows={numberOfLines}
          className="input-send__field"
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (!disabled) onChange(event.target.value);
          }}
        />
        <button
          disabled={disabled || loading}
          className={`input-send__button input-send__button--multiline ${disabled ? "input-send__button--disabled" : ""}`}
          onClick={() => onClick()}
        >
          {loading ? (
            <div
              className="spinner-border"
              role="status"
              style={{ width: "1.25rem", height: "1.25rem", borderWidth: "0.25rem", color: "var(--primary-color)" }}
            />
          ) : (
            buttonContent
          )}
        </button>
      </div>
    );
  },
);

export default SendMessageMultilineField;
