/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MobileViewUnavailable from "components/Utils/MobileViewUnavailable";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "stories/components/Spinner/Spinner";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import SelectField from "stories/components/SelectField/SelectField";
import { useTranslation } from "react-i18next";
import agent from "api/agent";
import VarDump from "components/Utils/VarDump";
import Badge from "stories/components/Badge/Badge";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Tooltip from "stories/components/Tooltip/Tooltip";

const EmailConfigItemView = ({ item, index, editClick }) => {
  const app = useSelector((store) => store.app);
  const { t } = useTranslation();
  return (
    <tr className={[item.isModified && "modified", item.customerView && "text--bold"].join(" ")}>
      <td>E{item.eventType}</td>
      <td title={`${t("management.event.customerView")}?`} className="text--center">
        {item.customerView ? <span>&radic;</span> : <span></span>}
      </td>
      <td title={t("global.event")} className="management-config-events">
        {t("constants.repair.eventtype." + app?.constants?.repairEventType?.[item.eventType]?.strVal)}
      </td>
      <td title={t("management.email.delivery")}>
        {item.delivery ? (
          <Badge
            content={
              typeof app.constants.emailsDelivery?.[item.delivery] === "object"
                ? t(`constants.emails.delivery.${app.constants.emailsDelivery[item.delivery].strVal}`)
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsDelivery?.[item.delivery] === "object" ? app.constants.emailsDelivery[item.delivery].bgColor : null}
            uppercase={false}
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("constants.user.role.customer")}>
        {item.customer ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.customer] === "object"
                ? app.constants.emailsRecipient[item.customer].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsRecipient?.[item.customer] === "object" ? app.constants.emailsRecipient[item.customer].bgColor : null}
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("constants.user.role.technician")}>
        {item.technician ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.technician] === "object"
                ? app.constants.emailsRecipient[item.technician].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={
              typeof app.constants.emailsRecipient?.[item.technician] === "object" ? app.constants.emailsRecipient[item.technician].bgColor : null
            }
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("constants.user.role.reception")}>
        {item.reception ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.reception] === "object"
                ? app.constants.emailsRecipient[item.reception].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsRecipient?.[item.reception] === "object" ? app.constants.emailsRecipient[item.reception].bgColor : null}
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("constants.user.role.admin")}>
        {item.admin ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.admin] === "object"
                ? app.constants.emailsRecipient[item.admin].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsRecipient?.[item.admin] === "object" ? app.constants.emailsRecipient[item.admin].bgColor : null}
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("global.recipient")}>
        {item.recipient ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.recipient] === "object"
                ? app.constants.emailsRecipient[item.recipient].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsRecipient?.[item.recipient] === "object" ? app.constants.emailsRecipient[item.recipient].bgColor : null}
          />
        ) : (
          "-"
        )}
      </td>
      <td title={t("global.author")}>
        {item.author ? (
          <Badge
            content={
              typeof app.constants.emailsRecipient?.[item.author] === "object"
                ? app.constants.emailsRecipient[item.author].strVal
                : t("global.notDefinedConstant")
            }
            backgroundColor={typeof app.constants.emailsRecipient?.[item.author] === "object" ? app.constants.emailsRecipient[item.author].bgColor : null}
          />
        ) : (
          "-"
        )}
      </td>
      <td className="management-config-actions text--no-bold">
        <Button
          variant="default"
          size="small"
          style={{ width: "60px" }}
          onClick={() => {
            editClick(index);
          }}
        >
          {t("global.edit")}
        </Button>
      </td>
    </tr>
  );
};

const EmailConfigForm = ({ item, index, editCancel, editApply }) => {
  const [formData, setFormData] = useState(item);
  const app = useSelector((store) => store.app);
  const { t } = useTranslation();
  const deliveryOptions = [0, 1, 2].map((item) => {
    return {
      value: item,
      label:
        typeof app.constants.emailsDelivery?.[item] === "object"
          ? t(`constants.emails.delivery.${app.constants.emailsDelivery[item].strVal}`)
          : t("global.notDefinedConstant"),
    };
  });
  const recipientOptions = [0, 1, 2, 3].map((item) => {
    return {
      value: item,
      label: typeof app.constants.emailsRecipient?.[item] === "object" ? app.constants.emailsRecipient[item].strVal : t("global.notDefinedConstant"),
    };
  });

  const handleChange = (value, name) => {
    const obj = { ...formData };
    obj[name] = name !== "customerView" ? value : value ? 1 : 0;
    setFormData(obj);
  };

  return (
    <Modal
      id={`ECF${index}`}
      title={t("constants.repair.eventtype." + app?.constants?.repairEventType?.[item.eventType]?.strVal)}
      onClose={editCancel}
      bsBackdrop="static"
      footerActions={
        <div className="modal-buttons-container">
          <Button variant="default" onClick={editCancel}>
            {t("global.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              editApply(formData, index);
            }}
          >
            {t("global.apply")}
          </Button>
        </div>
      }
    >
      <div>
        <div className="mb-3">
          <Checkbox label={`${t("management.event.customerView")}?`} name="customerView" checked={formData.customerView} onChange={handleChange} />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.delivery}
            name="delivery"
            options={deliveryOptions}
            onChange={handleChange}
            label={t("management.email.delivery")}
            className="management-email-select"
          />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.customer}
            name="customer"
            options={recipientOptions}
            onChange={handleChange}
            label={t("constants.user.role.customer")}
          />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.technician}
            name="technician"
            options={recipientOptions}
            onChange={handleChange}
            label={t("constants.user.role.technician")}
          />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.reception}
            name="reception"
            options={recipientOptions}
            onChange={handleChange}
            label={t("constants.user.role.reception")}
          />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.admin}
            name="admin"
            options={recipientOptions}
            onChange={handleChange}
            label={t("constants.user.role.admin")}
          />
        </div>
        <div className="mb-2">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.recipient}
            name="recipient"
            options={recipientOptions}
            onChange={handleChange}
            label={`${t("global.recipient")} (${t("global.only")} ${t("global.conversations").toLowerCase()})`}
            disabled={item.eventType > 100 ? false : true}
          />
        </div>
        <div className="mb-5">
          <SelectField
            placeholder={t("global.selectFromList")}
            multi={false}
            value={formData.author}
            name="author"
            options={recipientOptions}
            onChange={handleChange}
            label={`${t("global.author")} (${t("global.only")} ${t("global.conversations").toLowerCase()})`}
            disabled={item.eventType > 100 ? false : true}
          />
        </div>
      </div>
    </Modal>
  );
};

export const ManagementEmail = () => {
  const user = useSelector((store) => store.user);
  const desktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [emailConfigArray, setEmailConfigArray] = useState([]);
  const [editedIndex, setEditedIndex] = useState(null);

  useEffect(() => {
    emailConfigGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailConfigGet = () => {
    setFetching(true);
    agent.Management.emailConfigGet({ id: user.id, token: user.token }).then(
      (response) => {
        const arr = response.data;
        arr.forEach((item, index) => {
          arr[index].originalData = { ...item };
          arr[index].isModified = false;
        });
        setEmailConfigArray(arr);
        setFetching(false);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "emailConfigGet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const emailConfigSet = () => {
    setFetching(true);
    const arr = emailConfigArray
      .filter((item) => item.isModified)
      .map((item) => {
        return {
          id: item.id,
          customerView: item.customerView,
          delivery: item.delivery,
          customer: item.customer,
          technician: item.technician,
          reception: item.reception,
          admin: item.admin,
          author: item.author,
          recipient: item.recipient,
        };
      });
    agent.Management.emailConfigSet({ id: user.id, token: user.token, data: arr }).then(
      (response) => {
        if (response.result === 301) emailConfigGet();
        else {
          setFetching(false);
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "emailConfigSet.failed" } });
        }
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "emailConfigSet.failed" } });
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
      },
    );
  };

  const editClick = (index) => {
    setEditedIndex(index);
  };
  const editCancel = () => {
    setEditedIndex(null);
  };
  const editApply = (item, index) => {
    setEditedIndex(null);
    item.isModified =
      item.customerView !== item.originalData.customerView ||
      item.delivery !== item.originalData.delivery ||
      item.customer !== item.originalData.customer ||
      item.technician !== item.originalData.technician ||
      item.reception !== item.originalData.reception ||
      item.admin !== item.originalData.admin ||
      item.author !== item.originalData.author ||
      item.recipient !== item.originalData.recipient
        ? true
        : false;
    const arr = [...emailConfigArray];
    arr[index] = item;
    setEmailConfigArray(arr);
  };

  if (!desktop) return <MobileViewUnavailable />;
  return user.role !== "admin" ? (
    <h1>Access denied!</h1>
  ) : (
    <>
      <div className="mb-5">
        {fetching ? (
          <div className="mt-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mt-2 p-3">
              <table className="management-config">
                <thead>
                  <tr>
                    <th>
                      <Tooltip direction="top" content={t("management.event.typeId")}>
                        <span>ET</span>
                      </Tooltip>
                    </th>
                    <th className="text--center">
                      <Tooltip direction="top" content={t("management.event.customerView") + "?"}>
                        <div>CV</div>
                      </Tooltip>
                    </th>
                    <th>{t("global.event")}</th>
                    <th>{t("management.email.delivery")}</th>
                    <th>{t("constants.user.role.customer")}</th>
                    <th>{t("constants.user.role.technician")}</th>
                    <th>{t("constants.user.role.reception")}</th>
                    <th>{t("constants.user.role.admin")}</th>
                    <th>{t("global.recipient")}</th>
                    <th>{t("global.author")}</th>
                  </tr>
                </thead>
                <tbody>
                  {emailConfigArray.map((item, index) => (
                    <EmailConfigItemView key={item.id} item={item} index={index} editClick={editClick} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3 p-3">
              <Button variant="primary" onClick={emailConfigSet} disabled={emailConfigArray.filter((item) => item.isModified).length ? false : true}>
                {t("global.savechanges")}
              </Button>
            </div>
            {editedIndex !== null ? (
              <EmailConfigForm item={emailConfigArray[editedIndex]} index={editedIndex} editCancel={editCancel} editApply={editApply} />
            ) : null}
            {0 ? <VarDump name="emailConfigArray" data={emailConfigArray} /> : null}
          </>
        )}
      </div>
    </>
  );
};
