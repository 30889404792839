/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import { getBodyForAccountInfo } from "./utils";

const ContactInfo = ({ summaryDetails: { contactEmail, contactPerson, contactPhone } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    registration: { registrationUserId },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [userLoading, setUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user);

  const getUserInfoSuccess = (response) => {
    setSelectedUser(response.data.at(0));
    if (isAdmin(user) || isReception(user)) return;
    return dispatch({ type: "USER_LOGIN", ...response.data.at(0), id: user.id, token: user.token });
  };

  const getUserInfoError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getUserInfo = () => {
    agent.Users.getUser(getBodyForAccountInfo(user, registrationUserId))
      .then(getUserInfoSuccess, getUserInfoError)
      .finally(() => setUserLoading(false));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (userLoading) return;

  return (
    <div className="add-report-summary__contact-info">
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.fullName")}</span>
        <span className="paragraph--small">{contactPerson || selectedUser.personName}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.phone")}</span>
        <span className="paragraph--small">{contactPhone || selectedUser.phone}</span>
      </div>
      <div className="add-report-summary__items-wrapper">
        <span className="paragraph--small text--subdued">{t("global.email")}</span>
        <span className="paragraph--small">{contactEmail || selectedUser.email}</span>
      </div>
    </div>
  );
};

export default ContactInfo;
