import React from "react";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isAdmin, isReception } from "utils/global-functions";
import Button from "stories/components/Button/Button";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Stack from "stories/components/Stack/Stack";
import { handleAllItemsSelection } from "../utils";

const BottomActions = ({ selectedItems, setSelectedItems, activeBulkActionModal, setActiveBulkActionModal, repairListLoading }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairsList },
  } = useSelector((store) => store);

  if (!isAdmin(user) && !isReception(user)) return;

  return (
    <div className="admin-reports-bottom-actions">
      <Stack alignItems="center" gap="0.6rem">
        <Checkbox
          disabled={!repairsList.length || repairListLoading}
          label={t("global.selectAll")}
          checked={repairsList.every((repair) => selectedItems.some((selected) => selected === repair.repairId)) && repairsList.length > 0}
          onChange={() => setSelectedItems(handleAllItemsSelection(selectedItems, repairsList))}
        />
        <Button disabled={lodash.isEmpty(selectedItems)} onClick={() => setActiveBulkActionModal(!activeBulkActionModal)} size="small">
          {`${t("global.bulkAction")} (${selectedItems.length}/${repairsList.length})`}
        </Button>
      </Stack>
    </div>
  );
};

export default BottomActions;
