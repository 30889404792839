import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmployeeSelector from "components/EmployeeSelector/EmployeeSelector";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import { DISABLED_REPAIR_STATES, IS_IN_QUEUE_OPTIONS } from "utils/config";
import { getErrorForInputField, isAdmin, isReception } from "utils/global-functions";
import { isRepairDetailsModificationForbidden, isRepairDetailsOnlyAdminAllowed } from "./utils";

const DetailsForm = ({ values, setFieldValue, errors, handleBlur, touched, formErrors }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
    repair: { repairDetails },
  } = useSelector((store) => store);

  return (
    <div className="repair-info-form">
      <SelectField
        onBlur={handleBlur}
        id="repairType"
        label={t("global.repairType")}
        placeholder={t("global.chooseFromList")}
        isSearchable={false}
        value={values.repairType}
        onChange={(value) => setFieldValue("repairType", value)}
        options={constants.repairTypeArr
          ?.filter(({ intVal }) => intVal !== 0)
          .map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.type.${strVal}`) };
          })}
        error={getErrorForInputField(errors.repairState, touched.repairState) || formErrors?.repairState}
        disabled={user.id !== repairDetails.technicianId && !isAdmin(user) && !isReception(user)}
      />
      <SelectField
        onBlur={handleBlur}
        id="repairKind"
        label={t("global.repairKind")}
        placeholder={t("global.chooseFromList")}
        isSearchable={false}
        value={values.repairKind}
        onChange={(value) => setFieldValue("repairKind", value)}
        options={constants.repairKindArr
          ?.filter(({ intVal }) => intVal !== 0)
          .map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.kind.${strVal}`) };
          })}
        error={getErrorForInputField(errors.repairKind, touched.repairKind) || formErrors?.repairKind}
        disabled={user.id !== repairDetails.technicianId && !isAdmin(user) && !isReception(user)}
      />
      <SelectField
        onBlur={handleBlur}
        id="repairState"
        label={t("global.repairState")}
        placeholder={t("global.chooseFromList")}
        isSearchable={false}
        value={values.repairState}
        onChange={(value) => setFieldValue("repairState", value)}
        options={constants.repairStateArr
          ?.filter(({ intVal }) => intVal !== 0)
          .map(({ intVal, strVal }) => {
            return { value: intVal, label: t(`constants.repair.state.${strVal}`), disabled: DISABLED_REPAIR_STATES.includes(intVal) };
          })}
        error={getErrorForInputField(errors.repairState, touched.repairState) || formErrors?.repairState}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <EmployeeSelector
        id="technicianId"
        size="default"
        onBlur={handleBlur}
        value={values.technicianId}
        onChange={(value) => setFieldValue("technicianId", value)}
        error={getErrorForInputField(errors.technicianId, touched.technicianId) || formErrors?.technicianId}
        placeholder={t("global.chooseFromList")}
        label={t("global.serviceTechnician")}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <SelectField
        onBlur={handleBlur}
        id="isInQueue"
        label={t("global.isInQueue")}
        placeholder={t("global.chooseFromList")}
        isSearchable={false}
        value={values.isInQueue}
        onChange={(value) => setFieldValue("isInQueue", value)}
        options={IS_IN_QUEUE_OPTIONS.map(({ label, value }) => {
          return { value, label: t(`global.${label}`), disabled: value === 1 };
        })}
        error={getErrorForInputField(errors.isInQueue, touched.isInQueue) || formErrors?.isInQueue}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <DatePicker
        label={t("global.suggestedRepairDate")}
        placeholder={t("global.chooseDate")}
        date={values.suggestedRepairDate}
        onChange={(value) => setFieldValue("suggestedRepairDate", value)}
        error={getErrorForInputField(errors.suggestedRepairDate, touched.suggestedRepairDate) || formErrors?.suggestedRepairDate}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      {!!values.endDate && <TextField label={t("global.endDate")} value={values.endDate} disabled />}
      <TextField
        id="shippingNoticeNumber"
        name="shippingNoticeNumber"
        label={t("global.shippingNoticeNumber")}
        placeholder={t("global.provideShippingNoticeNumber")}
        value={values.shippingNoticeNumber}
        onChange={(value) => setFieldValue("shippingNoticeNumber", value)}
        error={getErrorForInputField(errors.shippingNoticeNumber, touched.shippingNoticeNumber) || formErrors?.shippingNoticeNumber}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <TextField
        id="invoiceNumber"
        name="invoiceNumber"
        label={t("global.invoice")}
        placeholder={t("global.provideInvoice")}
        value={values.invoiceNumber}
        onChange={(value) => setFieldValue("invoiceNumber", value)}
        error={getErrorForInputField(errors.invoiceNumber, touched.invoiceNumber) || formErrors?.invoiceNumber}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <TextField
        id="producerRepairNumber"
        name="producerRepairNumber"
        label={t("myRepairs.producerRepairNumber")}
        placeholder={t("global.provideRepairId")}
        value={values.producerRepairNumber}
        onChange={(value) => setFieldValue("producerRepairNumber", value)}
        error={getErrorForInputField(errors.producerRepairNumber, touched.producerRepairNumber) || formErrors?.producerRepairNumber}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <TextField
        id="rentInfo"
        name="rentInfo"
        label={t("myRepairs.rentInfo")}
        placeholder={t("global.provideCompanyName")}
        value={values.rentInfo}
        onChange={(value) => setFieldValue("rentInfo", value)}
        error={getErrorForInputField(errors.rentInfo, touched.rentInfo) || formErrors?.rentInfo}
        onBlur={handleBlur}
        disabled={isRepairDetailsModificationForbidden(user)}
      />
      <TextField
        id="delegationCount"
        name="delegationCount"
        label={t("myRepairs.delegationCount")}
        placeholder={t("global.provideCompanyName")}
        value={values.delegationCount}
        onChange={(value) => setFieldValue("delegationCount", value)}
        error={getErrorForInputField(errors.delegationCount, touched.delegationCount) || formErrors?.delegationCount}
        onBlur={handleBlur}
        disabled={isRepairDetailsOnlyAdminAllowed(user)}
      />
    </div>
  );
};

export default DetailsForm;
