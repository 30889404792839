import React from "react";
import PropTypes from "prop-types";
import "../commonStyles.css";
import { getButtonClassName } from "./utils";
import "./styles.css";

const Button = ({
  type = "button",
  disabled = false,
  loading = false,
  outline = false,
  fullWidth = false,
  fullHeight = false,
  variant = "default",
  size = "default",
  className,
  children,
  onClick,
  ...rest
}) => (
  <button
    disabled={disabled || loading}
    type={type}
    className={`button ${getButtonClassName({ variant, size, fullWidth, disabled, loading, outline, fullHeight })} ${className ? className : ""}`}
    onClick={onClick}
    {...rest}
  >
    <div className="button__content">
      {loading && <div className="spinner-border" role="status" style={{ width: "1.5rem", height: "1.5rem", borderWidth: "0.25rem" }} />}
      <div>{children}</div>
    </div>
  </button>
);

Button.propTypes = {
  variant: PropTypes.oneOf(["default", "primary", "error"]),
  size: PropTypes.oneOf(["big", "default", "small"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  outline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Button;
