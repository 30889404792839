import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Radio from "stories/components/Radio/Radio";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import { getErrorForInputField, isAdmin, isReception } from "utils/global-functions";

const AddContactDataForm = ({ formErrors, values, setFieldValue, touched, errors, handleBlur }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  return (
    <Stack flexDirection="column" gap="1.3rem">
      <Radio
        checked={!values.newContactData}
        onChange={() => setFieldValue("newContactData", !values.newContactData)}
        label={t(`addReport.${isAdmin(user) || isReception(user) ? "useClientContactData" : "useCurrentContactData"}`)}
      />
      <Radio
        checked={values.newContactData}
        onChange={() => setFieldValue("newContactData", !values.newContactData)}
        label={t("addReport.provideOtherContactData")}
      />
      {values.newContactData && (
        <>
          <p className="paragrapgh--small text--subdued">{t("addReport.contactFormInfo")}</p>
          <TextField
            id="contactPerson"
            name="contactPerson"
            maxLength={50}
            value={values.contactPerson}
            onChange={(value) => setFieldValue("contactPerson", value)}
            onBlur={handleBlur}
            error={getErrorForInputField(errors.contactPerson, touched.contactPerson) || formErrors?.contactPerson}
            label={t("global.fullName")}
            placeholder={t("global.provideFullName")}
          />
          <div className="add-report-contact__form-group">
            <TextField
              id="contactPhone"
              name="contactPhone"
              maxLength={50}
              value={values.contactPhone}
              onChange={(value) => setFieldValue("contactPhone", value)}
              onBlur={handleBlur}
              error={getErrorForInputField(errors.contactPhone, touched.contactPhone) || formErrors?.contactPhone}
              label={t("global.phone")}
              placeholder={t("global.provideTelephone")}
            />
            <TextField
              id="contactEmail"
              name="contactEmail"
              maxLength={255}
              value={values.contactEmail}
              onChange={(value) => setFieldValue("contactEmail", value)}
              onBlur={handleBlur}
              error={getErrorForInputField(errors.contactEmail, touched.contactEmail) || formErrors?.contactEmail}
              label={t("global.email")}
              placeholder={t("global.provideEmail")}
            />
          </div>
        </>
      )}
    </Stack>
  );
};

export default AddContactDataForm;
