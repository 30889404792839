import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}api/`;
axios.defaults.withCredentials = false;
axios.defaults.headers = {
  "Content-Type": "text/plain;charset=UTF-8",
};

const buildFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).every((key) => {
    if (object[key]?.toLocaleString().includes("FileList")) {
      for (let i = 0; i < object[key].length; i++) formData.append(`${key}[]`, object[key][i]);
      return true;
    }

    formData.append(key, object[key]);
    return true;
  });
  return formData;
};

const responseBody = (response) => response.data;

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body, options = { formData: false }) => axios.post(url, options.formData ? buildFormData(body) : body).then(responseBody),
  put: (url, body, options = { formData: false }) => axios.put(url, options.formData ? buildFormData(body) : body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
};

const App = {
  getConstants: (body) => requests.post("", { ...body, cmd: "getConstants" }),
};

const Conversations = {
  conversationsManage: (body) => requests.post("", { ...body, cmd: "conversationsManage" }, { formData: true }),
  conversationsList: (body) => requests.post("", { ...body, cmd: "conversationsList" }),
  conversationsCount: (body) => requests.post("", { ...body, cmd: "conversationsCount" }),
  conversationsAlert: (body) => requests.post("", { ...body, cmd: "conversationsAlert" }),
};

const Email = {
  emailSendReportAttention: (body) => requests.post("", { ...body, cmd: "emailSendReportAttention" }),
  emailSendActionEmail: (body) => requests.post("", { ...body, cmd: "emailSendActionEmail" }),
  emailSendFormData: (body) => requests.post("", { ...body, cmd: "emailSendFormData" }),
};

const ProducerReport = {
  producerReportList: (body) => requests.post("", { ...body, cmd: "producerReportList" }),
  producerReportManage: (body) => requests.post("", { ...body, cmd: "producerReportManage" }),
  producerReportManagePart: (body) => requests.post("", { ...body, cmd: "producerReportManagePart" }),
  producerReportDictionary: (body) => requests.post("", { ...body, cmd: "producerReportDictionary" }),
};

const Registration = {
  registrationProductAdd: (body) => requests.post("", { ...body, cmd: "registrationProductAdd" }, { formData: true }),
  registrationProductDelete: (body) => requests.post("", { ...body, cmd: "registrationProductDelete" }),
  registrationProductList: (body) => requests.post("", { ...body, cmd: "registrationProductList" }),
  registrationCancel: (body) => requests.post("", { ...body, cmd: "registrationCancel" }),
  registrationShipmentSet: (body) => requests.post("", { ...body, cmd: "registrationShipmentSet" }),
  registrationShipmentGet: (body) => requests.post("", { ...body, cmd: "registrationShipmentGet" }),
  registrationContactSet: (body) => requests.post("", { ...body, cmd: "registrationContactSet" }),
  registrationContactGet: (body) => requests.post("", { ...body, cmd: "registrationContactGet" }),
  registrationDetails: (body) => requests.post("", { ...body, cmd: "registrationDetails" }),
  registrationConfirm: (body) => requests.post("", { ...body, cmd: "registrationConfirm" }),
  registrationResult: (body) => requests.post("", { ...body, cmd: "registrationResult" }),
};

const Resources = {
  checkWarranty: (body) => requests.post("", { ...body, cmd: "warrantyCheck" }),
  customerSearch: (body) => requests.post("", { ...body, cmd: "customerSearch" }),
  employeeSearch: (body) => requests.post("", { ...body, cmd: "employeeSearch" }),
  externalSerialNumber: (body) => requests.post("", { ...body, cmd: "externalSerialNumber" }),
  getProductsList: (body) => requests.post("", { ...body, cmd: "productsList" }),
  accountSearch: (body) => requests.post("", { ...body, cmd: "accountSearch" }),
};

const Repairs = {
  showRepairs: (body) => requests.post("", { ...body, cmd: "repairSearch" }),
  getTabs: (body) => requests.post("", { ...body, cmd: "repairSearchFilterList" }),
  manageTabs: (body) => requests.post("", { ...body, cmd: "repairSearchFilterManage" }),
  repairEvents: (body) => requests.post("", { ...body, cmd: "repairEvents" }),
  repairBulkAction: (body) => requests.post("", { ...body, cmd: "repairBulkAction" }),
  getRepairDetails: (body) => requests.post("", { ...body, cmd: "repairDetails" }),
  setRepairContact: (body) => requests.post("", { ...body, cmd: "repairSetContact" }),
  changeUser: (body) => requests.post("", { ...body, cmd: "repairSetUserId" }),
  setRepairComment: (body) => requests.post("", { ...body, cmd: "repairSetComment" }),
  setRepairSerialNumber: (body) => requests.post("", { ...body, cmd: "repairSetSerialNumber" }),
  duplicateRepair: (body) => requests.post("", { ...body, cmd: "repairDuplicate" }),
  deleteRepair: (body) => requests.post("", { ...body, cmd: "repairDelete" }),
  setRepairDetails: (body) => requests.post("", { ...body, cmd: "repairSetDetails" }),
  setRepairReplacement: (body) => requests.post("", { ...body, cmd: "repairSetReplacement" }),
  setRepairReturnAddress: (body) => requests.post("", { ...body, cmd: "repairSetReturn" }),
  handleRepairAttachments: (body) => requests.post("", { ...body, cmd: "repairAttachments" }, { formData: true }),
  elmaticConfiguration: (body) => requests.post("", { ...body, cmd: "elmaticConfiguration" }),
};

const Management = {
  queueConfigGet: (body) => requests.post("", { ...body, cmd: "queueConfigGet" }),
  queueConfigSet: (body) => requests.post("", { ...body, cmd: "queueConfigSet" }),
  queueAccessGet: (body) => requests.post("", { ...body, cmd: "queueAccessGet" }),
  queueAccessSet: (body) => requests.post("", { ...body, cmd: "queueAccessSet" }),
  emailConfigGet: (body) => requests.post("", { ...body, cmd: "emailConfigGet" }),
  emailConfigSet: (body) => requests.post("", { ...body, cmd: "emailConfigSet" }),
  dictionaryListItems: (body) => requests.post("", { ...body, cmd: "dictionaryListItems" }),
  dictionaryManageItem: (body) => requests.post("", { ...body, cmd: "dictionaryManageItem" }),
  unapprovedReportsList: (body) => requests.post("", { ...body, cmd: "unapprovedReportsList" }),
  queueGetRepair: (body) => requests.post("", { ...body, cmd: "queueGetRepair" }),
};

const Users = {
  login: (body) => requests.post("", { ...body, cmd: "userLogin" }),
  getUser: (body) => requests.post("", { ...body, cmd: "userAccInfo" }),
  setLanguage: (body) => requests.post("", { ...body, cmd: "userSetLang" }),
  logout: (body) => requests.post("", { ...body, cmd: "userLogout" }),
};

const ExternalResources = {
  getProductStoreInfo: (body) =>
    axios
      .post(`${process.env.REACT_APP_STORE_SERVICE_API_URL}getProductUrlByName`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_STORE_SERVICE_API_KEY}`,
        },
      })
      .then(responseBody),
};

const DeepDive = {
  login: () =>
    axios
      .post(
        `${process.env.REACT_APP_DEEPDIVE_API_BASE_URL}user/login`,
        { key_token: process.env.REACT_APP_DEEPDIVE_API_KEY_TOKEN },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(responseBody),
  getLogs: (body, authToken, serialNumber) =>
    axios
      .post(
        `${process.env.REACT_APP_DEEPDIVE_API_BASE_URL}device/${encodeURIComponent(serialNumber)}/generate/support-file`,
        { ...body, endpoint: `${process.env.REACT_APP_BACKEND_URL}api/` },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      .then(responseBody),
};

const agent = {
  App,
  Conversations,
  Email,
  DeepDive,
  ExternalResources,
  ProducerReport,
  Registration,
  Resources,
  Repairs,
  Management,
  Users,
};

export default agent;
