import React from "react";
import { useMediaQuery } from "react-responsive";
import Pagination from "components/Pagination/Pagination";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import "./styles.css";

export const Footer = ({ reportList, pageNumber, setPageNumber, pageSize, setPageSize }) => {
  const mobile = useMediaQuery({ maxWidth: 850 });

  return (
    <div className={mobile ? "mobile-producer-report-footer" : ""}>
      {mobile && reportList.length !== 0 && (
        <PageSizeSelector
          selectedPageSize={pageSize}
          pageSizeOptions={[
            { value: 25, label: 25 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          onChange={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
        />
      )}

      {reportList.length !== 0 && (
        <Pagination
          pageSize={pageSize}
          pageNumber={pageNumber}
          listLength={reportList?.length}
          previousAction={() => {
            setPageNumber(pageNumber - 1);
          }}
          continueAction={() => {
            setPageNumber(pageNumber + 1);
          }}
        />
      )}
    </div>
  );
};
