import { DEFAULT_PAGE_SIZE } from "utils/config";
import { readCookie } from "utils/global-functions";

const repairReducer = (
  state = {
    repairsList: [],
    appliedFilters: { isClosed: 0 },
    filtersInMainTab: { isClosed: 0 },
    pageSize: parseInt(readCookie("repairsPageSize")) || DEFAULT_PAGE_SIZE,
    tabs: [],
    selectedTabId: "all",
    filtersFormErrors: {},
    repairDetails: {},
  },
  action,
) => {
  switch (action.type) {
    case "REPAIR_SET_REPAIRSLIST":
      return { ...state, repairsList: action.repairsList };
    case "REPAIR_SET_APPLIEDFILTERS":
      const newSetAppliedFiltersState =
        state.selectedTabId === "all"
          ? { ...state, filtersInMainTab: { ...action.appliedFilters }, appliedFilters: { ...action.appliedFilters } }
          : { ...state, appliedFilters: { ...action.appliedFilters } };
      return newSetAppliedFiltersState;
    case "REPAIR_SET_FILTERS_IN_MAIN_TAB":
      return { ...state, filtersInMainTab: { ...action.filtersInMainTab } };
    case "REPAIR_CLEAR_APPLIEDFILTER":
      const newAppliedFilters = Object.fromEntries(Object.entries(state.appliedFilters).filter(([key]) => key !== action.filterName));
      const newClearAppliedFiltersState =
        state.selectedTabId === "all"
          ? { ...state, filtersInMainTab: { ...newAppliedFilters }, appliedFilters: { ...newAppliedFilters } }
          : { ...state, appliedFilters: { ...newAppliedFilters } };
      return newClearAppliedFiltersState;
    case "REPAIR_CLEAR_ALL_APPLIEDFILTERS":
      const newClearAllAppliedFiltersState =
        state.selectedTabId === "all" ? { ...state, filtersInMainTab: {}, appliedFilters: {} } : { ...state, appliedFilters: {} };
      return newClearAllAppliedFiltersState;
    case "REPAIR_SET_PAGESIZE":
      return { ...state, pageSize: action.pageSize };
    case "REPAIR_SET_TABS":
      return { ...state, tabs: action.tabs };
    case "REPAIR_SET_SELECTED_TAB_ID":
      return { ...state, selectedTabId: action.selectedTabId };
    case "REPAIR_SET_FILTERS_FORM_ERROR":
      return { ...state, filtersFormErrors: action.filtersFormErrors };
    case "REPAIR_SET_REPAIR_DETAILS":
      return { ...state, repairDetails: action.repairDetails };
    default:
      return state;
  }
};

export default repairReducer;
