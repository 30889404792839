import React from "react";
import "./styles.css";

const TextButton = ({ icon, children, className = "", ...props }) => {
  return (
    <button className={`text-button ${className}`} {...props}>
      {icon}
      <span>{children}</span>
    </button>
  );
};

export default TextButton;
