import { readLangCookie } from "routes/Error/utils";
import { DEFAULT_LANGUAGE, WARRANTY_DETAILS_EXCLUDED_FOR_USER } from "utils/config";
import { isEmployee } from "utils/global-functions";

export const formatWarrantyResult = (warrantyResult) => Object.entries(warrantyResult).filter(([key]) => !WARRANTY_DETAILS_EXCLUDED_FOR_USER.includes(key));

export const getListType = (user) => {
  if (isEmployee(user)) return "full";

  return user.companyId ? "our" : "my";
};

export const getLanguage = (user) => {
  if (user?.lang) return user.lang;

  return readLangCookie() || DEFAULT_LANGUAGE;
};

export const getGroupedAttachmentsByType = (attachments) => {
  const groupedAttachments = {};
  const attachmentTypes = Array.from(new Set(attachments.map((attachment) => attachment.attachment_group)));
  attachmentTypes.forEach((attachmentType) => {
    groupedAttachments[attachmentType] = attachments.filter((attachment) => attachment.attachment_group === attachmentType);
  });

  return groupedAttachments;
};

export const formatFileSize = (size) => {
  let bytes = size / 1024;
  let index = 0;
  const maps = { 0: " ", 1: " k", 2: " M", 3: " G" };

  do {
    bytes = bytes / 1024;
    index++;
  } while (bytes > 1024);
  return ` (${bytes.toFixed(2)} ${maps[index]}B)`;
};

export const getSkus = (bundleProducts) => {
  if (!bundleProducts?.length) return "";
  return bundleProducts.map((bundleProduct) => bundleProduct.partNumber).join(",");
};
