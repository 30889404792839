import React from "react";
import ImageModal from "stories/components/ImageModal/ImageModal";
import { isVideo } from "./utils";

const PhotosPreviewModal = ({ active, setActive, selectedPhotoId, photos, setSelectedPhotoId }) => {
  if (!active) return;

  const selectedPhoto = photos.find(({ id }) => id === selectedPhotoId);
  const selectedPhotoIndex = photos.indexOf(selectedPhoto);

  return (
    <ImageModal
      filePath={`${process.env.REACT_APP_RESOURCE_URL}${selectedPhoto.id}`}
      video={isVideo(selectedPhoto)}
      content={<p className="text--preline">{selectedPhoto.commentText}</p>}
      onClose={() => {
        setSelectedPhotoId(null);
        setActive(false);
      }}
      photoButtons={{
        nextPhotoButton: {
          onAction: () => setSelectedPhotoId(selectedPhotoIndex > 0 ? photos.at(selectedPhotoIndex - 1).id : photos.at(-1).id),
          disabled: photos.length < 2,
        },
        previousPhotoButton: {
          onAction: () => setSelectedPhotoId(selectedPhotoIndex < photos.length - 1 ? photos.at(selectedPhotoIndex + 1).id : photos.at(0).id),
          disabled: photos.length < 2,
        },
      }}
    />
  );
};

export default PhotosPreviewModal;
