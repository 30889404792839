import React, { useState } from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import EditReturnAddressModal from "../EditReturnAddressModal";
import Content from "./Content";
import "./styles.css";

const Desktop = ({ links, repairDetailsLoading }) => {
  const [editReturnAddressModalActive, setEditReturnAddressModalActive] = useState(false);

  return (
    <>
      {repairDetailsLoading ? (
        <PageSpinner subPage />
      ) : (
        <Content editReturnAddressModalActive={editReturnAddressModalActive} setEditReturnAddressModalActive={setEditReturnAddressModalActive} />
      )}
      <EditReturnAddressModal active={editReturnAddressModalActive} setActive={setEditReturnAddressModalActive} />
    </>
  );
};

export default Desktop;
