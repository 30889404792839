import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import SendMessageField from "../SendMessageField";
import Messages from "../Messages";
import TopBar from "./TopBar";
import "./styles.css";

const Mobile = ({
  conversationList,
  setConversationList,
  converstionGroupType,
  setConversationGroupType,
  setSelectedConversationId,
  setConfirmDeleteMessageModalActive,
  editMessageActive,
  setEditMessageActive,
  selectedConversationId,
  responseMessageActive,
  setResponseMessageActive,
  conversationListLoading,
  refreshConversation,
  setRefreshConversation,
  setConfirmCloseMessageModalActive,
  refreshRepairDetails,
  setRefreshRepairDetails,
}) => {
  return conversationListLoading ? (
    <PageSpinner subPage />
  ) : (
    <>
      <TopBar
        converstionGroupType={converstionGroupType}
        setConversationGroupType={setConversationGroupType}
        setSelectedConversationId={setSelectedConversationId}
        setResponseMessageActive={setResponseMessageActive}
        setEditMessageActive={setEditMessageActive}
      />
      <Messages
        conversationList={conversationList}
        setConversationList={setConversationList}
        setSelectedConversationId={setSelectedConversationId}
        setConfirmDeleteMessageModalActive={setConfirmDeleteMessageModalActive}
        setEditMessageActive={setEditMessageActive}
        setConfirmCloseMessageModalActive={setConfirmCloseMessageModalActive}
        setResponseMessageActive={setResponseMessageActive}
      />
      <div className="mobile-conversation">
        <SendMessageField
          setSelectedConversationId={setSelectedConversationId}
          conversationList={conversationList}
          editMessageActive={editMessageActive}
          setEditMessageActive={setEditMessageActive}
          selectedConversationId={selectedConversationId}
          responseMessageActive={responseMessageActive}
          setResponseMessageActive={setResponseMessageActive}
          converstionGroupType={converstionGroupType}
          refreshConversation={refreshConversation}
          setRefreshConversation={setRefreshConversation}
          refreshRepairDetails={refreshRepairDetails}
          setRefreshRepairDetails={setRefreshRepairDetails}
        />
      </div>
    </>
  );
};

export default Mobile;
