import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import EmptyPage from "../EmptyPage";
import AttachmentsDetails from "./AttachmentsDetails";
import "./styles.css";

const Desktop = ({ attachmentsLoading, attachmentList, setAttachmentsModalActive, setAttachmentDeleteModalActive, setSelectedAttachmentId }) => {
  return attachmentsLoading ? (
    <PageSpinner subPage />
  ) : (
    <div className="attachments-box">
      <AttachmentsDetails
        setAttachmentsModalActive={setAttachmentsModalActive}
        attachmentList={attachmentList}
        setAttachmentDeleteModalActive={setAttachmentDeleteModalActive}
        setSelectedAttachmentId={setSelectedAttachmentId}
      />
      {!attachmentList.length && <EmptyPage />}
    </div>
  );
};

export default Desktop;
