import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Button from "stories/components/Button/Button";
import Modal from "stories/components/Modal/Modal";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { formatBodyForRepairContactRequest, isNewContact } from "./utils";
import EditClientContactForm from "./EditClientContactForm";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";

const EditClientContactModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [formErrors, setFormErrors] = useState({});
  const [repairContactLoading, setRepairContactLoading] = useState(false);
  const navigate = useNavigate();

  const setRepairContactSuccess = (response, repairContactBody) => {
    if (response.result === 301) {
      dispatch({ type: "REPAIR_SET_REPAIR_DETAILS", repairDetails: { ...repair.repairDetails, ...repairContactBody } });
      return setActive(false);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairContactError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    if (filteredErrors.some((filteredError) => filteredError.code === 302)) navigate("/");
    filteredErrors
      .filter((message) => !message.property)
      .forEach((message) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${message.code}`),
          },
        }),
      );
  };

  const setRepairContact = (values) => {
    setRepairContactLoading(true);
    const repairContactBody = formatBodyForRepairContactRequest(values);

    return agent.Repairs.setRepairContact({
      id: user.id,
      token: user.token,
      repairId: repair.repairDetails.repairId,
      ...repairContactBody,
    })
      .then((response) => setRepairContactSuccess(response, repairContactBody), setRepairContactError)
      .finally(() => setRepairContactLoading(false));
  };

  const validationSchema = Yup.object().shape({
    contactPerson: Yup.string().max(50, t("errors.102")).required(t("errors.99")),
    contactPhone: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    contactEmail: Yup.string().max(255, t("errors.102")).email(t("errors.104")).required(t("errors.99")),
  });

  if (!active) return;

  return (
    <Formik
      initialValues={{
        newContactData: isNewContact(repair.repairDetails),
        contactPerson: repair.repairDetails.contactPerson || "",
        contactPhone: repair.repairDetails.contactPhone || "",
        contactEmail: repair.repairDetails.contactEmail || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => setRepairContact(values)}
    >
      {({ values, handleSubmit, setFieldValue, handleBlur, errors, touched, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            title={t("myRepairs.editContactDetails")}
            onClose={(value) => setActive(value)}
            footerActions={
              <div className="edit-client-data-form__action-buttons">
                <Button size={mobile ? "small" : "default"} disabled={repairContactLoading} onClick={() => setActive(false)}>
                  {t("global.cancel")}
                </Button>
                <Button
                  size={mobile ? "small" : "default"}
                  type="submit"
                  variant="primary"
                  disabled={!isValid && values.newContactData}
                  loading={repairContactLoading}
                >
                  {t("global.save")}
                </Button>
              </div>
            }
          >
            <EditClientContactForm
              values={values}
              setFieldValue={setFieldValue}
              formErrors={formErrors}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default EditClientContactModal;
