import React from "react";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Search from "images/Search.svg";
import Button from "stories/components/Button/Button";

const EmptyState = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    repair: { appliedFilters },
  } = useSelector((store) => store);

  return (
    <div className="mobile-rapair-admin__empty-state">
      <img src={Search} alt="search_icon" className="custom-table-empty-state__icon-wrapper" />
      <div className="mobile-rapair-admin__empty-state-content">
        <h6 className="heading--6 no-margin text--center">{t("global.noResultsFound")}</h6>
        <p className="paragraph--small no-margin text--center">{t("global.noResultsFoundInfo")}</p>
        <div className="mobile-rapair-admin__empty-state-buttons">
          {!lodash.isEmpty(appliedFilters) && (
            <Button onClick={() => dispatch({ type: "REPAIR_CLEAR_ALL_APPLIEDFILTERS" })} className="mobile-rapair-admin__empty-state-button" size="small">
              {t("global.clearFilters")}
            </Button>
          )}
          <Button onClick={() => navigate("/addreport")} className="mobile-rapair-admin__empty-state-button" size="small" variant="primary">
            {t("addReport.addNewReport")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
