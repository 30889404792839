import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { isEmployee, isTechnician, setLangLinks } from "utils/global-functions";
import { MIN_DESKTOP_WIDTH } from "utils/config";
import Tooltip from "stories/components/Tooltip/Tooltip";
import plus from "images/Plus.svg";
import arrow from "images/Arrow.svg";
import { getRoutesForNavPanel } from "./utils";
import { pathWithoutNavigationBar } from "./config";
import LeftPanelItem from "./LeftPanelItem";
import { AccountMobile } from "./AccountNav";
import AssignRepairButton from "./AssignRepairButton";
import "./styles/LeftPanel.css";

export const LeftPanel = ({ logoutCallback }) => {
  const {
    user,
    app: { leftPanelHidden },
    registration: { registrationStep },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const halfDesktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH, maxWidth: 1300 });

  const [animationVisible, setAnimationVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    dispatch({ type: "APP_SETLEFTPANELHIDDEN", leftPanelHidden: halfDesktop });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [halfDesktop]);

  if (lodash.isEmpty(user)) return;

  if (pathWithoutNavigationBar.some((path) => window.location.pathname.startsWith(path))) return;

  const routesName = new Map([
    ["/", isEmployee(user) ? t("panel.repairList") : t("panel.myRepair")],
    ["/other-repairs", t("panel.otherRepair")],
    ["/actions", t("panel.actions")],
    ["/help", t("panel.help")],
    ["/producerreport", t("panel.producerReport")],
    ["/changelog", t("panel.changelog")],
    ["/management", t("panel.management")],
    ["/repairs", t("panel.repairs")],
    ["/history", t("panel.history")],
    ["/externalserial", t("panel.externalSerial")],
  ]);

  const preload = setTimeout(function () {
    document.querySelector(".panel__item--small")?.classList.add("animation-big-small");
    document.querySelector(".panel__item")?.classList.add("");
  }, 1000);

  clearTimeout(preload);

  return (
    <>
      <div className={`panel ${leftPanelHidden ? "panel-small" : "panel-full"}`}>
        <div className="panel__side-wrapper">
          {isTechnician(user) ? (
            <AssignRepairButton />
          ) : leftPanelHidden ? (
            <Tooltip allowHtml content={t("panel.add")} direction="right" zIndex={999999}>
              <NavLink to="/addreport" className={`panel__call-to-action panel__call-to-action--small ${animationVisible ? "animation-red-big-small" : ""}`}>
                <img
                  className={`panel__call-to-action--icon no-select panel__call-to-action--icon-small ${
                    animationVisible ? "animation-red-icon-big-small" : ""
                  }`}
                  src={plus}
                  alt="+"
                />
                <span className="span-hidden">{t("panel.add")}</span>
                {registrationStep && location.pathname !== "/addreport" ? (
                  <Tooltip allowHtml content={t("panel.openReport")} direction="right" zIndex={999999}>
                    <div className="repair-open-dot">?</div>
                  </Tooltip>
                ) : null}
              </NavLink>
            </Tooltip>
          ) : (
            <NavLink to="/addreport" className={`panel__call-to-action ${animationVisible ? "animation-red-small-big" : ""}`}>
              <img className={`panel__call-to-action--icon no-select ${animationVisible ? "animation-red-icon-small-big" : ""}`} src={plus} alt="+" />
              <span className="span">{t("panel.add")}</span>
              {registrationStep && location.pathname !== "/addreport" ? (
                <Tooltip allowHtml content={t("panel.openReport")} direction="right" zIndex={999999}>
                  <div className="repair-open-dot">?</div>
                </Tooltip>
              ) : null}
            </NavLink>
          )}
          {getRoutesForNavPanel(user).map((route) => (
            <LeftPanelItem key={route} routeName={routesName.get(route)} isHidden={leftPanelHidden} url={route} animationVisible={animationVisible} />
          ))}
          {leftPanelHidden ? (
            <Tooltip allowHtml content={t("panel.open")} direction="right" zIndex={999999}>
              <button
                className={`panel__item--small ${animationVisible ? "animation-big-small" : ""}`}
                onClick={() => {
                  dispatch({ type: "APP_SETLEFTPANELHIDDEN", leftPanelHidden: !leftPanelHidden });
                  setAnimationVisible(true);
                }}
              >
                <img src={arrow} className="panel__item--icon no-select arrow-rotated" alt="close" />
                <span className="span-hidden">{t("panel.close")}</span>
              </button>
            </Tooltip>
          ) : (
            <button
              className={`panel__item ${animationVisible ? "animation-small-big" : ""}`}
              onClick={() => {
                dispatch({ type: "APP_SETLEFTPANELHIDDEN", leftPanelHidden: !leftPanelHidden });
                setAnimationVisible(true);
              }}
            >
              <img src={arrow} className="panel__item--icon no-select" alt="close" />
              <span className="span">{t("panel.close")}</span>
            </button>
          )}
        </div>
        <div className={leftPanelHidden ? "other-hidden" : "other"}>
          <a target="_blank" className="panel__item panel__item-bottom" rel="noopener noreferrer" href={setLangLinks(user.lang, "elmark")}>
            Elmark
          </a>
          <a target="_blank" className="panel__item panel__item-bottom" rel="noopener noreferrer" href={setLangLinks(user.lang, "elmatic")}>
            Elmatic
          </a>
          <a target="_blank" className="panel__item panel__item-bottom" rel="noopener noreferrer" href={setLangLinks(user.lang, "rugged")}>
            Rugged
          </a>
          <button className="panel__item panel__item--logout" onClick={() => logoutCallback()}>
            {t("global.logout")}
          </button>
        </div>
      </div>
      <div className={leftPanelHidden ? "panel__buffor--small" : "panel__buffor"} />
    </>
  );
};

export const PanelMobile = ({ navMobileActive, closeNavMobile, logoutCallback }) => {
  const {
    user,
    registration: { registrationStep },
  } = useSelector((store) => store);
  const { t } = useTranslation();
  const location = useLocation();

  if (pathWithoutNavigationBar.some((path) => window.location.pathname.startsWith(path))) return;

  const routesName = new Map([
    ["/", isEmployee(user) ? t("panel.repairList") : t("panel.myRepair")],
    ["/other-repairs", t("panel.otherRepair")],
    ["/actions", t("panel.actions")],
    ["/help", t("panel.help")],
    ["/producerreport", t("panel.producerReport")],
    ["/changelog", t("panel.changelog")],
    ["/management", t("panel.management")],
    ["/repairs", t("panel.repairs")],
    ["/history", t("panel.history")],
    ["/externalserial", t("panel.externalSerial")],
  ]);

  return (
    <div className="mobile-navigation__links">
      <div className="mobile-navigation__side">
        {isTechnician(user) ? (
          <AssignRepairButton />
        ) : (
          <div className="panel__call-to-action-wrapper">
            <NavLink to="/addreport" className="panel__call-to-action" onClick={closeNavMobile}>
              <img src={plus} className="no-select plus" alt="+" />
              <span className="span span-red">{t("panel.add")}</span>
            </NavLink>
            {registrationStep && location.pathname !== "/addreport" ? (
              <Tooltip allowHtml content={t("panel.openReport")} direction="right" zIndex={999999}>
                <div className="mobile-repair-open-dot">?</div>
              </Tooltip>
            ) : null}
          </div>
        )}
        {getRoutesForNavPanel(user).map((route) => (
          <LeftPanelItem key={route} closeNavMobile={closeNavMobile} routeName={routesName.get(route)} isHidden={false} url={route} />
        ))}
        <AccountMobile navMobileActive={navMobileActive} closeNavMobile={() => closeNavMobile(!navMobileActive)} />
      </div>
      <div className="mobile-navigation__side">
        <a className="panel__item" target="_blank" rel="noopener noreferrer" href="https://www.elmark.com.pl/">
          Elmark
        </a>
        <a className="panel__item" target="_blank" rel="noopener noreferrer" href="https://elmatic.net/">
          Elmatic
        </a>
        <a className="panel__item" target="_blank" rel="noopener noreferrer" href="https://rugged.com.pl/">
          Rugged
        </a>
        <button
          className="panel__item panel__item--logout"
          onClick={() => {
            closeNavMobile(false);
            logoutCallback();
          }}
        >
          {t("global.logout")}
        </button>
      </div>
    </div>
  );
};
