/*
Author: jacek.bonecki@elmark.com.pl
Wpisy pobierane z pliku ChangeLog.json - tam tablica obiektów {"date":"...","version":"...","items":...}
Items w postaci stringa lub obiektu {"text":"...","ul/ol":[...]} oczywiście z możliwością zagnieżdżania
*/

import React from "react";
import Modal from "stories/components/Modal/Modal";
import changelog from "./ChangeLog.json";
import "./styles.css";

const ChangeLog = ({ onCloseCallback }) => {
  const ChangeLogItem = (item, key) => {
    if (typeof item === "string") return <span dangerouslySetInnerHTML={{ __html: item }} />;
    return (
      <>
        {typeof item.text !== "undefined" && <span dangerouslySetInnerHTML={{ __html: item.text }} />}
        {typeof item.ul !== "undefined" && (
          <ul>
            {item.ul.map((item, index) => (
              <li key={`${key}.${index}`}>{ChangeLogItem(item, `${key}.${index}`)}</li>
            ))}
          </ul>
        )}
        {typeof item.ol !== "undefined" && (
          <ol>
            {item.ol.map((item, index) => (
              <li key={`${key}.${index}`}>{ChangeLogItem(item, `${key}.${index}`)}</li>
            ))}
          </ol>
        )}
      </>
    );
  };

  return (
    <>
      <Modal title="ChangeLog" subTitle="Historia zmian funkcjonalnych w aplikacji serwis.elmark.com.pl" size="elastic" onClose={onCloseCallback}>
        <div className="changelog-body">
          <table className="changelog-table">
            <tbody>
              {changelog.map((build, buildIndex) => (
                <tr key={`CLR${buildIndex}`}>
                  <td>{build.date}</td>
                  <td>{build.version}</td>
                  <td>{ChangeLogItem(build.items, `B${buildIndex}`)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ChangeLog;
