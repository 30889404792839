import React from "react";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import "./styles.css";

const FoundProduct = ({ foundProduct }) => {
  const { t } = useTranslation();

  if (lodash.isEmpty(foundProduct)) return;

  return (
    <div className="found-product">
      <h6 className="heading--6 found-product__heading">{t("addReport.foundProduct")}</h6>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("global.symbol")}</span>
        <span className="paragraph--small">{foundProduct.partNumber}</span>
      </div>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("addReport.productDescription")}</span>
        <span className="paragraph--small">{foundProduct.description}</span>
      </div>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("global.producer")}</span>
        <span className="paragraph--small">{foundProduct.producer}</span>
      </div>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("addReport.sellDate")}</span>
        <span className="paragraph--small">{foundProduct.sellDate}</span>
      </div>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("addReport.warrantyEnd")}</span>
        <span className="paragraph--small">{foundProduct.warrantyEnd}</span>
      </div>
      <div className="found-product__item">
        <span className="text--subdued paragraph--small">{t("addReport.document")}</span>
        <span className="paragraph--small">{foundProduct.document}</span>
      </div>
    </div>
  );
};

export default FoundProduct;
