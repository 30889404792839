import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Chip = ({ children, onClick }) => (
  <div className="chip">
    <span className="chip__content">{children}</span>
    <button className="chip__close" onClick={onClick}>
      &#215;
    </button>
  </div>
);

Chip.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Chip;
