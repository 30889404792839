import React from "react";
import ColumnName from "./ColumnName";
import ColumnNameAction from "./ColumnNameAction";

const TableHead = ({ headings, selectable }) => (
  <thead>
    <tr>
      {selectable && <th></th>}
      {headings.map((heading, index) => (
        <th key={index}>
          {heading.onClick ? <ColumnNameAction content={heading.content} onClick={heading.onClick} /> : <ColumnName content={heading.content} />}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHead;
