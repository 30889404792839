import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import AdminRating from "../AdminRating";
import CustomerRating from "../CustomerRating";
import "./styles.css";

const Desktop = ({
  ratingResult,
  customerRatingResult,
  ratingInvitationResult,
  conversationListLoading,
  conversationListRefresh,
  setConversationListRefresh,
  refreshRepairDetails,
  setRefreshRepairDetails,
  setRejectRatingModalActive,
}) =>
  conversationListLoading ? (
    <PageSpinner subPage />
  ) : (
    <div className="rating-container">
      <CustomerRating
        conversationListRefresh={conversationListRefresh}
        setConversationListRefresh={setConversationListRefresh}
        customerRatingResult={customerRatingResult}
        ratingInvitationResult={ratingInvitationResult}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
        setRejectRatingModalActive={setRejectRatingModalActive}
      />
      <AdminRating
        conversationListRefresh={conversationListRefresh}
        setConversationListRefresh={setConversationListRefresh}
        ratingResult={ratingResult}
        ratingInvitationResult={ratingInvitationResult}
        refreshRepairDetails={refreshRepairDetails}
        setRefreshRepairDetails={setRefreshRepairDetails}
        setRejectRatingModalActive={setRejectRatingModalActive}
      />
    </div>
  );

export default Desktop;
