import React from "react";
import CardSidebarLink from "./CardSidebarLink";
import "./styles.css";

const CardSidebar = ({ links }) => (
  <div className="card-sidebar">
    {links.map((link) => (
      <CardSidebarLink key={link.url} url={link.url} counter={link.counter}>
        {link.name}
      </CardSidebarLink>
    ))}
  </div>
);

export default CardSidebar;
