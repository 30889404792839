import React from "react";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import RepairsTable from "./RepairsTable";
import TopActions from "./TopActions";
import "./styles.css";

const DisplayClientDesktop = ({ repairListLoading, activeFiltersDrawer, setActiveFiltersDrawer, pageNumber, setPageNumber }) => {
  const {
    repair: { repairsList, pageSize },
  } = useSelector((store) => store);

  return (
    <div className="page-content">
      <div className="repairs-table">
        <div className="repairs-table__group repairs-table__group--flex">
          <TopActions
            repairListLoading={repairListLoading}
            setActiveFiltersDrawer={setActiveFiltersDrawer}
            activeFiltersDrawer={activeFiltersDrawer}
            setPageNumber={setPageNumber}
          />
          <RepairsTable repairListLoading={repairListLoading} />
        </div>
        <div className="repairs-table__group">
          <Pagination
            loading={repairListLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            listLength={repairsList.length}
            previousAction={() => {
              setPageNumber(pageNumber - 1);
              window.scrollTo(0, 0);
            }}
            continueAction={() => {
              setPageNumber(pageNumber + 1);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayClientDesktop;
