import React from "react";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RepairsLogResult from "./RepairsLogResult";

const RepairHistory = ({ repairsLogResult }) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);

  if (lodash.isEmpty(repairsLogResult) && !lodash.isEmpty(user)) return;

  return (
    <div className="serial-number__repair-history">
      <h5 className="heading--5 serial-number__info-heading">{t("panel.history")}</h5>
      {lodash.isEmpty(user) ? (
        <p>
          {t("serialNumber.forYourSafety")} <span className="text--italic">{t("panel.history")}</span>{" "}
          {t("serialNumber.isAvailableAfterLoggingIntoTheUserAccount")}.{" "}
          <Link className="custom-link" to="/">
            {t("serialNumber.logInToGetAccess")}
          </Link>
        </p>
      ) : (
        <RepairsLogResult repairsLogResult={repairsLogResult} />
      )}
    </div>
  );
};

export default RepairHistory;
