import React from "react";
import ImageModal from "stories/components/ImageModal/ImageModal";

const ThumbnailPreviewModal = ({ active, onClose, thumbnailUrl }) => {
  if (!active) return;

  return (
    <ImageModal
      filePath={thumbnailUrl}
      onClose={() => {
        onClose();
      }}
    />
  );
};

export default ThumbnailPreviewModal;
