import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageSizeSelector from "components/PageSizeSelector/PageSizeSelector";
import Drawer from "stories/components/Drawer/Drawer";
import Button from "stories/components/Button/Button";
import Chip from "stories/components/Chip/Chip";
import { Filters } from "./Filters";
import "./styles.css";

const DisplayDesktop = ({
  producerReportList,
  filtersActive,
  setFiltersActive,
  pageSize,
  setPageSize,
  setPageNumber,
  endDateFrom,
  setEndDateFrom,
  endDateUpTo,
  setEndDateUpTo,
  repairId,
  setRepairId,
  serialNumber,
  setSerialNumber,
  partNumber,
  setPartNumber,
  technicianId,
  setTechnicianId,
  producerId,
  setProducerId,
  isIncomplete,
  setIsIncopmlete,
  endDateFromValue,
  setEndDateFromValue,
  endDateUpToValue,
  setEndDateUpToValue,
  repairIdValue,
  setRepairIdValue,
  serialNumberValue,
  setSerialNumberValue,
  partNumberValue,
  setPartNumberValue,
  technicianIdValue,
  setTechnicianIdValue,
  producerIdValue,
  setProducerIdValue,
  isIncompleteValue,
  setIsIncopmleteValue,
  reportListExportCallback,
  reportListLength,
}) => {
  const { t } = useTranslation();
  const {
    app: { constants },
  } = useSelector((store) => store);

  return (
    <div className="producer-report-heading">
      <div className="producer-report-heading-chips">
        {endDateFrom && (
          <Chip
            onClick={() => {
              setEndDateFrom();
              setEndDateFromValue();
            }}
          >{`Finish date: from ${endDateFrom?.format("YYYY-MM-DD")}`}</Chip>
        )}
        {endDateUpTo && (
          <Chip
            onClick={() => {
              setEndDateUpTo();
              setEndDateUpToValue();
            }}
          >{`Finish date: up to ${endDateUpTo?.format("YYYY-MM-DD")}`}</Chip>
        )}
        {serialNumber && (
          <Chip
            onClick={() => {
              setSerialNumber("");
              setSerialNumberValue("");
            }}
          >{`Serial number: ${serialNumber}`}</Chip>
        )}
        {partNumber && (
          <Chip
            onClick={() => {
              setPartNumber("");
              setPartNumberValue("");
            }}
          >{`Product name: ${partNumber}`}</Chip>
        )}
        {repairId && (
          <Chip
            onClick={() => {
              setRepairId("");
              setRepairIdValue("");
            }}
          >{`Repair number: ${repairId}`}</Chip>
        )}
        {producerId && (
          <Chip
            onClick={() => {
              setProducerId("");
              setProducerIdValue("");
            }}
          >{`Producer: ${constants.producers[producerId]?.strVal}`}</Chip>
        )}
        {technicianId && (
          <Chip
            onClick={() => {
              setTechnicianId("");
              setTechnicianIdValue("");
            }}
          >{`Technician: ${constants.employees[technicianId]?.strVal}`}</Chip>
        )}
        {isIncomplete === 0 ? (
          <Chip
            onClick={() => {
              setIsIncopmlete("");
              setIsIncopmleteValue("");
            }}
          >{`Report info: ${t("producerReport.reportStateOk")}`}</Chip>
        ) : null}
        {isIncomplete === 1 ? (
          <Chip
            onClick={() => {
              setIsIncopmlete("");
              setIsIncopmleteValue("");
            }}
          >{`Report info: ${t("producerReport.reportStateNoReport")}`}</Chip>
        ) : null}
      </div>
      <div className="producer-report-heading-right">
        {reportListLength !== 0 && (
          <PageSizeSelector
            selectedPageSize={pageSize}
            pageSizeOptions={[
              { value: 25, label: 25 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
              { value: 999, label: 999 },
            ]}
            onChange={(value) => {
              setPageSize(value);
              setPageNumber(1);
            }}
          />
        )}
        <div className="producer-report-heading-buttons">
          <Button onClick={() => producerReportList()}>{t("global.refresh")}</Button>

          <Button onClick={reportListExportCallback} disabled={reportListLength ? false : true}>
            {t("global.export")}
          </Button>

          <Drawer
            title={t("global.filters")}
            active={filtersActive}
            setActive={setFiltersActive}
            activator={
              <Button variant="primary" onClick={() => setFiltersActive(true)}>
                {t("global.filter")}
              </Button>
            }
          >
            <Filters
              setFiltersActive={setFiltersActive}
              setEndDateFrom={setEndDateFrom}
              setEndDateUpTo={setEndDateUpTo}
              setRepairId={setRepairId}
              setSerialNumber={setSerialNumber}
              setPartNumber={setPartNumber}
              setTechnicianId={setTechnicianId}
              setProducerId={setProducerId}
              setIsIncopmlete={setIsIncopmlete}
              endDateFromValue={endDateFromValue}
              setEndDateFromValue={setEndDateFromValue}
              endDateUpToValue={endDateUpToValue}
              setEndDateUpToValue={setEndDateUpToValue}
              repairIdValue={repairIdValue}
              setRepairIdValue={setRepairIdValue}
              serialNumberValue={serialNumberValue}
              setSerialNumberValue={setSerialNumberValue}
              partNumberValue={partNumberValue}
              setPartNumberValue={setPartNumberValue}
              technicianIdValue={technicianIdValue}
              setTechnicianIdValue={setTechnicianIdValue}
              producerIdValue={producerIdValue}
              setProducerIdValue={setProducerIdValue}
              isIncompleteValue={isIncompleteValue}
              setIsIncopmleteValue={setIsIncopmleteValue}
            />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default DisplayDesktop;
