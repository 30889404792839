import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Button from "stories/components/Button/Button";
import Checkbox from "stories/components/Checkbox/Checkbox";
import Modal from "stories/components/Modal/Modal";
import Stack from "stories/components/Stack/Stack";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { deleteCookie, readCookie, writeCookie } from "utils/global-functions";

const StreetWarningModal = ({ active, setActive }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  if (!active || readCookie("dontShowWarningStreet")) return;

  return (
    <Modal
      title={t("global.attention")}
      onClose={() => setActive(false)}
      footerActions={
        <Button size={mobile ? "small" : "default"} onClick={() => setActive(false)}>
          {t("global.close")}
        </Button>
      }
    >
      <Stack flexDirection="column" gap="1rem">
        <p className="paragraph--small">{t("addReport.streetWarningInfo")}</p>
        <Checkbox
          label={t("global.dontShowAgain")}
          onChange={() => {
            if (!readCookie("dontShowWarningStreet")) writeCookie({ key: "dontShowWarningStreet", value: true, days: 365 });
            else deleteCookie("dontShowWarningStreet");
          }}
        />
      </Stack>
    </Modal>
  );
};

export default StreetWarningModal;
