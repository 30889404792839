/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import Page from "stories/components/Page/Page";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import { isEmployee, removeEmptyValuesFromObject } from "utils/global-functions";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";
import DisplayClientDesktop from "./desktop/DisplayClientDesktop";
import DisplayAdminDesktop from "./desktop/DisplayAdminDesktop";
import DisplayAdminMobile from "./mobile/DisplayAdminMobile";
import {
  formatAppliedFiltersForApi,
  formatTabsFilterForSerializableValues,
  getListType,
  isAppliedFiltersEqualCurrentTabFilters,
  isMyRepairPage,
  setStarToCurrentTabName,
} from "./utils";
import ManageTabsModal from "./ManageTabsModal";
import DeleteTabModal from "./DeleteTabModal";
import DisplayClientMobile from "./mobile/DisplayClientMobile";
import { mainTab } from "./config";
import "./styles.css";

const Home = () => {
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user,
    repair: { appliedFilters, pageSize, tabs, filtersInMainTab, selectedTabId },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [activeManageTabsModal, setActiveManageTabsModal] = useState(false);
  const [activeDeleteTabModal, setDeleteTabModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [repairListLoading, setRepairListLoading] = useState(true);
  const [activeFiltersDrawer, setActiveFiltersDrawer] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [cardTabs, setCardTabs] = useState([
    mainTab,
    ...tabs.map((tab) => {
      return { id: tab.filterId, title: tab.filterName, content: tab.filterName };
    }),
  ]);

  const getTabsSuccess = (response) => {
    dispatch({ type: "REPAIR_SET_TABS", tabs: formatTabsFilterForSerializableValues(response.data) });
    setCardTabs([
      mainTab,
      ...response.data.map((tab) => {
        return { id: tab.filterId, title: tab.filterName, content: tab.filterName };
      }),
    ]);
  };

  const handleTabSelection = (tabId) => {
    dispatch({ type: "REPAIR_SET_SELECTED_TAB_ID", selectedTabId: tabId });

    if (tabId === "all") return dispatch({ type: "REPAIR_SET_APPLIEDFILTERS", appliedFilters: filtersInMainTab });

    const { filterId, filterName, filterOwnerId, ...rest } = tabs.find((tab) => tab.filterId === tabId);
    setCardTabs([
      mainTab,
      ...tabs.map((tab) => {
        return { id: tab.filterId, title: tab.filterName, content: tab.filterName };
      }),
    ]);
    return dispatch({ type: "REPAIR_SET_APPLIEDFILTERS", appliedFilters: removeEmptyValuesFromObject(rest) });
  };

  const getTabsError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
  };

  const getTabs = () => {
    agent.Repairs.getTabs({
      id: user.id,
      token: user.token,
    }).then(getTabsSuccess, getTabsError);
  };

  useEffect(() => {
    if (isEmployee(user)) getTabs();
  }, []);

  const showRepairsSuccess = ({ data }) => {
    dispatch({ type: "REPAIR_SET_REPAIRSLIST", repairsList: data });
    dispatch({
      type: "REPAIR_SET_FILTERS_FORM_ERROR",
      filtersFormErrors: {},
    });
  };

  const showRepairsErrors = (error) => {
    dispatch({ type: "REPAIR_SET_REPAIRSLIST", repairsList: [] });
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    dispatch({
      type: "REPAIR_SET_FILTERS_FORM_ERROR",
      filtersFormErrors: Object.fromEntries(
        filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)]),
      ),
    });
  };

  const showRepairs = () => {
    agent.Repairs.showRepairs({
      id: user.id,
      token: user.token,
      listType: getListType(user),
      pageSize: pageSize,
      pageNumber: pageNumber,
      ...formatAppliedFiltersForApi(appliedFilters),
    })
      .then(showRepairsSuccess, showRepairsErrors)
      .finally(() => setRepairListLoading(false));
  };

  useEffect(() => {
    setActiveFiltersDrawer(false);
    setRepairListLoading(true);
    showRepairs();
  }, [appliedFilters, pageNumber, pageSize, refreshList, window.location.pathname]);

  const changeTabNameIfDifferentFilters = () => {
    const currentTab = tabs.find((tab) => tab.filterId === selectedTabId);

    if (selectedTabId === "all" && !currentTab)
      return setCardTabs([
        mainTab,
        ...tabs.map((tab) => {
          return { id: tab.filterId, title: tab.filterName, content: tab.filterName };
        }),
      ]);

    const { filterId, filterName, filterOwnerId, ...currentTabFilters } = currentTab;
    if (!isAppliedFiltersEqualCurrentTabFilters(appliedFilters, currentTabFilters)) return setCardTabs((tabs) => setStarToCurrentTabName(tabs, selectedTabId));

    return setCardTabs([
      mainTab,
      ...tabs.map((tab) => {
        return { id: tab.filterId, title: tab.filterName, content: tab.filterName };
      }),
    ]);
  };

  useEffect(() => {
    changeTabNameIfDifferentFilters();
  }, [tabs, appliedFilters]);

  const displaysMap = {
    true: {
      true: (
        <DisplayAdminMobile
          setActiveManageTabsModal={setActiveManageTabsModal}
          setDeleteTabModal={setDeleteTabModal}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          repairListLoading={repairListLoading}
          activeFiltersDrawer={activeFiltersDrawer}
          setActiveFiltersDrawer={setActiveFiltersDrawer}
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          cardTabs={cardTabs}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          handleTabSelection={handleTabSelection}
        />
      ),
      false: (
        <DisplayAdminDesktop
          setActiveManageTabsModal={setActiveManageTabsModal}
          setDeleteTabModal={setDeleteTabModal}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          repairListLoading={repairListLoading}
          activeFiltersDrawer={activeFiltersDrawer}
          setActiveFiltersDrawer={setActiveFiltersDrawer}
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          cardTabs={cardTabs}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          handleTabSelection={handleTabSelection}
        />
      ),
    },
    false: {
      true: (
        <DisplayClientMobile
          repairListLoading={repairListLoading}
          activeFiltersDrawer={activeFiltersDrawer}
          setActiveFiltersDrawer={setActiveFiltersDrawer}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      ),
      false: (
        <DisplayClientDesktop
          repairListLoading={repairListLoading}
          activeFiltersDrawer={activeFiltersDrawer}
          setActiveFiltersDrawer={setActiveFiltersDrawer}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      ),
    },
  };

  return (
    <Page
      heading={
        <Stack gap="1rem" alignItems="center">
          <h3 className="heading--3 no-margin">
            {isEmployee(user) ? t("myRepairs.adminHeading") : isMyRepairPage() ? t("myRepairs.heading") : t("myRepairs.otherHeading")}
          </h3>
          <Button disabled={repairListLoading} className="text--no-bold" onClick={() => setRefreshList(!refreshList)} size="small">
            {t("global.refresh")}
          </Button>
        </Stack>
      }
    >
      {displaysMap[isEmployee(user)][mobile]}
      <ManageTabsModal active={activeManageTabsModal} setActive={setActiveManageTabsModal} />
      <DeleteTabModal active={activeDeleteTabModal} setActive={setDeleteTabModal} />
    </Page>
  );
};

export default Home;
