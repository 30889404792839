import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { isAdmin } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { Spinner } from "stories/components/Spinner/Spinner";
import Button from "stories/components/Button/Button";
import ReportModal from "./ReportModal/ReportModal";
import DeleteConfirmationModal from "./ReportModal/DeleteConfirmationModal";

const DisplayDesktop = ({
  repair,
  report,
  reportLength,
  dictionaries,
  getReportDetails,
  producerReportList,
  producerReportManage,
  producerReportManagePart,
  loading,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const [reportModalActive, setReportModalActive] = useState(false);
  const [deleteConfirmationModalActive, setDeleteConfirmationModalActive] = useState(false);
  const [modalVariant, setModalVariant] = useState("add"); //options: "add"/"edit"
  const [canDelete, setCanDelete] = useState(false);

  if (loading)
    return (
      <div className="producer-report-body-spinner">
        <Spinner />
      </div>
    );

  return (
    <div className="extended-report">
      <h6 className="extended-report-heading heading--6 heading--large-spacing">{!mobile && t("myRepairs.extendedProducerReport")}</h6>

      {reportLength === 0 ? (
        <div className="report-not-found">
          <div className="paragraph--small">{t("producerReport.reportNotFound")}</div>
          {isAdmin(user) || user.id === repair.technicianId ? (
            <div className="report-not-found-button">
              <Button
                variant="primary"
                onClick={() => {
                  setModalVariant("add");
                  setReportModalActive(true);
                }}
              >
                {t("producerReport.addReport")}
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div>
            <div className="extended-report-content">
              <div>
                <div className="paragraph--small text--subdued">Problem</div>
                <div className="paragraph--small">{report?.problem ?? "-"}</div>
              </div>
              <div>
                <div className="paragraph--small text--subdued">Findings</div>
                <div className="paragraph--small">{report?.findings ?? "-"}</div>
              </div>
              <div>
                <div className="paragraph--small text--subdued">Cause of malfunction</div>
                <div className="paragraph--small">{report?.malfunction ?? "-"}</div>
              </div>
              <div>
                <div className="paragraph--small text--subdued">Test procedure</div>
                <div className="paragraph--small">{report?.testProcedure ?? "-"}</div>
              </div>
              <div>
                <div className="parts-title paragraph-small text--subdued">Parts:</div>
                {report?.parts?.length !== 0 ? (
                  <div>
                    {!reportModalActive &&
                      report?.parts?.map(({ partId, pcbSerialNumber, partName, repairStatus, location, qty }, index) => {
                        return (
                          <div key={partId} className="extended-report-content-row">
                            <div className="parts-content-row-cell-no">
                              <div className="paragraph--small text--subdued">No.</div>
                              <div className="paragraph--small">{index + 1}</div>
                            </div>
                            <div className="parts-content-row-cell-pcb">
                              <div className="paragraph--small text--subdued">PCB serial number</div>
                              <div className="paragraph--small">{pcbSerialNumber}</div>
                            </div>
                            <div className="parts-content-row-cell-pn">
                              <div className="paragraph--small text--subdued">Part name</div>
                              <div className="paragraph--small">{partName}</div>
                            </div>

                            <div className="parts-content-row-cell-rs">
                              <div className="paragraph--small text--subdued">Repair status</div>
                              <div className="paragraph--small">{repairStatus ?? "-"}</div>
                            </div>
                            <div className="parts-content-row-cell-l">
                              <div className="paragraph--small text--subdued">Location</div>
                              <div className="paragraph--small">{location}</div>
                            </div>
                            <div className="parts-content-row-cell-q">
                              <div className="paragraph--small text--subdued">Qty</div>
                              <div className="paragraph--small">{qty}</div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div>No parts</div>
                )}
              </div>
            </div>
          </div>
          <div className="extended-report-footer">
            {isAdmin(user) || user.id === repair.technicianId ? (
              <>
                <Button variant="error" onClick={() => setDeleteConfirmationModalActive(true)}>
                  {t("producerReport.deleteReport")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalVariant("edit");
                    setReportModalActive(true);
                  }}
                >
                  {t("producerReport.editReport")}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      )}
      <ReportModal
        modalVariant={modalVariant}
        setCanDelete={setCanDelete}
        repair={repair}
        report={report}
        dictionaries={dictionaries}
        producerReportManagePart={producerReportManagePart}
        getReportDetails={getReportDetails}
        active={reportModalActive}
        onClose={() => {
          setReportModalActive(false);
          if (modalVariant === "add" && canDelete === true) producerReportManage({ update: true, action: "delete", repairId: repair.repairId });
          setTimeout(() => {
            setCanDelete(false);
          }, 2000);
        }}
        onAction={() => {
          setReportModalActive(false);
          producerReportList();
        }}
      />
      <DeleteConfirmationModal
        active={deleteConfirmationModalActive}
        setActive={setDeleteConfirmationModalActive}
        onAction={() => {
          producerReportManage({ update: true, action: "delete", repairId: repair.repairId });
          setDeleteConfirmationModalActive(false);
        }}
      />
    </div>
  );
};

export default DisplayDesktop;
