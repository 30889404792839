import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import lodash from "lodash";
import { PRIVACY_POLICY_LINKS } from "utils/config";
import { readCookie, writeCookie } from "utils/global-functions";
import { pathWithoutNavigationBar } from "./config";
import { getAppVersionForFooter } from "./utils";
import ChangeLog from "components/ChangeLog/ChangeLog";
import "./styles/Footer.css";

export const Footer = () => {
  const appVersion = getAppVersionForFooter();
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const [showChangeLog, setShowChangeLog] = useState(readCookie("seenChangeLog") !== appVersion);

  return (
    <div className="footer">
      <div className={`footer__content ${pathWithoutNavigationBar.includes(window.location.pathname) || lodash.isEmpty(user) ? "footer__content--full" : ""}`}>
        <div className="footer__copyright-wrapper">
          <span className="footer__copyright">Copyright © {new Date().getFullYear()} Elmark Automatyka S.A.</span>
          {user.companyId === 1 ? (
            <>
              <span
                className="text--subdued pointer"
                title="ChangeLog"
                onClick={() => {
                  setShowChangeLog(true);
                }}
              >
                {appVersion}
              </span>
              {showChangeLog && (
                <ChangeLog
                  onCloseCallback={() => {
                    writeCookie({ value: appVersion, key: "seenChangeLog", days: 3650 });
                    setShowChangeLog(false);
                  }}
                ></ChangeLog>
              )}
            </>
          ) : (
            <span className="text--subdued">{appVersion}</span>
          )}
        </div>
        <div className="footer__links">
          <a href={PRIVACY_POLICY_LINKS[user?.lang || readCookie("lang") || "pl"]} target="_blank" rel="noreferrer" className="custom-link custom-link--black">
            {t("global.privacyPolicy")}
          </a>
        </div>
      </div>
    </div>
  );
};
