import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import agent from "api/agent";
import { isEmployee } from "utils/global-functions";
import { MAX_MOBILE_WIDTH } from "utils/config";
import DisplayDesktop from "./DisplayDesktop";
import "./styles.css";

const Report = () => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { repairId } = useParams();

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [repair, setRepair] = useState([]);
  const [dictionaries, setDictionaries] = useState();

  const producerReportList = () => {
    setLoading(true);

    return agent.ProducerReport.producerReportList({
      id: user.id,
      token: user.token,
      pageSize: 1,
      pageNumber: 1,
      repairId: repairId,
    })
      .then(
        ({ data }) => {
          setReport(data);
        },
        (error) => {
          dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
          dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) } });
          setReport([]);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const getReportDetails = () => {
    agent.ProducerReport.producerReportList({
      id: user.id,
      token: user.token,
      pageSize: 1,
      pageNumber: 1,
      repairId: repairId,
    }).then(
      ({ data }) => {
        setReport(data);
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) } });
        setReport([]);
      },
    );
  };

  //action=add/update/delete
  const producerReportManage = ({ update = false, action, repairId, reportId, problem, findings, malfunction, testProcedure }) => {
    agent.ProducerReport.producerReportManage({
      id: user.id,
      token: user.token,
      action: action,
      repairId: repairId,
      reportId: reportId,
      problem: problem,
      findings: findings,
      malfunction: malfunction,
      testProcedure: testProcedure,
    }).then(
      () => (update ? producerReportList() : null),
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) } });
        setReport([]);
      },
    );
  };

  const producerReportManagePart = ({ update = false, action, partId, repairId, pcbSerialNumber, partName, repairStatus, location, qty }) => {
    agent.ProducerReport.producerReportManagePart({
      id: user.id,
      token: user.token,
      action: action,
      repairId: repairId,
      partId: partId,
      pcbSerialNumber: pcbSerialNumber,
      partName: partName,
      repairStatus: repairStatus,
      location: location,
      qty: qty,
    }).then(
      () => (update ? getReportDetails() : null),
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: t(`errors.${error.response.data.messages[0].code}`) } });
        setReport([]);
      },
    );
  };

  const producerReportDictionary = (producerId) => {
    agent.ProducerReport.producerReportDictionary({ id: user.id, token: user.token, producerId: producerId }).then(({ data }) => {
      setDictionaries(data);
    });
  };

  const getRepairDetails = () => {
    agent.Repairs.getRepairDetails({ id: user.id, token: user.token, repairId: repairId }).then(
      ({ data }) => {
        setRepair(data[0]);
        producerReportDictionary(data[0].producerId);
      },
      (error) => {
        dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
        navigate("/");
        setRepair();
      },
    );
  };

  useEffect(() => {
    if (!isEmployee(user)) navigate(`/${repairId}/info`);
    producerReportList();
    getRepairDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mobile ? (
    <>
      <DisplayDesktop
        repair={repair}
        report={report[0]}
        reportLength={report.length}
        dictionaries={dictionaries}
        getReportDetails={getReportDetails}
        producerReportList={producerReportList}
        producerReportManage={producerReportManage}
        producerReportManagePart={producerReportManagePart}
        loading={loading}
      />
    </>
  ) : (
    <>
      <DisplayDesktop
        repair={repair}
        report={report[0]}
        reportLength={report.length}
        dictionaries={dictionaries}
        getReportDetails={getReportDetails}
        producerReportList={producerReportList}
        producerReportManage={producerReportManage}
        producerReportManagePart={producerReportManagePart}
        loading={loading}
      />
    </>
  );
};

export default Report;
