import React from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import WaitingForValuation from "../WaitingForValuation";
import ValuationOffer from "./ValuationOffer";
import AddValuation from "./AddValuation";
import "./styles.css";

const Desktop = ({
  setAddValuationModalActive,
  conversationList,
  setConfirmModalActive,
  selectedConversationId,
  setSelectedConversationId,
  conversationListLoading,
  setConfirmActionName,
}) => {
  return (
    <>
      {conversationListLoading ? (
        <PageSpinner subPage />
      ) : (
        <>
          <AddValuation setAddValuationModalActive={setAddValuationModalActive} />
          {!!conversationList.length ? (
            <div className="pricing-box">
              {conversationList.map((conversation) => (
                <ValuationOffer
                  setAddValuationModalActive={setAddValuationModalActive}
                  selectedConversationId={selectedConversationId}
                  setSelectedConversationId={setSelectedConversationId}
                  setConfirmModalActive={setConfirmModalActive}
                  key={conversation.id}
                  conversation={conversation}
                  setConfirmActionName={setConfirmActionName}
                />
              ))}
            </div>
          ) : (
            <WaitingForValuation />
          )}
        </>
      )}
    </>
  );
};

export default Desktop;
