import { ATTACHMENTS_GENERAL_TYPE } from "utils/config";
import { isAdmin } from "utils/global-functions";

export const getAttachmentRequestBody = (attachmentToEdit, repairId, attachments) =>
  attachmentToEdit
    ? { action: "update", attachmentId: attachmentToEdit.id }
    : { action: "add", repairId: repairId, attachmentType: ATTACHMENTS_GENERAL_TYPE, attachments: attachments };

export const getClassForColspan = (attachmentList) => (!!attachmentList?.length ? "" : "attachment__table-colspan--hidden");

export const isAbleToModifyAttachment = (user, attachment) =>
  (isAdmin(user) || attachment.authorId === user.id) && attachment.attachmentType === ATTACHMENTS_GENERAL_TYPE;
