/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../commonStyles.css";
import { getSelectField } from "./utils";
import "./SelectField.css";

const SelectField = ({
  async,
  id,
  editable = false,
  size = "default",
  disabled = false,
  name,
  label,
  error,
  helpText,
  multi = false,
  placeholder,
  options,
  value,
  menuPortalTarget,
  onChange,
  isClearable = false,
  className,
  promiseOptions,
  specialActions,
  ...rest
}) => {
  const selectContainer = useRef(null);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(
    multi ? options?.filter((option) => value.includes(option.value)) : options?.find((option) => option.value === value),
  );

  useEffect(() => {
    if (!value && value !== 0) setSelected(null);
    else setSelected(multi ? options?.filter((option) => value.includes(option.value)) : options?.find((option) => option.value === value));
  }, [value]);

  return (
    <div className={`input ${className || ""}`}>
      {label && (
        <label className="input__label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className={specialActions ? "select-field__wrapper" : ""}>
        <div className={!!specialActions ? "select-field__special-actions" : ""} style={{ position: "relative" }} ref={selectContainer}>
          {getSelectField({
            async,
            id,
            editable,
            size,
            disabled,
            name,
            label,
            multi,
            placeholder,
            options,
            menuPortalTarget,
            onChange,
            isClearable,
            promiseOptions,
            t,
            selected,
            selectContainer,
            ...rest,
          })}
        </div>
        {specialActions}
      </div>
      {error && <span className="input__error-message">{error}</span>}
      {helpText && <span className="input__help-text">{helpText}</span>}
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(["small", "default"]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  error: PropTypes.string,
  multi: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default SelectField;
