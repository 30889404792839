/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import PageSpinner from "components/PageSpinner/PageSpinner";
import DisplayMobile from "./DisplayMobile";
import DisplayDesktop from "./DisplayDesktop";
import { isNewContactData, transformContactValues } from "./utils";
import "./styles.css";

export const AddReportData = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const {
    user,
    registration,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [contactData, setContactData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [contactDataLoading, setContactDataLoading] = useState(true);
  const [sendContactDataLoading, setSendContactDataLoading] = useState(false);

  const setRegistrationContactSuccess = (response) => {
    if (response.result === 301) {
      setFormErrors({});
      return dispatch({ type: "REGISTRATION_SETSTEP", step: 4 });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRegistrationContactError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
  };

  const setRegistrationContact = ({ newContactData, ...rest }) => {
    setSendContactDataLoading(true);
    return agent.Registration.registrationContactSet({
      id: user.id,
      token: user.token,
      registrationId: registration.registrationId,
      ...transformContactValues(rest, newContactData),
    })
      .then(setRegistrationContactSuccess, setRegistrationContactError)
      .finally(() => setSendContactDataLoading(false));
  };

  const getRegistrationContactSuccess = (response) => {
    setContactData(response.data.at(0));
  };

  const getRegistrationContactError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const getRegistrationContact = () =>
    agent.Registration.registrationContactGet({ id: user.id, token: user.token, registrationId: registration.registrationId })
      .then(getRegistrationContactSuccess, getRegistrationContactError)
      .finally(() => setContactDataLoading(false));

  useEffect(() => {
    getRegistrationContact();
  }, []);

  if (contactDataLoading) return <PageSpinner subPage />;

  const validationSchema = Yup.object().shape({
    contactPerson: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    contactPhone: Yup.string().min(3, t("errors.101")).max(50, t("errors.102")).required(t("errors.99")),
    contactEmail: Yup.string().email(t("errors.104")).required(t("errors.99")),
  });

  return (
    <Formik
      initialValues={{
        contactPerson: contactData?.contactPerson ?? "",
        contactPhone: contactData?.contactPhone ?? "",
        contactEmail: contactData?.contactEmail ?? "",
        newContactData: contactData ? isNewContactData(contactData) : true,
      }}
      validationSchema={validationSchema}
    >
      {({ touched, errors, handleBlur, values, setFieldValue, handleSubmit, isValid }) => (
        <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
          {mobile ? (
            <DisplayMobile
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              formErrors={formErrors}
              values={values}
              setFieldValue={setFieldValue}
              setRegistrationContact={setRegistrationContact}
              sendContactDataLoading={sendContactDataLoading}
              formValid={isValid}
            />
          ) : (
            <DisplayDesktop
              values={values}
              setRegistrationContact={setRegistrationContact}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              formErrors={formErrors}
              setFieldValue={setFieldValue}
              sendContactDataLoading={sendContactDataLoading}
              formValid={isValid}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
