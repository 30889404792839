import React from "react";
import { getClassNamesForTextField } from "./utils";

const MinimalistNumberField = ({ disabled = false, value, onChange, id, name, placeholder, specialActions }) => {
  const onlyNumbers = new RegExp("^[0-9]*$");

  return (
    <div className="textfield--special-actions">
      <input
        id={id}
        name={name}
        disabled={disabled}
        className={`input-minimalist-number ${getClassNamesForTextField(disabled)}`}
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) => {
          if (onlyNumbers.test(value)) onChange(value ? parseInt(value) : value, name);
        }}
      />
      {specialActions}
    </div>
  );
};

export default MinimalistNumberField;
