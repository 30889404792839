import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import agent from "api/agent";
import Radio from "stories/components/Radio/Radio";
import Stack from "stories/components/Stack/Stack";
import TextField from "stories/components/TextField/TextField";
import { filterErrorsFromCommonApiErrors } from "utils/global-functions";
import ChangeUserResults from "./ChangeUserResults";
import ChangeUserReturnAddressForm from "./ChangeUserReturnAddressForm";
import ChangeUserContactForm from "./ChangeUserContactForm";

const ChangeUserForm = ({ values, setFieldValue, formErrors, errors, touched, handleBlur }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [searchError, setSearchError] = useState();
  const [usersSearchResult, setUsersSearchResult] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const textFieldSearchUserInput = useRef(null);

  const searchUsersSuccess = (response) => {
    if (!response.data.length) throw Error("302");
    setUsersSearchResult(response.data);
    setSearchError(null);
  };

  const searchUsersError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    if (filteredErrors.length > 0) setSearchError(t(`errors.${filteredErrors.at(0).code}`));
  };

  const searchUsers = (values) => {
    setLoadingResults(true);
    if (values.employeeUser)
      return agent.Resources.employeeSearch({ id: user.id, token: user.token, filter: values.textFieldSearchPhrase })
        .then(searchUsersSuccess, searchUsersError)
        .catch((error) => {
          setUsersSearchResult([]);
          setSearchError(t(`errors.${error.message}`));
        })
        .finally(() => setLoadingResults(false));

    return agent.Resources.customerSearch({ id: user.id, token: user.token, filter: values.textFieldSearchPhrase })
      .then(searchUsersSuccess, searchUsersError)
      .catch((error) => {
        setUsersSearchResult([]);
        setSearchError(t(`errors.${error.message}`));
      })
      .finally(() => setLoadingResults(false));
  };

  return (
    <>
      <div>
        <Radio
          checked={values.employeeUser}
          onChange={() => {
            setFieldValue("employeeUser", true);
            setFieldValue("textFieldSearchPhrase", "");
            setSearchError(null);
            setFieldValue("selectedUserId", "");
            setUsersSearchResult([]);
          }}
          label={t("global.elmark")}
        />
        <Radio
          checked={!values.employeeUser}
          onChange={() => {
            setFieldValue("employeeUser", false);
            setFieldValue("textFieldSearchPhrase", "");
            setSearchError(null);
            setFieldValue("selectedUserId", "");
            setUsersSearchResult([]);
          }}
          label={t("global.client")}
        />
      </div>
      <TextField
        ref={textFieldSearchUserInput}
        variant="search"
        onKeyDown={(event) => {
          if (event.code === "Enter") {
            event.preventDefault();
            searchUsers(values);
          }
        }}
        value={values.textFieldSearchPhrase}
        onChange={(value) => {
          setFieldValue("textFieldSearchPhrase", value);
          setFieldValue("selectedUserId", "");
          setUsersSearchResult([]);
        }}
        error={searchError}
        placeholder={values.employeeUser ? t("myRepairs.searchForEmployee") : t("myRepairs.searchForClient")}
        onClick={() => searchUsers(values)}
        loading={loadingResults}
      />
      <ChangeUserResults values={values} setFieldValue={setFieldValue} results={usersSearchResult} />
      {values.selectedUserId && (
        <Stack flexDirection="column" gap="2.5rem">
          <ChangeUserReturnAddressForm
            formErrors={formErrors}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
          <ChangeUserContactForm
            formErrors={formErrors}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </Stack>
      )}
    </>
  );
};

export default ChangeUserForm;
