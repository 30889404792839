const imgSizesMap = new Map([
  ["mobile", "no-results-search-img__small"],
  ["small", "no-results-search-img__small"],
  ["big", "no-results-search-img__big"],
]);
export const getImgClassName = ({ size }) => {
  const classNames = [];

  classNames.push(imgSizesMap.get(size) || "");

  return classNames.length ? classNames.join(" ") : "";
};

const headingSizesMap = new Map([
  ["mobile", "heading--6"],
  ["small", "heading--5"],
  ["big", "heading--4"],
]);
export const getHeadingClassName = ({ size }) => {
  const classNames = [];

  classNames.push(headingSizesMap.get(size) || "");

  return classNames.length ? classNames.join(" ") : "";
};

const paragraphSizesMap = new Map([
  ["mobile", "paragraph"],
  ["small", "paragraph"],
  ["big", "paragraph--large"],
]);
export const getParagraphClassName = ({ size }) => {
  const classNames = [];

  classNames.push(paragraphSizesMap.get(size) || "");

  return classNames.length ? classNames.join(" ") : "";
};
