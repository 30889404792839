import React from "react";
import { useTranslation } from "react-i18next";
import Drawer from "stories/components/Drawer/Drawer";
import Button from "stories/components/Button/Button";
import { Filters } from "./Filters";
import "./styles.css";

const DisplayMobile = ({
  producerReportList,
  filtersActive,
  setFiltersActive,
  setEndDateFrom,
  setEndDateUpTo,
  setRepairId,
  setSerialNumber,
  setPartNumber,
  setTechnicianId,
  setProducerId,
  setIsIncopmlete,
  endDateFromValue,
  setEndDateFromValue,
  endDateUpToValue,
  setEndDateUpToValue,
  repairIdValue,
  setRepairIdValue,
  serialNumberValue,
  setSerialNumberValue,
  partNumberValue,
  setPartNumberValue,
  technicianIdValue,
  setTechnicianIdValue,
  producerIdValue,
  setProducerIdValue,
  isIncompleteValue,
  setIsIncopmleteValue,
  reportListExportCallback,
  reportListLength,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mobile-producer-report-heading-actions">
      <div className="mobile-producer-report-heading-actions-buttons-left">
        <Button size="small" onClick={() => producerReportList()}>
          {t("global.refresh")}
        </Button>
        <Button size="small" onClick={reportListExportCallback} disabled={reportListLength ? false : true}>
          {t("global.export")}
        </Button>
      </div>

      <Drawer
        title={t("global.filters")}
        active={filtersActive}
        setActive={setFiltersActive}
        activator={
          <Button variant="primary" size="small" onClick={() => setFiltersActive(true)}>
            {t("global.filter")}
          </Button>
        }
      >
        <Filters
          setFiltersActive={setFiltersActive}
          setEndDateFrom={setEndDateFrom}
          setEndDateUpTo={setEndDateUpTo}
          setRepairId={setRepairId}
          setSerialNumber={setSerialNumber}
          setPartNumber={setPartNumber}
          setTechnicianId={setTechnicianId}
          setProducerId={setProducerId}
          setIsIncopmlete={setIsIncopmlete}
          endDateFromValue={endDateFromValue}
          setEndDateFromValue={setEndDateFromValue}
          endDateUpToValue={endDateUpToValue}
          setEndDateUpToValue={setEndDateUpToValue}
          repairIdValue={repairIdValue}
          setRepairIdValue={setRepairIdValue}
          serialNumberValue={serialNumberValue}
          setSerialNumberValue={setSerialNumberValue}
          partNumberValue={partNumberValue}
          setPartNumberValue={setPartNumberValue}
          technicianIdValue={technicianIdValue}
          setTechnicianIdValue={setTechnicianIdValue}
          producerIdValue={producerIdValue}
          setProducerIdValue={setProducerIdValue}
          isIncompleteValue={isIncompleteValue}
          setIsIncopmleteValue={setIsIncopmleteValue}
        />
      </Drawer>
    </div>
  );
};

export default DisplayMobile;
