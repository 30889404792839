import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "stories/components/Button/Button";
import Stack from "stories/components/Stack/Stack";
import LoginModal from "./LoginModal";
import SearchForWarrantyModal from "./SearchForWarrantyModal";

const LoginCard = () => {
  const { t } = useTranslation();
  const [activeLoginModal, setActiveLoginModal] = useState(false);
  const [activeWarrantyModal, setActiveWarrantyModal] = useState(false);
  const [warrantyDetails, setWarrantyDetails] = useState({});

  return (
    <div className="mobile_login_card">
      <Stack flexDirection="column" gap="2.5rem">
        <div>
          <h2 className="mobile_heading">{t("loginPage.motto")}</h2>
        </div>
        <div className="mobile_login_box">
          <div className="mobile_login_button_wrapper">
            <Button fullWidth variant="primary" onClick={() => setActiveLoginModal(true)}>
              {t("loginPage.login")}
            </Button>
            <Button fullWidth onClick={() => setActiveWarrantyModal(true)}>
              {t("loginPage.checkWarranty")}
            </Button>
          </div>
        </div>
      </Stack>
      <LoginModal active={activeLoginModal} onClose={() => setActiveLoginModal(false)} />
      <SearchForWarrantyModal
        active={activeWarrantyModal}
        warrantyDetails={warrantyDetails}
        setWarrantyDetails={setWarrantyDetails}
        onClose={() => {
          setWarrantyDetails({});
          setActiveWarrantyModal(false);
        }}
      />
    </div>
  );
};

export default LoginCard;
