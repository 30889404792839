import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "stories/components/TextField/TextField";
import agent from "api/agent";
import { filterErrorsFromCommonApiErrors, isAdmin, isReception } from "utils/global-functions";
import Stack from "stories/components/Stack/Stack";
import Button from "stories/components/Button/Button";
import { convertNullToEmptyString } from "../utils";

const InternalComment = () => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const [internalComment, setInternalComment] = useState(repairDetails.repairComment || "");
  const [inernalCommentLoading, setInernalCommentLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRepairInernalCommentSuccess = (response, repairComment) => {
    if (response.result === 301) {
      return dispatch({ type: "REPAIR_SET_REPAIR_DETAILS", repairDetails: { ...repairDetails, repairComment } });
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const setRepairInernalCommentError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    if (filteredErrors.some((filteredError) => filteredError.code === 302)) navigate("/");
    filteredErrors.forEach((filterdError) =>
      dispatch({
        type: "APP_PUSHNOTIFICATION",
        notification: {
          type: "danger",
          description: t(`errors.${filterdError.code}`),
        },
      }),
    );
  };

  const setRepairInernalComment = (text) => {
    setInernalCommentLoading(true);
    agent.Repairs.setRepairComment({ id: user.id, token: user.token, repairId: repairDetails.repairId, commentType: "repairComment", commentText: text })
      .then((response) => setRepairInernalCommentSuccess(response, text), setRepairInernalCommentError)
      .then(() => setInernalCommentLoading(false));
  };

  return (
    <div className="mobile-comment-section">
      <Stack flexDirection="column" gap="0.5rem">
        <TextField
          label={t("global.internalComment")}
          value={internalComment}
          onChange={setInternalComment}
          variant="multi"
          placeholder={t("global.writeAComment")}
          numberOfLines={6}
          disabled={!isAdmin(user) && !isReception(user) && user.id !== repairDetails.technicianId}
        />
        <Stack gap="var(--modal-buttons-gap)">
          <Button
            size="small"
            variant="primary"
            loading={inernalCommentLoading}
            disabled={convertNullToEmptyString(repairDetails.repairComment) === internalComment}
            onClick={() => setRepairInernalComment(internalComment)}
          >
            {t("global.save")}
          </Button>
          <Button
            size="small"
            disabled={inernalCommentLoading || convertNullToEmptyString(repairDetails.repairComment) === internalComment}
            onClick={() => setInternalComment(repairDetails.repairComment)}
          >
            {t("global.cancel")}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default InternalComment;
