import React, { useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EmployeeSelector from "components/EmployeeSelector/EmployeeSelector";
import { getErrorForInputField, isEmployee } from "utils/global-functions";
import { IS_IN_QUEUE_OPTIONS } from "utils/config";
import DatePicker from "stories/components/DatePicker/DatePicker";
import SelectField from "stories/components/SelectField/SelectField";
import TextField from "stories/components/TextField/TextField";
import Button from "stories/components/Button/Button";
import Tooltip from "stories/components/Tooltip/Tooltip";
import { ratingsOptions, repairDetailsLinkRegExp, yesNoOptions } from "./config";
import { formatAppliedFiltersForSerializableValues } from "./utils";
import QRScanner from "components/QRScanner/QRSscanner";
import CustomerSelectorModal from "components/CustomerSelectorModal/CustomerSelectorModal";

const Filters = ({ setActiveManageTabsModal, setActiveFiltersDrawer, repairListLoading, setPageNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    repair: { appliedFilters, filtersFormErrors },
    app: { constants },
  } = useSelector((store) => store);
  const [customerSelectorModalActive, setCustomerSelectorModalActive] = useState(false);

  const repairsFiltersSchema = Yup.object({
    searchPhrase: Yup.string().min(3, t("global.tooShort")).max(30, t("global.tooLong")),
    customer: Yup.string().min(3, t("global.tooShort")).max(30, t("global.tooLong")),
    serialNumber: Yup.string().min(3, t("global.tooShort")).max(30, t("global.tooLong")),
    partNumber: Yup.string().min(3, t("global.tooShort")).max(30, t("global.tooLong")),
    repairId: Yup.number().typeError(t("global.mustBeNumber")).min(1, t("global.tooShort")).max(999999, t("global.tooLong")),
  });

  const clearAllFields = (setFieldValue, setFieldError, setFieldTouched, values) =>
    Object.keys(values).forEach((key) => {
      setFieldValue(key, key.toLowerCase().includes("date") ? null : "");
      setFieldError(key, null);
      setFieldTouched(key, null);
    });

  if (repairListLoading) return;

  return (
    <Formik
      initialValues={{
        searchPhrase: appliedFilters?.searchPhrase ?? "",
        customer: appliedFilters?.customer ?? "",
        repairId: appliedFilters?.repairId ?? "",
        serialNumber: appliedFilters?.serialNumber ?? "",
        registrationDateFrom: appliedFilters?.registrationDateFrom ? moment(parseInt(appliedFilters?.registrationDateFrom)) : null,
        registrationDateUpTo: appliedFilters?.registrationDateUpTo ? moment(parseInt(appliedFilters?.registrationDateUpTo)) : null,
        endDateFrom: appliedFilters?.endDateFrom ? moment(parseInt(appliedFilters?.endDateFrom)) : null,
        endDateUpTo: appliedFilters?.endDateUpTo ? moment(parseInt(appliedFilters?.endDateUpTo)) : null,
        partNumber: appliedFilters?.partNumber ?? "",
        producerId: appliedFilters?.producerId ?? "",
        technicianId: appliedFilters?.technicianId ?? "",
        repairSource: appliedFilters?.repairSource ?? "",
        repairState: appliedFilters?.repairState ?? "",
        customerRating: appliedFilters?.customerRating ?? "",
        elmarkRating: appliedFilters?.elmarkRating ?? "",
        repairType: appliedFilters?.repairType ?? "",
        repairKind: appliedFilters?.repairKind ?? "",
        isInQueue: appliedFilters?.isInQueue ?? "",
        isWaiting: appliedFilters?.isWaiting ?? "",
        isClosed: appliedFilters?.isClosed ?? "",
        isAsleep: appliedFilters?.isAsleep ?? "",
      }}
      validationSchema={repairsFiltersSchema}
      onSubmit={(values) => {
        setPageNumber(1);
        dispatch({
          type: "REPAIR_SET_APPLIEDFILTERS",
          appliedFilters: formatAppliedFiltersForSerializableValues(Object.fromEntries(Object.entries(values).filter(([_, value]) => value || value === 0))),
        });
        setActiveFiltersDrawer(false);
      }}
    >
      {({ values, isValid, errors, touched, handleBlur, setFieldValue, isSubmitting, handleSubmit, setFieldError, setFieldTouched }) => (
        <Form onSubmit={handleSubmit}>
          <div className="repairs__filters-form">
            <TextField
              id="repairId"
              name="repairId"
              variant="minimalistNumber"
              className="repairs__filters-field--full"
              value={values.repairId}
              error={getErrorForInputField(errors.repairId, touched.repairId) || filtersFormErrors.repairId}
              onChange={(value) => setFieldValue("repairId", value)}
              onBlur={handleBlur}
              label={t("global.repairId")}
              placeholder={t("global.provideRepairId")}
              specialActions={
                <QRScanner
                  setResultValue={(value) => {
                    setFieldValue("repairId", repairDetailsLinkRegExp.test(value) ? parseInt(value.match(/[0-9]{1,}/).at(0)) : parseInt(value));
                    setTimeout(() => handleSubmit(), 200);
                  }}
                />
              }
            />
            <TextField
              id="serialNumber"
              name="serialNumber"
              tooltipZindex={1040}
              tooltipContent={`<p class="text--left text--preline no-margin">${t("myRepairs.serialNumberFilterInfo")}</p>`}
              className="repairs__filters-field--full"
              value={values.serialNumber}
              error={getErrorForInputField(errors.serialNumber, touched.serialNumber) || filtersFormErrors.serialNumber}
              onChange={(value) => setFieldValue("serialNumber", value)}
              onBlur={handleBlur}
              label={t("global.serialNumber")}
              placeholder={t("global.provideSerialNumber")}
              specialActions={<QRScanner setResultValue={(value) => setFieldValue("serialNumber", value)} />}
            />
            {isEmployee(user) && (
              <TextField
                id="customer"
                name="customer"
                className="repairs__filters-field--full"
                value={values.customer}
                error={getErrorForInputField(errors.customer, touched.customer) || filtersFormErrors.customer}
                onChange={(value) => setFieldValue("customer", value)}
                onBlur={handleBlur}
                label={t("global.client")}
                placeholder={t("global.provideClientName")}
                specialActions={
                  <Tooltip content={t("global.selectFromList")} zIndex={1040} direction="left">
                    <div style={{ padding: "0px 4px" }}>
                      <svg className="pointer qr-scanner__icon" onClick={() => setCustomerSelectorModalActive(true)} viewBox="0 0 48 48" width="24" fill="none">
                        <g>
                          <path d="M16.601,18.578c0.106,0,0.21-0.012,0.316-0.016c-0.085-0.597-0.145-1.201-0.145-1.822 c0-5.098,3-9.484,7.324-11.523C22.551,2.691,19.777,1,16.601,1c-4.854,0-8.789,3.935-8.789,8.789 C7.812,14.643,11.746,18.578,16.601,18.578z" />
                          <circle cx="29.476" cy="16.74" r="9.763" />
                          <path d="M37.993,26.365c-2.27,2.011-5.247,3.241-8.518,3.241c-3.256,0-6.22-1.219-8.487-3.212 c-5.913,3.227-9.953,9.7-9.953,17.165c0,1.176,0.105,2.323,0.297,3.44h36.37C47.895,45.883,48,44.735,48,43.56 C48,36.072,43.937,29.582,37.993,26.365z" />
                          <path d="M19.162,24.205c-0.628-0.886-1.153-1.849-1.542-2.881c-0.336,0.029-0.676,0.048-1.02,0.048 c-2.932,0-5.6-1.097-7.641-2.892C3.637,21.385,0,27.213,0,33.934c0,1.058,0.095,2.092,0.267,3.098h9.062 C10.636,31.484,14.27,26.838,19.162,24.205z" />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                }
              />
            )}
            <TextField
              id="partNumber"
              name="partNumber"
              className="repairs__filters-field--full"
              value={values.partNumber}
              error={getErrorForInputField(errors.partNumber, touched.partNumber) || filtersFormErrors.partNumber}
              onChange={(value) => setFieldValue("partNumber", value)}
              onBlur={handleBlur}
              label={t("global.symbolOrDescription")}
              placeholder={t("global.provideSymbolOrDescription")}
            />
            {isEmployee(user) && (
              <>
                <TextField
                  id="searchPhrase"
                  name="searchPhrase"
                  tooltipZindex={1040}
                  tooltipContent={`
                  <div class="filters__label-tooltip">
                  <span>${t("global.searchBy")}:</span>
                  <ul class="no-margin">
                  <li>${t("myRepairs.byFaultDescription").toLowerCase()}</li>
                  <li>${t("myRepairs.byEquipmentDescription").toLowerCase()}</li>
                  <li>${t("myRepairs.byRepairDescription").toLowerCase()}</li>
                  <li>${t("myRepairs.internalComments").toLowerCase()}</li>
                  <li>${t("myRepairs.byProducerRepairNumber").toLowerCase()}</li>
                  <li>${t("myRepairs.byShippingNoticeNumber").toLowerCase()}</li>
                  </ul>
                  </div>
                  `}
                  className="repairs__filters-field--full"
                  value={values.searchPhrase}
                  error={getErrorForInputField(errors.searchPhrase, touched.searchPhrase) || filtersFormErrors.searchPhrase}
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue("searchPhrase", value)}
                  label={t("global.searchPhrase")}
                  placeholder={t("global.provideSearchPhrase")}
                />
                <EmployeeSelector
                  isClearable={true}
                  size="default"
                  menuPortalTarget={null}
                  value={values.technicianId}
                  onChange={(value) => setFieldValue("technicianId", value)}
                  label={t("global.serviceTechnician")}
                  placeholder={t("global.chooseFromList")}
                  error={filtersFormErrors.technicianId}
                />
              </>
            )}
            <SelectField
              isClearable={true}
              menuPortalTarget={null}
              value={values.producerId}
              className={isEmployee(user) ? "" : "repairs__filters-field--full"}
              onChange={(value) => setFieldValue("producerId", value)}
              label={t("global.producer")}
              placeholder={t("global.chooseFromList")}
              error={filtersFormErrors.producerId}
              options={constants?.producersArr?.map(({ intVal, strVal }) => {
                return { value: intVal, label: strVal };
              })}
            />
            <DatePicker
              startDateId="start_report_date_id"
              startDate={values.registrationDateFrom}
              startDatePlaceholderText={t("global.chooseEndDate")}
              endDateId="end_report_date_id"
              endDate={values.registrationDateUpTo}
              endDatePlaceholderText={t("global.chooseStartDate")}
              onChange={(values) => {
                setFieldValue("registrationDateFrom", values.startDate);
                setFieldValue("registrationDateUpTo", values.endDate);
              }}
              variant="range"
              className="repairs__filters-field--full"
              language={user.lang}
              openDirection="down"
              error={filtersFormErrors.registrationDateFrom}
              label={t("global.reportDate")}
            />
            <DatePicker
              startDateId="start_report_end_date_id"
              startDate={values.endDateFrom}
              startDatePlaceholderText={t("global.chooseEndDate")}
              endDateId="end_report_end_date_id"
              endDate={values.endDateUpTo}
              endDatePlaceholderText={t("global.chooseStartDate")}
              onChange={(values) => {
                setFieldValue("endDateFrom", values.startDate);
                setFieldValue("endDateUpTo", values.endDate);
              }}
              language={user.lang}
              variant="range"
              className="repairs__filters-field--full"
              openDirection="down"
              error={filtersFormErrors.endDateFrom}
              label={t("global.repairEndDate")}
            />
            {isEmployee(user) && (
              <>
                <SelectField
                  isClearable={true}
                  menuPortalTarget={null}
                  value={values.isAsleep}
                  onChange={(value) => setFieldValue("isAsleep", value)}
                  label={t("global.isAsleep")}
                  placeholder={t("global.chooseFromList")}
                  isSearchable={false}
                  error={filtersFormErrors.isAsleep}
                  options={yesNoOptions.map(({ label, value }) => {
                    return { value, label: t(`global.${label}`) };
                  })}
                />
                <SelectField
                  isClearable={true}
                  menuPortalTarget={null}
                  value={values.isInQueue}
                  onChange={(value) => setFieldValue("isInQueue", value)}
                  label={t("global.isInQueue")}
                  placeholder={t("global.chooseFromList")}
                  isSearchable={false}
                  error={filtersFormErrors.isInQueue}
                  options={IS_IN_QUEUE_OPTIONS.map(({ label, value }) => {
                    return { value, label: t(`global.${label}`) };
                  })}
                />
                <SelectField
                  isClearable={true}
                  menuPortalTarget={null}
                  value={values.elmarkRating}
                  onChange={(value) => setFieldValue("elmarkRating", value)}
                  label={t("global.elmarkRating")}
                  placeholder={t("global.chooseFromList")}
                  isSearchable={false}
                  error={filtersFormErrors.elmarkRating}
                  options={ratingsOptions.map(({ label, value }) => {
                    return { value, label: t(`global.${label}`) };
                  })}
                />
                <SelectField
                  isClearable={true}
                  menuPortalTarget={null}
                  value={values.customerRating}
                  onChange={(value) => setFieldValue("customerRating", value)}
                  label={t("global.customerRating")}
                  placeholder={t("global.chooseFromList")}
                  isSearchable={false}
                  error={filtersFormErrors.customerRating}
                  options={ratingsOptions.map(({ label, value }) => {
                    return { value, label: t(`global.${label}`) };
                  })}
                />
              </>
            )}
            <SelectField
              isClearable={true}
              menuPortalTarget={null}
              value={values.repairType}
              onChange={(value) => setFieldValue("repairType", value)}
              label={t("global.repairType")}
              isSearchable={false}
              placeholder={t("global.chooseFromList")}
              error={filtersFormErrors.repairType}
              options={constants?.repairTypeArr
                ?.filter(({ intVal }) => intVal !== 0)
                ?.map(({ intVal, strVal }) => {
                  return { value: intVal, label: t(`constants.repair.type.${strVal}`) };
                })}
            />
            <SelectField
              isClearable={true}
              menuPortalTarget={null}
              value={values.repairState}
              onChange={(value) => setFieldValue("repairState", value)}
              label={t("global.repairState")}
              isSearchable={false}
              placeholder={t("global.chooseFromList")}
              error={filtersFormErrors.repairState}
              options={constants?.repairStateArr
                ?.filter(({ intVal }) => intVal !== 0)
                ?.map(({ intVal, strVal }) => {
                  return { value: intVal, label: t(`constants.repair.state.${strVal}`) };
                })}
            />
            {isEmployee(user) && (
              <SelectField
                isClearable={true}
                menuPortalTarget={null}
                value={values.repairSource}
                onChange={(value) => setFieldValue("repairSource", value)}
                label={t("global.repairSource")}
                isSearchable={false}
                placeholder={t("global.chooseFromList")}
                error={filtersFormErrors.repairSource}
                options={constants?.repairSourceArr
                  ?.filter(({ intVal }) => intVal !== 0)
                  ?.map(({ intVal, strVal }) => {
                    return { value: intVal, label: t(`constants.repair.source.${strVal}`) };
                  })}
              />
            )}
            <SelectField
              isClearable={true}
              menuPortalTarget={null}
              value={values.repairKind}
              onChange={(value) => setFieldValue("repairKind", value)}
              label={t("global.repairKind")}
              isSearchable={false}
              placeholder={t("global.chooseFromList")}
              error={filtersFormErrors.repairKind}
              options={constants?.repairKindArr
                ?.filter(({ intVal }) => intVal !== 0)
                ?.map(({ intVal, strVal }) => {
                  return { value: intVal, label: t(`constants.repair.kind.${strVal}`) };
                })}
            />
            {isEmployee(user) && (
              <SelectField
                isClearable={true}
                menuPortalTarget={null}
                value={values.isWaiting}
                onChange={(value) => setFieldValue("isWaiting", value)}
                label={t("myRepairs.isWaiting")}
                isSearchable={false}
                placeholder={t("global.chooseFromList")}
                error={filtersFormErrors.isWaiting}
                options={yesNoOptions.map(({ label, value }) => {
                  return { value, label: t(`global.${label}`) };
                })}
              />
            )}
            <SelectField
              isClearable={true}
              menuPortalTarget={null}
              value={values.isClosed}
              onChange={(value) => setFieldValue("isClosed", value)}
              label={t("myRepairs.isClosed")}
              isSearchable={false}
              placeholder={t("global.chooseFromList")}
              error={filtersFormErrors.isClosed}
              options={yesNoOptions.map(({ label, value }) => {
                return { value, label: t(`global.${label}`) };
              })}
            />
            <div className="repairs__filters-fields repairs__filters-field--full">
              <Button fullWidth onClick={() => clearAllFields(setFieldValue, setFieldError, setFieldTouched, values)}>
                {t("global.clearFilters")}
              </Button>
              {isEmployee(user) && (
                <Button
                  onClick={() => {
                    setActiveManageTabsModal(true);
                    dispatch({
                      type: "REPAIR_SET_APPLIEDFILTERS",
                      appliedFilters: formatAppliedFiltersForSerializableValues(
                        Object.fromEntries(Object.entries(values).filter(([_, value]) => value || value === 0)),
                      ),
                    });
                  }}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  fullWidth
                >
                  {t("global.saveAsTab")}
                </Button>
              )}
              <Button type="submit" disabled={!isValid} loading={isSubmitting} fullWidth variant="primary">
                {t("global.showResults")}
              </Button>
            </div>
            <CustomerSelectorModal
              zIndex={1030}
              active={customerSelectorModalActive}
              setActive={setCustomerSelectorModalActive}
              setCustomer={(value) => setFieldValue("customer", value)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Filters;
