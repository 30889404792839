import React from "react";
import { useTranslation } from "react-i18next";

const EmptyPage = () => {
  const { t } = useTranslation();

  return (
    <div className="attachments__empty-page">
      <h6 className="heading--6 text--center">{t("myRepairs.attachmentsEmptyPage")}</h6>
    </div>
  );
};

export default EmptyPage;
