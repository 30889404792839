/*
Author: jacek.bonecki@elmark.com.pl
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Modal from "stories/components/Modal/Modal";
import { Spinner } from "stories/components/Spinner/Spinner";

export const UnoErmaSnCheck = ({ sn, onClose, setSnErrorsCallback }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(true);
  const [snData, setSnData] = useState(null);
  useEffect(() => {
    setSnErrorsCallback([]);
    const formData = new FormData();
    formData.append("mode", "json");
    formData.append("sn", sn);
    axios.post("https://uno.elmark.com.pl/widgets/erma.sn.check.php", formData).then(
      (response) => {
        setFetching(false);
        setSnData(response.data);
        const snErrors = [];
        if (typeof response.data.demo === "object" && response.data.demo.length) snErrors.push("Sprzęt demo!");
        if (typeof response.data.loans === "object" && response.data.loans.length) {
          response.data.loans.forEach((item) => {
            if (item.STATE === "OPEN") snErrors.push("Otwarte wypożyczenie!");
          });
        }
        if (snErrors.length) setSnErrorsCallback(snErrors);
      },
      (error) => {
        setFetching(false);
        dispatch({ type: "APP_PUSHNOTIFICATION", notification: { type: "danger", description: "UnoErmaSnCheck.failed" } });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal title={`Uno/Erma/SnCheck: ${sn}`} size="elastic" onClose={onClose}>
      {fetching ? (
        <Spinner />
      ) : (
        snData !== null && (
          <>
            {typeof snData.err !== "undefined"
              ? snData.err
              : !snData?.demo?.length && !snData?.loans?.length && !snData?.history?.length
              ? `Dla podanego numeru seryjnego "${snData.sn}" nie znaleziono żadnych danych!`
              : null}
            {typeof snData.demo === "object" && snData.demo.length ? (
              <table className="management-config mb-5" style={{ width: "auto" }}>
                <thead>
                  <tr>
                    <th colSpan={2} className="title">
                      Zarejestrowany sprzęt demo <span className="text--silver text--no-bold">(ADO-OFERTY)</span>
                    </th>
                  </tr>
                  <tr>
                    <th>Grupa</th>
                    <th>Nazwa/opis</th>
                  </tr>
                </thead>
                <tbody>
                  {snData.demo.map((item, index) => (
                    <tr key={`DEMO#${index}`}>
                      <td>{item.GRUPA}</td>
                      <td>
                        <div>
                          <b>{item.NAZWA}</b>
                        </div>
                        <div>{item.OPIS}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
            {typeof snData.loans === "object" && snData.loans.length ? (
              <table className="management-config mb-5" style={{ width: "auto" }}>
                <thead>
                  <tr>
                    <th colSpan={4} className="title">
                      Wypożyczenia <span className="text--silver text--no-bold">(ADO-OFERTY)</span>
                    </th>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <th>Nazwa</th>
                    <th>Wypożyczający</th>
                    <th>Sporządził/dnia</th>
                  </tr>
                </thead>
                <tbody>
                  {snData.loans.map((item, index) => (
                    <tr key={`LOANS#${index}`}>
                      <td style={item.STATE === "OPEN" ? { backgroundColor: "brown", color: "white" } : null}>
                        {item.STATE === "CLOSED" ? "Zamknięte" : "Otwarte!!!"}
                      </td>
                      <td>
                        <b>{item.NAZWA}</b>
                      </td>
                      <td>
                        <div>{item.FIRMA}</div>
                        <div>{item.OSOBA}</div>
                      </td>
                      <td>
                        <div>{item.SPORZADZIL}</div>
                        <div>{item.Z_DNIA}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
            {typeof snData.history === "object" && snData.history.length ? (
              <table className="management-config mb-5" style={{ width: "auto" }}>
                <thead>
                  <tr>
                    <th colSpan={4} className="title">
                      Historia WE/WY <span className="text--silver text--no-bold">(Magazyn.exe, last 100)</span>
                    </th>
                  </tr>
                  <tr>
                    <th>Symbol/opis</th>
                    <th>We/Wy</th>
                    <th>Dokument</th>
                    <th>Data/godzina</th>
                  </tr>
                </thead>
                <tbody>
                  {snData.history.map((item, index) => (
                    <tr key={`HISTORY#${index}`}>
                      <td>
                        <div>
                          <b>{item.SYMBOL}</b>
                        </div>
                        <div>{item.OPIS}</div>
                      </td>
                      <td className="text--center" dangerouslySetInnerHTML={{ __html: item.OPERACJA }}></td>
                      <td>{item.DOK}</td>
                      <td>
                        {item.DATA.substring(0, 10)} <span className="text--silver">godz.</span> {item.DATA.substring(11, 19)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </>
        )
      )}
      {/* <div className="mt-5">
        <h3>HTML FRAME</h3>
      </div>
      <iframe
        title="uno.erma.sn.check"
        src={`https://uno.elmark.com.pl/@widgets/erma.sn.check.php?mode=html&sn=${sn}`}
        style={{ minWidth: "800px", height: "600px" }}
      ></iframe> */}
    </Modal>
  );
};
