import React, { useState } from "react";
import { Form, Formik } from "formik";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import agent from "api/agent";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Stack from "stories/components/Stack/Stack";
import Dropzone from "stories/components/Dropzone/Dropzone.js";
import TextField from "stories/components/TextField/TextField";
import Button from "stories/components/Button/Button";
import RadioGroup from "stories/components/RadioGroup/RadioGroup";
import { filterErrorsFromCommonApiErrors, isAdmin } from "utils/global-functions";
import { getBodyForRatingRequest, isSaveRatingDisabled } from "./utils";
import WaitingForInvitation from "./WaitingForInvitation";

const RatingForm = ({
  ratingResult,
  ratingInvitationResult,
  conversationListRefresh,
  setConversationListRefresh,
  refreshRepairDetails,
  setRefreshRepairDetails,
  setRejectRatingModalActive,
  customerRatingForm = false,
  setEditMode,
}) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { commonApiErrors },
  } = useSelector((store) => store);
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});

  const sendRatingSuccess = (response) => {
    if (response.result === 301) {
      dispatch({ type: "USER_NOTIFICATION", notificationCount: response.cnt });
      setConversationListRefresh(!conversationListRefresh);
      return setRefreshRepairDetails(!refreshRepairDetails);
    }

    return dispatch({
      type: "APP_PUSHNOTIFICATION",
      notification: {
        type: "danger",
        description: t(`errors.${response.result}`),
      },
    });
  };

  const sendRatingError = (error) => {
    dispatch({ type: "APP_CHECK_COMMONAPIERROR", error: error.response.data, status: error.response.status });
    const filteredErrors = filterErrorsFromCommonApiErrors(error.response.data, commonApiErrors.codeList);
    setFormErrors(Object.fromEntries(filteredErrors.filter((message) => !!message.property).map((message) => [message.property, t(`errors.${message.code}`)])));
    filteredErrors
      .filter((message) => !message.property)
      .forEach((filterdError) =>
        dispatch({
          type: "APP_PUSHNOTIFICATION",
          notification: {
            type: "danger",
            description: t(`errors.${filterdError.code}`),
          },
        }),
      );
    if (error.response.data.messages.find((message) => message?.code === 206)) setConversationListRefresh(!conversationListRefresh);
  };

  const sendRating = ({ keepAttachmentsIds, ...rest }) =>
    agent.Conversations.conversationsManage({
      id: user.id,
      token: user.token,
      repairId: repairDetails.repairId,
      keepAttachmentsIds: JSON.stringify(keepAttachmentsIds),
      ...getBodyForRatingRequest(ratingResult, ratingInvitationResult, user, customerRatingForm),
      ...rest,
    }).then(sendRatingSuccess, sendRatingError);

  if (!isAdmin(user) && lodash.isEmpty(ratingInvitationResult)) return <WaitingForInvitation />;

  const keepAttachmentsIds = ratingResult?.attachments?.map(({ id }) => id);

  return (
    <Formik
      initialValues={{
        conversationResult: ratingResult?.effect ?? "",
        conversationText: ratingResult?.text ?? "",
        attachments: [],
        keepAttachmentsIds: keepAttachmentsIds || [],
      }}
      onSubmit={(values) => sendRating(values)}
    >
      {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form className="rating__form" onSubmit={handleSubmit}>
          <div className="repair-info-form">
            {customerRatingForm && <p className="paragraph--small">{t("myRepairs.addRatingInfo")}</p>}
            <div className="rating-form__radio-group--wrapper">
              <RadioGroup
                name="conversationResult"
                choices={[
                  { label: t("global.iAmSatisfied"), value: 1 },
                  { label: t("global.iAmNotSatisfied"), value: 0 },
                ]}
                selected={values.conversationResult}
                onChange={(value) => setFieldValue("conversationResult", value)}
                error={formErrors.conversationResult}
                disabled={isSaveRatingDisabled(ratingResult)}
              />
            </div>
            <div className="rating-form__comment-wrapper">
              <TextField
                value={values.conversationText}
                onChange={(value) => setFieldValue("conversationText", value)}
                variant="multi"
                label={t("myRepairs.rateElmarkWarrancySerivce")}
                placeholder={t("global.writeAComment")}
                numberOfLines={5}
                disableResize
                error={formErrors.conversationText}
                disabled={isSaveRatingDisabled(ratingResult)}
              />
              {!!values.keepAttachmentsIds?.length && (
                <div>
                  <span className="paragraph--small">{t("global.uploadedAttachments")}</span>
                  <div className="pricing__keep-attachments">
                    {values.keepAttachmentsIds.map((attachmentId) => {
                      if (!ratingResult?.attachments?.length) return null;
                      const attachment = ratingResult?.attachments.find(({ id }) => id === attachmentId);
                      return (
                        <div className="pricing__keep-attachment" key={attachmentId}>
                          <a className="custom-link--black" href={`${process.env.REACT_APP_RESOURCE_URL}${attachment.id}`} target="_blank" rel="noreferrer">
                            {attachment.fileName}
                          </a>
                          <button
                            className="unset-all custom-link"
                            onClick={() =>
                              setFieldValue(
                                "keepAttachmentsIds",
                                values.keepAttachmentsIds.filter((id) => id !== attachmentId),
                              )
                            }
                          >
                            {t("global.delete")}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <Dropzone label={t("addReport.attachments")} files={values.attachments} addFilesValue={(value) => setFieldValue("attachments", value)} />
            </div>
            <Stack gap="0.75rem">
              <Button disabled={isSaveRatingDisabled(ratingResult)} size={mobile ? "small" : "default"} type="submit" variant="primary" loading={isSubmitting}>
                {t("global.save")}
              </Button>
              {!lodash.isEmpty(ratingResult) && (
                <Button onClick={() => setEditMode(false)} size={mobile ? "small" : "default"} disabled={isSubmitting}>
                  {t("global.cancel")}
                </Button>
              )}
              {!ratingInvitationResult?.isClosed && !lodash.isEmpty(ratingInvitationResult) && user.id === repairDetails.userId && customerRatingForm && (
                <Button
                  onClick={() => setRejectRatingModalActive(true)}
                  size={mobile ? "small" : "default"}
                  disabled={isSubmitting || isSaveRatingDisabled(ratingResult)}
                >
                  {t("myRepairs.leaveUnrated")}
                </Button>
              )}
            </Stack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RatingForm;
