import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "utils/config";
import Modal from "stories/components/Modal/Modal";

const BundleContentModal = ({ active, onClose, elmaticConfiguration }) => {
  const { t } = useTranslation();
  const { serialNumber } = useParams();
  const mobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const {
    app: { constants },
  } = useSelector((store) => store);

  if (!active) return;

  const [configuration] = elmaticConfiguration.data;

  return (
    <Modal size="big" title={t("myRepairs.configuration")} onClose={onClose}>
      <div>
        {!mobile ? (
          // --- DESKTOP --- //
          <table className="management-config">
            <thead>
              <tr>
                <th colSpan={4} className="pb-2">
                  <h5 className="text--black heading--5">{t("global.computer")}:</h5>
                </th>
              </tr>
              <tr>
                <th>{t("myRepairs.partNumber")}</th>
                <th>{t("myRepairs.description")}</th>
                <th>{t("myRepairs.producer")}</th>
                <th>{t("myRepairs.serialNumber")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className={serialNumber === configuration.serialNumber ? "text--red text--bold" : ""}>
                <td>{`${configuration.partNumber}`}</td>
                <td>{`${configuration.description}`}</td>
                <td>{`${configuration.producerName}`}</td>
                <td>{`${configuration.serialNumber}`}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={4} className="pt-5 pb-2">
                  <h5 className="text--black heading--5">{t("global.components")}:</h5>
                </th>
              </tr>
              <tr>
                <th>{t("myRepairs.partNumber")}</th>
                <th>{t("myRepairs.description")}</th>
                <th>{t("myRepairs.producer")}</th>
                <th>{t("myRepairs.serialNumber")}</th>
              </tr>
            </thead>
            <tbody>
              {configuration.items?.map((item) => (
                <tr key={item.serialNumber} className={serialNumber === item.serialNumber ? "text--red text--bold" : ""}>
                  <td>{`${item.partNumber}`}</td>
                  <td>{`${item.description}`}</td>
                  <td>{`${item.producerName}`}</td>
                  <td>{`${item.serialNumber}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          // --- MOBILE --- //
          <div>
            <h5 className="text--black heading--6 pb-2">{t("global.computer")}:</h5>
            <div className="bundle-configuration__row--mobile">
              <div className="bundle-configuration__row__cell--mobile">
                <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.partNumber")}</div>
                <div className="text--red text--bold">{`${configuration.partNumber}`}</div>
              </div>
              <div className="bundle-configuration__row__cell--mobile">
                <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.description")}</div>
                <div className="text--red text--bold">{`${configuration.description}`}</div>
              </div>
              <div className="bundle-configuration__row__cell--mobile">
                <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.producer")}</div>
                <div className="text--red text--bold">{`${configuration.producerName}`}</div>
              </div>
              <div className="bundle-configuration__row__cell--mobile">
                <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.serialNumber")}</div>
                <div className="text--red text--bold">{`${configuration.serialNumber}`}</div>
              </div>
            </div>

            <h5 className="text--black heading--6 pb-2 pt-5">{t("global.components")}:</h5>
            {configuration.items?.map((item) => (
              <div className="bundle-configuration__row--mobile">
                <div className="bundle-configuration__row__cell--mobile">
                  <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.partNumber")}</div>
                  <div className="bundle-configuration__row__cell__content--mobile">{`${item.partNumber}`}</div>
                </div>
                <div className="bundle-configuration__row__cell--mobile">
                  <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.description")}</div>
                  <div className="bundle-configuration__row__cell__content--mobile">{`${item.description}`}</div>
                </div>
                <div className="bundle-configuration__row__cell--mobile">
                  <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.producer")}</div>
                  <div className="bundle-configuration__row__cell__content--mobile">{`${item.producerName}`}</div>
                </div>
                <div className="bundle-configuration__row__cell--mobile">
                  <div className="bundle-configuration__row__cell__heading--mobile">{t("myRepairs.serialNumber")}</div>
                  <div className="bundle-configuration__row__cell__content--mobile">{`${item.serialNumber}`}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BundleContentModal;
