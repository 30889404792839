import React from "react";

const CardContent = ({ children, grid = false, flex = false, className = "" }) => (
  <div className={`custom-card__content ${className}`}>
    <div className="custom-card__border" />
    <div className={`custom-card__box ${grid ? "custom-card__content-grid" : ""} ${flex ? "custom-card__box--flex" : ""}`}>{children}</div>
  </div>
);

export default CardContent;
