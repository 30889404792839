import React, { useState } from "react";
import PageSpinner from "components/PageSpinner/PageSpinner";
import EditReturnAddressModal from "../EditReturnAddressModal";
import Content from "./Content";

const Mobile = ({ repairDetailsLoading }) => {
  const [editReturnAddressModalActive, setEditReturnAddressModalActive] = useState(false);

  return (
    <>
      {repairDetailsLoading ? <PageSpinner subPage /> : <Content setEditReturnAddressModalActive={setEditReturnAddressModalActive} />}
      <EditReturnAddressModal active={editReturnAddressModalActive} setActive={setEditReturnAddressModalActive} />
    </>
  );
};

export default Mobile;
