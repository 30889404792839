import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Link = ({ variant, ...args }) => (
  <a className={variant === "primary" ? "link__primary" : "link"} {...args}>
    {args.children}
  </a>
);

Link.propTypes = {
  variant: PropTypes.oneOf(["primary", "default"]),
};

export default Link;
