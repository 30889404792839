import md5 from "md5";

export const getRandomComponentId = () => String(md5(Math.floor(Math.random() * 10000)).slice(0, 17));

export const getChevron = (type, photoButton = { onAction: () => {}, disabled: true }, loading, setLoading) => {
  const chevrons = {
    back: (
      <button
        disabled={photoButton.disabled || loading}
        onClick={() => {
          setLoading(true);
          photoButton.onAction();
        }}
        className={`image-modal__chevron ${photoButton.disabled || loading ? " image-modal__chevron--disabled" : ""}`}
      >
        <svg height="5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="60" d="M328 112L184 256l144 144" />
        </svg>
      </button>
    ),
    forward: (
      <button
        disabled={photoButton.disabled || loading}
        onClick={() => {
          setLoading(true);
          photoButton.onAction();
        }}
        className={`image-modal__chevron ${photoButton.disabled || loading ? " image-modal__chevron--disabled" : ""}`}
      >
        <svg height="5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="60" d="M184 112l144 144-144 144" />
        </svg>
      </button>
    ),
  };

  return chevrons[type];
};
