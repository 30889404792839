import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "stories/components/Modal/Modal";
import ContactOptions from "./ContactOptions";
import ContactForm from "./ContactForm";
import { contactModalTitles } from "./config";
import ContactSentInfo from "./ContactSentInfo";
import "./styles.css";

const ContactModal = ({ active, onClose }) => {
  const { t } = useTranslation();
  const [contactOption, setContactOption] = useState("");

  if (!active) return;

  return (
    <Modal
      size="huge"
      onClose={() => {
        onClose();
        setContactOption("");
      }}
      title={contactModalTitles[contactOption] ? t(`serialNumber.${contactModalTitles[contactOption]}`) : ""}
    >
      <ContactForm contactOption={contactOption} setContactOption={setContactOption} />
      <ContactOptions setContactOption={setContactOption} contactOption={contactOption} />
      <ContactSentInfo contactOption={contactOption} />
    </Modal>
  );
};

export default ContactModal;
