import React from "react";
import Tab from "./Tab";
import "./styles.css";

const TabsCard = ({ selected, setSelected, tabs, fitted, children }) => {
  return (
    <div className="tabs-card">
      <ul className="nav nav-tabs">
        {tabs.map((tab) => (
          <Tab key={tab.id} selected={selected} onSelect={setSelected} fitted={fitted} {...tab} />
        ))}
      </ul>
      <div className="nav-tabs-content tabs-card__content">{children}</div>
    </div>
  );
};

export default TabsCard;
