import React, { useState } from "react";
import Chevron from "images/Chevron.svg";
import { isActiveLink } from "../utils";
import NavigationDropdownLink from "./NavigationDropdownLink";

const NavigationDropdown = ({ links }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const currentLink = links.find((link) => isActiveLink(link.url));

  window.onclick = function (event) {
    if (event.target.matches(".mobile-navigation-dropdown__button")) return;

    const dropdowns = document.getElementsByClassName("mobile-navigation-dropdown__content");

    for (let i = 0; i < dropdowns.length; i++) if (dropdowns[i].classList.contains("mobile-navigation-dropdown--show")) setDropdownActive(false);
  };

  return (
    <div className="mobile-navigation-dropdown">
      <div className="mobile-navigation-dropdown__button-wrapper">
        <button className="mobile-navigation-dropdown__button" onClick={() => setDropdownActive(!dropdownActive)}>
          {currentLink?.name}
        </button>
        <img className={`mobile-navigation-dropdown__img ${dropdownActive ? "mobile-navigation-dropdown__img--active" : ""}`} src={Chevron} alt="v" />
      </div>
      <div className={`mobile-navigation-dropdown__content ${dropdownActive ? "mobile-navigation-dropdown--show" : ""}`}>
        {links.map((link) => (
          <NavigationDropdownLink key={link.url} link={link} />
        ))}
      </div>
    </div>
  );
};

export default NavigationDropdown;
