import React from "react";
import { useTranslation } from "react-i18next";

const WaitingForInvitation = () => {
  const { t } = useTranslation();

  return (
    <div className="rating__empty-state">
      <h6 className="heading--6">{t("myRepairs.waitingForInvitationHeading")}</h6>
      <span className="paragraph--small text--subdued">{t("myRepairs.waitingForInvitationInfo")}</span>
    </div>
  );
};

export default WaitingForInvitation;
