import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Tooltip from "stories/components/Tooltip/Tooltip";
import RepairStepsIcon from "./RepairStepsIcon";

const RepairStepsDropdown = ({ steps }) => {
  const [active, setActive] = useState(false);
  const {
    app: { constants },
  } = useSelector((store) => store);
  const { t } = useTranslation();
  const repairStepsDropdown = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (repairStepsDropdown.current && !repairStepsDropdown.current.contains(event.target)) setActive(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [repairStepsDropdown]);

  return (
    <div ref={repairStepsDropdown} className="repair-steps__dropdown">
      <div style={{ height: active ? `${(steps.length + 2.1) * 4 - 4.5}rem` : "4rem" }} className="repair-steps__dropdown-item-wrapper">
        {steps.map(({ valueAfter, id, createdAt }, index) => (
          <Tooltip
            direction="bottom"
            key={id}
            content={`<div class="text--left">${t(
              `constants.repair.state.${constants?.repairState?.[parseInt(valueAfter)]?.strVal}`,
            )}<div class="text--green">${moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</div></div>`}
          >
            <div style={{ top: active ? `${4 * (index + 1.1)}rem` : "0.5rem" }} className="repair-steps__item repair-steps__dropdown-item ">
              <RepairStepsIcon iconName={valueAfter} iconColor="var(--primary-color)" />
            </div>
          </Tooltip>
        ))}
      </div>
      <div onClick={() => setActive(!active)} className="repair-steps__item repair-steps__dropdown-item repair-steps__dropdown-activator">
        <RepairStepsIcon iconColor="var(--primary-color)" iconName="multi" />
      </div>
    </div>
  );
};

export default RepairStepsDropdown;
