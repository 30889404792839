import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { counterVariation, isEmployee } from "utils/global-functions";
import { additionalProductDetails, productDetailsInOrder } from "../config";
import { isProductDetailEmpty } from "../utils";
import DeleteRepair from "../DeleteRepair";
import InfoDetails from "./InfoDetails";
import RepairDescription from "./RepairDescription";
import InternalComment from "./InternalComment";
import ClientInfoDetails from "./ClientInfoDetails";
import "./styles.css";

const Mobile = ({ refreshRepairDetails, setRefreshRepairDetails, repairDetailsTermsMap, repairDetailsDescriptionMap, productUrl }) => {
  const { t } = useTranslation();
  const {
    user,
    app: { constants },
    repair: { repairDetails },
  } = useSelector((store) => store);

  const productDetailsContentDecodedValues = {
    producerId: <p className="paragraph--small info-details__description">{constants.producers?.[repairDetails.producerId]?.strVal || "-"}</p>,
    warrantyLength: (
      <p className="paragraph--small info-details__description">
        {repairDetails.warrantyLength
          ? `${repairDetails.warrantyLength} ${counterVariation(
              repairDetails.warrantyLength,
              t("global.month").toLowerCase(),
              t("global.months234").toLowerCase(),
              t("global.months05p").toLowerCase(),
            )}`
          : "-"}
      </p>
    ),
    warrantyUnder: (
      <p className="paragraph--small info-details__description">{repairDetails.warrantyUnder !== null ? t(`global.${!!repairDetails.warrantyUnder}`) : "-"}</p>
    ),
    repairSource: <p className="paragraph--small info-details__description">{t(`global.${constants.repairSource?.[repairDetails.repairSource]?.strVal}`)}</p>,
    factoryResetAgreement: (
      <p className="paragraph--small info-details__description">
        {repairDetails.factoryResetAgreement === null ? t("global.noData") : repairDetails.factoryResetAgreement ? t("global.yes") : t("global.no")}
      </p>
    ),
  };

  const productDetailsContent = [
    ...productDetailsInOrder.map((productDetail) => {
      return {
        term: t(`myRepairs.${productDetail}`),
        description: productDetailsContentDecodedValues[productDetail] || (
          <p className="paragraph--small info-details__description">{repairDetails[productDetail] || "-"}</p>
        ),
      };
    }),
    ...additionalProductDetails.map((additionalProductDetail) => {
      if (isProductDetailEmpty(repairDetails, additionalProductDetail)) return {};

      return {
        term: t(`myRepairs.${additionalProductDetail}`),
        description: productDetailsContentDecodedValues[additionalProductDetail] || (
          <p className="paragraph--small info-details__description">{repairDetails[additionalProductDetail] || "-"}</p>
        ),
      };
    }),
  ];

  return (
    <>
      {isEmployee(user) ? (
        <>
          <InfoDetails
            productDetailsContent={productDetailsContent}
            refreshRepairDetails={refreshRepairDetails}
            setRefreshRepairDetails={setRefreshRepairDetails}
            repairDetailsTermsMap={repairDetailsTermsMap}
            repairDetailsDescriptionMap={repairDetailsDescriptionMap}
            productUrl={productUrl}
          />
          <RepairDescription />
          <InternalComment />
          <DeleteRepair />
        </>
      ) : (
        <ClientInfoDetails
          productDetailsContent={productDetailsContent}
          repairDetailsTermsMap={repairDetailsTermsMap}
          repairDetailsDescriptionMap={repairDetailsDescriptionMap}
          productUrl={productUrl}
        />
      )}
    </>
  );
};

export default Mobile;
