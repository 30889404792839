import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Accordion from "stories/components/Accordion/Accordion";
import Button from "stories/components/Button/Button";
import { isAdmin, isReception } from "utils/global-functions";
import { addressOrder } from "../config";
import { getContentForDeliveryAddress, getContentForReturnAddress, getPackageInfo } from "../utils";

const Content = ({ setEditReturnAddressModalActive }) => {
  const { t } = useTranslation();
  const {
    user,
    repair: { repairDetails },
    app: { constants },
  } = useSelector((store) => store);
  const translatedCountryNames = new Intl.DisplayNames([user.lang], { type: "region" });

  const { deliveryPackages, deliveryType, ...restDeliveryInfo } = Object.fromEntries(Object.entries(repairDetails).filter(([key]) => key.includes("delivery")));
  const { returnType, ...restReturnInfo } = Object.fromEntries(Object.entries(repairDetails).filter(([key]) => key.includes("return")));

  const details = [
    {
      title: t("myRepairs.deliveryInfo"),
      content: [
        { term: t("myRepairs.deliveryType"), description: t(`constants.delivery.type.${constants?.deliveryType?.[deliveryType].strVal}`) },
        ...getPackageInfo(deliveryPackages, constants, t),
      ],
    },
    {
      title: t("myRepairs.deliveryAddress"),
      content: getContentForDeliveryAddress(deliveryType, addressOrder, restDeliveryInfo, translatedCountryNames, constants, t),
    },
    {
      title: t("myRepairs.returnAddress"),
      content: getContentForReturnAddress(returnType, addressOrder, restReturnInfo, translatedCountryNames, constants, t),
      action: (isAdmin(user) || isReception(user)) && (
        <Button onClick={() => setEditReturnAddressModalActive(true)} size="small">
          {t("global.edit")}
        </Button>
      ),
    },
  ];

  return (
    <Accordion
      content={details.map(({ title, content, action }) => {
        return {
          title: <h6 className="heading--6 heading--large-spacing">{title}</h6>,
          body: (
            <div className="repair-info-form">
              {content.map(({ term, description }, index) => (
                <div key={index}>
                  <span className="paragrapgh--small text--subdued">{term}</span>
                  <p className="paragrapgh--small">{description}</p>
                </div>
              ))}
              {action}
            </div>
          ),
        };
      })}
    />
  );
};

export default Content;
